import React from 'react';
import { ScrollView } from 'react-native';
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom';
import { AreaPage } from './Area/AreaPage';
import { AdminPage } from './AdminPage';
import { AreaDetailPage } from './Area/AreaDetailPage';
import { EmployeePage } from './Employee/EmployeePage';
import { EmployeeDetailPage } from './Employee/EmployeeDetailPage';
import { AddEmployeePage } from './Employee/AddEmployeePage';
import { BuildingPage } from './Buildings/BuildingsPage';
import { BuildingDetailPage } from './Buildings/BuildingDetailPage';
import { AddBuildingPage } from './Buildings/AddBuildingPage';
import { TerritoryPage } from './Territory/TerritoryPage';
import { TerritoryDetailPage } from './Territory/TerritoryDetailPage';
import { AddTerritoryPage } from './Territory/AddTerritoryPage';
import { SchedulePage } from './Schedule/SchedulePage';
import { ScheduleDetailPage } from './Schedule/ScheduleDetailPage';
import { VehiclePage } from './Vehicle/VehiclesPage';
import { VehicleDetailPage } from './Vehicle/VehiclesDetailPage';
import { AddVehiclePage } from './Vehicle/AddVehiclePage';
import { LocationPage } from './Location/LocationPage';
import { LocationDetailPage } from './Location/LocationDetailPage';
import { AddLocationPage } from './Location/AddLocationPage';
import { StorageItemTypePage } from './StorageItemType/StorageItemTypesPage';
import { StorageItemTypeDetailPage } from './StorageItemType/StorageItemTypesDetailPage';
import { AddStorageItemTypePage } from './StorageItemType/AddStorageItemTypePage';
import { StorageItemTypeCategoryPage } from './StorageItemTypeCategory/StorageItemTypeCategoryPage';
import { StorageItemTypeCategoryDetailPage } from './StorageItemTypeCategory/StorageItemTypeCategoryDetailPage';
import { AddStorageItemTypeCategoryPage } from './StorageItemTypeCategory/AddStorageItemTypeCategoryPage';
import { StorageContainerPage } from './Storage-Container/StorageContainerPage';
import { StorageContainerDetailPage } from './Storage-Container/StorageContainerDetailPage';
import { AddStorageContainerPage } from './Storage-Container/AddStorageContainerPage';
import { VisitManagementPage } from './Visit-Management/VisitManagement';
import { VisitManagementDetailPage } from './Visit-Management/VisitManagementDetailPage';
import { StorageContainerTypePage } from './Storage-Container-Type/StorageContainerTypePage';
import { StorageContainerTypeDetailPage } from './Storage-Container-Type/StorageContainerTypeDetailPage';
import { AddStorageContainerTypePage } from './Storage-Container-Type/AddStorageContainerTypePage';

type AdminRoutesProps = {};

export const AdminRoutes: React.FC<AdminRoutesProps> = () => {
    const { url } = useRouteMatch();
    const history = useHistory();

    const handleAreaSelect = (areaId: string): void => {
        history.push(`/admin/area/${areaId}/detail`);
    };
    const handleEmployeeSelect = (emplyId: string): void => {
        history.push(`/admin/employee/${emplyId}/detail`);
    };

    const handleAddEmployeeSelect = (): void => {
        history.push(`/admin/employee/addEmployee`);
    };

    const handleEmployeeAdded = (): void => {
        history.push(`/admin/employee`);
    };

    const handleOnBuildingsSelect = (buildingId: string): void => {
        history.push(`/admin/buildings/${buildingId}/detail`);
    };
    const handleAddBuildingSelect = (): void => {
        history.push(`/admin/buildings/addBuilding`);
    };
    const handleBuildingAdded = (): void => {
        history.push(`/admin/buildings`);
    };

    const handleOnVehicleSelect = (vehicleId: string): void => {
        history.push(`/admin/vehicle/${vehicleId}/detail`);
    };

    const handleAddVehicleSelect = (): void => {
        history.push(`/admin/vehicle/addVehicle`);
    };

    const handleVehicleAdded = (): void => {
        history.push(`/admin/vehicle`);
    };

    const handleBackClick = (): void => {
        history.push(`/admin/area`);
    };
    const handleOnTerritorysSelect = (territoryId: string): void => {
        history.push(`/admin/territories/${territoryId}/detail`);
    };
    const handleAddTerritorySelect = (): void => {
        history.push(`/admin/territories/addTerritory`);
    };
    const handleTerritoryAdded = (): void => {
        history.push(`/admin/territories`);
    };

    const handleOnScheduleIdsSelect = (scheduleId: string): void => {
        history.push(`/admin/schedules/${scheduleId}/detail`);
    };

    const handleOnLocationSelect = (locationId: string): void => {
        history.push(`/admin/locations/${locationId}/detail`);
    };

    const handleOnStorageItemTypesIdSelect = (storageItemTypeId: string): void => {
        history.push(`/admin/storageItemTypes/${storageItemTypeId}/detail`);
    };

    const handleStorageItemTypesAdded = (): void => {
        history.push(`/admin/storageItemTypes`);
    };
    const handleAddStorageItemTypesSelect = (): void => {
        history.push(`/admin/storageItemTypes/addStorageItemTypes`);
    };
    const handleOnAddLocationSelect = (): void => {
        history.push(`/admin/locations/addLocation`);
    };

    const handleOnLocationAdded = (): void => {
        history.push(`/admin/locations`);
    };

    const handleOnCategoryIdSelect = (categoryId: string): void => {
        history.push(`/admin/categories/${categoryId}/detail`);
    };
    const handleCategoryAdded = (): void => {
        history.push(`/admin/categories`);
    };
    const handleAddCategorySelect = (): void => {
        history.push(`/admin/categories/addCategory`);
    };

    const handleOnContainersIdSelect = (containerId: string): void => {
        history.push(`/admin/containers/${containerId}/detail`);
    };
    const handleAddContainerSelect = (): void => {
        history.push(`/admin/containers/addContainer`);
    };
    const handleContainerAdded = (): void => {
        history.push(`/admin/containers`);
    };

    const handleOnVisitManagementIdSelect = (visitId: string): void => {
        history.push(`/admin/visitManagement/${visitId}/detail`);
    };

    const handleOnContainerTypeIdSelect = (containerTypeId: string): void => {
        history.push(`/admin/containerTypes/${containerTypeId}/detail`);
    };
    const handleAddContainerTypeSelect = (): void => {
        history.push(`/admin/containerTypes/addContainerType`);
    };
    const handleContainerTypeAdded = (): void => {
        history.push(`/admin/containerTypes`);
    };
    return (
        <ScrollView>
            <Switch>
                {/* Area Page*/}
                <Route path={`${url}/area`} exact>
                    <AreaPage onAreaSelect={handleAreaSelect} />
                </Route>
                <Route path={`${url}/area/:areaId/detail`} exact>
                    <AreaDetailPage onBackClick={handleBackClick} />
                </Route>
                {/* Admin Page*/}
                <Route path={`${url}`} exact>
                    <AdminPage />
                </Route>
                {/* Employee Page*/}
                <Route path={`${url}/employee`} exact>
                    <EmployeePage
                        onEmployeeSelect={handleEmployeeSelect}
                        onAddEmployeeSelect={handleAddEmployeeSelect}
                    />
                </Route>
                <Route path={`${url}/employee/:employeeId/detail`} exact>
                    <EmployeeDetailPage locale={'en'} countryCode={'CA'} />
                </Route>
                <Route path={`${url}/employee/addEmployee`} exact>
                    <AddEmployeePage
                        onEmployeeAdded={handleEmployeeAdded}
                        locale={'en'}
                        countryCode={'CA'}
                    />
                </Route>
                {/* Building Page*/}
                <Route path={`${url}/buildings`} exact>
                    <BuildingPage
                        onBuildingSelect={handleOnBuildingsSelect}
                        onAddBuildingSelect={handleAddBuildingSelect}
                    />
                </Route>
                <Route path={`${url}/buildings/:buildingId/detail`} exact>
                    <BuildingDetailPage locale={'en'} countryCode={'CA'} />
                </Route>
                <Route path={`${url}/buildings/addBuilding`} exact>
                    <AddBuildingPage
                        onBuildingAdded={handleBuildingAdded}
                        locale={'en'}
                        countryCode={'CA'}
                    />
                </Route>
                {/* Vehicle Page*/}
                <Route path={`${url}/vehicle`} exact>
                    <VehiclePage
                        onVehicleSelect={handleOnVehicleSelect}
                        onAddVehicleSelect={handleAddVehicleSelect}
                    />
                </Route>
                <Route path={`${url}/vehicle/:vehicleId/detail`} exact>
                    <VehicleDetailPage />
                </Route>
                <Route path={`${url}/vehicle/addVehicle`} exact>
                    <AddVehiclePage onVehicleAdded={handleVehicleAdded} />
                </Route>
                {/* Territory Page*/}
                <Route path={`${url}/territories`} exact>
                    <TerritoryPage
                        onTerritorySelect={handleOnTerritorysSelect}
                        onAddTerritorySelect={handleAddTerritorySelect}
                    />
                </Route>
                <Route path={`${url}/territories/:territoryId/detail`} exact>
                    <TerritoryDetailPage />
                </Route>
                <Route path={`${url}/territories/addTerritory`} exact>
                    <AddTerritoryPage onTerritoryAdded={handleTerritoryAdded} />
                </Route>
                {/*Schedules Page  */}
                <Route path={`${url}/schedules`} exact>
                    <SchedulePage onScheduleSelect={handleOnScheduleIdsSelect} />
                </Route>
                <Route path={`${url}/schedules/:scheduleTemplateId/detail`} exact>
                    <ScheduleDetailPage />
                </Route>
                {/*Locations Page  */}
                <Route path={`${url}/locations`} exact>
                    <LocationPage
                        onLocationSelect={handleOnLocationSelect}
                        onAddLocationSelect={handleOnAddLocationSelect}
                    />
                </Route>
                <Route path={`${url}/locations/:locationId/detail`} exact>
                    <LocationDetailPage />
                </Route>
                <Route path={`${url}/locations/addLocation`} exact>
                    <AddLocationPage onLocationAdded={handleOnLocationAdded} />
                </Route>
                {/*StorageItemTypes Page  */}
                <Route path={`${url}/storageItemTypes`} exact>
                    <StorageItemTypePage
                        onStorageItemTypeSelect={handleOnStorageItemTypesIdSelect}
                        onAddStorageItemTypeSelect={handleAddStorageItemTypesSelect}
                    />
                </Route>
                <Route path={`${url}/storageItemTypes/:storageItemTypeId/detail`} exact>
                    <StorageItemTypeDetailPage />
                </Route>
                <Route path={`${url}/storageItemTypes/addStorageItemTypes`} exact>
                    <AddStorageItemTypePage onStorageItemTypeAdded={handleStorageItemTypesAdded} />
                </Route>
                {/*StorageItemTypeCategory Page  */}
                <Route path={`${url}/categories`} exact>
                    <StorageItemTypeCategoryPage
                        onItemCategorySelect={handleOnCategoryIdSelect}
                        onAddItemCategorySelect={handleAddCategorySelect}
                    />
                </Route>
                <Route path={`${url}/categories/:categoryId/detail`} exact>
                    <StorageItemTypeCategoryDetailPage />
                </Route>
                <Route path={`${url}/categories/addCategory`} exact>
                    <AddStorageItemTypeCategoryPage onItemCategoryAdded={handleCategoryAdded} />
                </Route>
                {/*Storage Container Page  */}
                <Route path={`${url}/containers`} exact>
                    <StorageContainerPage
                        onStorageContainerSelect={handleOnContainersIdSelect}
                        onAddStorageContainerSelect={handleAddContainerSelect}
                    />
                </Route>
                <Route path={`${url}/containers/:containerId/detail`} exact>
                    <StorageContainerDetailPage />
                </Route>
                <Route path={`${url}/containers/addContainer`} exact>
                    <AddStorageContainerPage onStorageContainerAdded={handleContainerAdded} />
                </Route>
                {/*Visit Management Page  */}
                <Route path={`${url}/visitManagement`} exact>
                    <VisitManagementPage
                        handleVisitManagementSelect={handleOnVisitManagementIdSelect}
                    />
                </Route>
                <Route path={`${url}/visitManagement/:visitId/detail`} exact>
                    <VisitManagementDetailPage />
                </Route>

                {/*Storage Container TypePage  */}
                <Route path={`${url}/containerTypes`} exact>
                    <StorageContainerTypePage
                        onContainerTypeSelect={handleOnContainerTypeIdSelect}
                        onAddContainerTypeSelect={handleAddContainerTypeSelect}
                    />
                </Route>
                <Route path={`${url}/containerTypes/:containerTypeId/detail`} exact>
                    <StorageContainerTypeDetailPage />
                </Route>
                <Route path={`${url}/containerTypes/addContainerType`} exact>
                    <AddStorageContainerTypePage
                        onItemContainerTypeAdded={handleContainerTypeAdded}
                    />
                    <AddStorageContainerTypePage
                        onItemContainerTypeAdded={handleContainerTypeAdded}
                    />
                </Route>
            </Switch>
        </ScrollView>
    );
};
