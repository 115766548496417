import React, { useMemo } from 'react';
import { View, FlatList, ListRenderItem, Text, StyleSheet, Platform } from 'react-native';
import { Button, Card } from '@valet/ui-components';
import { useDrop } from 'react-dnd';
import {
    RouteData,
    VisitsSummaryData,
    RouteWithSummaryData,
    RoutingDragObject,
    TerritoryData,
    VisitData,
} from './Routing';
import { VisitsSummaryIcons } from './VisitsSummaryIcons';
import { useIntl } from 'react-intl';

type RouteListProps = {
    routes: RouteData[];
    routeSummaries: { [key: string]: VisitsSummaryData };
    selectedRoute?: RouteData['id'];
    onItemSelected?: (id: RouteData['id']) => void;
    onItemUnselected?: (id: RouteData['id']) => void;
    onTerritoryDroppedOnRoute?: (
        territoryId: TerritoryData['id'],
        routeId?: RouteData['id'],
    ) => void;
    onSelectedDroppedOnRoute?: (routeId?: RouteData['id']) => void;
    onVisitDroppedOnRoute?: (visitId: VisitData['id'], routeId?: RouteData['id']) => void;
};

export const RouteList: React.FC<RouteListProps> = ({
    routes = [],
    routeSummaries = {},
    selectedRoute,
    onItemSelected = () => undefined,
    onItemUnselected = () => undefined,
    onTerritoryDroppedOnRoute = () => undefined,
    onSelectedDroppedOnRoute = () => undefined,
    onVisitDroppedOnRoute = () => undefined,
}) => {
    const routesWithSummaries = useMemo(
        () =>
            routes.map((route) => ({
                ...route,
                summary: routeSummaries[route.id] ?? { deliveries: 0, pickups: 0 },
            })),
        [routes, routeSummaries],
    );

    const extractItemKey: (route: RouteWithSummaryData) => string = (route) => `${route.id}`;

    const renderItem: ListRenderItem<RouteWithSummaryData> = ({ item: route }) => {
        return (
            <RouteListItem
                routeInformation={route}
                routeSummary={route.summary}
                isSelected={selectedRoute === route.id}
                onSelected={() => onItemSelected(route.id)}
                onUnselected={() => onItemUnselected(route.id)}
                onTerritoryDropped={(territoryId) =>
                    onTerritoryDroppedOnRoute(territoryId, route.id)
                }
                onSelectedDropped={() => onSelectedDroppedOnRoute(route.id)}
                onVisitDropped={(visitId) => onVisitDroppedOnRoute(visitId, route.id)}
            />
        );
    };

    return (
        <FlatList
            data={routesWithSummaries}
            extraData={[
                selectedRoute,
                onSelectedDroppedOnRoute,
                onTerritoryDroppedOnRoute,
                onVisitDroppedOnRoute,
                onItemSelected,
                onItemUnselected,
            ]}
            keyExtractor={extractItemKey}
            renderItem={renderItem}
        ></FlatList>
    );
};

type RouteListItemProps = {
    routeInformation: RouteData;
    routeSummary: VisitsSummaryData;
    isSelected?: boolean;
    onSelected?: () => void;
    onUnselected?: () => void;
    onTerritoryDropped?: (territoryId: TerritoryData['id']) => void;
    onSelectedDropped?: () => void;
    onVisitDropped?: (visitId: VisitData['id']) => void;
};

const RouteListItemContent: React.FC<RouteListItemProps> = ({
    routeInformation,
    routeSummary,
    isSelected,
    onSelected = () => undefined,
    onUnselected = () => undefined,
}) => {
    const intl = useIntl();

    return (
        <View style={menuListStyles.ViewListItemContainer}>
            <Card
                style={{ flex: 1 }}
                disabled
                footer={() => (
                    <View style={menuListStyles.ViewListItemActions}>
                        {isSelected ? (
                            <Button
                                title={intl.formatMessage({
                                    id: 'routing.hideVisits',
                                    defaultMessage: 'Hide visits',
                                })}
                                onPress={onUnselected}
                                size="tiny"
                            />
                        ) : (
                            <Button
                                title={intl.formatMessage({
                                    id: 'routing.showVisits',
                                    defaultMessage: 'Show visits',
                                })}
                                onPress={onSelected}
                                size="tiny"
                            />
                        )}
                    </View>
                )}
            >
                <View style={menuListStyles.ViewListItemBody}>
                    <View
                        style={{
                            paddingVertical: 2,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}
                    >
                        <Text style={{ fontSize: 16 }}>{routeInformation?.description}</Text>
                    </View>

                    <View style={{ paddingVertical: 2 }}>
                        <VisitsSummaryIcons {...routeSummary} />
                    </View>
                </View>
            </Card>
        </View>
    );
};

const RouteListItem: React.FC<RouteListItemProps> = (props) => {
    const {
        routeInformation,
        onTerritoryDropped = () => undefined,
        onSelectedDropped = () => undefined,
        onVisitDropped = () => undefined,
    } = props;

    const [, drop] = useDrop<RoutingDragObject, unknown, unknown>({
        accept: ['territory', 'visit', 'selected'],
        drop(item) {
            if (item.type === 'territory') {
                console.log('onTerritoryAdded', routeInformation.id, item.id);
                onTerritoryDropped(item.id);
            }
            if (item.type === 'visit') {
                console.log('onVisitAdded', routeInformation.id, item.id);
                onVisitDropped(item.id);
            }
            if (item.type === 'selected') {
                console.log('onSelectedAdded', routeInformation.id);
                onSelectedDropped();
            }
        },
    });

    if (Platform.OS === 'web') {
        return (
            <div ref={drop}>
                <RouteListItemContent {...props} />
            </div>
        );
    }

    return <RouteListItemContent {...props} />;
};

const menuListStyles = StyleSheet.create({
    ViewListItemContainer: {
        flexDirection: 'row',
        paddingVertical: 4,
        paddingRight: 4,
    },
    ViewListItemBody: {
        flex: 1,
        flexDirection: 'column',
    },
    ViewListItemActions: {
        padding: 5,
        justifyContent: 'center',
        flex: 1,
    },
});
