import { gql } from '@apollo/client';
import React, { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ScrollView, StyleSheet, Text, View } from 'react-native';
import { Button, Message, TextInput } from '@valet/ui-components';
import {
    useGetAdminStorageItemTypeCategoryByIdQuery,
    AdminStorageItemTypeCategoryDetailFragment,
    useAdminUpdateStorageItemTypeCategoryMutation,
} from '../../../../schema-types';
import { useHistory, useRouteMatch } from 'react-router-dom';


const STORAGEITEMTYPE_CATEGORY_FRAGMENT = gql`
    fragment adminStorageItemTypeCategoryDetail on StorageItemTypeCategory {
        id
        name
    }
`;

export const RETRIEVE_BUILDING_QUERY = gql`
    query getAdminStorageItemTypeCategoryById($categoryId: String!) {
        storageItemTypeCategoryById(id: $categoryId) {
            ...adminStorageItemTypeCategoryDetail
        }
    }
    ${STORAGEITEMTYPE_CATEGORY_FRAGMENT}
`;

export const ADMIN_UPDATE_BUILDING_MUTATION = gql`
    mutation adminUpdateStorageItemTypeCategory($data: AdminStorageItemTypeCategoryInput!) {
        adminUpdateStorageItemTypeCategory(data: $data) {
            data {
                ...adminStorageItemTypeCategoryDetail
            }
        }
    }
`;

type StorageItemTypeCategoryDetailPageProps = {};

export const StorageItemTypeCategoryDetailPage: React.FC<StorageItemTypeCategoryDetailPageProps> = () => {
    const history = useHistory();
    const intl = useIntl();
    const match = useRouteMatch();
    const params: { categoryId?: string } = match.params;
    const categoryId = params.categoryId;
    const [storageItemTypeCategory, setStorageItemTypeCategory] = useState<AdminStorageItemTypeCategoryDetailFragment>({
        id: '',
        name: '',
    });


    const {
        loading: retrievedStorageItemTypeCategoryDetailLoading,
        error: retrievedStorageItemTypeCategoryDetailError,
    } = useGetAdminStorageItemTypeCategoryByIdQuery({
        variables: {
            categoryId: categoryId || '',
        },
        onCompleted: (data: any) => {
            setStorageItemTypeCategory(data.storageItemTypeCategoryById as AdminStorageItemTypeCategoryDetailFragment);
        },
    });

    const [
        adminUpdateStorageItemTypeCategoryMutation,
        {
            loading: adminUpdateStorageItemTypeCategoryDetailMutationLoading,
            error: adminUpdateStorageItemTypeCategoryDetailMutationError,
        },
    ] = useAdminUpdateStorageItemTypeCategoryMutation();

    const handleBackClick = useCallback(async () => {
        history.push(`/admin/categories`);
    }, [history]);

    const handleSaveStorageItemTypeCategoryDetailClick = useCallback(async () => {
        if (storageItemTypeCategory) {
            try {
                await adminUpdateStorageItemTypeCategoryMutation({
                    variables: {
                        data: {
                            id: storageItemTypeCategory.id,
                            name: storageItemTypeCategory.name,
                        }

                    },
                });
                handleBackClick();
            } catch (error) { }
        }
    }, [storageItemTypeCategory, handleBackClick, adminUpdateStorageItemTypeCategoryMutation]);

    return (
        <ScrollView>
            {retrievedStorageItemTypeCategoryDetailError ?
                <View style={StorageItemTypeCategoryPageStyles.ViewErrorMessageParent} testID="data-errorMessage">
                    <Message
                        type="negative"
                        header={intl.formatMessage({
                            id: 'StorageItemTypeCategoryDetail.StorageItemTypeCategoryDetailErrorMessage',
                            defaultMessage: 'Could not load detail for the storage Item Type - please try again',
                        })}
                        content={retrievedStorageItemTypeCategoryDetailError.message}
                        testID="data-StorageItemTypeCategoryDetailErrorMessage"
                    />
                </View>
                : adminUpdateStorageItemTypeCategoryDetailMutationError ?
                    <View style={StorageItemTypeCategoryPageStyles.ViewErrorMessageParent} testID="data-errorMessage">
                        <Message
                            type="negative"
                            header={intl.formatMessage({
                                id: 'StorageItemTypeCategoryDetail.StorageItemTypeCategoryDetailUpdateErrorMessage',
                                defaultMessage: 'Could not update storage Item Type detail - please try again',
                            })}
                            content={adminUpdateStorageItemTypeCategoryDetailMutationError.message}
                            testID="data-StorageItemTypeCategoryDetailUpdateErrorMessage"
                        />
                    </View>
                    :
                    retrievedStorageItemTypeCategoryDetailLoading || adminUpdateStorageItemTypeCategoryDetailMutationLoading ?
                        <View testID="data-StorageItemTypeCategoryDetailItemLoading">
                            <Text>
                                <FormattedMessage id="StorageItemTypeCategoryDetail.loading" defaultMessage="Loading..." />
                            </Text>
                        </View>
                        :
                        <>
                            <View style={StorageItemTypeCategoryPageStyles.ViewParent} testID="data-StorageItemTypeCategoryDetail">
                                <View style={StorageItemTypeCategoryPageStyles.ViewChild}>
                                    <View style={StorageItemTypeCategoryPageStyles.ViewContentParent}>
                                        <View style={StorageItemTypeCategoryPageStyles.ViewTitleParent}>
                                            <Text style={StorageItemTypeCategoryPageStyles.TextTitle} testID="data-StorageItemTypeCategoryDetailTitle">
                                                {`${intl.formatMessage({
                                                    id: 'StorageItemTypeCategoryDetail.id',
                                                    defaultMessage: 'Category Details:',
                                                })}${''}`}
                                            </Text>
                                        </View>
                                        <View style={StorageItemTypeCategoryPageStyles.ViewTitleParent}>
                                            <View style={StorageItemTypeCategoryPageStyles.ViewDescription}>
                                                <FormattedMessage
                                                    id="StorageItemTypeCategoryDetail.name"
                                                    defaultMessage="Name: "
                                                />
                                                <TextInput
                                                    type="text"
                                                    ariaLabel="Name"
                                                    value={storageItemTypeCategory?.name || ''}
                                                    onChange={(value: string) => {
                                                        setStorageItemTypeCategory({ ...storageItemTypeCategory, name: value });
                                                    }}
                                                />
                                            </View>
                                        </View>
                                    </View>
                                </View>
                                <View
                                    style={{
                                        marginVertical: 10,
                                        marginHorizontal: 10,
                                        display: 'flex',
                                        flexDirection: 'row',
                                    }}
                                >
                                    <View style={{ flex: 1, marginRight: 10 }}>
                                        <Button
                                            title={intl.formatMessage({
                                                id: 'StorageItemTypeCategoryDetail.Cancel',
                                                defaultMessage: 'Cancel',
                                            })}
                                            onPress={handleBackClick}
                                        />
                                    </View>

                                    <View style={{ flex: 1 }}>
                                        <Button
                                            title={intl.formatMessage({
                                                id: 'StorageItemTypeCategoryDetail.Save',
                                                defaultMessage: 'Save',
                                            })}
                                            onPress={handleSaveStorageItemTypeCategoryDetailClick}
                                        />
                                    </View>
                                </View>
                            </View>
                        </>}
        </ScrollView >
    );
};

const StorageItemTypeCategoryPageStyles = StyleSheet.create({
    ViewParent: {
        display: 'flex',
        flexDirection: 'column',
    },
    ViewTabs: {
        marginVertical: 5,
        display: 'flex',
        flexDirection: 'row',
    },
    ViewContent: {
        paddingVertical: 5,
    },
    TextTab: {
        flex: 1,
        color: '#000',
        backgroundColor: '#D4D4D5',
        fontSize: 18,
        textAlign: 'center',
        fontWeight: '700',
    },

    ViewChild: {
        display: 'flex',
        flexDirection: 'row',
    },
    ViewImageParent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    RouteImage: {
        height: 40,
        width: 40,
        resizeMode: 'stretch',
    },
    ViewContentParent: {
        paddingHorizontal: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    ViewTitleParent: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 10,
    },
    ViewTitleChild: {},
    TextTitle: {
        fontSize: 18,
        fontWeight: 'bold',
    },
    ViewDescription: {
        marginTop: 10,
        fontWeight: 'bold',
    },
    TextDescription: { fontSize: 14 },
    ViewStatus: {},
    TextStatus: {
        fontSize: 12,
        fontWeight: '700',
    },
    ViewDate: {},
    TextDate: {
        fontSize: 12,
    },
    ViewErrorMessageParent: {
        marginTop: 5,
        marginBottom: 5,
    },
    TextContainerItem: {
        marginVertical: 2,
        display: 'flex',
        flexDirection: 'row',
        fontWeight: 'bold',
    },
    ViewImage: {
        paddingHorizontal: 5,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
});
