import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { Icon } from '@valet/ui-components';
import { RouteStopData, VisitSummaryData } from './Routing';
import { FormattedMessage, useIntl } from 'react-intl';

type VisitSummaryIconsProps = VisitSummaryData & {
    expectedDuration?: number;
    routeStop?: RouteStopData;
};

//TODO: Fragment

export const VisitSummaryIcons: React.FC<VisitSummaryIconsProps> = ({
    outGoing,
    inComing,
    multiplePeople,
    expectedDuration,
    routeStop,
}) => {
    const intl = useIntl();

    return (
        <View style={visitsSummaryIconsStyles.ViewContainer}>
            <View style={visitsSummaryIconsStyles.ViewDeliveryPickupContainer}>
                <View style={[visitsSummaryIconsStyles.ViewSummaryContainer, { flex: 1 }]}>
                    <View style={visitsSummaryIconsStyles.ViewIcon}>
                        <Icon icon="delivery" size="tiny" color="color-primary-600" />
                    </View>

                    <View style={visitsSummaryIconsStyles.ViewText}>
                        <Text style={{ fontWeight: '700' }}>{outGoing ?? 0}</Text>
                        <Text>
                            {' '}
                            <FormattedMessage
                                id="visitSummaryIcons.deliveries"
                                defaultMessage="deliveries"
                            />
                        </Text>
                    </View>
                </View>

                <View style={[visitsSummaryIconsStyles.ViewSummaryContainer, { flex: 1 }]}>
                    <View style={visitsSummaryIconsStyles.ViewIcon}>
                        <Icon icon="pickup" size="tiny" color="color-primary-600" />
                    </View>

                    <View style={visitsSummaryIconsStyles.ViewText}>
                        <Text style={{ fontWeight: '700' }}>{inComing ?? 0}</Text>
                        <Text>
                            {' '}
                            <FormattedMessage
                                id="visitSummaryIcons.pickups"
                                defaultMessage="pickups"
                            />
                        </Text>
                    </View>
                </View>

                {multiplePeople && (
                    <View style={[visitsSummaryIconsStyles.ViewSummaryContainer]}>
                        <Icon icon="multiplePeopleVisit" size="tiny" color="color-primary-600" />
                    </View>
                )}
            </View>

            <View style={visitsSummaryIconsStyles.ViewDeliveryPickupContainer}>
                {expectedDuration && (
                    <View style={[visitsSummaryIconsStyles.ViewSummaryContainer, { flex: 1 }]}>
                        <View style={visitsSummaryIconsStyles.ViewIcon}>
                            <Icon icon="visitDuration" size="tiny" color="color-primary-600" />
                        </View>

                        <View style={visitsSummaryIconsStyles.ViewText}>
                            <Text style={{ fontWeight: '700' }}>
                                {intl.formatRelativeTime(expectedDuration, 'minutes', {})}
                            </Text>
                        </View>
                    </View>
                )}

                {routeStop && (
                    <View style={[visitsSummaryIconsStyles.ViewSummaryContainer]}>
                        <Icon icon="routeStop" size="tiny" color="color-primary-600" />
                    </View>
                )}
            </View>
        </View>
    );
};

const visitsSummaryIconsStyles = StyleSheet.create({
    ViewContainer: { flexDirection: 'column', flexWrap: 'wrap' },
    ViewVisitsContainer: {},
    ViewDeliveryPickupContainer: {
        paddingTop: 3,
        display: 'flex',
        flexDirection: 'column',
    },
    ViewIcon: {
        paddingRight: 3,
    },
    ViewText: {
        paddingLeft: 3,
        display: 'flex',
        flexDirection: 'row',
    },
    ViewSummaryContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
});
