import React, { useMemo } from 'react';
import { Platform } from 'react-native';
import { useDrag } from 'react-dnd';
import { View, FlatList, ListRenderItem, Text, StyleSheet } from 'react-native';
import { Button, Card } from '@valet/ui-components';
import {
    TerritoryData,
    RouteData,
    VisitsSummaryData,
    TerritoryWithSummaryData,
    TerritoryWithSummaryAndColorData,
} from './Routing';
import { VisitsSummaryIcons } from './VisitsSummaryIcons';
import { useIntl } from 'react-intl';

type TerritoryListProps = {
    territories: TerritoryData[];
    territorySummaries: { [key: string]: VisitsSummaryData };
    territoryColors: { [key: string]: string };
    selectedTerritory?: TerritoryData['id'];
    onItemSelected?: (territoryId: TerritoryData['id']) => void;
    onItemUnselected?: (territoryId: TerritoryData['id']) => void;
    onItemAddToRoute?: (territoryId: TerritoryData['id'], routeId?: RouteData['id']) => void;
};

export const TerritoryList: React.FC<TerritoryListProps> = ({
    territories = [],
    territorySummaries = {},
    territoryColors = {},
    selectedTerritory,
    onItemSelected = () => undefined,
    onItemUnselected = () => undefined,
    onItemAddToRoute = () => undefined,
}) => {
    const territoriesWithSummaries = useMemo(
        () =>
            territories.map((territory) => ({
                ...territory,
                summary: territorySummaries[territory.id] ?? { deliveries: 0, pickups: 0 },
                color: territoryColors[territory.id],
            })),
        [territories, territorySummaries, territoryColors],
    );

    const extractItemKey: (item: TerritoryWithSummaryData) => string = (item) => `${item.id}`;

    const renderItem: ListRenderItem<TerritoryWithSummaryAndColorData> = ({ item }) => {
        return (
            <TerritoryListItem
                territoryInformation={item}
                territorySummary={item.summary}
                territoryColor={item.color}
                isSelected={selectedTerritory === item.id}
                onSelected={() => onItemSelected(item.id)}
                onUnselected={() => onItemUnselected(item.id)}
                onAddToRoute={() => onItemAddToRoute(item.id)}
            />
        );
    };

    return (
        <FlatList
            data={territoriesWithSummaries}
            extraData={[selectedTerritory, onItemSelected, onItemUnselected, onItemAddToRoute]}
            keyExtractor={extractItemKey}
            renderItem={renderItem}
        ></FlatList>
    );
};

type TerritoryListItemProps = {
    territoryInformation: TerritoryData;
    territorySummary: VisitsSummaryData;
    territoryColor?: string;
    isSelected?: boolean;
    onSelected?: () => void;
    onUnselected?: () => void;
    onAddToRoute?: () => void;
};

const TerritoryListItemContent: React.FC<TerritoryListItemProps> = ({
    territoryInformation,
    territorySummary,
    territoryColor,
    isSelected = false,
    onSelected = () => undefined,
    onUnselected = () => undefined,
    onAddToRoute = () => undefined,
}) => {
    const intl = useIntl();

    return (
        <View style={menuListStyles.ViewListItemContainer}>
            <Card
                style={{ flex: 1 }}
                disabled
                footer={() => (
                    <View style={menuListStyles.ViewListItemActions}>
                        <View style={{ flex: 1, marginRight: 2 }}>
                            <Button
                                title={intl.formatMessage({
                                    id: 'routing.addAllVisitsToRoute',
                                    defaultMessage: 'Add all visits to route',
                                })}
                                onPress={onAddToRoute}
                                size="tiny"
                            />
                        </View>

                        <View style={{ flex: 1, marginLeft: 2 }}>
                            {isSelected ? (
                                <Button
                                    title={intl.formatMessage({
                                        id: 'routing.hideVisits',
                                        defaultMessage: 'Hide visits',
                                    })}
                                    onPress={onUnselected}
                                    size="tiny"
                                />
                            ) : (
                                <Button
                                    title={intl.formatMessage({
                                        id: 'routing.showVisits',
                                        defaultMessage: 'Show visits',
                                    })}
                                    onPress={onSelected}
                                    size="tiny"
                                />
                            )}
                        </View>
                    </View>
                )}
            >
                <View style={menuListStyles.ViewListItemBody}>
                    <View
                        style={{
                            paddingVertical: 2,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}
                    >
                        {territoryColor && (
                            <View
                                style={[
                                    menuListStyles.ViewColorDot,
                                    { backgroundColor: territoryColor },
                                ]}
                            ></View>
                        )}

                        <Text style={{ fontSize: 16 }}>{territoryInformation?.description}</Text>
                    </View>

                    <View style={{ paddingVertical: 2 }}>
                        <VisitsSummaryIcons {...territorySummary} />
                    </View>
                </View>
            </Card>
        </View>
    );
};

const TerritoryListItem: React.FC<TerritoryListItemProps> = (props) => {
    const { territoryInformation } = props;

    const [, drag] = useDrag({ item: { type: 'territory', id: territoryInformation.id } });

    if (Platform.OS === 'web') {
        return (
            <div ref={drag}>
                <TerritoryListItemContent {...props} />
            </div>
        );
    }

    return <TerritoryListItemContent {...props} />;
};

const menuListStyles = StyleSheet.create({
    ViewListItemContainer: {
        flexDirection: 'row',
        paddingVertical: 4,
        paddingRight: 4,
    },
    ViewListItemBody: {
        flex: 1,
        flexDirection: 'column',
    },
    ViewListItemActions: {
        padding: 5,
        flexDirection: 'row',
        justifyContent: 'center',
        flex: 1,
        display: 'flex',
    },
    ViewColorDot: {
        margin: 0,
        padding: 0,
        marginRight: 3,
        width: '1em',
        height: '1em',
        borderRadius: 1000,
    },
});
