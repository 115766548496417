import { gql } from '@apollo/client';
import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Card, Message } from '@valet/ui-components';

import { FlatList, ScrollView, StyleSheet, Text, View } from 'react-native';
import { AdminVehicleFragment, useGetAdminVehiclesQuery } from '../../../../schema-types';
import { useHistory } from 'react-router-dom';

export const vehiclePageSize = 100;

const ADMIN_VEHICLES_FRAGMENT = gql`
    fragment adminVehicle on Vehicle {
        id
        description
        model
        isActive
    }
`;


export const RETRIEVE_ROUTES_QUERY = gql`
    query getAdminVehicles($pageSize: Int!, $cursor: String!) {
        vehicles(
            filter: {
                pageSize: $pageSize
                after: $cursor
            }
        ) {
            edges {
                cursor
                node {
                    id
                    ...adminVehicle
                }
            }
            pageInfo {
                cursor {
                    beforeCursor
                    afterCursor
                }
                hasNextPage
                hasPreviousPage
            }
        }
    }

    ${ADMIN_VEHICLES_FRAGMENT}
`;

type VehiclePageProps = {
    onVehicleSelect: (id: string) => void;
    onAddVehicleSelect: () => void
};

export const VehiclePage: React.FC<VehiclePageProps> = ({ onVehicleSelect, onAddVehicleSelect }) => {
    const history = useHistory();
    const intl = useIntl();

    const [isFetching, setIsFetching] = useState<boolean>(false);

    const {
        data: retrievedVehiclesData,
        loading: retrievedVehiclesLoading,
        error: retrievedVehiclesError,
        fetchMore: retrievedVehicleFetchMore,
    } = useGetAdminVehiclesQuery({
        variables: {
            pageSize: vehiclePageSize,
            cursor: '',
        },
    });

    const vehicleEdges = retrievedVehiclesData?.vehicles.edges ?? [];
    const vehicleData = useMemo(() => {
        return {
            vehicle: vehicleEdges?.map((itemEdge: any) => itemEdge.node) ?? [],
            hasNextPage: retrievedVehiclesData?.vehicles?.pageInfo?.hasNextPage ?? false,
            endCursor: retrievedVehiclesData?.vehicles?.pageInfo?.cursor.afterCursor ?? '',
        };
    }, [vehicleEdges, retrievedVehiclesData]);

    const handleLoadMoreItems = useCallback((): void => {
        if (vehicleData.hasNextPage && !isFetching) {
            setIsFetching(true);

            retrievedVehicleFetchMore({
                variables: {
                    pageSize: vehiclePageSize,
                    cursor: vehicleData.endCursor,
                },
            }).then(() => {
                setIsFetching(false);
            });
        }
    }, [isFetching, vehicleData, retrievedVehicleFetchMore]);

    useEffect(() => {
        if (!retrievedVehiclesError && vehicleData.hasNextPage) {
            handleLoadMoreItems();
        }
    }, [handleLoadMoreItems, vehicleData, retrievedVehiclesError]);

    const handleItemClick = useCallback(
        (id: string) => {
            onVehicleSelect(id);
        },
        [onVehicleSelect],
    );

    const handleAddClick = useCallback(
        () => {
            onAddVehicleSelect();
        },
        [onAddVehicleSelect],
    );

    const handleBacklClick = useCallback(async () => {
        history.push(`/admin`);
    }, [history]);

    return (
        <ScrollView>
            <View style={vehiclesPageStyles.ViewParent} testID="data-delivery">
                <View style={vehiclesPageStyles.ViewTabs}>
                    <View>
                        <View style={{ flex: 1, marginRight: 2 }}>
                            <Button
                                title={intl.formatMessage({
                                    id: 'vehicles.backButton',
                                    defaultMessage: 'Back',
                                })}
                                onPress={handleBacklClick}
                            />
                        </View>
                    </View>
                    <View>
                        <View style={{ flex: 1, marginRight: 20, marginBottom: 10 }}>
                            <Button
                                title={intl.formatMessage({
                                    id: 'vehicles.addNewVehicle',
                                    defaultMessage: '+ Add New Vehicle',
                                })}
                                onPress={handleAddClick}
                            />
                        </View>
                    </View>
                </View>
                {retrievedVehiclesLoading &&
                    (
                        <View testID="data-vehicleDetailItemLoading">
                            <Text>
                                <FormattedMessage
                                    id="vehicleDetail.loading"
                                    defaultMessage="Loading..."
                                />
                            </Text>
                        </View>
                    )
                }
                {retrievedVehiclesError &&
                    (
                        <View
                            style={vehiclesPageStyles.ViewErrorMessageParent}
                            testID="data-errorMessage"
                        >
                            <Message
                                type="negative"
                                header={intl.formatMessage({
                                    id: 'vehicleDetail.vehicleDetailErrorMessage',
                                    defaultMessage: 'Could not load detail for the vehicle - please try again',
                                })}
                                content={retrievedVehiclesError.message}
                                testID="data-vehicleDetailErrorMessage"
                            />
                        </View>
                    )
                }
                {!retrievedVehiclesLoading &&
                    !retrievedVehiclesData?.vehicles.pageInfo.hasNextPage &&
                    retrievedVehiclesData && (
                        <View style={vehiclesPageStyles.ViewContent}>
                            <FlatList
                                data={vehicleData.vehicle}
                                renderItem={({ item }) => (
                                    <VehicleItem
                                        key={item.id}
                                        vehicle={item}
                                        onItemClick={handleItemClick}
                                    />
                                )}
                                keyExtractor={(item) => item.id}
                            />
                        </View>
                    )}
            </View>
        </ScrollView>
    );
};


const vehiclesPageStyles = StyleSheet.create({
    ViewParent: {
        display: 'flex',
        flexDirection: 'column',
    },
    ViewTabs: {
        marginVertical: 5,
        display: 'flex',
        flexDirection: 'row',
    },
    ViewContent: {
        paddingVertical: 5,
    },
    TextTab: {
        flex: 1,
        color: '#000',
        backgroundColor: '#D4D4D5',
        fontSize: 18,
        textAlign: 'center',
        fontWeight: '700',
    },
    ViewErrorMessageParent: {
        marginTop: 5,
        marginBottom: 5,
    },
});

type VehicleItemProps = {
    vehicle: AdminVehicleFragment;
    onItemClick: (id: string) => void;
};

const VehicleItem: React.FC<VehicleItemProps> = ({ vehicle, onItemClick }) => {
    const intl = useIntl();

    return (
        <Card
            style={vehicleItemStyles.ViewParent}
            onPress={() => onItemClick(vehicle?.id || '')}
            testID="data-deliveryDetail"
        >
            <View style={vehicleItemStyles.ViewChild}>
                <View style={vehicleItemStyles.ViewContentParent}>
                    <View style={vehicleItemStyles.ViewTitleParent}>
                        <Text style={vehicleItemStyles.TextTitle} testID="data-vehicleItemDescription">
                            {`${intl.formatMessage({
                                id: 'vehicleItem.Description',
                                defaultMessage: 'Description: ',
                            })}`}
                        </Text>
                        <Text>{vehicle?.description}</Text>
                    </View>
                    <View style={vehicleItemStyles.ViewTitleParent}>
                        <Text style={vehicleItemStyles.TextTitle} testID="data-vehicleItemModel">
                            {`${intl.formatMessage({
                                id: 'vehicleItem.model',
                                defaultMessage: 'Model: ',
                            })}`}
                        </Text>
                        <Text>{vehicle?.model}</Text>
                    </View>
                </View>
            </View>
        </Card>
    );
};

const vehicleItemStyles = StyleSheet.create({
    ViewParent: {
        marginVertical: 3,
    },
    ViewChild: {
        display: 'flex',
        flexDirection: 'row',
    },
    ViewImageParent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    VehicleImage: {
        height: 40,
        width: 40,
        resizeMode: 'stretch',
    },
    ViewContentParent: {
        paddingHorizontal: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    ViewTitleParent: {
        display: 'flex',
        flexDirection: 'column',
    },
    ViewTitleChild: {},
    TextTitle: {
        fontSize: 18,
        fontWeight: 'bold',
    },
    ViewDescription: {},
    TextDescription: { fontSize: 14 },
    ViewStatus: {},
    TextStatus: {
        fontSize: 12,
        fontWeight: '700',
    },
    ViewDate: {},
    TextDate: {
        fontSize: 12,
    },
    ViewErrorMessageParent: {
        marginTop: 5,
        marginBottom: 5,
    },
});

