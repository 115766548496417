import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { gql } from '@apollo/client';
import { FormattedMessage, useIntl } from 'react-intl';
import { View, ScrollView, Text, StyleSheet, Platform } from 'react-native';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { addDays, startOfDay, endOfDay, addMinutes, parseISO } from 'date-fns';
import booleanContains from '@turf/boolean-contains';
import { point as turfPoint } from '@turf/helpers';
import { TerritoryDetails } from './TerritoryDetails';
import { TerritoryList } from './TerritoryList';
import { RouteList } from './RouteList';
import { SelectedVisitsListItem } from './SelectedVisitsList';
import { RouteDetails } from './RouteDetails';
import { SelectedVisitsDetails } from './SelectedVisitsDetails';
import { RoutingMap } from './Map';
import { Button, Card, Modal, Datepicker, Select, SelectItem } from '@valet/ui-components';
import {
    useRoutingVisitsQuery,
    useRoutingRoutesQuery,
    useRoutingAreaInformationQuery,
    useRoutingDeliveryWindowsQuery,
    useRoutingCreateRouteMutation,
    useRoutingAddStopsToRouteMutation,
    RoutingVisitInformationFragment,
    RoutingVisitSummaryInformationFragment,
    RoutingRouteInformationFragment,
    RoutingBuildingInformationFragment,
    RoutingTerritoryInformationFragment,
    RoutingDeliveryWindowInformationFragment,
    RoutingRoutesDocument,
    useRoutingRemoveStopFromRouteMutation,
} from '../../../schema-types';

export const ROUTING_VISIT_FRAGMENT = gql`
    fragment RoutingVisitInformation on Visit {
        id
        status
        startTime
        endTime
        expectedDuration
        customerAddress {
            id
            address1
            address2
            city
            zoneId
            countryId
            zip
            longitude
            latitude
            addressType
            territory {
                id
            }
        }
        customer {
            id
            email
            firstName
            lastName
            homePhone
            cellPhone
        }
        scheduleWindow {
            id
            startTime
            endTime
        }
        summary {
            ...RoutingVisitSummaryInformation
        }
        stop {
            id
            route {
                id
            }
            startTime
            endTime
        }
    }

    fragment RoutingVisitSummaryInformation on VisitSummary {
        inComing
        outGoing
        multiplePeople
    }
`;

export const ROUTING_VISITS_QUERY = gql`
    query RoutingVisits($cursor: String, $startDate: String, $endDate: String) {
        visits(
            filter: {
                pageSize: 100
                after: $cursor
                conditions: [
                    { field: "startTime", operator: GreaterthanOrEqualto, value: $startDate }
                    { field: "startTime", operator: Lesserthanorequalto, value: $endDate }
                ]
            }
        ) {
            pageInfo {
                hasNextPage
                hasPreviousPage
                cursor {
                    afterCursor
                    beforeCursor
                }
            }
            edges {
                cursor
                node {
                    id
                    ...RoutingVisitInformation
                }
            }
        }
    }

    ${ROUTING_VISIT_FRAGMENT}
`;

export const ROUTING_AREA_INFORMATION_QUERY = gql`
    query RoutingAreaInformation {
        areas {
            buildings {
                id
                ...RoutingBuildingInformation
            }
            territories {
                id
                ...RoutingTerritoryInformation
            }
        }
    }

    fragment RoutingBuildingInformation on Building {
        id
        description
    }

    fragment RoutingTerritoryInformation on Territory {
        id
        description
    }
`;

export const ROUTING_DELIVERY_WINDOWS_QUERY = gql`
    query RoutingDeliveryWindows($date: DateTime!) {
        areas {
            deliveryWindowsByDate(date: $date) {
                id
                ...RoutingDeliveryWindowInformation
            }
        }
    }

    fragment RoutingDeliveryWindowInformation on ScheduleWindow {
        id
        startTime
        endTime
    }
`;

export const ROUTING_ROUTES_QUERY = gql`
    query RoutingRoutes($cursor: String, $startDate: String, $endDate: String) {
        routes(
            filter: {
                pageSize: 100
                after: $cursor
                conditions: [
                    { field: "operationDate", operator: GreaterthanOrEqualto, value: $startDate }
                    { field: "operationDate", operator: Lesserthanorequalto, value: $endDate }
                ]
            }
        ) {
            pageInfo {
                hasNextPage
                hasPreviousPage
                cursor {
                    afterCursor
                    beforeCursor
                }
            }
            edges {
                cursor
                node {
                    id
                    ...RoutingRouteInformation
                }
            }
        }
    }

    fragment RoutingRouteInformation on Route {
        id
        description
        employees {
            id
        }
        vehicles {
            id
        }
    }
`;

export const ROUTING_CREATE_ROUTE_MUTATION = gql`
    mutation RoutingCreateRoute($date: DateTime!, $building: String!, $description: String) {
        routingCreateRoute(
            data: {
                operationDate: $date
                startBuildingId: $building
                endBuildingId: $building
                description: $description
            }
        ) {
            route {
                id
                description
                operationDate
            }
        }
    }
`;

export const ROUTING_ADD_STOP_TO_ROUTE_MUTATION = gql`
    mutation RoutingAddStopsToRoute($routeId: ID!, $visits: [RoutingAddStopVisitInput!]!) {
        routingAddStops(data: { routeId: $routeId, visits: $visits }) {
            routeStops {
                id
                route {
                    id
                }
                startTime
                visit {
                    ...RoutingVisitInformation
                }
            }
        }
    }

    ${ROUTING_VISIT_FRAGMENT}
`;

export const ROUTING_REMOVE_STOP_FROM_ROUTE_MUTATION = gql`
    mutation RoutingRemoveStopFromRoute($routeStopId: ID!) {
        routingRemoveStop(data: { routeStopId: $routeStopId }) {
            visit {
                ...RoutingVisitInformation
            }
            route {
                stops {
                    id
                    route {
                        id
                    }
                    startTime
                    visit {
                        ...RoutingVisitInformation
                    }
                }
            }
        }
    }

    ${ROUTING_VISIT_FRAGMENT}
`;

export type DeliveryWindowData = RoutingDeliveryWindowInformationFragment;
export type VisitData = RoutingVisitInformationFragment;
export type VisitSummaryData = RoutingVisitSummaryInformationFragment;
export type BuildingData = RoutingBuildingInformationFragment;
export type TerritoryData = RoutingTerritoryInformationFragment;
export type RouteData = RoutingRouteInformationFragment;

export type VisitsSummaryData = VisitSummaryData & {
    count: number;
};

export type TerritoryWithSummaryData = TerritoryData & { summary: VisitsSummaryData };
export type TerritoryWithSummaryAndColorData = TerritoryWithSummaryData & { color?: string };

export type RouteWithSummaryData = RouteData & { summary: VisitsSummaryData };

export type RouteStopData = {
    id: string;
    routeId: RouteData['id'];
    time: number;
};

export type RouteStopsData = {
    [visitId: string]: RouteStopData;
};

type SelectedSectionData =
    | undefined
    | {
          type: 'selected';
      }
    | {
          type: 'territory';
          id: TerritoryData['id'];
      }
    | {
          type: 'route';
          id: RouteData['id'];
      };

export type RoutingDragObject =
    | {
          type: 'selected';
      }
    | {
          type: 'territory';
          id: TerritoryData['id'];
      }
    | {
          type: 'visit';
          id: VisitData['id'];
      };

type RoutingPageProps = {};

const RoutingPage: React.FC<RoutingPageProps> = () => {
    const intl = useIntl();

    const [createRoute] = useRoutingCreateRouteMutation();
    const [addStopsToRoute] = useRoutingAddStopsToRouteMutation();
    const [removeStopFromRoute] = useRoutingRemoveStopFromRouteMutation();

    const [selectedDate, setSelectedDate] = useState<Date>(new Date());
    const [selectedDeliveryWindow, setSelectedDeliveryWindow] = useState<
        DeliveryWindowData['id'] | 'all'
    >('all');
    const [selectedSection, setSelectedSection] = useState<SelectedSectionData>();
    const [selectedVisits, setSelectedVisits] = useState<VisitData['id'][]>([]);

    const [deliveryWindows, setDeliveryWindows] = useState<DeliveryWindowData[]>([]);
    const [buildings, setBuildings] = useState<BuildingData[]>([]);
    const [territories, setTerritories] = useState<TerritoryData[]>([]);
    const [routes, setRoutes] = useState<RouteData[]>([]);
    const [routeStops, setRouteStops] = useState<RouteStopsData>({});
    const [visits, setVisits] = useState<VisitData[]>([]);

    useEffect(() => {
        setSelectedDeliveryWindow('all');
        setSelectedSection(undefined);
        setVisits([]);
    }, [selectedDate, setSelectedSection, setSelectedDeliveryWindow, setVisits]);

    const {
        data: areaInformationData,
        // loading: areaInformationLoading,
        // error: areaInformationError,
    } = useRoutingAreaInformationQuery();

    const {
        data: deliveryWindowsData,
        refetch: refetchDeliveryWindows,
        // loading: deliveryWindowsLoading,
        // error: deliveryWindowsError,
    } = useRoutingDeliveryWindowsQuery({
        variables: {
            date: selectedDate,
        },
    });

    useEffect(() => {
        refetchDeliveryWindows({
            date: selectedDate,
        });
    }, [selectedDate, refetchDeliveryWindows]);

    const {
        data: visitsData,
        refetch: refetchVisits,
        // error: visitsError,
        loading: visitsLoading,
        fetchMore: fetchMoreVisits,
    } = useRoutingVisitsQuery({
        variables: {
            startDate: startOfDay(selectedDate).toISOString(),
            endDate: endOfDay(selectedDate).toISOString(),
        },
    });

    useEffect(() => {
        if (!visitsLoading && visitsData?.visits.pageInfo.hasNextPage) {
            fetchMoreVisits({
                variables: {
                    cursor: visitsData?.visits.pageInfo.cursor.afterCursor,
                    startDate: startOfDay(selectedDate).toISOString(),
                    endDate: endOfDay(selectedDate).toISOString(),
                },
            });
        }
    }, [selectedDate, visitsData, visitsLoading, fetchMoreVisits]);

    useEffect(() => {
        refetchVisits({
            startDate: startOfDay(selectedDate).toISOString(),
            endDate: endOfDay(selectedDate).toISOString(),
        });
    }, [selectedDate, refetchVisits]);

    const {
        data: routesData,
        refetch: refetchRoutes,
        // error: routesError,
        loading: routesLoading,
        fetchMore: fetchMoreRoutes,
    } = useRoutingRoutesQuery({
        variables: {
            startDate: startOfDay(selectedDate).toISOString(),
            endDate: endOfDay(selectedDate).toISOString(),
        },
    });

    useEffect(() => {
        if (!routesLoading && routesData?.routes.pageInfo.hasNextPage) {
            fetchMoreRoutes({
                variables: {
                    cursor: routesData?.routes.pageInfo.cursor.afterCursor,
                    startDate: startOfDay(selectedDate).toISOString(),
                    endDate: endOfDay(selectedDate).toISOString(),
                },
            });
        }
    }, [selectedDate, routesData, routesLoading, fetchMoreRoutes]);

    useEffect(() => {
        refetchRoutes({
            startDate: startOfDay(selectedDate).toISOString(),
            endDate: endOfDay(selectedDate).toISOString(),
        });
    }, [selectedDate, refetchRoutes]);

    useEffect(() => {
        const area = areaInformationData?.areas?.length ? areaInformationData?.areas[0] : undefined;

        setBuildings(area?.buildings ?? []);
        setTerritories(area?.territories ?? []);
    }, [areaInformationData, setTerritories]);

    useEffect(() => {
        const area = deliveryWindowsData?.areas?.length ? deliveryWindowsData?.areas[0] : undefined;

        setDeliveryWindows(area?.deliveryWindowsByDate ?? []);
    }, [deliveryWindowsData, setDeliveryWindows]);

    useEffect(() => {
        setRoutes(routesData?.routes.edges.map((routeNode) => routeNode.node) ?? []);
    }, [routesData, setRoutes]);

    useEffect(() => {
        const visits = visitsData?.visits.edges.map((visitNode) => visitNode.node) ?? [];

        const routeStops: RouteStopsData = {};
        visits.forEach((visitNode) => {
            if (visitNode.stop) {
                routeStops[visitNode.id] = {
                    id: visitNode.stop.id,
                    routeId: visitNode.stop.route.id,
                    time: new Date(visitNode.stop.startTime).getTime(),
                };
            }
        });

        setVisits(visits);
        setRouteStops(routeStops);
    }, [visitsData, setVisits, setRouteStops]);

    const filteredVisits = useMemo(
        () =>
            selectedDeliveryWindow !== 'all'
                ? visits.filter((visit) => visit.scheduleWindow.id === selectedDeliveryWindow)
                : visits,
        [visits, selectedDeliveryWindow],
    );

    const [territoryColors, setTerritoryColors] = useState<{ [id: string]: string }>({});

    useEffect(() => {
        const colors = [
            '#00b894',
            '#fdcb6e',
            '#00cec9',
            '#e17055',
            '#0984e3',
            '#d63031',
            '#6c5ce7',
            '#e84393',
            '#55efc4',
            '#ffeaa7',
            '#81ecec',
            '#fab1a0',
            '#74b9ff',
            '#ff7675',
            '#a29bfe',
            '#fd79a8',
        ];

        setTerritoryColors((territoryColors) => {
            const newTerritoryColors: { [id: string]: string } = territoryColors
                ? { ...territoryColors }
                : {};

            territories.forEach((territory) => {
                if (newTerritoryColors[territory.id] === undefined) {
                    newTerritoryColors[territory.id] =
                        colors[Object.keys(newTerritoryColors).length % colors.length];
                }
            });

            return newTerritoryColors;
        });
    }, [territories, setTerritoryColors]);

    const territorySummaries = useMemo(() => {
        const defaultSummary: VisitsSummaryData = {
            count: 0,
            outGoing: 0,
            inComing: 0,
            multiplePeople: false,
        };
        const summaries: { [key: string]: VisitsSummaryData } = {};

        territories.forEach((territory) => {
            summaries[territory.id] = { ...defaultSummary };
        });

        filteredVisits.forEach((visit) => {
            if (summaries[visit.customerAddress.territory.id] !== undefined) {
                summaries[visit.customerAddress.territory.id] = {
                    ...summaries[visit.customerAddress.territory.id],
                    count: summaries[visit.customerAddress.territory.id].count + 1,
                    outGoing:
                        summaries[visit.customerAddress.territory.id].outGoing +
                        visit.summary.outGoing,
                    inComing:
                        summaries[visit.customerAddress.territory.id].inComing +
                        visit.summary.inComing,
                    multiplePeople:
                        summaries[visit.customerAddress.territory.id].multiplePeople ||
                        visit.summary.multiplePeople,
                };
            }
        });

        return summaries;
    }, [filteredVisits, territories]);

    const selectedVisitsSummary = useMemo(() => {
        const summary: VisitsSummaryData = {
            count: 0,
            outGoing: 0,
            inComing: 0,
            multiplePeople: false,
        };

        filteredVisits.forEach((visit) => {
            if (selectedVisits.includes(visit.id)) {
                summary.count = summary.count + 1;
                summary.outGoing = summary.outGoing + visit.summary.outGoing;
                summary.inComing = summary.inComing + visit.summary.inComing;
                summary.multiplePeople = summary.multiplePeople || visit.summary.multiplePeople;
            }
        });

        return summary;
    }, [filteredVisits, selectedVisits]);

    const routeSummaries = useMemo(() => {
        const defaultSummary: VisitsSummaryData = {
            count: 0,
            outGoing: 0,
            inComing: 0,
            multiplePeople: false,
        };
        const summaries: { [key: string]: VisitsSummaryData } = {};

        routes.forEach((route) => {
            summaries[route.id] = { ...defaultSummary };
        });

        filteredVisits.forEach((visit) => {
            if (routeStops[visit.id] !== undefined) {
                const routeId = routeStops[visit.id].routeId;

                if (summaries[routeId] !== undefined) {
                    summaries[routeId] = {
                        ...summaries[routeId],
                        count: summaries[routeId].count + 1,
                        outGoing: summaries[routeId].outGoing + visit.summary.outGoing,
                        inComing: summaries[routeId].inComing + visit.summary.inComing,
                        multiplePeople:
                            summaries[routeId].multiplePeople || visit.summary.multiplePeople,
                    };
                }
            }
        });

        return summaries;
    }, [routes, routeStops, filteredVisits]);

    const selectedTerritory = useMemo<TerritoryData | undefined>(
        () =>
            selectedSection?.type === 'territory'
                ? territories.find((territory) => territory.id === selectedSection.id)
                : undefined,
        [selectedSection, territories],
    );

    const selectedRoute = useMemo<RouteData | undefined>(
        () =>
            selectedSection?.type === 'route'
                ? routes.find((route) => route.id === selectedSection.id)
                : undefined,
        [selectedSection, routes],
    );

    const selectedTerritoryVisitsList = useMemo<
        (VisitData & { routeStop: RouteStopData })[]
    >(() => {
        const visitList = selectedTerritory
            ? filteredVisits.filter(
                  (visit) => visit.customerAddress.territory.id === selectedTerritory.id,
              )
            : [];
        return visitList.map((visit) => ({ ...visit, routeStop: routeStops[visit.id] }));
    }, [filteredVisits, routeStops, selectedTerritory]);

    const selectedVisitsList = useMemo<(VisitData & { routeStop: RouteStopData })[]>(() => {
        const visitList =
            selectedSection?.type === 'selected'
                ? filteredVisits.filter((visit) => selectedVisits.includes(visit.id))
                : [];
        return visitList.map((visit) => ({ ...visit, routeStop: routeStops[visit.id] }));
    }, [filteredVisits, routeStops, selectedSection, selectedVisits]);

    const selectedRouteStopsList = useMemo(() => {
        const result: (RouteStopData & { visit: VisitData })[] = [];

        if (selectedRoute === undefined) {
            return result;
        }

        const visitIds = Object.keys(routeStops);

        visitIds.forEach((visitId) => {
            if (routeStops[visitId]?.routeId === selectedRoute.id) {
                const visit = visits.find((visit) => visit.id === visitId);
                if (visit) {
                    result.push({
                        ...routeStops[visitId],
                        visit,
                    });
                }
            }
        });

        return result;
    }, [selectedRoute, routeStops, visits]);

    const highlightedVisits = useMemo(() => {
        if (selectedSection?.type === 'territory') {
            return filteredVisits
                .filter((visit) => visit.customerAddress.territory.id === selectedSection.id)
                .map((visit) => visit.id);
        }
        if (selectedSection?.type === 'route') {
            return filteredVisits
                .filter((visit) => routeStops[visit.id]?.routeId === selectedSection.id)
                .map((visit) => visit.id);
        }
        if (selectedSection?.type === 'selected') {
            return [...selectedVisits];
        }
        return [];
    }, [filteredVisits, selectedSection, selectedVisits, routeStops]);

    const handleSelectTerritory = useCallback(
        (territoryId: TerritoryData['id']) =>
            setSelectedSection({ type: 'territory', id: territoryId }),
        [setSelectedSection],
    );

    const handleSelectRoute = useCallback(
        (routeId: RouteData['id']) => setSelectedSection({ type: 'route', id: routeId }),
        [setSelectedSection],
    );

    const handleSelectSelectedVisits = useCallback(() => setSelectedSection({ type: 'selected' }), [
        setSelectedSection,
    ]);

    const handleUnselecSection = useCallback(() => setSelectedSection(undefined), [
        setSelectedSection,
    ]);

    const handleMapVisitClicked = useCallback(
        (visitId: VisitData['id']) => {
            setSelectedVisits((selectedVisits) => {
                return selectedVisits.includes(visitId)
                    ? [...selectedVisits.filter((i) => i !== visitId)]
                    : [...selectedVisits, visitId];
            });
        },
        [setSelectedVisits],
    );

    const handleMapAreaSelected = useCallback(
        (area) => {
            setSelectedVisits((selectedVisits) => {
                const visitsToSelect = visits
                    .filter(
                        (visit) =>
                            visit.customerAddress.longitude !== null &&
                            visit.customerAddress.longitude !== undefined &&
                            visit.customerAddress.latitude !== null &&
                            visit.customerAddress.latitude !== undefined &&
                            booleanContains(
                                area,
                                turfPoint([
                                    visit.customerAddress.longitude,
                                    visit.customerAddress.latitude,
                                ]),
                            ),
                    )
                    .map(({ id }) => id);

                return [...selectedVisits, ...visitsToSelect];
            });
        },
        [setSelectedVisits, visits],
    );

    const [modalVisible, setModalVisible] = useState<
        { resolve: (routeId: RouteData['id']) => void; reject: () => void } | undefined
    >();

    const selectRoute = useCallback((): Promise<RouteData['id']> => {
        return new Promise((resolve, reject) => {
            setModalVisible({ resolve, reject });
        });
    }, [setModalVisible]);

    const handleAddVisitToRoute = useCallback(
        async (visitId: VisitData['id'], routeId?: RouteData['id']) => {
            try {
                if (routeId === undefined) {
                    routeId = await selectRoute();
                }

                const visit = visits.find((visit) => visit.id === visitId);

                await addStopsToRoute({
                    variables: {
                        routeId,
                        visits: [
                            {
                                visitId,
                                startTime: visit?.startTime ?? selectedDate,
                                endTime: addMinutes(
                                    (visit?.startTime
                                        ? parseISO(`${visit?.startTime}`)
                                        : undefined) ?? selectedDate,
                                    visit?.expectedDuration ?? 30,
                                ),
                            },
                        ],
                    },
                });
            } catch (error) {
                //TODO: Implement proper error management
                alert(error);
            }
        },
        [selectRoute, addStopsToRoute, visits, selectedDate],
    );

    const handleRemoveVisitFromRoute = useCallback(
        async (routeStopId: RouteStopData['id']) => {
            try {
                await removeStopFromRoute({
                    variables: {
                        routeStopId,
                    },
                });
            } catch (error) {
                //TODO: Implement proper error management
                alert(error);
            }
        },
        [removeStopFromRoute],
    );

    const handleAddTerritoryToRoute = useCallback(
        async (territoryId: TerritoryData['id'], routeId?: RouteData['id']) => {
            try {
                if (routeId === undefined) {
                    routeId = await selectRoute();
                    if (routeId === '-1') return;
                }

                const visitsToAdd = filteredVisits.filter(
                    (visit) => visit.customerAddress.territory.id === territoryId,
                );

                await addStopsToRoute({
                    variables: {
                        routeId,
                        visits: visitsToAdd.map((visit) => ({
                            visitId: visit.id,
                            startTime: visit?.startTime ?? selectedDate,
                            endTime: addMinutes(
                                (visit?.startTime ? parseISO(`${visit?.startTime}`) : undefined) ??
                                    selectedDate,
                                visit?.expectedDuration ?? 30,
                            ),
                        })),
                    },
                });
            } catch (error) {
                //TODO: Implement proper error management
                alert(error);
            }
        },
        [addStopsToRoute, selectRoute, selectedDate, filteredVisits],
    );

    const handleAddSelectedToRoute = useCallback(
        async (routeId?: RouteData['id']) => {
            try {
                if (routeId === undefined) {
                    routeId = await selectRoute();
                    if (routeId === '-1') return;
                }

                const visitsToAdd = selectedVisits
                    .map((visitId) => visits.find((visit) => visit.id === visitId))
                    .filter(
                        (visit): visit is RoutingVisitInformationFragment =>
                            visit !== undefined && visit !== null,
                    );

                await addStopsToRoute({
                    variables: {
                        routeId,
                        visits: visitsToAdd.map((visit) => ({
                            visitId: visit.id,
                            startTime: visit?.startTime ?? selectedDate,
                            //TODO: Remove and calculate on the backend
                            endTime: addMinutes(
                                (visit?.startTime ? parseISO(`${visit?.startTime}`) : undefined) ??
                                    selectedDate,
                                visit?.expectedDuration ?? 30,
                            ),
                        })),
                    },
                });
            } catch (error) {
                //TODO: Implement proper error management
                alert(error);
            }
        },
        [addStopsToRoute, selectRoute, selectedVisits, selectedDate, visits],
    );

    const handleRouteSelection = useCallback(
        (routeId: RouteData['id']) => {
            modalVisible?.resolve(routeId);
            setModalVisible(undefined);
        },
        [modalVisible, setModalVisible],
    );

    const handleRouteSelectionCancel = useCallback(() => {
        modalVisible?.resolve('-1');
        setModalVisible(undefined);
    }, [modalVisible, setModalVisible]);

    const handleAddRoute = useCallback(async () => {
        try {
            const result = await createRoute({
                variables: {
                    date: selectedDate,
                    building: buildings.length ? buildings[0].id : '',
                    description: 'test',
                },
                refetchQueries: [
                    {
                        query: RoutingRoutesDocument,
                        variables: {
                            startDate: startOfDay(selectedDate).toISOString(),
                            endDate: endOfDay(selectedDate).toISOString(),
                        },
                    },
                ],
                awaitRefetchQueries: true,
            });

            return result.data?.routingCreateRoute.route.id ?? undefined;
        } catch (error) {
            //TODO: Implement proper error management
            alert(error);
        }
    }, [createRoute, selectedDate, buildings]);

    return (
        <View testID="data-routingPage" style={{ flexDirection: 'row', flex: 1, height: '100%' }}>
            <Modal
                visible={modalVisible !== undefined}
                onBackdropPress={handleRouteSelectionCancel}
            >
                <Card style={styles.CardModal} disabled>
                    <View>
                        <Text style={styles.TextSelectRoute}>
                            <FormattedMessage
                                id="routing.selectRoute"
                                defaultMessage="Select Route"
                            />
                        </Text>

                        {routes.map((route) => (
                            <View style={styles.ViewRouteOption} key={route.id}>
                                <Button
                                    key={route.id}
                                    onPress={() => handleRouteSelection(route.id)}
                                    title={route.description ?? ''}
                                />
                            </View>
                        ))}
                        <View style={styles.ViewRouteOption}>
                            <Button
                                onPress={async () => {
                                    const routeId = await handleAddRoute();
                                    routeId && handleRouteSelection(routeId);
                                }}
                                title={intl.formatMessage({
                                    id: 'routing.newRoute',
                                    defaultMessage: 'New Route',
                                })}
                            />
                        </View>
                        <View style={styles.ViewRouteOption}>
                            <Button
                                onPress={handleRouteSelectionCancel}
                                appearance="ghost"
                                title={intl.formatMessage({
                                    id: 'routing.selectRouteCancel',
                                    defaultMessage: 'Cancel',
                                })}
                            />
                        </View>
                    </View>
                </Card>
            </Modal>

            <ScrollView
                testID="data-routingNavigationMenu"
                style={{ padding: 10, width: 400, maxWidth: 400, flexGrow: 0 }}
            >
                <View
                    style={{
                        flex: 1,
                    }}
                >
                    <View
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'row',
                            flex: 1,
                            marginBottom: 5,
                        }}
                    >
                        <View style={{ marginRight: 3, marginTop: -3 }}>
                            <Button
                                onPress={() =>
                                    setSelectedDate((selectedDate) => addDays(selectedDate, -1))
                                }
                                title={'<'}
                            />
                        </View>

                        <View style={{ flex: 1 }}>
                            <Datepicker date={selectedDate} onSelect={setSelectedDate} />
                        </View>

                        <View style={{ marginLeft: 3, marginTop: -3 }}>
                            <Button
                                onPress={() =>
                                    setSelectedDate((selectedDate) => addDays(selectedDate, 1))
                                }
                                title={'>'}
                            />
                        </View>
                    </View>

                    <View style={{ marginBottom: 5 }}>
                        <Select
                            itemsDisplayValues={[
                                intl.formatMessage({
                                    id: 'routing.all',
                                    defaultMessage: 'All',
                                }),
                                ...deliveryWindows.map(
                                    (deliveryWindow) =>
                                        `${intl.formatTime(
                                            deliveryWindow.startTime,
                                        )} - ${intl.formatTime(deliveryWindow.endTime)}`,
                                ),
                            ]}
                            currentIndex={
                                selectedDeliveryWindow === 'all'
                                    ? 0
                                    : deliveryWindows.findIndex(
                                          (deliveryWindow) =>
                                              deliveryWindow.id === selectedDeliveryWindow,
                                      )
                            }
                            onSelect={(index) => {
                                index === 0
                                    ? setSelectedDeliveryWindow('all')
                                    : setSelectedDeliveryWindow(deliveryWindows[index].id);
                            }}
                        >
                            {[
                                <SelectItem
                                    key="all"
                                    title={intl.formatMessage({
                                        id: 'routing.all',
                                        defaultMessage: 'All',
                                    })}
                                />,
                                ...deliveryWindows.map((deliveryWindow) => (
                                    <SelectItem
                                        key={deliveryWindow.id}
                                        title={`${intl.formatTime(
                                            deliveryWindow.startTime,
                                        )} - ${intl.formatTime(deliveryWindow.endTime)}`}
                                    />
                                )),
                            ]}
                        </Select>
                    </View>
                </View>

                <Text>Territories</Text>
                <View style={{ marginBottom: 5 }}>
                    <TerritoryList
                        territories={territories}
                        territorySummaries={territorySummaries}
                        territoryColors={territoryColors}
                        selectedTerritory={selectedTerritory?.id}
                        onItemSelected={handleSelectTerritory}
                        onItemUnselected={handleUnselecSection}
                        onItemAddToRoute={handleAddTerritoryToRoute}
                    />
                    <SelectedVisitsListItem
                        selectedVisitsSummary={selectedVisitsSummary}
                        isSelected={selectedSection?.type === 'selected'}
                        onSelected={handleSelectSelectedVisits}
                        onUnselected={handleUnselecSection}
                        onAddToRoute={handleAddSelectedToRoute}
                    />
                </View>

                <Text>Routes</Text>
                <View style={{ marginBottom: 5 }}>
                    <RouteList
                        routes={routes}
                        routeSummaries={routeSummaries}
                        selectedRoute={selectedRoute?.id}
                        onItemSelected={handleSelectRoute}
                        onItemUnselected={handleUnselecSection}
                        onTerritoryDroppedOnRoute={handleAddTerritoryToRoute}
                        onSelectedDroppedOnRoute={handleAddSelectedToRoute}
                        onVisitDroppedOnRoute={handleAddVisitToRoute}
                    />
                </View>
            </ScrollView>

            {selectedSection?.type !== undefined && (
                <ScrollView
                    testID="data-routingDetailsPage"
                    style={{
                        padding: 10,
                        width: 350,
                        maxWidth: 350,
                        backgroundColor: '',
                        flexGrow: 0,
                    }}
                >
                    {selectedTerritory && (
                        <TerritoryDetails
                            territory={selectedTerritory}
                            visits={selectedTerritoryVisitsList}
                            onAddVisitToRoute={handleAddVisitToRoute}
                        />
                    )}
                    {selectedSection.type === 'selected' && (
                        <SelectedVisitsDetails
                            visits={selectedVisitsList}
                            onAddVisitToRoute={handleAddVisitToRoute}
                        />
                    )}
                    {selectedRoute && (
                        <RouteDetails
                            route={selectedRoute}
                            routeStops={selectedRouteStopsList}
                            onRemoveVisitFromRoute={handleRemoveVisitFromRoute}
                        />
                    )}
                </ScrollView>
            )}

            <View testID="data-routingMap" style={{ flex: 1, width: 'auto', backgroundColor: '' }}>
                <RoutingMap
                    visits={filteredVisits.map((visit) => ({
                        ...visit,
                        routeStop: routeStops[visit.id],
                        groupColor: territoryColors[visit.customerAddress.territory.id],
                    }))}
                    selectedVisits={selectedVisits}
                    hightlightedVisits={highlightedVisits}
                    onVisitClicked={handleMapVisitClicked}
                    onAreaSelected={handleMapAreaSelected}
                />
            </View>
        </View>
    );
};

const RoutingPageWithDnd: React.FC<RoutingPageProps> = (props = {}) => (
    <DndProvider backend={HTML5Backend}>
        <RoutingPage {...props} />
    </DndProvider>
);

const styles = StyleSheet.create({
    CardModal: {
        width: 400,
    },
    TextSelectRoute: {
        fontSize: 18,
    },
    ViewRouteOption: {
        marginVertical: 3,
    },
});

export default Platform.select({
    web: RoutingPageWithDnd,
    default: RoutingPage,
});
