import React from 'react';
import { ScrollView } from 'react-native';
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom';
import { DeliveryRoutesPage } from './DeliveryRoutesPage';
import { DeliveryRouteDetailPage } from './DeliveryRouteDetailPage';
import { DeliveryPickupProcess } from './DeliveryPickupProcess';
import { DeliveryVisitDetail } from './DeliveryVisitDetail';
import { DeliveryDeliverItemProcess } from './DeliveryDeliverItemProcess';
import { DeliveryDeliverEmptyContainerProcess } from './DeliveryDeliverEmptyContainerProcess';
import { DeliveryPickupEmptyContainerProcess } from './DeliveryPickupEmptyContainerProcess';
import { DeliveryCompleteProcess } from './DeliveryCompleteProcess';
import { DeliveryPickupItemDetail } from './DeliveryPickupItemDetail';

type DeliveryRoutesProps = {};

export const DeliveryRoutes: React.FC<DeliveryRoutesProps> = () => {
    const { url } = useRouteMatch();
    const history = useHistory();

    const handleRouteSelect = (routeId: string): void => {
        history.push(`/delivery/${routeId}`);
    };

    const handleRouteStopItemSelect = (routeId: string, visitId: string): void => {
        history.push(`/delivery/${routeId}/${visitId}`);
    };

    const handleItemSelected = (routeId: String, visitId: String, requestId: string) => {
        history.push(`/delivery/${routeId}/${visitId}/${requestId}/detail`);
    };

    const handleGoBackPress = (routeId: string): void => {
        history.push(`/delivery/${routeId}`);
    };

    return (
        <ScrollView>
            <Switch>
                <Route path={`${url}/:routeId/:visitId/pickup`} exact>
                    <DeliveryPickupProcess onItemSelected={handleItemSelected} />
                </Route>
                <Route path={`${url}/:routeId/:visitId/:requestId/detail`} exact>
                    <DeliveryPickupItemDetail />
                </Route>

                <Route path={`${url}/:routeId/:visitId/deliverItem`} exact>
                    <DeliveryDeliverItemProcess />
                </Route>

                <Route path={`${url}/:routeId/:visitId/deliverEmptyContainer`} exact>
                    <DeliveryDeliverEmptyContainerProcess />
                </Route>

                <Route path={`${url}/:routeId/:visitId/pickupEmptyContainer`} exact>
                    <DeliveryPickupEmptyContainerProcess />
                </Route>

                <Route path={`${url}/:routeId/:visitId/complete`} exact>
                    <DeliveryCompleteProcess />
                </Route>
                <Route path={`${url}/:routeId`} exact>
                    <DeliveryRouteDetailPage onRouteStopItemSelect={handleRouteStopItemSelect} />
                </Route>

                <Route path={`${url}/:routeId/:visitId`} exact>
                    <DeliveryVisitDetail onHandleGoBackPress={handleGoBackPress} />
                </Route>

                <Route path={`${url}`}>
                    <DeliveryRoutesPage onRouteSelect={handleRouteSelect} />
                </Route>
            </Switch>
        </ScrollView>
    );
};
