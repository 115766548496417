import React from 'react';
import { ScrollView } from 'react-native';
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom';
import { TruckUnloadingVehiclesPage } from './TruckUnloadingVehiclesPage';
import { TruckUnloadingProcessItem } from './TruckUnloadingProcessItem';

type TruckUnloadingRoutesProps = {};

export type RouteStopType = {
    id: string;
    routeNumber: number;
    sequence?: number;
    routeId?: string;
    visitId: string;
};

export const TruckUnloadingRoutes: React.FC<TruckUnloadingRoutesProps> = () => {
    const { url } = useRouteMatch();
    const history = useHistory();

    const handleTruckSelect = (vehicleId: string): void => {
        history.push(`/truckUnloading/${vehicleId}`);
    };

    const handleTruckUnloaded = (): void => {
        history.push(`/truckUnloading`);
    };

    return (
        <ScrollView>
            <Switch>
                <Route path={`${url}/:vehicleId`} exact>
                    <TruckUnloadingProcessItem onTruckUnloaded={handleTruckUnloaded} />
                </Route>

                <Route path={`${url}`}>
                    <TruckUnloadingVehiclesPage onTruckSelect={handleTruckSelect} />
                </Route>
            </Switch>
        </ScrollView>
    );
};
