import React, { useState, useEffect, useCallback, ReactElement } from 'react';
import { gql } from '@apollo/client';

import { Button, Message, ErrorMessage, Icon, TextInput } from '@valet/ui-components';
import {
    View,
    Text,
    Image,
    TouchableWithoutFeedback,
    TouchableOpacity,
    StyleSheet,
} from 'react-native';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    usePickListPickItemMutation,
    usePickListExcludeItemMutation,
    PicklistProcessPicklistItemFragment,
    RequestStatus,
} from '../../../../schema-types';

function getMessage(
    currentItem: number,
    pickListItems: PicklistProcessPicklistItemFragment[],
): ErrorMessage | undefined {
    let nextItemNum = 0;
    let i = currentItem;

    while (i < pickListItems.length - 1) {
        if (pickListItems[i].storageItem.location === pickListItems[i + 1].storageItem.location) {
            nextItemNum++;
            i++;
        } else {
            break;
        }
    }

    if (nextItemNum > 0) {
        const msg: ErrorMessage = {
            type: 'info',
            header: 'Scan Item',
            content: `Next ${nextItemNum} item(s) in same location`,
        };

        return msg;
    } else {
        return undefined;
    }
}

export const PICKLIST_PICK_ITEM_MUTATION = gql`
    mutation pickListPickItem($pickListItemId: String!, $targetLocationName: String!) {
        pickingPickItem(
            data: { pickListItemId: $pickListItemId, targetLocationName: $targetLocationName }
        ) {
            pickListItem {
                id
                request {
                    id
                    status
                }
            }
        }
    }
`;

export const PICKLIST_EXCLUDE_ITEM_MUTATION = gql`
    mutation pickListExcludeItem($pickListItemId: String!) {
        pickingExcludeItem(data: { pickListItemId: $pickListItemId }) {
            pickListItem {
                id
                request {
                    id
                    status
                }
            }
        }
    }
`;

export const PICKLIST_PROCESS_PICKLIST_ITEM_FRAGMENT = gql`
    fragment PicklistProcessPicklistItem on PickListItem {
        id
        request {
            id
            status
        }
        storageItem {
            id
            barcode
            location {
                description
            }
            storageItemType {
                id
                name
                description
            }
        }
    }
`;

type PickListProcessProps = {
    pickListItems: PicklistProcessPicklistItemFragment[];
    onGoBackPress: () => void;
};

export const PickListProcess: React.FC<PickListProcessProps> = ({
    pickListItems,
    onGoBackPress,
}) => {
    const intl = useIntl();

    const [currentItem, setCurrentItem] = useState<number>(0);
    const [message, setMessage] = useState<ErrorMessage | undefined>(undefined);
    const [expandPickList, setExpandPickList] = useState<boolean>(false);
    const [expandBarcodeManualEntry, setExpandBarcodeManualEntry] = useState<boolean>(false);
    const [barcodeEntryValue, setBarcodeEntryValue] = useState<string>('');
    const [excludeItemActive, setExcludeItemActive] = useState<boolean>(false);
    const [excludeItemNote, setExcludeItemNote] = useState<{
        item: number | undefined;
        value: string;
    }>({ item: undefined, value: '' });

    const [pickListPickItem] = usePickListPickItemMutation();

    const [pickListExcludeItem] = usePickListExcludeItemMutation();

    useEffect(() => {
        const retrievedMessage = getMessage(currentItem, pickListItems);
        if (
            (message === undefined && retrievedMessage !== undefined) ||
            (message !== undefined && retrievedMessage === undefined)
        ) {
            return setMessage(retrievedMessage);
        }

        if (message !== undefined && retrievedMessage !== undefined) {
            if (retrievedMessage.content !== message.content) {
                return setMessage(retrievedMessage);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pickListItems, currentItem]);

    const handleNextPress = useCallback((): void => {
        setCurrentItem(currentItem + 1);
    }, [currentItem]);

    const handlePreviousPress = useCallback((): void => {
        setCurrentItem(currentItem - 1);
    }, [setCurrentItem, currentItem]);

    const handleExcludePress = useCallback((): void => {
        // Remove note value if the user moves on to another item
        if (currentItem !== excludeItemNote.item) {
            setExcludeItemNote({ item: currentItem, value: '' });
        }

        setExcludeItemActive(!excludeItemActive);
    }, [currentItem, excludeItemNote, setExcludeItemNote, excludeItemActive, setExcludeItemActive]);

    const handleExcludeItemNoteValueChange = useCallback(
        (value: string) => {
            setExcludeItemNote({ ...excludeItemNote, value: value });
        },
        [excludeItemNote],
    );

    const handleExcludeItemConfirmationPress = useCallback(() => {
        pickListExcludeItem({ variables: { pickListItemId: pickListItems[currentItem].id } })
            .then(() => {
                setExcludeItemActive(false);

                setCurrentItem(currentItem + 1);
            })
            .catch((err) => {
                setMessage({
                    type: 'negative',
                    header: intl.formatMessage({
                        id: 'pickListProcess.messageExcludeItemErrorHeader',
                        defaultMessage: 'There was an error',
                    }),
                    content: intl.formatMessage(
                        {
                            id: 'pickListProcess.messageExcludeItemErrorContent',
                            defaultMessage: '{err}',
                        },
                        { err: err.message },
                    ),
                });
            });
    }, [intl, pickListExcludeItem, currentItem, pickListItems]);

    const handleGoBackPress = useCallback((): void => {
        onGoBackPress();
    }, [onGoBackPress]);

    const handleExpandPickListPress = useCallback((): void => {
        setExpandPickList(!expandPickList);
    }, [setExpandPickList, expandPickList]);

    const handleManualScanCameraPress = useCallback((): void => {
        console.log('Pressed Manual Scan Camera Icon');
    }, []);

    const handleManualScanKeyboardPress = useCallback((): void => {
        setExpandBarcodeManualEntry(!expandBarcodeManualEntry);
    }, [expandBarcodeManualEntry]);

    const handleManualScanSubmit = useCallback((): void => {
        setMessage(undefined);
        if (barcodeEntryValue === pickListItems[currentItem].storageItem.barcode) {
            pickListPickItem({
                variables: {
                    pickListItemId: pickListItems[currentItem].id,
                    targetLocationName: 'location 1',
                },
            })
                .then(() => {
                    if (currentItem === pickListItems.length - 1) {
                        onGoBackPress();
                    } else {
                        setCurrentItem(currentItem + 1);
                        setBarcodeEntryValue('');
                        setExpandBarcodeManualEntry(false);
                    }
                })
                .catch((err) => {
                    setMessage({
                        type: 'negative',
                        header: intl.formatMessage({
                            id: 'pickListProcess.messageManualScanErrorHeader',
                            defaultMessage: 'There was an error',
                        }),
                        content: intl.formatMessage(
                            {
                                id: 'pickListProcess.messageManualScanErrorContent',
                                defaultMessage: '{err}',
                            },
                            { err: err.message },
                        ),
                    });
                });
        } else {
            setMessage({
                type: 'warning',
                header: intl.formatMessage({
                    id: 'pickListProcess.messageInvalidBarcodeErrorHeader',
                    defaultMessage: 'Incorrect barcode',
                }),
                content: intl.formatMessage(
                    {
                        id: 'pickListProcess.messageInvalidBarcodeErrorContent',
                        defaultMessage: `{barcodeEntryValue} does not match the barcode in file for this item.`,
                    },
                    { barcodeEntryValue: barcodeEntryValue },
                ),
            });
        }
    }, [intl, barcodeEntryValue, currentItem, onGoBackPress, pickListItems, pickListPickItem]);

    const handleItemPress = useCallback(
        (index: number) => {
            if (index !== currentItem) {
                setCurrentItem(index);
                setExpandPickList(false);
            }
        },
        [currentItem],
    );

    if (excludeItemActive) {
        return (
            <ExcludeItem
                noteValue={excludeItemNote.value}
                onNoteValueChange={handleExcludeItemNoteValueChange}
                onGoBackPress={handleExcludePress}
                onConfirmExclusionPress={handleExcludeItemConfirmationPress}
            />
        );
    }

    return (
        <View style={pickListProcessStyles.ViewParent} testID="data-pickListProcess">
            <View style={{ marginVertical: 5 }}>
                <Button
                    onPress={handleGoBackPress}
                    title={intl.formatMessage({
                        id: 'pickListProcess.backButton',
                        defaultMessage: 'Go Back',
                    })}
                    testID="data-pickListProcessBackButton"
                />
            </View>

            {!expandPickList && message !== undefined && (
                <Message
                    type={message.type}
                    header={message.header}
                    content={message.content}
                    testID="data-pickListProcessMessage"
                />
            )}

            {expandPickList ? (
                <View style={pickListProcessStyles.ViewExpandPickList}>
                    <ExpandPickList
                        pickListItems={pickListItems}
                        onItemPress={handleItemPress}
                        currentItem={currentItem}
                    />
                </View>
            ) : (
                <View
                    style={pickListProcessStyles.ViewPickListItem}
                    testID="data-pickListProcessItemContainer"
                >
                    <PickListItem details={pickListItems[currentItem]} />
                </View>
            )}

            <View
                style={pickListProcessStyles.ViewActionsGroup}
                testID="data-pickListProcessActionsContainer"
            >
                {expandBarcodeManualEntry && (
                    <View
                        style={{
                            padding: 3,
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                        testID="data-pickListProcessManualBarcodeEntryContainer"
                    >
                        <View style={{ flex: 1, marginRight: 5 }}>
                            <TextInput
                                type="text"
                                onChange={(e) => setBarcodeEntryValue(e)}
                                value={barcodeEntryValue}
                                ariaLabel={intl.formatMessage({
                                    id: 'pickListProcessManualBarcodeEntryPlaceholderText',
                                    defaultMessage: 'Enter item barcode',
                                })}
                                placeholder={intl.formatMessage({
                                    id: 'pickListProcessManualBarcodeEntryPlaceholderText',
                                    defaultMessage: 'Enter item barcode',
                                })}
                                testID="data-pickListProcessManualBarcodeEntryInput"
                            />
                        </View>

                        <View style={{ marginLeft: 5 }}>
                            <Button
                                onPress={handleManualScanSubmit}
                                title={intl.formatMessage({
                                    id: 'pickListProcessScanButton',
                                    defaultMessage: 'SCAN',
                                })}
                                testID="data-pickListProcessManualBarcodeEntrySubmitButton"
                            />
                        </View>
                    </View>
                )}

                <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <View style={pickListProcessStyles.ViewExpandPickListText}>
                        <Text
                            onPress={handleExpandPickListPress}
                            style={pickListProcessStyles.TextActionList}
                            testID="data-pickListProcessActionToggleList"
                        >
                            <FormattedMessage
                                id="pickListProcess.expandPickListText"
                                defaultMessage={`{state} full list`}
                                values={{ state: expandPickList ? 'Hide' : 'Show' }}
                            />
                        </Text>
                    </View>

                    <View style={pickListProcessStyles.ViewManualScanIcons}>
                        <TouchableOpacity
                            onPress={handleManualScanCameraPress}
                            style={[{ marginRight: 5 }]}
                            testID="data-pickListProcessActionCamera"
                        >
                            <Icon icon="barcodeCamera" />
                        </TouchableOpacity>

                        <TouchableOpacity
                            onPress={handleManualScanKeyboardPress}
                            style={[{ marginLeft: 5 }]}
                            testID="data-pickListProcessActionKeyboard"
                        >
                            <Icon icon="barcodeKeyboard" />
                        </TouchableOpacity>
                    </View>
                </View>

                <View style={pickListProcessStyles.ViewActions}>
                    <View style={pickListProcessStyles.ViewNavigationButtons}>
                        <View style={{ flex: 1 }}>
                            <Button
                                title="<"
                                onPress={handlePreviousPress}
                                disabled={currentItem === 0}
                                testID="data-pickListProcessActionPrevious"
                            />
                        </View>

                        <View style={{ flex: 1 }}>
                            <Text style={pickListProcessStyles.TextCurrentItemNumber}>
                                {currentItem + 1}/{pickListItems.length}
                            </Text>
                        </View>

                        <View style={{ flex: 1 }}>
                            <Button
                                disabled={currentItem === pickListItems.length - 1}
                                title=">"
                                onPress={handleNextPress}
                                testID="data-pickListProcessActionNext"
                            />
                        </View>
                    </View>

                    <View style={{ flex: 1, marginLeft: 5 }}>
                        <Button
                            disabled={pickListItems[currentItem].request?.status === 'EXCLUDED'}
                            title={intl.formatMessage({
                                id: 'pickListProcess.excludeButtonText',
                                defaultMessage: 'Exclude',
                            })}
                            onPress={handleExcludePress}
                            testID="data-pickListProcessActionExclude"
                        />
                    </View>
                </View>
            </View>
        </View>
    );
};

const pickListProcessStyles = StyleSheet.create({
    ViewParent: {
        height: '100%',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    ViewActionsGroup: {
        marginVertical: 5,
        display: 'flex',
        flexDirection: 'column',
        borderTopColor: '#D4D4D5',
        borderTopWidth: 1,
    },
    ViewExpandPickList: {
        flex: 1,
        paddingVertical: 5,
        display: 'flex',
        overflowY: 'scroll',
        overflowX: 'none',
    },
    ViewExpandPickListText: {
        marginVertical: 5,
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
    },
    ViewManualScanIcons: {
        marginVertical: 5,
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    ViewActions: {
        marginVertical: 5,
        display: 'flex',
        flexDirection: 'row',
    },
    ViewNavigationButtons: {
        flex: 1,
        marginRight: 5,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    ViewPickListItem: {
        flex: 1,
        paddingVertical: 5,
        display: 'flex',
        overflowY: 'scroll',
        overflowX: 'none',
    },
    TextActionList: {
        alignSelf: 'flex-start',
        color: '#0984e3',
    },
    TextCurrentItemNumber: {
        fontWeight: '700',
        fontSize: 18,
        textAlign: 'center',
    },
});

type PickListItemProps = {
    details: PicklistProcessPicklistItemFragment;
};

const PickListItem: React.FC<PickListItemProps> = ({ details }) => {
    const {
        storageItem: {
            location: locationObj,
            barcode,
            storageItemType: { name: storageItemTypeName, description },
        },
        request,
    } = details;

    const { description: location } = locationObj ?? {};

    const { status } = request ?? {};

    // TODO: Get image from backend
    const image = 'largecouch.jpg';

    return (
        <View>
            <View style={pickListItemStyles.ViewDetailGroup}>
                <Text
                    style={[pickListItemStyles.TextDetailHeader, { fontSize: 20 }]}
                    testID="data-pickListProcessItemName"
                >
                    <FormattedMessage
                        id="pickListProcessItem.storageItemTypeNameText"
                        defaultMessage={`{storageItemTypeName}`}
                        values={{
                            storageItemTypeName: storageItemTypeName ?? 'No storage item type name',
                        }}
                    />
                </Text>
            </View>

            <View style={pickListItemStyles.ViewDetailGroup}>
                <Text
                    style={pickListItemStyles.TextDetailHeader}
                    testID="data-pickListProcessItemLocation"
                >
                    <FormattedMessage
                        id="pickListProcessItem.locationHeader"
                        defaultMessage="Location "
                    />
                </Text>

                <Text>
                    <FormattedMessage
                        id="pickListProcessItem.locationText"
                        defaultMessage="{location}"
                        values={{ location: location ?? 'No location' }}
                    />
                </Text>
            </View>

            <View style={pickListItemStyles.ViewDetailGroup}>
                <Text
                    style={pickListItemStyles.TextDetailHeader}
                    testID="data-pickListProcessItemBarcode"
                >
                    <FormattedMessage
                        id="pickListProcessItem.barcodeHeader"
                        defaultMessage="Barcode: "
                    />
                </Text>
                <Text>
                    <FormattedMessage
                        id="pickListProcessItem.barcodeText"
                        defaultMessage="{barcode}"
                        values={{ barcode: barcode ?? 'No barcode' }}
                    />
                </Text>
            </View>

            <View style={pickListItemStyles.ViewImageGroup}>
                <Image
                    style={pickListItemStyles.ImageItem}
                    source={require(`../MockImages/${image}`)}
                    testID="data-pickListProcessItemImage"
                />
            </View>

            <View style={pickListItemStyles.ViewDetailGroup}>
                <Text
                    style={pickListItemStyles.TextDetailHeader}
                    testID="data-pickListProcessItemStatus"
                >
                    <FormattedMessage
                        id="pickListProcessItem.statusHeader"
                        defaultMessage="Status: "
                    />
                </Text>
                <Text>
                    <FormattedMessage
                        id="pickListProcessItem.statusText"
                        defaultMessage="{status}"
                        values={{ status: status ?? 'No status' }}
                    />
                </Text>
            </View>

            <View style={pickListItemStyles.ViewDetailGroup}>
                <Text
                    style={pickListItemStyles.TextDetailHeader}
                    testID="data-pickListProcessItemItemType"
                >
                    <FormattedMessage
                        id="pickListProcessItem.itemTypeHeader"
                        defaultMessage="Item type: "
                    />
                </Text>
                <Text>
                    <FormattedMessage
                        id="pickListProcessItem.itemTypeText"
                        defaultMessage="{itemType}"
                        values={{ itemType: storageItemTypeName ?? 'No storage item type name' }}
                    />
                </Text>
            </View>

            <View style={{ marginVertical: 2 }}>
                <Text
                    style={pickListItemStyles.TextDetailHeader}
                    testID="data-pickListProcessItemDescription"
                >
                    <FormattedMessage
                        id="pickListProcessItem.descriptionHeader"
                        defaultMessage="Description"
                    />
                </Text>
                <Text>
                    <FormattedMessage
                        id="pickListProcessItem.descriptionText"
                        defaultMessage="{description}"
                        values={{ description: description ?? 'No description' }}
                    />
                </Text>
            </View>
        </View>
    );
};

const pickListItemStyles = StyleSheet.create({
    ViewDetailGroup: {
        marginVertical: 2,
        display: 'flex',
        flexDirection: 'row',
    },
    ViewImageGroup: {
        marginVertical: 5,
        display: 'flex',
        justifyContent: 'center',
    },
    TextDetailHeader: {
        fontWeight: '700',
    },
    ImageItem: {
        height: 150,
        width: 200,
        resizeMode: 'stretch',
    },
});

const statusIconMapping: { [key in RequestStatus]: ReactElement } = {
    [RequestStatus.Created]: <Icon icon="pickListCreated" />,
    [RequestStatus.InPicklist]: <Icon icon="pickListInPickList" />,
    [RequestStatus.PickingProcessed]: <Icon icon="pickListPicked" />,
    [RequestStatus.PickingCompleted]: <Icon icon="pickListCompleted" />,
    [RequestStatus.Excluded]: <Icon icon="pickListExcluded" />,
    [RequestStatus.InTransitToCustomer]: <Icon icon="pickListInTransitToCustomer" />,
    [RequestStatus.InTransitToWarehouse]: <Icon icon="pickListInTransitToWarehouse" />,
    [RequestStatus.Completed]: <Icon icon="pickListCompleted" />,
    [RequestStatus.Cancelled]: <Icon icon="pickListCancelled" />,
    [RequestStatus.Sorted]: <Icon icon="pickListSorted" />,
    [RequestStatus.Processed]: <Icon icon="pickListCompleted" />,
};

type ExpandPickListProps = {
    pickListItems: PicklistProcessPicklistItemFragment[];
    onItemPress: (index: number) => void;
    currentItem: number;
};

const ExpandPickList: React.FC<ExpandPickListProps> = ({
    pickListItems,
    onItemPress,
    currentItem,
}) => {
    return (
        <View style={expandPickListStyles.ViewParent} testID="data-pickListProcessListContainer">
            {pickListItems.map((item: PicklistProcessPicklistItemFragment, index: number) => (
                <TouchableWithoutFeedback
                    onPress={() => onItemPress(index)}
                    key={`${item.storageItem.barcode}.${index}`}
                    testID="data-pickListProcessListItem"
                >
                    <View style={expandPickListStyles.ViewItemGroup}>
                        <Text
                            style={expandPickListStyles.TextItemNumber}
                            testID="data-pickListProcessListItemIndex"
                        >
                            {index + 1}
                        </Text>
                        <Text
                            style={[
                                expandPickListStyles.TextItemName,
                                {
                                    color: currentItem === index ? '#000' : '#0984e3',
                                },
                            ]}
                            testID="data-pickListProcessListItemName"
                        >
                            <FormattedMessage
                                id="pickListProcessExpandList.itemNameText"
                                defaultMessage="{itemName}"
                                values={{ itemName: item.storageItem.storageItemType.name }}
                            />
                        </Text>
                        <Text
                            style={expandPickListStyles.TextItemStatus}
                            testID="data-pickListProcessListItemStatus"
                        >
                            {item.request?.status
                                ? statusIconMapping[item.request.status]
                                : undefined}
                        </Text>
                    </View>
                </TouchableWithoutFeedback>
            ))}
        </View>
    );
};

const expandPickListStyles = StyleSheet.create({
    ViewParent: {
        display: 'flex',
        flexDirection: 'column',
    },
    ViewItemGroup: {
        paddingVertical: 3,
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
    },
    TextItemNumber: {
        flex: 0.25,
        fontWeight: '700',
    },
    TextItemName: {
        flex: 1,
        justifyContent: 'flex-start',
    },
    TextItemStatus: {
        flex: 0.25,
        fontWeight: '700',
        justifyContent: 'flex-end',
    },
});

type ExcludeItemProps = {
    noteValue: string;
    onNoteValueChange: (value: string) => void;
    onGoBackPress: () => void;
    onConfirmExclusionPress: () => void;
};

const ExcludeItem: React.FC<ExcludeItemProps> = ({
    noteValue,
    onNoteValueChange,
    onGoBackPress,
    onConfirmExclusionPress,
}) => {
    const intl = useIntl();

    return (
        <View style={excludeItemStyles.ViewParent}>
            <Button
                title={intl.formatMessage({
                    id: 'pickListProcessExcludeItem.backButton',
                    defaultMessage: 'Go back',
                })}
                onPress={onGoBackPress}
                size="small"
            />

            <Text style={excludeItemStyles.TextConfirmation}>
                <FormattedMessage
                    id="pickListProcessExcludeItem.confirmationText"
                    defaultMessage="Are you sure you could not find the item?"
                />
            </Text>

            <View style={excludeItemStyles.ViewNoteGroup}>
                <Text style={{ fontWeight: '700' }}>
                    <FormattedMessage id="pickListProcess.addNote" defaultMessage="Add a note" />
                </Text>
                <View style={{ flex: 1 }}>
                    <TextInput
                        type="text"
                        value={noteValue}
                        ariaLabel={intl.formatMessage({
                            id: 'pickListProcess.addNoteInput',
                            defaultMessage: 'Add a note',
                        })}
                        onChange={onNoteValueChange}
                    />
                </View>
            </View>

            <Button
                title={intl.formatMessage({
                    id: 'pickListProcessExcludeItem.confirmButton',
                    defaultMessage: 'Confirm Exclusion',
                })}
                onPress={onConfirmExclusionPress}
            />
        </View>
    );
};

const excludeItemStyles = StyleSheet.create({
    ViewParent: {
        display: 'flex',
        flexDirection: 'column',
    },
    TextConfirmation: {
        marginVertical: 5,
    },
    ViewNoteGroup: {
        marginVertical: 5,
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
});
