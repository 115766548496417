import React, { useEffect, useCallback, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { gql } from '@apollo/client';

import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import { Button, TextInput, Icon, Message } from '@valet/ui-components';

import { FormattedMessage, useIntl } from 'react-intl';
import {
    useSetStorageItemMetadataMutation,
    useStorageItemAssignBarcodeMutation,
    useRequestAssignContainerMutation,
    useRetrieveStorageItemQuery,
    PickupRequestsByVisitIdPickupRequestFragment,
} from '../../../schema-types';

export const RETRIEVE_STORAGE_ITEM_QUERY = gql`
    query RetrieveStorageItem($requestId: String!) {
        requestById(id: $requestId) {
            id
            ... on PickupStorageItemRequest {
                container {
                    id
                    barcode
                }
                storageItem {
                    id
                    status
                    barcode
                    container {
                        id
                        barcode
                    }
                    storageItemType {
                        id
                        metadataDefinitions {
                            id
                            propertyName
                            isRequired
                            propertyType
                            values
                            isActive
                            sortOrder
                        }
                        containerType {
                            id
                            description
                        }
                    }
                    metadataValue {
                        value
                        metadataDefinition {
                            id
                            propertyName
                        }
                    }
                }
            }
        }
    }
`;

export const SET_STORAGE_ITEM_METADATA_MUTATION = gql`
    mutation setStorageItemMetadata($data: SetStorageItemMetadataInput!) {
        setStorageItemMetadata(data: $data) {
            values {
                value
            }
        }
    }
`;

export const STORAGE_ITEM_ASSIGN_BARCODE_MUTATION = gql`
    mutation storageItemAssignBarcode($data: StorageItemAssignBarcodeInput!) {
        storageItemAssignBarcode(data: $data) {
            data {
                id
                barcode
            }
        }
    }
`;

export const REQUEST_ASSIGN_CONTAINER_MUTATION = gql`
    mutation requestAssignContainer($data: RequestAssignContainerInput!) {
        requestAssignContainer(data: $data) {
            data {
                id
            }
        }
    }
`;

type DetailsType = { [key: string]: { values?: string[]; value?: string } };

type DeliveryPickupItemDetailProps = {};

export const DeliveryPickupItemDetail: React.FC<DeliveryPickupItemDetailProps> = () => {
    const intl = useIntl();

    const [details, setDetails] = useState<DetailsType | undefined>(undefined);
    const [expandAssignBarcodeEntry, setExpandAssignBarcodeEntry] = useState<boolean>(false);
    const [assignBarcodeEntryValue, setAssignBarcodeEntryValue] = useState<string>('');

    const history = useHistory();
    const match = useRouteMatch();

    const params: { routeId?: string; visitId?: string; requestId?: string } = match.params;
    const routeId = params.routeId;
    const visitId = params.visitId;
    const requestId = params.requestId;

    const handleGoBackPress = useCallback((): void => {
        history.push(`/delivery/${routeId}/${visitId}/pickup`);
    }, [history, routeId, visitId]);

    useEffect(() => {
        if (!requestId) {
            handleGoBackPress();
        }
    }, [requestId, handleGoBackPress]);

    const { data, loading, error, refetch } = useRetrieveStorageItemQuery({
        variables: { requestId: requestId || '' },
        nextFetchPolicy: 'network-only'
    });

    const item = (data?.requestById as PickupRequestsByVisitIdPickupRequestFragment)?.storageItem;
    const request = data?.requestById as PickupRequestsByVisitIdPickupRequestFragment;

    const storageItemStatusTextMapping: { [key: string]: string } = {
        CREATED: intl.formatMessage({
            id: 'inventory.statusCreated',
            defaultMessage: 'This item has been recently added.',
        }),
        IN_TRANSIT: intl.formatMessage({
            id: 'inventory.statusInTransit',
            defaultMessage: 'This item is in transit.',
        }),
        AT_CUSTOMER_LOCATION: intl.formatMessage({
            id: 'inventory.statusAtCustomerLocation',
            defaultMessage: 'This item is not in storage.',
        }),
        IN_STORAGE: intl.formatMessage({
            id: 'inventory.statusInStorage',
            defaultMessage: 'This item is in storage.',
        }),
        ARCHIVED: intl.formatMessage({
            id: 'inventory.statusArchived',
            defaultMessage: 'This item is no longer active.',
        }),
    };

    const [
        setStorageItemMetadataMutation,
        {
            loading: setStorageItemMetadataMutationLoading,
            error: setStorageItemMetadataMutationError,
        },
    ] = useSetStorageItemMetadataMutation({
        onCompleted({ setStorageItemMetadata: { values } }) {
            if (values) {
                setDetails(undefined);
                refetch({
                    requestId: requestId,
                });
            }
            handleGoBackPress();
        },
    });

    const [
        storageItemAssignBarcodeMutation,
        {
            loading: storageItemAssignBarcodeMutationLoading,
            error: storageItemAssignBarcodeMutationError,
        },
    ] = useStorageItemAssignBarcodeMutation({
        onCompleted({ storageItemAssignBarcode: { data } }) {
            if (data) {
                setDetails(undefined);
                refetch({
                    requestId: requestId,
                });
            }
        },
    });

    const [
        requestAssignContainerMutation,
        {
            loading: requestAssignContainerMutationLoading,
            error: requestAssignContainerMutationError,
        },
    ] = useRequestAssignContainerMutation({
        onCompleted({ requestAssignContainer: { data } }) {
            if (data) {
                setDetails(undefined);
                refetch({
                    requestId: requestId,
                });
            }
        },
    });

    const handleAttachBarcodeSubmit = useCallback(async () => {
        if (assignBarcodeEntryValue) {
            try {
                setExpandAssignBarcodeEntry(!expandAssignBarcodeEntry);
                setAssignBarcodeEntryValue('');
                await storageItemAssignBarcodeMutation({
                    variables: {
                        data: {
                            storageItemId: request.storageItem?.id || '',
                            barcode: assignBarcodeEntryValue,
                        },
                    },
                });
            } catch (ignore) { } //eslint-disable-line no-empty
        }
    }, [
        assignBarcodeEntryValue,
        expandAssignBarcodeEntry,
        request,
        storageItemAssignBarcodeMutation,
    ]);

    const handleAssignContainerSubmit = useCallback(async () => {
        if (assignBarcodeEntryValue) {
            setExpandAssignBarcodeEntry(!expandAssignBarcodeEntry);
            await requestAssignContainerMutation({
                variables: {
                    data: {
                        requestId: requestId || '',
                        barcode: assignBarcodeEntryValue,
                    },
                },
            });
            setAssignBarcodeEntryValue('');
        }
    }, [
        assignBarcodeEntryValue,
        expandAssignBarcodeEntry,
        requestId,
        requestAssignContainerMutation,
    ]);

    const handleManualScanCameraPress = useCallback((): void => {
        console.log('Pressed Manual Scan Camera Icon');
    }, []);

    const handleManualScanKeyboardPress = useCallback((): void => {
        setExpandAssignBarcodeEntry(!expandAssignBarcodeEntry);
    }, [expandAssignBarcodeEntry]);

    if (
        loading ||
        storageItemAssignBarcodeMutationLoading ||
        setStorageItemMetadataMutationLoading ||
        requestAssignContainerMutationLoading
    ) {
        return (
            <View testID="data-deliveryPickupItemDetailPage">
                <FormattedMessage
                    id="deliveryPickupItemDetail.loading"
                    defaultMessage="Loading..."
                />
            </View>
        );
    }

    if (
        error ||
        storageItemAssignBarcodeMutationError ||
        setStorageItemMetadataMutationError ||
        requestAssignContainerMutationError
    ) {
        return (
            <View testID="data-deliveryPickupItemDetailPage">
                <Message
                    type="negative"
                    header={intl.formatMessage({
                        id: 'truckLoadingItemProcess.truckLoadingItemProcessErrorMessageHeader',
                        defaultMessage: 'Could not deliver this item - please try again',
                    })}
                    content={
                        requestAssignContainerMutationError?.message ||
                        storageItemAssignBarcodeMutationError?.message ||
                        setStorageItemMetadataMutationError?.message ||
                        intl.formatMessage({
                            id:
                                'truckLoadingItemProcess.truckLoadingItemProcessErrorMessageContent',
                            defaultMessage: 'There was an error - please refresh this page.',
                        })
                    }
                    testID="data-truckLoadingItemProcessErrorMessage"
                />
            </View>
        );
    }

    if (data) {
        const { container: requestContainer, storageItem } = request;

        if (details === undefined) {
            const detailsFields: DetailsType = {};

            storageItem?.storageItemType.metadataDefinitions?.forEach((data) => {
                detailsFields[data.propertyName] = {
                    values: data.values ?? undefined,
                    value:
                        storageItem?.metadataValue?.find(
                            (m) => m.metadataDefinition?.id === data.id,
                        )?.value ?? undefined,
                };
            });
            setDetails(detailsFields);
        } else {
            return (
                <View style={styles.ViewParent} testID="data-deliveryPickupItemDetailPage">
                    <View style={styles.ViewTopBar}>
                        <Text onPress={handleGoBackPress}>
                            <Icon icon="back" />{' '}
                            <FormattedMessage
                                id="deliveryPickupItemDetail.back"
                                defaultMessage="Back"
                            />
                        </Text>
                    </View>
                    <View style={styles.ViewContent}>
                        <View style={styles.title}>
                            <Text style={styles.BarcodeTitle}>Barcode:</Text>
                            <Text style={styles.TextHeader}>
                                {storageItem?.container?.barcode ||
                                    storageItem?.storageItemType?.containerType
                                    ? requestContainer?.barcode
                                    : item?.barcode}
                            </Text>
                        </View>
                    </View>

                    {storageItem?.storageItemType.metadataDefinitions?.map((metadata) => (
                        <View style={styles.ViewContent} key={metadata.propertyName}>
                            <View style={styles.title}>
                                <Text style={styles.TextHeader}>{`${metadata.propertyName} `}</Text>
                                <Text style={styles.RequiredField}>*</Text>
                            </View>

                            <TextInput
                                type="text"
                                ariaLabel=""
                                value={details[metadata.propertyName]?.value || ''}
                                onChange={(value: string) =>
                                    setDetails({
                                        ...details,
                                        [metadata.propertyName]: {
                                            values: metadata.values ?? undefined,
                                            value,
                                        },
                                    })
                                }
                            />
                        </View>
                    ))}

                    <View style={styles.ViewContent}>
                        <Text style={styles.TextHeader}>
                            <FormattedMessage
                                id="deliveryPickupItemDetail.statusHeader"
                                defaultMessage="Status"
                            />
                        </Text>
                        <Text>{storageItemStatusTextMapping[`${storageItem?.status}`]}</Text>
                    </View>

                    <View style={styles.ViewContent}>
                        <Text style={styles.TextHeader}>
                            <FormattedMessage
                                id="deliveryPickupItemDetail.scheduleDetailsHeader"
                                defaultMessage="Schedule Details"
                            />
                        </Text>
                    </View>

                    <View style={styles.ViewContent}>
                        <View style={styles.buttonContainer}>
                            <View style={styles.button}>
                                <Button
                                    title={intl.formatMessage({
                                        id: 'deliveryPickupItemDetail.saveDetail',
                                        defaultMessage: 'Save Detail',
                                    })}
                                    onPress={async () => {
                                        if (storageItem?.id) {
                                            await setStorageItemMetadataMutation({
                                                variables: {
                                                    data: {
                                                        storageItemId: storageItem?.id,
                                                        values:
                                                            storageItem?.storageItemType.metadataDefinitions?.map(
                                                                (md) => {
                                                                    return {
                                                                        storageItemMetadataDefinitionId:
                                                                            md.id,
                                                                        value:
                                                                            details[md.propertyName]
                                                                                .value ?? '',
                                                                    };
                                                                },
                                                            ) ?? undefined,
                                                    },
                                                },
                                            });
                                        }
                                    }}
                                />
                            </View>
                            <View style={styles.button}>
                                {!request.storageItem?.storageItemType.containerType ? (
                                    <Button
                                        title={intl.formatMessage({
                                            id: 'deliveryPickupItemDetail.attachBarcode',
                                            defaultMessage: 'Attach Barcode',
                                        })}
                                        onPress={async () => {
                                            setExpandAssignBarcodeEntry(!expandAssignBarcodeEntry);
                                        }}
                                    />
                                ) : (
                                    <Button
                                        title={intl.formatMessage({
                                            id: 'deliveryPickupItemDetail.assignContainer',
                                            defaultMessage: 'Assign Container',
                                        })}
                                        onPress={async () => {
                                            setExpandAssignBarcodeEntry(!expandAssignBarcodeEntry);
                                        }}
                                    />
                                )}
                            </View>
                        </View>
                        <View
                            style={styles.ViewActionsGroup}
                            testID="data-deliveryPickupItemActionsContainer"
                        >
                            {expandAssignBarcodeEntry && (
                                <View
                                    style={{
                                        padding: 3,
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                    testID="data-deliveryPickupItemManualBarcodeEntryContainer"
                                >
                                    <View style={{ flex: 1, marginRight: 5 }}>
                                        <TextInput
                                            type="text"
                                            onChange={(e) => setAssignBarcodeEntryValue(e)}
                                            value={assignBarcodeEntryValue}
                                            placeholder={intl.formatMessage({
                                                id: 'pickUpItemAssignBarcodeEntryPlaceholderText',
                                                defaultMessage: 'Enter item barcode',
                                            })}
                                            ariaLabel={intl.formatMessage({
                                                id: 'pickUpItemAssignBarcodeEntryPlaceholderText',
                                                defaultMessage: 'Enter item barcode',
                                            })}
                                            testID="data-deliveryPickupItemManualBarcodeEntryInput"
                                        />
                                    </View>

                                    <View style={{ marginLeft: 5 }}>
                                        <Button
                                            onPress={
                                                request.storageItem?.storageItemType.containerType
                                                    ? handleAssignContainerSubmit
                                                    : handleAttachBarcodeSubmit
                                            }
                                            title={intl.formatMessage({
                                                id: 'deliveryPickupItemScanButton',
                                                defaultMessage: 'SCAN',
                                            })}
                                            testID="data-deliveryPickupItemManualBarcodeEntrySubmitButton"
                                        />
                                    </View>
                                </View>
                            )}
                            <View style={{ display: 'flex', flexDirection: 'row' }}>
                                <View style={styles.ViewManualScanIcons}>
                                    <TouchableOpacity
                                        onPress={handleManualScanCameraPress}
                                        style={[{ marginRight: 5 }]}
                                        testID="data-deliveryPickupItemActionCamera"
                                    >
                                        <Icon icon="barcodeCamera" />
                                    </TouchableOpacity>

                                    <TouchableOpacity
                                        onPress={handleManualScanKeyboardPress}
                                        style={[{ marginLeft: 5 }]}
                                        testID="data-deliveryPickupItemActionKeyboard"
                                    >
                                        <Icon icon="barcodeKeyboard" />
                                    </TouchableOpacity>
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
            );
        }
    }

    return <></>;
};

const styles = StyleSheet.create({
    ViewParent: {
        display: 'flex',
        flexDirection: 'column' as 'column',
    },
    ViewTopBar: {
        paddingBottom: 5,
        display: 'flex',
        flexDirection: 'row' as 'row',
        alignItems: 'center',
        borderBottomWidth: 1,
        borderBottomColor: '#d4d4d5',
    },
    ViewContent: {
        display: 'flex',
        flexDirection: 'column' as 'column',
        marginVertical: 5,
    },
    TextHeader: {
        fontWeight: '700',
    },
    RequiredField: {
        fontWeight: '100',
        color: 'Red',
        width: '10%',
    },
    ViewErrorMessageParent: {
        marginTop: 5,
        marginBottom: 5,
    },
    buttonContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    button: {
        width: '40%',
        height: 40,
    },
    title: {
        display: 'flex',
        flexDirection: 'row' as 'row',
    },
    ViewActionsGroup: {
        marginVertical: 5,
        display: 'flex',
        flexDirection: 'column',
        borderTopColor: '#D4D4D5',
        borderTopWidth: 1,
    },
    ViewButton: {
        marginTop: 10,
    },
    CancelAction: {
        borderWidth: 1,
        borderRadius: 5,
        padding: 5,
    },
    ViewDetailGroup: {
        marginVertical: 2,
        display: 'flex',
        flexDirection: 'row',
    },
    DeliveryHeader: {
        fontWeight: '700',
        flex: 1,
    },

    ViewExpandPickList: {
        flex: 1,
        paddingVertical: 5,
        display: 'flex',
        overflowY: 'scroll',
        overflowX: 'none',
    },
    ViewExpandPickListText: {
        marginVertical: 5,
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
    },
    ViewManualScanIcons: {
        marginVertical: 5,
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    BarcodeTitle: {},
});
