import React from 'react';
import { ScrollView } from 'react-native';
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom';
import { RefileListCreationPage } from './RefileListCreationPage';
import { RefileListPage } from './RefileListPage';
import { RefileListProcessPage } from './RefileListProcessPage';

const RefileListRoutes: React.FC = () => {
    const { url } = useRouteMatch();
    const history = useHistory();

    const handleRefileListSelect = (refileListId: string): void => {
        console.log(refileListId);
        history.push(`/refile/${refileListId}/process`);
    };

    const handleAddRefileListPressed = (): void => {
        history.push(`/refile/createList`);
    };

    const handleCreateRefileListPressed = (refileListId: string): void => {
        history.push(`/refile/${refileListId}/process`);
    };

    return (
        <ScrollView>
            <Switch>
                <Route path={`${url}/:refileListId/process`} exact>
                    <RefileListProcessPage />
                </Route>
                <Route path={`${url}/createList`} exact>
                    <RefileListCreationPage
                        onCreateRefileListPressed={handleCreateRefileListPressed}
                    />
                </Route>
                <Route path={`${url}`}>
                    <RefileListPage
                        onRefileListSelect={handleRefileListSelect}
                        onAddRefileListPressed={handleAddRefileListPressed}
                    />
                </Route>
            </Switch>
        </ScrollView>
    );
};

export default RefileListRoutes;
