import { gql } from '@apollo/client';
import React, { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ScrollView, StyleSheet, Text, View } from 'react-native';
import { Button, ErrorMessage, Message, Select, SelectItem } from '@valet/ui-components';
import { useHistory } from 'react-router-dom';
import {
    AdminAddLocationBuildingFragment,
    LocationType,
    useAdminAddLocationMutation,
    useGetAdminAddLocationBuildingsQuery,
} from '../../../../schema-types';
import { parse } from 'papaparse';

const BUILDING_FRAGMENT = gql`
    fragment adminAddLocationBuilding on Building {
        id
        code
        description
    }
`;

export const RETRIEVE_BUILDING_QUERY = gql`
    query getAdminAddLocationBuildings {
        buildingsAll {
            ...adminAddLocationBuilding
        }
    }
    ${BUILDING_FRAGMENT}
`;

export const ADMIN_ADD_LOCATION_MUTATION = gql`
    mutation adminAddLocation($data: [AdminAddLocationsInput!]) {
        adminAddLocation(data: $data) {
            data {
                id
            }
        }
    }
`;

type LocationInputType = {
    buildingId: string;
    description: string;
    barcode: string;
    type: LocationType;
};

type AddLocationPageProps = {
    onLocationAdded: () => void;
};

export const AddLocationPage: React.FC<AddLocationPageProps> = ({ onLocationAdded }) => {
    const history = useHistory();
    const [errorMessage, setErrorMessage] = useState<ErrorMessage | undefined>(undefined);
    const intl = useIntl();
    const [buildingId, setBuildingId] = useState<string>('');
    const [uploadedLocations, setUploadedLocations] = useState<LocationInputType[]>();
    const { data: retrievedBuildingData } = useGetAdminAddLocationBuildingsQuery({
        onCompleted: (data) => {
            if (data && data.buildingsAll && data.buildingsAll.length)
                setBuildingId(data.buildingsAll[0].id);
        },
    });

    const buildingsData = retrievedBuildingData?.buildingsAll
        ? (retrievedBuildingData?.buildingsAll as AdminAddLocationBuildingFragment[])
        : [];

    const selectItems: { id: string; title: string }[] = [];
    buildingsData.map((item) =>
        selectItems.push({
            id: item.id,
            title: item.code,
        }),
    );
    const [selectIndex] = useState<number>(0);

    const [
        adminAddLocationMutation,
        {
            loading: adminAddLocationDetailMutationLoading,
            error: adminAddLocationDetailMutationError,
        },
    ] = useAdminAddLocationMutation({
        onCompleted(data) {
            if (data) {
                onLocationAdded();
            }
        },
    });

    const handleAddLocationClick = useCallback(async () => {
        if (uploadedLocations) {
            try {
                if (uploadedLocations.find((l) => l.description === '' || l.barcode === '')) {
                    return setErrorMessage({
                        header: '',
                        content: intl.formatMessage({
                            id: 'adminAddLocation.errorUploadLocationFile',
                            defaultMessage: 'Location Description and Barcode could not be empty.',
                        }),
                        type: 'warning',
                    });
                } else {
                    setErrorMessage(undefined);
                    await adminAddLocationMutation({
                        variables: {
                            data: uploadedLocations,
                        },
                    });
                }
            } catch (ignore) { }
        }
    }, [uploadedLocations, adminAddLocationMutation, setErrorMessage, intl]);

    const processCSV = useCallback(
        (str: string): LocationInputType[] => {
            const parser = parse<LocationInputType>(str, { header: true, skipEmptyLines: true });
            const locations: LocationInputType[] = parser.data.map((r) => {
                return { ...r, buildingId: buildingId };
            });
            return locations;
        },
        [buildingId],
    );

    const handleFiles = useCallback(
        async (files) => {
            var reader = new FileReader();
            reader.onload = function (e) {
                const text = e.target?.result?.toString() || '';
                if (text) {
                    setUploadedLocations(processCSV(text));
                }
            };
            reader.readAsText(files[0]);
        },
        [processCSV, setUploadedLocations],
    );

    const handleBacklClick = useCallback(async () => {
        history.push(`/admin/locations`);
    }, [history]);

    return (
        <ScrollView>
            <View style={adminAddLocationPageStyles.ViewParent} testID="data-AddLocation">
                <View style={adminAddLocationPageStyles.ViewChild}>
                    <View style={adminAddLocationPageStyles.ViewContentParent}>
                        <View style={adminAddLocationPageStyles.ViewTitleParent}>
                            <Text
                                style={adminAddLocationPageStyles.TextTitle}
                                testID="data-newLocation"
                            >
                                {`${intl.formatMessage({
                                    id: 'addLocation.newLocation',
                                    defaultMessage: 'New Location Details:',
                                })}${''}`}
                            </Text>
                        </View>
                        {adminAddLocationDetailMutationLoading && (
                            <Text>
                                <FormattedMessage
                                    id="addLocation.loading"
                                    defaultMessage="Loading..."
                                />
                            </Text>
                        )}
                        {adminAddLocationDetailMutationError && (
                            <Text>
                                <FormattedMessage
                                    id="addLocation-AddLocationErrorMessage"
                                    defaultMessage="Could not add newLocation - please try again."
                                />
                            </Text>
                        )}
                        {errorMessage && (
                            <Message
                                type={errorMessage.type}
                                header={errorMessage.header}
                                content={errorMessage.content}
                            />
                        )}
                        <View style={adminAddLocationPageStyles.ViewTitleParent}>
                            <View style={adminAddLocationPageStyles.ViewDescription}>
                                <FormattedMessage
                                    id="addLocation.Building"
                                    defaultMessage="Building"
                                />
                                <Select
                                    currentIndex={selectIndex}
                                    itemsDisplayValues={selectItems.map((items) => items.title)}
                                    onSelect={(index: any) => {
                                        setBuildingId(selectItems[index].id);
                                    }}
                                >
                                    {selectItems.map((item) => (
                                        <SelectItem title={item.title} key={item.id} />
                                    ))}
                                </Select>
                            </View>
                            <View style={adminAddLocationPageStyles.ViewDescription}>
                                <input
                                    type="file"
                                    onChange={(e) => handleFiles(e.target.files)}
                                ></input>
                            </View>
                        </View>
                    </View>
                </View>
                <View
                    style={{
                        marginVertical: 10,
                        marginHorizontal: 10,
                        display: 'flex',
                        flexDirection: 'row',
                    }}
                >
                    <View style={{ flex: 1, marginRight: 10 }}>
                        <Button
                            title={intl.formatMessage({
                                id: 'addLocation.Cancel',
                                defaultMessage: 'Cancel',
                            })}
                            onPress={handleBacklClick}
                        />
                    </View>
                    <View style={{ flex: 1 }}>
                        <Button
                            title={intl.formatMessage({
                                id: 'addLocation.Add',
                                defaultMessage: 'Add',
                            })}
                            onPress={handleAddLocationClick}
                        />
                    </View>
                </View>
            </View>
        </ScrollView>
    );
};

const adminAddLocationPageStyles = StyleSheet.create({
    ViewParent: {
        display: 'flex',
        flexDirection: 'column',
    },
    ViewTabs: {
        marginVertical: 5,
        display: 'flex',
        flexDirection: 'row',
    },
    ViewContent: {
        paddingVertical: 5,
    },
    TextTab: {
        flex: 1,
        color: '#000',
        backgroundColor: '#D4D4D5',
        fontSize: 18,
        textAlign: 'center',
        fontWeight: '700',
    },

    ViewChild: {
        display: 'flex',
        flexDirection: 'row',
    },
    ViewImageParent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    RouteImage: {
        height: 40,
        width: 40,
        resizeMode: 'stretch',
    },
    ViewContentParent: {
        paddingHorizontal: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    ViewTitleParent: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 5,
    },
    ViewTitleChild: {},
    TextTitle: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 10,
    },
    ViewDescription: {
        marginTop: 10,
        fontWeight: 'bold',
    },
    TextDescription: { fontSize: 14 },
    ViewStatus: {},
    TextStatus: {
        fontSize: 12,
        fontWeight: '700',
    },
    ViewDate: {},
    TextDate: {
        fontSize: 12,
    },
    ViewErrorMessageParent: {
        marginTop: 5,
        marginBottom: 5,
    },
});
