import { gql } from '@apollo/client';

import { VISIT_REVIEW_MAP_VISIT_FRAGMENT } from './Map/Map.web';

export const visitsPageSize = 100;
export const RETRIEVE_VISITS_QUERY = gql`
    query RetrievePendingVisits($pageSize: Int!, $cursor: String!) {
        visits(filter: { pageSize: $pageSize, after: $cursor }) {
            pageInfo {
                cursor {
                    beforeCursor
                    afterCursor
                }
                hasNextPage
                hasPreviousPage
            }
            edges {
                cursor
                node {
                    id
                    ...VisitReviewMapVisit
                    status
                    startTime
                    endTime
                    expectedDuration
                    summary {
                        inComing
                        outGoing
                        multiplePeople
                        firstVisit
                    }
                    customerAddress {
                        id
                        address1
                        zoneId
                        city
                        zoneId
                        countryId
                        zip
                        longitude
                        latitude
                        phone
                        firstName
                        lastName
                        addressType
                        territory {
                            id
                            description
                        }
                    }
                    scheduleWindow {
                        id
                        isLocked
                        startTime
                        endTime
                    }
                }
            }
        }
    }
    ${VISIT_REVIEW_MAP_VISIT_FRAGMENT}
`;

// Fragment defined locally to prevent circular dependency issue
export const RETRIEVE_SCHEDULE_WINDOWS_QUERY = gql`
    query RetrieveScheduleWindows($zipCode: String!, $startDate: DateTime!, $endDate: DateTime!) {
        territoryByZipCode(zipCode: $zipCode) {
            id
            schedule(startDate: $startDate, endDate: $endDate, requestType: DELIVER_STORAGE_ITEM) {
                id
                ...VisitReviewVisitRequestScheduleWindow
            }
        }
    }

    fragment VisitReviewVisitRequestScheduleWindow on ScheduleWindow {
        id
        isLocked
        startTime
        endTime
    }
`;

export const requestsForVisitPageSize = 100;

// Fragment defined locally to prevent circular dependency issue
export const RETRIEVE_REQUESTS_BY_VISIT_ID_QUERY = gql`
    query RetrieveRequestsByVisitId($visitId: String!, $pageSize: Int!, $cursor: String!) {
        requestsByVisitId(visitId: $visitId, filter: { pageSize: $pageSize, after: $cursor }) {
            pageInfo {
                cursor {
                    beforeCursor
                    afterCursor
                }
                hasNextPage
                hasPreviousPage
            }
            edges {
                cursor
                node {
                    id
                    requestType
                    ...VisitReviewVisitRequestPickupStorageItemRequest
                    ...VisitReviewVisitRequestDeliverStorageItemRequest
                    ...VisitReviewVisitRequestDeliverEmptyContainerRequest
                    ...VisitReviewVisitRequestPickupEmptyContainerRequest
                    ...VisitReviewVisitRequestDriverPickupEmptyContainerRequest
                }
            }
        }
    }

    fragment VisitReviewVisitRequestPickupStorageItemRequest on PickupStorageItemRequest {
        id
        requestType
        storageItem {
            id
            storageItemType {
                id
                name
            }
        }
    }

    fragment VisitReviewVisitRequestDeliverStorageItemRequest on DeliverStorageItemRequest {
        id
        requestType
        storageItem {
            id
            storageItemType {
                id
                name
            }
        }
    }

    fragment VisitReviewVisitRequestDeliverEmptyContainerRequest on DeliverEmptyContainerRequest {
        id
        requestType
        storageItem {
            id
            storageItemType {
                id
                name
            }
        }
    }

    fragment VisitReviewVisitRequestPickupEmptyContainerRequest on PickupEmptyContainerRequest {
        id
        requestType
        storageItem {
            id
            storageItemType {
                id
                name
            }
        }
    }

    fragment VisitReviewVisitRequestDriverPickupEmptyContainerRequest on DriverPickupEmptyContainerRequest {
        id
        requestType
        storageItem {
            id
            storageItemType {
                id
                name
            }
        }
    }
`;

export const APPROVE_VISIT_MUTATION = gql`
    mutation approveVisit($details: VisitReviewApproveVisitInput!) {
        visitReviewApproveVisit(data: $details) {
            visit {
                id
                ...VisitReviewMapVisit
                status
                startTime
                endTime
                expectedDuration
                summary {
                    inComing
                    outGoing
                    multiplePeople
                    firstVisit
                }
                customerAddress {
                    id
                    address1
                    zoneId
                    city
                    zoneId
                    countryId
                    zip
                    longitude
                    latitude
                    phone
                    firstName
                    lastName
                    addressType
                }
                scheduleWindow {
                    id
                    isLocked
                    startTime
                    endTime
                }
            }
        }
    }
    ${VISIT_REVIEW_MAP_VISIT_FRAGMENT}
`;
