import React from 'react';
import { ScrollView, View } from 'react-native';
import { Button } from '@valet/ui-components';
import { useHistory } from 'react-router-dom';

type AdminPageProps = {};

export const AdminPage: React.FC<AdminPageProps> = () => {
    const history = useHistory();
    return (
        <ScrollView>
            <View style={{ flex: 1 }}>
                <Button title="Area" onPress={() => history.push(`/admin/area`)} />
            </View>
            <View style={{ flex: 1, marginVertical: 10 }}>
                <Button title="Employee" onPress={() => history.push(`/admin/employee`)} />
            </View>
            <View style={{ flex: 1, marginVertical: 10 }}>
                <Button title="Buildings" onPress={() => history.push(`/admin/buildings`)} />
            </View>
            <View style={{ flex: 1, marginVertical: 10 }}>
                <Button title="Vehicle" onPress={() => history.push(`/admin/vehicle`)} />
            </View>
            <View style={{ flex: 1, marginVertical: 10 }}>
                <Button title="Territory" onPress={() => history.push(`/admin/territories`)} />
            </View>
            <View style={{ flex: 1, marginVertical: 10 }}>
                <Button title="Schedule" onPress={() => history.push(`/admin/schedules`)} />
            </View>
            <View style={{ flex: 1, marginVertical: 10 }}>
                <Button title="Location" onPress={() => history.push(`/admin/locations`)} />
            </View>
            <View style={{ flex: 1, marginVertical: 10 }}>
                <Button
                    title="Storage Item Type"
                    onPress={() => history.push(`/admin/storageItemTypes`)}
                />
            </View>
            <View style={{ flex: 1, marginVertical: 10 }}>
                <Button
                    title="Storage Item Type Categories"
                    onPress={() => history.push(`/admin/categories`)}
                />
            </View>
            <View style={{ flex: 1, marginVertical: 10 }}>
                <Button
                    title="Storage Container"
                    onPress={() => history.push(`/admin/containers`)}
                />
            </View>
            <View style={{ flex: 1, marginVertical: 10 }}>
                <Button
                    title="Storage Container Type"
                    onPress={() => history.push(`/admin/containerTypes`)}
                />
            </View>
            <View style={{ flex: 1, marginVertical: 10 }}>
                <Button
                    title="Visit Management"
                    onPress={() => history.push(`/admin/visitManagement`)}
                />
            </View>
        </ScrollView>
    );
};
