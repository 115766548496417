import { gql } from '@apollo/client';
import React, { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ScrollView, StyleSheet, Text, View } from 'react-native';
import { Button, ErrorMessage, Message, Select, SelectItem, TextInput } from '@valet/ui-components';
import { useAdminAddBuildingMutation, AdminAddBuildingInput, AdminBuildingAreaFragment, useGetAdminBuildingAreasQuery } from '../../../../schema-types';
import { useHistory } from 'react-router-dom';
import { AddressInput, NewAddressForm } from '../../../Organisms/NewAddress/NewAddressForm';
import { CountryCode, LocaleType } from '../../../Organisms/AddressTypings';
import { validateAddressInput } from '../../../Organisms/NewAddress/ValidateAddressInput';

const AREA_FRAGMENT = gql`
    fragment adminBuildingArea on Area {
        id
        code
    }
`;

export const RETRIEVE_AREA_QUERY = gql`
    query getAdminBuildingAreas {
        areas {
            ...adminBuildingArea
        }
    }
    ${AREA_FRAGMENT}
`;

export const ADMIN_ADD_BUILDING_MUTATION = gql`
    mutation adminAddBuilding($data: AdminAddBuildingInput!) {
        adminAddBuilding(data: $data) {
            data {
                id
                code
                description
                isActive
                address {
                    id
                    address1
                    address2
                    city
                    zoneId
                    countryId
                    zip
                    longitude
                    latitude
                    phone
                    firstName
                    lastName
                }
                area {
                        id
                        code
                     }
            }
        }
    }
`;

const defaultAddressInput: AddressInput = {
    id: '',
    firstName: '',
    lastName: '',
    address1: '',
    address2: '',
    city: '',
    zoneId: '',
    zip: '',
    countryId: 'CA',
    phone: '',
};

type AddBuildingPageProps = {
    locale: LocaleType;
    countryCode: CountryCode;
    onBuildingAdded: () => void;
};

export const AddBuildingPage: React.FC<AddBuildingPageProps> = ({
    onBuildingAdded,
    countryCode,
    locale,
}) => {
    const history = useHistory();
    const [errorMessage, setErrorMessage] = useState<ErrorMessage | undefined>(undefined);
    const [addressInput, setAddressInput] = useState<AddressInput>({
        ...defaultAddressInput,
        countryId: countryCode,
    });
    const intl = useIntl();

    const {
        data: retrievedAreaData,
    } = useGetAdminBuildingAreasQuery();

    const areasData = retrievedAreaData?.areas
        ? (retrievedAreaData?.areas as AdminBuildingAreaFragment[])
        : [];

    const selectItems: { id: string; title: string }[] = [];
    areasData.map((item) =>
        selectItems.push({
            id: item.id,
            title: item.code,
        }),
    );
    const [selectIndex,] = useState<number>(0);

    const [newBuilding, setNewBuilding] = useState<AdminAddBuildingInput>({
        code: '',
        description: '',
        address1: '',
        address2: '',
        city: '',
        zoneId: '',
        countryId: 'CA',
        zip: '',
        phone: '',
        firstName: '',
        lastName: '',
        areaId: ''
    });

    const [
        adminAddBuildingMutation,
        {
            loading: adminAddBuildingDetailMutationLoading,
            error: adminAddBuildingDetailMutationError,
        },
    ] = useAdminAddBuildingMutation({
        onCompleted(data) {
            if (data) {
                setNewBuilding(
                    {
                        code: '',
                        description: '',
                        address1: '',
                        address2: '',
                        city: '',
                        zoneId: '',
                        countryId: 'CA',
                        zip: '',
                        phone: '',
                        firstName: '',
                        lastName: '',
                        areaId: ''
                    },
                );
                onBuildingAdded();
            }
        },
    });

    const handleAddBuildingClick = useCallback(async () => {
        if (newBuilding) {
            try {
                const invalidAddress = validateAddressInput(addressInput, intl);
                if (invalidAddress) {
                    return setErrorMessage(invalidAddress);
                } else {
                    setErrorMessage(undefined);
                    await adminAddBuildingMutation({
                        variables: {
                            data: {
                                ...newBuilding,
                            },
                        },
                    });
                }
            } catch (ignore) { }
        }
    }, [
        adminAddBuildingMutation,
        newBuilding,
        addressInput,
        setErrorMessage,
        intl
    ]);

    const handleBacklClick = useCallback(async () => {
        history.push(`/admin/buildings`);
    }, [history]);

    const handleAddressChanged = useCallback(
        async (changedAddressInput: AddressInput): Promise<void> => {
            try {
                const {
                    address1,
                    address2,
                    city,
                    zoneId,
                    countryId,
                    zip,
                    phone,
                    firstName,
                    lastName,
                } = changedAddressInput;
                setAddressInput(changedAddressInput);
                setNewBuilding({
                    ...newBuilding,
                    address1,
                    address2,
                    city,
                    zoneId,
                    countryId,
                    zip,
                    phone,
                    firstName,
                    lastName,
                });
                defaultAddressInput.countryId = countryId;
            } catch (ignore) { } //eslint-disable-line no-empty
        },
        [setAddressInput, newBuilding],
    );

    return (
        <ScrollView>
            <View style={buildingPageStyles.ViewParent} testID="data-AddBuilding">
                <View style={buildingPageStyles.ViewChild}>
                    <View style={buildingPageStyles.ViewContentParent}>
                        <View style={buildingPageStyles.ViewTitleParent}>
                            <Text style={buildingPageStyles.TextTitle} testID="data-newBuilding">
                                {`${intl.formatMessage({
                                    id: 'addBuilding.newBuilding',
                                    defaultMessage: 'New Building Details:',
                                })}${''}`}
                            </Text>
                        </View>
                        {errorMessage && (
                            <Message
                                type={errorMessage.type}
                                header={errorMessage.header}
                                content={errorMessage.content}
                            />
                        )}
                        {adminAddBuildingDetailMutationLoading && (
                            <View testID="data-AddBuildingItemLoading">
                                <Text>
                                    <FormattedMessage id="AddBuilding.loading" defaultMessage="Loading..." />
                                </Text>
                            </View>
                        )}
                        {adminAddBuildingDetailMutationError && (
                            <View style={buildingPageStyles.ViewErrorMessageParent} testID="data-errorMessage">
                                <Message
                                    type="negative"
                                    header={intl.formatMessage({
                                        id: 'AddBuilding.addBuildingErrorMessage',
                                        defaultMessage: 'Could not add newBuilding - please try again',
                                    })}
                                    content={adminAddBuildingDetailMutationError.message}
                                    testID="data-AddBuildingErrorMessage"
                                />
                            </View>
                        )}
                        <View style={buildingPageStyles.ViewTitleParent}>
                            <View style={buildingPageStyles.ViewDescription}>
                                <View>
                                    <FormattedMessage
                                        id="addBuilding.code"
                                        defaultMessage="Code"
                                    />
                                    <TextInput
                                        type="text"
                                        ariaLabel="Code"
                                        value={newBuilding?.code || ''}
                                        onChange={(value: string) => {
                                            setNewBuilding({ ...newBuilding, code: value });
                                        }}
                                    />
                                </View>
                            </View>
                            <View style={buildingPageStyles.ViewDescription}>
                                <View>
                                    <FormattedMessage
                                        id="addBuilding.description"
                                        defaultMessage="Description"
                                    />
                                    <TextInput
                                        type="text"
                                        ariaLabel="Description"
                                        value={newBuilding?.description || ''}
                                        onChange={(value: string) => {
                                            setNewBuilding({ ...newBuilding, description: value });
                                        }}
                                    />
                                </View>
                            </View>
                            <View style={buildingPageStyles.ViewDescription}>
                                <FormattedMessage
                                    id="addBuilding.Area"
                                    defaultMessage="Area"
                                />
                                <Select
                                    currentIndex={selectIndex}
                                    itemsDisplayValues={selectItems.map((items) => items.title)}
                                    onSelect={(index: any) =>
                                        setNewBuilding({
                                            ...newBuilding,
                                            areaId: selectItems[index].id
                                        })
                                    }
                                >
                                    {selectItems.map((item) => (
                                        <SelectItem title={item.title} key={item.id} />
                                    ))}
                                </Select>
                            </View>
                        </View>
                        <View style={buildingPageStyles.ViewDescription}>
                            <FormattedMessage
                                id="addBuilding.AddressHeader"
                                defaultMessage="Address"
                            />
                            <NewAddressForm
                                locale={locale}
                                countryCode={countryCode}
                                currentAddress={defaultAddressInput}
                                onAddressChanged={handleAddressChanged}
                            />
                        </View>
                    </View>
                </View>
                <View
                    style={{
                        marginVertical: 10,
                        marginHorizontal: 10,
                        display: 'flex',
                        flexDirection: 'row',
                    }}
                >
                    <View style={{ flex: 1, marginRight: 10 }}>
                        <Button
                            title={intl.formatMessage({
                                id: 'addBuilding.Cancel',
                                defaultMessage: 'Cancel',
                            })}
                            onPress={handleBacklClick}
                        />
                    </View>
                    <View style={{ flex: 1 }}>
                        <Button
                            title={intl.formatMessage({
                                id: 'addBuilding.Add',
                                defaultMessage: 'Add',
                            })}
                            onPress={handleAddBuildingClick}
                        />
                    </View>
                </View>
            </View>
        </ScrollView>
    );
};

const buildingPageStyles = StyleSheet.create({
    ViewParent: {
        display: 'flex',
        flexDirection: 'column',
    },
    ViewTabs: {
        marginVertical: 5,
        display: 'flex',
        flexDirection: 'row',
    },
    ViewContent: {
        paddingVertical: 5,
    },
    TextTab: {
        flex: 1,
        color: '#000',
        backgroundColor: '#D4D4D5',
        fontSize: 18,
        textAlign: 'center',
        fontWeight: '700',
    },

    ViewChild: {
        display: 'flex',
        flexDirection: 'row',
    },
    ViewImageParent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    RouteImage: {
        height: 40,
        width: 40,
        resizeMode: 'stretch',
    },
    ViewContentParent: {
        paddingHorizontal: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    ViewTitleParent: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 5,
    },
    ViewTitleChild: {},
    TextTitle: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 10,
    },
    ViewDescription: {
        marginTop: 10,
        fontWeight: 'bold',
    },
    TextDescription: { fontSize: 14 },
    ViewStatus: {},
    TextStatus: {
        fontSize: 12,
        fontWeight: '700',
    },
    ViewDate: {},
    TextDate: {
        fontSize: 12,
    },
    ViewErrorMessageParent: {
        marginTop: 5,
        marginBottom: 5,
    },
});
