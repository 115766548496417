import { gql } from '@apollo/client';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FlatList, Image, ScrollView, StyleSheet, Text, View } from 'react-native';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
    Button,
    Icon,
    TextInput,
    Message,
    TopTabBar,
    TopTab,
    Card,
    ErrorMessage,
} from '@valet/ui-components';
import {
    useRefilingProcessItemMutation,
    useRefileListByIdQuery,
    RefileItemStatus,
    RefileItemProcessItemFragment,
    useRefilingCompleteListMutation,
    useRefilingLocationByBarcodeLazyQuery,
    RefilingProcessItemLocationFragment,
} from '../../../schema-types';

export const refileListPageSize = 100;

const REFILEITEM_PROCESS_ITEM_FRAGMENT = gql`
    fragment refileItemProcessItem on RefileItem {
        id
        status
        storageItem {
            id
            status
            barcode
            container {
                id
                barcode
            }
            storageItemType {
                id
                name
                description
                image
                containerType {
                    id
                }
                metadataDefinitions {
                    id
                    propertyName
                    isRequired
                    propertyType
                    values
                    isActive
                    sortOrder
                }
            }
            metadataValue {
                value
                metadataDefinition {
                    id
                    propertyName
                }
            }
            location {
                description
                barcode
            }
        }
    }
`;

const REFILELIST_PROCESS_ITEM_FRAGMENT = gql`
    fragment refileListProcessItem on RefileList {
        id
        refileListNumber
        items {
            ...refileItemProcessItem
        }
    }
`;

export const REFILELIST_PROCESS_ITEM_QUERY = gql`
    query refileListById($refileListId: String!) {
        refileListById(id: $refileListId) {
            id
            ...refileListProcessItem
        }
    }

    ${REFILELIST_PROCESS_ITEM_FRAGMENT}
    ${REFILEITEM_PROCESS_ITEM_FRAGMENT}
`;

export const REFILELIST_PROCESS_ITEM_MUTATION = gql`
    mutation refilingProcessItem($data: RefilingProcessItemInput!) {
        refilingProcessItem(data: $data) {
            data {
                id
                status
            }
        }
    }
`;

const LOCATION_FRAGMENT = gql`
    fragment RefilingProcessItemLocation on Location {
        id
        barcode
        description
    }
`;

export const RETRIEVE_LOCATION_BY_BARCODE_QUERY = gql`
    query refilingLocationByBarcode($barcode: String!) {
        locationByBarcode(barcode: $barcode) {
            id
            ...RefilingProcessItemLocation
        }
    }

    ${LOCATION_FRAGMENT}
`;

export const REFILING_COMPLETE_LIST_MUTATION = gql`
    mutation refilingCompleteList($data: RefilingCompleteListInput!) {
        refilingCompleteList(data: $data) {
            data {
                id
                status
            }
        }
    }
`;

type RefileListProcessPageProps = {};

export const RefileListProcessPage: React.FC<RefileListProcessPageProps> = () => {
    const history = useHistory();
    const match = useRouteMatch();
    const params: { refileListId?: string } = match.params;
    const refileListId = params.refileListId;

    const [errorMessage, setErrorMessage] = useState<ErrorMessage | undefined>(undefined);
    const intl = useIntl();

    const handleGoBackPress = useCallback((): void => {
        history.push(`/refile`);
    }, [history]);

    useEffect(() => {
        if (!refileListId) {
            handleGoBackPress();
        }
    }, [refileListId, handleGoBackPress]);

    const {
        data: retrievedRefileListData,
        loading: retrievedRefileListLoading,
        error: retrievedRefileListError,
        refetch: retrievedRefileListReFetch,
    } = useRefileListByIdQuery({
        variables: {
            refileListId: refileListId || '',
        },
    });

    const refileListData = useMemo(() => {
        return {
            refileItems: retrievedRefileListData?.refileListById?.items ?? [],
        };
    }, [retrievedRefileListData]);

    useEffect(() => {
        if (!refileListId) {
            handleGoBackPress();
        }
    }, [refileListId, handleGoBackPress]);

    const refileList = useMemo<{
        refiled: RefileItemProcessItemFragment[];
        waiting: RefileItemProcessItemFragment[];
    }>(() => {
        const refiled = [];
        const waiting = [];

        for (let i = 0; i < refileListData.refileItems.length; i++) {
            const item = refileListData.refileItems[i];
            if (item.status === RefileItemStatus.Refiled) {
                refiled.push(item);
            }

            if (item.status === RefileItemStatus.Created) {
                waiting.push(item);
            }
        }

        return {
            refiled: refiled,
            waiting,
        };
    }, [refileListData.refileItems]);

    const [expandBarcodeManualEntry, setExpandBarcodeManualEntry] = useState<boolean>(false);
    const [barcodeEntryValue, setBarcodeEntryValue] = useState<string>('');
    const [currentItem, setCurrentItem] = useState<RefileItemProcessItemFragment | undefined>(
        undefined,
    );

    const [currentLocation, setCurrentLocation] = useState<
        RefilingProcessItemLocationFragment | undefined
    >(undefined);

    const handleManualScanCameraPress = useCallback((): void => {
        console.log('Pressed Manual Scan Camera Icon');
    }, []);

    const handleManualScanKeyboardPress = useCallback((): void => {
        setExpandBarcodeManualEntry(!expandBarcodeManualEntry);
    }, [expandBarcodeManualEntry]);

    const [
        refilingProcessItemMutation,
        { loading: refilingProcessItemMutationLoading, error: refilingProcessItemMutationError },
    ] = useRefilingProcessItemMutation();

    const [
        refilingLocationByBarcode,
        {
            loading: refilingLocationByBarcodeMutationLoading,
            error: refilingLocationByBarcodeMutationError,
        },
    ] = useRefilingLocationByBarcodeLazyQuery({
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            if (data && data.locationByBarcode) setCurrentLocation(data.locationByBarcode);
        },
    });

    const getLocationByBarcode = useCallback(
        async (barcode: string) => {
            setErrorMessage(undefined);
            refilingLocationByBarcode({
                variables: { barcode },
            });

            if (refilingLocationByBarcodeMutationError) {
                setErrorMessage({
                    content: intl.formatMessage({
                        id: 'refileListProcessPage.errorGetLocationByBarcodeContent',
                        defaultMessage: 'Could not find location by barcode',
                    }),
                    type: 'warning',
                    header: intl.formatMessage({
                        id: 'refileListProcessPage.errorGetLocationByBarcodeHeader',
                        defaultMessage: 'There was an issue',
                    }),
                });
                return false;
            }

            return true;
        },
        [intl, refilingLocationByBarcode, refilingLocationByBarcodeMutationError],
    );

    const handleManualScanSubmit = useCallback(async () => {
        setErrorMessage(undefined);
        if (barcodeEntryValue) {
            try {
                if (
                    currentLocation &&
                    currentLocation &&
                    currentLocation?.barcode === barcodeEntryValue
                ) {
                    setCurrentItem(undefined);
                    setCurrentLocation(undefined);
                } else {
                    if (!currentLocation) {
                        const res = await getLocationByBarcode(barcodeEntryValue);
                        if (!res)
                            setErrorMessage({
                                content: intl.formatMessage({
                                    id: 'refileListProcessPage.errorInvalidLocationBarcode',
                                    defaultMessage: 'Please enter valid Location barcode',
                                }),
                                type: 'warning',
                                header: intl.formatMessage({
                                    id: 'refileListProcessPage.errorMessageHeader',
                                    defaultMessage: 'There was an issue',
                                }),
                            });
                    } else if (
                        refileListData.refileItems &&
                        refileListData.refileItems.length > 0
                    ) {
                        let didNotFindItemByBarcode = true;
                        for (let i = 0; i < refileListData.refileItems.length; i++) {
                            const refileItem = refileListData.refileItems[i];

                            if (
                                refileItem.storageItem?.container?.barcode === barcodeEntryValue ||
                                refileItem.storageItem?.barcode === barcodeEntryValue
                            ) {
                                didNotFindItemByBarcode = false;
                                setCurrentItem(refileItem);
                                await refilingProcessItemMutation({
                                    variables: {
                                        data: {
                                            refileListId: refileListId || '',
                                            locationId: currentLocation?.id || '',
                                            itemBarcode: barcodeEntryValue,
                                        },
                                    },
                                });
                                retrievedRefileListReFetch({
                                    refileListId: refileListId || '',
                                });
                                break;
                            }
                        }
                        if (didNotFindItemByBarcode)
                            setErrorMessage({
                                content: intl.formatMessage({
                                    id: 'refileListProcessPage.errorInvalidItemBarcode',
                                    defaultMessage: 'Please enter valid storage item barcode',
                                }),
                                type: 'warning',
                                header: intl.formatMessage({
                                    id: 'refileListProcessPage.errorInvalidItemBarcodeHeader',
                                    defaultMessage: 'There was an issue',
                                }),
                            });
                    }
                }
                setExpandBarcodeManualEntry(!expandBarcodeManualEntry);
                setBarcodeEntryValue('');
            } catch {}
        }
    }, [
        refileListData,
        barcodeEntryValue,
        expandBarcodeManualEntry,
        refilingProcessItemMutation,
        retrievedRefileListReFetch,
        refileListId,
        setErrorMessage,
        currentLocation,
        getLocationByBarcode,
        intl,
    ]);

    const handleClearScreenPress = useCallback((): void => {
        setCurrentItem(undefined);
        setExpandBarcodeManualEntry(false);
    }, []);

    // Top tabs
    const tabs = [
        {
            title: intl.formatMessage({
                id: 'refileListProcessPage.waitingTabText',
                defaultMessage: 'Waiting',
            }),
        },
        {
            title: intl.formatMessage({
                id: 'refileListProcessPage.scannedTabText',
                defaultMessage: 'Scanned',
            }),
        },
    ];
    const [currentTab, setCurrentTab] = useState<number>(0);

    const handleTabSelect = (index: number): void => {
        setCurrentTab(index);
    };

    const [
        refilingCompleteListMutation,
        { loading: refilingCompleteListMutationLoading, error: refilingCompleteListMutationError },
    ] = useRefilingCompleteListMutation({
        onCompleted({ refilingCompleteList: { data } }) {
            if (data) {
                handleGoBackPress();
            }
        },
    });

    const handleConfirmCompleteButtonClick = useCallback(async () => {
        if (refileListId !== undefined) {
            try {
                await refilingCompleteListMutation({
                    variables: { data: { refileListId: refileListId || '' } },
                });
            } catch (err) {
                console.log(err);
            }
        }
    }, [refilingCompleteListMutation, refileListId]);

    return (
        <ScrollView>
            <View style={refileListProcessPageStyles.ViewParent} testID="data-deliveryDetail">
                {(retrievedRefileListLoading ||
                    refilingProcessItemMutationLoading ||
                    refilingLocationByBarcodeMutationLoading ||
                    refilingCompleteListMutationLoading) && (
                    <View testID="data-refilingProcessItemLoading">
                        <Text>
                            <FormattedMessage
                                id="refileListProcessPage.loading"
                                defaultMessage="Loading..."
                            />
                        </Text>
                    </View>
                )}
                {errorMessage && (
                    <Message errorMessage={errorMessage} testID="data-messageRefileError" />
                )}
                {retrievedRefileListError && (
                    <Message
                        type="negative"
                        header="Error"
                        content={intl.formatMessage({
                            id: 'refileListProcessPage.retrievedRefileListErrorMessage',
                            defaultMessage: 'Could not retreive refile list - please try again',
                        })}
                        testID="data-retrievedRefileListErrorMessage"
                    />
                )}
                {refilingLocationByBarcodeMutationError && (
                    <Message
                        type="negative"
                        header="Error"
                        content={intl.formatMessage({
                            id:
                                'refileListProcessPage.refilingLocationByBarcodeMutationErrorMessage',
                            defaultMessage: 'Could not retreive location - please try again',
                        })}
                        testID="data-refilingLocationByBarcodeMutationErrorMessage"
                    />
                )}

                {refilingCompleteListMutationError && (
                    <Message
                        type="negative"
                        header="Error"
                        content={intl.formatMessage({
                            id: 'refileListProcessPage.refilingCompleteListMutationErrorMessage',
                            defaultMessage: 'Could not complete list - please try again',
                        })}
                        testID="data-refilingCompleteListMutationErrorMessage"
                    />
                )}
                {refilingProcessItemMutationError && (
                    <View
                        style={refileListProcessPageStyles.ViewErrorMessageParent}
                        testID="data-errorMessage"
                    >
                        <Message
                            type="negative"
                            content={intl.formatMessage({
                                id: 'refileListProcessPage.refileListProcessPageErrorMessage',
                                defaultMessage: 'Could not refile this item - please try again',
                            })}
                            header="Error"
                            testID="data-refileListProcessPageErrorMessage"
                        />
                    </View>
                )}

                {currentLocation && (
                    <View
                        style={refileListProcessPageStyles.ViewLocationMessageParent}
                        testID="data-locationMessage"
                    >
                        <Message
                            type="info"
                            header={`${intl.formatMessage({
                                id: 'refileListProcessPage.refileListProcessPageLocationMessage',
                                defaultMessage: `Current Location: `,
                            })}${currentLocation?.barcode}`}
                            content=""
                            testID="data-refileListProcessPageCurrentLocationMessage"
                        />
                    </View>
                )}

                <View>
                    <View style={refileListProcessPageStyles.ViewTopBar}>
                        <Button
                            accessoryLeft={() => <Icon icon="back" />}
                            title={intl.formatMessage({
                                id: 'deliveryRouteDetail.back',
                                defaultMessage: 'Back',
                            })}
                            onPress={handleGoBackPress}
                            appearance="ghost"
                        />
                    </View>
                    <TopTabBar onSelect={handleTabSelect} selectedIndex={currentTab}>
                        {tabs.map((tab) => (
                            <TopTab key={tab.title} title={tab.title} />
                        ))}
                    </TopTabBar>
                    <View style={refileListProcessPageStyles.ViewContent}>
                        {currentTab === 0 && refileList.waiting.length > 0 && (
                            <View style={refileListProcessPageStyles.ViewContent}>
                                <Text>
                                    <FormattedMessage
                                        id="refileListProcessPage.scanTargetLocation"
                                        defaultMessage={
                                            currentLocation
                                                ? 'Please scan item to refile'
                                                : 'Please scan target location.'
                                        }
                                    />
                                </Text>
                            </View>
                        )}

                        <View style={refileListProcessPageStyles.ViewContent}>
                            {(currentTab === 0 && refileList.waiting.length > 0) ||
                            (currentTab === 1 && refileList.refiled.length > 0) ? (
                                <FlatList
                                    data={
                                        currentTab === 0 ? refileList.waiting : refileList.refiled
                                    }
                                    renderItem={({ item }) => (
                                        <RefileItemDetail refileItem={item} />
                                    )}
                                    keyExtractor={(item) => item.id}
                                />
                            ) : (
                                <Text>
                                    {currentTab === 0 ? (
                                        <FormattedMessage
                                            id="refileListProcessPage.noItemsToRefile"
                                            defaultMessage="There are no item to refile."
                                        />
                                    ) : (
                                        <FormattedMessage
                                            id="refileListProcessPage.noRefiledItem"
                                            defaultMessage="There are no refiled item."
                                        />
                                    )}
                                </Text>
                            )}
                        </View>
                    </View>

                    <View
                        style={refileListProcessPageStyles.ViewActionsGroup}
                        testID="data-refilingProcessItemActionsContainer"
                    >
                        {expandBarcodeManualEntry && (
                            <View
                                style={{
                                    padding: 3,
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                                testID="data-refilingProcessItemManualBarcodeEntryContainer"
                            >
                                <View style={{ flex: 1, marginRight: 5 }}>
                                    <TextInput
                                        type="text"
                                        onChange={(e) => setBarcodeEntryValue(e)}
                                        value={barcodeEntryValue}
                                        ariaLabel={intl.formatMessage({
                                            id:
                                                'refilingProcessItemManualBarcodeEntryPlaceholderText',
                                            defaultMessage: 'Enter item barcode',
                                        })}
                                        placeholder={intl.formatMessage({
                                            id:
                                                'refilingProcessItemManualBarcodeEntryPlaceholderText',
                                            defaultMessage: 'Enter item barcode',
                                        })}
                                        testID="data-refilingProcessItemManualBarcodeEntryInput"
                                    />
                                </View>

                                <View style={{ marginLeft: 5 }}>
                                    <Button
                                        onPress={handleManualScanSubmit}
                                        title={intl.formatMessage({
                                            id: 'refilingProcessItemScanButton',
                                            defaultMessage: 'SCAN',
                                        })}
                                        testID="data-refilingProcessItemManualBarcodeEntrySubmitButton"
                                    />
                                </View>
                            </View>
                        )}
                        <View style={{ display: 'flex', flexDirection: 'row' }}>
                            <View style={refileListProcessPageStyles.ViewManualScanIcons}>
                                {currentItem && (
                                    <Button
                                        accessoryLeft={() => <Icon icon="pickListCancelled" />}
                                        onPress={handleClearScreenPress}
                                        testID="data-refilingProcessItemActionKeyboard"
                                        title={''}
                                        appearance="ghost"
                                        accessibilityLabel={intl.formatMessage({
                                            id: 'refilingProcessItemCancelButton',
                                            defaultMessage: 'Go back',
                                        })}
                                    />
                                )}

                                <Button
                                    accessoryLeft={() => <Icon icon="barcodeCamera" />}
                                    onPress={handleManualScanCameraPress}
                                    testID="data-refilingProcessItemActionCamera"
                                    title={''}
                                    appearance="ghost"
                                    accessibilityLabel={intl.formatMessage({
                                        id: 'refilingProcessItemCameraButton',
                                        defaultMessage: 'Open camera to scan item',
                                    })}
                                />

                                <Button
                                    accessoryLeft={() => <Icon icon="barcodeKeyboard" />}
                                    onPress={handleManualScanKeyboardPress}
                                    testID="data-refilingProcessItemActionKeyboard"
                                    title={''}
                                    appearance="ghost"
                                    accessibilityLabel={intl.formatMessage({
                                        id: 'refilingProcessItemKeyboardButton',
                                        defaultMessage: 'Open keyboard to enter barcode',
                                    })}
                                />
                            </View>
                        </View>
                        <View
                            style={{
                                flexDirection: 'row',
                                margin: 5,
                            }}
                        >
                            <Button
                                title={intl.formatMessage({
                                    id: 'deliverycompletebutton',
                                    defaultMessage: 'Confirm Completion',
                                })}
                                onPress={() => {
                                    handleConfirmCompleteButtonClick();
                                }}
                            />
                        </View>
                    </View>
                </View>
            </View>
        </ScrollView>
    );
};

const refileListProcessPageStyles = StyleSheet.create({
    ViewParent: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    ViewTabs: {
        marginVertical: 5,
        display: 'flex',
        flexDirection: 'row',
    },
    ViewContent: {
        paddingVertical: 5,
    },
    TextTab: {
        flex: 1,
        color: '#000',
        backgroundColor: '#D4D4D5',
        fontSize: 18,
        textAlign: 'center',
        fontWeight: '700',
    },
    ViewTopBar: {
        paddingBottom: 5,
        display: 'flex',
        flexDirection: 'row' as 'row',
        alignItems: 'center',
        borderBottomWidth: 1,
        borderBottomColor: '#d4d4d5',
    },
    ViewActionsGroup: {
        marginVertical: 5,
        display: 'flex',
        flexDirection: 'column',
        borderTopColor: '#D4D4D5',
        borderTopWidth: 1,
    },
    ViewButton: {
        marginTop: 10,
    },
    CancelAction: {
        borderWidth: 1,
        borderRadius: 5,
        padding: 5,
    },
    ViewDetailGroup: {
        marginVertical: 2,
        display: 'flex',
        flexDirection: 'row',
    },
    DeliveryHeader: {
        fontWeight: '700',
        flex: 1,
    },

    ViewExpandPickList: {
        flex: 1,
        paddingVertical: 5,
        display: 'flex',
        overflowY: 'scroll',
        overflowX: 'none',
    },
    ViewExpandPickListText: {
        marginVertical: 5,
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
    },
    ViewManualScanIcons: {
        marginVertical: 5,
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    ViewActions: {
        marginVertical: 5,
        display: 'flex',
        flexDirection: 'row',
    },
    ViewNavigationButtons: {
        flex: 1,
        marginRight: 5,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    TextActionList: {
        alignSelf: 'flex-start',
        color: '#0984e3',
    },
    TextCurrentItemNumber: {
        fontWeight: '700',
        fontSize: 18,
        textAlign: 'center',
    },
    ViewItem: {
        flex: 1,
    },
    TextHeader: {
        fontWeight: '700',
    },
    ViewErrorMessageParent: {
        marginTop: 5,
        marginBottom: 5,
    },
    ViewLocationMessageParent: {
        marginTop: 5,
        marginBottom: 5,
    },
});

type RefileItemDetailProps = {
    refileItem: RefileItemProcessItemFragment;
};

const RefileItemDetail: React.FC<RefileItemDetailProps> = ({ refileItem: refileList }) => {
    const { storageItem, status } = refileList;

    return (
        <Card
            onPress={() => null}
            style={RefileItemDetailStyles.ViewParent}
            testID="data-refileListDetails"
        >
            <View style={RefileItemDetailStyles.ViewDetailGroup}>
                <Image
                    style={RefileItemDetailStyles.ImageItem}
                    source={{ uri: storageItem?.storageItemType.image }}
                    testID="data-RefileItemDetailImage"
                />
                <View>
                    <View style={RefileItemDetailStyles.ViewDetailGroup}>
                        <Text
                            style={RefileItemDetailStyles.TextItemValue}
                            testID="data-RefileItemDetailDescription"
                        >
                            {storageItem?.storageItemType.description}
                        </Text>
                    </View>
                    <View style={RefileItemDetailStyles.ViewDetailGroup}>
                        <Text
                            style={RefileItemDetailStyles.CustomerTextItemLabel}
                            testID="data-RefileItemDetailLocation"
                        >
                            <FormattedMessage
                                id="refileListDetails.barcode"
                                defaultMessage="Barcode"
                            />
                            :
                        </Text>
                        <Text style={RefileItemDetailStyles.TextItemValue}>
                            {storageItem?.container?.barcode || storageItem?.barcode}
                        </Text>
                    </View>
                    <View style={RefileItemDetailStyles.ViewDetailGroup}>
                        <Text
                            style={RefileItemDetailStyles.CustomerTextItemLabel}
                            testID="data-RefileItemDetailLocation"
                        >
                            <FormattedMessage
                                id="refileListDetails.customer"
                                defaultMessage="Status: "
                            />
                        </Text>
                        <Text style={RefileItemDetailStyles.TextItemValue}>{status}</Text>
                    </View>
                    <View style={RefileItemDetailStyles.ViewDetailGroup}>
                        <Text
                            style={RefileItemDetailStyles.CustomerTextItemLabel}
                            testID="data-RequestDetailsLocation"
                        >
                            <FormattedMessage
                                id="refileListDetails.location"
                                defaultMessage="Location"
                            />
                            :
                        </Text>
                        <Text style={RefileItemDetailStyles.TextItemValue}>
                            {`${storageItem?.location?.description} (${storageItem?.location?.barcode})`}
                        </Text>
                    </View>
                </View>
            </View>
        </Card>
    );
};

const RefileItemDetailStyles = StyleSheet.create({
    ViewParent: {
        marginVertical: 3,
    },
    ViewDetailGroup: {
        marginVertical: 2,
        display: 'flex',
        flexDirection: 'row',
    },
    ViewImageGroup: {
        marginVertical: 5,
        display: 'flex',
        justifyContent: 'center',
    },
    TextDetailHeader: {
        fontWeight: '700',
    },
    ImageItem: {
        height: 40,
        width: 40,
        resizeMode: 'stretch',
        marginLeft: 5,
        marginRight: 10,
    },

    ViewItemsList: {
        marginVertical: 5,
    },
    ViewItem: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
    },
    ViewItemsSummary: {
        marginVertical: 5,
    },
    ViewAssignee: {
        marginVertical: 5,
    },
    TextHeader: {
        fontWeight: '700',
    },
    TextItemLabel: {
        flex: 1,
    },
    CustomerTextItemLabel: {
        flex: 0,
        marginRight: 5,
    },
    TextItemValue: {
        flex: 1,
        fontWeight: '700',
    },
    TouchableOpacityItem: {
        borderWidth: 1,
        borderRadius: 5,
        padding: 5,
    },
    TextDescription: {
        fontWeight: '700',
    },
    ViewButton: {},
});
