import { gql } from '@apollo/client';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    FlatList,
    Image,
    ScrollView,
    StyleSheet,
    Text,
    TouchableOpacity,
    View,
    Animated,
} from 'react-native';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Button, Icon, TextInput, Message } from '@valet/ui-components';
import {
    useDeliverEmptyContainerRequestsByVisitIdQuery,
    RequestType,
    StorageItem,
    DeliverEmptyContainerRequestsByVisitIdDeliverRequestFragment,
    DeliverEmptyContainerRequestsByVisitIdStorageItemFragment,
    RequestStatus,
    useDeliveryDeliverEmptyContainerMutation,
} from '../../../schema-types';

type MenuItem = {
    key: string;
    label: string;
};

const RETRIEVE_REQUESTS_QUERY_STORAGE_ITEM_FRAGMENT = gql`
    fragment deliverEmptyContainerRequestsByVisitIdStorageItem on StorageItem {
        id
        status
        barcode
        storageItemType {
            id
            name
            description
            image
            metadataDefinitions {
                id
                propertyName
                isRequired
                propertyType
                values
                isActive
                sortOrder
            }
        }
        metadataValue {
            value
            metadataDefinition {
                id
                propertyName
            }
        }
    }
`;

const RETRIEVE_REQUESTS_QUERY_DELIVERY_EMPTY_CONTAINER_REQUEST_FRAGMENT = gql`
    fragment deliverEmptyContainerRequestsByVisitIdDeliverRequest on DeliverEmptyContainerRequest {
        id
        status
        requestType
        container {
            id
            status
            barcode
        }
        storageItem {
            id
            ...deliverEmptyContainerRequestsByVisitIdStorageItem
        }
        customer {
            email
            firstName
            lastName
            homePhone
            cellPhone
        }
    }

    ${RETRIEVE_REQUESTS_QUERY_STORAGE_ITEM_FRAGMENT}
`;

export const RETRIEVE_REQUESTS_QUERY = gql`
    query deliverEmptyContainerRequestsByVisitId(
        $visitId: String!
        $pageSize: Int!
        $cursor: String!
    ) {
        requestsByVisitId(visitId: $visitId, filter: { pageSize: $pageSize, after: $cursor }) {
            edges {
                cursor
                node {
                    id
                    requestType
                    ...deliverEmptyContainerRequestsByVisitIdDeliverRequest
                }
            }
            pageInfo {
                cursor {
                    beforeCursor
                    afterCursor
                }
                hasNextPage
                hasPreviousPage
            }
        }
    }
    ${RETRIEVE_REQUESTS_QUERY_DELIVERY_EMPTY_CONTAINER_REQUEST_FRAGMENT}
`;

export const DELIVERY_DELIVER_ITEM_MUTATION = gql`
    mutation deliveryDeliverEmptyContainer($data: DeliveryDeliverEmptyContainerInput!) {
        deliveryDeliverEmptyContainer(data: $data) {
            data {
                id
                status
            }
        }
    }
`;

type DeliveryDeliverEmptyContainerProcessProps = {};

export const requestPageSize = 100;
export const DeliveryDeliverEmptyContainerProcess: React.FC<DeliveryDeliverEmptyContainerProcessProps> = () => {
    const history = useHistory();
    const match = useRouteMatch();
    const params: { routeId?: string; visitId?: string } = match.params;
    const routeId = params.routeId;
    const visitId = params.visitId;

    const [showActionMenu, setShowActionMenu] = useState<boolean>(false);
    const intl = useIntl();

    const handleGoBackPress = useCallback((): void => {
        history.push(`/delivery/${routeId}/${visitId}`);
    }, [history, routeId, visitId]);

    useEffect(() => {
        if (!visitId) {
            handleGoBackPress();
        }
    }, [visitId, handleGoBackPress]);

    const [isFetching, setIsFetching] = useState<boolean>(false);

    const {
        data: retrievedRequestsData,
        loading: retrievedRequestsLoading,
        error: retrievedRequestsError,
        fetchMore: retrievedRequestsFetchMore,
    } = useDeliverEmptyContainerRequestsByVisitIdQuery({
        variables: {
            visitId: visitId || '',
            pageSize: requestPageSize,
            cursor: '',
        },
    });

    const requestEdges = retrievedRequestsData?.requestsByVisitId.edges ?? [];
    const requestData = useMemo(() => {
        return {
            requests:
                requestEdges
                    .map((itemEdge) => itemEdge.node)
                    .filter((request) => request.requestType === RequestType.DeliverEmptyContainer)
                    .map(
                        (request) =>
                            request as DeliverEmptyContainerRequestsByVisitIdDeliverRequestFragment,
                    ) ?? [],
            hasNextPage: retrievedRequestsData?.requestsByVisitId?.pageInfo?.hasNextPage ?? false,
            endCursor: retrievedRequestsData?.requestsByVisitId?.pageInfo?.cursor.afterCursor ?? '',
        };
    }, [requestEdges, retrievedRequestsData]);

    const handleLoadMoreItems = useCallback((): void => {
        if (requestData.hasNextPage && !isFetching) {
            setIsFetching(true);
            retrievedRequestsFetchMore({
                variables: {
                    pageSize: requestPageSize,
                    cursor: requestData.endCursor,
                },
            }).then(() => {
                setIsFetching(false);
            });
        }
    }, [isFetching, retrievedRequestsFetchMore, requestData.hasNextPage, requestData.endCursor]);

    useEffect(() => {
        if (!retrievedRequestsError && requestData.hasNextPage) {
            handleLoadMoreItems();
        }
    }, [handleLoadMoreItems, requestData, retrievedRequestsError]);

    useEffect(() => {
        if (!routeId) {
            handleGoBackPress();
        }
    }, [routeId, handleGoBackPress]);

    const requests: {
        scanned: DeliverEmptyContainerRequestsByVisitIdDeliverRequestFragment[];
        waiting: { containerTypeName: string; quantity: number; image?: string }[];
    } = useMemo(() => {
        const tmpRequests: {
            scanned: DeliverEmptyContainerRequestsByVisitIdDeliverRequestFragment[];
            waiting: { containerTypeName: string; quantity: number; image?: string }[];
        } = {
            scanned: [],
            waiting: [],
        };
        const deliveryRequests = requestData.requests.filter(
            (r) => r.requestType === RequestType.DeliverEmptyContainer,
        );
        const waitingItems: { [key: string]: { quantity: number; image: string } } = {};
        for (let i = 0; i < deliveryRequests.length; i++) {
            const item = deliveryRequests[i];
            if (item.status === RequestStatus.Processed) {
                tmpRequests.scanned.push(item);
            }

            if (item.status !== 'PROCESSED') {
                const itemType = item.storageItem?.storageItemType?.name || 'no type';
                if (waitingItems[itemType]) {
                    waitingItems[itemType].quantity++;
                } else {
                    waitingItems[itemType] = {
                        quantity: 1,
                        image: item.storageItem?.storageItemType?.image || '',
                    };
                }
            }
        }

        Object.keys(waitingItems).forEach((key: string) => {
            tmpRequests.waiting.push({ containerTypeName: key, ...waitingItems[key] });
        });

        return tmpRequests;
    }, [requestData.requests]);

    const [selectedStorageItem, setSelectedStorageItem] = useState<
        DeliverEmptyContainerRequestsByVisitIdStorageItemFragment | undefined
    >();

    useEffect(() => {
        if (!routeId) {
            handleGoBackPress();
        }
    }, [routeId, handleGoBackPress]);

    const handleItemClick = useCallback(
        (id: string) => {
            if (requestData.requests) {
                const req = requestData.requests.find((r) => r.id === id);
                setSelectedStorageItem(req?.storageItem || undefined);
            }
            setShowActionMenu(!showActionMenu);
        },
        [requestData.requests, showActionMenu],
    );

    const menuItems: MenuItem[] = [];

    const [expandBarcodeManualEntry, setExpandBarcodeManualEntry] = useState<boolean>(false);
    const [barcodeEntryValue, setBarcodeEntryValue] = useState<string>('');
    const [currentItem, setCurrentItem] = useState<StorageItem | undefined>(undefined);

    const handleManualScanCameraPress = useCallback((): void => {
        console.log('Pressed Manual Scan Camera Icon');
    }, []);

    const handleManualScanKeyboardPress = useCallback((): void => {
        setExpandBarcodeManualEntry(!expandBarcodeManualEntry);
    }, [expandBarcodeManualEntry]);

    const [
        deliveryDeliverEmptyContainerMutation,
        {
            loading: deliveryDeliverEmptyContainerMutationLoading,
            error: deliveryDeliverEmptyContainerMutationError,
        },
    ] = useDeliveryDeliverEmptyContainerMutation({
        onCompleted({ deliveryDeliverEmptyContainer: { data } }) {
            if (data) {
                handleLoadMoreItems();
            }
        },
    });

    const handleManualScanSubmit = useCallback(async () => {
        if (barcodeEntryValue) {
            const waitingRequests = requestData.requests.filter(
                (r) =>
                    r.requestType === RequestType.DeliverEmptyContainer &&
                    r.status === RequestStatus.InTransitToCustomer,
            );
            if (waitingRequests && waitingRequests.length > 0) {
                const request = waitingRequests[0];
                await deliveryDeliverEmptyContainerMutation({
                    variables: {
                        data: {
                            requestId: request.id,
                            containerBarcode: barcodeEntryValue,
                        },
                    },
                });
            }
            setExpandBarcodeManualEntry(!expandBarcodeManualEntry);
            setBarcodeEntryValue('');
        }
    }, [
        requestData.requests,
        barcodeEntryValue,
        expandBarcodeManualEntry,
        deliveryDeliverEmptyContainerMutation,
    ]);

    const handleClearScreenPress = useCallback((): void => {
        setCurrentItem(undefined);
        setExpandBarcodeManualEntry(false);
    }, []);

    const handleMenuItemClick = useCallback(
        (key: string) => {
            setShowActionMenu(!showActionMenu);
        },
        [showActionMenu],
    );

    const fadeAnimWaiting = useRef(new Animated.Value(1)).current;
    const fadeAnimScanned = useRef(new Animated.Value(0.5)).current;
    const [activeTab, setActiveTab] = useState<'Waiting' | 'Scanned'>('Waiting');

    const handleTabPress = (tab: 'Waiting' | 'Scanned'): void => {
        setActiveTab(tab);
        if (tab === 'Waiting') {
            updateTabAnimation(fadeAnimWaiting, [fadeAnimScanned]);
        }

        if (tab === 'Scanned') {
            updateTabAnimation(fadeAnimScanned, [fadeAnimWaiting]);
        }
    };

    function updateTabAnimation(activeAnim: Animated.Value, otherAnims: Animated.Value[]): void {
        Animated.timing(activeAnim, {
            toValue: 1,
            duration: 250,
        }).start();
        otherAnims.map((anim2) =>
            Animated.timing(anim2, {
                toValue: 0.5,
                duration: 250,
            }).start(),
        );
    }

    if (isFetching || retrievedRequestsLoading || deliveryDeliverEmptyContainerMutationLoading) {
        return (
            <View testID="data-deliveryDeliverEmptyContainerLoading">
                <Text>
                    <FormattedMessage
                        id="deliveryDeliverProcess.loading"
                        defaultMessage="Loading..."
                    />
                </Text>
            </View>
        );
    }

    if (deliveryDeliverEmptyContainerMutationError) {
        return (
            <View
                style={deliveryDeliverProcessStyles.ViewErrorMessageParent}
                testID="data-errorMessage"
            >
                <Message
                    type="negative"
                    header={intl.formatMessage({
                        id: 'deliveryDeliverProcess.deliveryDeliverProcessErrorMessage',
                        defaultMessage: 'Could not deliver this item - please try again',
                    })}
                    content={deliveryDeliverEmptyContainerMutationError.message}
                    testID="data-deliveryDeliverProcessErrorMessage"
                />
            </View>
        );
    }

    return (
        <ScrollView>
            <View style={deliveryDeliverProcessStyles.ViewParent} testID="data-deliveryDetail">
                {!showActionMenu && (
                    <View>
                        <View style={deliveryDeliverProcessStyles.ViewTopBar}>
                            <Text onPress={handleGoBackPress}>
                                <Icon icon="back" />{' '}
                                <FormattedMessage
                                    id="deliveryRouteDetail.back"
                                    defaultMessage="Back"
                                />
                            </Text>
                        </View>
                        <View style={deliveryDeliverProcessStyles.ViewTabs}>
                            <Animated.Text
                                style={[
                                    deliveryDeliverProcessStyles.TextTab,
                                    { opacity: fadeAnimWaiting },
                                ]}
                                onPress={() => handleTabPress('Waiting')}
                                testID="data-deliveryDeliverProcessWaitingTab"
                            >
                                <FormattedMessage
                                    id="deliveryDeliverProcess.waitingTabText"
                                    defaultMessage="Waiting"
                                />
                            </Animated.Text>
                            <Animated.Text
                                style={[
                                    deliveryDeliverProcessStyles.TextTab,
                                    { opacity: fadeAnimScanned },
                                ]}
                                onPress={() => handleTabPress('Scanned')}
                                testID="data-deliveryDeliverProcessScannedTab"
                            >
                                <FormattedMessage
                                    id="deliveryDeliverProcess.scannedTabText"
                                    defaultMessage="Scanned"
                                />
                            </Animated.Text>
                        </View>
                        <View style={deliveryDeliverProcessStyles.ViewContent}>
                            <View style={deliveryDeliverProcessStyles.ViewContent}>
                                {activeTab === 'Waiting' ? (
                                    requests.waiting.length > 0 ? (
                                        <FlatList
                                            data={requests.waiting}
                                            renderItem={({ item }) => <WaitingItem item={item} />}
                                            keyExtractor={(item) => item.containerTypeName}
                                        />
                                    ) : (
                                        <Text>
                                            <FormattedMessage
                                                id="deliveryDeliverProcess.noRequestText"
                                                defaultMessage="There are no waiting request for this visit."
                                            />
                                        </Text>
                                    )
                                ) : activeTab === 'Scanned' ? (
                                    requests.scanned.length > 0 ? (
                                        <FlatList
                                            data={requests.scanned}
                                            renderItem={({ item }) => (
                                                <RequestDetails
                                                    request={item}
                                                    onItemClick={handleItemClick}
                                                />
                                            )}
                                            keyExtractor={(item) => item.id}
                                        />
                                    ) : (
                                        <Text>
                                            <FormattedMessage
                                                id="deliveryDeliverProcess.noScannedRequestText"
                                                defaultMessage="no scanned request."
                                            />
                                        </Text>
                                    )
                                ) : (
                                    <Text>
                                        <FormattedMessage
                                            id="deliveryDeliverProcess.noRequestsText"
                                            defaultMessage="There are no requests for this visit."
                                        />
                                    </Text>
                                )}
                            </View>
                        </View>

                        <View
                            style={deliveryDeliverProcessStyles.ViewActionsGroup}
                            testID="data-deliveryDeliverEmptyContainerActionsContainer"
                        >
                            {expandBarcodeManualEntry && (
                                <View
                                    style={{
                                        padding: 3,
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                    testID="data-deliveryDeliverEmptyContainerManualBarcodeEntryContainer"
                                >
                                    <View style={{ flex: 1, marginRight: 5 }}>
                                        <TextInput
                                            type="text"
                                            onChange={(e) => setBarcodeEntryValue(e)}
                                            value={barcodeEntryValue}
                                            placeholder={intl.formatMessage({
                                                id:
                                                    'deliveryDeliverEmptyContainerManualBarcodeEntryPlaceholderText',
                                                defaultMessage: 'Enter item barcode',
                                            })}
                                            testID="data-deliveryDeliverEmptyContainerManualBarcodeEntryInput"
                                            ariaLabel={intl.formatMessage({
                                                id:
                                                    'deliveryDeliverEmptyContainerManualBarcodeEntryPlaceholderText',
                                                defaultMessage: 'Enter item barcode',
                                            })}
                                        />
                                    </View>

                                    <View style={{ marginLeft: 5 }}>
                                        <Button
                                            onPress={handleManualScanSubmit}
                                            title={intl.formatMessage({
                                                id: 'deliveryDeliverEmptyContainerScanButton',
                                                defaultMessage: 'SCAN',
                                            })}
                                            testID="data-deliveryDeliverEmptyContainerManualBarcodeEntrySubmitButton"
                                        />
                                    </View>
                                </View>
                            )}
                            <View style={{ display: 'flex', flexDirection: 'row' }}>
                                <View style={deliveryDeliverProcessStyles.ViewManualScanIcons}>
                                    {currentItem && (
                                        <TouchableOpacity
                                            onPress={handleClearScreenPress}
                                            style={[{ marginLeft: 5, flex: 1 }]}
                                            testID="data-deliveryDeliverEmptyContainerActionKeyboard"
                                        >
                                            <Icon icon="pickListCancelled" />
                                        </TouchableOpacity>
                                    )}

                                    <TouchableOpacity
                                        onPress={handleManualScanCameraPress}
                                        style={[{ marginRight: 5 }]}
                                        testID="data-deliveryDeliverEmptyContainerActionCamera"
                                    >
                                        <Icon icon="barcodeCamera" />
                                    </TouchableOpacity>

                                    <TouchableOpacity
                                        onPress={handleManualScanKeyboardPress}
                                        style={[{ marginLeft: 5 }]}
                                        testID="data-deliveryDeliverEmptyContainerActionKeyboard"
                                    >
                                        <Icon icon="barcodeKeyboard" />
                                    </TouchableOpacity>
                                </View>
                            </View>
                        </View>
                    </View>
                )}

                {showActionMenu && (
                    <View>
                        <View style={{ flex: 1 }}>
                            <View style={deliveryDeliverProcessStyles.ViewDetailGroup}>
                                <Text
                                    style={deliveryDeliverProcessStyles.DeliveryHeader}
                                    testID="data-routeStopDetailsDescription"
                                >
                                    {selectedStorageItem?.storageItemType.name}
                                </Text>
                                <TouchableOpacity
                                    style={deliveryDeliverProcessStyles.CancelAction}
                                    onPress={() => {
                                        setShowActionMenu(!showActionMenu);
                                    }}
                                >
                                    <Icon icon="pickListCancelled" />
                                </TouchableOpacity>
                            </View>
                        </View>
                        <View style={deliveryDeliverProcessStyles.ViewContent}>
                            <FlatList
                                data={menuItems}
                                renderItem={({ item }) => (
                                    <View style={deliveryDeliverProcessStyles.ViewButton}>
                                        <Button
                                            title={intl.formatMessage({
                                                id: item.key,
                                                defaultMessage: item.label,
                                            })}
                                            onPress={() => {
                                                handleMenuItemClick(item.key);
                                            }}
                                        />
                                    </View>
                                )}
                                keyExtractor={(item) => item.key}
                            />
                        </View>
                    </View>
                )}
            </View>
        </ScrollView>
    );
};

const deliveryDeliverProcessStyles = StyleSheet.create({
    ViewParent: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    ViewTabs: {
        marginVertical: 5,
        display: 'flex',
        flexDirection: 'row',
    },
    ViewContent: {},
    TextTab: {
        flex: 1,
        color: '#000',
        backgroundColor: '#D4D4D5',
        fontSize: 18,
        textAlign: 'center',
        fontWeight: '700',
    },
    ViewTopBar: {
        paddingBottom: 5,
        display: 'flex',
        flexDirection: 'row' as 'row',
        alignItems: 'center',
        borderBottomWidth: 1,
        borderBottomColor: '#d4d4d5',
    },
    ViewActionsGroup: {
        marginVertical: 5,
        display: 'flex',
        flexDirection: 'column',
        borderTopColor: '#D4D4D5',
        borderTopWidth: 1,
    },
    ViewButton: {
        marginTop: 10,
    },
    CancelAction: {
        borderWidth: 1,
        borderRadius: 5,
        padding: 5,
    },
    ViewDetailGroup: {
        marginVertical: 2,
        display: 'flex',
        flexDirection: 'row',
    },
    DeliveryHeader: {
        fontWeight: '700',
        flex: 1,
    },

    ViewExpandPickList: {
        flex: 1,
        paddingVertical: 5,
        display: 'flex',
        overflowY: 'scroll',
        overflowX: 'none',
    },
    ViewExpandPickListText: {
        marginVertical: 5,
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
    },
    ViewManualScanIcons: {
        marginVertical: 5,
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    ViewActions: {
        marginVertical: 5,
        display: 'flex',
        flexDirection: 'row',
    },
    ViewNavigationButtons: {
        flex: 1,
        marginRight: 5,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    TextActionList: {
        alignSelf: 'flex-start',
        color: '#0984e3',
    },
    TextCurrentItemNumber: {
        fontWeight: '700',
        fontSize: 18,
        textAlign: 'center',
    },
    ViewItem: {
        flex: 1,
    },
    TextHeader: {
        fontWeight: '700',
    },
    ViewErrorMessageParent: {
        marginTop: 5,
        marginBottom: 5,
    },
});

type RequestDetailsProps = {
    request: DeliverEmptyContainerRequestsByVisitIdDeliverRequestFragment;
    onItemClick: (id: string) => void;
};

const RequestDetails: React.FC<RequestDetailsProps> = ({ request, onItemClick }) => {
    const { storageItem, container, status, customer } = request;

    return (
        <View style={RequestDetailsStyles.ViewParent}>
            <TouchableOpacity
                onPress={() => onItemClick(request.id)}
                style={RequestDetailsStyles.TouchableOpacityItem}
                testID="data-requestDetails"
            >
                <View style={RequestDetailsStyles.ViewDetailGroup}>
                    <Image
                        style={RequestDetailsStyles.ImageItem}
                        source={{ uri: storageItem?.storageItemType.image }}
                        testID="data-RequestDetailsImage"
                    />
                    <View>
                        <View style={RequestDetailsStyles.ViewDetailGroup}>
                            <Text
                                style={RequestDetailsStyles.TextItemValue}
                                testID="data-RequestDetailsDescription"
                            >
                                {storageItem?.storageItemType.description}
                            </Text>
                        </View>
                        <View style={RequestDetailsStyles.ViewDetailGroup}>
                            <Text
                                style={RequestDetailsStyles.CustomerTextItemLabel}
                                testID="data-RequestDetailsLocation"
                            >
                                <FormattedMessage
                                    id="requestDetails.customer"
                                    defaultMessage="Customer: "
                                />
                            </Text>
                            <Text
                                style={RequestDetailsStyles.TextItemValue}
                            >{`${customer?.firstName} ${customer?.lastName}`}</Text>
                        </View>
                        <View style={RequestDetailsStyles.ViewDetailGroup}>
                            <Text
                                style={RequestDetailsStyles.CustomerTextItemLabel}
                                testID="data-RequestDetailsLocation"
                            >
                                <FormattedMessage
                                    id="requestDetails.barcode"
                                    defaultMessage="Barcode"
                                />
                                :
                            </Text>
                            <Text style={RequestDetailsStyles.TextItemValue}>
                                {container?.barcode}
                            </Text>
                        </View>
                        <View style={RequestDetailsStyles.ViewDetailGroup}>
                            <Text
                                style={RequestDetailsStyles.CustomerTextItemLabel}
                                testID="data-RequestDetailsLocation"
                            >
                                <FormattedMessage
                                    id="requestDetails.customer"
                                    defaultMessage="Status: "
                                />
                            </Text>
                            <Text style={RequestDetailsStyles.TextItemValue}>{status}</Text>
                        </View>
                    </View>
                </View>
            </TouchableOpacity>
        </View>
    );
};

const RequestDetailsStyles = StyleSheet.create({
    ViewParent: {
        padding: 5,
    },
    ViewDetailGroup: {
        marginVertical: 2,
        display: 'flex',
        flexDirection: 'row',
    },
    ViewImageGroup: {
        marginVertical: 5,
        display: 'flex',
        justifyContent: 'center',
    },
    TextDetailHeader: {
        fontWeight: '700',
    },
    ImageItem: {
        height: 40,
        width: 40,
        resizeMode: 'stretch',
        marginLeft: 5,
        marginRight: 10,
    },

    ViewItemsList: {
        marginVertical: 5,
    },
    ViewItem: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
    },
    ViewItemsSummary: {
        marginVertical: 5,
    },
    ViewAssignee: {
        marginVertical: 5,
    },
    TextHeader: {
        fontWeight: '700',
    },
    TextItemLabel: {
        flex: 1,
    },
    CustomerTextItemLabel: {
        flex: 0,
        marginRight: 5,
    },
    TextItemValue: {
        flex: 1,
        fontWeight: '700',
    },
    TouchableOpacityItem: {
        borderWidth: 1,
        borderRadius: 5,
        padding: 5,
    },
    TextDescription: {
        fontWeight: '700',
    },
    ViewButton: {},
});

type WaitingItemProps = {
    item: { containerTypeName: string; quantity: number; image?: string };
};
const WaitingItem: React.FC<WaitingItemProps> = ({ item }) => {
    const { containerTypeName, quantity, image } = item;

    return (
        <View style={RequestDetailsStyles.ViewParent}>
            <TouchableOpacity
                onPress={() => null}
                style={RequestDetailsStyles.TouchableOpacityItem}
                testID="data-requestDetails"
            >
                <View style={RequestDetailsStyles.ViewDetailGroup}>
                    <Image
                        style={RequestDetailsStyles.ImageItem}
                        source={{ uri: image }}
                        testID="data-RequestDetailsImage"
                    />
                    <View style={{ flex: 2 }}>
                        <View style={RequestDetailsStyles.ViewDetailGroup}>
                            <Text
                                style={RequestDetailsStyles.TextItemValue}
                                testID="data-RequestDetailsDescription"
                            >
                                {containerTypeName}
                            </Text>
                        </View>
                    </View>
                    <View style={{ marginRight: 5 }}>
                        <Text style={RequestDetailsStyles.TextItemValue}>{quantity}</Text>
                    </View>
                </View>
            </TouchableOpacity>
        </View>
    );
};
