import React, { useState, useCallback } from 'react';
import { gql } from '@apollo/client';

import { Button, ErrorMessage, Icon, TextInput, Message } from '@valet/ui-components';
import { ScrollView, View, Text, Image, TouchableOpacity, StyleSheet } from 'react-native';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    RefilingStorageItemFragment,
    useRefileStorageItemByBarcodeLazyQuery,
    useRefilingCreateListMutation,
} from '../../../schema-types';
import { useEffect } from 'react';
//import { storageItems, locations as mockLocations } from './_MockData';

const SORTING_LOCATION_FRAGMENT = gql`
    fragment RefilingLocation on Location {
        id
        barcode
        description
    }
`;

const SORTING_STORAGE_ITEM_FRAGMENT = gql`
    fragment RefilingStorageItem on StorageItem {
        id
        status
        barcode
        storageItemType {
            id
            name
            description
            image
        }
    }

    ${SORTING_LOCATION_FRAGMENT}
`;

export const RETRIEVE_STORAGE_ITEM_BY_BARCODE_QUERY = gql`
    query refileStorageItemByBarcode($barcode: String!) {
        storageItemByBarcode(barcode: $barcode) {
            ...RefilingStorageItem
        }
    }

    ${SORTING_STORAGE_ITEM_FRAGMENT}
`;

export const REFILING_CREATE_LIST_MUTATION = gql`
    mutation refilingCreateList($inputData: RefilingCreateListInput!) {
        refilingCreateList(data: $inputData) {
            data {
                id
            }
        }
    }
`;

type RefileListCreationPageProps = {
    onCreateRefileListPressed: (refileListId: string) => void;
};

export const RefileListCreationPage: React.FC<RefileListCreationPageProps> = ({
    onCreateRefileListPressed,
}) => {
    const intl = useIntl();

    const [errorMessage, setErrorMessage] = useState<ErrorMessage | undefined>(undefined);
    const [expandBarcodeManualEntry, setExpandBarcodeManualEntry] = useState<boolean>(false);
    const [barcodeEntryValue, setBarcodeEntryValue] = useState<string>('');
    const [itemsToRefile, setItemsToRefile] = useState<RefilingStorageItemFragment[] | undefined>(
        undefined,
    );

    const [
        refilingCreateListMutation,
        //{ loading: refilingCreateListMutationLoading, error: refilingCreateListMutationError },
    ] = useRefilingCreateListMutation();

    const handleManualScanCameraPress = useCallback((): void => {
        console.log('Pressed Manual Scan Camera Icon');
    }, []);

    const handleManualScanKeyboardPress = useCallback((): void => {
        setExpandBarcodeManualEntry(!expandBarcodeManualEntry);
    }, [expandBarcodeManualEntry]);

    const handleClearScreenPress = useCallback((): void => {
        setErrorMessage(undefined);
        setItemsToRefile([]);
        setExpandBarcodeManualEntry(false);
        setBarcodeEntryValue('');
    }, []);

    const [storageItemByBarcode, { loading }] = useRefileStorageItemByBarcodeLazyQuery({
        onCompleted: (data) => {
            if (data?.storageItemByBarcode) {
                if (!itemsToRefile)
                    setItemsToRefile([data.storageItemByBarcode as RefilingStorageItemFragment]);
                else {
                    itemsToRefile.push(data.storageItemByBarcode as RefilingStorageItemFragment);
                    setItemsToRefile(itemsToRefile);
                }
            }
        },

        onError: (err) =>
            setErrorMessage({
                content: err.message,
                type: 'negative',
                header: 'Error',
            }),
    });

    useEffect(() => {
        if (loading) {
            setExpandBarcodeManualEntry(false);
        } else setExpandBarcodeManualEntry(true);
    }, [loading, expandBarcodeManualEntry]);

    const handleManualScanSubmit = () => {
        if (barcodeEntryValue) {
            setErrorMessage(undefined);
            storageItemByBarcode({
                variables: {
                    barcode: barcodeEntryValue,
                },
            });

            setExpandBarcodeManualEntry(!expandBarcodeManualEntry);
            setBarcodeEntryValue('');
        }
    };

    const handleDeleteItemFromList = useCallback((id: string) => {
        setItemsToRefile((preItems) => {
            return preItems?.filter((r) => r.id !== id);
        });
    }, []);

    const handleAddClick = useCallback(() => {
        setErrorMessage(undefined);
        refilingCreateListMutation({
            variables: {
                inputData: {
                    storageItemIds: itemsToRefile?.map((r) => r.id),
                },
            },
        }).then(({ data, errors }) => {
            const newRefileListId = data?.refilingCreateList.data?.id || '';
            if (newRefileListId) onCreateRefileListPressed(newRefileListId);
            else
                setErrorMessage({
                    content: errors?.length ? errors[0].message : 'Error in List Creation',
                    type: 'negative',
                    header: 'Error',
                });
        });
    }, [onCreateRefileListPressed, itemsToRefile, refilingCreateListMutation]);

    if (loading) {
        return (
            <View style={refilingPageStyles.ViewErrorMessageParent} testID="data-errorMessage">
                <Text>Loading...</Text>
            </View>
        );
    }

    return (
        <ScrollView>
            <View testID="data-refiling">
                {errorMessage && (
                    <View
                        style={refilingPageStyles.ViewErrorMessageParent}
                        testID="data-errorMessage"
                    >
                        <RefilingErrorMessage errorMessage={errorMessage} />
                    </View>
                )}
                <View
                    style={refilingPageStyles.ViewActionsGroup}
                    testID="data-refilingActionsContainer"
                >
                    <View style={{ display: 'flex', flexDirection: 'row' }}>
                        <Text style={refilingPageStyles.TextHeader}>
                            <FormattedMessage
                                id="refiling.scanItemDesc"
                                defaultMessage="Scan an Item to add to list."
                            />
                        </Text>
                    </View>
                </View>
                <View testID="data-delivery">
                    <View style={refilingPageStyles.ViewContent}>
                        {itemsToRefile &&
                            itemsToRefile.map((item) => {
                                return (
                                    <RefileItem
                                        key={item.id}
                                        details={item}
                                        onItemDeletePressed={handleDeleteItemFromList}
                                    />
                                );
                            })}
                    </View>
                </View>

                <View
                    style={refilingPageStyles.ViewActionsGroup}
                    testID="data-refilingActionsContainer"
                >
                    {expandBarcodeManualEntry && (
                        <View
                            style={{
                                padding: 3,
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                            testID="data-refilingManualBarcodeEntryContainer"
                        >
                            <View style={{ flex: 1, marginRight: 5 }}>
                                <TextInput
                                    type="text"
                                    onChange={(e) => setBarcodeEntryValue(e)}
                                    value={barcodeEntryValue}
                                    ariaLabel={intl.formatMessage({
                                        id: 'refilingManualBarcodeEntryPlaceholderText',
                                        defaultMessage: 'Enter item barcode',
                                    })}
                                    placeholder={intl.formatMessage({
                                        id: 'refilingManualBarcodeEntryPlaceholderText',
                                        defaultMessage: 'Enter item barcode',
                                    })}
                                    testID="data-refilingManualBarcodeEntryInput"
                                />
                            </View>

                            <View style={{ marginLeft: 5 }}>
                                <Button
                                    onPress={handleManualScanSubmit}
                                    title={intl.formatMessage({
                                        id: 'refilingScanButton',
                                        defaultMessage: 'SCAN',
                                    })}
                                    testID="data-refilingManualBarcodeEntrySubmitButton"
                                />
                            </View>
                        </View>
                    )}

                    <View style={{ display: 'flex', flexDirection: 'row' }}>
                        <View style={refilingPageStyles.ViewManualScanIcons}>
                            <TouchableOpacity
                                onPress={handleClearScreenPress}
                                style={[{ marginLeft: 5, flex: 1 }]}
                                testID="data-refilingActionKeyboard"
                            >
                                <Icon icon="clearScreen" />
                            </TouchableOpacity>

                            <TouchableOpacity
                                onPress={handleManualScanCameraPress}
                                style={[{ marginRight: 5 }]}
                                testID="data-refilingActionCamera"
                            >
                                <Icon icon="barcodeCamera" />
                            </TouchableOpacity>

                            <TouchableOpacity
                                onPress={handleManualScanKeyboardPress}
                                style={[{ marginLeft: 5 }]}
                                testID="data-refilingActionKeyboard"
                            >
                                <Icon icon="barcodeKeyboard" />
                            </TouchableOpacity>
                        </View>
                    </View>
                    <View>
                        <View style={{ flex: 1, marginRight: 10, marginBottom: 10 }}>
                            <Button title="+ Create List" onPress={handleAddClick} />
                        </View>
                    </View>
                </View>
            </View>
        </ScrollView>
    );
};

const refilingPageStyles = StyleSheet.create({
    ViewParent: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    ViewActionsGroup: {
        marginVertical: 5,
        display: 'flex',
        flexDirection: 'column',
        borderTopColor: '#D4D4D5',
        borderTopWidth: 1,
    },
    ViewHeader: {
        marginVertical: 5,
        display: 'flex',
        flexDirection: 'column',
        borderTopColor: '#D4D4D5',
        borderTopWidth: 1,
    },
    ViewExpandPickList: {
        flex: 1,
        paddingVertical: 5,
        display: 'flex',
        overflowY: 'scroll',
        overflowX: 'none',
    },
    ViewExpandPickListText: {
        marginVertical: 5,
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
    },
    ViewManualScanIcons: {
        marginVertical: 5,
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    ViewActions: {
        marginVertical: 5,
        display: 'flex',
        flexDirection: 'row',
    },
    ViewNavigationButtons: {
        flex: 1,
        marginRight: 5,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    ViewRefilingItem: {
        flex: 1,
        paddingVertical: 5,
        display: 'flex',
        overflowY: 'scroll',
        overflowX: 'none',
    },
    TextActionList: {
        alignSelf: 'flex-start',
        color: '#0984e3',
    },
    TextCurrentItemNumber: {
        fontWeight: '700',
        fontSize: 18,
        textAlign: 'center',
    },
    ViewItem: {
        flex: 1,
    },
    TextHeader: {
        fontWeight: '700',
    },
    ViewErrorMessageParent: {
        marginTop: 5,
        marginBottom: 5,
    },
    ViewContent: {
        paddingVertical: 5,
    },
});

type RefileItemProps = {
    details: RefilingStorageItemFragment;
    onItemDeletePressed: (storageItemId: string) => void;
};

const RefileItem: React.FC<RefileItemProps> = ({ details }) => {
    const {
        storageItemType: { name: itemType, description, image },
        barcode,
        status,
    } = details;
    console.log(details);
    return (
        <View>
            <View
                style={refilingItemStyles.ViewItemsList}
                testID="data-pickListSummaryItemSummaryContainer"
            >
                <View
                    style={refilingItemStyles.ViewDetailGroup}
                    testID="data-refilingLocationListItem"
                >
                    <Text
                        style={refilingItemStyles.TextItemValue}
                        testID="data-refilingVisitLocationsHeader"
                    >
                        <FormattedMessage
                            id="refiling.visitLocations"
                            defaultMessage="Visit Location(s)"
                        />
                    </Text>
                    <Text
                        style={refilingItemStyles.TextItemValue}
                        testID="data-refilingItemLocation"
                    >
                        <FormattedMessage id="refiling.locationBarcode" defaultMessage="Barcode" />
                    </Text>

                    <Text style={refilingItemStyles.TextItemValue}>{`Sorted / Total`}</Text>
                </View>
            </View>

            <View style={refilingItemStyles.ViewDetailGroup}>
                <Text
                    style={refilingItemStyles.TextDetailHeader}
                    testID="data-refilingItemLocation"
                >
                    <FormattedMessage
                        id="refiling.currentLocation"
                        defaultMessage={`Current Location:${' '}`}
                    />
                </Text>
            </View>

            <View style={refilingItemStyles.ViewDetailGroup}>
                <Text style={refilingItemStyles.TextDetailHeader} testID="data-refilingItemBarcode">
                    <FormattedMessage id="refiling.itemBarcode" defaultMessage={`Barcode:${' '}`} />
                </Text>
                <Text>{barcode}</Text>
            </View>

            <View style={refilingItemStyles.ViewImageGroup}>
                <Image
                    style={refilingItemStyles.ImageItem}
                    source={{ uri: image }}
                    testID="data-refilingItemImage"
                />
            </View>

            <View style={refilingItemStyles.ViewDetailGroup}>
                <Text style={refilingItemStyles.TextDetailHeader} testID="data-refilingItemStatus">
                    <FormattedMessage id="refiling.itemStatus" defaultMessage={`Status:${' '}`} />
                </Text>
                <Text>{status}</Text>
            </View>

            <View style={refilingItemStyles.ViewDetailGroup}>
                <Text
                    style={refilingItemStyles.TextDetailHeader}
                    testID="data-refilingItemItemType"
                >
                    <FormattedMessage id="refiling.itemType" defaultMessage={`Item type:${' '}`} />
                </Text>
                <Text>{itemType}</Text>
            </View>

            <View style={{ marginVertical: 2 }}>
                <Text
                    style={refilingItemStyles.TextDetailHeader}
                    testID="data-refilingItemDescription"
                >
                    <FormattedMessage
                        id="refiling.itemDescription"
                        defaultMessage={`Description:${' '}`}
                    />
                </Text>
                <Text>{description}</Text>
            </View>
        </View>
    );
};

const refilingItemStyles = StyleSheet.create({
    ViewDetailGroup: {
        marginVertical: 2,
        display: 'flex',
        flexDirection: 'row',
    },
    ViewImageGroup: {
        marginVertical: 5,
        display: 'flex',
        justifyContent: 'center',
    },
    TextDetailHeader: {
        fontWeight: '700',
    },
    ImageItem: {
        height: 150,
        width: 200,
        resizeMode: 'stretch',
    },

    ViewItemsList: {
        marginVertical: 5,
    },
    ViewItem: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
    },
    ViewItemsSummary: {
        marginVertical: 5,
    },
    ViewAssignee: {
        marginVertical: 5,
    },
    TextHeader: {
        fontWeight: '700',
    },
    TextItemLabel: {
        flex: 1,
    },
    TextItemValue: {
        flex: 1,
        fontWeight: '700',
    },
});

type RefilingErrorMessageProps = {
    errorMessage: ErrorMessage | undefined;
};

const RefilingErrorMessage: React.FC<RefilingErrorMessageProps> = ({ errorMessage }) => {
    return <Message errorMessage={errorMessage} />;
};
