import { gql } from '@apollo/client';
import { Button, Card, Icon } from '@valet/ui-components';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    FlatList,
    Image,
    ScrollView,
    StyleSheet,
    Text,
    TouchableOpacity,
    View,
    Linking,
} from 'react-native';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
    useGetRouteDetailsQuery,
    DeliveryRouteDetailRouteStopFragment,
} from '../../../schema-types';

const DELIVERY_ROUTE_DETAIL_ROUTE_STOP_FRAGMENT = gql`
    fragment deliveryRouteDetailRouteStop on RouteStop {
        id
        startTime
        endTime
        sequence
        visit {
            id
            status
            expectedDuration
            startTime
            endTime
            summary {
                inComing
                outGoing
                multiplePeople
                firstVisit
            }
            customerAddress {
                address1
                city
                countryId
                firstName
                lastName
            }
            scheduleWindow {
                startTime
                endTime
            }
            customer {
                email
                firstName
                lastName
                homePhone
                cellPhone
            }
            requests(filter: {}) {
                edges {
                    cursor
                    node {
                        id
                        status
                    }
                }
            }
        }
    }
`;

export const RETRIEVE_ROUTE_DETAILS_QUERY = gql`
    query getRouteDetails($routeId: String!) {
        routes(filter: { conditions: [{ field: "id", value: $routeId, operator: Equal }] }) {
            edges {
                cursor
                node {
                    id
                    description
                    routeNumber
                    status
                    operationDate
                    employees {
                        id
                        firstName
                        lastName
                    }
                    stops {
                        ...deliveryRouteDetailRouteStop
                    }
                }
            }
            pageInfo {
                cursor {
                    beforeCursor
                    afterCursor
                }
                hasNextPage
                hasPreviousPage
            }
        }
    }

    ${DELIVERY_ROUTE_DETAIL_ROUTE_STOP_FRAGMENT}
`;

type DeliveryRouteDetailPageProps = {
    onRouteStopItemSelect: (routeId: string, visitId: string) => void;
};

export const DeliveryRouteDetailPage: React.FC<DeliveryRouteDetailPageProps> = ({
    onRouteStopItemSelect,
}) => {
    const intl = useIntl();

    const history = useHistory();
    const match = useRouteMatch();
    const params: { routeId?: string } = match.params;
    const routeId = params.routeId;

    const handleGoBackPress = useCallback((): void => {
        history.push('/delivery');
    }, [history]);

    useEffect(() => {
        if (!routeId) {
            handleGoBackPress();
        }
    }, [routeId, handleGoBackPress]);

    const [isFetching, setIsFetching] = useState<boolean>(false);

    const {
        data: retrievedRoutesData,
        loading: retrievedRoutesLoading,
        error: retrievedRoutesError,
        fetchMore: retrievedRoutesFetchMore,
    } = useGetRouteDetailsQuery({
        variables: {
            routeId: routeId || '',
        },
    });

    const routeEdges = retrievedRoutesData?.routes.edges ?? [];
    const routesData = useMemo(() => {
        return {
            routes: routeEdges?.map((itemEdge) => itemEdge.node) ?? [],
        };
    }, [routeEdges]);

    const handleLoadMoreItems = useCallback((): void => {
        if (!isFetching) {
            setIsFetching(true);

            retrievedRoutesFetchMore({
                variables: {
                    routeId: routeId,
                },
            }).then(() => {
                setIsFetching(false);
            });
        }
    }, [isFetching, retrievedRoutesFetchMore, routeId]);

    useEffect(() => {
        if (!routesData) {
            handleLoadMoreItems();
        }
    }, [handleLoadMoreItems, routesData, retrievedRoutesError]);

    useEffect(() => {
        if (!routeId) {
            handleGoBackPress();
        }
    }, [routeId, handleGoBackPress]);

    const deliveryStops = useMemo<{ stops: DeliveryRouteDetailRouteStopFragment[] }>(() => {
        if (routesData.routes && routesData.routes.length > 0 && routesData.routes[0].stops) {
            return { stops: [...routesData.routes[0].stops] };
        }

        return { stops: [] };
    }, [routesData.routes]);

    const handleItemClick = useCallback(
        (routeStop: DeliveryRouteDetailRouteStopFragment) => {
            onRouteStopItemSelect(routeId ?? '', routeStop.visit.id);
        },
        [onRouteStopItemSelect, routeId],
    );

    return (
        <ScrollView>
            <View testID="data-deliveryRouteDetailDataView">
                <View style={deliveryRouteDetailPageStyles.ViewParent} testID="data-delivery">
                    <View style={deliveryRouteDetailPageStyles.ViewTopBar}>
                        <Button
                            accessoryLeft={() => <Icon icon="back" />}
                            title={intl.formatMessage({
                                id: 'deliveryRouteDetail.back',
                                defaultMessage: 'Back',
                            })}
                            onPress={handleGoBackPress}
                            appearance="ghost"
                        />
                    </View>
                    {!retrievedRoutesLoading &&
                        !retrievedRoutesData?.routes.pageInfo.hasNextPage &&
                        retrievedRoutesData && (
                            <View style={deliveryRouteDetailPageStyles.ViewContent}>
                                {deliveryStops?.stops?.length ? (
                                    <FlatList
                                        data={deliveryStops.stops}
                                        renderItem={({ item }) => (
                                            <RouteStopItem
                                                key={item.id}
                                                routeStop={item}
                                                onItemClick={handleItemClick}
                                            />
                                        )}
                                        keyExtractor={(item) => item.id}
                                    />
                                ) : (
                                    <Text>
                                        <FormattedMessage
                                            id="deliveryRouteDetail.noDeliveriesText"
                                            defaultMessage="There are no stops for this delivery."
                                        />
                                    </Text>
                                )}
                            </View>
                        )}
                </View>
            </View>
        </ScrollView>
    );
};

const deliveryRouteDetailPageStyles = StyleSheet.create({
    ViewParent: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    ViewTopBar: {
        paddingBottom: 5,
        display: 'flex',
        flexDirection: 'row' as 'row',
        alignItems: 'center',
        borderBottomWidth: 1,
        borderBottomColor: '#d4d4d5',
    },
    ViewTabs: {
        marginVertical: 5,
        display: 'flex',
        flexDirection: 'row',
    },
    ViewContent: {
        paddingVertical: 5,
    },
    TextTab: {
        flex: 1,
        color: '#000',
        backgroundColor: '#D4D4D5',
        fontSize: 18,
        textAlign: 'center',
        fontWeight: '700',
    },
    ViewButton: {
        marginTop: 10,
        display: 'flex',
        flexDirection: 'row',
    },
    CancelAction: {
        borderWidth: 1,
        borderRadius: 5,
        padding: 5,
    },
    ViewDetailGroup: {
        marginVertical: 2,
        display: 'flex',
        flexDirection: 'row',
    },
    DeliveryHeader: {
        fontWeight: '700',
        flex: 1,
    },
});

type RouteStopItemProps = {
    routeStop: DeliveryRouteDetailRouteStopFragment;
    onItemClick: (routeStop: DeliveryRouteDetailRouteStopFragment) => void;
};

const RouteStopItem: React.FC<RouteStopItemProps> = ({ routeStop, onItemClick }) => {
    const {
        sequence,
        visit: {
            scheduleWindow,
            customerAddress: { address1, city, countryId },
            status,
            customer: { firstName, lastName, cellPhone, homePhone, email },
            summary: { multiplePeople, outGoing, inComing, firstVisit },
        },
    } = routeStop;

    const { startTime } = scheduleWindow ?? {};

    const intl = useIntl();

    return (
        <Card
            style={routeStopItemStyles.ViewParent}
            onPress={() => onItemClick(routeStop)}
            testID="data-routeStopItem"
        >
            <View style={routeStopItemStyles.ViewDetailGroup}>
                <Image
                    style={routeStopItemStyles.ImageItem}
                    source={require(`./MockImages/map_route.png`)}
                    testID="data-routeStopItemImage"
                />
                <Text
                    style={routeStopItemStyles.TextItemValue}
                    testID="data-routeStopItemDescription"
                >
                    <FormattedMessage id="routeStopItem.delivery" defaultMessage="Delivery: " />
                    {sequence}
                </Text>
            </View>
            <View style={routeStopItemStyles.ViewDetailGroup}>
                <Text
                    style={routeStopItemStyles.CustomerTextItemLabel}
                    testID="data-routeStopItemCustomerLabel"
                >
                    <FormattedMessage id="routeStopItem.customer" defaultMessage="Customer: " />
                </Text>
                <Text style={routeStopItemStyles.TextItemValue}>{`${firstName} ${lastName}`}</Text>
            </View>
            <View style={routeStopItemStyles.ViewDetailGroup}>
                <Text
                    style={routeStopItemStyles.CustomerTextItemLabel}
                    testID="data-routeStopItemEmail"
                >
                    <FormattedMessage id="routeStopItem.email" defaultMessage="Email: " />
                </Text>
                <TouchableOpacity
                    style={routeStopItemStyles.TextItemValue}
                    onPress={() => {
                        Linking.openURL(
                            `mailto:${email}?subject=${intl.formatMessage({
                                id: 'routeStopItem.Delivery',
                                defaultMessage: 'Delivery',
                            })}`,
                        );
                    }}
                >
                    <Text style={{ color: 'blue' }}>{email}</Text>
                </TouchableOpacity>
            </View>
            <View style={routeStopItemStyles.ViewDetailGroup}>
                <Text
                    style={routeStopItemStyles.CustomerTextItemLabel}
                    testID="data-routeStopItemPhone"
                >
                    <FormattedMessage id="routeStopItem.Phone" defaultMessage="Phone: " />
                </Text>
                <TouchableOpacity
                    style={routeStopItemStyles.TextItemValue}
                    onPress={() => {
                        Linking.openURL(`tel:${homePhone || cellPhone}`);
                    }}
                >
                    <Text style={{ color: 'blue' }}>{homePhone || cellPhone}</Text>
                </TouchableOpacity>
            </View>
            <View style={routeStopItemStyles.ViewDetailGroup}>
                <Text
                    style={routeStopItemStyles.CustomerTextItemLabel}
                    testID="data-routeStopItemAddress"
                >
                    <FormattedMessage id="routeStopItem.Address" defaultMessage="Address: " />
                </Text>
                <Text
                    style={routeStopItemStyles.TextItemLabel}
                >{`${address1}, ${city}, ${countryId}`}</Text>
            </View>
            <View style={routeStopItemStyles.ViewDetailGroup}>
                <Text
                    style={routeStopItemStyles.CustomerTextItemLabel}
                    testID="data-routeStopItemScheduledTime"
                >
                    <FormattedMessage
                        id="routeStopItem.ScheduledTime"
                        defaultMessage="Scheduled Time: "
                    />
                </Text>
                <Text style={routeStopItemStyles.TextItemLabel} testID="data-routeStopItemLocation">
                    {intl.formatDate(startTime, {
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                    })}
                </Text>
            </View>
            <View style={routeStopItemStyles.ViewDetailGroup}>
                <Text
                    style={routeStopItemStyles.CustomerTextItemLabel}
                    testID="data-routeStopItemLocation"
                >
                    <FormattedMessage id="routeStopItem.status" defaultMessage="Status: " />
                </Text>
                <Text style={routeStopItemStyles.TextItemValue}>{status}</Text>
            </View>
            <View style={routeStopItemStyles.ViewDetailGroup}>
                <Text
                    style={routeStopItemStyles.CustomerTextItemLabel}
                    testID="data-routeStopItemType"
                >
                    <FormattedMessage id="routeStopItem.type" defaultMessage="Type: " />
                </Text>
                <Text style={routeStopItemStyles.TextItemValue}>
                    {firstVisit
                        ? `${intl.formatMessage({
                              id: 'routeStopItem.MultiplePersons',
                              defaultMessage: 'Multiple Persons',
                          })}`
                        : `${intl.formatMessage({
                              id: 'routeStopItem.SinglePerson',
                              defaultMessage: 'Single Person',
                          })}`}
                </Text>
            </View>
            <View style={routeStopItemStyles.ViewDetailGroup}>
                <Text
                    style={routeStopItemStyles.CustomerTextItemLabel}
                    testID="data-routeStopItemFirstVisit"
                >
                    <FormattedMessage
                        id="routeStopItem.firstTimeCustomer"
                        defaultMessage="First Time: "
                    />
                </Text>
                <Text style={routeStopItemStyles.TextItemValue}>
                    {multiplePeople
                        ? `${intl.formatMessage({
                              id: 'routeStopItem.Yes',
                              defaultMessage: 'Yes',
                          })}`
                        : `${intl.formatMessage({
                              id: 'routeStopItem.No',
                              defaultMessage: 'No',
                          })}`}
                </Text>
            </View>
            <View style={routeStopItemStyles.ViewDetailGroup}>
                <Text
                    style={routeStopItemStyles.CustomerTextItemLabel}
                    testID="data-routeStopItemPickupItems"
                >
                    <FormattedMessage
                        id="routeStopItem.PickupItems"
                        defaultMessage="Pickup Items: "
                    />
                </Text>
                <Text style={routeStopItemStyles.TextItemValue}>{inComing}</Text>
            </View>
            <View style={routeStopItemStyles.ViewDetailGroup}>
                <Text
                    style={routeStopItemStyles.CustomerTextItemLabel}
                    testID="data-routeStopItemDeliveryItems"
                >
                    <FormattedMessage
                        id="routeStopItem.DeliveryItems"
                        defaultMessage="Delivery Items: "
                    />
                </Text>
                <Text style={routeStopItemStyles.TextItemValue}>{outGoing}</Text>
            </View>
        </Card>
    );
};

const routeStopItemStyles = StyleSheet.create({
    ViewParent: {
        marginVertical: 3,
    },
    ViewDetailGroup: {
        marginVertical: 2,
        display: 'flex',
        flexDirection: 'row',
    },
    ViewImageGroup: {
        marginVertical: 5,
        display: 'flex',
        justifyContent: 'center',
    },
    TextDetailHeader: {
        fontWeight: '700',
    },
    ImageItem: {
        height: 20,
        width: 20,
        resizeMode: 'stretch',
    },

    ViewItemsList: {
        marginVertical: 5,
    },
    ViewItem: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
    },
    ViewItemsSummary: {
        marginVertical: 5,
    },
    ViewAssignee: {
        marginVertical: 5,
    },
    TextHeader: {
        fontWeight: '700',
    },
    TextItemLabel: {
        flex: 1,
    },
    CustomerTextItemLabel: {
        flex: 0.5,
        marginRight: 5,
    },
    TextItemValue: {
        flex: 1,
        fontWeight: '700',
    },
    TextDescription: {
        fontWeight: '700',
    },
});
