import { gql } from '@apollo/client';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FlatList, Image, ScrollView, StyleSheet, Text, View } from 'react-native';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Button, Icon, TextInput, Message, TopTabBar, TopTab, Card } from '@valet/ui-components';
import {
    useRequestsByVisitIdDeliveryCompleteQuery,
    RequestStatus,
    useDeliveryCompleteVisitMutation,
    DeliveryCompleteDeliverStorageItemRequestFragment,
    DeliveryCompletePickupStorageItemRequestFragment,
    RequestType,
} from '../../../schema-types';

export const DELIVERY_COMPLETE_DELIVER_STORAGE_ITEM_REQUEST_FRAGMENT = gql`
    fragment DeliveryCompleteDeliverStorageItemRequest on DeliverStorageItemRequest {
        id
        status
        requestType
        storageItem {
            id
            status
            barcode
            storageItemType {
                id
                name
                description
                image
            }
        }
        customer {
            email
            firstName
            lastName
            homePhone
            cellPhone
        }
    }
`;

export const DELIVERY_COMPLETE_PICKUP_STORAGE_ITEM_REQUEST_FRAGMENT = gql`
    fragment DeliveryCompletePickupStorageItemRequest on PickupStorageItemRequest {
        id
        status
        requestType
        storageItem {
            id
            status
            barcode
            storageItemType {
                id
                name
                description
                image
            }
        }
        customer {
            email
            firstName
            lastName
            homePhone
            cellPhone
        }
    }
`;

export const DELIVERY_COMPLETE_DELIVER_EMPTY_CONTAINER_REQUEST_FRAGMENT = gql`
    fragment DeliveryCompleteDeliverEmptyContainerRequest on DeliverEmptyContainerRequest {
        id
        status
        requestType
        storageItem {
            id
            status
            barcode
            storageItemType {
                id
                name
                description
                image
                containerType {
                    id
                    description
                }
            }
        }
        customer {
            email
            firstName
            lastName
            homePhone
            cellPhone
        }
    }
`;

export const DELIVERY_COMPLETE_PICKUP_EMPTY_CONTAINER_REQUEST_FRAGMENT = gql`
    fragment DeliveryCompletePickupEmptyContainerRequest on PickupEmptyContainerRequest {
        id
        status
        requestType
        storageItem {
            id
            status
            barcode
            storageItemType {
                id
                name
                description
                image
            }
        }
        customer {
            email
            firstName
            lastName
            homePhone
            cellPhone
        }
    }
`;

export const DELIVERY_COMPLETE_DRIVER_PICKUP_EMPTY_CONTAINER_REQUEST_FRAGMENT = gql`
    fragment DeliveryCompleteDriverPickupEmptyContainerRequest on DriverPickupEmptyContainerRequest {
        id
        status
        requestType
        storageItem {
            id
            status
            barcode
            storageItemType {
                id
                name
                description
                image
            }
        }
        customer {
            email
            firstName
            lastName
            homePhone
            cellPhone
        }
    }
`;

export const RETRIEVE_REQUESTS_QUERY = gql`
    query requestsByVisitIdDeliveryComplete($visitId: String!, $pageSize: Int!, $cursor: String!) {
        requestsByVisitId(visitId: $visitId, filter: { pageSize: $pageSize, after: $cursor }) {
            edges {
                cursor
                node {
                    id
                    status
                    requestType
                    __typename
                    ...DeliveryCompleteDeliverStorageItemRequest
                    ...DeliveryCompletePickupStorageItemRequest
                    ...DeliveryCompleteDeliverEmptyContainerRequest
                    ...DeliveryCompletePickupEmptyContainerRequest
                    ...DeliveryCompleteDriverPickupEmptyContainerRequest
                }
            }
            pageInfo {
                cursor {
                    afterCursor
                    beforeCursor
                }
                hasNextPage
                hasPreviousPage
            }
        }
    }

    ${DELIVERY_COMPLETE_DELIVER_STORAGE_ITEM_REQUEST_FRAGMENT}
    ${DELIVERY_COMPLETE_PICKUP_STORAGE_ITEM_REQUEST_FRAGMENT}
    ${DELIVERY_COMPLETE_DELIVER_EMPTY_CONTAINER_REQUEST_FRAGMENT}
    ${DELIVERY_COMPLETE_PICKUP_EMPTY_CONTAINER_REQUEST_FRAGMENT}
    ${DELIVERY_COMPLETE_DRIVER_PICKUP_EMPTY_CONTAINER_REQUEST_FRAGMENT}
`;

export const DELIVERY_COMPLETE_VISIT_MUTATION = gql`
    mutation deliveryCompleteVisit($data: DeliveryCompleteVisitInput!) {
        deliveryCompleteVisit(data: $data) {
            data {
                id
                status
            }
        }
    }
`;

type DeliveryDeliverProcessProps = {};

export const requestPageSize = 100;
export const DeliveryCompleteProcess: React.FC<DeliveryDeliverProcessProps> = () => {
    const history = useHistory();
    const match = useRouteMatch();
    const params: { routeId?: string; visitId?: string } = match.params;
    const routeId = params.routeId;
    const visitId = params.visitId;

    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
    const intl = useIntl();

    const handleGoBackPress = useCallback((): void => {
        history.push(`/delivery/${routeId}/${visitId}`);
    }, [history, routeId, visitId]);

    useEffect(() => {
        if (!visitId) {
            handleGoBackPress();
        }
    }, [visitId, handleGoBackPress]);

    const [isFetching, setIsFetching] = useState<boolean>(false);

    const {
        data: retrievedRequestsData,
        loading: retrievedRequestsLoading,
        error: retrievedRequestsError,
        fetchMore: retrievedRequestsFetchMore,
    } = useRequestsByVisitIdDeliveryCompleteQuery({
        variables: {
            visitId: visitId || '',
            pageSize: requestPageSize,
            cursor: '',
        },
    });

    const requestEdges = retrievedRequestsData?.requestsByVisitId.edges ?? [];
    const requestData = useMemo(() => {
        return {
            requests: requestEdges?.map((itemEdge) => itemEdge.node) ?? [],
            hasNextPage: retrievedRequestsData?.requestsByVisitId?.pageInfo?.hasNextPage ?? false,
            endCursor: retrievedRequestsData?.requestsByVisitId?.pageInfo?.cursor.afterCursor ?? '',
        };
    }, [requestEdges, retrievedRequestsData]);

    const handleLoadMoreItems = useCallback((): void => {
        if (requestData.hasNextPage && !isFetching) {
            setIsFetching(true);
            retrievedRequestsFetchMore({
                variables: {
                    pageSize: requestPageSize,
                    cursor: requestData.endCursor,
                },
            }).then(() => {
                setIsFetching(false);
            });
        }
    }, [isFetching, retrievedRequestsFetchMore, requestData.hasNextPage, requestData.endCursor]);

    useEffect(() => {
        if (!requestData) {
            handleLoadMoreItems();
        }
    }, [handleLoadMoreItems, requestData, retrievedRequestsError]);

    useEffect(() => {
        if (!routeId) {
            handleGoBackPress();
        }
    }, [routeId, handleGoBackPress]);

    const requests = useMemo<{
        incomplete: (
            | DeliveryCompleteDeliverStorageItemRequestFragment
            | DeliveryCompletePickupStorageItemRequestFragment
        )[];
        delivered: DeliveryCompleteDeliverStorageItemRequestFragment[];
        pickedUp: DeliveryCompletePickupStorageItemRequestFragment[];
    }>(() => {
        const deliveryRequests = requestData.requests
            .filter((r) => r && r.requestType === RequestType.DeliverStorageItem)
            .map((r) => r as DeliveryCompleteDeliverStorageItemRequestFragment);

        const pickupRequests = requestData.requests
            .filter((r) => r && r.requestType === RequestType.PickupStorageItem)
            .map((r) => r as DeliveryCompletePickupStorageItemRequestFragment);

        return {
            delivered: [
                ...deliveryRequests.filter((request) => request.status === RequestStatus.Processed),
            ],
            pickedUp: [
                ...pickupRequests.filter((request) => request.status === RequestStatus.Processed),
            ],
            incomplete: [
                ...deliveryRequests.filter((request) => request.status !== RequestStatus.Processed),
                ...pickupRequests.filter((request) => request.status !== RequestStatus.Processed),
            ],
        };
    }, [requestData.requests]);

    useEffect(() => {
        if (!routeId) {
            handleGoBackPress();
        }
    }, [routeId, handleGoBackPress]);

    const [
        deliveryCompleteVisitMutation,
        {
            loading: deliveryCompleteVisitMutationLoading,
            error: deliveryCompleteVisitMutationError,
        },
    ] = useDeliveryCompleteVisitMutation({
        onCompleted({ deliveryCompleteVisit: { data } }) {
            if (data) {
                handleGoBackPress();
            }
        },
    });

    const [signature, setSignature] = useState('');

    const handleCompleteButtonClick = useCallback(() => {
        setShowConfirmation(!showConfirmation);
    }, [showConfirmation]);

    const handleConfirmCompleteButtonClick = useCallback(() => {
        if (visitId !== undefined) {
            deliveryCompleteVisitMutation({
                variables: { data: { visitId: visitId, customerSignature: signature } },
            });
        }
    }, [signature, deliveryCompleteVisitMutation, visitId]);

    const handleResetSignClick = useCallback(() => {
        setSignature('');
    }, []);

    // Top tabs
    const tabs = [
        {
            title: intl.formatMessage({
                id: 'deliveryCompleteProcess.pickedUpTabText',
                defaultMessage: 'PickedUp',
            }),
        },
        {
            title: intl.formatMessage({
                id: 'deliveryCompleteProcess.deliveredTabText',
                defaultMessage: 'Delivered',
            }),
        },
        {
            title: intl.formatMessage({
                id: 'deliveryCompleteProcess.incompleteTabText',
                defaultMessage: 'Incomplete',
            }),
        },
    ];
    const [currentTab, setCurrentTab] = useState<number>(0);

    const handleTabSelect = (index: number): void => {
        setCurrentTab(index);
    };

    if (isFetching || retrievedRequestsLoading || deliveryCompleteVisitMutationLoading) {
        return (
            <View testID="data-deliveryCompleteLoading">
                <Text>
                    <FormattedMessage
                        id="deliveryCompleteProcess.loading"
                        defaultMessage="Loading..."
                    />
                </Text>
            </View>
        );
    }

    if (deliveryCompleteVisitMutationError) {
        return (
            <View
                style={deliveryCompleteProcessStyles.ViewErrorMessageParent}
                testID="data-errorMessage"
            >
                <Message
                    type="negative"
                    header={intl.formatMessage({
                        id: 'deliveryCompleteProcess.deliveryCompleteProcessErrorMessage',
                        defaultMessage: 'Could not deliver this item - please try again',
                    })}
                    content={deliveryCompleteVisitMutationError.message}
                    testID="data-deliveryCompleteProcessErrorMessage"
                />
            </View>
        );
    }

    return (
        <ScrollView>
            <View style={deliveryCompleteProcessStyles.ViewParent} testID="data-deliveryDetail">
                {!showConfirmation && (
                    <View>
                        <View style={deliveryCompleteProcessStyles.ViewTopBar}>
                            <Button
                                accessoryLeft={() => <Icon icon="back" />}
                                title={intl.formatMessage({
                                    id: 'deliveryRouteDetail.back',
                                    defaultMessage: 'Back',
                                })}
                                onPress={handleGoBackPress}
                                appearance="ghost"
                            />
                        </View>
                        <TopTabBar onSelect={handleTabSelect} selectedIndex={currentTab}>
                            {tabs.map((tab) => (
                                <TopTab key={tab.title} title={tab.title} />
                            ))}
                        </TopTabBar>
                        <View style={deliveryCompleteProcessStyles.ViewContent}>
                            <View style={deliveryCompleteProcessStyles.ViewContent}>
                                {(currentTab === 0 && requests.pickedUp.length > 0) ||
                                (currentTab === 1 && requests.delivered.length > 0) ||
                                (currentTab === 2 && requests.incomplete.length > 0) ? (
                                    <FlatList
                                        data={
                                            currentTab === 0
                                                ? requests.pickedUp
                                                : currentTab === 1
                                                ? requests.delivered
                                                : requests.incomplete
                                        }
                                        renderItem={({ item }) => <RequestDetails request={item} />}
                                        keyExtractor={(item) => item.id}
                                    />
                                ) : (
                                    <Text>
                                        <FormattedMessage
                                            id="deliveryCompleteProcess.noRequestsText"
                                            defaultMessage="There are no requests."
                                        />
                                    </Text>
                                )}
                            </View>
                        </View>
                        <View style={deliveryCompleteProcessStyles.ViewButton}>
                            <Button
                                title={intl.formatMessage({
                                    id: 'deliverycompletebutton',
                                    defaultMessage: 'Complete Delivery',
                                })}
                                onPress={() => {
                                    handleCompleteButtonClick();
                                }}
                            />
                        </View>
                    </View>
                )}
                {showConfirmation && (
                    <View>
                        <View style={RequestDetailsStyles.ViewParent}>
                            <View
                                style={RequestDetailsStyles.TouchableOpacityItem}
                                testID="data-requestDetails"
                            >
                                <View style={RequestDetailsStyles.ViewDetailGroup}>
                                    <View>
                                        <View style={RequestDetailsStyles.ViewDetailGroup}>
                                            <Text
                                                style={RequestDetailsStyles.CustomerTextItemLabel}
                                                testID="data-RequestDetailsLocation"
                                            >
                                                <FormattedMessage
                                                    id="deliveryCompelete.Pickedups"
                                                    defaultMessage="Pickups: "
                                                />
                                            </Text>
                                            <Text style={RequestDetailsStyles.TextItemValue}>
                                                {' '}
                                                {requests?.pickedUp?.length}
                                            </Text>
                                        </View>
                                        <View style={RequestDetailsStyles.ViewDetailGroup}>
                                            <Text
                                                style={RequestDetailsStyles.CustomerTextItemLabel}
                                                testID="data-RequestDetailsLocation"
                                            >
                                                <FormattedMessage
                                                    id="deliveryCompelete.Deliveries"
                                                    defaultMessage="Deliveries: "
                                                />
                                            </Text>
                                            <Text style={RequestDetailsStyles.TextItemValue}>
                                                {requests?.delivered?.length}
                                            </Text>
                                        </View>
                                        <View style={RequestDetailsStyles.ViewDetailGroup}>
                                            <Text
                                                style={RequestDetailsStyles.CustomerTextItemLabel}
                                                testID="data-RequestDetailsLocation"
                                            >
                                                <FormattedMessage
                                                    id="requestDetails.customer"
                                                    defaultMessage="Cost: "
                                                />
                                            </Text>
                                            <Text style={RequestDetailsStyles.TextItemValue}>
                                                20
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                                <View>
                                    <TextInput
                                        type="text"
                                        value={signature}
                                        onChange={(e) => setSignature(e)}
                                        placeholder={intl.formatMessage({
                                            id: 'deliveryComplete.CustomerSignature',
                                            defaultMessage: 'Customer Signature',
                                        })}
                                        ariaLabel={intl.formatMessage({
                                            id: 'deliveryComplete.CustomerSignature',
                                            defaultMessage: 'Customer Signature',
                                        })}
                                    />
                                </View>
                            </View>
                            <View style={RequestDetailsStyles.ViewDetailGroup}>
                                <View
                                    style={{
                                        flexDirection: 'row',
                                        margin: 5,
                                    }}
                                >
                                    <Button
                                        title={intl.formatMessage({
                                            id: 'deliverycompletebutton',
                                            defaultMessage: 'Confirm Completion',
                                        })}
                                        onPress={() => {
                                            handleConfirmCompleteButtonClick();
                                        }}
                                    />
                                </View>
                                <View
                                    style={{
                                        flexDirection: 'row',
                                        margin: 5,
                                    }}
                                >
                                    <Button
                                        title={intl.formatMessage({
                                            id: 'deliverycompletebutton',
                                            defaultMessage: 'Reset Signature',
                                        })}
                                        onPress={() => {
                                            handleResetSignClick();
                                        }}
                                    />
                                </View>
                            </View>
                        </View>
                    </View>
                )}
            </View>
        </ScrollView>
    );
};

const deliveryCompleteProcessStyles = StyleSheet.create({
    ViewParent: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    ViewTabs: {
        marginVertical: 5,
        display: 'flex',
        flexDirection: 'row',
    },
    ViewContent: {
        paddingVertical: 5,
    },
    TextTab: {
        flex: 1,
        color: '#000',
        backgroundColor: '#D4D4D5',
        fontSize: 18,
        textAlign: 'center',
        fontWeight: '700',
    },
    ViewTopBar: {
        paddingBottom: 5,
        display: 'flex',
        flexDirection: 'row' as 'row',
        alignItems: 'center',
        borderBottomWidth: 1,
        borderBottomColor: '#d4d4d5',
    },
    ViewActionsGroup: {
        marginVertical: 5,
        display: 'flex',
        flexDirection: 'column',
        borderTopColor: '#D4D4D5',
        borderTopWidth: 1,
    },
    ViewButton: {
        marginTop: 10,
    },
    CancelAction: {
        borderWidth: 1,
        borderRadius: 5,
        padding: 5,
    },
    ViewDetailGroup: {
        marginVertical: 2,
        display: 'flex',
        flexDirection: 'row',
    },
    DeliveryHeader: {
        fontWeight: '700',
        flex: 1,
    },

    ViewExpandPickList: {
        flex: 1,
        paddingVertical: 5,
        display: 'flex',
        overflowY: 'scroll',
        overflowX: 'none',
    },
    ViewExpandPickListText: {
        marginVertical: 5,
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
    },
    ViewManualScanIcons: {
        marginVertical: 5,
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    ViewActions: {
        marginVertical: 5,
        display: 'flex',
        flexDirection: 'row',
    },
    ViewNavigationButtons: {
        flex: 1,
        marginRight: 5,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    TextActionList: {
        alignSelf: 'flex-start',
        color: '#0984e3',
    },
    TextCurrentItemNumber: {
        fontWeight: '700',
        fontSize: 18,
        textAlign: 'center',
    },
    ViewItem: {
        flex: 1,
    },
    TextHeader: {
        fontWeight: '700',
    },
    ViewErrorMessageParent: {
        marginTop: 5,
        marginBottom: 5,
    },
    signature: {
        flex: 1,
        borderColor: '#000033',
        borderWidth: 1,
    },
});

type RequestDetailsProps = {
    request:
        | DeliveryCompleteDeliverStorageItemRequestFragment
        | DeliveryCompletePickupStorageItemRequestFragment;
};

const RequestDetails: React.FC<RequestDetailsProps> = ({ request }) => {
    const {
        storageItem,
        status,
        customer: { firstName, lastName },
    } = request;

    return (
        <Card
            style={RequestDetailsStyles.ViewParent}
            onPress={() => null}
            testID="data-requestDetails"
        >
            <View style={RequestDetailsStyles.ViewDetailGroup}>
                <Image
                    style={RequestDetailsStyles.ImageItem}
                    source={{ uri: storageItem?.storageItemType.image }}
                    testID="data-RequestDetailsImage"
                />
                <View>
                    <View style={RequestDetailsStyles.ViewDetailGroup}>
                        <Text
                            style={RequestDetailsStyles.TextItemValue}
                            testID="data-RequestDetailsDescription"
                        >
                            {storageItem?.storageItemType.description}
                        </Text>
                    </View>
                    <View style={RequestDetailsStyles.ViewDetailGroup}>
                        <Text
                            style={RequestDetailsStyles.CustomerTextItemLabel}
                            testID="data-RequestDetailsLocation"
                        >
                            <FormattedMessage
                                id="requestDetails.customer"
                                defaultMessage="Customer: "
                            />
                        </Text>
                        <Text
                            style={RequestDetailsStyles.TextItemValue}
                        >{`${firstName} ${lastName}`}</Text>
                    </View>
                    <View style={RequestDetailsStyles.ViewDetailGroup}>
                        <Text
                            style={RequestDetailsStyles.CustomerTextItemLabel}
                            testID="data-RequestDetailsLocation"
                        >
                            <FormattedMessage
                                id="requestDetails.barcode"
                                defaultMessage="Barcode"
                            />
                            :
                        </Text>
                        <Text style={RequestDetailsStyles.TextItemValue}>
                            {storageItem?.barcode}
                        </Text>
                    </View>
                    <View style={RequestDetailsStyles.ViewDetailGroup}>
                        <Text
                            style={RequestDetailsStyles.CustomerTextItemLabel}
                            testID="data-RequestDetailsLocation"
                        >
                            <FormattedMessage
                                id="requestDetails.customer"
                                defaultMessage="Status: "
                            />
                        </Text>
                        <Text style={RequestDetailsStyles.TextItemValue}>{status}</Text>
                    </View>
                </View>
            </View>
        </Card>
    );
};

const RequestDetailsStyles = StyleSheet.create({
    ViewParent: {
        marginVertical: 3,
    },
    ViewDetailGroup: {
        marginVertical: 2,
        display: 'flex',
        flexDirection: 'row',
    },
    ViewImageGroup: {
        marginVertical: 5,
        display: 'flex',
        justifyContent: 'center',
    },
    TextDetailHeader: {
        fontWeight: '700',
    },
    ImageItem: {
        height: 40,
        width: 40,
        resizeMode: 'stretch',
        marginLeft: 5,
        marginRight: 10,
    },

    ViewItemsList: {
        marginVertical: 5,
    },
    ViewItem: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
    },
    ViewItemsSummary: {
        marginVertical: 5,
    },
    ViewAssignee: {
        marginVertical: 5,
    },
    TextHeader: {
        fontWeight: '700',
    },
    TextItemLabel: {
        flex: 1,
    },
    CustomerTextItemLabel: {
        flex: 0,
        marginRight: 5,
    },
    TextItemValue: {
        flex: 1,
        fontWeight: '700',
    },
    TouchableOpacityItem: {
        borderWidth: 1,
        borderRadius: 5,
        padding: 5,
    },
    TextDescription: {
        fontWeight: '700',
    },
    ViewButton: {},
});
