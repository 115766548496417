import { gql } from '@apollo/client';
import React, { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ScrollView, StyleSheet, Text, View } from 'react-native';
import { Button, ErrorMessage, Message, TextInput } from '@valet/ui-components';
import {
    useEmployeeDetailGetEmployeeByIdQuery,
    AdminEmployeeDetailFragment,
    useAdminUpdateEmployeeMutation,
    useAdminUpdateEmployeePasswordMutation,
} from '../../../../schema-types';
import { useHistory, useParams } from 'react-router-dom';
import { AddressInput, NewAddressForm } from '../../../Organisms/NewAddress/NewAddressForm';
import { CountryCode, LocaleType } from '../../../Organisms/AddressTypings';
import { validateAddressInput } from '../../../Organisms/NewAddress/ValidateAddressInput';

const Employee_FRAGMENT = gql`
    fragment adminEmployeeDetail on Employee {
        id
        firstName
        lastName
        email
        homePhone
        cellPhone
        workPhone
        startDate
        endDate
        address {
            id
            address1
            address2
            city
            zoneId
            countryId
            zip
            longitude
            latitude
        }
        type
    }
`;

export const RETRIEVE_EMPLOYEE_QUERY = gql`
    query employeeDetailGetEmployeeById($employeeId: String!) {
        employeeById(id: $employeeId) {
            ...adminEmployeeDetail
        }
    }
    ${Employee_FRAGMENT}
`;

export const ADMIN_UPDATE_EMLOYEE_MUTATION = gql`
    mutation adminUpdateEmployee($data: AdminUpdateEmployeeInput!) {
        adminUpdateEmployee(data: $data) {
            data {
                id
            }
        }
    }
`;

export const ADMIN_EMLOYEE_RESET_PASSWORD_MUTATION = gql`
    mutation adminUpdateEmployeePassword($data: AdminUpdateEmployeePasswordInput!) {
        adminUpdateEmployeePassword(data: $data) {
            data {
                id
            }
        }
    }
`;

const defaultAddressInput: AddressInput = {
    id: '',
    address1: '',
    address2: '',
    city: '',
    zoneId: '',
    zip: '',
    countryId: '',
};

type EmployeeDetailPageProps = {
    locale: LocaleType;
    countryCode: CountryCode;
};

export const EmployeeDetailPage: React.FC<EmployeeDetailPageProps> = ({ countryCode, locale }) => {
    const history = useHistory();
    const intl = useIntl();
    const [errorMessage, setErrorMessage] = useState<ErrorMessage | undefined>(undefined);
    const [addressInput, setAddressInput] = useState<AddressInput>({
        ...defaultAddressInput,
        countryId: countryCode,
    });
    const { employeeId } = useParams<{ employeeId?: string }>();

    const [employee, setEmployee] = useState<AdminEmployeeDetailFragment>({
        id: '',
        firstName: '',
        lastName: '',
        email: '',
        homePhone: '',
        cellPhone: '',
        workPhone: '',
        startDate: null,
        endDate: null,
        address: {
            id: '',
            address1: '',
            address2: '',
            city: '',
            zoneId: '',
            countryId: '',
            zip: '',
        },
        type: '',
    });

    const {
        data: retrievedEmployeeData,
        loading: retrievedEmployeeDetailLoading,
        error: retrievedEmployeeDetailError,
        refetch,
    } = useEmployeeDetailGetEmployeeByIdQuery({
        variables: {
            employeeId: employeeId || '',
        },
        onCompleted: (data) => {
            setEmployee(
                data.employeeById ?? {
                    id: '',
                    firstName: '',
                    lastName: '',
                    email: '',
                    homePhone: '',
                    cellPhone: '',
                    workPhone: '',
                    startDate: null,
                    endDate: null,
                    address: {
                        id: '',
                        address1: '',
                        address2: '',
                        city: '',
                        zoneId: '',
                        countryId: '',
                        zip: '',
                    },
                    type: '',
                },
            );
            setAddressInput({
                id: data?.employeeById?.address.id || '',
                address1: data?.employeeById?.address.address1 || '',
                address2: data?.employeeById?.address.address2 || '',
                city: data?.employeeById?.address.city || '',
                zoneId: data?.employeeById?.address.zoneId || '',
                countryId: data?.employeeById?.address.countryId || '',
                zip: data?.employeeById?.address.zip || '',
            });
        },
    });

    let employeesData = retrievedEmployeeData?.employeeById as AdminEmployeeDetailFragment;

    const [
        adminUpdateEmployeeMutation,
        {
            loading: adminUpdateEmployeeDetailMutationLoading,
            error: adminUpdateEmployeeDetailMutationError,
        },
    ] = useAdminUpdateEmployeeMutation({
        onCompleted(data) {
            if (data) {
                refetch({
                    employeeId: employeeId || '',
                });
                handleBacklClick();
            }
        },
    });

    const handleAddressChanged = useCallback(
        async (changedAddressInput: AddressInput): Promise<void> => {
            try {
                const {
                    id,
                    address1,
                    address2,
                    city,
                    zoneId,
                    countryId,
                    zip,
                } = changedAddressInput;
                if (id) {
                    setAddressInput(changedAddressInput);
                    setEmployee({
                        ...employee,
                        address: {
                            id,
                            address1,
                            address2,
                            city,
                            zoneId,
                            countryId,
                            zip,
                        },
                    });
                }
            } catch (ignore) { } //eslint-disable-line no-empty
        },
        [setAddressInput, employee, setEmployee],
    );

    const handleSaveEmployeeDetailClick = useCallback(async () => {
        if (employee) {
            try {
                const invalidAddress = validateAddressInput(addressInput, intl);
                if (invalidAddress) {
                    return setErrorMessage(invalidAddress);
                } else {
                    setErrorMessage(undefined);
                    await adminUpdateEmployeeMutation({
                        variables: {
                            data: {
                                id: employee.id,
                                firstName: employee.firstName,
                                lastName: employee.lastName,
                                email: employee.email,
                                homePhone: employee.homePhone,
                                cellPhone: employee.cellPhone,
                                workPhone: employee.workPhone,
                                address: {
                                    address1: employee.address.address1,
                                    address2: employee.address.address2,
                                    city: employee.address.city,
                                    zoneId: employee.address.zoneId,
                                    countryId: employee.address.countryId,
                                    zip: employee.address.zip,
                                },
                            },
                        },
                    });
                }
            } catch (ignore) { }
        }
    }, [adminUpdateEmployeeMutation, employee, addressInput, intl]);

    const [
        adminUpdateEmployeePasswordMutation,
        {
            loading: adminUpdateEmployeePasswordMutationLoading,
            error: adminUpdateEmployeePasswordMutationError,
        },
    ] = useAdminUpdateEmployeePasswordMutation({
        onCompleted() {
            alert(
                intl.formatMessage({
                    id: 'employeeDetail.resetPasswordMessage',
                    defaultMessage: 'Password has been reset',
                }),
            );
        },
    });

    const handleResetPasswordClick = useCallback(async () => {
        if (employeesData) {
            setErrorMessage(undefined);
            try {
                await adminUpdateEmployeePasswordMutation({
                    variables: {
                        data: { id: employeesData.id },
                    },
                });
            } catch (error) {
                setErrorMessage(error);
            }
        }
    }, [adminUpdateEmployeePasswordMutation, employeesData]);

    const handleBacklClick = useCallback(async () => {
        history.push(`/admin/employee`);
    }, [history]);


    return (
        <ScrollView>
            <View style={employeePageStyles.ViewParent} testID="data-employeeDetail">
                <View style={employeePageStyles.ViewChild}>
                    <View style={employeePageStyles.ViewContentParent}>
                        <View style={employeePageStyles.ViewTitleParent}>
                            <Text style={employeePageStyles.TextTitle} testID="data-employee">
                                {`${intl.formatMessage({
                                    id: 'employeeDetail.id',
                                    defaultMessage: 'Employee Details:',
                                })}${''}`}
                            </Text>
                        </View>
                        {(
                            retrievedEmployeeDetailLoading ||
                            adminUpdateEmployeeDetailMutationLoading ||
                            adminUpdateEmployeePasswordMutationLoading
                        ) && (
                                <View testID="data-employeeDetailItemLoading" >
                                    <Text>
                                        <FormattedMessage id="employeeDetail.loading" defaultMessage="Loading..." />
                                    </Text>
                                </View >
                            )}
                        {adminUpdateEmployeePasswordMutationError && (
                            <View style={employeePageStyles.ViewErrorMessageParent} testID="data-errorMessage">
                                <Message
                                    type="negative"
                                    header={intl.formatMessage({
                                        id: 'employeeDetail.employeeDetailResetPasswordErrorMessage',
                                        defaultMessage: 'Could not reset password - please try again',
                                    })}
                                    content={adminUpdateEmployeePasswordMutationError.message}
                                    testID="data-employeeDetailResetPasswordErrorMessage"
                                />
                            </View>
                        )}
                        {adminUpdateEmployeeDetailMutationError && (
                            <View style={employeePageStyles.ViewErrorMessageParent} testID="data-errorMessage">
                                <Message
                                    type="negative"
                                    header={intl.formatMessage({
                                        id: 'employeeDetail.employeeDetailUpdateErrorMessage',
                                        defaultMessage: 'Could not update employee detail - please try again',
                                    })}
                                    content={adminUpdateEmployeeDetailMutationError.message}
                                    testID="data-employeeDetailUpdateErrorMessage"
                                />
                            </View>
                        )}
                        {retrievedEmployeeDetailError && (
                            <View style={employeePageStyles.ViewErrorMessageParent} testID="data-errorMessage">
                                <Message
                                    type="negative"
                                    header={intl.formatMessage({
                                        id: 'employeeDetail.employeeDetailErrorMessage',
                                        defaultMessage: 'Could not load detail for the eployee - please try again',
                                    })}
                                    content={retrievedEmployeeDetailError.message}
                                    testID="data-employeeDetailErrorMessage"
                                />
                            </View>
                        )}
                        {errorMessage && (
                            <Message
                                type={errorMessage.type}
                                header={errorMessage.header}
                                content={errorMessage.content}
                            />
                        )}
                        <View style={employeePageStyles.ViewTitleParent}>
                            <View style={employeePageStyles.ViewDescription}>
                                <View>
                                    <FormattedMessage
                                        id="employeeDetail.firstName"
                                        defaultMessage="First Name"
                                    />
                                    <TextInput
                                        type="text"
                                        ariaLabel="Description"
                                        value={employee?.firstName}
                                        onChange={(value: string) => {
                                            setEmployee({ ...employee, firstName: value });
                                        }}
                                    />
                                </View>
                            </View>
                            <View style={employeePageStyles.ViewDescription}>
                                <View>
                                    <FormattedMessage
                                        id="employeeDetail.lastName"
                                        defaultMessage="Last Name"
                                    />
                                    <TextInput
                                        type="text"
                                        ariaLabel="Description"
                                        value={employee?.lastName}
                                        onChange={(value: string) => {
                                            setEmployee({ ...employee, lastName: value });
                                        }}
                                    />
                                </View>
                            </View>
                            <View style={employeePageStyles.ViewDescription}>
                                <FormattedMessage
                                    id="employeeDetail.email"
                                    defaultMessage="Email"
                                />
                                <TextInput
                                    type="text"
                                    ariaLabel="Email"
                                    value={employee?.email || ''}
                                    onChange={(value: string) => {
                                        setEmployee({ ...employee, email: value });
                                    }}
                                />
                            </View>
                            <View style={employeePageStyles.ViewDescription}>
                                <FormattedMessage
                                    id="employeeDetail.homePhone"
                                    defaultMessage="Home Phone"
                                />
                                <TextInput
                                    type="text"
                                    ariaLabel="Home Phone"
                                    value={employee?.homePhone || ''}
                                    onChange={(value: string) => {
                                        setEmployee({ ...employee, homePhone: value });
                                    }}
                                />
                            </View>
                            <View style={employeePageStyles.ViewDescription}>
                                <FormattedMessage
                                    id="employeeDetail.cellPhone"
                                    defaultMessage="Cell Phone"
                                />
                                <TextInput
                                    type="text"
                                    ariaLabel="Cell Phone"
                                    value={employee?.cellPhone || ''}
                                    onChange={(value: string) => {
                                        setEmployee({ ...employee, cellPhone: value });
                                    }}
                                />
                            </View>
                            <View style={employeePageStyles.ViewDescription}>
                                <FormattedMessage
                                    id="employeeDetail.workPhone"
                                    defaultMessage="Work Phone"
                                />
                                <TextInput
                                    type="text"
                                    ariaLabel="Work Phone"
                                    value={employee?.workPhone || ''}
                                    onChange={(value: string) => {
                                        setEmployee({ ...employee, workPhone: value });
                                    }}
                                />
                            </View>
                            <View style={employeePageStyles.ViewDescription}>
                                <FormattedMessage
                                    id="employeeDetail.AddressHeader"
                                    defaultMessage="Address"
                                />
                                <NewAddressForm
                                    locale={locale}
                                    countryCode={addressInput.countryId as CountryCode}
                                    currentAddress={addressInput}
                                    displayNames={false}
                                    onAddressChanged={handleAddressChanged}
                                />
                            </View>
                        </View>
                    </View>
                </View>
                <View
                    style={{
                        marginVertical: 10,
                        marginHorizontal: 10,
                        display: 'flex',
                        flexDirection: 'row',
                    }}
                >
                    <View style={{ flex: 1, marginRight: 10 }}>
                        <Button
                            title={intl.formatMessage({
                                id: 'employeeDetail.Cancel',
                                defaultMessage: 'Cancel',
                            })}
                            onPress={handleBacklClick}
                        />
                    </View>
                    <View style={{ flex: 1, marginRight: 10 }}>
                        <Button
                            title={intl.formatMessage({
                                id: 'employeeDetail.ResetPassword',
                                defaultMessage: 'Password Reset',
                            })}
                            onPress={handleResetPasswordClick}
                        />
                    </View>
                    <View style={{ flex: 1 }}>
                        <Button
                            title={intl.formatMessage({
                                id: 'employeeDetail.Save',
                                defaultMessage: 'Save',
                            })}
                            onPress={handleSaveEmployeeDetailClick}
                        />
                    </View>
                </View>
            </View>
        </ScrollView>
    );
};

const employeePageStyles = StyleSheet.create({
    ViewParent: {
        display: 'flex',
        flexDirection: 'column',
    },
    ViewTabs: {
        marginVertical: 5,
        display: 'flex',
        flexDirection: 'row',
    },
    ViewContent: {
        paddingVertical: 5,
    },
    TextTab: {
        flex: 1,
        color: '#000',
        backgroundColor: '#D4D4D5',
        fontSize: 18,
        textAlign: 'center',
        fontWeight: '700',
    },

    ViewChild: {
        display: 'flex',
        flexDirection: 'row',
    },
    ViewImageParent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    RouteImage: {
        height: 40,
        width: 40,
        resizeMode: 'stretch',
    },
    ViewContentParent: {
        paddingHorizontal: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    ViewTitleParent: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 10,
    },
    ViewTitleChild: {},
    TextTitle: {
        fontSize: 18,
        fontWeight: 'bold',
    },
    ViewDescription: {
        marginTop: 10,
        fontWeight: 'bold',
    },
    TextDescription: { fontSize: 14 },
    ViewStatus: {},
    TextStatus: {
        fontSize: 12,
        fontWeight: '700',
    },
    ViewDate: {},
    TextDate: {
        fontSize: 12,
    },
    ViewErrorMessageParent: {
        marginTop: 5,
        marginBottom: 5,
    },
});
