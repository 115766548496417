/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useApplicationContext } from '../../ApplicationContext';

import { View } from 'react-native';
import { Button } from '@valet/ui-components';

type LanguageSelectorProps = {};

export const LanguageSelector: React.FC<LanguageSelectorProps> = () => {
    const { currentLanguage, supportedLanguages, changeLanguage } = useApplicationContext();
    return (
        <>
            {/* {currentLanguage}
            {supportedLanguages?.map((supportedLanguage) => (
                <View style={{ marginVertical: 3 }} key={supportedLanguage.code}>
                    <Button
                        title={supportedLanguage.description}
                        onPress={() => changeLanguage(supportedLanguage.code)}
                        size="tiny"
                        appearance="outline"
                    />
                </View>
            ))} */}
        </>
    );
};
