import { gql } from '@apollo/client';
import React, { useMemo, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Card, Message } from '@valet/ui-components';

import { FlatList, ScrollView, StyleSheet, Text, View } from 'react-native';
import { AdminStorageItemTypeCategoryFragment, useGetAdminStorageItemTypeCategoryQuery } from '../../../../schema-types';
import { useHistory } from 'react-router-dom';

export const storageItemTypeCategoryPageSize = 100;

const ADMIN_STORAGEITEMTYPE_CATEGORY_FRAGMENT = gql`
    fragment adminStorageItemTypeCategory on StorageItemTypeCategory {
        id
        name
        parentId
    }
`;


export const RETRIEVE_STORAGEITEMTYPE_QUERY = gql`
    query getAdminStorageItemTypeCategory {
        storageItemTypeCategory{
            ...adminStorageItemTypeCategory
        }
    }

    ${ADMIN_STORAGEITEMTYPE_CATEGORY_FRAGMENT}
`;

type StorageItemTypeCategoryPageProps = {
    onItemCategorySelect: (id: string) => void;
    onAddItemCategorySelect: () => void
};

export const StorageItemTypeCategoryPage: React.FC<StorageItemTypeCategoryPageProps> = ({ onItemCategorySelect: onCategorySelect, onAddItemCategorySelect: onAddCategorySelect }) => {
    const history = useHistory();
    const intl = useIntl();


    const {
        data: retrievedStorageItemTypeCategorysData,
        loading: retrievedStorageItemTypeCategorysLoading,
        error: retrievedStorageItemTypeCategorysError,
    } = useGetAdminStorageItemTypeCategoryQuery();

    const storageItemTypeCategoryData = useMemo(() => {
        return {
            storageItemTypeCategory: retrievedStorageItemTypeCategorysData?.storageItemTypeCategory
        };
    }, [retrievedStorageItemTypeCategorysData]);

    const handleItemClick = useCallback(
        (id: string) => {
            onCategorySelect(id);
        },
        [onCategorySelect],
    );

    const handleAddClick = useCallback(
        () => {
            onAddCategorySelect();
        },
        [onAddCategorySelect],
    );

    const handleBacklClick = useCallback(async () => {
        history.push(`/admin`);
    }, [history]);

    return (
        <ScrollView>
            <View style={storageItemTypeCategoryPageStyles.ViewParent} testID="data-storageItemTypeCategoryCategory">
                <View style={storageItemTypeCategoryPageStyles.ViewTabs}>
                    <View>
                        <View style={{ flex: 1, marginRight: 2 }}>
                            <Button
                                title={intl.formatMessage({
                                    id: 'storageItemTypeCategoryCategory.backButton',
                                    defaultMessage: 'Back',
                                })}
                                onPress={handleBacklClick}
                            />
                        </View>
                    </View>
                    <View>
                        <View style={{ flex: 1, marginRight: 20, marginBottom: 10 }}>
                            <Button
                                title={intl.formatMessage({
                                    id: 'storageItemTypeCategoryCategory.addNewStorageItemTypeCategory',
                                    defaultMessage: '+ Add New Storage Item Type Category',
                                })}
                                onPress={handleAddClick}
                            />
                        </View>
                    </View>
                </View>
                {retrievedStorageItemTypeCategorysData && (
                    <View style={storageItemTypeCategoryPageStyles.ViewContent}>
                        {storageItemTypeCategoryData.storageItemTypeCategory ?
                            (
                                <FlatList
                                    data={storageItemTypeCategoryData.storageItemTypeCategory}
                                    renderItem={({ item }) => (
                                        <StorageItemTypeCategoryItem
                                            key={item.id}
                                            storageItemTypeCategory={item}
                                            onItemClick={handleItemClick}
                                        />
                                    )}
                                    keyExtractor={(item) => item.id}
                                />
                            ) :
                            retrievedStorageItemTypeCategorysLoading ?
                                (
                                    <Text>
                                        <FormattedMessage
                                            id="storageItemTypeCategory.loading"
                                            defaultMessage="Loading..."
                                        />
                                    </Text>
                                ) : retrievedStorageItemTypeCategorysError ?
                                    (
                                        <View
                                            style={storageItemTypeCategoryPageStyles.ViewErrorMessageParent}
                                            testID="data-errorMessage"
                                        >
                                            <Message
                                                type="negative"
                                                header={intl.formatMessage({
                                                    id: 'storageItemTypeCategory.storageItemTypeCategoryErrorMessage',
                                                    defaultMessage: 'Could not load detail for the storageItemTypeCategory - please try again',
                                                })}
                                                content={retrievedStorageItemTypeCategorysError.message}
                                                testID="data-storageItemTypeCategoryErrorMessage"
                                            />
                                        </View>
                                    ) : null
                        }
                    </View>
                )}

            </View>
        </ScrollView>
    );
};


const storageItemTypeCategoryPageStyles = StyleSheet.create({
    ViewParent: {
        display: 'flex',
        flexDirection: 'column',
    },
    ViewTabs: {
        marginVertical: 5,
        display: 'flex',
        flexDirection: 'row',
    },
    ViewContent: {
        paddingVertical: 5,
    },
    TextTab: {
        flex: 1,
        color: '#000',
        backgroundColor: '#D4D4D5',
        fontSize: 18,
        textAlign: 'center',
        fontWeight: '700',
    },
    ViewErrorMessageParent: {
        marginTop: 5,
        marginBottom: 5,
    },
});

type StorageItemTypeCategoryItemProps = {
    storageItemTypeCategory: AdminStorageItemTypeCategoryFragment;
    onItemClick: (id: string) => void;
};

const StorageItemTypeCategoryItem: React.FC<StorageItemTypeCategoryItemProps> = ({ storageItemTypeCategory, onItemClick }) => {
    const intl = useIntl();

    return (
        <Card
            style={storageItemTypeCategoryItemStyles.ViewParent}
            onPress={() => onItemClick(storageItemTypeCategory?.id || '')}
            testID="data-storageItemTypeCategory"
        >
            <View style={storageItemTypeCategoryItemStyles.ViewChild}>
                <View style={storageItemTypeCategoryItemStyles.ViewContentParent}>
                    <View style={storageItemTypeCategoryItemStyles.ViewTitleParent}>
                        <Text style={storageItemTypeCategoryItemStyles.TextTitle} testID="data-storageItemTypeCategoryName">
                            {`${intl.formatMessage({
                                id: 'storageItemTypeCategory.Name',
                                defaultMessage: 'Name: ',
                            })}`}
                        </Text>
                        <Text>{storageItemTypeCategory?.name}</Text>
                    </View>
                </View>
            </View>
        </Card>
    );
};

const storageItemTypeCategoryItemStyles = StyleSheet.create({
    ViewParent: {
        marginVertical: 3,
    },
    ViewChild: {
        display: 'flex',
        flexDirection: 'row',
    },
    ViewImageParent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    StorageItemTypeCategoryImage: {
        height: 40,
        width: 40,
        resizeMode: 'stretch',
    },
    ViewContentParent: {
        paddingHorizontal: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    ViewTitleParent: {
        display: 'flex',
        flexDirection: 'column',
    },
    ViewTitleChild: {},
    TextTitle: {
        fontSize: 18,
        fontWeight: 'bold',
    },
    ViewDescription: {},
    TextDescription: { fontSize: 14 },
    ViewStatus: {},
    TextStatus: {
        fontSize: 12,
        fontWeight: '700',
    },
    ViewDate: {},
    TextDate: {
        fontSize: 12,
    },
    ViewErrorMessageParent: {
        marginTop: 5,
        marginBottom: 5,
    },
});

