import React from 'react';
import { gql } from '@apollo/client';

import { Button } from '@valet/ui-components';
import { View, Text, StyleSheet } from 'react-native';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    PicklistSummaryPicklistItemFragment,
    PickListStatus,
    RequestStatus,
} from '../../../../schema-types';

export const PICKLIST_SUMMARY_PICKLIST_ITEM_FRAGMENT = gql`
    fragment PicklistSummaryPicklistItem on PickListItem {
        id
        request {
            id
            status
        }
        storageItem {
            id
            barcode
            location {
                description
            }
            storageItemType {
                id
                name
                description
            }
        }
    }
`;

type PickListSummaryDetails = {
    toBePicked: number;
    picked: number;
    excluded: number;
};

export type FiltersType = {
    description?: string;
    visitStartTime?: string;
    visitEndTime?: string;
    locations?: { from?: string | null; to?: string | null }[];
    storageItemTypeIds?: string[];
    maxQuantity?: number | null;
    visitIds?: string[];
};

type PickListSummaryProps = {
    onGoBack: () => void;
    id: string;
    filters: FiltersType;
    pickListItems: PicklistSummaryPicklistItemFragment[];
    status: PickListStatus;
    onProcessListPress: () => void;
    onCompleteListPress: () => void;
    onDeleteListPress: () => void;
};

export const PickListSummary: React.FC<PickListSummaryProps> = ({
    onGoBack,
    id,
    filters,
    pickListItems,
    status,
    onProcessListPress,
    onCompleteListPress,
    onDeleteListPress,
}) => {
    const intl = useIntl();

    const details: PickListSummaryDetails = {
        toBePicked: pickListItems.reduce(
            (acc, cur) => (cur.request?.status === RequestStatus.InPicklist ? acc + 1 : acc),
            0,
        ),
        picked: pickListItems.reduce(
            (acc, cur) => (cur.request?.status === RequestStatus.PickingProcessed ? acc + 1 : acc),
            0,
        ),
        excluded: pickListItems.reduce(
            (acc, cur) => (cur.request?.status === RequestStatus.Excluded ? acc + 1 : acc),
            0,
        ),
    };

    return (
        <View style={pickListSummaryStyles.ViewParent} testID="data-pickListSummaryPage">
            <Button
                title={intl.formatMessage({
                    id: 'pickListSummary.backButton',
                    defaultMessage: 'Go Back',
                })}
                onPress={onGoBack}
                testID="data-pickListSummaryBackButton"
                size="small"
            />

            <PickListSummaryDetails
                pickListId={id}
                pickListItems={pickListItems}
                //TODO: Hardcoded data
                assignee={['Radiun', 'Maurice', 'Amin']}
                filters={filters}
                details={details}
            />

            <View
                style={pickListSummaryStyles.ViewActions}
                testID="data-pickListSummaryActionsContainer"
            >
                <View style={pickListSummaryStyles.ViewActionButton}>
                    <Button
                        disabled={details.toBePicked === 0}
                        title={intl.formatMessage({
                            id: 'pickListSummary.processListButton',
                            defaultMessage: 'Process List',
                        })}
                        onPress={onProcessListPress}
                        testID="data-pickListSummaryActionProcess"
                    />
                </View>

                <View
                    style={[
                        pickListSummaryStyles.ViewActionButton,
                        { display: 'flex', flexDirection: 'row' },
                    ]}
                >
                    <View style={{ flex: 1, marginRight: 3 }}>
                        <Button
                            disabled={status !== 'IN_PROGRESS'}
                            title={intl.formatMessage({
                                id: 'pickListSummary.completeListButton',
                                defaultMessage: 'Complete List',
                            })}
                            onPress={onCompleteListPress}
                            testID="data-pickListSummaryActionComplete"
                        />
                    </View>

                    <View style={{ flex: 1, marginLeft: 3 }}>
                        <Button
                            title={intl.formatMessage({
                                id: 'pickListSummary.deleteListButton',
                                defaultMessage: 'Delete List',
                            })}
                            onPress={onDeleteListPress}
                            testID="data-pickListSummaryActionDelete"
                        />
                    </View>
                </View>
            </View>
        </View>
    );
};

const pickListSummaryStyles = StyleSheet.create({
    ViewParent: {
        height: '100%',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    ViewActions: {
        marginVertical: 5,
    },
    ViewActionButton: {
        marginVertical: 5,
    },
});

type PickListSummaryDetailsProps = {
    pickListId: string;
    pickListItems: PicklistSummaryPicklistItemFragment[];
    details: PickListSummaryDetails;
    filters: FiltersType;
    assignee?: string[];
};

const PickListSummaryDetails: React.FC<PickListSummaryDetailsProps> = ({
    pickListId,
    pickListItems,
    details,
    filters,
    assignee = [],
}) => {
    const pickListItemsSummary: Record<string, number> = {};

    pickListItems.forEach((item) => {
        pickListItemsSummary[item.storageItem.storageItemType.name] =
            (pickListItemsSummary[item.storageItem.storageItemType.name] ?? 0) + 1;
    });

    return (
        <View style={pickListSummaryDetailsStyles.ViewDetails}>
            <Text>{filters.description ?? `Pick List ${pickListId}`}</Text>

            <View
                style={pickListSummaryDetailsStyles.ViewFiltersList}
                testID="data-pickListSummaryFiltersContainer"
            >
                <Text style={pickListSummaryDetailsStyles.TextHeader}>
                    <FormattedMessage
                        id="pickListSummaryDetails.filtersHeader"
                        defaultMessage="Filter"
                    />
                </Text>

                <View style={pickListSummaryDetailsStyles.ViewItem}>
                    <Text
                        style={pickListSummaryDetailsStyles.TextItemLabel}
                        testID="data-pickListSummaryDateRangeFilter"
                    >
                        <FormattedMessage
                            id="pickListSummaryDetails.dateRangeHeader"
                            defaultMessage="Visit date range"
                        />
                    </Text>
                    <Text
                        style={pickListSummaryDetailsStyles.TextItemValue}
                        testID="data-pickListSummaryDateRangeFilterValue"
                    >
                        {/* TODO */}
                        {filters.visitStartTime && filters.visitEndTime
                            ? `${filters.visitStartTime} to ${filters.visitEndTime}`
                            : 'Any'}
                    </Text>
                </View>

                <View style={pickListSummaryDetailsStyles.ViewItem}>
                    <Text
                        style={pickListSummaryDetailsStyles.TextItemLabel}
                        testID="data-pickListSummaryLocationRangeFilter"
                    >
                        <FormattedMessage
                            id="pickListSummaryDetails.locationRangeHeader"
                            defaultMessage="Location range"
                        />
                    </Text>
                    <Text
                        style={pickListSummaryDetailsStyles.TextItemValue}
                        testID="data-pickListSummaryLocationRangeFilterValue"
                    >
                        {/* TODO: This does not work when only one value is set. It also does not account for undefined or null values */}
                        <FormattedMessage
                            id="pickListSummary.locationRangeText"
                            defaultMessage="{locationRange}"
                            values={{
                                locationRange:
                                    filters.locations &&
                                    filters.locations[0].from !== '' &&
                                    filters.locations[0].to !== ''
                                        ? `${filters.locations[0].from} to ${filters.locations[0].to}`
                                        : 'All',
                            }}
                        />
                    </Text>
                </View>

                <View style={pickListSummaryDetailsStyles.ViewItem}>
                    <Text
                        style={pickListSummaryDetailsStyles.TextItemLabel}
                        testID="data-pickListSummaryItemTypesFilter"
                    >
                        <FormattedMessage
                            id="pickListSummaryDetails.storageItemTypesHeader"
                            defaultMessage="Storage item types"
                        />
                    </Text>
                    <Text
                        style={pickListSummaryDetailsStyles.TextItemValue}
                        testID="data-pickListSummaryItemTypesFilterValue"
                    >
                        <FormattedMessage
                            id="pickListSummary.storageItemTypesText"
                            defaultMessage="{storageItemTypes}"
                            values={{
                                storageItemTypes:
                                    filters.storageItemTypeIds &&
                                    filters.storageItemTypeIds.length > 0
                                        ? `${filters.storageItemTypeIds.join(', ')}`
                                        : 'All',
                            }}
                        />
                    </Text>
                </View>

                <View style={pickListSummaryDetailsStyles.ViewItem}>
                    <Text
                        style={pickListSummaryDetailsStyles.TextItemLabel}
                        testID="data-pickListSummaryMaxItemFilter"
                    >
                        <FormattedMessage
                            id="pickListSummaryDetails.maxQuantityHeader"
                            defaultMessage="Max. item quantity"
                        />
                    </Text>
                    <Text
                        style={pickListSummaryDetailsStyles.TextItemValue}
                        testID="data-pickListSummaryMaxItemFilterValue"
                    >
                        <FormattedMessage
                            id="pickListSummary.maxQuantityText"
                            defaultMessage="{maxQuantity}"
                            values={{
                                maxQuantity: filters.maxQuantity ? `${filters.maxQuantity}` : 'All',
                            }}
                        />
                    </Text>
                </View>

                <View style={pickListSummaryDetailsStyles.ViewItem}>
                    <Text
                        style={pickListSummaryDetailsStyles.TextItemLabel}
                        testID="data-pickListSummaryVisitRangeFilter"
                    >
                        <FormattedMessage
                            id="pickListSummaryDetails.visitsHeader"
                            defaultMessage="Visits"
                        />
                    </Text>
                    <Text
                        style={pickListSummaryDetailsStyles.TextItemValue}
                        testID="data-pickListSummaryVisitRangeFilterValue"
                    >
                        <FormattedMessage
                            id="pickListSummary.visitsText"
                            defaultMessage="{visits}"
                            values={{
                                visits:
                                    filters.visitIds && filters.visitIds.length > 0
                                        ? `${filters.visitIds.join(', ')}`
                                        : 'All',
                            }}
                        />
                    </Text>
                </View>
            </View>

            <View
                style={pickListSummaryDetailsStyles.ViewItemsList}
                testID="data-pickListSummaryItemSummaryContainer"
            >
                <Text style={pickListSummaryDetailsStyles.TextHeader}>
                    <FormattedMessage
                        id="pickListSummary.itemsListHeader"
                        defaultMessage="Items in this list"
                    />
                </Text>

                {Object.keys(pickListItemsSummary).map((item, index) => (
                    <View
                        style={pickListSummaryDetailsStyles.ViewItem}
                        key={`${index}${item}`}
                        testID="data-pickListSummaryItemSummaryListItem"
                    >
                        <Text
                            style={pickListSummaryDetailsStyles.TextItemLabel}
                            testID="data-pickListSummaryItemSummaryListItemName"
                        >
                            <FormattedMessage
                                id="pickListSummary.itemsListItemName"
                                defaultMessage="{item}"
                                values={{ item: item }}
                            />
                        </Text>
                        <Text
                            style={pickListSummaryDetailsStyles.TextItemValue}
                            testID="data-pickListSummaryItemSummaryListItemAmount"
                        >
                            <FormattedMessage
                                id="pickListSummary.itemsListItemAmount"
                                defaultMessage="{amount}"
                                values={{ amount: pickListItemsSummary[item] }}
                            />
                        </Text>
                    </View>
                ))}
            </View>

            <View
                style={pickListSummaryDetailsStyles.ViewItemsSummary}
                testID="data-pickListSummaryJobSummaryContainer"
            >
                <View
                    style={pickListSummaryDetailsStyles.ViewItem}
                    testID="data-pickListSummaryJobSummaryListToBePicked"
                >
                    <Text style={pickListSummaryDetailsStyles.TextItemLabel}>
                        <FormattedMessage
                            id="pickListSummary.itemsListToBePickedHeader"
                            defaultMessage="To be picked"
                        />
                    </Text>
                    <Text
                        style={pickListSummaryDetailsStyles.TextItemValue}
                        testID="data-pickListSummaryJobSummaryListToBePickedAmount"
                    >
                        <FormattedMessage
                            id="pickListSummary.itemsListToBePickedAmount"
                            defaultMessage="{amount}"
                            values={{ amount: details.toBePicked }}
                        />
                    </Text>
                </View>

                <View
                    style={pickListSummaryDetailsStyles.ViewItem}
                    testID="data-pickListSummaryJobSummaryListPicked"
                >
                    <Text style={pickListSummaryDetailsStyles.TextItemLabel}>
                        <FormattedMessage
                            id="pickListSummary.itemsListPickedHeader"
                            defaultMessage="Picked"
                        />
                    </Text>
                    <Text
                        style={pickListSummaryDetailsStyles.TextItemValue}
                        testID="data-pickListSummaryJobSummaryListPickedAmount"
                    >
                        <FormattedMessage
                            id="pickListSummary.itemsListPickedAmount"
                            defaultMessage="{amount}"
                            values={{ amount: details.picked }}
                        />
                    </Text>
                </View>

                <View
                    style={pickListSummaryDetailsStyles.ViewItem}
                    testID="data-pickListSummaryJobSummaryListExcluded"
                >
                    <Text style={pickListSummaryDetailsStyles.TextItemLabel}>
                        <FormattedMessage
                            id="pickListSummary.itemsListExcludedHeader"
                            defaultMessage="Excluded"
                        />
                    </Text>
                    <Text
                        style={pickListSummaryDetailsStyles.TextItemValue}
                        testID="data-pickListSummaryJobSummaryListExcludedAmount"
                    >
                        <FormattedMessage
                            id="pickListSummary.itemsListExcludedAmount"
                            defaultMessage="{amount}"
                            values={{ amount: details.excluded }}
                        />
                    </Text>
                </View>
            </View>

            <View style={pickListSummaryDetailsStyles.ViewAssignee}>
                <View
                    style={pickListSummaryDetailsStyles.ViewItem}
                    testID="data-pickListSummaryJobSummaryListAssignees"
                >
                    <Text style={pickListSummaryDetailsStyles.TextItemLabel}>
                        <FormattedMessage
                            id="pickListSummary.itemsListAssigneeHeader"
                            defaultMessage="Assignee(s)"
                        />
                    </Text>

                    <View
                        style={{ flex: 1, display: 'flex', flexDirection: 'column' }}
                        testID="data-pickListSummaryJobSummaryListAssigneesNames"
                    >
                        {assignee.length > 0 ? (
                            assignee.map((assignee: string, index: number) => (
                                <Text style={{ fontWeight: '700' }} key={`${index}${assignee}`}>
                                    {assignee}
                                </Text>
                            ))
                        ) : (
                            <Text>
                                <FormattedMessage
                                    id="pickListSummary.itemsListNoAssigneeText"
                                    defaultMessage="None"
                                />
                            </Text>
                        )}
                    </View>
                </View>
            </View>
        </View>
    );
};

const pickListSummaryDetailsStyles = StyleSheet.create({
    ViewDetails: {
        flex: 1,
        overflowY: 'scroll',
        overflowX: 'none',
        marginVertical: 5,
        display: 'flex',
        flexDirection: 'column',
    },
    ViewFiltersList: {
        paddingVertical: 5,
        borderBottomWidth: 1,
        borderBottomColor: '#D4D4D5',
    },
    ViewItemsList: {
        marginVertical: 5,
    },
    ViewItem: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
    },
    ViewItemsSummary: {
        marginVertical: 5,
    },
    ViewAssignee: {
        marginVertical: 5,
    },
    TextHeader: {
        fontWeight: '700',
    },
    TextItemLabel: {
        flex: 1,
    },
    TextItemValue: {
        flex: 1,
        fontWeight: '700',
    },
});
