import { gql } from '@apollo/client';
import React, { useCallback, useState, useEffect } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { FlatList, ScrollView, StyleSheet, Text, View } from 'react-native';
import { Button, Datepicker, Select, SelectItem } from '@valet/ui-components';

import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import {
    AdminScheduleTemplateAreaFragment,
    AdminScheduleTemplateDetailFragment,
    useAdminAddScheduleTemplatePeriodMutation,
    useAdminDeleteScheduleTemplatePeriodMutation,
    useAdminUpdateScheduleTemplateMutation,
    useAdminUpdateScheduleTemplatePeriodMutation,
    useGetAdminScheduleTemplateAreasQuery,
    useGetAdminScheduleTemplateByIdQuery,
} from '../../../../schema-types';
import { ScheduleTemplate, ScheduleTemplateWindowType, ScheduleWeek } from './ScheduleWeek';

const AREA_FRAGMENT = gql`
    fragment adminScheduleTemplateArea on Area {
        id
        code
    }
`;

export const RETRIEVE_AREA_QUERY = gql`
    query getAdminScheduleTemplateAreas {
        areas {
            ...adminScheduleTemplateArea
        }
    }
    ${AREA_FRAGMENT}
`;

const SCHEDULE_TEMPLATE_PERIOD_FRAGMENT = gql`
    fragment adminScheduleTemplatePeriodDetail on ScheduleTemplateWindow {
        id
        startTime
        endTime
        week
        day
    }
`;

const SCHEDULE_TEMPLATE_FRAGMENT = gql`
    fragment adminScheduleTemplateDetail on ScheduleTemplate {
        id
        startDate
        weeks {
            monday {
                id
                ...adminScheduleTemplatePeriodDetail
            }
            tuesday {
                id
                ...adminScheduleTemplatePeriodDetail
            }
            wednesday {
                id
                ...adminScheduleTemplatePeriodDetail
            }
            thursday {
                id
                ...adminScheduleTemplatePeriodDetail
            }
            friday {
                id
                ...adminScheduleTemplatePeriodDetail
            }
            saturday {
                id
                ...adminScheduleTemplatePeriodDetail
            }
            sunday {
                id
                ...adminScheduleTemplatePeriodDetail
            }
        }
        area {
            id
            ...adminScheduleTemplateArea
        }
    }
    ${AREA_FRAGMENT}
    ${SCHEDULE_TEMPLATE_PERIOD_FRAGMENT}
`;

export const RETRIEVE_SCHEDULE_TEMPLATE_QUERY = gql`
    query getAdminScheduleTemplateById($scheduleTemplateId: String!) {
        scheduleTemplateById(id: $scheduleTemplateId) {
            ...adminScheduleTemplateDetail
        }
    }
    ${SCHEDULE_TEMPLATE_FRAGMENT}
`;

export const ADMIN_ADD_SCHEDULE_TEMPLATE_PERIOD_MUTATION = gql`
    mutation adminAddScheduleTemplatePeriod($data: AdminAddScheduleTemplatePeriodInput!) {
        adminAddScheduleTemplatePeriod(data: $data) {
            data {
                id
                ...adminScheduleTemplatePeriodDetail
            }
        }
    }
    ${SCHEDULE_TEMPLATE_PERIOD_FRAGMENT}
`;

export const ADMIN_UPDATE_SCHEDULE_TEMPLATE_PERIOD_MUTATION = gql`
    mutation adminUpdateScheduleTemplatePeriod($data: AdminUpdateScheduleTemplatePeriodInput!) {
        adminUpdateScheduleTemplatePeriod(data: $data) {
            data {
                id
                ...adminScheduleTemplatePeriodDetail
            }
        }
    }
    ${SCHEDULE_TEMPLATE_PERIOD_FRAGMENT}
`;

export const ADMIN_UPDATE_SCHEDULE_TEMPLATE_MUTATION = gql`
    mutation adminUpdateScheduleTemplate($data: AdminUpdateScheduleTemplateInput!) {
        adminUpdateScheduleTemplate(data: $data) {
            data {
                id
                ...adminScheduleTemplateDetail
            }
        }
    }
    ${SCHEDULE_TEMPLATE_PERIOD_FRAGMENT}
`;

export const ADMIN_DELETE_SCHEDULE_TEMPLATE_PERIOD_MUTATION = gql`
    mutation adminDeleteScheduleTemplatePeriod($data: AdminDeleteScheduleTemplatePeriodInput!) {
        adminDeleteScheduleTemplatePeriod(data: $data) {
            data {
                id
                ...adminScheduleTemplatePeriodDetail
            }
        }
    }
    ${SCHEDULE_TEMPLATE_PERIOD_FRAGMENT}
`;

type ScheduleDetailPageProps = {};

export const ScheduleDetailPage: React.FC<ScheduleDetailPageProps> = () => {
    const history = useHistory();
    const intl = useIntl();
    const [parentMode, setParentMode] = useState('View');

    const { data: retrievedAreaData } = useGetAdminScheduleTemplateAreasQuery();
    const [selectIndex] = useState<number>(0);

    const areasData = retrievedAreaData?.areas
        ? (retrievedAreaData?.areas as AdminScheduleTemplateAreaFragment[])
        : [];

    const { scheduleTemplateId } = useParams<{ scheduleTemplateId?: string }>();
    const [scheduleTemplate, setScheduleTemplate] = useState<AdminScheduleTemplateDetailFragment>({
        id: '',
        startDate: new Date(),
        weeks: [],
        area: {
            id: '',
            code: '',
        },
    });

    const {
        data: retrievedScheduleTemplateDetail,
        loading: retrievedScheduleTemplateDetailLoading,
        error: retrievedScheduleTemplateDetailError,
    } = useGetAdminScheduleTemplateByIdQuery({
        variables: {
            scheduleTemplateId: scheduleTemplateId ?? '',
        },
    });

    useEffect(() => {
        setScheduleTemplate(
            retrievedScheduleTemplateDetail?.scheduleTemplateById
                ? {
                      id: retrievedScheduleTemplateDetail.scheduleTemplateById.id,
                      startDate: new Date(
                          retrievedScheduleTemplateDetail.scheduleTemplateById.startDate,
                      ),
                      weeks: [...retrievedScheduleTemplateDetail.scheduleTemplateById.weeks],
                      area: retrievedScheduleTemplateDetail.scheduleTemplateById.area,
                  }
                : {
                      id: '',
                      startDate: new Date(),
                      weeks: [],
                      area: {
                          id: '',
                          code: '',
                      },
                  },
        );
    }, [retrievedScheduleTemplateDetail]);

    const selectItems: { id: string; title: string }[] = [];
    areasData.map((item) =>
        selectItems.push({
            id: item.id,
            title: item.code,
        }),
    );

    const handleBacklClick = useCallback(async () => {
        history.push(`/admin/schedules`);
    }, [history]);

    const handleAddNewWeek = useCallback(async () => {
        const allweeks = [...scheduleTemplate?.weeks];
        const newWeek = {
            monday: [],
            tuesday: [],
            wednesday: [],
            thursday: [],
            friday: [],
            saturday: [],
            sunday: [],
        };

        if (scheduleTemplate?.weeks) {
            allweeks.push(newWeek);
        }
        setScheduleTemplate({ ...scheduleTemplate, weeks: allweeks });
    }, [scheduleTemplate]);

    const [
        adminAddScheduleTemplatePeriodMutation,
        {
            loading: adminAddScheduleTemplatePeriodMutationLoading,
            error: adminAddScheduleTemplatePeriodMutationError,
        },
    ] = useAdminAddScheduleTemplatePeriodMutation();

    const [
        adminUpdateScheduleTemplatePeriodMutation,
        {
            loading: adminUpdateScheduleTemplatePeriodMutationLoading,
            error: adminUpdateScheduleTemplatePeriodMutationError,
        },
    ] = useAdminUpdateScheduleTemplatePeriodMutation();

    const [
        adminUpdateScheduleTemplateMutation,
        {
            loading: adminUpdateScheduleTemplateMutationLoading,
            error: adminUpdateScheduleTemplateMutationError,
        },
    ] = useAdminUpdateScheduleTemplateMutation();

    const [
        adminDeleteScheduleTemplatePeriodMutation,
        {
            loading: adminDeleteScheduleTemplatePeriodMutationLoading,
            error: adminDeleteScheduleTemplatePeriodMutationError,
        },
    ] = useAdminDeleteScheduleTemplatePeriodMutation();

    const handleSchedulePeriodAdded = (inputData: ScheduleTemplateWindowType): void => {
        try {
            adminAddScheduleTemplatePeriodMutation({
                variables: {
                    data: {
                        scheduleTemplateId: scheduleTemplateId || '',
                        week: inputData.week,
                        day: inputData.day,
                        startTime: inputData.startTime,
                        endTime: inputData.endTime,
                    },
                },
            });
        } catch (ignore) {} //eslint-disable-line no-empty
    };

    const handleSchedulePeriodDeleted = (id: string): void => {
        try {
            adminDeleteScheduleTemplatePeriodMutation({
                variables: { data: { id } },
            });
        } catch (ignore) {} //eslint-disable-line no-empty
    };

    const handleSchedulePeriodUpdated = (inputData: ScheduleTemplateWindowType): void => {
        try {
            adminUpdateScheduleTemplatePeriodMutation({
                variables: { data: { ...inputData } },
            });
        } catch (ignore) {} //eslint-disable-line no-empty
    };

    const handleScheduleTemplateUpdated = (inputData: ScheduleTemplate): void => {
        try {
            adminUpdateScheduleTemplateMutation({
                variables: { data: { ...inputData } },
            });
            setParentMode('View');
        } catch (ignore) {} //eslint-disable-line no-empty
    };

    const handleScheduleTemplateCancelClick = useCallback(async () => {
        setScheduleTemplate({ ...scheduleTemplate });
        setParentMode('View');
    }, [scheduleTemplate]);

    const handleScheduleTemplateEditClick = useCallback(async () => {
        setParentMode('Edit');
    }, []);

    return (
        <ScrollView>
            <View
                style={scheduleTemplateDetailPageStyles.ViewParent}
                testID="data-scheduleTemplateDetail"
            >
                <View style={scheduleTemplateDetailPageStyles.ViewChild}>
                    <View style={scheduleTemplateDetailPageStyles.ViewContentParent}>
                        <View style={scheduleTemplateDetailPageStyles.ViewTabs}>
                            <View>
                                <View style={{ flex: 1, marginLeft: 2 }}>
                                    <Button
                                        title={intl.formatMessage({
                                            id: 'scheduleTemplateDetail.Back',
                                            defaultMessage: 'Back',
                                        })}
                                        onPress={handleBacklClick}
                                    />
                                </View>
                            </View>
                        </View>
                        {(adminAddScheduleTemplatePeriodMutationError ||
                            adminUpdateScheduleTemplatePeriodMutationError ||
                            adminUpdateScheduleTemplateMutationError ||
                            retrievedScheduleTemplateDetailError ||
                            adminDeleteScheduleTemplatePeriodMutationError) && (
                            <Text testID="data-scheduleDetailError">
                                <FormattedMessage
                                    id="scheduleDetailPage.scheduleDetailError"
                                    defaultMessage="There was an error on the page - please reload this page."
                                />
                            </Text>
                        )}

                        <View style={scheduleTemplateDetailPageStyles.ViewTitleParent}>
                            <Text
                                style={scheduleTemplateDetailPageStyles.TextTitle}
                                testID="data-scheduleTemplate"
                            >
                                {`${intl.formatMessage({
                                    id: 'scheduleTemplateDetail.id',
                                    defaultMessage: 'Schedule Template Details:',
                                })}${''}`}
                            </Text>
                        </View>

                        <View style={scheduleTemplateDetailPageStyles.ViewTitleParent}>
                            <View style={scheduleTemplateDetailPageStyles.ViewDescription}>
                                <FormattedMessage
                                    id="scheduleTemplateDetail.startDate"
                                    defaultMessage="StartDate"
                                />
                                {parentMode === 'View' ? (
                                    <Text>
                                        <FormattedDate value={scheduleTemplate?.startDate} />
                                    </Text>
                                ) : (
                                    <Datepicker
                                        date={scheduleTemplate?.startDate}
                                        onSelect={(date) =>
                                            setScheduleTemplate({
                                                ...scheduleTemplate,
                                                startDate: date,
                                            })
                                        }
                                        testID="scheduleTemplateDetail-startDate"
                                    />
                                )}
                            </View>

                            <View style={scheduleTemplateDetailPageStyles.ViewDescription}>
                                <FormattedMessage
                                    id="scheduleTemplateDetail.Area"
                                    defaultMessage="Area"
                                />
                                <Select
                                    currentIndex={selectIndex}
                                    itemsDisplayValues={selectItems.map((items) => items.title)}
                                    disabled={parentMode !== 'View' ? false : true}
                                    onSelect={(index) =>
                                        setScheduleTemplate({
                                            ...scheduleTemplate,
                                            area: {
                                                id: selectItems[index].id,
                                                code: selectItems[index].title,
                                            },
                                        })
                                    }
                                >
                                    {selectItems.map((item) => (
                                        <SelectItem title={item.title} key={item.id} />
                                    ))}
                                </Select>
                            </View>
                            {parentMode === 'View' ? (
                                <>
                                    <View style={scheduleTemplateDetailPageStyles.ViewTabs}>
                                        <View style={scheduleTemplateDetailPageStyles.ViewTabs}>
                                            <View>
                                                <View style={{ flex: 1, marginLeft: 2 }}>
                                                    <Button
                                                        title={intl.formatMessage({
                                                            id: 'scheduleTemplateDetail.Edit',
                                                            defaultMessage: 'Edit',
                                                        })}
                                                        onPress={handleScheduleTemplateEditClick}
                                                    />
                                                </View>
                                            </View>
                                        </View>

                                        <View style={scheduleTemplateDetailPageStyles.ViewTabs}>
                                            <View>
                                                <View style={{ flex: 1, marginLeft: 2 }}>
                                                    <Button
                                                        title={intl.formatMessage({
                                                            id: 'scheduleTemplateDetail.AddNewWeek',
                                                            defaultMessage: '+ Add New Week',
                                                        })}
                                                        onPress={handleAddNewWeek}
                                                    />
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                </>
                            ) : (
                                <>
                                    <View style={scheduleTemplateDetailPageStyles.ViewTabs}>
                                        <View style={scheduleTemplateDetailPageStyles.ViewTabs}>
                                            <View style={{ flex: 1, marginLeft: 2 }}>
                                                <Button
                                                    title={intl.formatMessage({
                                                        id: 'scheduleTemplateDetail.Cancel',
                                                        defaultMessage: 'Cancel',
                                                    })}
                                                    onPress={handleScheduleTemplateCancelClick}
                                                />
                                            </View>
                                        </View>
                                        <View style={scheduleTemplateDetailPageStyles.ViewTabs}>
                                            <View style={{ flex: 1, marginLeft: 2 }}>
                                                <Button
                                                    title={intl.formatMessage({
                                                        id: 'scheduleTemplateDetail.Save',
                                                        defaultMessage: 'Save',
                                                    })}
                                                    onPress={() =>
                                                        handleScheduleTemplateUpdated({
                                                            areaId: scheduleTemplate.area.id,
                                                            startDate: scheduleTemplate.startDate,
                                                            id: scheduleTemplate.id,
                                                        })
                                                    }
                                                />
                                            </View>
                                        </View>
                                    </View>
                                </>
                            )}
                        </View>
                    </View>
                    {!retrievedScheduleTemplateDetailLoading && scheduleTemplate ? (
                        <View style={scheduleTemplateDetailPageStyles.ViewContent}>
                            {!adminUpdateScheduleTemplatePeriodMutationLoading ||
                            !adminUpdateScheduleTemplateMutationLoading ||
                            !adminDeleteScheduleTemplatePeriodMutationLoading ||
                            !adminAddScheduleTemplatePeriodMutationLoading ? (
                                <FlatList
                                    data={scheduleTemplate.weeks}
                                    renderItem={({ item, index }) => (
                                        <ScheduleWeek
                                            key={`week_${index + 1}`}
                                            weekData={item}
                                            weekNumber={index + 1}
                                            onSchedulePeriodAdded={handleSchedulePeriodAdded}
                                            onSchedulePeriodDeleted={handleSchedulePeriodDeleted}
                                            onSchedulePeriodUpdated={handleSchedulePeriodUpdated}
                                            parentMode={parentMode}
                                        />
                                    )}
                                />
                            ) : (
                                <View testID="data-adminAddScheduleTemplatePeriodLoading">
                                    <Text>
                                        <FormattedMessage
                                            id="scheduleTemplateDetailPage.loading"
                                            defaultMessage="Loading..."
                                        />
                                    </Text>
                                </View>
                            )}
                        </View>
                    ) : null}
                </View>
            </View>
        </ScrollView>
    );
};

const scheduleTemplateDetailPageStyles = StyleSheet.create({
    ViewParent: {
        display: 'flex',
        flexDirection: 'column',
    },
    ViewTabs: {
        // marginVertical: 5,
        // display: 'flex',
        flexDirection: 'row',
    },
    ViewContent: {
        paddingVertical: 5,
    },
    TextTab: {
        flex: 1,
        color: '#000',
        backgroundColor: '#D4D4D5',
        fontSize: 18,
        textAlign: 'center',
        fontWeight: '700',
    },

    ViewChild: {
        display: 'flex',
        flexDirection: 'column',
    },
    ViewImageParent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    RouteImage: {
        height: 40,
        width: 40,
        resizeMode: 'stretch',
    },
    ViewContentParent: {
        paddingHorizontal: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    ViewTitleParent: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 10,
    },
    ViewTitleChild: {},
    TextTitle: {
        fontSize: 18,
        fontWeight: 'bold',
    },
    ViewDescription: {
        marginTop: 10,
        marginBottom: 10,
        fontWeight: 'bold',
    },
    TextDescription: { fontSize: 14 },
    ViewStatus: {},
    TextStatus: {
        fontSize: 12,
        fontWeight: '700',
    },
    ViewDate: {},
    TextDate: {
        fontSize: 12,
    },
    ViewErrorMessageParent: {
        marginTop: 5,
        marginBottom: 5,
    },
    TextContainerItem: {
        marginVertical: 2,
        display: 'flex',
        flexDirection: 'row',
        fontWeight: 'bold',
    },
});
