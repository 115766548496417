import { gql } from '@apollo/client';
import React, { useMemo, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Card, Message } from '@valet/ui-components';

import { FlatList, ScrollView, StyleSheet, Text, View } from 'react-native';
import {
    useGetAdminStorageContainerTypesQuery,
    AdminStorageContainerTypeFragment,
} from '../../../../schema-types';
import { useHistory } from 'react-router-dom';

export const storageContainerTypePageSize = 100;

const CONTAINERTYPE_FRAGMENT = gql`
    fragment adminStorageContainerType on StorageContainerType {
        id
        description
    }
`;

export const RETRIEVE_CONTAINERTYPE_QUERY = gql`
    query getAdminStorageContainerTypes {
        storageContainerType {
            ...adminStorageContainerType
        }
    }
    ${CONTAINERTYPE_FRAGMENT}
`;


type StorageContainerTypePageProps = {
    onContainerTypeSelect: (id: string) => void;
    onAddContainerTypeSelect: () => void
};

export const StorageContainerTypePage: React.FC<StorageContainerTypePageProps> = ({ onContainerTypeSelect: onStorageContainerTypeSelect, onAddContainerTypeSelect: onAddStorageContainerTypeSelect }) => {
    const history = useHistory();
    const intl = useIntl();


    const {
        data: retrievedStorageContainerTypesData,
        loading: retrievedStorageContainerTypesLoading,
        error: retrievedStorageContainerTypesError,
    } = useGetAdminStorageContainerTypesQuery();

    const storageContainerTypeData = useMemo(() => {
        return {
            storageContainerType: retrievedStorageContainerTypesData?.storageContainerType
        };
    }, [retrievedStorageContainerTypesData]);

    const handleItemClick = useCallback(
        (id: string) => {
            onStorageContainerTypeSelect(id);
        },
        [onStorageContainerTypeSelect],
    );

    const handleAddClick = useCallback(
        () => {
            onAddStorageContainerTypeSelect();
        },
        [onAddStorageContainerTypeSelect],
    );

    const handleBacklClick = useCallback(async () => {
        history.push(`/admin`);
    }, [history]);

    return (
        <ScrollView>
            <View style={storageContainerTypePageStyles.ViewParent} testID="data-storageContainerType">
                <View style={storageContainerTypePageStyles.ViewTabs}>
                    <View>
                        <View style={{ flex: 1, marginRight: 2 }}>
                            <Button
                                title={intl.formatMessage({
                                    id: 'storageContainerType.backButton',
                                    defaultMessage: 'Back',
                                })}
                                onPress={handleBacklClick}
                            />
                        </View>
                    </View>
                    <View>
                        <View style={{ flex: 1, marginRight: 20, marginBottom: 10 }}>
                            <Button
                                title={intl.formatMessage({
                                    id: 'storageContainerType.addNewStorageContainerType',
                                    defaultMessage: '+ Add New Container Type',
                                })}
                                onPress={handleAddClick}
                            />
                        </View>
                    </View>
                </View>
                {retrievedStorageContainerTypesData && (
                    <View style={storageContainerTypePageStyles.ViewContent}>
                        {storageContainerTypeData.storageContainerType ?
                            (
                                <FlatList
                                    data={storageContainerTypeData.storageContainerType}
                                    renderItem={({ item }) => (
                                        <StorageContainerTypeItem
                                            key={item.id}
                                            storageContainerType={item}
                                            onItemClick={handleItemClick}
                                        />
                                    )}
                                    keyExtractor={(item) => item.id}
                                />
                            ) :
                            retrievedStorageContainerTypesLoading ?
                                (
                                    <Text>
                                        <FormattedMessage
                                            id="storageContainerType.loading"
                                            defaultMessage="Loading..."
                                        />
                                    </Text>
                                ) : retrievedStorageContainerTypesError ?
                                    (
                                        <View
                                            style={storageContainerTypePageStyles.ViewErrorMessageParent}
                                            testID="data-errorMessage"
                                        >
                                            <Message
                                                type="negative"
                                                header={intl.formatMessage({
                                                    id: 'storageContainerType.storageContainerTypeErrorMessage',
                                                    defaultMessage: 'Could not load detail for the storageContainerType - please try again',
                                                })}
                                                content={retrievedStorageContainerTypesError.message}
                                                testID="data-storageContainerTypeErrorMessage"
                                            />
                                        </View>
                                    ) : null
                        }
                    </View>
                )}

            </View>
        </ScrollView>
    );
};


const storageContainerTypePageStyles = StyleSheet.create({
    ViewParent: {
        display: 'flex',
        flexDirection: 'column',
    },
    ViewTabs: {
        marginVertical: 5,
        display: 'flex',
        flexDirection: 'row',
    },
    ViewContent: {
        paddingVertical: 5,
    },
    TextTab: {
        flex: 1,
        color: '#000',
        backgroundColor: '#D4D4D5',
        fontSize: 18,
        textAlign: 'center',
        fontWeight: '700',
    },
    ViewErrorMessageParent: {
        marginTop: 5,
        marginBottom: 5,
    },
});

type StorageContainerTypeItemProps = {
    storageContainerType: AdminStorageContainerTypeFragment;
    onItemClick: (id: string) => void;
};

const StorageContainerTypeItem: React.FC<StorageContainerTypeItemProps> = ({ storageContainerType, onItemClick }) => {
    const intl = useIntl();

    return (
        <Card
            style={storageContainerTypeItemStyles.ViewParent}
            onPress={() => onItemClick(storageContainerType?.id || '')}
            testID="data-storageContainerType"
        >
            <View style={storageContainerTypeItemStyles.ViewChild}>
                <View style={storageContainerTypeItemStyles.ViewContentParent}>
                    <View style={storageContainerTypeItemStyles.ViewTitleParent}>
                        <Text style={storageContainerTypeItemStyles.TextTitle} testID="data-storageContainerTypeDescription">
                            {`${intl.formatMessage({
                                id: 'storageContainerType.description',
                                defaultMessage: 'Description: ',
                            })}`}
                        </Text>
                        <Text>{storageContainerType?.description}</Text>
                    </View>
                </View>
            </View>
        </Card>
    );
};

const storageContainerTypeItemStyles = StyleSheet.create({
    ViewParent: {
        marginVertical: 3,
    },
    ViewChild: {
        display: 'flex',
        flexDirection: 'row',
    },
    ViewImageParent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    StorageContainerTypeImage: {
        height: 40,
        width: 40,
        resizeMode: 'stretch',
    },
    ViewContentParent: {
        paddingHorizontal: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    ViewTitleParent: {
        display: 'flex',
        flexDirection: 'column',
    },
    ViewTitleChild: {},
    TextTitle: {
        fontSize: 18,
        fontWeight: 'bold',
    },
    ViewDescription: {},
    TextDescription: { fontSize: 14 },
    ViewStatus: {},
    TextStatus: {
        fontSize: 12,
        fontWeight: '700',
    },
    ViewDate: {},
    TextDate: {
        fontSize: 12,
    },
    ViewErrorMessageParent: {
        marginTop: 5,
        marginBottom: 5,
    },
});

