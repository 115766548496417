import { gql } from '@apollo/client';
import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Card, Message } from '@valet/ui-components';

import { FlatList, ScrollView, StyleSheet, Text, View } from 'react-native';
import { AdminBuildingFragment, useGetAdminBuildingsQuery } from '../../../../schema-types';
import { useHistory } from 'react-router-dom';

export const buildingPageSize = 100;

const ADMIN_BUILDING_FRAGMENT = gql`
    fragment adminBuilding on Building {
        id
        code
        description
        area {
            id
            code
        }
        address {
            id
            address1
            address2
            city
            zoneId
            countryId
            zip
            longitude
            latitude
            phone
            firstName
            lastName
        }
    }
`;

export const RETRIEVE_ROUTES_QUERY = gql`
    query getAdminBuildings($pageSize: Int!, $cursor: String!) {
        buildings(filter: { pageSize: $pageSize, after: $cursor }) {
            edges {
                cursor
                node {
                    id
                    ...adminBuilding
                }
            }
            pageInfo {
                cursor {
                    beforeCursor
                    afterCursor
                }
                hasNextPage
                hasPreviousPage
            }
        }
    }

    ${ADMIN_BUILDING_FRAGMENT}
`;

type BuildingPageProps = {
    onBuildingSelect: (id: string) => void;
    onAddBuildingSelect: () => void;
};

export const BuildingPage: React.FC<BuildingPageProps> = ({
    onBuildingSelect,
    onAddBuildingSelect,
}) => {
    const intl = useIntl();
    const history = useHistory();

    const [isFetching, setIsFetching] = useState<boolean>(false);

    const {
        data: retrievedBuildingsData,
        loading: retrievedBuildingsLoading,
        error: retrievedBuildingsError,
        fetchMore: retrievedBuildingFetchMore,
    } = useGetAdminBuildingsQuery({
        fetchPolicy: 'network-only',
        variables: {
            pageSize: buildingPageSize,
            cursor: '',
        },
    });

    const buildingEdges = retrievedBuildingsData?.buildings.edges ?? [];
    const buildingData = useMemo(() => {
        return {
            building: buildingEdges?.map((itemEdge) => itemEdge.node) ?? [],
            hasNextPage: retrievedBuildingsData?.buildings?.pageInfo?.hasNextPage ?? false,
            endCursor: retrievedBuildingsData?.buildings?.pageInfo?.cursor.afterCursor ?? '',
        };
    }, [buildingEdges, retrievedBuildingsData]);

    const handleLoadMoreItems = useCallback((): void => {
        if (buildingData.hasNextPage && !isFetching) {
            setIsFetching(true);

            retrievedBuildingFetchMore({
                variables: {
                    pageSize: buildingPageSize,
                    cursor: buildingData.endCursor,
                },
            }).then(() => {
                setIsFetching(false);
            });
        }
    }, [isFetching, buildingData, retrievedBuildingFetchMore]);

    useEffect(() => {
        if (!retrievedBuildingsError && buildingData.hasNextPage) {
            handleLoadMoreItems();
        }
    }, [handleLoadMoreItems, buildingData, retrievedBuildingsError]);

    const handleItemClick = useCallback(
        (id: string) => {
            onBuildingSelect(id);
        },
        [onBuildingSelect],
    );

    const handleAddClick = useCallback(() => {
        onAddBuildingSelect();
    }, [onAddBuildingSelect]);

    const handleBacklClick = useCallback(async () => {
        history.push(`/admin`);
    }, [history]);

    return (
        <ScrollView>
            <View style={buildingsPageStyles.ViewParent} testID="data-delivery">
                <View style={buildingsPageStyles.ViewTabs}>
                    <View>
                        <View style={{ flex: 1, marginRight: 2 }}>
                            <Button
                                title={intl.formatMessage({
                                    id: 'buildings.backButton',
                                    defaultMessage: 'Back',
                                })}
                                onPress={handleBacklClick}
                            />
                        </View>
                    </View>
                    <View>
                        <View style={{ flex: 1, marginRight: 10, marginBottom: 10 }}>
                            <Button
                                title={intl.formatMessage({
                                    id: 'buildings.addNewBuilding',
                                    defaultMessage: '+ Add New Building',
                                })}
                                onPress={handleAddClick}
                            />
                        </View>
                    </View>
                </View>
                {retrievedBuildingsLoading &&
                    (
                        <View testID="data-buildingsItemLoading">
                            <Text>
                                <FormattedMessage id="buildings.loading" defaultMessage="Loading..." />
                            </Text>
                        </View>
                    )
                }
                {retrievedBuildingsError &&
                    (
                        <View style={buildingsPageStyles.ViewErrorMessageParent} testID="data-errorMessage">
                            <Message
                                type="negative"
                                header={intl.formatMessage({
                                    id: 'buildings.buildingsErrorMessage',
                                    defaultMessage: 'Could not load detail for the area - please try again',
                                })}
                                content={retrievedBuildingsError.message}
                                testID="data-buildingsErrorMessage"
                            />
                        </View>
                    )
                }
                {!retrievedBuildingsLoading &&
                    !retrievedBuildingsData?.buildings.pageInfo.hasNextPage &&
                    retrievedBuildingsData && (
                        <View style={buildingsPageStyles.ViewContent}>
                            <FlatList
                                data={buildingData.building}
                                renderItem={({ item }) => (
                                    <BuildingItem
                                        key={item.id}
                                        building={item}
                                        onItemClick={handleItemClick}
                                    />
                                )}
                                keyExtractor={(item) => item.id}
                            />
                        </View>
                    )}
            </View>
        </ScrollView>
    );
};

const buildingsPageStyles = StyleSheet.create({
    ViewParent: {
        display: 'flex',
        flexDirection: 'column',
    },
    ViewTabs: {
        marginVertical: 5,
        display: 'flex',
        flexDirection: 'row',
    },
    ViewContent: {
        paddingVertical: 5,
    },
    TextTab: {
        flex: 1,
        color: '#000',
        backgroundColor: '#D4D4D5',
        fontSize: 18,
        textAlign: 'center',
        fontWeight: '700',
    },
    ViewErrorMessageParent: {
        marginTop: 5,
        marginBottom: 5,
    },
});

type BuildingItemProps = {
    building: AdminBuildingFragment;
    onItemClick: (id: string) => void;
};

const BuildingItem: React.FC<BuildingItemProps> = ({ building, onItemClick }) => {
    const intl = useIntl();

    return (
        <Card
            style={buildingItemStyles.ViewParent}
            onPress={() => onItemClick(building?.id || '')}
            testID="data-deliveryDetail"
        >
            <View style={buildingItemStyles.ViewChild}>
                <View style={buildingItemStyles.ViewContentParent}>
                    <View style={buildingItemStyles.ViewTitleParent}>
                        <Text
                            style={buildingItemStyles.TextTitle}
                            testID="data-buildingItemDescription"
                        >
                            {`${intl.formatMessage({
                                id: 'buildingItem.code',
                                defaultMessage: 'Code: ',
                            })}`}
                        </Text>
                        <Text>{building?.code}</Text>
                    </View>
                    <View style={buildingItemStyles.ViewTitleParent}>
                        <Text
                            style={buildingItemStyles.TextTitle}
                            testID="data-buildingItemDescription"
                        >
                            {`${intl.formatMessage({
                                id: 'buildingItem.description',
                                defaultMessage: 'Description: ',
                            })}`}
                        </Text>
                        <Text>{building?.description}</Text>
                    </View>
                </View>
            </View>
        </Card>
    );
};

const buildingItemStyles = StyleSheet.create({
    ViewParent: {
        marginVertical: 3,
    },
    ViewChild: {
        display: 'flex',
        flexDirection: 'row',
    },
    ViewImageParent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    BuildingImage: {
        height: 40,
        width: 40,
        resizeMode: 'stretch',
    },
    ViewContentParent: {
        paddingHorizontal: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    ViewTitleParent: {
        display: 'flex',
        flexDirection: 'column',
    },
    ViewTitleChild: {},
    TextTitle: {
        fontSize: 18,
        fontWeight: 'bold',
    },
    ViewDescription: {},
    TextDescription: { fontSize: 14 },
    ViewStatus: {},
    TextStatus: {
        fontSize: 12,
        fontWeight: '700',
    },
    ViewDate: {},
    TextDate: {
        fontSize: 12,
    },
    ViewErrorMessageParent: {
        marginTop: 5,
        marginBottom: 5,
    },
});
