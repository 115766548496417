import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    Upload: any;
    DateTime: any;
};

export type Mutation = {
    __typename?: 'Mutation';
    _empty?: Maybe<Scalars['String']>;
    addCustomerAddress: AddCustomerAddressPayload;
    addCustomerPaymentMethod: AddCustomerPaymentMethodPayload;
    adminAddBuilding: AdminAddBuildingPayload;
    adminAddEmployee: AdminAddEmployeePayload;
    adminAddLocation: AdminAddLocationsPayload;
    adminAddPeriodToTerritory: AdminAddPeriodToTerritoryPayload;
    adminAddScheduleTemplatePeriod: AdminAddScheduleTemplatePeriodPayload;
    adminAddStorageContainer: AdminAddStorageContainerPayload;
    adminAddStorageContainerType: AdminAddStorageContainerTypePayload;
    adminAddStorageItemType: AdminAddStorageItemTypePayload;
    adminAddStorageItemTypeCategory: AdminAddStorageItemTypeCategoryPayload;
    adminAddTerritory: AdminAddTerritoryPayload;
    adminAddVehicle: AdminAddVehiclePayload;
    adminDeleteScheduleTemplatePeriod: AdminDeleteScheduleTemplatePeriodPayload;
    adminExcludePeriodFromTerritory: AdminExcludePeriodFromTerritoryPayload;
    adminUpdateArea: AdminUpdateAreaPayload;
    adminUpdateBuilding: AdminUpdateBuildingPayload;
    adminUpdateEmployee: AdminUpdateEmployeePayload;
    adminUpdateEmployeePassword: AdminUpdateEmployeePasswordPayload;
    adminUpdateLocation: AdminUpdateLocationPayload;
    adminUpdateScheduleTemplate: AdminUpdateScheduleTemplatePayload;
    adminUpdateScheduleTemplatePeriod: AdminUpdateScheduleTemplatePeriodPayload;
    adminUpdateStorageContainer: AdminUpdateStorageContainerPayload;
    adminUpdateStorageContainerType: AdminStorageContainerTypeload;
    adminUpdateStorageItemType: AdminUpdateStorageItemTypePayload;
    adminUpdateStorageItemTypeCategory: AdminStorageItemTypeCategoryload;
    adminUpdateTerritory: AdminUpdateTerritoryPayload;
    adminUpdateVehicle: AdminUpdateVehiclePayload;
    assignStorageContainer: AssignStorageContainerPayload;
    deliveryCompleteVisit: DeliveryCompleteVisitPayload;
    deliveryDeliverEmptyContainer: DeliveryDeliverEmptyContainerPayload;
    deliveryDeliverItem: DeliveryDeliverItemPayload;
    deliveryPickupEmptyContainer: DeliveryPickupEmptyContainerPayload;
    deliveryPickupItem: DeliveryPickupItemPayload;
    movingProcessItem: MovingProcessItemPayload;
    pickingCompleteList: PickingCompleteListPayload;
    pickingCreateList: PickingCreateListPayload;
    pickingDeleteList: PickingDeleteListPayload;
    pickingExcludeItem: PickingExcludeItemPayload;
    pickingPickItem: PickingPickItemPayload;
    pickingSortItem: PickingSortItemPayload;
    refilingCompleteList: RefilingCompleteListPayload;
    refilingCreateList: RefilingCreateListPayload;
    refilingProcessItem: RefilingProcessItemPayload;
    renameBook?: Maybe<Book>;
    requestAssignContainer: RequestAssignContainerPayload;
    routingAddStops: RoutingAddStopPayload;
    routingCreateRoute: RoutingCreateRoutePayload;
    routingRemoveStop: RoutingRemoveStopPayload;
    routingSetEmployees: RoutingSetEmployeesPayload;
    routingSetVehicle: RoutingSetVehiclePayload;
    routingUpdateStop: RoutingUpdateStopPayload;
    saveVisit: SaveVisitPayload;
    setStorageItemMetadata: SetStorageItemMetadataPayload;
    storageItemAssignBarcode: StorageItemAssignBarcodePayload;
    truckLoadingProcessConfirmRoute: TruckLoadingProcessConfirmRoutePayload;
    truckLoadingProcessEmptyContainers: TruckLoadingProcessEmptyContainersPayload;
    truckLoadingProcessItem: TruckLoadingProcessItemPayload;
    truckUnloadingProcessItem: TruckUnloadingProcessItemPayload;
    unassignStorageContainer: UnassignStorageContainerPayload;
    updateVisitPaymentMethod: UpdateVisitPaymentMethodPayload;
    uploadStorageItemTypeImage: UploadStorageItemTypeImagePayload;
    visitCancelVisit: VisitCancelVisitPayload;
    visitReviewApproveVisit: VisitReviewApproveVisitPayload;
    visitUpdateWindow: VisitUpdateWindowPayload;
};

export type MutationAddCustomerAddressArgs = {
    data: AddCustomerAddressInput;
};

export type MutationAddCustomerPaymentMethodArgs = {
    data: AddCustomerPaymentMethodInput;
};

export type MutationAdminAddBuildingArgs = {
    data: AdminAddBuildingInput;
};

export type MutationAdminAddEmployeeArgs = {
    data: AdminAddEmployeeInput;
};

export type MutationAdminAddLocationArgs = {
    data?: Maybe<Array<AdminAddLocationsInput>>;
};

export type MutationAdminAddPeriodToTerritoryArgs = {
    data: AdminAddPeriodToTerritoryInput;
};

export type MutationAdminAddScheduleTemplatePeriodArgs = {
    data: AdminAddScheduleTemplatePeriodInput;
};

export type MutationAdminAddStorageContainerArgs = {
    data: AdminAddStorageContainerInput;
};

export type MutationAdminAddStorageContainerTypeArgs = {
    data: AdminAddStorageContainerTypeInput;
};

export type MutationAdminAddStorageItemTypeArgs = {
    data: AdminAddStorageItemTypeInput;
};

export type MutationAdminAddStorageItemTypeCategoryArgs = {
    data: AdminAddStorageItemTypeCategoryInput;
};

export type MutationAdminAddTerritoryArgs = {
    data: AdminAddTerritoryInput;
};

export type MutationAdminAddVehicleArgs = {
    data: AdminAddVehicleInput;
};

export type MutationAdminDeleteScheduleTemplatePeriodArgs = {
    data: AdminDeleteScheduleTemplatePeriodInput;
};

export type MutationAdminExcludePeriodFromTerritoryArgs = {
    data: AdminExcludePeriodFromTerritoryInput;
};

export type MutationAdminUpdateAreaArgs = {
    data: AdminUpdateAreaInput;
};

export type MutationAdminUpdateBuildingArgs = {
    data: AdminUpdateBuildingInput;
};

export type MutationAdminUpdateEmployeeArgs = {
    data: AdminUpdateEmployeeInput;
};

export type MutationAdminUpdateEmployeePasswordArgs = {
    data: AdminUpdateEmployeePasswordInput;
};

export type MutationAdminUpdateLocationArgs = {
    data: AdminUpdateLocationInput;
};

export type MutationAdminUpdateScheduleTemplateArgs = {
    data: AdminUpdateScheduleTemplateInput;
};

export type MutationAdminUpdateScheduleTemplatePeriodArgs = {
    data: AdminUpdateScheduleTemplatePeriodInput;
};

export type MutationAdminUpdateStorageContainerArgs = {
    data: AdminUpdateStorageContainerInput;
};

export type MutationAdminUpdateStorageContainerTypeArgs = {
    data: AdminStorageContainerTypeInput;
};

export type MutationAdminUpdateStorageItemTypeArgs = {
    data: AdminStorageItemTypeInput;
};

export type MutationAdminUpdateStorageItemTypeCategoryArgs = {
    data: AdminStorageItemTypeCategoryInput;
};

export type MutationAdminUpdateTerritoryArgs = {
    data: AdminUpdateTerritoryInput;
};

export type MutationAdminUpdateVehicleArgs = {
    data: AdminUpdateVehicleInput;
};

export type MutationAssignStorageContainerArgs = {
    input: AssignStorageContainerInput;
};

export type MutationDeliveryCompleteVisitArgs = {
    data: DeliveryCompleteVisitInput;
};

export type MutationDeliveryDeliverEmptyContainerArgs = {
    data: DeliveryDeliverEmptyContainerInput;
};

export type MutationDeliveryDeliverItemArgs = {
    data: DeliveryDeliverItemInput;
};

export type MutationDeliveryPickupEmptyContainerArgs = {
    data: DeliveryPickupEmptyContainerInput;
};

export type MutationDeliveryPickupItemArgs = {
    data: DeliveryPickupItemInput;
};

export type MutationMovingProcessItemArgs = {
    data: MovingProcessItemInput;
};

export type MutationPickingCompleteListArgs = {
    data: PickingCompleteListInput;
};

export type MutationPickingCreateListArgs = {
    data: PickingCreateListInput;
};

export type MutationPickingDeleteListArgs = {
    data: PickingDeleteListInput;
};

export type MutationPickingExcludeItemArgs = {
    data: PickingExcludeItemInput;
};

export type MutationPickingPickItemArgs = {
    data: PickingPickItemInput;
};

export type MutationPickingSortItemArgs = {
    data: PickingSortItemInput;
};

export type MutationRefilingCompleteListArgs = {
    data: RefilingCompleteListInput;
};

export type MutationRefilingCreateListArgs = {
    data: RefilingCreateListInput;
};

export type MutationRefilingProcessItemArgs = {
    data: RefilingProcessItemInput;
};

export type MutationRenameBookArgs = {
    bookId: Scalars['Int'];
    title: Scalars['String'];
};

export type MutationRequestAssignContainerArgs = {
    data: RequestAssignContainerInput;
};

export type MutationRoutingAddStopsArgs = {
    data: RoutingAddStopInput;
};

export type MutationRoutingCreateRouteArgs = {
    data: RoutingCreateRouteInput;
};

export type MutationRoutingRemoveStopArgs = {
    data: RoutingRemoveStopInput;
};

export type MutationRoutingSetEmployeesArgs = {
    data: RoutingSetEmployeesInput;
};

export type MutationRoutingSetVehicleArgs = {
    data: RoutingSetVehicleInput;
};

export type MutationRoutingUpdateStopArgs = {
    data: RoutingUpdateStopInput;
};

export type MutationSaveVisitArgs = {
    data: SaveVisitInput;
};

export type MutationSetStorageItemMetadataArgs = {
    data: SetStorageItemMetadataInput;
};

export type MutationStorageItemAssignBarcodeArgs = {
    data: StorageItemAssignBarcodeInput;
};

export type MutationTruckLoadingProcessConfirmRouteArgs = {
    data: TruckLoadingProcessConfirmRouteInput;
};

export type MutationTruckLoadingProcessEmptyContainersArgs = {
    data: TruckLoadingProcessEmptyContainersInput;
};

export type MutationTruckLoadingProcessItemArgs = {
    data: TruckLoadingProcessItemInput;
};

export type MutationTruckUnloadingProcessItemArgs = {
    data: TruckUnloadingProcessItemInput;
};

export type MutationUnassignStorageContainerArgs = {
    input: UnassignStorageContainerInput;
};

export type MutationUpdateVisitPaymentMethodArgs = {
    data: UpdateVisitPaymentMethodInput;
};

export type MutationUploadStorageItemTypeImageArgs = {
    file: Scalars['Upload'];
};

export type MutationVisitCancelVisitArgs = {
    data: VisitCancelVisitInput;
};

export type MutationVisitReviewApproveVisitArgs = {
    data: VisitReviewApproveVisitInput;
};

export type MutationVisitUpdateWindowArgs = {
    data: VisitUpdateWindowInput;
};

export type AddCustomerAddressInput = {
    customerId?: Maybe<Scalars['String']>;
    addressType: CustomerAddressType;
    address1: Scalars['String'];
    address2?: Maybe<Scalars['String']>;
    city: Scalars['String'];
    zoneId?: Maybe<Scalars['String']>;
    countryId: Scalars['String'];
    zip?: Maybe<Scalars['String']>;
    longitude?: Maybe<Scalars['Float']>;
    latitude?: Maybe<Scalars['Float']>;
    phone?: Maybe<Scalars['String']>;
    firstName?: Maybe<Scalars['String']>;
    lastName?: Maybe<Scalars['String']>;
};

export type AddCustomerAddressPayload = {
    __typename?: 'AddCustomerAddressPayload';
    customerAddress?: Maybe<CustomerAddress>;
};

export type AddCustomerPaymentMethodInput = {
    description: Scalars['String'];
    isDefault: Scalars['Boolean'];
};

export type AddCustomerPaymentMethodPayload = {
    __typename?: 'AddCustomerPaymentMethodPayload';
    paymentMethod?: Maybe<PaymentMethod>;
};

export type AdminAddBuildingInput = {
    code: Scalars['String'];
    description?: Maybe<Scalars['String']>;
    address1: Scalars['String'];
    address2?: Maybe<Scalars['String']>;
    city?: Maybe<Scalars['String']>;
    zoneId?: Maybe<Scalars['String']>;
    countryId?: Maybe<Scalars['String']>;
    zip?: Maybe<Scalars['String']>;
    phone?: Maybe<Scalars['String']>;
    firstName?: Maybe<Scalars['String']>;
    lastName?: Maybe<Scalars['String']>;
    areaId: Scalars['String'];
};

export type AdminAddBuildingPayload = {
    __typename?: 'AdminAddBuildingPayload';
    data: Building;
};

export type AdminAddEmployeeInput = {
    firstName: Scalars['String'];
    lastName: Scalars['String'];
    email: Scalars['String'];
    homePhone?: Maybe<Scalars['String']>;
    cellPhone?: Maybe<Scalars['String']>;
    workPhone?: Maybe<Scalars['String']>;
    startDate?: Maybe<Scalars['DateTime']>;
    endDate?: Maybe<Scalars['DateTime']>;
    address1: Scalars['String'];
    address2?: Maybe<Scalars['String']>;
    city?: Maybe<Scalars['String']>;
    zoneId?: Maybe<Scalars['String']>;
    countryId: Scalars['String'];
    zip?: Maybe<Scalars['String']>;
};

export type AdminAddEmployeePayload = {
    __typename?: 'AdminAddEmployeePayload';
    data: Employee;
};

export type AdminAddLocationsInput = {
    description: Scalars['String'];
    barcode: Scalars['String'];
    type: LocationType;
    buildingId: Scalars['String'];
};

export type AdminAddLocationsPayload = {
    __typename?: 'AdminAddLocationsPayload';
    data: Array<Maybe<Location>>;
};

export type AdminAddPeriodToTerritoryInput = {
    scheduleTemplatePeriodId: Scalars['String'];
    territoryId: Scalars['String'];
};

export type AdminAddPeriodToTerritoryPayload = {
    __typename?: 'AdminAddPeriodToTerritoryPayload';
    data: ScheduleTemplateWindow;
};

export type AdminAddScheduleTemplatePeriodInput = {
    scheduleTemplateId: Scalars['String'];
    startTime: Scalars['String'];
    endTime: Scalars['String'];
    week: Scalars['Int'];
    day: Scalars['Int'];
};

export type AdminAddScheduleTemplatePeriodPayload = {
    __typename?: 'AdminAddScheduleTemplatePeriodPayload';
    data: ScheduleTemplateWindow;
};

export type AdminAddStorageContainerInput = {
    status: StorageContainerStatus;
    barcode?: Maybe<Scalars['String']>;
    storageContainerTypeId: Scalars['String'];
};

export type AdminAddStorageContainerPayload = {
    __typename?: 'AdminAddStorageContainerPayload';
    data: StorageContainer;
};

export type AdminAddStorageContainerTypeInput = {
    description: Scalars['String'];
};

export type AdminAddStorageContainerTypePayload = {
    __typename?: 'AdminAddStorageContainerTypePayload';
    data: StorageContainerType;
};

export type AdminAddStorageItemTypeInput = {
    name: Scalars['String'];
    description?: Maybe<Scalars['String']>;
    image?: Maybe<Scalars['String']>;
    multiplePeople: Scalars['Boolean'];
    maximumRequestQuantity: Scalars['Int'];
    categoryId: Scalars['String'];
};

export type AdminAddStorageItemTypePayload = {
    __typename?: 'AdminAddStorageItemTypePayload';
    data: StorageItemType;
};

export type AdminAddStorageItemTypeCategoryInput = {
    name: Scalars['String'];
};

export type AdminAddStorageItemTypeCategoryPayload = {
    __typename?: 'AdminAddStorageItemTypeCategoryPayload';
    data: StorageItemTypeCategory;
};

export type AdminAddTerritoryInput = {
    description: Scalars['String'];
    maximumRequestQuantity?: Maybe<Scalars['Int']>;
    deliveryCutoff?: Maybe<Scalars['Int']>;
    deliveryDelay?: Maybe<Scalars['Int']>;
    pickupCutoff?: Maybe<Scalars['Int']>;
    pickupDelay?: Maybe<Scalars['Int']>;
    areaId: Scalars['String'];
    zipCodes?: Maybe<Array<Scalars['String']>>;
};

export type AdminAddTerritoryPayload = {
    __typename?: 'AdminAddTerritoryPayload';
    data: Territory;
};

export type AdminAddVehicleInput = {
    description: Scalars['String'];
    model?: Maybe<Scalars['String']>;
    manufacture_year: Scalars['Int'];
    vin?: Maybe<Scalars['String']>;
    image?: Maybe<Scalars['String']>;
};

export type AdminAddVehiclePayload = {
    __typename?: 'AdminAddVehiclePayload';
    data: Vehicle;
};

export type AdminDeleteScheduleTemplatePeriodInput = {
    id: Scalars['String'];
};

export type AdminDeleteScheduleTemplatePeriodPayload = {
    __typename?: 'AdminDeleteScheduleTemplatePeriodPayload';
    data: ScheduleTemplateWindow;
};

export type AdminExcludePeriodFromTerritoryInput = {
    scheduleTemplatePeriodId: Scalars['String'];
    territoryId: Scalars['String'];
};

export type AdminExcludePeriodFromTerritoryPayload = {
    __typename?: 'AdminExcludePeriodFromTerritoryPayload';
    data: ScheduleTemplateWindow;
};

export type AdminUpdateAreaInput = {
    areaId: Scalars['String'];
    code: Scalars['String'];
};

export type AdminUpdateAreaPayload = {
    __typename?: 'AdminUpdateAreaPayload';
    data: Area;
};

export type AdminUpdateBuildingInput = {
    id: Scalars['String'];
    code: Scalars['String'];
    description?: Maybe<Scalars['String']>;
    isActive: Scalars['Boolean'];
    address?: Maybe<AdminUpdateBuildingAddressInput>;
    areaId: Scalars['String'];
};

export type AdminUpdateBuildingAddressInput = {
    address1: Scalars['String'];
    address2?: Maybe<Scalars['String']>;
    city?: Maybe<Scalars['String']>;
    zoneId?: Maybe<Scalars['String']>;
    countryId?: Maybe<Scalars['String']>;
    zip?: Maybe<Scalars['String']>;
    phone?: Maybe<Scalars['String']>;
    firstName?: Maybe<Scalars['String']>;
    lastName?: Maybe<Scalars['String']>;
};

export type AdminUpdateBuildingPayload = {
    __typename?: 'AdminUpdateBuildingPayload';
    data: Building;
};

export type AdminUpdateEmployeeInput = {
    id: Scalars['String'];
    firstName: Scalars['String'];
    lastName: Scalars['String'];
    email: Scalars['String'];
    homePhone?: Maybe<Scalars['String']>;
    cellPhone?: Maybe<Scalars['String']>;
    workPhone?: Maybe<Scalars['String']>;
    address?: Maybe<AdminUpdateEmployeeAddressInput>;
};

export type AdminUpdateEmployeeAddressInput = {
    address1: Scalars['String'];
    address2?: Maybe<Scalars['String']>;
    city?: Maybe<Scalars['String']>;
    zoneId?: Maybe<Scalars['String']>;
    countryId?: Maybe<Scalars['String']>;
    zip?: Maybe<Scalars['String']>;
};

export type AdminUpdateEmployeePayload = {
    __typename?: 'AdminUpdateEmployeePayload';
    data: Employee;
};

export type AdminUpdateEmployeePasswordInput = {
    id: Scalars['String'];
    password?: Maybe<Scalars['String']>;
};

export type AdminUpdateEmployeePasswordPayload = {
    __typename?: 'AdminUpdateEmployeePasswordPayload';
    data: Employee;
};

export type AdminUpdateLocationInput = {
    id: Scalars['String'];
    description: Scalars['String'];
    barcode?: Maybe<Scalars['String']>;
    type: LocationType;
    buildingId: Scalars['String'];
};

export type AdminUpdateLocationPayload = {
    __typename?: 'AdminUpdateLocationPayload';
    data: Location;
};

export type AdminUpdateScheduleTemplateInput = {
    id: Scalars['String'];
    startDate: Scalars['DateTime'];
    areaId: Scalars['String'];
};

export type AdminUpdateScheduleTemplatePayload = {
    __typename?: 'AdminUpdateScheduleTemplatePayload';
    data: ScheduleTemplate;
};

export type AdminUpdateScheduleTemplatePeriodInput = {
    id: Scalars['String'];
    startTime: Scalars['String'];
    endTime: Scalars['String'];
    week: Scalars['Int'];
    day: Scalars['Int'];
};

export type AdminUpdateScheduleTemplatePeriodPayload = {
    __typename?: 'AdminUpdateScheduleTemplatePeriodPayload';
    data: ScheduleTemplateWindow;
};

export type AdminUpdateStorageContainerInput = {
    id: Scalars['String'];
    status: StorageContainerStatus;
    barcode?: Maybe<Scalars['String']>;
    storageContainerTypeId: Scalars['String'];
};

export type AdminUpdateStorageContainerPayload = {
    __typename?: 'AdminUpdateStorageContainerPayload';
    data: StorageContainer;
};

export type AdminStorageContainerTypeInput = {
    id: Scalars['ID'];
    description: Scalars['String'];
};

export type AdminStorageContainerTypeload = {
    __typename?: 'AdminStorageContainerTypeload';
    data: StorageContainerType;
};

export type AdminStorageItemTypeInput = {
    id: Scalars['String'];
    name: Scalars['String'];
    description?: Maybe<Scalars['String']>;
    image?: Maybe<Scalars['String']>;
    multiplePeople: Scalars['Boolean'];
    maximumRequestQuantity: Scalars['Int'];
    categoryId: Scalars['String'];
};

export type AdminUpdateStorageItemTypePayload = {
    __typename?: 'AdminUpdateStorageItemTypePayload';
    data: StorageItemType;
};

export type AdminStorageItemTypeCategoryInput = {
    id: Scalars['String'];
    name: Scalars['String'];
};

export type AdminStorageItemTypeCategoryload = {
    __typename?: 'AdminStorageItemTypeCategoryload';
    data: StorageItemTypeCategory;
};

export type AdminUpdateTerritoryInput = {
    id: Scalars['String'];
    description: Scalars['String'];
    maximumRequestQuantity?: Maybe<Scalars['Int']>;
    deliveryCutoff?: Maybe<Scalars['Int']>;
    deliveryDelay?: Maybe<Scalars['Int']>;
    pickupCutoff?: Maybe<Scalars['Int']>;
    pickupDelay?: Maybe<Scalars['Int']>;
    areaId: Scalars['String'];
    zipCodes?: Maybe<Array<Scalars['String']>>;
};

export type AdminUpdateTerritoryPayload = {
    __typename?: 'AdminUpdateTerritoryPayload';
    data: Territory;
};

export type AdminUpdateVehicleInput = {
    id: Scalars['String'];
    description: Scalars['String'];
    model?: Maybe<Scalars['String']>;
    manufacture_year: Scalars['Int'];
    vin?: Maybe<Scalars['String']>;
    image?: Maybe<Scalars['String']>;
    isActive: Scalars['Boolean'];
};

export type AdminUpdateVehiclePayload = {
    __typename?: 'AdminUpdateVehiclePayload';
    data: Vehicle;
};

export type AssignStorageContainerInput = {
    storageItemId: Scalars['ID'];
    storageContainerId: Scalars['ID'];
};

export type AssignStorageContainerPayload = {
    __typename?: 'AssignStorageContainerPayload';
    storageItem: StorageItem;
};

export type DeliveryCompleteVisitInput = {
    visitId: Scalars['String'];
    customerSignature: Scalars['String'];
};

export type DeliveryCompleteVisitPayload = {
    __typename?: 'DeliveryCompleteVisitPayload';
    data: Visit;
};

export type DeliveryDeliverEmptyContainerInput = {
    requestId: Scalars['String'];
    containerBarcode: Scalars['String'];
};

export type DeliveryDeliverEmptyContainerPayload = {
    __typename?: 'DeliveryDeliverEmptyContainerPayload';
    data?: Maybe<DeliverEmptyContainerRequest>;
};

export type DeliveryDeliverItemInput = {
    requestId: Scalars['String'];
};

export type DeliveryDeliverItemPayload = {
    __typename?: 'DeliveryDeliverItemPayload';
    data?: Maybe<DeliverStorageItemRequest>;
};

export type DeliveryPickupEmptyContainerInput = {
    visitId: Scalars['String'];
    containerBarcode: Scalars['String'];
};

export type DeliveryPickupEmptyContainerPayload = {
    __typename?: 'DeliveryPickupEmptyContainerPayload';
    data?: Maybe<PickupEmptyContainerRequest>;
};

export type DeliveryPickupItemInput = {
    requestId: Scalars['String'];
};

export type DeliveryPickupItemPayload = {
    __typename?: 'DeliveryPickupItemPayload';
    data?: Maybe<PickupStorageItemRequest>;
};

export type MovingProcessItemInput = {
    itemBarcode: Scalars['String'];
    locationId: Scalars['String'];
};

export type MovingProcessItemPayload = {
    __typename?: 'MovingProcessItemPayload';
    data: StorageItem;
};

export type PickingCompleteListInput = {
    pickListId: Scalars['String'];
};

export type PickingCompleteListPayload = {
    __typename?: 'PickingCompleteListPayload';
    pickList?: Maybe<PickList>;
};

export type PickingCreateListInput = {
    description?: Maybe<Scalars['String']>;
    filter: PickingCreateListFilterInput;
};

export type PickingCreateListFilterInput = {
    visitStartTime?: Maybe<Scalars['DateTime']>;
    visitEndTime?: Maybe<Scalars['DateTime']>;
    locations?: Maybe<Array<PickingCreateListLocationFilterInput>>;
    storageItemTypeIds?: Maybe<Array<Scalars['String']>>;
    maxQuantity?: Maybe<Scalars['Int']>;
    visitIds?: Maybe<Array<Scalars['String']>>;
};

export type PickingCreateListLocationFilterInput = {
    from?: Maybe<Scalars['String']>;
    to?: Maybe<Scalars['String']>;
};

export type PickingCreateListPayload = {
    __typename?: 'PickingCreateListPayload';
    pickList?: Maybe<PickList>;
};

export type PickingDeleteListInput = {
    pickListId: Scalars['String'];
};

export type PickingDeleteListPayload = {
    __typename?: 'PickingDeleteListPayload';
    pickList?: Maybe<PickList>;
};

export type PickingExcludeItemInput = {
    pickListItemId: Scalars['String'];
};

export type PickingExcludeItemPayload = {
    __typename?: 'PickingExcludeItemPayload';
    pickListItem?: Maybe<PickListItem>;
};

export type PickingPickItemInput = {
    pickListItemId: Scalars['String'];
    targetLocationName?: Maybe<Scalars['String']>;
};

export type PickingPickItemPayload = {
    __typename?: 'PickingPickItemPayload';
    pickListItem?: Maybe<PickListItem>;
};

export type PickingSortItemInput = {
    storageItemId: Scalars['String'];
    locationId: Scalars['String'];
};

export type PickingSortItemPayload = {
    __typename?: 'PickingSortItemPayload';
    storageItem?: Maybe<StorageItem>;
};

export type RefilingCompleteListInput = {
    refileListId: Scalars['String'];
};

export type RefilingCompleteListPayload = {
    __typename?: 'RefilingCompleteListPayload';
    data: RefileList;
};

export type RefilingCreateListInput = {
    storageItemIds?: Maybe<Array<Scalars['String']>>;
};

export type RefilingCreateListPayload = {
    __typename?: 'RefilingCreateListPayload';
    data?: Maybe<RefileList>;
};

export type RefilingProcessItemInput = {
    itemBarcode: Scalars['String'];
    locationId: Scalars['String'];
    refileListId: Scalars['String'];
};

export type RefilingProcessItemPayload = {
    __typename?: 'RefilingProcessItemPayload';
    data: RefileItem;
};

export type RequestAssignContainerInput = {
    requestId: Scalars['String'];
    barcode: Scalars['String'];
};

export type RequestAssignContainerPayload = {
    __typename?: 'RequestAssignContainerPayload';
    data?: Maybe<Request>;
};

export type RoutingAddStopVisitInput = {
    visitId: Scalars['ID'];
    startTime: Scalars['DateTime'];
    endTime: Scalars['DateTime'];
};

export type RoutingAddStopInput = {
    routeId: Scalars['ID'];
    visits: Array<RoutingAddStopVisitInput>;
};

export type RoutingAddStopPayload = {
    __typename?: 'RoutingAddStopPayload';
    routeStops: Array<RouteStop>;
    route: Route;
};

export type RoutingCreateRouteInput = {
    description?: Maybe<Scalars['String']>;
    startBuildingId?: Maybe<Scalars['String']>;
    endBuildingId?: Maybe<Scalars['String']>;
    employeeIds?: Maybe<Array<Scalars['String']>>;
    operationDate: Scalars['DateTime'];
};

export type RoutingCreateRoutePayload = {
    __typename?: 'RoutingCreateRoutePayload';
    route: Route;
};

export type RoutingRemoveStopInput = {
    routeStopId: Scalars['ID'];
};

export type RoutingRemoveStopPayload = {
    __typename?: 'RoutingRemoveStopPayload';
    visit: Visit;
    route: Route;
};

export type RoutingSetEmployeesInput = {
    routeId: Scalars['String'];
    employeeIds: Array<Scalars['String']>;
};

export type RoutingSetEmployeesPayload = {
    __typename?: 'RoutingSetEmployeesPayload';
    route: Route;
};

export type RoutingSetVehicleInput = {
    routeId: Scalars['String'];
    vehicleId?: Maybe<Scalars['String']>;
};

export type RoutingSetVehiclePayload = {
    __typename?: 'RoutingSetVehiclePayload';
    route: Route;
};

export type RoutingUpdateStopInput = {
    routeStopId: Scalars['ID'];
    startTime: Scalars['DateTime'];
};

export type RoutingUpdateStopPayload = {
    __typename?: 'RoutingUpdateStopPayload';
    routeStop: RouteStop;
    visit: Visit;
    route: Route;
};

export type SaveVisitInput = {
    customerId?: Maybe<Scalars['String']>;
    newItemPickupRequests?: Maybe<Array<RequestNewItemPickupRequestInput>>;
    existingItemPickupRequests?: Maybe<Array<RequestExistingItemPickupRequestInput>>;
    itemDeliveryRequests?: Maybe<Array<ItemDeliveryRequestInput>>;
    emptyContainerPickupRequests?: Maybe<Array<EmptyContainerPickupRequestInput>>;
    visitId?: Maybe<Scalars['String']>;
    customerAddressId?: Maybe<Scalars['String']>;
    customerPaymentMethodId?: Maybe<Scalars['String']>;
    startTime?: Maybe<Scalars['DateTime']>;
    endTime?: Maybe<Scalars['DateTime']>;
    containsContainers?: Maybe<Scalars['Boolean']>;
    containersOneVisit?: Maybe<Scalars['Boolean']>;
    secondVisitId?: Maybe<Scalars['String']>;
    secondCustomerAddressId?: Maybe<Scalars['String']>;
    secondCustomerPaymentMethodId?: Maybe<Scalars['String']>;
    secondStartTime?: Maybe<Scalars['DateTime']>;
    secondEndTime?: Maybe<Scalars['DateTime']>;
};

export type RequestNewItemPickupRequestInput = {
    storageItemTypeId: Scalars['String'];
    quantity: Scalars['Float'];
};

export type RequestExistingItemPickupRequestInput = {
    storageItemId: Scalars['String'];
};

export type ItemDeliveryRequestInput = {
    storageItemId: Scalars['String'];
};

export type EmptyContainerPickupRequestInput = {
    storageContainerId: Scalars['String'];
};

export type SaveVisitPayload = {
    __typename?: 'SaveVisitPayload';
    visit?: Maybe<Visit>;
    secondVisit?: Maybe<Visit>;
};

export type SetStorageItemMetadataInput = {
    storageItemId: Scalars['String'];
    values?: Maybe<Array<SetStorageItemMetadataValueInput>>;
};

export type SetStorageItemMetadataValueInput = {
    storageItemMetadataDefinitionId: Scalars['String'];
    value: Scalars['String'];
};

export type SetStorageItemMetadataPayload = {
    __typename?: 'SetStorageItemMetadataPayload';
    values?: Maybe<Array<StorageItemMetadataValue>>;
};

export type StorageItemAssignBarcodeInput = {
    storageItemId: Scalars['String'];
    barcode: Scalars['String'];
};

export type StorageItemAssignBarcodePayload = {
    __typename?: 'StorageItemAssignBarcodePayload';
    data?: Maybe<StorageItem>;
};

export type TruckLoadingProcessConfirmRouteInput = {
    routeId: Scalars['String'];
};

export type TruckLoadingProcessConfirmRoutePayload = {
    __typename?: 'TruckLoadingProcessConfirmRoutePayload';
    data: Route;
};

export type TruckLoadingProcessEmptyContainersInput = {
    routeId: Scalars['String'];
};

export type TruckLoadingProcessEmptyContainersPayload = {
    __typename?: 'TruckLoadingProcessEmptyContainersPayload';
    data: Route;
};

export type TruckLoadingProcessItemInput = {
    requestId: Scalars['String'];
};

export type TruckLoadingProcessItemPayload = {
    __typename?: 'TruckLoadingProcessItemPayload';
    data?: Maybe<DeliverStorageItemRequest>;
};

export type TruckUnloadingProcessItemInput = {
    routeIds: Array<Scalars['String']>;
    locationBarcode: Scalars['String'];
};

export type TruckUnloadingProcessItemPayload = {
    __typename?: 'TruckUnloadingProcessItemPayload';
    data: Scalars['Boolean'];
};

export type UnassignStorageContainerInput = {
    storageContainerId: Scalars['ID'];
};

export type UnassignStorageContainerPayload = {
    __typename?: 'UnassignStorageContainerPayload';
    storageItem: StorageItem;
};

export type UpdateVisitPaymentMethodInput = {
    visitId: Scalars['String'];
    customerPaymentMethodId: Scalars['String'];
};

export type UpdateVisitPaymentMethodPayload = {
    __typename?: 'UpdateVisitPaymentMethodPayload';
    visit?: Maybe<Visit>;
};

export type UploadStorageItemTypeImageResponse = {
    __typename?: 'UploadStorageItemTypeImageResponse';
    url: Scalars['String'];
};

export type UploadStorageItemTypeImagePayload = {
    __typename?: 'UploadStorageItemTypeImagePayload';
    data: UploadStorageItemTypeImageResponse;
};

export type VisitCancelVisitInput = {
    id: Scalars['String'];
};

export type VisitCancelVisitPayload = {
    __typename?: 'VisitCancelVisitPayload';
    data: Visit;
};

export type VisitReviewApproveVisitInput = {
    visitId: Scalars['String'];
    estimatedDuration: Scalars['Int'];
    scheduleWindowId?: Maybe<Scalars['String']>;
    scheduleWindowDate?: Maybe<Scalars['DateTime']>;
};

export type VisitReviewApproveVisitPayload = {
    __typename?: 'VisitReviewApproveVisitPayload';
    visit?: Maybe<Visit>;
};

export type VisitUpdateWindowInput = {
    visitId: Scalars['String'];
    startTime: Scalars['DateTime'];
    endTime: Scalars['DateTime'];
};

export type VisitUpdateWindowPayload = {
    __typename?: 'VisitUpdateWindowPayload';
    data: Visit;
};

export type FilterInput = {
    conditions?: Maybe<Array<Maybe<WhereCondition>>>;
    pageSize?: Maybe<Scalars['Int']>;
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
};

export type WhereCondition = {
    field: Scalars['String'];
    value?: Maybe<Scalars['String']>;
    operator?: Maybe<Operators>;
};

export enum Operators {
    Greaterthan = 'Greaterthan',
    Lesserthan = 'Lesserthan',
    GreaterthanOrEqualto = 'GreaterthanOrEqualto',
    Lesserthanorequalto = 'Lesserthanorequalto',
    Equal = 'Equal',
    Notequal = 'Notequal',
}

export type OrderBy = {
    field: Scalars['String'];
    direction?: Maybe<Directions>;
};

export enum Directions {
    Asc = 'Asc',
    Desc = 'Desc',
}

export type PageInfo = {
    __typename?: 'PageInfo';
    cursor: PageCursor;
    hasNextPage: Scalars['Boolean'];
    hasPreviousPage: Scalars['Boolean'];
};

export type PageCursor = {
    __typename?: 'PageCursor';
    beforeCursor?: Maybe<Scalars['String']>;
    afterCursor?: Maybe<Scalars['String']>;
};

export type Query = {
    __typename?: 'Query';
    _empty?: Maybe<Scalars['String']>;
    areaById?: Maybe<Area>;
    areas?: Maybe<Array<Area>>;
    books?: Maybe<Array<Maybe<Book>>>;
    buildingById?: Maybe<Building>;
    buildings: BuildingConnection;
    buildingsAll?: Maybe<Array<Building>>;
    currentCustomer?: Maybe<Customer>;
    customers: CustomerConnection;
    employeeById?: Maybe<Employee>;
    employees: EmployeeConnection;
    getCustomerById: Customer;
    locationByBarcode?: Maybe<Location>;
    locationById?: Maybe<Location>;
    locations: LocationConnection;
    me?: Maybe<User>;
    pickListById?: Maybe<PickList>;
    pickLists: PickListConnection;
    pickingListSummary: PickingListSummaryPayload;
    providerByDomain?: Maybe<Provider>;
    refileListById?: Maybe<RefileList>;
    refileLists: RefileListConnection;
    requestById?: Maybe<Request>;
    requestsByVisitId: RequestConnection;
    routeByVehicleId?: Maybe<Array<Route>>;
    routes: RouteConnection;
    scheduleTemplateById?: Maybe<ScheduleTemplate>;
    scheduleTemplates?: Maybe<Array<ScheduleTemplate>>;
    serviceProvider?: Maybe<ServiceProvider>;
    storageContainerByBarcode?: Maybe<StorageContainer>;
    storageContainerById?: Maybe<StorageContainer>;
    storageContainerType?: Maybe<Array<StorageContainerType>>;
    storageContainerTypeById?: Maybe<StorageContainerType>;
    storageContainers: StorageContainerConnection;
    storageItemByBarcode?: Maybe<StorageItem>;
    storageItemById?: Maybe<StorageItem>;
    storageItemByRequest?: Maybe<StorageItem>;
    storageItemTypeById?: Maybe<StorageItemType>;
    storageItemTypeCategory?: Maybe<Array<StorageItemTypeCategory>>;
    storageItemTypeCategoryById?: Maybe<StorageItemTypeCategory>;
    storageItemTypes: StorageItemTypeConnection;
    storageItems: StorageItemConnection;
    territories: TerritoryConnection;
    territoryById?: Maybe<Territory>;
    territoryByZipCode?: Maybe<Territory>;
    vehicleById?: Maybe<Vehicle>;
    vehicles: VehicleConnection;
    vehiclesByInProgressRoutes?: Maybe<Array<Vehicle>>;
    visitById?: Maybe<Visit>;
    visits: VisitConnection;
};

export type QueryAreaByIdArgs = {
    id: Scalars['String'];
};

export type QueryBuildingByIdArgs = {
    id: Scalars['String'];
};

export type QueryBuildingsArgs = {
    filter: FilterInput;
    sort?: Maybe<Array<Maybe<OrderBy>>>;
};

export type QueryCustomersArgs = {
    filter: FilterInput;
    sort?: Maybe<Array<Maybe<OrderBy>>>;
};

export type QueryEmployeeByIdArgs = {
    id: Scalars['String'];
};

export type QueryEmployeesArgs = {
    filter: FilterInput;
    sort?: Maybe<Array<Maybe<OrderBy>>>;
};

export type QueryGetCustomerByIdArgs = {
    id?: Maybe<Scalars['String']>;
};

export type QueryLocationByBarcodeArgs = {
    barcode: Scalars['String'];
};

export type QueryLocationByIdArgs = {
    id: Scalars['String'];
};

export type QueryLocationsArgs = {
    filter: FilterInput;
    sort?: Maybe<Array<Maybe<OrderBy>>>;
};

export type QueryPickListByIdArgs = {
    id: Scalars['String'];
};

export type QueryPickListsArgs = {
    filter: FilterInput;
    sort?: Maybe<Array<Maybe<OrderBy>>>;
};

export type QueryPickingListSummaryArgs = {
    data: PickingListSummaryFilterInput;
};

export type QueryProviderByDomainArgs = {
    domain: Scalars['String'];
};

export type QueryRefileListByIdArgs = {
    id: Scalars['String'];
};

export type QueryRefileListsArgs = {
    filter: FilterInput;
    sort?: Maybe<Array<Maybe<OrderBy>>>;
};

export type QueryRequestByIdArgs = {
    id: Scalars['String'];
};

export type QueryRequestsByVisitIdArgs = {
    visitId: Scalars['String'];
    filter: FilterInput;
};

export type QueryRouteByVehicleIdArgs = {
    vehicleId: Scalars['String'];
    routeStatus?: Maybe<RouteStatus>;
    fromDate?: Maybe<Scalars['DateTime']>;
    toDate?: Maybe<Scalars['DateTime']>;
};

export type QueryRoutesArgs = {
    filter: FilterInput;
    sort?: Maybe<Array<Maybe<OrderBy>>>;
};

export type QueryScheduleTemplateByIdArgs = {
    id: Scalars['String'];
};

export type QueryStorageContainerByBarcodeArgs = {
    barcode: Scalars['String'];
};

export type QueryStorageContainerByIdArgs = {
    id: Scalars['String'];
};

export type QueryStorageContainerTypeByIdArgs = {
    id: Scalars['String'];
};

export type QueryStorageContainersArgs = {
    filter: FilterInput;
    sort?: Maybe<Array<Maybe<OrderBy>>>;
};

export type QueryStorageItemByBarcodeArgs = {
    barcode: Scalars['String'];
};

export type QueryStorageItemByIdArgs = {
    id: Scalars['String'];
};

export type QueryStorageItemByRequestArgs = {
    requestId: Scalars['String'];
};

export type QueryStorageItemTypeByIdArgs = {
    id: Scalars['String'];
};

export type QueryStorageItemTypeCategoryByIdArgs = {
    id: Scalars['String'];
};

export type QueryStorageItemTypesArgs = {
    filter: FilterInput;
    sort?: Maybe<Array<Maybe<OrderBy>>>;
};

export type QueryStorageItemsArgs = {
    filter: FilterInput;
    sort?: Maybe<Array<Maybe<OrderBy>>>;
};

export type QueryTerritoriesArgs = {
    filter: FilterInput;
    sort?: Maybe<Array<Maybe<OrderBy>>>;
};

export type QueryTerritoryByIdArgs = {
    id: Scalars['String'];
};

export type QueryTerritoryByZipCodeArgs = {
    zipCode: Scalars['String'];
};

export type QueryVehicleByIdArgs = {
    id: Scalars['String'];
};

export type QueryVehiclesArgs = {
    filter: FilterInput;
    sort?: Maybe<Array<Maybe<OrderBy>>>;
};

export type QueryVisitByIdArgs = {
    visitId: Scalars['String'];
};

export type QueryVisitsArgs = {
    filter: FilterInput;
    sort?: Maybe<Array<Maybe<OrderBy>>>;
};

export type Address = {
    __typename?: 'Address';
    id: Scalars['String'];
    address1: Scalars['String'];
    address2?: Maybe<Scalars['String']>;
    city?: Maybe<Scalars['String']>;
    zoneId?: Maybe<Scalars['String']>;
    countryId?: Maybe<Scalars['String']>;
    zip?: Maybe<Scalars['String']>;
    longitude?: Maybe<Scalars['Float']>;
    latitude?: Maybe<Scalars['Float']>;
    phone?: Maybe<Scalars['String']>;
    firstName?: Maybe<Scalars['String']>;
    lastName?: Maybe<Scalars['String']>;
};

export type AddressConnection = {
    __typename?: 'AddressConnection';
    pageInfo: PageInfo;
    edges: Array<AddressEdge>;
};

export type AddressEdge = {
    __typename?: 'AddressEdge';
    cursor: Scalars['String'];
    node: Address;
};

export type Area = {
    __typename?: 'Area';
    buildings?: Maybe<Array<Building>>;
    code: Scalars['String'];
    deliveryWindowsByDate?: Maybe<Array<ScheduleWindow>>;
    id: Scalars['ID'];
    scheduleTemplate?: Maybe<ScheduleTemplate>;
    territories?: Maybe<Array<Territory>>;
    visits?: Maybe<VisitConnection>;
};

export type AreaDeliveryWindowsByDateArgs = {
    date: Scalars['DateTime'];
};

export type AreaVisitsArgs = {
    data?: Maybe<VisitFilterInput>;
};

export type Building = {
    __typename?: 'Building';
    address: Address;
    area: Area;
    code: Scalars['String'];
    description: Scalars['String'];
    id: Scalars['ID'];
    isActive: Scalars['Boolean'];
};

export type Territory = {
    __typename?: 'Territory';
    area: Area;
    deliveryCutoff?: Maybe<Scalars['Int']>;
    deliveryDelay?: Maybe<Scalars['Int']>;
    description: Scalars['String'];
    excludedDeliveryWindows?: Maybe<Array<ScheduleTemplateWindow>>;
    id: Scalars['ID'];
    maximumRequestQuantity?: Maybe<Scalars['Int']>;
    pickupCutoff?: Maybe<Scalars['Int']>;
    pickupDelay?: Maybe<Scalars['Int']>;
    schedule?: Maybe<Array<ScheduleWindow>>;
    storageItemTypes?: Maybe<Array<StorageItemType>>;
    zipCodes?: Maybe<Array<Scalars['String']>>;
};

export type TerritoryScheduleArgs = {
    startDate: Scalars['DateTime'];
    endDate: Scalars['DateTime'];
    requestType: RequestType;
};

export type ScheduleTemplate = {
    __typename?: 'ScheduleTemplate';
    area: Area;
    id: Scalars['ID'];
    startDate?: Maybe<Scalars['DateTime']>;
    weeks: Array<ScheduleTemplateWeek>;
};

export type Author = {
    __typename?: 'Author';
    age?: Maybe<Scalars['Int']>;
    books?: Maybe<Array<Maybe<Book>>>;
    name?: Maybe<Scalars['String']>;
};

export type Book = {
    __typename?: 'Book';
    author?: Maybe<Author>;
    availableQty?: Maybe<Scalars['Int']>;
    price?: Maybe<Scalars['Float']>;
    rand?: Maybe<Scalars['Int']>;
    soldQty?: Maybe<Scalars['Int']>;
    test?: Maybe<Scalars['Int']>;
    title?: Maybe<Scalars['String']>;
};

export type BuildingConnection = {
    __typename?: 'BuildingConnection';
    pageInfo: PageInfo;
    edges: Array<BuildingEdge>;
};

export type BuildingEdge = {
    __typename?: 'BuildingEdge';
    cursor: Scalars['String'];
    node: Building;
};

export type Route = {
    __typename?: 'Route';
    description?: Maybe<Scalars['String']>;
    employees?: Maybe<Array<Employee>>;
    endBuilding: Building;
    id: Scalars['ID'];
    operationDate: Scalars['DateTime'];
    routeNumber: Scalars['Int'];
    startBuilding: Building;
    status: RouteStatus;
    stops?: Maybe<Array<RouteStop>>;
    vehicles?: Maybe<Array<Vehicle>>;
};

export type Location = {
    __typename?: 'Location';
    barcode: Scalars['String'];
    building: Building;
    description: Scalars['String'];
    id: Scalars['ID'];
    itemsToSort?: Maybe<Scalars['Int']>;
    sortedItems?: Maybe<Scalars['Int']>;
    type?: Maybe<LocationType>;
};

export type Customer = {
    __typename?: 'Customer';
    addresses: CustomerAddressConnection;
    cellPhone?: Maybe<Scalars['String']>;
    email: Scalars['String'];
    firstName: Scalars['String'];
    homePhone?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    lastName: Scalars['String'];
    paymentMethods: PaymentMethodConnection;
    storageContainers: StorageContainerConnection;
    storageItems: StorageItemConnection;
    type: Scalars['String'];
    visits: VisitConnection;
};

export type CustomerAddressesArgs = {
    filter: FilterInput;
};

export type CustomerPaymentMethodsArgs = {
    filter: FilterInput;
};

export type CustomerStorageContainersArgs = {
    filter: FilterInput;
    sort?: Maybe<Array<Maybe<OrderBy>>>;
};

export type CustomerStorageItemsArgs = {
    filter: FilterInput;
    sort?: Maybe<Array<Maybe<OrderBy>>>;
};

export type CustomerVisitsArgs = {
    filter: FilterInput;
};

export type CustomerConnection = {
    __typename?: 'CustomerConnection';
    pageInfo: PageInfo;
    edges: Array<CustomerEdge>;
};

export type CustomerEdge = {
    __typename?: 'CustomerEdge';
    cursor: Scalars['String'];
    node: Customer;
};

export type Visit = {
    __typename?: 'Visit';
    completedDate?: Maybe<Scalars['DateTime']>;
    customer: Customer;
    customerAddress: CustomerAddress;
    customerSignature?: Maybe<Scalars['String']>;
    endTime?: Maybe<Scalars['DateTime']>;
    expectedDuration?: Maybe<Scalars['Float']>;
    id: Scalars['ID'];
    locations: Array<Location>;
    requests: RequestConnection;
    scheduleWindow: ScheduleWindow;
    startTime?: Maybe<Scalars['DateTime']>;
    status: VisitStatus;
    stop?: Maybe<RouteStop>;
    summary: VisitSummary;
    territory: Territory;
};

export type VisitRequestsArgs = {
    filter: FilterInput;
};

export type PickupStorageItemRequest = Request & {
    __typename?: 'PickupStorageItemRequest';
    container?: Maybe<StorageContainer>;
    customer: Customer;
    id: Scalars['ID'];
    requestType: RequestType;
    status: RequestStatus;
    storageItem?: Maybe<StorageItem>;
};

export type DeliverStorageItemRequest = Request & {
    __typename?: 'DeliverStorageItemRequest';
    customer: Customer;
    id: Scalars['ID'];
    requestType: RequestType;
    sortLocation?: Maybe<Array<Location>>;
    status: RequestStatus;
    storageItem?: Maybe<StorageItem>;
};

export type DeliverEmptyContainerRequest = Request & {
    __typename?: 'DeliverEmptyContainerRequest';
    container?: Maybe<StorageContainer>;
    customer: Customer;
    id: Scalars['ID'];
    requestType: RequestType;
    status: RequestStatus;
    storageItem?: Maybe<StorageItem>;
};

export type PickupEmptyContainerRequest = Request & {
    __typename?: 'PickupEmptyContainerRequest';
    container?: Maybe<StorageContainer>;
    customer: Customer;
    id: Scalars['ID'];
    requestType: RequestType;
    status: RequestStatus;
    storageItem?: Maybe<StorageItem>;
};

export type DriverPickupEmptyContainerRequest = Request & {
    __typename?: 'DriverPickupEmptyContainerRequest';
    customer: Customer;
    id: Scalars['ID'];
    requestType: RequestType;
    status: RequestStatus;
    storageItem?: Maybe<StorageItem>;
};

export type StorageContainer = {
    __typename?: 'StorageContainer';
    assignedStorageItem?: Maybe<StorageItem>;
    barcode?: Maybe<Scalars['String']>;
    containerType: StorageContainerType;
    customer?: Maybe<Customer>;
    id: Scalars['ID'];
    status: StorageContainerStatus;
};

export type CustomerAddress = {
    __typename?: 'CustomerAddress';
    address1: Scalars['String'];
    address2?: Maybe<Scalars['String']>;
    addressType?: Maybe<CustomerAddressType>;
    city?: Maybe<Scalars['String']>;
    countryId?: Maybe<Scalars['String']>;
    firstName?: Maybe<Scalars['String']>;
    id: Scalars['String'];
    lastName?: Maybe<Scalars['String']>;
    latitude?: Maybe<Scalars['Float']>;
    longitude?: Maybe<Scalars['Float']>;
    phone?: Maybe<Scalars['String']>;
    territory: Territory;
    visits: VisitConnection;
    zip?: Maybe<Scalars['String']>;
    zoneId?: Maybe<Scalars['String']>;
};

export type CustomerAddressVisitsArgs = {
    filter: FilterInput;
};

export enum CustomerAddressType {
    Billing = 'BILLING',
    Delivery = 'DELIVERY',
}

export type CustomerAddressConnection = {
    __typename?: 'CustomerAddressConnection';
    pageInfo: PageInfo;
    edges: Array<CustomerAddressEdge>;
};

export type CustomerAddressEdge = {
    __typename?: 'CustomerAddressEdge';
    cursor: Scalars['String'];
    node: CustomerAddress;
};

export type PaymentMethod = {
    __typename?: 'PaymentMethod';
    id: Scalars['ID'];
    description: Scalars['String'];
    isDefault?: Maybe<Scalars['Boolean']>;
    order: Scalars['Int'];
};

export type PaymentMethodConnection = {
    __typename?: 'PaymentMethodConnection';
    pageInfo: PageInfo;
    edges: Array<PaymentMethodEdge>;
};

export type PaymentMethodEdge = {
    __typename?: 'PaymentMethodEdge';
    cursor: Scalars['String'];
    node: PaymentMethod;
};

export type Employee = {
    __typename?: 'Employee';
    address: Address;
    cellPhone?: Maybe<Scalars['String']>;
    email: Scalars['String'];
    endDate?: Maybe<Scalars['DateTime']>;
    firstName: Scalars['String'];
    homePhone?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    lastName: Scalars['String'];
    startDate?: Maybe<Scalars['DateTime']>;
    type: Scalars['String'];
    vehicles?: Maybe<Array<Vehicle>>;
    workPhone?: Maybe<Scalars['String']>;
};

export type PickList = {
    __typename?: 'PickList';
    created_at?: Maybe<Scalars['DateTime']>;
    description: Scalars['String'];
    endTime?: Maybe<Scalars['DateTime']>;
    id: Scalars['ID'];
    items?: Maybe<PickListItemConnection>;
    locations?: Maybe<Array<LocationFilter>>;
    maxQuantity?: Maybe<Scalars['Int']>;
    pickListNumber: Scalars['Int'];
    pickers?: Maybe<Array<Employee>>;
    startTime?: Maybe<Scalars['DateTime']>;
    status: PickListStatus;
    storageItemTypes?: Maybe<Array<StorageItemType>>;
    summary: PickListSummary;
    visitEndTime?: Maybe<Scalars['DateTime']>;
    visitStartTime?: Maybe<Scalars['DateTime']>;
    visits?: Maybe<Array<Visit>>;
};

export type PickListItemsArgs = {
    filter: FilterInput;
    sort?: Maybe<Array<Maybe<OrderBy>>>;
};

export type EmployeeConnection = {
    __typename?: 'EmployeeConnection';
    pageInfo: PageInfo;
    edges: Array<EmployeeEdge>;
};

export type EmployeeEdge = {
    __typename?: 'EmployeeEdge';
    cursor: Scalars['String'];
    node: Employee;
};

export enum LocationType {
    Bin = 'BIN',
    Storage = 'STORAGE',
    Incoming = 'INCOMING',
}

export type StorageItem = {
    __typename?: 'StorageItem';
    barcode?: Maybe<Scalars['String']>;
    container?: Maybe<StorageContainer>;
    id: Scalars['ID'];
    latestVisit?: Maybe<Visit>;
    location?: Maybe<Location>;
    metadataValue?: Maybe<Array<StorageItemMetadataValue>>;
    requests?: Maybe<Array<Request>>;
    status: StorageItemStatus;
    storageItemType: StorageItemType;
    visits: VisitConnection;
};

export type StorageItemRequestsArgs = {
    filter: FilterInput;
};

export type StorageItemVisitsArgs = {
    filter: FilterInput;
};

export type LocationConnection = {
    __typename?: 'LocationConnection';
    pageInfo: PageInfo;
    edges: Array<LocationEdge>;
};

export type LocationEdge = {
    __typename?: 'LocationEdge';
    cursor: Scalars['String'];
    node: Location;
};

export enum PickListStatus {
    Created = 'CREATED',
    InProgress = 'IN_PROGRESS',
    Completed = 'COMPLETED',
    Cancelled = 'CANCELLED',
}

export type PickListSummary = {
    __typename?: 'PickListSummary';
    toBePicked: Scalars['Int'];
    picked: Scalars['Int'];
    excluded: Scalars['Int'];
};

export type LocationFilter = {
    __typename?: 'LocationFilter';
    from?: Maybe<Scalars['String']>;
    to?: Maybe<Scalars['String']>;
};

export type PickListConnection = {
    __typename?: 'PickListConnection';
    pageInfo: PageInfo;
    edges: Array<PickListEdge>;
};

export type PickListEdge = {
    __typename?: 'PickListEdge';
    cursor: Scalars['String'];
    node: PickList;
};

export type PickListItem = {
    __typename?: 'PickListItem';
    id: Scalars['ID'];
    storageItem: StorageItem;
    request?: Maybe<Request>;
};

export type PickListItemConnection = {
    __typename?: 'PickListItemConnection';
    pageInfo: PageInfo;
    edges: Array<PickListItemtEdge>;
};

export type PickListItemtEdge = {
    __typename?: 'PickListItemtEdge';
    cursor: Scalars['String'];
    node: PickListItem;
};

export type PickingListSummary = {
    __typename?: 'PickingListSummary';
    storageItemType: StorageItemType;
    listTotalItems: Scalars['Float'];
    warehouseTotalItems: Scalars['Float'];
};

export type PickingListSummaryFilterInput = {
    visitStartTime?: Maybe<Scalars['DateTime']>;
    visitEndTime?: Maybe<Scalars['DateTime']>;
    locations?: Maybe<Array<PickingCreateListLocationFilterInput>>;
    storageItemTypeIds?: Maybe<Array<Scalars['String']>>;
    maxQuantity?: Maybe<Scalars['Int']>;
    visitIds?: Maybe<Array<Scalars['String']>>;
};

export type PickingListSummaryPayload = {
    __typename?: 'PickingListSummaryPayload';
    data?: Maybe<Array<PickingListSummary>>;
};

export type Provider = {
    __typename?: 'Provider';
    name: Scalars['String'];
    description: Scalars['String'];
    customUrl?: Maybe<Scalars['String']>;
    logo?: Maybe<Scalars['String']>;
    color?: Maybe<Scalars['String']>;
    customerUserPool?: Maybe<Scalars['String']>;
    employeeUserPool?: Maybe<Scalars['String']>;
};

export type RefileItem = {
    __typename?: 'RefileItem';
    id: Scalars['ID'];
    status?: Maybe<RefileItemStatus>;
    storageItem: StorageItem;
};

export enum RefileItemStatus {
    Created = 'CREATED',
    Refiled = 'REFILED',
}

export type RefileList = {
    __typename?: 'RefileList';
    created_at?: Maybe<Scalars['DateTime']>;
    endTime?: Maybe<Scalars['DateTime']>;
    id: Scalars['ID'];
    items?: Maybe<Array<RefileItem>>;
    refileListNumber: Scalars['Int'];
    startTime?: Maybe<Scalars['DateTime']>;
    status: RefileListStatus;
};

export enum RefileListStatus {
    Created = 'CREATED',
    InProgress = 'IN_PROGRESS',
    Completed = 'COMPLETED',
    Cancelled = 'CANCELLED',
}

export type RefileListConnection = {
    __typename?: 'RefileListConnection';
    pageInfo: PageInfo;
    edges: Array<RefileListEdge>;
};

export type RefileListEdge = {
    __typename?: 'RefileListEdge';
    cursor: Scalars['String'];
    node: RefileList;
};

export enum RequestStatus {
    Created = 'CREATED',
    InPicklist = 'IN_PICKLIST',
    PickingProcessed = 'PICKING_PROCESSED',
    Excluded = 'EXCLUDED',
    InTransitToCustomer = 'IN_TRANSIT_TO_CUSTOMER',
    InTransitToWarehouse = 'IN_TRANSIT_TO_WAREHOUSE',
    Completed = 'COMPLETED',
    Cancelled = 'CANCELLED',
    Sorted = 'SORTED',
    Processed = 'PROCESSED',
    PickingCompleted = 'PICKING_COMPLETED',
}

export type Request = {
    id: Scalars['ID'];
    status: RequestStatus;
    storageItem?: Maybe<StorageItem>;
    requestType: RequestType;
};

export type RequestConnection = {
    __typename?: 'RequestConnection';
    pageInfo: PageInfo;
    edges: Array<RequestEdge>;
};

export type RequestEdge = {
    __typename?: 'RequestEdge';
    cursor: Scalars['String'];
    node: Request;
};

export enum RequestType {
    DeliverStorageItem = 'DELIVER_STORAGE_ITEM',
    PickupStorageItem = 'PICKUP_STORAGE_ITEM',
    PickupEmptyContainer = 'PICKUP_EMPTY_CONTAINER',
    DeliverEmptyContainer = 'DELIVER_EMPTY_CONTAINER',
    DriverPickupEmptyContainer = 'DRIVER_PICKUP_EMPTY_CONTAINER',
}

export enum RouteStatus {
    Created = 'CREATED',
    InProgress = 'IN_PROGRESS',
    Completed = 'COMPLETED',
    Cancelled = 'CANCELLED',
    InTransit = 'IN_TRANSIT',
}

export type RouteConnection = {
    __typename?: 'RouteConnection';
    pageInfo: PageInfo;
    edges: Array<RouteEdge>;
};

export type RouteEdge = {
    __typename?: 'RouteEdge';
    cursor: Scalars['String'];
    node: Route;
};

export type RouteStop = {
    __typename?: 'RouteStop';
    endTime: Scalars['DateTime'];
    id: Scalars['ID'];
    route: Route;
    sequence?: Maybe<Scalars['Int']>;
    startTime: Scalars['DateTime'];
    visit: Visit;
};

export type ScheduleTemplateWindow = {
    __typename?: 'ScheduleTemplateWindow';
    id: Scalars['String'];
    startTime: Scalars['String'];
    endTime: Scalars['String'];
    week: Scalars['Int'];
    day: Scalars['Int'];
};

export type ScheduleTemplateWeek = {
    __typename?: 'ScheduleTemplateWeek';
    monday: Array<ScheduleTemplateWindow>;
    tuesday: Array<ScheduleTemplateWindow>;
    wednesday: Array<ScheduleTemplateWindow>;
    thursday: Array<ScheduleTemplateWindow>;
    friday: Array<ScheduleTemplateWindow>;
    saturday: Array<ScheduleTemplateWindow>;
    sunday: Array<ScheduleTemplateWindow>;
};

export type ScheduleWindow = {
    __typename?: 'ScheduleWindow';
    id: Scalars['ID'];
    startTime: Scalars['DateTime'];
    endTime: Scalars['DateTime'];
    isLocked: Scalars['Boolean'];
};

export type ServiceProvider = {
    __typename?: 'ServiceProvider';
    cognitoHostedDomain?: Maybe<Scalars['String']>;
    cognitoRegion: Scalars['String'];
    cognitoSocialSignInCallback?: Maybe<Scalars['String']>;
    cognitoSocialSignOutCallback?: Maybe<Scalars['String']>;
    cognitoWebApplicationId?: Maybe<Scalars['String']>;
    customerUserPool: Scalars['String'];
    employeeUserPool: Scalars['String'];
    id: Scalars['ID'];
    name: Scalars['String'];
    storageItemCatgories?: Maybe<Array<StorageItemTypeCategory>>;
    storageItemTypes: StorageItemTypeConnection;
    territories: TerritoryConnection;
    theme?: Maybe<Scalars['String']>;
};

export type ServiceProviderStorageItemTypesArgs = {
    filter: FilterInput;
    sort?: Maybe<Array<Maybe<OrderBy>>>;
};

export type ServiceProviderTerritoriesArgs = {
    filter?: Maybe<FilterInput>;
};

export enum StorageContainerStatus {
    Available = 'AVAILABLE',
    InUse = 'IN_USE',
    Retired = 'RETIRED',
}

export type StorageContainerConnection = {
    __typename?: 'StorageContainerConnection';
    pageInfo: PageInfo;
    edges: Array<StorageContainerEdge>;
};

export type StorageContainerEdge = {
    __typename?: 'StorageContainerEdge';
    cursor: Scalars['String'];
    node: StorageContainer;
};

export type StorageContainerType = {
    __typename?: 'StorageContainerType';
    id: Scalars['ID'];
    description: Scalars['String'];
};

export type StorageItemType = {
    __typename?: 'StorageItemType';
    category: StorageItemTypeCategory;
    containerType?: Maybe<StorageContainerType>;
    description: Scalars['String'];
    id: Scalars['ID'];
    image: Scalars['String'];
    maximumRequestQuantity: Scalars['Int'];
    metadataDefinitions?: Maybe<Array<StorageItemMetadataDefinition>>;
    multiplePeople: Scalars['Boolean'];
    name: Scalars['String'];
};

export enum StorageItemStatus {
    Created = 'CREATED',
    InTransit = 'IN_TRANSIT',
    AtCustomerLocation = 'AT_CUSTOMER_LOCATION',
    InStorage = 'IN_STORAGE',
    Archived = 'ARCHIVED',
    Lost = 'LOST',
}

export type StorageItemConnection = {
    __typename?: 'StorageItemConnection';
    pageInfo: PageInfo;
    edges: Array<StorageItemEdge>;
};

export type StorageItemEdge = {
    __typename?: 'StorageItemEdge';
    cursor: Scalars['String'];
    node: StorageItem;
};

export enum PorpertyType {
    StringSingleLine = 'STRING_SINGLE_LINE',
    StringMultiLine = 'STRING_MULTI_LINE',
    ListDropdown = 'LIST_DROPDOWN',
    ListCheckbox = 'LIST_CHECKBOX',
    ListRadio = 'LIST_RADIO',
    Boolean = 'BOOLEAN',
    NumberInteger = 'NUMBER_INTEGER',
    NumberDecimal = 'NUMBER_DECIMAL',
}

export type StorageItemMetadataDefinition = {
    __typename?: 'StorageItemMetadataDefinition';
    id: Scalars['ID'];
    propertyName: Scalars['String'];
    isRequired: Scalars['Boolean'];
    propertyType?: Maybe<PorpertyType>;
    values?: Maybe<Array<Scalars['String']>>;
    isActive: Scalars['Boolean'];
    sortOrder: Scalars['Int'];
};

export type StorageItemMetadataValue = {
    __typename?: 'StorageItemMetadataValue';
    id: Scalars['ID'];
    value?: Maybe<Scalars['String']>;
    metadataDefinition?: Maybe<StorageItemMetadataDefinition>;
};

export type StorageItemTypeConnection = {
    __typename?: 'StorageItemTypeConnection';
    pageInfo: PageInfo;
    edges: Array<StorageItemTypeEdge>;
};

export type StorageItemTypeEdge = {
    __typename?: 'StorageItemTypeEdge';
    cursor: Scalars['String'];
    node: StorageItemType;
};

export type StorageItemTypeCategory = {
    __typename?: 'StorageItemTypeCategory';
    id: Scalars['ID'];
    name: Scalars['String'];
    parentId?: Maybe<Scalars['String']>;
    parentCategory?: Maybe<StorageItemTypeCategory>;
    subCategories?: Maybe<Array<StorageItemTypeCategory>>;
    types?: Maybe<Array<StorageItemType>>;
};

export type TerritoryConnection = {
    __typename?: 'TerritoryConnection';
    pageInfo: PageInfo;
    edges: Array<TerritoryEdge>;
};

export type TerritoryEdge = {
    __typename?: 'TerritoryEdge';
    cursor: Scalars['String'];
    node: Territory;
};

export type User = Customer | Employee;

export type Vehicle = {
    __typename?: 'Vehicle';
    id: Scalars['ID'];
    created_at: Scalars['DateTime'];
    description: Scalars['String'];
    model?: Maybe<Scalars['String']>;
    manufacture_year: Scalars['Int'];
    vin?: Maybe<Scalars['String']>;
    image?: Maybe<Scalars['String']>;
    isActive: Scalars['Boolean'];
};

export type VehicleConnection = {
    __typename?: 'VehicleConnection';
    pageInfo: PageInfo;
    edges: Array<VehicleEdge>;
};

export type VehicleEdge = {
    __typename?: 'VehicleEdge';
    cursor: Scalars['String'];
    node: Vehicle;
};

export enum VisitStatus {
    Pending = 'PENDING',
    Created = 'CREATED',
    Reviewed = 'REVIEWED',
    InTransit = 'IN_TRANSIT',
    Completed = 'COMPLETED',
    Cancelled = 'CANCELLED',
    BeingPicked = 'BEING_PICKED',
    PickingProcessed = 'PICKING_PROCESSED',
}

export enum VisitType {
    Incoming = 'INCOMING',
    Outgoing = 'OUTGOING',
}

export type VisitSummary = {
    __typename?: 'VisitSummary';
    inComing: Scalars['Int'];
    outGoing: Scalars['Int'];
    multiplePeople: Scalars['Boolean'];
    firstVisit: Scalars['Boolean'];
};

export type VisitConnection = {
    __typename?: 'VisitConnection';
    pageInfo: PageInfo;
    edges: Array<VisitEdge>;
};

export type VisitEdge = {
    __typename?: 'VisitEdge';
    cursor: Scalars['String'];
    node: Visit;
};

export type VisitFilterInput = {
    otherFilter?: Maybe<FilterInput>;
    sorts?: Maybe<Array<OrderBy>>;
    fromDate?: Maybe<Scalars['DateTime']>;
    toDate?: Maybe<Scalars['DateTime']>;
    territoryId?: Maybe<Scalars['String']>;
    areaId?: Maybe<Scalars['String']>;
};

export type ApplicationContextQueryVariables = Exact<{ [key: string]: never }>;

export type ApplicationContextQuery = { __typename?: 'Query' } & {
    serviceProvider?: Maybe<
        { __typename?: 'ServiceProvider' } & Pick<
            ServiceProvider,
            | 'name'
            | 'employeeUserPool'
            | 'cognitoRegion'
            | 'cognitoWebApplicationId'
            | 'cognitoHostedDomain'
            | 'cognitoSocialSignInCallback'
            | 'cognitoSocialSignOutCallback'
        >
    >;
};

export type AdminAreaDetailFragment = { __typename?: 'Area' } & Pick<Area, 'id' | 'code'>;

export type GetAreaByIdQueryVariables = Exact<{
    areaId: Scalars['String'];
}>;

export type GetAreaByIdQuery = { __typename?: 'Query' } & {
    areaById?: Maybe<{ __typename?: 'Area' } & AdminAreaDetailFragment>;
};

export type AdminUpdateAreaMutationVariables = Exact<{
    data: AdminUpdateAreaInput;
}>;

export type AdminUpdateAreaMutation = { __typename?: 'Mutation' } & {
    adminUpdateArea: { __typename?: 'AdminUpdateAreaPayload' } & {
        data: { __typename?: 'Area' } & Pick<Area, 'id' | 'code'>;
    };
};

export type AdminAreaFragment = { __typename?: 'Area' } & Pick<Area, 'id' | 'code'>;

export type GetAreasQueryVariables = Exact<{ [key: string]: never }>;

export type GetAreasQuery = { __typename?: 'Query' } & {
    areas?: Maybe<Array<{ __typename?: 'Area' } & AdminAreaFragment>>;
};

export type AdminBuildingAreaFragment = { __typename?: 'Area' } & Pick<Area, 'id' | 'code'>;

export type GetAdminBuildingAreasQueryVariables = Exact<{ [key: string]: never }>;

export type GetAdminBuildingAreasQuery = { __typename?: 'Query' } & {
    areas?: Maybe<Array<{ __typename?: 'Area' } & AdminBuildingAreaFragment>>;
};

export type AdminAddBuildingMutationVariables = Exact<{
    data: AdminAddBuildingInput;
}>;

export type AdminAddBuildingMutation = { __typename?: 'Mutation' } & {
    adminAddBuilding: { __typename?: 'AdminAddBuildingPayload' } & {
        data: { __typename?: 'Building' } & Pick<
            Building,
            'id' | 'code' | 'description' | 'isActive'
        > & {
                address: { __typename?: 'Address' } & Pick<
                    Address,
                    | 'id'
                    | 'address1'
                    | 'address2'
                    | 'city'
                    | 'zoneId'
                    | 'countryId'
                    | 'zip'
                    | 'longitude'
                    | 'latitude'
                    | 'phone'
                    | 'firstName'
                    | 'lastName'
                >;
                area: { __typename?: 'Area' } & Pick<Area, 'id' | 'code'>;
            };
    };
};

export type AdminBuildingDetailFragment = { __typename?: 'Building' } & Pick<
    Building,
    'id' | 'code' | 'description' | 'isActive'
> & {
        address: { __typename?: 'Address' } & Pick<
            Address,
            | 'id'
            | 'address1'
            | 'address2'
            | 'city'
            | 'zoneId'
            | 'countryId'
            | 'zip'
            | 'longitude'
            | 'latitude'
            | 'phone'
            | 'firstName'
            | 'lastName'
        >;
        area: { __typename?: 'Area' } & Pick<Area, 'id' | 'code'>;
    };

export type GetAdminBuildingByIdQueryVariables = Exact<{
    buildingId: Scalars['String'];
}>;

export type GetAdminBuildingByIdQuery = { __typename?: 'Query' } & {
    buildingById?: Maybe<{ __typename?: 'Building' } & AdminBuildingDetailFragment>;
};

export type AdminUpdateBuildingMutationVariables = Exact<{
    data: AdminUpdateBuildingInput;
}>;

export type AdminUpdateBuildingMutation = { __typename?: 'Mutation' } & {
    adminUpdateBuilding: { __typename?: 'AdminUpdateBuildingPayload' } & {
        data: { __typename?: 'Building' } & Pick<Building, 'id'> & AdminBuildingDetailFragment;
    };
};

export type AdminBuildingFragment = { __typename?: 'Building' } & Pick<
    Building,
    'id' | 'code' | 'description'
> & {
        area: { __typename?: 'Area' } & Pick<Area, 'id' | 'code'>;
        address: { __typename?: 'Address' } & Pick<
            Address,
            | 'id'
            | 'address1'
            | 'address2'
            | 'city'
            | 'zoneId'
            | 'countryId'
            | 'zip'
            | 'longitude'
            | 'latitude'
            | 'phone'
            | 'firstName'
            | 'lastName'
        >;
    };

export type GetAdminBuildingsQueryVariables = Exact<{
    pageSize: Scalars['Int'];
    cursor: Scalars['String'];
}>;

export type GetAdminBuildingsQuery = { __typename?: 'Query' } & {
    buildings: { __typename?: 'BuildingConnection' } & {
        edges: Array<
            { __typename?: 'BuildingEdge' } & Pick<BuildingEdge, 'cursor'> & {
                    node: { __typename?: 'Building' } & Pick<Building, 'id'> &
                        AdminBuildingFragment;
                }
        >;
        pageInfo: { __typename?: 'PageInfo' } & Pick<
            PageInfo,
            'hasNextPage' | 'hasPreviousPage'
        > & {
                cursor: { __typename?: 'PageCursor' } & Pick<
                    PageCursor,
                    'beforeCursor' | 'afterCursor'
                >;
            };
    };
};

export type AdminAddEmployeeMutationVariables = Exact<{
    data: AdminAddEmployeeInput;
}>;

export type AdminAddEmployeeMutation = { __typename?: 'Mutation' } & {
    adminAddEmployee: { __typename?: 'AdminAddEmployeePayload' } & {
        data: { __typename?: 'Employee' } & Pick<Employee, 'id'>;
    };
};

export type AdminEmployeeDetailFragment = { __typename?: 'Employee' } & Pick<
    Employee,
    | 'id'
    | 'firstName'
    | 'lastName'
    | 'email'
    | 'homePhone'
    | 'cellPhone'
    | 'workPhone'
    | 'startDate'
    | 'endDate'
    | 'type'
> & {
        address: { __typename?: 'Address' } & Pick<
            Address,
            | 'id'
            | 'address1'
            | 'address2'
            | 'city'
            | 'zoneId'
            | 'countryId'
            | 'zip'
            | 'longitude'
            | 'latitude'
        >;
    };

export type EmployeeDetailGetEmployeeByIdQueryVariables = Exact<{
    employeeId: Scalars['String'];
}>;

export type EmployeeDetailGetEmployeeByIdQuery = { __typename?: 'Query' } & {
    employeeById?: Maybe<{ __typename?: 'Employee' } & AdminEmployeeDetailFragment>;
};

export type AdminUpdateEmployeeMutationVariables = Exact<{
    data: AdminUpdateEmployeeInput;
}>;

export type AdminUpdateEmployeeMutation = { __typename?: 'Mutation' } & {
    adminUpdateEmployee: { __typename?: 'AdminUpdateEmployeePayload' } & {
        data: { __typename?: 'Employee' } & Pick<Employee, 'id'>;
    };
};

export type AdminUpdateEmployeePasswordMutationVariables = Exact<{
    data: AdminUpdateEmployeePasswordInput;
}>;

export type AdminUpdateEmployeePasswordMutation = { __typename?: 'Mutation' } & {
    adminUpdateEmployeePassword: { __typename?: 'AdminUpdateEmployeePasswordPayload' } & {
        data: { __typename?: 'Employee' } & Pick<Employee, 'id'>;
    };
};

export type AdminEmployeeFragment = { __typename?: 'Employee' } & Pick<
    Employee,
    | 'id'
    | 'firstName'
    | 'lastName'
    | 'email'
    | 'homePhone'
    | 'cellPhone'
    | 'workPhone'
    | 'startDate'
    | 'endDate'
    | 'type'
> & {
        address: { __typename?: 'Address' } & Pick<
            Address,
            | 'id'
            | 'address1'
            | 'address2'
            | 'city'
            | 'zoneId'
            | 'countryId'
            | 'zip'
            | 'longitude'
            | 'latitude'
            | 'phone'
            | 'firstName'
            | 'lastName'
        >;
    };

export type GetEmployeesQueryVariables = Exact<{
    pageSize: Scalars['Int'];
    cursor: Scalars['String'];
}>;

export type GetEmployeesQuery = { __typename?: 'Query' } & {
    employees: { __typename?: 'EmployeeConnection' } & {
        edges: Array<
            { __typename?: 'EmployeeEdge' } & Pick<EmployeeEdge, 'cursor'> & {
                    node: { __typename?: 'Employee' } & Pick<Employee, 'id'> &
                        AdminEmployeeFragment;
                }
        >;
        pageInfo: { __typename?: 'PageInfo' } & Pick<
            PageInfo,
            'hasNextPage' | 'hasPreviousPage'
        > & {
                cursor: { __typename?: 'PageCursor' } & Pick<
                    PageCursor,
                    'beforeCursor' | 'afterCursor'
                >;
            };
    };
};

export type AdminAddLocationBuildingFragment = { __typename?: 'Building' } & Pick<
    Building,
    'id' | 'code' | 'description'
>;

export type GetAdminAddLocationBuildingsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAdminAddLocationBuildingsQuery = { __typename?: 'Query' } & {
    buildingsAll?: Maybe<Array<{ __typename?: 'Building' } & AdminAddLocationBuildingFragment>>;
};

export type AdminAddLocationMutationVariables = Exact<{
    data?: Maybe<Array<AdminAddLocationsInput> | AdminAddLocationsInput>;
}>;

export type AdminAddLocationMutation = { __typename?: 'Mutation' } & {
    adminAddLocation: { __typename?: 'AdminAddLocationsPayload' } & {
        data: Array<Maybe<{ __typename?: 'Location' } & Pick<Location, 'id'>>>;
    };
};

export type AdminLocationDetailBuildingFragment = { __typename?: 'Building' } & Pick<
    Building,
    'id' | 'code' | 'description'
>;

export type GetAdminLocationDetailBuildingsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAdminLocationDetailBuildingsQuery = { __typename?: 'Query' } & {
    buildingsAll?: Maybe<Array<{ __typename?: 'Building' } & AdminLocationDetailBuildingFragment>>;
};

export type AdminLocationDetailFragment = { __typename?: 'Location' } & Pick<
    Location,
    'id' | 'description' | 'barcode' | 'type'
> & { building: { __typename?: 'Building' } & Pick<Building, 'id' | 'code' | 'description'> };

export type GetAdminLocationByIdQueryVariables = Exact<{
    locationId: Scalars['String'];
}>;

export type GetAdminLocationByIdQuery = { __typename?: 'Query' } & {
    locationById?: Maybe<{ __typename?: 'Location' } & AdminLocationDetailFragment>;
};

export type AdminUpdateLocationMutationVariables = Exact<{
    data: AdminUpdateLocationInput;
}>;

export type AdminUpdateLocationMutation = { __typename?: 'Mutation' } & {
    adminUpdateLocation: { __typename?: 'AdminUpdateLocationPayload' } & {
        data: { __typename?: 'Location' } & AdminLocationDetailFragment;
    };
};

export type AdminLocationFragment = { __typename?: 'Location' } & Pick<
    Location,
    'id' | 'description' | 'barcode' | 'sortedItems' | 'itemsToSort'
>;

export type GetAdminLocationsQueryVariables = Exact<{
    pageSize: Scalars['Int'];
    afterCursor?: Maybe<Scalars['String']>;
    beforeCursor?: Maybe<Scalars['String']>;
}>;

export type GetAdminLocationsQuery = { __typename?: 'Query' } & {
    locations: { __typename?: 'LocationConnection' } & {
        edges: Array<
            { __typename?: 'LocationEdge' } & Pick<LocationEdge, 'cursor'> & {
                    node: { __typename?: 'Location' } & Pick<Location, 'id'> &
                        AdminLocationFragment;
                }
        >;
        pageInfo: { __typename?: 'PageInfo' } & Pick<
            PageInfo,
            'hasNextPage' | 'hasPreviousPage'
        > & {
                cursor: { __typename?: 'PageCursor' } & Pick<
                    PageCursor,
                    'beforeCursor' | 'afterCursor'
                >;
            };
    };
};

export type AdminScheduleTemplateAreaFragment = { __typename?: 'Area' } & Pick<Area, 'id' | 'code'>;

export type GetAdminScheduleTemplateAreasQueryVariables = Exact<{ [key: string]: never }>;

export type GetAdminScheduleTemplateAreasQuery = { __typename?: 'Query' } & {
    areas?: Maybe<Array<{ __typename?: 'Area' } & AdminScheduleTemplateAreaFragment>>;
};

export type AdminScheduleTemplatePeriodDetailFragment = {
    __typename?: 'ScheduleTemplateWindow';
} & Pick<ScheduleTemplateWindow, 'id' | 'startTime' | 'endTime' | 'week' | 'day'>;

export type AdminScheduleTemplateDetailFragment = { __typename?: 'ScheduleTemplate' } & Pick<
    ScheduleTemplate,
    'id' | 'startDate'
> & {
        weeks: Array<
            { __typename?: 'ScheduleTemplateWeek' } & {
                monday: Array<
                    { __typename?: 'ScheduleTemplateWindow' } & Pick<ScheduleTemplateWindow, 'id'> &
                        AdminScheduleTemplatePeriodDetailFragment
                >;
                tuesday: Array<
                    { __typename?: 'ScheduleTemplateWindow' } & Pick<ScheduleTemplateWindow, 'id'> &
                        AdminScheduleTemplatePeriodDetailFragment
                >;
                wednesday: Array<
                    { __typename?: 'ScheduleTemplateWindow' } & Pick<ScheduleTemplateWindow, 'id'> &
                        AdminScheduleTemplatePeriodDetailFragment
                >;
                thursday: Array<
                    { __typename?: 'ScheduleTemplateWindow' } & Pick<ScheduleTemplateWindow, 'id'> &
                        AdminScheduleTemplatePeriodDetailFragment
                >;
                friday: Array<
                    { __typename?: 'ScheduleTemplateWindow' } & Pick<ScheduleTemplateWindow, 'id'> &
                        AdminScheduleTemplatePeriodDetailFragment
                >;
                saturday: Array<
                    { __typename?: 'ScheduleTemplateWindow' } & Pick<ScheduleTemplateWindow, 'id'> &
                        AdminScheduleTemplatePeriodDetailFragment
                >;
                sunday: Array<
                    { __typename?: 'ScheduleTemplateWindow' } & Pick<ScheduleTemplateWindow, 'id'> &
                        AdminScheduleTemplatePeriodDetailFragment
                >;
            }
        >;
        area: { __typename?: 'Area' } & Pick<Area, 'id'> & AdminScheduleTemplateAreaFragment;
    };

export type GetAdminScheduleTemplateByIdQueryVariables = Exact<{
    scheduleTemplateId: Scalars['String'];
}>;

export type GetAdminScheduleTemplateByIdQuery = { __typename?: 'Query' } & {
    scheduleTemplateById?: Maybe<
        { __typename?: 'ScheduleTemplate' } & AdminScheduleTemplateDetailFragment
    >;
};

export type AdminAddScheduleTemplatePeriodMutationVariables = Exact<{
    data: AdminAddScheduleTemplatePeriodInput;
}>;

export type AdminAddScheduleTemplatePeriodMutation = { __typename?: 'Mutation' } & {
    adminAddScheduleTemplatePeriod: { __typename?: 'AdminAddScheduleTemplatePeriodPayload' } & {
        data: { __typename?: 'ScheduleTemplateWindow' } & Pick<ScheduleTemplateWindow, 'id'> &
            AdminScheduleTemplatePeriodDetailFragment;
    };
};

export type AdminUpdateScheduleTemplatePeriodMutationVariables = Exact<{
    data: AdminUpdateScheduleTemplatePeriodInput;
}>;

export type AdminUpdateScheduleTemplatePeriodMutation = { __typename?: 'Mutation' } & {
    adminUpdateScheduleTemplatePeriod: {
        __typename?: 'AdminUpdateScheduleTemplatePeriodPayload';
    } & {
        data: { __typename?: 'ScheduleTemplateWindow' } & Pick<ScheduleTemplateWindow, 'id'> &
            AdminScheduleTemplatePeriodDetailFragment;
    };
};

export type AdminUpdateScheduleTemplateMutationVariables = Exact<{
    data: AdminUpdateScheduleTemplateInput;
}>;

export type AdminUpdateScheduleTemplateMutation = { __typename?: 'Mutation' } & {
    adminUpdateScheduleTemplate: { __typename?: 'AdminUpdateScheduleTemplatePayload' } & {
        data: { __typename?: 'ScheduleTemplate' } & Pick<ScheduleTemplate, 'id'> &
            AdminScheduleTemplateDetailFragment;
    };
};

export type AdminDeleteScheduleTemplatePeriodMutationVariables = Exact<{
    data: AdminDeleteScheduleTemplatePeriodInput;
}>;

export type AdminDeleteScheduleTemplatePeriodMutation = { __typename?: 'Mutation' } & {
    adminDeleteScheduleTemplatePeriod: {
        __typename?: 'AdminDeleteScheduleTemplatePeriodPayload';
    } & {
        data: { __typename?: 'ScheduleTemplateWindow' } & Pick<ScheduleTemplateWindow, 'id'> &
            AdminScheduleTemplatePeriodDetailFragment;
    };
};

export type AdminScheduleTemplateFragment = { __typename?: 'ScheduleTemplate' } & Pick<
    ScheduleTemplate,
    'id' | 'startDate'
> & { area: { __typename?: 'Area' } & Pick<Area, 'id' | 'code'> };

export type GetAdminScheduleTemplatesQueryVariables = Exact<{ [key: string]: never }>;

export type GetAdminScheduleTemplatesQuery = { __typename?: 'Query' } & {
    scheduleTemplates?: Maybe<
        Array<{ __typename?: 'ScheduleTemplate' } & AdminScheduleTemplateFragment>
    >;
};

export type AdminAddStorageContainerTypeMutationVariables = Exact<{
    data: AdminAddStorageContainerTypeInput;
}>;

export type AdminAddStorageContainerTypeMutation = { __typename?: 'Mutation' } & {
    adminAddStorageContainerType: { __typename?: 'AdminAddStorageContainerTypePayload' } & {
        data: { __typename?: 'StorageContainerType' } & Pick<StorageContainerType, 'description'>;
    };
};

export type AdminStorageContainerTypeDetailFragment = {
    __typename?: 'StorageContainerType';
} & Pick<StorageContainerType, 'id' | 'description'>;

export type GetAdminStorageContainerTypeByIdQueryVariables = Exact<{
    containerTypeId: Scalars['String'];
}>;

export type GetAdminStorageContainerTypeByIdQuery = { __typename?: 'Query' } & {
    storageContainerTypeById?: Maybe<
        { __typename?: 'StorageContainerType' } & AdminStorageContainerTypeDetailFragment
    >;
};

export type AdminUpdateStorageContainerTypeMutationVariables = Exact<{
    data: AdminStorageContainerTypeInput;
}>;

export type AdminUpdateStorageContainerTypeMutation = { __typename?: 'Mutation' } & {
    adminUpdateStorageContainerType: { __typename?: 'AdminStorageContainerTypeload' } & {
        data: { __typename?: 'StorageContainerType' } & AdminStorageContainerTypeDetailFragment;
    };
};

export type AdminStorageContainerTypeFragment = { __typename?: 'StorageContainerType' } & Pick<
    StorageContainerType,
    'id' | 'description'
>;

export type GetAdminStorageContainerTypesQueryVariables = Exact<{ [key: string]: never }>;

export type GetAdminStorageContainerTypesQuery = { __typename?: 'Query' } & {
    storageContainerType?: Maybe<
        Array<{ __typename?: 'StorageContainerType' } & AdminStorageContainerTypeFragment>
    >;
};

export type AdminAddStorageContainerMutationVariables = Exact<{
    data: AdminAddStorageContainerInput;
}>;

export type AdminAddStorageContainerMutation = { __typename?: 'Mutation' } & {
    adminAddStorageContainer: { __typename?: 'AdminAddStorageContainerPayload' } & {
        data: { __typename?: 'StorageContainer' } & Pick<StorageContainer, 'status' | 'barcode'> & {
                containerType: { __typename?: 'StorageContainerType' } & Pick<
                    StorageContainerType,
                    'id' | 'description'
                >;
            };
    };
};

export type AdminStorageContainerDetailFragment = { __typename?: 'StorageContainer' } & Pick<
    StorageContainer,
    'id' | 'status' | 'barcode'
> & {
        containerType: { __typename?: 'StorageContainerType' } & Pick<
            StorageContainerType,
            'id' | 'description'
        >;
    };

export type GetAdminStorageContainerByIdQueryVariables = Exact<{
    containerId: Scalars['String'];
}>;

export type GetAdminStorageContainerByIdQuery = { __typename?: 'Query' } & {
    storageContainerById?: Maybe<
        { __typename?: 'StorageContainer' } & AdminStorageContainerDetailFragment
    >;
};

export type AdminUpdateStorageContainerMutationVariables = Exact<{
    data: AdminUpdateStorageContainerInput;
}>;

export type AdminUpdateStorageContainerMutation = { __typename?: 'Mutation' } & {
    adminUpdateStorageContainer: { __typename?: 'AdminUpdateStorageContainerPayload' } & {
        data: { __typename?: 'StorageContainer' } & AdminStorageContainerDetailFragment;
    };
};

export type AdminStorageContainerFragment = { __typename?: 'StorageContainer' } & Pick<
    StorageContainer,
    'id' | 'status' | 'barcode'
> & {
        containerType: { __typename?: 'StorageContainerType' } & Pick<
            StorageContainerType,
            'id' | 'description'
        >;
    };

export type GetAdminStorageContainersQueryVariables = Exact<{
    pageSize: Scalars['Int'];
    cursor: Scalars['String'];
}>;

export type GetAdminStorageContainersQuery = { __typename?: 'Query' } & {
    storageContainers: { __typename?: 'StorageContainerConnection' } & {
        edges: Array<
            { __typename?: 'StorageContainerEdge' } & Pick<StorageContainerEdge, 'cursor'> & {
                    node: { __typename?: 'StorageContainer' } & Pick<StorageContainer, 'id'> &
                        AdminStorageContainerFragment;
                }
        >;
        pageInfo: { __typename?: 'PageInfo' } & Pick<
            PageInfo,
            'hasNextPage' | 'hasPreviousPage'
        > & {
                cursor: { __typename?: 'PageCursor' } & Pick<
                    PageCursor,
                    'beforeCursor' | 'afterCursor'
                >;
            };
    };
};

export type AdminStorageItemTypeCategoryFragment = {
    __typename?: 'StorageItemTypeCategory';
} & Pick<StorageItemTypeCategory, 'id' | 'name' | 'parentId'>;

export type GetAdminStorageItemTypeCategoriesQueryVariables = Exact<{ [key: string]: never }>;

export type GetAdminStorageItemTypeCategoriesQuery = { __typename?: 'Query' } & {
    storageItemTypeCategory?: Maybe<
        Array<{ __typename?: 'StorageItemTypeCategory' } & AdminStorageItemTypeCategoryFragment>
    >;
};

export type AdminAddStorageItemTypeMutationVariables = Exact<{
    data: AdminAddStorageItemTypeInput;
}>;

export type AdminAddStorageItemTypeMutation = { __typename?: 'Mutation' } & {
    adminAddStorageItemType: { __typename?: 'AdminAddStorageItemTypePayload' } & {
        data: { __typename?: 'StorageItemType' } & Pick<
            StorageItemType,
            'id' | 'description' | 'name' | 'image' | 'maximumRequestQuantity' | 'multiplePeople'
        > & {
                category: { __typename?: 'StorageItemTypeCategory' } & Pick<
                    StorageItemTypeCategory,
                    'id' | 'name' | 'parentId'
                >;
            };
    };
};

export type UploadStorageItemTypeImageMutationVariables = Exact<{
    file: Scalars['Upload'];
}>;

export type UploadStorageItemTypeImageMutation = { __typename?: 'Mutation' } & {
    uploadStorageItemTypeImage: { __typename?: 'UploadStorageItemTypeImagePayload' } & {
        data: { __typename?: 'UploadStorageItemTypeImageResponse' } & Pick<
            UploadStorageItemTypeImageResponse,
            'url'
        >;
    };
};

export type AdminStorageItemTypeDetailFragment = { __typename?: 'StorageItemType' } & Pick<
    StorageItemType,
    'id' | 'description' | 'name' | 'image' | 'maximumRequestQuantity' | 'multiplePeople'
> & {
        category: { __typename?: 'StorageItemTypeCategory' } & Pick<
            StorageItemTypeCategory,
            'id' | 'name' | 'parentId'
        >;
    };

export type GetAdminStorageItemTypeByIdQueryVariables = Exact<{
    storageItemTypeId: Scalars['String'];
}>;

export type GetAdminStorageItemTypeByIdQuery = { __typename?: 'Query' } & {
    storageItemTypeById?: Maybe<
        { __typename?: 'StorageItemType' } & AdminStorageItemTypeDetailFragment
    >;
};

export type AdminUpdateStorageItemTypeMutationVariables = Exact<{
    data: AdminStorageItemTypeInput;
}>;

export type AdminUpdateStorageItemTypeMutation = { __typename?: 'Mutation' } & {
    adminUpdateStorageItemType: { __typename?: 'AdminUpdateStorageItemTypePayload' } & {
        data: { __typename?: 'StorageItemType' } & AdminStorageItemTypeDetailFragment;
    };
};

export type StorageItemTypeDetailUploadImageMutationVariables = Exact<{
    file: Scalars['Upload'];
}>;

export type StorageItemTypeDetailUploadImageMutation = { __typename?: 'Mutation' } & {
    uploadStorageItemTypeImage: { __typename?: 'UploadStorageItemTypeImagePayload' } & {
        data: { __typename?: 'UploadStorageItemTypeImageResponse' } & Pick<
            UploadStorageItemTypeImageResponse,
            'url'
        >;
    };
};

export type AdminStorageItemTypeFragment = { __typename?: 'StorageItemType' } & Pick<
    StorageItemType,
    'id' | 'description' | 'name' | 'image' | 'maximumRequestQuantity' | 'multiplePeople'
> & {
        category: { __typename?: 'StorageItemTypeCategory' } & Pick<
            StorageItemTypeCategory,
            'id' | 'name' | 'parentId'
        >;
    };

export type GetAdminStorageItemTypesQueryVariables = Exact<{
    pageSize: Scalars['Int'];
    cursor: Scalars['String'];
}>;

export type GetAdminStorageItemTypesQuery = { __typename?: 'Query' } & {
    storageItemTypes: { __typename?: 'StorageItemTypeConnection' } & {
        edges: Array<
            { __typename?: 'StorageItemTypeEdge' } & Pick<StorageItemTypeEdge, 'cursor'> & {
                    node: { __typename?: 'StorageItemType' } & Pick<StorageItemType, 'id'> &
                        AdminStorageItemTypeFragment;
                }
        >;
        pageInfo: { __typename?: 'PageInfo' } & Pick<
            PageInfo,
            'hasNextPage' | 'hasPreviousPage'
        > & {
                cursor: { __typename?: 'PageCursor' } & Pick<
                    PageCursor,
                    'beforeCursor' | 'afterCursor'
                >;
            };
    };
};

export type AdminAddStorageItemTypeCategoryMutationVariables = Exact<{
    data: AdminAddStorageItemTypeCategoryInput;
}>;

export type AdminAddStorageItemTypeCategoryMutation = { __typename?: 'Mutation' } & {
    adminAddStorageItemTypeCategory: { __typename?: 'AdminAddStorageItemTypeCategoryPayload' } & {
        data: { __typename?: 'StorageItemTypeCategory' } & Pick<StorageItemTypeCategory, 'name'>;
    };
};

export type AdminStorageItemTypeCategoryDetailFragment = {
    __typename?: 'StorageItemTypeCategory';
} & Pick<StorageItemTypeCategory, 'id' | 'name'>;

export type GetAdminStorageItemTypeCategoryByIdQueryVariables = Exact<{
    categoryId: Scalars['String'];
}>;

export type GetAdminStorageItemTypeCategoryByIdQuery = { __typename?: 'Query' } & {
    storageItemTypeCategoryById?: Maybe<
        { __typename?: 'StorageItemTypeCategory' } & AdminStorageItemTypeCategoryDetailFragment
    >;
};

export type AdminUpdateStorageItemTypeCategoryMutationVariables = Exact<{
    data: AdminStorageItemTypeCategoryInput;
}>;

export type AdminUpdateStorageItemTypeCategoryMutation = { __typename?: 'Mutation' } & {
    adminUpdateStorageItemTypeCategory: { __typename?: 'AdminStorageItemTypeCategoryload' } & {
        data: {
            __typename?: 'StorageItemTypeCategory';
        } & AdminStorageItemTypeCategoryDetailFragment;
    };
};

export type GetAdminStorageItemTypeCategoryQueryVariables = Exact<{ [key: string]: never }>;

export type GetAdminStorageItemTypeCategoryQuery = { __typename?: 'Query' } & {
    storageItemTypeCategory?: Maybe<
        Array<{ __typename?: 'StorageItemTypeCategory' } & AdminStorageItemTypeCategoryFragment>
    >;
};

export type AdminAddTerritoryAreaFragment = { __typename?: 'Area' } & Pick<Area, 'id' | 'code'>;

export type GetAdminAddTerritoryAreasQueryVariables = Exact<{ [key: string]: never }>;

export type GetAdminAddTerritoryAreasQuery = { __typename?: 'Query' } & {
    areas?: Maybe<Array<{ __typename?: 'Area' } & AdminTerritoryAreaFragment>>;
};

export type AdminAddTerritoryFragment = { __typename?: 'Territory' } & Pick<
    Territory,
    | 'id'
    | 'description'
    | 'maximumRequestQuantity'
    | 'deliveryCutoff'
    | 'deliveryDelay'
    | 'pickupCutoff'
    | 'pickupDelay'
    | 'zipCodes'
> & { area: { __typename?: 'Area' } & Pick<Area, 'id' | 'code'> };

export type AdminAddTerritoryMutationVariables = Exact<{
    data: AdminAddTerritoryInput;
}>;

export type AdminAddTerritoryMutation = { __typename?: 'Mutation' } & {
    adminAddTerritory: { __typename?: 'AdminAddTerritoryPayload' } & {
        data: { __typename?: 'Territory' } & AdminAddTerritoryFragment;
    };
};

export type AdminTerritoryAreaFragment = { __typename?: 'Area' } & Pick<Area, 'id' | 'code'>;

export type GetAdminTerritoryAreasQueryVariables = Exact<{ [key: string]: never }>;

export type GetAdminTerritoryAreasQuery = { __typename?: 'Query' } & {
    areas?: Maybe<Array<{ __typename?: 'Area' } & AdminTerritoryAreaFragment>>;
};

export type AdminTerritoryDetailFragment = { __typename?: 'Territory' } & Pick<
    Territory,
    | 'id'
    | 'description'
    | 'maximumRequestQuantity'
    | 'deliveryCutoff'
    | 'deliveryDelay'
    | 'pickupCutoff'
    | 'pickupDelay'
    | 'zipCodes'
> & {
        area: { __typename?: 'Area' } & Pick<Area, 'id' | 'code'> & {
                scheduleTemplate?: Maybe<
                    { __typename?: 'ScheduleTemplate' } & Pick<
                        ScheduleTemplate,
                        'id' | 'startDate'
                    > & {
                            weeks: Array<
                                { __typename?: 'ScheduleTemplateWeek' } & {
                                    monday: Array<
                                        { __typename?: 'ScheduleTemplateWindow' } & Pick<
                                            ScheduleTemplateWindow,
                                            'id' | 'startTime' | 'endTime' | 'week' | 'day'
                                        >
                                    >;
                                    tuesday: Array<
                                        { __typename?: 'ScheduleTemplateWindow' } & Pick<
                                            ScheduleTemplateWindow,
                                            'id' | 'startTime' | 'endTime' | 'week' | 'day'
                                        >
                                    >;
                                    wednesday: Array<
                                        { __typename?: 'ScheduleTemplateWindow' } & Pick<
                                            ScheduleTemplateWindow,
                                            'id' | 'startTime' | 'endTime' | 'week' | 'day'
                                        >
                                    >;
                                    thursday: Array<
                                        { __typename?: 'ScheduleTemplateWindow' } & Pick<
                                            ScheduleTemplateWindow,
                                            'id' | 'startTime' | 'endTime' | 'week' | 'day'
                                        >
                                    >;
                                    friday: Array<
                                        { __typename?: 'ScheduleTemplateWindow' } & Pick<
                                            ScheduleTemplateWindow,
                                            'id' | 'startTime' | 'endTime' | 'week' | 'day'
                                        >
                                    >;
                                    saturday: Array<
                                        { __typename?: 'ScheduleTemplateWindow' } & Pick<
                                            ScheduleTemplateWindow,
                                            'id' | 'startTime' | 'endTime' | 'week' | 'day'
                                        >
                                    >;
                                    sunday: Array<
                                        { __typename?: 'ScheduleTemplateWindow' } & Pick<
                                            ScheduleTemplateWindow,
                                            'id' | 'startTime' | 'endTime' | 'week' | 'day'
                                        >
                                    >;
                                }
                            >;
                            area: { __typename?: 'Area' } & Pick<Area, 'id' | 'code'>;
                        }
                >;
            };
        excludedDeliveryWindows?: Maybe<
            Array<
                { __typename?: 'ScheduleTemplateWindow' } & Pick<
                    ScheduleTemplateWindow,
                    'id' | 'startTime' | 'endTime' | 'week' | 'day'
                >
            >
        >;
    };

export type GetAdminTerritoryByIdQueryVariables = Exact<{
    territoryId: Scalars['String'];
}>;

export type GetAdminTerritoryByIdQuery = { __typename?: 'Query' } & {
    territoryById?: Maybe<{ __typename?: 'Territory' } & AdminTerritoryDetailFragment>;
};

export type AdminUpdateTerritoryMutationVariables = Exact<{
    data: AdminUpdateTerritoryInput;
}>;

export type AdminUpdateTerritoryMutation = { __typename?: 'Mutation' } & {
    adminUpdateTerritory: { __typename?: 'AdminUpdateTerritoryPayload' } & {
        data: { __typename?: 'Territory' } & Pick<Territory, 'id'> & AdminTerritoryDetailFragment;
    };
};

export type AdminAddPeriodToTerritoryMutationVariables = Exact<{
    data: AdminAddPeriodToTerritoryInput;
}>;

export type AdminAddPeriodToTerritoryMutation = { __typename?: 'Mutation' } & {
    adminAddPeriodToTerritory: { __typename?: 'AdminAddPeriodToTerritoryPayload' } & {
        data: { __typename?: 'ScheduleTemplateWindow' } & Pick<ScheduleTemplateWindow, 'id'>;
    };
};

export type AdminExcludePeriodFromTerritoryMutationVariables = Exact<{
    data: AdminExcludePeriodFromTerritoryInput;
}>;

export type AdminExcludePeriodFromTerritoryMutation = { __typename?: 'Mutation' } & {
    adminExcludePeriodFromTerritory: { __typename?: 'AdminExcludePeriodFromTerritoryPayload' } & {
        data: { __typename?: 'ScheduleTemplateWindow' } & Pick<ScheduleTemplateWindow, 'id'>;
    };
};

export type AdminTerritoryFragment = { __typename?: 'Territory' } & Pick<
    Territory,
    | 'id'
    | 'description'
    | 'maximumRequestQuantity'
    | 'deliveryCutoff'
    | 'deliveryDelay'
    | 'pickupCutoff'
    | 'pickupDelay'
>;

export type GetAdminTerritorysQueryVariables = Exact<{
    pageSize: Scalars['Int'];
    cursor: Scalars['String'];
}>;

export type GetAdminTerritorysQuery = { __typename?: 'Query' } & {
    territories: { __typename?: 'TerritoryConnection' } & {
        edges: Array<
            { __typename?: 'TerritoryEdge' } & Pick<TerritoryEdge, 'cursor'> & {
                    node: { __typename?: 'Territory' } & Pick<Territory, 'id'> &
                        AdminTerritoryFragment;
                }
        >;
        pageInfo: { __typename?: 'PageInfo' } & Pick<
            PageInfo,
            'hasNextPage' | 'hasPreviousPage'
        > & {
                cursor: { __typename?: 'PageCursor' } & Pick<
                    PageCursor,
                    'beforeCursor' | 'afterCursor'
                >;
            };
    };
};

export type AdminAddVehicleMutationVariables = Exact<{
    data: AdminAddVehicleInput;
}>;

export type AdminAddVehicleMutation = { __typename?: 'Mutation' } & {
    adminAddVehicle: { __typename?: 'AdminAddVehiclePayload' } & {
        data: { __typename?: 'Vehicle' } & Pick<
            Vehicle,
            | 'id'
            | 'created_at'
            | 'description'
            | 'model'
            | 'manufacture_year'
            | 'vin'
            | 'image'
            | 'isActive'
        >;
    };
};

export type AdminVehicleDetailFragment = { __typename?: 'Vehicle' } & Pick<
    Vehicle,
    | 'id'
    | 'created_at'
    | 'description'
    | 'model'
    | 'manufacture_year'
    | 'vin'
    | 'image'
    | 'isActive'
>;

export type GetAdminVehicleByIdQueryVariables = Exact<{
    vehicleId: Scalars['String'];
}>;

export type GetAdminVehicleByIdQuery = { __typename?: 'Query' } & {
    vehicleById?: Maybe<{ __typename?: 'Vehicle' } & AdminVehicleDetailFragment>;
};

export type AdminUpdateVehicleMutationVariables = Exact<{
    data: AdminUpdateVehicleInput;
}>;

export type AdminUpdateVehicleMutation = { __typename?: 'Mutation' } & {
    adminUpdateVehicle: { __typename?: 'AdminUpdateVehiclePayload' } & {
        data: { __typename?: 'Vehicle' } & AdminVehicleDetailFragment;
    };
};

export type AdminVehicleFragment = { __typename?: 'Vehicle' } & Pick<
    Vehicle,
    'id' | 'description' | 'model' | 'isActive'
>;

export type GetAdminVehiclesQueryVariables = Exact<{
    pageSize: Scalars['Int'];
    cursor: Scalars['String'];
}>;

export type GetAdminVehiclesQuery = { __typename?: 'Query' } & {
    vehicles: { __typename?: 'VehicleConnection' } & {
        edges: Array<
            { __typename?: 'VehicleEdge' } & Pick<VehicleEdge, 'cursor'> & {
                    node: { __typename?: 'Vehicle' } & Pick<Vehicle, 'id'> & AdminVehicleFragment;
                }
        >;
        pageInfo: { __typename?: 'PageInfo' } & Pick<
            PageInfo,
            'hasNextPage' | 'hasPreviousPage'
        > & {
                cursor: { __typename?: 'PageCursor' } & Pick<
                    PageCursor,
                    'beforeCursor' | 'afterCursor'
                >;
            };
    };
};

export type RetrieveEmployeeScheduleWindowsQueryVariables = Exact<{
    zipCode: Scalars['String'];
    startDate: Scalars['DateTime'];
    endDate: Scalars['DateTime'];
}>;

export type RetrieveEmployeeScheduleWindowsQuery = { __typename?: 'Query' } & {
    territoryByZipCode?: Maybe<
        { __typename?: 'Territory' } & {
            schedule?: Maybe<
                Array<
                    { __typename?: 'ScheduleWindow' } & Pick<
                        ScheduleWindow,
                        'startTime' | 'endTime' | 'isLocked'
                    >
                >
            >;
        }
    >;
};

export type EmployeeVisitManagementFragment = { __typename?: 'Visit' } & Pick<
    Visit,
    'id' | 'status' | 'startTime' | 'endTime' | 'expectedDuration'
>;

export type RetrieveAllVisitsQueryVariables = Exact<{
    pageSize?: Maybe<Scalars['Int']>;
    visitStatus: Scalars['String'];
    afterCursor?: Maybe<Scalars['String']>;
    beforeCursor?: Maybe<Scalars['String']>;
}>;

export type RetrieveAllVisitsQuery = { __typename?: 'Query' } & {
    visits: { __typename?: 'VisitConnection' } & {
        edges: Array<
            { __typename?: 'VisitEdge' } & Pick<VisitEdge, 'cursor'> & {
                    node: { __typename?: 'Visit' } & Pick<Visit, 'id'> &
                        EmployeeVisitManagementFragment;
                }
        >;
        pageInfo: { __typename?: 'PageInfo' } & Pick<
            PageInfo,
            'hasNextPage' | 'hasPreviousPage'
        > & {
                cursor: { __typename?: 'PageCursor' } & Pick<
                    PageCursor,
                    'beforeCursor' | 'afterCursor'
                >;
            };
    };
};

export type VisitManagementDetailEmployeeFragment = { __typename?: 'Visit' } & Pick<
    Visit,
    'id' | 'status' | 'startTime' | 'endTime' | 'expectedDuration'
> & { customerAddress: { __typename?: 'CustomerAddress' } & Pick<CustomerAddress, 'zip'> };

export type GetVisitManagementByIdQueryVariables = Exact<{
    visitId: Scalars['String'];
}>;

export type GetVisitManagementByIdQuery = { __typename?: 'Query' } & {
    visitById?: Maybe<{ __typename?: 'Visit' } & VisitManagementDetailEmployeeFragment>;
};

export type UpdateVisitManagementMutationVariables = Exact<{
    data: VisitUpdateWindowInput;
}>;

export type UpdateVisitManagementMutation = { __typename?: 'Mutation' } & {
    visitUpdateWindow: { __typename?: 'VisitUpdateWindowPayload' } & {
        data: { __typename?: 'Visit' } & VisitManagementDetailEmployeeFragment;
    };
};

export type VisitCancelVisitMutationVariables = Exact<{
    data: VisitCancelVisitInput;
}>;

export type VisitCancelVisitMutation = { __typename?: 'Mutation' } & {
    visitCancelVisit: { __typename?: 'VisitCancelVisitPayload' } & {
        data: { __typename?: 'Visit' } & VisitManagementDetailEmployeeFragment;
    };
};

export type GetEmployeeQueryVariables = Exact<{ [key: string]: never }>;

export type GetEmployeeQuery = { __typename?: 'Query' } & {
    me?: Maybe<
        | { __typename?: 'Customer' }
        | ({ __typename?: 'Employee' } & Pick<Employee, 'id' | 'firstName' | 'lastName'>)
    >;
};

export type DeliveryCompleteDeliverStorageItemRequestFragment = {
    __typename?: 'DeliverStorageItemRequest';
} & Pick<DeliverStorageItemRequest, 'id' | 'status' | 'requestType'> & {
        storageItem?: Maybe<
            { __typename?: 'StorageItem' } & Pick<StorageItem, 'id' | 'status' | 'barcode'> & {
                    storageItemType: { __typename?: 'StorageItemType' } & Pick<
                        StorageItemType,
                        'id' | 'name' | 'description' | 'image'
                    >;
                }
        >;
        customer: { __typename?: 'Customer' } & Pick<
            Customer,
            'email' | 'firstName' | 'lastName' | 'homePhone' | 'cellPhone'
        >;
    };

export type DeliveryCompletePickupStorageItemRequestFragment = {
    __typename?: 'PickupStorageItemRequest';
} & Pick<PickupStorageItemRequest, 'id' | 'status' | 'requestType'> & {
        storageItem?: Maybe<
            { __typename?: 'StorageItem' } & Pick<StorageItem, 'id' | 'status' | 'barcode'> & {
                    storageItemType: { __typename?: 'StorageItemType' } & Pick<
                        StorageItemType,
                        'id' | 'name' | 'description' | 'image'
                    >;
                }
        >;
        customer: { __typename?: 'Customer' } & Pick<
            Customer,
            'email' | 'firstName' | 'lastName' | 'homePhone' | 'cellPhone'
        >;
    };

export type DeliveryCompleteDeliverEmptyContainerRequestFragment = {
    __typename?: 'DeliverEmptyContainerRequest';
} & Pick<DeliverEmptyContainerRequest, 'id' | 'status' | 'requestType'> & {
        storageItem?: Maybe<
            { __typename?: 'StorageItem' } & Pick<StorageItem, 'id' | 'status' | 'barcode'> & {
                    storageItemType: { __typename?: 'StorageItemType' } & Pick<
                        StorageItemType,
                        'id' | 'name' | 'description' | 'image'
                    > & {
                            containerType?: Maybe<
                                { __typename?: 'StorageContainerType' } & Pick<
                                    StorageContainerType,
                                    'id' | 'description'
                                >
                            >;
                        };
                }
        >;
        customer: { __typename?: 'Customer' } & Pick<
            Customer,
            'email' | 'firstName' | 'lastName' | 'homePhone' | 'cellPhone'
        >;
    };

export type DeliveryCompletePickupEmptyContainerRequestFragment = {
    __typename?: 'PickupEmptyContainerRequest';
} & Pick<PickupEmptyContainerRequest, 'id' | 'status' | 'requestType'> & {
        storageItem?: Maybe<
            { __typename?: 'StorageItem' } & Pick<StorageItem, 'id' | 'status' | 'barcode'> & {
                    storageItemType: { __typename?: 'StorageItemType' } & Pick<
                        StorageItemType,
                        'id' | 'name' | 'description' | 'image'
                    >;
                }
        >;
        customer: { __typename?: 'Customer' } & Pick<
            Customer,
            'email' | 'firstName' | 'lastName' | 'homePhone' | 'cellPhone'
        >;
    };

export type DeliveryCompleteDriverPickupEmptyContainerRequestFragment = {
    __typename?: 'DriverPickupEmptyContainerRequest';
} & Pick<DriverPickupEmptyContainerRequest, 'id' | 'status' | 'requestType'> & {
        storageItem?: Maybe<
            { __typename?: 'StorageItem' } & Pick<StorageItem, 'id' | 'status' | 'barcode'> & {
                    storageItemType: { __typename?: 'StorageItemType' } & Pick<
                        StorageItemType,
                        'id' | 'name' | 'description' | 'image'
                    >;
                }
        >;
        customer: { __typename?: 'Customer' } & Pick<
            Customer,
            'email' | 'firstName' | 'lastName' | 'homePhone' | 'cellPhone'
        >;
    };

export type RequestsByVisitIdDeliveryCompleteQueryVariables = Exact<{
    visitId: Scalars['String'];
    pageSize: Scalars['Int'];
    cursor: Scalars['String'];
}>;

export type RequestsByVisitIdDeliveryCompleteQuery = { __typename?: 'Query' } & {
    requestsByVisitId: { __typename?: 'RequestConnection' } & {
        edges: Array<
            { __typename?: 'RequestEdge' } & Pick<RequestEdge, 'cursor'> & {
                    node:
                        | ({ __typename: 'PickupStorageItemRequest' } & Pick<
                              PickupStorageItemRequest,
                              'id' | 'status' | 'requestType'
                          > &
                              DeliveryCompletePickupStorageItemRequestFragment)
                        | ({ __typename: 'DeliverStorageItemRequest' } & Pick<
                              DeliverStorageItemRequest,
                              'id' | 'status' | 'requestType'
                          > &
                              DeliveryCompleteDeliverStorageItemRequestFragment)
                        | ({ __typename: 'DeliverEmptyContainerRequest' } & Pick<
                              DeliverEmptyContainerRequest,
                              'id' | 'status' | 'requestType'
                          > &
                              DeliveryCompleteDeliverEmptyContainerRequestFragment)
                        | ({ __typename: 'PickupEmptyContainerRequest' } & Pick<
                              PickupEmptyContainerRequest,
                              'id' | 'status' | 'requestType'
                          > &
                              DeliveryCompletePickupEmptyContainerRequestFragment)
                        | ({ __typename: 'DriverPickupEmptyContainerRequest' } & Pick<
                              DriverPickupEmptyContainerRequest,
                              'id' | 'status' | 'requestType'
                          > &
                              DeliveryCompleteDriverPickupEmptyContainerRequestFragment);
                }
        >;
        pageInfo: { __typename?: 'PageInfo' } & Pick<
            PageInfo,
            'hasNextPage' | 'hasPreviousPage'
        > & {
                cursor: { __typename?: 'PageCursor' } & Pick<
                    PageCursor,
                    'afterCursor' | 'beforeCursor'
                >;
            };
    };
};

export type DeliveryCompleteVisitMutationVariables = Exact<{
    data: DeliveryCompleteVisitInput;
}>;

export type DeliveryCompleteVisitMutation = { __typename?: 'Mutation' } & {
    deliveryCompleteVisit: { __typename?: 'DeliveryCompleteVisitPayload' } & {
        data: { __typename?: 'Visit' } & Pick<Visit, 'id' | 'status'>;
    };
};

export type DeliverEmptyContainerRequestsByVisitIdStorageItemFragment = {
    __typename?: 'StorageItem';
} & Pick<StorageItem, 'id' | 'status' | 'barcode'> & {
        storageItemType: { __typename?: 'StorageItemType' } & Pick<
            StorageItemType,
            'id' | 'name' | 'description' | 'image'
        > & {
                metadataDefinitions?: Maybe<
                    Array<
                        { __typename?: 'StorageItemMetadataDefinition' } & Pick<
                            StorageItemMetadataDefinition,
                            | 'id'
                            | 'propertyName'
                            | 'isRequired'
                            | 'propertyType'
                            | 'values'
                            | 'isActive'
                            | 'sortOrder'
                        >
                    >
                >;
            };
        metadataValue?: Maybe<
            Array<
                { __typename?: 'StorageItemMetadataValue' } & Pick<
                    StorageItemMetadataValue,
                    'value'
                > & {
                        metadataDefinition?: Maybe<
                            { __typename?: 'StorageItemMetadataDefinition' } & Pick<
                                StorageItemMetadataDefinition,
                                'id' | 'propertyName'
                            >
                        >;
                    }
            >
        >;
    };

export type DeliverEmptyContainerRequestsByVisitIdDeliverRequestFragment = {
    __typename?: 'DeliverEmptyContainerRequest';
} & Pick<DeliverEmptyContainerRequest, 'id' | 'status' | 'requestType'> & {
        container?: Maybe<
            { __typename?: 'StorageContainer' } & Pick<
                StorageContainer,
                'id' | 'status' | 'barcode'
            >
        >;
        storageItem?: Maybe<
            { __typename?: 'StorageItem' } & Pick<StorageItem, 'id'> &
                DeliverEmptyContainerRequestsByVisitIdStorageItemFragment
        >;
        customer: { __typename?: 'Customer' } & Pick<
            Customer,
            'email' | 'firstName' | 'lastName' | 'homePhone' | 'cellPhone'
        >;
    };

export type DeliverEmptyContainerRequestsByVisitIdQueryVariables = Exact<{
    visitId: Scalars['String'];
    pageSize: Scalars['Int'];
    cursor: Scalars['String'];
}>;

export type DeliverEmptyContainerRequestsByVisitIdQuery = { __typename?: 'Query' } & {
    requestsByVisitId: { __typename?: 'RequestConnection' } & {
        edges: Array<
            { __typename?: 'RequestEdge' } & Pick<RequestEdge, 'cursor'> & {
                    node:
                        | ({ __typename?: 'PickupStorageItemRequest' } & Pick<
                              PickupStorageItemRequest,
                              'id' | 'requestType'
                          >)
                        | ({ __typename?: 'DeliverStorageItemRequest' } & Pick<
                              DeliverStorageItemRequest,
                              'id' | 'requestType'
                          >)
                        | ({ __typename?: 'DeliverEmptyContainerRequest' } & Pick<
                              DeliverEmptyContainerRequest,
                              'id' | 'requestType'
                          > &
                              DeliverEmptyContainerRequestsByVisitIdDeliverRequestFragment)
                        | ({ __typename?: 'PickupEmptyContainerRequest' } & Pick<
                              PickupEmptyContainerRequest,
                              'id' | 'requestType'
                          >)
                        | ({ __typename?: 'DriverPickupEmptyContainerRequest' } & Pick<
                              DriverPickupEmptyContainerRequest,
                              'id' | 'requestType'
                          >);
                }
        >;
        pageInfo: { __typename?: 'PageInfo' } & Pick<
            PageInfo,
            'hasNextPage' | 'hasPreviousPage'
        > & {
                cursor: { __typename?: 'PageCursor' } & Pick<
                    PageCursor,
                    'beforeCursor' | 'afterCursor'
                >;
            };
    };
};

export type DeliveryDeliverEmptyContainerMutationVariables = Exact<{
    data: DeliveryDeliverEmptyContainerInput;
}>;

export type DeliveryDeliverEmptyContainerMutation = { __typename?: 'Mutation' } & {
    deliveryDeliverEmptyContainer: { __typename?: 'DeliveryDeliverEmptyContainerPayload' } & {
        data?: Maybe<
            { __typename?: 'DeliverEmptyContainerRequest' } & Pick<
                DeliverEmptyContainerRequest,
                'id' | 'status'
            >
        >;
    };
};

export type DeliveryDeliverProcessDeliverStorageItemRequestFragment = {
    __typename?: 'DeliverStorageItemRequest';
} & Pick<DeliverStorageItemRequest, 'id' | 'status' | 'requestType'> & {
        storageItem?: Maybe<
            { __typename?: 'StorageItem' } & Pick<StorageItem, 'id' | 'status' | 'barcode'> & {
                    storageItemType: { __typename?: 'StorageItemType' } & Pick<
                        StorageItemType,
                        'id' | 'name' | 'description' | 'image'
                    >;
                }
        >;
        customer: { __typename?: 'Customer' } & Pick<
            Customer,
            'email' | 'firstName' | 'lastName' | 'homePhone' | 'cellPhone'
        >;
    };

export type DeliveryRequestsByVisitIdQueryVariables = Exact<{
    visitId: Scalars['String'];
    pageSize: Scalars['Int'];
    cursor: Scalars['String'];
}>;

export type DeliveryRequestsByVisitIdQuery = { __typename?: 'Query' } & {
    requestsByVisitId: { __typename?: 'RequestConnection' } & {
        edges: Array<
            { __typename?: 'RequestEdge' } & Pick<RequestEdge, 'cursor'> & {
                    node:
                        | ({ __typename?: 'PickupStorageItemRequest' } & Pick<
                              PickupStorageItemRequest,
                              'id' | 'status' | 'requestType'
                          >)
                        | ({ __typename?: 'DeliverStorageItemRequest' } & Pick<
                              DeliverStorageItemRequest,
                              'id' | 'status' | 'requestType'
                          > &
                              DeliveryDeliverProcessDeliverStorageItemRequestFragment)
                        | ({ __typename?: 'DeliverEmptyContainerRequest' } & Pick<
                              DeliverEmptyContainerRequest,
                              'id' | 'status' | 'requestType'
                          >)
                        | ({ __typename?: 'PickupEmptyContainerRequest' } & Pick<
                              PickupEmptyContainerRequest,
                              'id' | 'status' | 'requestType'
                          >)
                        | ({ __typename?: 'DriverPickupEmptyContainerRequest' } & Pick<
                              DriverPickupEmptyContainerRequest,
                              'id' | 'status' | 'requestType'
                          >);
                }
        >;
        pageInfo: { __typename?: 'PageInfo' } & Pick<
            PageInfo,
            'hasNextPage' | 'hasPreviousPage'
        > & {
                cursor: { __typename?: 'PageCursor' } & Pick<
                    PageCursor,
                    'afterCursor' | 'beforeCursor'
                >;
            };
    };
};

export type DeliveryDeliverItemMutationVariables = Exact<{
    data: DeliveryDeliverItemInput;
}>;

export type DeliveryDeliverItemMutation = { __typename?: 'Mutation' } & {
    deliveryDeliverItem: { __typename?: 'DeliveryDeliverItemPayload' } & {
        data?: Maybe<
            { __typename?: 'DeliverStorageItemRequest' } & Pick<
                DeliverStorageItemRequest,
                'id' | 'status'
            >
        >;
    };
};

export type PickupEmptyContainerRequestsByVisitIdStorageItemFragment = {
    __typename?: 'StorageItem';
} & Pick<StorageItem, 'id' | 'status' | 'barcode'> & {
        storageItemType: { __typename?: 'StorageItemType' } & Pick<
            StorageItemType,
            'id' | 'name' | 'description' | 'image'
        > & {
                metadataDefinitions?: Maybe<
                    Array<
                        { __typename?: 'StorageItemMetadataDefinition' } & Pick<
                            StorageItemMetadataDefinition,
                            | 'id'
                            | 'propertyName'
                            | 'isRequired'
                            | 'propertyType'
                            | 'values'
                            | 'isActive'
                            | 'sortOrder'
                        >
                    >
                >;
            };
        metadataValue?: Maybe<
            Array<
                { __typename?: 'StorageItemMetadataValue' } & Pick<
                    StorageItemMetadataValue,
                    'value'
                > & {
                        metadataDefinition?: Maybe<
                            { __typename?: 'StorageItemMetadataDefinition' } & Pick<
                                StorageItemMetadataDefinition,
                                'id' | 'propertyName'
                            >
                        >;
                    }
            >
        >;
    };

export type PickupEmptyContainerRequestsByVisitIdDeliverRequestFragment = {
    __typename?: 'DeliverEmptyContainerRequest';
} & Pick<DeliverEmptyContainerRequest, 'id' | 'status' | 'requestType'> & {
        container?: Maybe<
            { __typename?: 'StorageContainer' } & Pick<
                StorageContainer,
                'id' | 'status' | 'barcode'
            >
        >;
        storageItem?: Maybe<
            { __typename?: 'StorageItem' } & Pick<StorageItem, 'id'> &
                PickupEmptyContainerRequestsByVisitIdStorageItemFragment
        >;
        customer: { __typename?: 'Customer' } & Pick<
            Customer,
            'email' | 'firstName' | 'lastName' | 'homePhone' | 'cellPhone'
        >;
    };

export type PickupEmptyContainerRequestsByVisitIdQueryVariables = Exact<{
    visitId: Scalars['String'];
    pageSize: Scalars['Int'];
    cursor: Scalars['String'];
}>;

export type PickupEmptyContainerRequestsByVisitIdQuery = { __typename?: 'Query' } & {
    requestsByVisitId: { __typename?: 'RequestConnection' } & {
        edges: Array<
            { __typename?: 'RequestEdge' } & Pick<RequestEdge, 'cursor'> & {
                    node:
                        | ({ __typename?: 'PickupStorageItemRequest' } & Pick<
                              PickupStorageItemRequest,
                              'id' | 'requestType'
                          >)
                        | ({ __typename?: 'DeliverStorageItemRequest' } & Pick<
                              DeliverStorageItemRequest,
                              'id' | 'requestType'
                          >)
                        | ({ __typename?: 'DeliverEmptyContainerRequest' } & Pick<
                              DeliverEmptyContainerRequest,
                              'id' | 'requestType'
                          > &
                              PickupEmptyContainerRequestsByVisitIdDeliverRequestFragment)
                        | ({ __typename?: 'PickupEmptyContainerRequest' } & Pick<
                              PickupEmptyContainerRequest,
                              'id' | 'requestType'
                          >)
                        | ({ __typename?: 'DriverPickupEmptyContainerRequest' } & Pick<
                              DriverPickupEmptyContainerRequest,
                              'id' | 'requestType'
                          >);
                }
        >;
        pageInfo: { __typename?: 'PageInfo' } & Pick<
            PageInfo,
            'hasNextPage' | 'hasPreviousPage'
        > & {
                cursor: { __typename?: 'PageCursor' } & Pick<
                    PageCursor,
                    'beforeCursor' | 'afterCursor'
                >;
            };
    };
};

export type DeliveryPickupEmptyContainerMutationVariables = Exact<{
    data: DeliveryPickupEmptyContainerInput;
}>;

export type DeliveryPickupEmptyContainerMutation = { __typename?: 'Mutation' } & {
    deliveryPickupEmptyContainer: { __typename?: 'DeliveryPickupEmptyContainerPayload' } & {
        data?: Maybe<
            { __typename?: 'PickupEmptyContainerRequest' } & Pick<
                PickupEmptyContainerRequest,
                'id' | 'status'
            >
        >;
    };
};

export type RetrieveStorageItemQueryVariables = Exact<{
    requestId: Scalars['String'];
}>;

export type RetrieveStorageItemQuery = { __typename?: 'Query' } & {
    requestById?: Maybe<
        | ({ __typename?: 'PickupStorageItemRequest' } & Pick<PickupStorageItemRequest, 'id'> & {
                  container?: Maybe<
                      { __typename?: 'StorageContainer' } & Pick<StorageContainer, 'id' | 'barcode'>
                  >;
                  storageItem?: Maybe<
                      { __typename?: 'StorageItem' } & Pick<
                          StorageItem,
                          'id' | 'status' | 'barcode'
                      > & {
                              container?: Maybe<
                                  { __typename?: 'StorageContainer' } & Pick<
                                      StorageContainer,
                                      'id' | 'barcode'
                                  >
                              >;
                              storageItemType: { __typename?: 'StorageItemType' } & Pick<
                                  StorageItemType,
                                  'id'
                              > & {
                                      metadataDefinitions?: Maybe<
                                          Array<
                                              {
                                                  __typename?: 'StorageItemMetadataDefinition';
                                              } & Pick<
                                                  StorageItemMetadataDefinition,
                                                  | 'id'
                                                  | 'propertyName'
                                                  | 'isRequired'
                                                  | 'propertyType'
                                                  | 'values'
                                                  | 'isActive'
                                                  | 'sortOrder'
                                              >
                                          >
                                      >;
                                      containerType?: Maybe<
                                          { __typename?: 'StorageContainerType' } & Pick<
                                              StorageContainerType,
                                              'id' | 'description'
                                          >
                                      >;
                                  };
                              metadataValue?: Maybe<
                                  Array<
                                      { __typename?: 'StorageItemMetadataValue' } & Pick<
                                          StorageItemMetadataValue,
                                          'value'
                                      > & {
                                              metadataDefinition?: Maybe<
                                                  {
                                                      __typename?: 'StorageItemMetadataDefinition';
                                                  } & Pick<
                                                      StorageItemMetadataDefinition,
                                                      'id' | 'propertyName'
                                                  >
                                              >;
                                          }
                                  >
                              >;
                          }
                  >;
              })
        | ({ __typename?: 'DeliverStorageItemRequest' } & Pick<DeliverStorageItemRequest, 'id'>)
        | ({ __typename?: 'DeliverEmptyContainerRequest' } & Pick<
              DeliverEmptyContainerRequest,
              'id'
          >)
        | ({ __typename?: 'PickupEmptyContainerRequest' } & Pick<PickupEmptyContainerRequest, 'id'>)
        | ({ __typename?: 'DriverPickupEmptyContainerRequest' } & Pick<
              DriverPickupEmptyContainerRequest,
              'id'
          >)
    >;
};

export type SetStorageItemMetadataMutationVariables = Exact<{
    data: SetStorageItemMetadataInput;
}>;

export type SetStorageItemMetadataMutation = { __typename?: 'Mutation' } & {
    setStorageItemMetadata: { __typename?: 'SetStorageItemMetadataPayload' } & {
        values?: Maybe<
            Array<
                { __typename?: 'StorageItemMetadataValue' } & Pick<
                    StorageItemMetadataValue,
                    'value'
                >
            >
        >;
    };
};

export type StorageItemAssignBarcodeMutationVariables = Exact<{
    data: StorageItemAssignBarcodeInput;
}>;

export type StorageItemAssignBarcodeMutation = { __typename?: 'Mutation' } & {
    storageItemAssignBarcode: { __typename?: 'StorageItemAssignBarcodePayload' } & {
        data?: Maybe<{ __typename?: 'StorageItem' } & Pick<StorageItem, 'id' | 'barcode'>>;
    };
};

export type RequestAssignContainerMutationVariables = Exact<{
    data: RequestAssignContainerInput;
}>;

export type RequestAssignContainerMutation = { __typename?: 'Mutation' } & {
    requestAssignContainer: { __typename?: 'RequestAssignContainerPayload' } & {
        data?: Maybe<
            | ({ __typename?: 'PickupStorageItemRequest' } & Pick<PickupStorageItemRequest, 'id'>)
            | ({ __typename?: 'DeliverStorageItemRequest' } & Pick<DeliverStorageItemRequest, 'id'>)
            | ({ __typename?: 'DeliverEmptyContainerRequest' } & Pick<
                  DeliverEmptyContainerRequest,
                  'id'
              >)
            | ({ __typename?: 'PickupEmptyContainerRequest' } & Pick<
                  PickupEmptyContainerRequest,
                  'id'
              >)
            | ({ __typename?: 'DriverPickupEmptyContainerRequest' } & Pick<
                  DriverPickupEmptyContainerRequest,
                  'id'
              >)
        >;
    };
};

export type PickupRequestsByVisitIdStorageItemFragment = { __typename?: 'StorageItem' } & Pick<
    StorageItem,
    'id' | 'status' | 'barcode'
> & {
        container?: Maybe<
            { __typename?: 'StorageContainer' } & Pick<StorageContainer, 'id' | 'barcode'>
        >;
        storageItemType: { __typename?: 'StorageItemType' } & Pick<
            StorageItemType,
            'id' | 'name' | 'description' | 'image'
        > & {
                containerType?: Maybe<
                    { __typename?: 'StorageContainerType' } & Pick<StorageContainerType, 'id'>
                >;
                metadataDefinitions?: Maybe<
                    Array<
                        { __typename?: 'StorageItemMetadataDefinition' } & Pick<
                            StorageItemMetadataDefinition,
                            | 'id'
                            | 'propertyName'
                            | 'isRequired'
                            | 'propertyType'
                            | 'values'
                            | 'isActive'
                            | 'sortOrder'
                        >
                    >
                >;
            };
        metadataValue?: Maybe<
            Array<
                { __typename?: 'StorageItemMetadataValue' } & Pick<
                    StorageItemMetadataValue,
                    'value'
                > & {
                        metadataDefinition?: Maybe<
                            { __typename?: 'StorageItemMetadataDefinition' } & Pick<
                                StorageItemMetadataDefinition,
                                'id' | 'propertyName'
                            >
                        >;
                    }
            >
        >;
    };

export type PickupRequestsByVisitIdPickupRequestFragment = {
    __typename?: 'PickupStorageItemRequest';
} & Pick<PickupStorageItemRequest, 'id' | 'status' | 'requestType'> & {
        container?: Maybe<
            { __typename?: 'StorageContainer' } & Pick<
                StorageContainer,
                'id' | 'status' | 'barcode'
            >
        >;
        storageItem?: Maybe<
            { __typename?: 'StorageItem' } & Pick<StorageItem, 'id'> &
                PickupRequestsByVisitIdStorageItemFragment
        >;
        customer: { __typename?: 'Customer' } & Pick<
            Customer,
            'email' | 'firstName' | 'lastName' | 'homePhone' | 'cellPhone'
        >;
    };

export type PickupRequestsByVisitIdQueryVariables = Exact<{
    visitId: Scalars['String'];
    pageSize: Scalars['Int'];
    cursor: Scalars['String'];
}>;

export type PickupRequestsByVisitIdQuery = { __typename?: 'Query' } & {
    requestsByVisitId: { __typename?: 'RequestConnection' } & {
        edges: Array<
            { __typename?: 'RequestEdge' } & Pick<RequestEdge, 'cursor'> & {
                    node:
                        | ({ __typename?: 'PickupStorageItemRequest' } & Pick<
                              PickupStorageItemRequest,
                              'id' | 'requestType'
                          > &
                              PickupRequestsByVisitIdPickupRequestFragment)
                        | ({ __typename?: 'DeliverStorageItemRequest' } & Pick<
                              DeliverStorageItemRequest,
                              'id' | 'requestType'
                          >)
                        | ({ __typename?: 'DeliverEmptyContainerRequest' } & Pick<
                              DeliverEmptyContainerRequest,
                              'id' | 'requestType'
                          >)
                        | ({ __typename?: 'PickupEmptyContainerRequest' } & Pick<
                              PickupEmptyContainerRequest,
                              'id' | 'requestType'
                          >)
                        | ({ __typename?: 'DriverPickupEmptyContainerRequest' } & Pick<
                              DriverPickupEmptyContainerRequest,
                              'id' | 'requestType'
                          >);
                }
        >;
        pageInfo: { __typename?: 'PageInfo' } & Pick<
            PageInfo,
            'hasNextPage' | 'hasPreviousPage'
        > & {
                cursor: { __typename?: 'PageCursor' } & Pick<
                    PageCursor,
                    'beforeCursor' | 'afterCursor'
                >;
            };
    };
};

export type DeliveryPickupItemMutationVariables = Exact<{
    data: DeliveryPickupItemInput;
}>;

export type DeliveryPickupItemMutation = { __typename?: 'Mutation' } & {
    deliveryPickupItem: { __typename?: 'DeliveryPickupItemPayload' } & {
        data?: Maybe<
            { __typename?: 'PickupStorageItemRequest' } & Pick<
                PickupStorageItemRequest,
                'id' | 'status'
            >
        >;
    };
};

export type DeliveryRouteDetailRouteStopFragment = { __typename?: 'RouteStop' } & Pick<
    RouteStop,
    'id' | 'startTime' | 'endTime' | 'sequence'
> & {
        visit: { __typename?: 'Visit' } & Pick<
            Visit,
            'id' | 'status' | 'expectedDuration' | 'startTime' | 'endTime'
        > & {
                summary: { __typename?: 'VisitSummary' } & Pick<
                    VisitSummary,
                    'inComing' | 'outGoing' | 'multiplePeople' | 'firstVisit'
                >;
                customerAddress: { __typename?: 'CustomerAddress' } & Pick<
                    CustomerAddress,
                    'address1' | 'city' | 'countryId' | 'firstName' | 'lastName'
                >;
                scheduleWindow: { __typename?: 'ScheduleWindow' } & Pick<
                    ScheduleWindow,
                    'startTime' | 'endTime'
                >;
                customer: { __typename?: 'Customer' } & Pick<
                    Customer,
                    'email' | 'firstName' | 'lastName' | 'homePhone' | 'cellPhone'
                >;
                requests: { __typename?: 'RequestConnection' } & {
                    edges: Array<
                        { __typename?: 'RequestEdge' } & Pick<RequestEdge, 'cursor'> & {
                                node:
                                    | ({ __typename?: 'PickupStorageItemRequest' } & Pick<
                                          PickupStorageItemRequest,
                                          'id' | 'status'
                                      >)
                                    | ({ __typename?: 'DeliverStorageItemRequest' } & Pick<
                                          DeliverStorageItemRequest,
                                          'id' | 'status'
                                      >)
                                    | ({ __typename?: 'DeliverEmptyContainerRequest' } & Pick<
                                          DeliverEmptyContainerRequest,
                                          'id' | 'status'
                                      >)
                                    | ({ __typename?: 'PickupEmptyContainerRequest' } & Pick<
                                          PickupEmptyContainerRequest,
                                          'id' | 'status'
                                      >)
                                    | ({ __typename?: 'DriverPickupEmptyContainerRequest' } & Pick<
                                          DriverPickupEmptyContainerRequest,
                                          'id' | 'status'
                                      >);
                            }
                    >;
                };
            };
    };

export type GetRouteDetailsQueryVariables = Exact<{
    routeId: Scalars['String'];
}>;

export type GetRouteDetailsQuery = { __typename?: 'Query' } & {
    routes: { __typename?: 'RouteConnection' } & {
        edges: Array<
            { __typename?: 'RouteEdge' } & Pick<RouteEdge, 'cursor'> & {
                    node: { __typename?: 'Route' } & Pick<
                        Route,
                        'id' | 'description' | 'routeNumber' | 'status' | 'operationDate'
                    > & {
                            employees?: Maybe<
                                Array<
                                    { __typename?: 'Employee' } & Pick<
                                        Employee,
                                        'id' | 'firstName' | 'lastName'
                                    >
                                >
                            >;
                            stops?: Maybe<
                                Array<
                                    {
                                        __typename?: 'RouteStop';
                                    } & DeliveryRouteDetailRouteStopFragment
                                >
                            >;
                        };
                }
        >;
        pageInfo: { __typename?: 'PageInfo' } & Pick<
            PageInfo,
            'hasNextPage' | 'hasPreviousPage'
        > & {
                cursor: { __typename?: 'PageCursor' } & Pick<
                    PageCursor,
                    'beforeCursor' | 'afterCursor'
                >;
            };
    };
};

export type DeliveryRoutesRouteFragment = { __typename?: 'Route' } & Pick<
    Route,
    'id' | 'description' | 'routeNumber' | 'status' | 'operationDate'
> & {
        employees?: Maybe<
            Array<{ __typename?: 'Employee' } & Pick<Employee, 'id' | 'firstName' | 'lastName'>>
        >;
        vehicles?: Maybe<Array<{ __typename?: 'Vehicle' } & Pick<Vehicle, 'description'>>>;
    };

export type GetRoutesQueryVariables = Exact<{
    startDate: Scalars['String'];
    endDate: Scalars['String'];
    pageSize: Scalars['Int'];
    cursor: Scalars['String'];
}>;

export type GetRoutesQuery = { __typename?: 'Query' } & {
    routes: { __typename?: 'RouteConnection' } & {
        edges: Array<
            { __typename?: 'RouteEdge' } & Pick<RouteEdge, 'cursor'> & {
                    node: { __typename?: 'Route' } & Pick<Route, 'id'> &
                        DeliveryRoutesRouteFragment;
                }
        >;
        pageInfo: { __typename?: 'PageInfo' } & Pick<
            PageInfo,
            'hasNextPage' | 'hasPreviousPage'
        > & {
                cursor: { __typename?: 'PageCursor' } & Pick<
                    PageCursor,
                    'beforeCursor' | 'afterCursor'
                >;
            };
    };
};

export type DeliverVisitDetailsQueryVariables = Exact<{
    visitId: Scalars['String'];
}>;

export type DeliverVisitDetailsQuery = { __typename?: 'Query' } & {
    visitById?: Maybe<
        { __typename?: 'Visit' } & Pick<
            Visit,
            'id' | 'status' | 'expectedDuration' | 'startTime' | 'endTime'
        > & {
                summary: { __typename?: 'VisitSummary' } & Pick<
                    VisitSummary,
                    'inComing' | 'outGoing' | 'multiplePeople' | 'firstVisit'
                >;
                customerAddress: { __typename?: 'CustomerAddress' } & Pick<
                    CustomerAddress,
                    'address1' | 'city' | 'countryId' | 'firstName' | 'lastName'
                >;
                scheduleWindow: { __typename?: 'ScheduleWindow' } & Pick<
                    ScheduleWindow,
                    'startTime' | 'endTime'
                >;
                customer: { __typename?: 'Customer' } & Pick<
                    Customer,
                    'email' | 'firstName' | 'lastName' | 'homePhone' | 'cellPhone'
                >;
                requests: { __typename?: 'RequestConnection' } & {
                    edges: Array<
                        { __typename?: 'RequestEdge' } & Pick<RequestEdge, 'cursor'> & {
                                node:
                                    | ({ __typename?: 'PickupStorageItemRequest' } & Pick<
                                          PickupStorageItemRequest,
                                          'id' | 'status' | 'requestType'
                                      >)
                                    | ({ __typename?: 'DeliverStorageItemRequest' } & Pick<
                                          DeliverStorageItemRequest,
                                          'id' | 'status' | 'requestType'
                                      >)
                                    | ({ __typename?: 'DeliverEmptyContainerRequest' } & Pick<
                                          DeliverEmptyContainerRequest,
                                          'id' | 'status' | 'requestType'
                                      >)
                                    | ({ __typename?: 'PickupEmptyContainerRequest' } & Pick<
                                          PickupEmptyContainerRequest,
                                          'id' | 'status' | 'requestType'
                                      >)
                                    | ({ __typename?: 'DriverPickupEmptyContainerRequest' } & Pick<
                                          DriverPickupEmptyContainerRequest,
                                          'id' | 'status' | 'requestType'
                                      >);
                            }
                    >;
                };
                stop?: Maybe<{ __typename?: 'RouteStop' } & Pick<RouteStop, 'sequence'>>;
            }
    >;
};

export type HomeQueryVariables = Exact<{ [key: string]: never }>;

export type HomeQuery = { __typename?: 'Query' } & {
    serviceProvider?: Maybe<
        { __typename?: 'ServiceProvider' } & Pick<
            ServiceProvider,
            | 'id'
            | 'name'
            | 'customerUserPool'
            | 'employeeUserPool'
            | 'cognitoRegion'
            | 'cognitoWebApplicationId'
            | 'theme'
        >
    >;
};

export type MovingProcessItemStorageItemFragment = { __typename?: 'StorageItem' } & Pick<
    StorageItem,
    'id' | 'status' | 'barcode'
> & {
        container?: Maybe<
            { __typename?: 'StorageContainer' } & Pick<StorageContainer, 'id' | 'barcode'>
        >;
        storageItemType: { __typename?: 'StorageItemType' } & Pick<
            StorageItemType,
            'id' | 'name' | 'description' | 'image'
        >;
        location?: Maybe<{ __typename?: 'Location' } & Pick<Location, 'description' | 'barcode'>>;
    };

export type MovingProcessItemMutationVariables = Exact<{
    data: MovingProcessItemInput;
}>;

export type MovingProcessItemMutation = { __typename?: 'Mutation' } & {
    movingProcessItem: { __typename?: 'MovingProcessItemPayload' } & {
        data: { __typename?: 'StorageItem' } & Pick<StorageItem, 'id'> &
            MovingProcessItemStorageItemFragment;
    };
};

export type MovingProcessItemLocationFragment = { __typename?: 'Location' } & Pick<
    Location,
    'id' | 'barcode' | 'description'
>;

export type MovingLocationByBarcodeQueryVariables = Exact<{
    barcode: Scalars['String'];
}>;

export type MovingLocationByBarcodeQuery = { __typename?: 'Query' } & {
    locationByBarcode?: Maybe<
        { __typename?: 'Location' } & Pick<Location, 'id'> & MovingProcessItemLocationFragment
    >;
};

export type GetPickListSummaryQueryVariables = Exact<{
    filterInput: PickingListSummaryFilterInput;
}>;

export type GetPickListSummaryQuery = { __typename?: 'Query' } & {
    pickingListSummary: { __typename?: 'PickingListSummaryPayload' } & {
        data?: Maybe<
            Array<
                { __typename?: 'PickingListSummary' } & Pick<
                    PickingListSummary,
                    'listTotalItems' | 'warehouseTotalItems'
                > & {
                        storageItemType: { __typename?: 'StorageItemType' } & Pick<
                            StorageItemType,
                            'id' | 'name'
                        >;
                    }
            >
        >;
    };
};

export type GetVisitsQueryVariables = Exact<{
    visitsFilterInput: FilterInput;
}>;

export type GetVisitsQuery = { __typename?: 'Query' } & {
    visits: { __typename?: 'VisitConnection' } & {
        edges: Array<
            { __typename?: 'VisitEdge' } & Pick<VisitEdge, 'cursor'> & {
                    node: { __typename?: 'Visit' } & Pick<Visit, 'id'> &
                        PickListFiltersVisitFragment;
                }
        >;
        pageInfo: { __typename?: 'PageInfo' } & Pick<
            PageInfo,
            'hasNextPage' | 'hasPreviousPage'
        > & {
                cursor: { __typename?: 'PageCursor' } & Pick<
                    PageCursor,
                    'beforeCursor' | 'afterCursor'
                >;
            };
    };
};

export type GetStorageItemTypesQueryVariables = Exact<{
    storageItemTypesFilterInput: FilterInput;
}>;

export type GetStorageItemTypesQuery = { __typename?: 'Query' } & {
    serviceProvider?: Maybe<
        { __typename?: 'ServiceProvider' } & Pick<ServiceProvider, 'id'> & {
                storageItemTypes: { __typename?: 'StorageItemTypeConnection' } & {
                    edges: Array<
                        { __typename?: 'StorageItemTypeEdge' } & Pick<
                            StorageItemTypeEdge,
                            'cursor'
                        > & {
                                node: { __typename?: 'StorageItemType' } & Pick<
                                    StorageItemType,
                                    'id'
                                > &
                                    PickListFiltersStorageItemTypeFragment;
                            }
                    >;
                    pageInfo: { __typename?: 'PageInfo' } & Pick<
                        PageInfo,
                        'hasNextPage' | 'hasPreviousPage'
                    > & {
                            cursor: { __typename?: 'PageCursor' } & Pick<
                                PageCursor,
                                'beforeCursor' | 'afterCursor'
                            >;
                        };
                };
            }
    >;
};

export type CreatePickListMutationVariables = Exact<{
    description?: Maybe<Scalars['String']>;
    filterInput: PickingCreateListFilterInput;
}>;

export type CreatePickListMutation = { __typename?: 'Mutation' } & {
    pickingCreateList: { __typename?: 'PickingCreateListPayload' } & {
        pickList?: Maybe<{ __typename?: 'PickList' } & Pick<PickList, 'id'>>;
    };
};

export type PickListFiltersVisitFragment = { __typename?: 'Visit' } & Pick<Visit, 'id'>;

export type PickListFiltersStorageItemTypeFragment = { __typename?: 'StorageItemType' } & Pick<
    StorageItemType,
    'id' | 'name'
>;

export type RetrievePickListsPickListFragment = { __typename?: 'PickList' } & Pick<
    PickList,
    'id' | 'pickListNumber' | 'status' | 'created_at' | 'description'
> & {
        summary: { __typename?: 'PickListSummary' } & Pick<
            PickListSummary,
            'toBePicked' | 'picked' | 'excluded'
        >;
    };

export type GetPickListsQueryVariables = Exact<{
    pageSize: Scalars['Int'];
    cursor: Scalars['String'];
}>;

export type GetPickListsQuery = { __typename?: 'Query' } & {
    pickLists: { __typename?: 'PickListConnection' } & {
        edges: Array<
            { __typename?: 'PickListEdge' } & Pick<PickListEdge, 'cursor'> & {
                    node: { __typename?: 'PickList' } & Pick<PickList, 'id'> &
                        RetrievePickListsPickListFragment;
                }
        >;
        pageInfo: { __typename?: 'PageInfo' } & Pick<
            PageInfo,
            'hasNextPage' | 'hasPreviousPage'
        > & {
                cursor: { __typename?: 'PageCursor' } & Pick<
                    PageCursor,
                    'beforeCursor' | 'afterCursor'
                >;
            };
    };
};

export type GetPickListByIdQueryVariables = Exact<{
    pickListId: Scalars['String'];
    pageSize: Scalars['Int'];
    cursor: Scalars['String'];
}>;

export type GetPickListByIdQuery = { __typename?: 'Query' } & {
    pickListById?: Maybe<
        { __typename?: 'PickList' } & Pick<
            PickList,
            | 'id'
            | 'pickListNumber'
            | 'description'
            | 'status'
            | 'startTime'
            | 'endTime'
            | 'created_at'
            | 'visitStartTime'
            | 'visitEndTime'
            | 'maxQuantity'
        > & {
                items?: Maybe<
                    { __typename?: 'PickListItemConnection' } & {
                        edges: Array<
                            { __typename?: 'PickListItemtEdge' } & Pick<
                                PickListItemtEdge,
                                'cursor'
                            > & {
                                    node: { __typename?: 'PickListItem' } & Pick<
                                        PickListItem,
                                        'id'
                                    > &
                                        PicklistProcessPicklistItemFragment &
                                        PicklistSummaryPicklistItemFragment;
                                }
                        >;
                        pageInfo: { __typename?: 'PageInfo' } & Pick<
                            PageInfo,
                            'hasNextPage' | 'hasPreviousPage'
                        > & {
                                cursor: { __typename?: 'PageCursor' } & Pick<
                                    PageCursor,
                                    'beforeCursor' | 'afterCursor'
                                >;
                            };
                    }
                >;
                locations?: Maybe<
                    Array<{ __typename?: 'LocationFilter' } & Pick<LocationFilter, 'from' | 'to'>>
                >;
                storageItemTypes?: Maybe<
                    Array<{ __typename?: 'StorageItemType' } & Pick<StorageItemType, 'id'>>
                >;
                visits?: Maybe<Array<{ __typename?: 'Visit' } & Pick<Visit, 'id'>>>;
            }
    >;
};

export type PickListCompleteListMutationVariables = Exact<{
    pickListId: Scalars['String'];
}>;

export type PickListCompleteListMutation = { __typename?: 'Mutation' } & {
    pickingCompleteList: { __typename?: 'PickingCompleteListPayload' } & {
        pickList?: Maybe<
            { __typename?: 'PickList' } & Pick<PickList, 'id' | 'pickListNumber' | 'status'>
        >;
    };
};

export type PickListPickItemMutationVariables = Exact<{
    pickListItemId: Scalars['String'];
    targetLocationName: Scalars['String'];
}>;

export type PickListPickItemMutation = { __typename?: 'Mutation' } & {
    pickingPickItem: { __typename?: 'PickingPickItemPayload' } & {
        pickListItem?: Maybe<
            { __typename?: 'PickListItem' } & Pick<PickListItem, 'id'> & {
                    request?: Maybe<
                        | ({ __typename?: 'PickupStorageItemRequest' } & Pick<
                              PickupStorageItemRequest,
                              'id' | 'status'
                          >)
                        | ({ __typename?: 'DeliverStorageItemRequest' } & Pick<
                              DeliverStorageItemRequest,
                              'id' | 'status'
                          >)
                        | ({ __typename?: 'DeliverEmptyContainerRequest' } & Pick<
                              DeliverEmptyContainerRequest,
                              'id' | 'status'
                          >)
                        | ({ __typename?: 'PickupEmptyContainerRequest' } & Pick<
                              PickupEmptyContainerRequest,
                              'id' | 'status'
                          >)
                        | ({ __typename?: 'DriverPickupEmptyContainerRequest' } & Pick<
                              DriverPickupEmptyContainerRequest,
                              'id' | 'status'
                          >)
                    >;
                }
        >;
    };
};

export type PickListExcludeItemMutationVariables = Exact<{
    pickListItemId: Scalars['String'];
}>;

export type PickListExcludeItemMutation = { __typename?: 'Mutation' } & {
    pickingExcludeItem: { __typename?: 'PickingExcludeItemPayload' } & {
        pickListItem?: Maybe<
            { __typename?: 'PickListItem' } & Pick<PickListItem, 'id'> & {
                    request?: Maybe<
                        | ({ __typename?: 'PickupStorageItemRequest' } & Pick<
                              PickupStorageItemRequest,
                              'id' | 'status'
                          >)
                        | ({ __typename?: 'DeliverStorageItemRequest' } & Pick<
                              DeliverStorageItemRequest,
                              'id' | 'status'
                          >)
                        | ({ __typename?: 'DeliverEmptyContainerRequest' } & Pick<
                              DeliverEmptyContainerRequest,
                              'id' | 'status'
                          >)
                        | ({ __typename?: 'PickupEmptyContainerRequest' } & Pick<
                              PickupEmptyContainerRequest,
                              'id' | 'status'
                          >)
                        | ({ __typename?: 'DriverPickupEmptyContainerRequest' } & Pick<
                              DriverPickupEmptyContainerRequest,
                              'id' | 'status'
                          >)
                    >;
                }
        >;
    };
};

export type PicklistProcessPicklistItemFragment = { __typename?: 'PickListItem' } & Pick<
    PickListItem,
    'id'
> & {
        request?: Maybe<
            | ({ __typename?: 'PickupStorageItemRequest' } & Pick<
                  PickupStorageItemRequest,
                  'id' | 'status'
              >)
            | ({ __typename?: 'DeliverStorageItemRequest' } & Pick<
                  DeliverStorageItemRequest,
                  'id' | 'status'
              >)
            | ({ __typename?: 'DeliverEmptyContainerRequest' } & Pick<
                  DeliverEmptyContainerRequest,
                  'id' | 'status'
              >)
            | ({ __typename?: 'PickupEmptyContainerRequest' } & Pick<
                  PickupEmptyContainerRequest,
                  'id' | 'status'
              >)
            | ({ __typename?: 'DriverPickupEmptyContainerRequest' } & Pick<
                  DriverPickupEmptyContainerRequest,
                  'id' | 'status'
              >)
        >;
        storageItem: { __typename?: 'StorageItem' } & Pick<StorageItem, 'id' | 'barcode'> & {
                location?: Maybe<{ __typename?: 'Location' } & Pick<Location, 'description'>>;
                storageItemType: { __typename?: 'StorageItemType' } & Pick<
                    StorageItemType,
                    'id' | 'name' | 'description'
                >;
            };
    };

export type PicklistSummaryPicklistItemFragment = { __typename?: 'PickListItem' } & Pick<
    PickListItem,
    'id'
> & {
        request?: Maybe<
            | ({ __typename?: 'PickupStorageItemRequest' } & Pick<
                  PickupStorageItemRequest,
                  'id' | 'status'
              >)
            | ({ __typename?: 'DeliverStorageItemRequest' } & Pick<
                  DeliverStorageItemRequest,
                  'id' | 'status'
              >)
            | ({ __typename?: 'DeliverEmptyContainerRequest' } & Pick<
                  DeliverEmptyContainerRequest,
                  'id' | 'status'
              >)
            | ({ __typename?: 'PickupEmptyContainerRequest' } & Pick<
                  PickupEmptyContainerRequest,
                  'id' | 'status'
              >)
            | ({ __typename?: 'DriverPickupEmptyContainerRequest' } & Pick<
                  DriverPickupEmptyContainerRequest,
                  'id' | 'status'
              >)
        >;
        storageItem: { __typename?: 'StorageItem' } & Pick<StorageItem, 'id' | 'barcode'> & {
                location?: Maybe<{ __typename?: 'Location' } & Pick<Location, 'description'>>;
                storageItemType: { __typename?: 'StorageItemType' } & Pick<
                    StorageItemType,
                    'id' | 'name' | 'description'
                >;
            };
    };

export type RefilingLocationFragment = { __typename?: 'Location' } & Pick<
    Location,
    'id' | 'barcode' | 'description'
>;

export type RefilingStorageItemFragment = { __typename?: 'StorageItem' } & Pick<
    StorageItem,
    'id' | 'status' | 'barcode'
> & {
        storageItemType: { __typename?: 'StorageItemType' } & Pick<
            StorageItemType,
            'id' | 'name' | 'description' | 'image'
        >;
    };

export type RefileStorageItemByBarcodeQueryVariables = Exact<{
    barcode: Scalars['String'];
}>;

export type RefileStorageItemByBarcodeQuery = { __typename?: 'Query' } & {
    storageItemByBarcode?: Maybe<{ __typename?: 'StorageItem' } & RefilingStorageItemFragment>;
};

export type RefilingCreateListMutationVariables = Exact<{
    inputData: RefilingCreateListInput;
}>;

export type RefilingCreateListMutation = { __typename?: 'Mutation' } & {
    refilingCreateList: { __typename?: 'RefilingCreateListPayload' } & {
        data?: Maybe<{ __typename?: 'RefileList' } & Pick<RefileList, 'id'>>;
    };
};

export type RefileListFragment = { __typename?: 'RefileList' } & Pick<
    RefileList,
    'id' | 'refileListNumber' | 'status' | 'created_at'
>;

export type RefileListGetRefilesQueryVariables = Exact<{
    pageSize: Scalars['Int'];
    cursor: Scalars['String'];
}>;

export type RefileListGetRefilesQuery = { __typename?: 'Query' } & {
    refileLists: { __typename?: 'RefileListConnection' } & {
        edges: Array<
            { __typename?: 'RefileListEdge' } & Pick<RefileListEdge, 'cursor'> & {
                    node: { __typename?: 'RefileList' } & Pick<RefileList, 'id'> &
                        RefileListFragment;
                }
        >;
        pageInfo: { __typename?: 'PageInfo' } & Pick<
            PageInfo,
            'hasNextPage' | 'hasPreviousPage'
        > & {
                cursor: { __typename?: 'PageCursor' } & Pick<
                    PageCursor,
                    'beforeCursor' | 'afterCursor'
                >;
            };
    };
};

export type RefileItemProcessItemFragment = { __typename?: 'RefileItem' } & Pick<
    RefileItem,
    'id' | 'status'
> & {
        storageItem: { __typename?: 'StorageItem' } & Pick<
            StorageItem,
            'id' | 'status' | 'barcode'
        > & {
                container?: Maybe<
                    { __typename?: 'StorageContainer' } & Pick<StorageContainer, 'id' | 'barcode'>
                >;
                storageItemType: { __typename?: 'StorageItemType' } & Pick<
                    StorageItemType,
                    'id' | 'name' | 'description' | 'image'
                > & {
                        containerType?: Maybe<
                            { __typename?: 'StorageContainerType' } & Pick<
                                StorageContainerType,
                                'id'
                            >
                        >;
                        metadataDefinitions?: Maybe<
                            Array<
                                { __typename?: 'StorageItemMetadataDefinition' } & Pick<
                                    StorageItemMetadataDefinition,
                                    | 'id'
                                    | 'propertyName'
                                    | 'isRequired'
                                    | 'propertyType'
                                    | 'values'
                                    | 'isActive'
                                    | 'sortOrder'
                                >
                            >
                        >;
                    };
                metadataValue?: Maybe<
                    Array<
                        { __typename?: 'StorageItemMetadataValue' } & Pick<
                            StorageItemMetadataValue,
                            'value'
                        > & {
                                metadataDefinition?: Maybe<
                                    { __typename?: 'StorageItemMetadataDefinition' } & Pick<
                                        StorageItemMetadataDefinition,
                                        'id' | 'propertyName'
                                    >
                                >;
                            }
                    >
                >;
                location?: Maybe<
                    { __typename?: 'Location' } & Pick<Location, 'description' | 'barcode'>
                >;
            };
    };

export type RefileListProcessItemFragment = { __typename?: 'RefileList' } & Pick<
    RefileList,
    'id' | 'refileListNumber'
> & { items?: Maybe<Array<{ __typename?: 'RefileItem' } & RefileItemProcessItemFragment>> };

export type RefileListByIdQueryVariables = Exact<{
    refileListId: Scalars['String'];
}>;

export type RefileListByIdQuery = { __typename?: 'Query' } & {
    refileListById?: Maybe<
        { __typename?: 'RefileList' } & Pick<RefileList, 'id'> & RefileListProcessItemFragment
    >;
};

export type RefilingProcessItemMutationVariables = Exact<{
    data: RefilingProcessItemInput;
}>;

export type RefilingProcessItemMutation = { __typename?: 'Mutation' } & {
    refilingProcessItem: { __typename?: 'RefilingProcessItemPayload' } & {
        data: { __typename?: 'RefileItem' } & Pick<RefileItem, 'id' | 'status'>;
    };
};

export type RefilingProcessItemLocationFragment = { __typename?: 'Location' } & Pick<
    Location,
    'id' | 'barcode' | 'description'
>;

export type RefilingLocationByBarcodeQueryVariables = Exact<{
    barcode: Scalars['String'];
}>;

export type RefilingLocationByBarcodeQuery = { __typename?: 'Query' } & {
    locationByBarcode?: Maybe<
        { __typename?: 'Location' } & Pick<Location, 'id'> & RefilingProcessItemLocationFragment
    >;
};

export type RefilingCompleteListMutationVariables = Exact<{
    data: RefilingCompleteListInput;
}>;

export type RefilingCompleteListMutation = { __typename?: 'Mutation' } & {
    refilingCompleteList: { __typename?: 'RefilingCompleteListPayload' } & {
        data: { __typename?: 'RefileList' } & Pick<RefileList, 'id' | 'status'>;
    };
};

export type RoutingRouteDetailsEmployeesQueryVariables = Exact<{ [key: string]: never }>;

export type RoutingRouteDetailsEmployeesQuery = { __typename?: 'Query' } & {
    employees: { __typename?: 'EmployeeConnection' } & {
        edges: Array<
            { __typename?: 'EmployeeEdge' } & {
                node: { __typename?: 'Employee' } & Pick<Employee, 'id' | 'firstName' | 'lastName'>;
            }
        >;
        pageInfo: { __typename?: 'PageInfo' } & Pick<
            PageInfo,
            'hasNextPage' | 'hasPreviousPage'
        > & {
                cursor: { __typename?: 'PageCursor' } & Pick<
                    PageCursor,
                    'beforeCursor' | 'afterCursor'
                >;
            };
    };
};

export type RoutingRouteDetailsVehiclesQueryVariables = Exact<{ [key: string]: never }>;

export type RoutingRouteDetailsVehiclesQuery = { __typename?: 'Query' } & {
    vehicles: { __typename?: 'VehicleConnection' } & {
        edges: Array<
            { __typename?: 'VehicleEdge' } & {
                node: { __typename?: 'Vehicle' } & Pick<Vehicle, 'id' | 'description'>;
            }
        >;
        pageInfo: { __typename?: 'PageInfo' } & Pick<
            PageInfo,
            'hasNextPage' | 'hasPreviousPage'
        > & {
                cursor: { __typename?: 'PageCursor' } & Pick<
                    PageCursor,
                    'beforeCursor' | 'afterCursor'
                >;
            };
    };
};

export type RoutingSetRouteEmployeesMutationVariables = Exact<{
    routeId: Scalars['String'];
    employeeIds: Array<Scalars['String']> | Scalars['String'];
}>;

export type RoutingSetRouteEmployeesMutation = { __typename?: 'Mutation' } & {
    routingSetEmployees: { __typename?: 'RoutingSetEmployeesPayload' } & {
        route: { __typename?: 'Route' } & Pick<Route, 'id'> & {
                employees?: Maybe<Array<{ __typename?: 'Employee' } & Pick<Employee, 'id'>>>;
            };
    };
};

export type RoutingSetRouteVehicleMutationVariables = Exact<{
    routeId: Scalars['String'];
    vehicleId?: Maybe<Scalars['String']>;
}>;

export type RoutingSetRouteVehicleMutation = { __typename?: 'Mutation' } & {
    routingSetVehicle: { __typename?: 'RoutingSetVehiclePayload' } & {
        route: { __typename?: 'Route' } & Pick<Route, 'id'> & {
                vehicles?: Maybe<Array<{ __typename?: 'Vehicle' } & Pick<Vehicle, 'id'>>>;
            };
    };
};

export type RoutingUpdateRouteStopTimeMutationVariables = Exact<{
    routeStopId: Scalars['ID'];
    startTime: Scalars['DateTime'];
}>;

export type RoutingUpdateRouteStopTimeMutation = { __typename?: 'Mutation' } & {
    routingUpdateStop: { __typename?: 'RoutingUpdateStopPayload' } & {
        routeStop: { __typename?: 'RouteStop' } & Pick<RouteStop, 'id' | 'startTime'> & {
                visit: { __typename?: 'Visit' } & RoutingVisitInformationFragment;
            };
    };
};

export type RoutingVisitInformationFragment = { __typename?: 'Visit' } & Pick<
    Visit,
    'id' | 'status' | 'startTime' | 'endTime' | 'expectedDuration'
> & {
        customerAddress: { __typename?: 'CustomerAddress' } & Pick<
            CustomerAddress,
            | 'id'
            | 'address1'
            | 'address2'
            | 'city'
            | 'zoneId'
            | 'countryId'
            | 'zip'
            | 'longitude'
            | 'latitude'
            | 'addressType'
        > & { territory: { __typename?: 'Territory' } & Pick<Territory, 'id'> };
        customer: { __typename?: 'Customer' } & Pick<
            Customer,
            'id' | 'email' | 'firstName' | 'lastName' | 'homePhone' | 'cellPhone'
        >;
        scheduleWindow: { __typename?: 'ScheduleWindow' } & Pick<
            ScheduleWindow,
            'id' | 'startTime' | 'endTime'
        >;
        summary: { __typename?: 'VisitSummary' } & RoutingVisitSummaryInformationFragment;
        stop?: Maybe<
            { __typename?: 'RouteStop' } & Pick<RouteStop, 'id' | 'startTime' | 'endTime'> & {
                    route: { __typename?: 'Route' } & Pick<Route, 'id'>;
                }
        >;
    };

export type RoutingVisitSummaryInformationFragment = { __typename?: 'VisitSummary' } & Pick<
    VisitSummary,
    'inComing' | 'outGoing' | 'multiplePeople'
>;

export type RoutingVisitsQueryVariables = Exact<{
    cursor?: Maybe<Scalars['String']>;
    startDate?: Maybe<Scalars['String']>;
    endDate?: Maybe<Scalars['String']>;
}>;

export type RoutingVisitsQuery = { __typename?: 'Query' } & {
    visits: { __typename?: 'VisitConnection' } & {
        pageInfo: { __typename?: 'PageInfo' } & Pick<
            PageInfo,
            'hasNextPage' | 'hasPreviousPage'
        > & {
                cursor: { __typename?: 'PageCursor' } & Pick<
                    PageCursor,
                    'afterCursor' | 'beforeCursor'
                >;
            };
        edges: Array<
            { __typename?: 'VisitEdge' } & Pick<VisitEdge, 'cursor'> & {
                    node: { __typename?: 'Visit' } & Pick<Visit, 'id'> &
                        RoutingVisitInformationFragment;
                }
        >;
    };
};

export type RoutingAreaInformationQueryVariables = Exact<{ [key: string]: never }>;

export type RoutingAreaInformationQuery = { __typename?: 'Query' } & {
    areas?: Maybe<
        Array<
            { __typename?: 'Area' } & {
                buildings?: Maybe<
                    Array<
                        { __typename?: 'Building' } & Pick<Building, 'id'> &
                            RoutingBuildingInformationFragment
                    >
                >;
                territories?: Maybe<
                    Array<
                        { __typename?: 'Territory' } & Pick<Territory, 'id'> &
                            RoutingTerritoryInformationFragment
                    >
                >;
            }
        >
    >;
};

export type RoutingBuildingInformationFragment = { __typename?: 'Building' } & Pick<
    Building,
    'id' | 'description'
>;

export type RoutingTerritoryInformationFragment = { __typename?: 'Territory' } & Pick<
    Territory,
    'id' | 'description'
>;

export type RoutingDeliveryWindowsQueryVariables = Exact<{
    date: Scalars['DateTime'];
}>;

export type RoutingDeliveryWindowsQuery = { __typename?: 'Query' } & {
    areas?: Maybe<
        Array<
            { __typename?: 'Area' } & {
                deliveryWindowsByDate?: Maybe<
                    Array<
                        { __typename?: 'ScheduleWindow' } & Pick<ScheduleWindow, 'id'> &
                            RoutingDeliveryWindowInformationFragment
                    >
                >;
            }
        >
    >;
};

export type RoutingDeliveryWindowInformationFragment = { __typename?: 'ScheduleWindow' } & Pick<
    ScheduleWindow,
    'id' | 'startTime' | 'endTime'
>;

export type RoutingRoutesQueryVariables = Exact<{
    cursor?: Maybe<Scalars['String']>;
    startDate?: Maybe<Scalars['String']>;
    endDate?: Maybe<Scalars['String']>;
}>;

export type RoutingRoutesQuery = { __typename?: 'Query' } & {
    routes: { __typename?: 'RouteConnection' } & {
        pageInfo: { __typename?: 'PageInfo' } & Pick<
            PageInfo,
            'hasNextPage' | 'hasPreviousPage'
        > & {
                cursor: { __typename?: 'PageCursor' } & Pick<
                    PageCursor,
                    'afterCursor' | 'beforeCursor'
                >;
            };
        edges: Array<
            { __typename?: 'RouteEdge' } & Pick<RouteEdge, 'cursor'> & {
                    node: { __typename?: 'Route' } & Pick<Route, 'id'> &
                        RoutingRouteInformationFragment;
                }
        >;
    };
};

export type RoutingRouteInformationFragment = { __typename?: 'Route' } & Pick<
    Route,
    'id' | 'description'
> & {
        employees?: Maybe<Array<{ __typename?: 'Employee' } & Pick<Employee, 'id'>>>;
        vehicles?: Maybe<Array<{ __typename?: 'Vehicle' } & Pick<Vehicle, 'id'>>>;
    };

export type RoutingCreateRouteMutationVariables = Exact<{
    date: Scalars['DateTime'];
    building: Scalars['String'];
    description?: Maybe<Scalars['String']>;
}>;

export type RoutingCreateRouteMutation = { __typename?: 'Mutation' } & {
    routingCreateRoute: { __typename?: 'RoutingCreateRoutePayload' } & {
        route: { __typename?: 'Route' } & Pick<Route, 'id' | 'description' | 'operationDate'>;
    };
};

export type RoutingAddStopsToRouteMutationVariables = Exact<{
    routeId: Scalars['ID'];
    visits: Array<RoutingAddStopVisitInput> | RoutingAddStopVisitInput;
}>;

export type RoutingAddStopsToRouteMutation = { __typename?: 'Mutation' } & {
    routingAddStops: { __typename?: 'RoutingAddStopPayload' } & {
        routeStops: Array<
            { __typename?: 'RouteStop' } & Pick<RouteStop, 'id' | 'startTime'> & {
                    route: { __typename?: 'Route' } & Pick<Route, 'id'>;
                    visit: { __typename?: 'Visit' } & RoutingVisitInformationFragment;
                }
        >;
    };
};

export type RoutingRemoveStopFromRouteMutationVariables = Exact<{
    routeStopId: Scalars['ID'];
}>;

export type RoutingRemoveStopFromRouteMutation = { __typename?: 'Mutation' } & {
    routingRemoveStop: { __typename?: 'RoutingRemoveStopPayload' } & {
        visit: { __typename?: 'Visit' } & RoutingVisitInformationFragment;
        route: { __typename?: 'Route' } & {
            stops?: Maybe<
                Array<
                    { __typename?: 'RouteStop' } & Pick<RouteStop, 'id' | 'startTime'> & {
                            route: { __typename?: 'Route' } & Pick<Route, 'id'>;
                            visit: { __typename?: 'Visit' } & RoutingVisitInformationFragment;
                        }
                >
            >;
        };
    };
};

export type SortingLocationFragment = { __typename?: 'Location' } & Pick<
    Location,
    'id' | 'barcode' | 'description' | 'sortedItems' | 'itemsToSort'
>;

export type SortingStorageItemFragment = { __typename?: 'StorageItem' } & Pick<
    StorageItem,
    'id' | 'status' | 'barcode'
> & {
        location?: Maybe<
            { __typename?: 'Location' } & Pick<Location, 'id'> & SortingLocationFragment
        >;
        storageItemType: { __typename?: 'StorageItemType' } & Pick<
            StorageItemType,
            'id' | 'name' | 'description' | 'image'
        > & {
                metadataDefinitions?: Maybe<
                    Array<
                        { __typename?: 'StorageItemMetadataDefinition' } & Pick<
                            StorageItemMetadataDefinition,
                            | 'propertyName'
                            | 'isRequired'
                            | 'propertyType'
                            | 'values'
                            | 'isActive'
                            | 'sortOrder'
                        >
                    >
                >;
            };
        latestVisit?: Maybe<
            { __typename?: 'Visit' } & Pick<Visit, 'id'> & {
                    locations: Array<
                        { __typename?: 'Location' } & Pick<Location, 'id'> & SortingLocationFragment
                    >;
                }
        >;
    };

export type LocationByBarcodeQueryVariables = Exact<{
    barcode: Scalars['String'];
}>;

export type LocationByBarcodeQuery = { __typename?: 'Query' } & {
    locationByBarcode?: Maybe<
        { __typename?: 'Location' } & Pick<Location, 'id'> & SortingLocationFragment
    >;
};

export type StorageItemByBarcodeQueryVariables = Exact<{
    barcode: Scalars['String'];
}>;

export type StorageItemByBarcodeQuery = { __typename?: 'Query' } & {
    storageItemByBarcode?: Maybe<
        { __typename?: 'StorageItem' } & Pick<StorageItem, 'id'> & SortingStorageItemFragment
    >;
};

export type PickingSortItemMutationVariables = Exact<{
    pickingSortItem: PickingSortItemInput;
}>;

export type PickingSortItemMutation = { __typename?: 'Mutation' } & {
    pickingSortItem: { __typename?: 'PickingSortItemPayload' } & {
        storageItem?: Maybe<{ __typename?: 'StorageItem' } & Pick<StorageItem, 'id'>>;
    };
};

export type TruckLoadingDeliverProcessDeliverStorageItemRequestFragment = {
    __typename?: 'DeliverStorageItemRequest';
} & Pick<DeliverStorageItemRequest, 'id' | 'status' | 'requestType'> & {
        storageItem?: Maybe<
            { __typename?: 'StorageItem' } & Pick<StorageItem, 'id' | 'status' | 'barcode'> & {
                    location?: Maybe<{ __typename?: 'Location' } & Pick<Location, 'description'>>;
                    storageItemType: { __typename?: 'StorageItemType' } & Pick<
                        StorageItemType,
                        'id' | 'name' | 'description' | 'image'
                    >;
                }
        >;
        customer: { __typename?: 'Customer' } & Pick<
            Customer,
            'email' | 'firstName' | 'lastName' | 'homePhone' | 'cellPhone'
        >;
    };

export type TruckLoadingDeliverProcessDeliverEmptyContainerRequestFragment = {
    __typename?: 'DeliverEmptyContainerRequest';
} & Pick<DeliverEmptyContainerRequest, 'id' | 'status' | 'requestType'> & {
        storageItem?: Maybe<
            { __typename?: 'StorageItem' } & Pick<StorageItem, 'id' | 'status' | 'barcode'> & {
                    location?: Maybe<{ __typename?: 'Location' } & Pick<Location, 'description'>>;
                    storageItemType: { __typename?: 'StorageItemType' } & Pick<
                        StorageItemType,
                        'id' | 'name' | 'description' | 'image'
                    >;
                }
        >;
        customer: { __typename?: 'Customer' } & Pick<
            Customer,
            'email' | 'firstName' | 'lastName' | 'homePhone' | 'cellPhone'
        >;
    };

export type TruckLoadingRequestsByVisitIdQueryVariables = Exact<{
    visitId: Scalars['String'];
    pageSize: Scalars['Int'];
    cursor: Scalars['String'];
}>;

export type TruckLoadingRequestsByVisitIdQuery = { __typename?: 'Query' } & {
    requestsByVisitId: { __typename?: 'RequestConnection' } & {
        edges: Array<
            { __typename?: 'RequestEdge' } & Pick<RequestEdge, 'cursor'> & {
                    node:
                        | ({ __typename?: 'PickupStorageItemRequest' } & Pick<
                              PickupStorageItemRequest,
                              'id' | 'status' | 'requestType'
                          >)
                        | ({ __typename?: 'DeliverStorageItemRequest' } & Pick<
                              DeliverStorageItemRequest,
                              'id' | 'status' | 'requestType'
                          > &
                              TruckLoadingDeliverProcessDeliverStorageItemRequestFragment)
                        | ({ __typename?: 'DeliverEmptyContainerRequest' } & Pick<
                              DeliverEmptyContainerRequest,
                              'id' | 'status' | 'requestType'
                          > &
                              TruckLoadingDeliverProcessDeliverEmptyContainerRequestFragment)
                        | ({ __typename?: 'PickupEmptyContainerRequest' } & Pick<
                              PickupEmptyContainerRequest,
                              'id' | 'status' | 'requestType'
                          >)
                        | ({ __typename?: 'DriverPickupEmptyContainerRequest' } & Pick<
                              DriverPickupEmptyContainerRequest,
                              'id' | 'status' | 'requestType'
                          >);
                }
        >;
        pageInfo: { __typename?: 'PageInfo' } & Pick<
            PageInfo,
            'hasNextPage' | 'hasPreviousPage'
        > & {
                cursor: { __typename?: 'PageCursor' } & Pick<
                    PageCursor,
                    'afterCursor' | 'beforeCursor'
                >;
            };
    };
};

export type TruckLoadingProcessItemMutationVariables = Exact<{
    data: TruckLoadingProcessItemInput;
}>;

export type TruckLoadingProcessItemMutation = { __typename?: 'Mutation' } & {
    truckLoadingProcessItem: { __typename?: 'TruckLoadingProcessItemPayload' } & {
        data?: Maybe<
            { __typename?: 'DeliverStorageItemRequest' } & Pick<
                DeliverStorageItemRequest,
                'id' | 'status'
            >
        >;
    };
};

export type TruckLoadingProcessEmptyContainersMutationVariables = Exact<{
    data: TruckLoadingProcessEmptyContainersInput;
}>;

export type TruckLoadingProcessEmptyContainersMutation = { __typename?: 'Mutation' } & {
    truckLoadingProcessEmptyContainers: {
        __typename?: 'TruckLoadingProcessEmptyContainersPayload';
    } & { data: { __typename?: 'Route' } & Pick<Route, 'id' | 'status'> };
};

export type TruckLoadingProcessConfirmRouteMutationVariables = Exact<{
    data: TruckLoadingProcessConfirmRouteInput;
}>;

export type TruckLoadingProcessConfirmRouteMutation = { __typename?: 'Mutation' } & {
    truckLoadingProcessConfirmRoute: { __typename?: 'TruckLoadingProcessConfirmRoutePayload' } & {
        data: { __typename?: 'Route' } & Pick<Route, 'id' | 'status'>;
    };
};

export type TruckLoadingRouteDetailRouteStopFragment = { __typename?: 'RouteStop' } & Pick<
    RouteStop,
    'id' | 'startTime' | 'endTime' | 'sequence'
> & {
        visit: { __typename?: 'Visit' } & Pick<
            Visit,
            'id' | 'status' | 'expectedDuration' | 'startTime' | 'endTime'
        > & {
                summary: { __typename?: 'VisitSummary' } & Pick<
                    VisitSummary,
                    'inComing' | 'outGoing' | 'multiplePeople' | 'firstVisit'
                >;
                customerAddress: { __typename?: 'CustomerAddress' } & Pick<
                    CustomerAddress,
                    'address1' | 'city' | 'countryId' | 'firstName' | 'lastName'
                >;
                scheduleWindow: { __typename?: 'ScheduleWindow' } & Pick<
                    ScheduleWindow,
                    'startTime' | 'endTime'
                >;
                customer: { __typename?: 'Customer' } & Pick<
                    Customer,
                    'email' | 'firstName' | 'lastName' | 'homePhone' | 'cellPhone'
                >;
                requests: { __typename?: 'RequestConnection' } & {
                    edges: Array<
                        { __typename?: 'RequestEdge' } & Pick<RequestEdge, 'cursor'> & {
                                node:
                                    | ({ __typename?: 'PickupStorageItemRequest' } & Pick<
                                          PickupStorageItemRequest,
                                          'id' | 'status'
                                      >)
                                    | ({ __typename?: 'DeliverStorageItemRequest' } & Pick<
                                          DeliverStorageItemRequest,
                                          'id' | 'status'
                                      >)
                                    | ({ __typename?: 'DeliverEmptyContainerRequest' } & Pick<
                                          DeliverEmptyContainerRequest,
                                          'id' | 'status'
                                      >)
                                    | ({ __typename?: 'PickupEmptyContainerRequest' } & Pick<
                                          PickupEmptyContainerRequest,
                                          'id' | 'status'
                                      >)
                                    | ({ __typename?: 'DriverPickupEmptyContainerRequest' } & Pick<
                                          DriverPickupEmptyContainerRequest,
                                          'id' | 'status'
                                      >);
                            }
                    >;
                };
            };
    };

export type TruckLoadingGetRouteDetailsQueryVariables = Exact<{
    routeId: Scalars['String'];
}>;

export type TruckLoadingGetRouteDetailsQuery = { __typename?: 'Query' } & {
    routes: { __typename?: 'RouteConnection' } & {
        edges: Array<
            { __typename?: 'RouteEdge' } & Pick<RouteEdge, 'cursor'> & {
                    node: { __typename?: 'Route' } & Pick<
                        Route,
                        'id' | 'description' | 'routeNumber' | 'status' | 'operationDate'
                    > & {
                            employees?: Maybe<
                                Array<
                                    { __typename?: 'Employee' } & Pick<
                                        Employee,
                                        'id' | 'firstName' | 'lastName'
                                    >
                                >
                            >;
                            stops?: Maybe<
                                Array<
                                    {
                                        __typename?: 'RouteStop';
                                    } & TruckLoadingRouteDetailRouteStopFragment
                                >
                            >;
                        };
                }
        >;
        pageInfo: { __typename?: 'PageInfo' } & Pick<
            PageInfo,
            'hasNextPage' | 'hasPreviousPage'
        > & {
                cursor: { __typename?: 'PageCursor' } & Pick<
                    PageCursor,
                    'beforeCursor' | 'afterCursor'
                >;
            };
    };
};

export type TruckLoadingRoutesRouteFragment = { __typename?: 'Route' } & Pick<
    Route,
    'id' | 'description' | 'routeNumber' | 'status' | 'operationDate'
> & {
        employees?: Maybe<
            Array<{ __typename?: 'Employee' } & Pick<Employee, 'id' | 'firstName' | 'lastName'>>
        >;
        vehicles?: Maybe<Array<{ __typename?: 'Vehicle' } & Pick<Vehicle, 'description'>>>;
    };

export type TruckLoadingGetRoutesQueryVariables = Exact<{
    startDate: Scalars['String'];
    endDate: Scalars['String'];
    pageSize: Scalars['Int'];
    cursor: Scalars['String'];
}>;

export type TruckLoadingGetRoutesQuery = { __typename?: 'Query' } & {
    routes: { __typename?: 'RouteConnection' } & {
        edges: Array<
            { __typename?: 'RouteEdge' } & Pick<RouteEdge, 'cursor'> & {
                    node: { __typename?: 'Route' } & Pick<Route, 'id'> &
                        TruckLoadingRoutesRouteFragment;
                }
        >;
        pageInfo: { __typename?: 'PageInfo' } & Pick<
            PageInfo,
            'hasNextPage' | 'hasPreviousPage'
        > & {
                cursor: { __typename?: 'PageCursor' } & Pick<
                    PageCursor,
                    'beforeCursor' | 'afterCursor'
                >;
            };
    };
};

export type TruckUnloadingProcessItemRouteFragment = { __typename?: 'Route' } & Pick<
    Route,
    'id' | 'routeNumber'
>;

export type TruckUnloadingProcessItemGetRouteByVehicleQueryVariables = Exact<{
    vehicleId: Scalars['String'];
    routeStatus?: Maybe<RouteStatus>;
    fromDate?: Maybe<Scalars['DateTime']>;
    toDate?: Maybe<Scalars['DateTime']>;
}>;

export type TruckUnloadingProcessItemGetRouteByVehicleQuery = { __typename?: 'Query' } & {
    routeByVehicleId?: Maybe<
        Array<{ __typename?: 'Route' } & Pick<Route, 'id'> & TruckUnloadingProcessItemRouteFragment>
    >;
};

export type TruckUnloadingProcessItemMutationVariables = Exact<{
    data: TruckUnloadingProcessItemInput;
}>;

export type TruckUnloadingProcessItemMutation = { __typename?: 'Mutation' } & {
    truckUnloadingProcessItem: { __typename?: 'TruckUnloadingProcessItemPayload' } & Pick<
        TruckUnloadingProcessItemPayload,
        'data'
    >;
};

export type TruckUnloadingLocationFragment = { __typename?: 'Location' } & Pick<
    Location,
    'id' | 'barcode' | 'description' | 'type'
>;

export type TruckUnloadingLocationByBarcodeQueryVariables = Exact<{
    barcode: Scalars['String'];
}>;

export type TruckUnloadingLocationByBarcodeQuery = { __typename?: 'Query' } & {
    locationByBarcode?: Maybe<
        { __typename?: 'Location' } & Pick<Location, 'id'> & TruckUnloadingLocationFragment
    >;
};

export type TruckUnloadingVehiclesVehicleFragment = { __typename?: 'Vehicle' } & Pick<
    Vehicle,
    'id' | 'description'
>;

export type TruckUnloadingGetVehiclesQueryVariables = Exact<{ [key: string]: never }>;

export type TruckUnloadingGetVehiclesQuery = { __typename?: 'Query' } & {
    vehiclesByInProgressRoutes?: Maybe<
        Array<
            { __typename?: 'Vehicle' } & Pick<Vehicle, 'id'> & TruckUnloadingVehiclesVehicleFragment
        >
    >;
};

export type VisitReviewMapVisitFragment = { __typename?: 'Visit' } & Pick<Visit, 'id'> & {
        customerAddress: { __typename?: 'CustomerAddress' } & Pick<
            CustomerAddress,
            | 'longitude'
            | 'latitude'
            | 'firstName'
            | 'lastName'
            | 'address1'
            | 'city'
            | 'zoneId'
            | 'countryId'
            | 'zip'
        > & { territory: { __typename?: 'Territory' } & Pick<Territory, 'id'> };
        summary: { __typename?: 'VisitSummary' } & Pick<
            VisitSummary,
            'multiplePeople' | 'inComing' | 'outGoing'
        >;
    };

export type RetrievePendingVisitsQueryVariables = Exact<{
    pageSize: Scalars['Int'];
    cursor: Scalars['String'];
}>;

export type RetrievePendingVisitsQuery = { __typename?: 'Query' } & {
    visits: { __typename?: 'VisitConnection' } & {
        pageInfo: { __typename?: 'PageInfo' } & Pick<
            PageInfo,
            'hasNextPage' | 'hasPreviousPage'
        > & {
                cursor: { __typename?: 'PageCursor' } & Pick<
                    PageCursor,
                    'beforeCursor' | 'afterCursor'
                >;
            };
        edges: Array<
            { __typename?: 'VisitEdge' } & Pick<VisitEdge, 'cursor'> & {
                    node: { __typename?: 'Visit' } & Pick<
                        Visit,
                        'id' | 'status' | 'startTime' | 'endTime' | 'expectedDuration'
                    > & {
                            summary: { __typename?: 'VisitSummary' } & Pick<
                                VisitSummary,
                                'inComing' | 'outGoing' | 'multiplePeople' | 'firstVisit'
                            >;
                            customerAddress: { __typename?: 'CustomerAddress' } & Pick<
                                CustomerAddress,
                                | 'id'
                                | 'address1'
                                | 'zoneId'
                                | 'city'
                                | 'countryId'
                                | 'zip'
                                | 'longitude'
                                | 'latitude'
                                | 'phone'
                                | 'firstName'
                                | 'lastName'
                                | 'addressType'
                            > & {
                                    territory: { __typename?: 'Territory' } & Pick<
                                        Territory,
                                        'id' | 'description'
                                    >;
                                };
                            scheduleWindow: { __typename?: 'ScheduleWindow' } & Pick<
                                ScheduleWindow,
                                'id' | 'isLocked' | 'startTime' | 'endTime'
                            >;
                        } & VisitReviewMapVisitFragment;
                }
        >;
    };
};

export type RetrieveScheduleWindowsQueryVariables = Exact<{
    zipCode: Scalars['String'];
    startDate: Scalars['DateTime'];
    endDate: Scalars['DateTime'];
}>;

export type RetrieveScheduleWindowsQuery = { __typename?: 'Query' } & {
    territoryByZipCode?: Maybe<
        { __typename?: 'Territory' } & Pick<Territory, 'id'> & {
                schedule?: Maybe<
                    Array<
                        { __typename?: 'ScheduleWindow' } & Pick<ScheduleWindow, 'id'> &
                            VisitReviewVisitRequestScheduleWindowFragment
                    >
                >;
            }
    >;
};

export type VisitReviewVisitRequestScheduleWindowFragment = {
    __typename?: 'ScheduleWindow';
} & Pick<ScheduleWindow, 'id' | 'isLocked' | 'startTime' | 'endTime'>;

export type RetrieveRequestsByVisitIdQueryVariables = Exact<{
    visitId: Scalars['String'];
    pageSize: Scalars['Int'];
    cursor: Scalars['String'];
}>;

export type RetrieveRequestsByVisitIdQuery = { __typename?: 'Query' } & {
    requestsByVisitId: { __typename?: 'RequestConnection' } & {
        pageInfo: { __typename?: 'PageInfo' } & Pick<
            PageInfo,
            'hasNextPage' | 'hasPreviousPage'
        > & {
                cursor: { __typename?: 'PageCursor' } & Pick<
                    PageCursor,
                    'beforeCursor' | 'afterCursor'
                >;
            };
        edges: Array<
            { __typename?: 'RequestEdge' } & Pick<RequestEdge, 'cursor'> & {
                    node:
                        | ({ __typename?: 'PickupStorageItemRequest' } & Pick<
                              PickupStorageItemRequest,
                              'id' | 'requestType'
                          > &
                              VisitReviewVisitRequestPickupStorageItemRequestFragment)
                        | ({ __typename?: 'DeliverStorageItemRequest' } & Pick<
                              DeliverStorageItemRequest,
                              'id' | 'requestType'
                          > &
                              VisitReviewVisitRequestDeliverStorageItemRequestFragment)
                        | ({ __typename?: 'DeliverEmptyContainerRequest' } & Pick<
                              DeliverEmptyContainerRequest,
                              'id' | 'requestType'
                          > &
                              VisitReviewVisitRequestDeliverEmptyContainerRequestFragment)
                        | ({ __typename?: 'PickupEmptyContainerRequest' } & Pick<
                              PickupEmptyContainerRequest,
                              'id' | 'requestType'
                          > &
                              VisitReviewVisitRequestPickupEmptyContainerRequestFragment)
                        | ({ __typename?: 'DriverPickupEmptyContainerRequest' } & Pick<
                              DriverPickupEmptyContainerRequest,
                              'id' | 'requestType'
                          > &
                              VisitReviewVisitRequestDriverPickupEmptyContainerRequestFragment);
                }
        >;
    };
};

export type VisitReviewVisitRequestPickupStorageItemRequestFragment = {
    __typename?: 'PickupStorageItemRequest';
} & Pick<PickupStorageItemRequest, 'id' | 'requestType'> & {
        storageItem?: Maybe<
            { __typename?: 'StorageItem' } & Pick<StorageItem, 'id'> & {
                    storageItemType: { __typename?: 'StorageItemType' } & Pick<
                        StorageItemType,
                        'id' | 'name'
                    >;
                }
        >;
    };

export type VisitReviewVisitRequestDeliverStorageItemRequestFragment = {
    __typename?: 'DeliverStorageItemRequest';
} & Pick<DeliverStorageItemRequest, 'id' | 'requestType'> & {
        storageItem?: Maybe<
            { __typename?: 'StorageItem' } & Pick<StorageItem, 'id'> & {
                    storageItemType: { __typename?: 'StorageItemType' } & Pick<
                        StorageItemType,
                        'id' | 'name'
                    >;
                }
        >;
    };

export type VisitReviewVisitRequestDeliverEmptyContainerRequestFragment = {
    __typename?: 'DeliverEmptyContainerRequest';
} & Pick<DeliverEmptyContainerRequest, 'id' | 'requestType'> & {
        storageItem?: Maybe<
            { __typename?: 'StorageItem' } & Pick<StorageItem, 'id'> & {
                    storageItemType: { __typename?: 'StorageItemType' } & Pick<
                        StorageItemType,
                        'id' | 'name'
                    >;
                }
        >;
    };

export type VisitReviewVisitRequestPickupEmptyContainerRequestFragment = {
    __typename?: 'PickupEmptyContainerRequest';
} & Pick<PickupEmptyContainerRequest, 'id' | 'requestType'> & {
        storageItem?: Maybe<
            { __typename?: 'StorageItem' } & Pick<StorageItem, 'id'> & {
                    storageItemType: { __typename?: 'StorageItemType' } & Pick<
                        StorageItemType,
                        'id' | 'name'
                    >;
                }
        >;
    };

export type VisitReviewVisitRequestDriverPickupEmptyContainerRequestFragment = {
    __typename?: 'DriverPickupEmptyContainerRequest';
} & Pick<DriverPickupEmptyContainerRequest, 'id' | 'requestType'> & {
        storageItem?: Maybe<
            { __typename?: 'StorageItem' } & Pick<StorageItem, 'id'> & {
                    storageItemType: { __typename?: 'StorageItemType' } & Pick<
                        StorageItemType,
                        'id' | 'name'
                    >;
                }
        >;
    };

export type ApproveVisitMutationVariables = Exact<{
    details: VisitReviewApproveVisitInput;
}>;

export type ApproveVisitMutation = { __typename?: 'Mutation' } & {
    visitReviewApproveVisit: { __typename?: 'VisitReviewApproveVisitPayload' } & {
        visit?: Maybe<
            { __typename?: 'Visit' } & Pick<
                Visit,
                'id' | 'status' | 'startTime' | 'endTime' | 'expectedDuration'
            > & {
                    summary: { __typename?: 'VisitSummary' } & Pick<
                        VisitSummary,
                        'inComing' | 'outGoing' | 'multiplePeople' | 'firstVisit'
                    >;
                    customerAddress: { __typename?: 'CustomerAddress' } & Pick<
                        CustomerAddress,
                        | 'id'
                        | 'address1'
                        | 'zoneId'
                        | 'city'
                        | 'countryId'
                        | 'zip'
                        | 'longitude'
                        | 'latitude'
                        | 'phone'
                        | 'firstName'
                        | 'lastName'
                        | 'addressType'
                    >;
                    scheduleWindow: { __typename?: 'ScheduleWindow' } & Pick<
                        ScheduleWindow,
                        'id' | 'isLocked' | 'startTime' | 'endTime'
                    >;
                } & VisitReviewMapVisitFragment
        >;
    };
};

export const AdminAreaDetailFragmentDoc = gql`
    fragment adminAreaDetail on Area {
        id
        code
    }
`;
export const AdminAreaFragmentDoc = gql`
    fragment adminArea on Area {
        id
        code
    }
`;
export const AdminBuildingAreaFragmentDoc = gql`
    fragment adminBuildingArea on Area {
        id
        code
    }
`;
export const AdminBuildingDetailFragmentDoc = gql`
    fragment adminBuildingDetail on Building {
        id
        code
        description
        isActive
        address {
            id
            address1
            address2
            city
            zoneId
            countryId
            zip
            longitude
            latitude
            phone
            firstName
            lastName
        }
        area {
            id
            code
        }
    }
`;
export const AdminBuildingFragmentDoc = gql`
    fragment adminBuilding on Building {
        id
        code
        description
        area {
            id
            code
        }
        address {
            id
            address1
            address2
            city
            zoneId
            countryId
            zip
            longitude
            latitude
            phone
            firstName
            lastName
        }
    }
`;
export const AdminEmployeeDetailFragmentDoc = gql`
    fragment adminEmployeeDetail on Employee {
        id
        firstName
        lastName
        email
        homePhone
        cellPhone
        workPhone
        startDate
        endDate
        address {
            id
            address1
            address2
            city
            zoneId
            countryId
            zip
            longitude
            latitude
        }
        type
    }
`;
export const AdminEmployeeFragmentDoc = gql`
    fragment adminEmployee on Employee {
        id
        firstName
        lastName
        email
        homePhone
        cellPhone
        workPhone
        startDate
        endDate
        address {
            id
            address1
            address2
            city
            zoneId
            countryId
            zip
            longitude
            latitude
            phone
            firstName
            lastName
        }
        type
    }
`;
export const AdminAddLocationBuildingFragmentDoc = gql`
    fragment adminAddLocationBuilding on Building {
        id
        code
        description
    }
`;
export const AdminLocationDetailBuildingFragmentDoc = gql`
    fragment adminLocationDetailBuilding on Building {
        id
        code
        description
    }
`;
export const AdminLocationDetailFragmentDoc = gql`
    fragment adminLocationDetail on Location {
        id
        description
        barcode
        type
        building {
            id
            code
            description
        }
    }
`;
export const AdminLocationFragmentDoc = gql`
    fragment adminLocation on Location {
        id
        description
        barcode
        sortedItems
        itemsToSort
    }
`;
export const AdminScheduleTemplatePeriodDetailFragmentDoc = gql`
    fragment adminScheduleTemplatePeriodDetail on ScheduleTemplateWindow {
        id
        startTime
        endTime
        week
        day
    }
`;
export const AdminScheduleTemplateAreaFragmentDoc = gql`
    fragment adminScheduleTemplateArea on Area {
        id
        code
    }
`;
export const AdminScheduleTemplateDetailFragmentDoc = gql`
    fragment adminScheduleTemplateDetail on ScheduleTemplate {
        id
        startDate
        weeks {
            monday {
                id
                ...adminScheduleTemplatePeriodDetail
            }
            tuesday {
                id
                ...adminScheduleTemplatePeriodDetail
            }
            wednesday {
                id
                ...adminScheduleTemplatePeriodDetail
            }
            thursday {
                id
                ...adminScheduleTemplatePeriodDetail
            }
            friday {
                id
                ...adminScheduleTemplatePeriodDetail
            }
            saturday {
                id
                ...adminScheduleTemplatePeriodDetail
            }
            sunday {
                id
                ...adminScheduleTemplatePeriodDetail
            }
        }
        area {
            id
            ...adminScheduleTemplateArea
        }
    }
    ${AdminScheduleTemplatePeriodDetailFragmentDoc}
    ${AdminScheduleTemplateAreaFragmentDoc}
`;
export const AdminScheduleTemplateFragmentDoc = gql`
    fragment adminScheduleTemplate on ScheduleTemplate {
        id
        startDate
        area {
            id
            code
        }
    }
`;
export const AdminStorageContainerTypeDetailFragmentDoc = gql`
    fragment adminStorageContainerTypeDetail on StorageContainerType {
        id
        description
    }
`;
export const AdminStorageContainerTypeFragmentDoc = gql`
    fragment adminStorageContainerType on StorageContainerType {
        id
        description
    }
`;
export const AdminStorageContainerDetailFragmentDoc = gql`
    fragment adminStorageContainerDetail on StorageContainer {
        id
        status
        barcode
        containerType {
            id
            description
        }
    }
`;
export const AdminStorageContainerFragmentDoc = gql`
    fragment adminStorageContainer on StorageContainer {
        id
        status
        barcode
        containerType {
            id
            description
        }
    }
`;
export const AdminStorageItemTypeCategoryFragmentDoc = gql`
    fragment adminStorageItemTypeCategory on StorageItemTypeCategory {
        id
        name
        parentId
    }
`;
export const AdminStorageItemTypeDetailFragmentDoc = gql`
    fragment adminStorageItemTypeDetail on StorageItemType {
        id
        description
        name
        image
        maximumRequestQuantity
        category {
            id
            name
            parentId
        }
        multiplePeople
    }
`;
export const AdminStorageItemTypeFragmentDoc = gql`
    fragment adminStorageItemType on StorageItemType {
        id
        description
        name
        image
        maximumRequestQuantity
        category {
            id
            name
            parentId
        }
        multiplePeople
    }
`;
export const AdminStorageItemTypeCategoryDetailFragmentDoc = gql`
    fragment adminStorageItemTypeCategoryDetail on StorageItemTypeCategory {
        id
        name
    }
`;
export const AdminAddTerritoryAreaFragmentDoc = gql`
    fragment adminAddTerritoryArea on Area {
        id
        code
    }
`;
export const AdminAddTerritoryFragmentDoc = gql`
    fragment adminAddTerritory on Territory {
        id
        description
        maximumRequestQuantity
        deliveryCutoff
        deliveryDelay
        pickupCutoff
        pickupDelay
        zipCodes
        area {
            id
            code
        }
    }
`;
export const AdminTerritoryAreaFragmentDoc = gql`
    fragment adminTerritoryArea on Area {
        id
        code
    }
`;
export const AdminTerritoryDetailFragmentDoc = gql`
    fragment adminTerritoryDetail on Territory {
        id
        description
        maximumRequestQuantity
        deliveryCutoff
        deliveryDelay
        pickupCutoff
        pickupDelay
        zipCodes
        area {
            id
            code
            scheduleTemplate {
                id
                startDate
                weeks {
                    monday {
                        id
                        startTime
                        endTime
                        week
                        day
                    }
                    tuesday {
                        id
                        startTime
                        endTime
                        week
                        day
                    }
                    wednesday {
                        id
                        startTime
                        endTime
                        week
                        day
                    }
                    thursday {
                        id
                        startTime
                        endTime
                        week
                        day
                    }
                    friday {
                        id
                        startTime
                        endTime
                        week
                        day
                    }
                    saturday {
                        id
                        startTime
                        endTime
                        week
                        day
                    }
                    sunday {
                        id
                        startTime
                        endTime
                        week
                        day
                    }
                }
                area {
                    id
                    code
                }
            }
        }
        excludedDeliveryWindows {
            id
            startTime
            endTime
            week
            day
        }
    }
`;
export const AdminTerritoryFragmentDoc = gql`
    fragment adminTerritory on Territory {
        id
        description
        maximumRequestQuantity
        deliveryCutoff
        deliveryDelay
        pickupCutoff
        pickupDelay
    }
`;
export const AdminVehicleDetailFragmentDoc = gql`
    fragment adminVehicleDetail on Vehicle {
        id
        created_at
        description
        model
        manufacture_year
        vin
        image
        isActive
    }
`;
export const AdminVehicleFragmentDoc = gql`
    fragment adminVehicle on Vehicle {
        id
        description
        model
        isActive
    }
`;
export const EmployeeVisitManagementFragmentDoc = gql`
    fragment EmployeeVisitManagement on Visit {
        id
        status
        startTime
        endTime
        expectedDuration
    }
`;
export const VisitManagementDetailEmployeeFragmentDoc = gql`
    fragment VisitManagementDetailEmployee on Visit {
        id
        status
        startTime
        endTime
        expectedDuration
        customerAddress {
            zip
        }
    }
`;
export const DeliveryCompleteDeliverStorageItemRequestFragmentDoc = gql`
    fragment DeliveryCompleteDeliverStorageItemRequest on DeliverStorageItemRequest {
        id
        status
        requestType
        storageItem {
            id
            status
            barcode
            storageItemType {
                id
                name
                description
                image
            }
        }
        customer {
            email
            firstName
            lastName
            homePhone
            cellPhone
        }
    }
`;
export const DeliveryCompletePickupStorageItemRequestFragmentDoc = gql`
    fragment DeliveryCompletePickupStorageItemRequest on PickupStorageItemRequest {
        id
        status
        requestType
        storageItem {
            id
            status
            barcode
            storageItemType {
                id
                name
                description
                image
            }
        }
        customer {
            email
            firstName
            lastName
            homePhone
            cellPhone
        }
    }
`;
export const DeliveryCompleteDeliverEmptyContainerRequestFragmentDoc = gql`
    fragment DeliveryCompleteDeliverEmptyContainerRequest on DeliverEmptyContainerRequest {
        id
        status
        requestType
        storageItem {
            id
            status
            barcode
            storageItemType {
                id
                name
                description
                image
                containerType {
                    id
                    description
                }
            }
        }
        customer {
            email
            firstName
            lastName
            homePhone
            cellPhone
        }
    }
`;
export const DeliveryCompletePickupEmptyContainerRequestFragmentDoc = gql`
    fragment DeliveryCompletePickupEmptyContainerRequest on PickupEmptyContainerRequest {
        id
        status
        requestType
        storageItem {
            id
            status
            barcode
            storageItemType {
                id
                name
                description
                image
            }
        }
        customer {
            email
            firstName
            lastName
            homePhone
            cellPhone
        }
    }
`;
export const DeliveryCompleteDriverPickupEmptyContainerRequestFragmentDoc = gql`
    fragment DeliveryCompleteDriverPickupEmptyContainerRequest on DriverPickupEmptyContainerRequest {
        id
        status
        requestType
        storageItem {
            id
            status
            barcode
            storageItemType {
                id
                name
                description
                image
            }
        }
        customer {
            email
            firstName
            lastName
            homePhone
            cellPhone
        }
    }
`;
export const DeliverEmptyContainerRequestsByVisitIdStorageItemFragmentDoc = gql`
    fragment deliverEmptyContainerRequestsByVisitIdStorageItem on StorageItem {
        id
        status
        barcode
        storageItemType {
            id
            name
            description
            image
            metadataDefinitions {
                id
                propertyName
                isRequired
                propertyType
                values
                isActive
                sortOrder
            }
        }
        metadataValue {
            value
            metadataDefinition {
                id
                propertyName
            }
        }
    }
`;
export const DeliverEmptyContainerRequestsByVisitIdDeliverRequestFragmentDoc = gql`
    fragment deliverEmptyContainerRequestsByVisitIdDeliverRequest on DeliverEmptyContainerRequest {
        id
        status
        requestType
        container {
            id
            status
            barcode
        }
        storageItem {
            id
            ...deliverEmptyContainerRequestsByVisitIdStorageItem
        }
        customer {
            email
            firstName
            lastName
            homePhone
            cellPhone
        }
    }
    ${DeliverEmptyContainerRequestsByVisitIdStorageItemFragmentDoc}
`;
export const DeliveryDeliverProcessDeliverStorageItemRequestFragmentDoc = gql`
    fragment DeliveryDeliverProcessDeliverStorageItemRequest on DeliverStorageItemRequest {
        id
        status
        requestType
        storageItem {
            id
            status
            barcode
            storageItemType {
                id
                name
                description
                image
            }
        }
        customer {
            email
            firstName
            lastName
            homePhone
            cellPhone
        }
    }
`;
export const PickupEmptyContainerRequestsByVisitIdStorageItemFragmentDoc = gql`
    fragment pickupEmptyContainerRequestsByVisitIdStorageItem on StorageItem {
        id
        status
        barcode
        storageItemType {
            id
            name
            description
            image
            metadataDefinitions {
                id
                propertyName
                isRequired
                propertyType
                values
                isActive
                sortOrder
            }
        }
        metadataValue {
            value
            metadataDefinition {
                id
                propertyName
            }
        }
    }
`;
export const PickupEmptyContainerRequestsByVisitIdDeliverRequestFragmentDoc = gql`
    fragment pickupEmptyContainerRequestsByVisitIdDeliverRequest on DeliverEmptyContainerRequest {
        id
        status
        requestType
        container {
            id
            status
            barcode
        }
        storageItem {
            id
            ...pickupEmptyContainerRequestsByVisitIdStorageItem
        }
        customer {
            email
            firstName
            lastName
            homePhone
            cellPhone
        }
    }
    ${PickupEmptyContainerRequestsByVisitIdStorageItemFragmentDoc}
`;
export const PickupRequestsByVisitIdStorageItemFragmentDoc = gql`
    fragment pickupRequestsByVisitIdStorageItem on StorageItem {
        id
        status
        barcode
        container {
            id
            barcode
        }
        storageItemType {
            id
            name
            description
            image
            containerType {
                id
            }
            metadataDefinitions {
                id
                propertyName
                isRequired
                propertyType
                values
                isActive
                sortOrder
            }
        }
        metadataValue {
            value
            metadataDefinition {
                id
                propertyName
            }
        }
    }
`;
export const PickupRequestsByVisitIdPickupRequestFragmentDoc = gql`
    fragment pickupRequestsByVisitIdPickupRequest on PickupStorageItemRequest {
        id
        status
        requestType
        container {
            id
            status
            barcode
        }
        storageItem {
            id
            ...pickupRequestsByVisitIdStorageItem
        }
        customer {
            email
            firstName
            lastName
            homePhone
            cellPhone
        }
    }
    ${PickupRequestsByVisitIdStorageItemFragmentDoc}
`;
export const DeliveryRouteDetailRouteStopFragmentDoc = gql`
    fragment deliveryRouteDetailRouteStop on RouteStop {
        id
        startTime
        endTime
        sequence
        visit {
            id
            status
            expectedDuration
            startTime
            endTime
            summary {
                inComing
                outGoing
                multiplePeople
                firstVisit
            }
            customerAddress {
                address1
                city
                countryId
                firstName
                lastName
            }
            scheduleWindow {
                startTime
                endTime
            }
            customer {
                email
                firstName
                lastName
                homePhone
                cellPhone
            }
            requests(filter: {}) {
                edges {
                    cursor
                    node {
                        id
                        status
                    }
                }
            }
        }
    }
`;
export const DeliveryRoutesRouteFragmentDoc = gql`
    fragment deliveryRoutesRoute on Route {
        id
        description
        routeNumber
        status
        operationDate
        employees {
            id
            firstName
            lastName
        }
        vehicles {
            description
        }
    }
`;
export const MovingProcessItemStorageItemFragmentDoc = gql`
    fragment movingProcessItemStorageItem on StorageItem {
        id
        status
        barcode
        container {
            id
            barcode
        }
        storageItemType {
            id
            name
            description
            image
        }
        location {
            description
            barcode
        }
    }
`;
export const MovingProcessItemLocationFragmentDoc = gql`
    fragment movingProcessItemLocation on Location {
        id
        barcode
        description
    }
`;
export const PickListFiltersVisitFragmentDoc = gql`
    fragment PickListFiltersVisit on Visit {
        id
    }
`;
export const PickListFiltersStorageItemTypeFragmentDoc = gql`
    fragment PickListFiltersStorageItemType on StorageItemType {
        id
        name
    }
`;
export const RetrievePickListsPickListFragmentDoc = gql`
    fragment RetrievePickListsPickList on PickList {
        id
        pickListNumber
        status
        created_at
        description
        summary {
            toBePicked
            picked
            excluded
        }
    }
`;
export const PicklistProcessPicklistItemFragmentDoc = gql`
    fragment PicklistProcessPicklistItem on PickListItem {
        id
        request {
            id
            status
        }
        storageItem {
            id
            barcode
            location {
                description
            }
            storageItemType {
                id
                name
                description
            }
        }
    }
`;
export const PicklistSummaryPicklistItemFragmentDoc = gql`
    fragment PicklistSummaryPicklistItem on PickListItem {
        id
        request {
            id
            status
        }
        storageItem {
            id
            barcode
            location {
                description
            }
            storageItemType {
                id
                name
                description
            }
        }
    }
`;
export const RefilingLocationFragmentDoc = gql`
    fragment RefilingLocation on Location {
        id
        barcode
        description
    }
`;
export const RefilingStorageItemFragmentDoc = gql`
    fragment RefilingStorageItem on StorageItem {
        id
        status
        barcode
        storageItemType {
            id
            name
            description
            image
        }
    }
`;
export const RefileListFragmentDoc = gql`
    fragment refileList on RefileList {
        id
        refileListNumber
        status
        created_at
    }
`;
export const RefileItemProcessItemFragmentDoc = gql`
    fragment refileItemProcessItem on RefileItem {
        id
        status
        storageItem {
            id
            status
            barcode
            container {
                id
                barcode
            }
            storageItemType {
                id
                name
                description
                image
                containerType {
                    id
                }
                metadataDefinitions {
                    id
                    propertyName
                    isRequired
                    propertyType
                    values
                    isActive
                    sortOrder
                }
            }
            metadataValue {
                value
                metadataDefinition {
                    id
                    propertyName
                }
            }
            location {
                description
                barcode
            }
        }
    }
`;
export const RefileListProcessItemFragmentDoc = gql`
    fragment refileListProcessItem on RefileList {
        id
        refileListNumber
        items {
            ...refileItemProcessItem
        }
    }
    ${RefileItemProcessItemFragmentDoc}
`;
export const RefilingProcessItemLocationFragmentDoc = gql`
    fragment RefilingProcessItemLocation on Location {
        id
        barcode
        description
    }
`;
export const RoutingVisitSummaryInformationFragmentDoc = gql`
    fragment RoutingVisitSummaryInformation on VisitSummary {
        inComing
        outGoing
        multiplePeople
    }
`;
export const RoutingVisitInformationFragmentDoc = gql`
    fragment RoutingVisitInformation on Visit {
        id
        status
        startTime
        endTime
        expectedDuration
        customerAddress {
            id
            address1
            address2
            city
            zoneId
            countryId
            zip
            longitude
            latitude
            addressType
            territory {
                id
            }
        }
        customer {
            id
            email
            firstName
            lastName
            homePhone
            cellPhone
        }
        scheduleWindow {
            id
            startTime
            endTime
        }
        summary {
            ...RoutingVisitSummaryInformation
        }
        stop {
            id
            route {
                id
            }
            startTime
            endTime
        }
    }
    ${RoutingVisitSummaryInformationFragmentDoc}
`;
export const RoutingBuildingInformationFragmentDoc = gql`
    fragment RoutingBuildingInformation on Building {
        id
        description
    }
`;
export const RoutingTerritoryInformationFragmentDoc = gql`
    fragment RoutingTerritoryInformation on Territory {
        id
        description
    }
`;
export const RoutingDeliveryWindowInformationFragmentDoc = gql`
    fragment RoutingDeliveryWindowInformation on ScheduleWindow {
        id
        startTime
        endTime
    }
`;
export const RoutingRouteInformationFragmentDoc = gql`
    fragment RoutingRouteInformation on Route {
        id
        description
        employees {
            id
        }
        vehicles {
            id
        }
    }
`;
export const SortingLocationFragmentDoc = gql`
    fragment SortingLocation on Location {
        id
        barcode
        description
        sortedItems
        itemsToSort
    }
`;
export const SortingStorageItemFragmentDoc = gql`
    fragment SortingStorageItem on StorageItem {
        id
        status
        barcode
        location {
            id
            ...SortingLocation
        }
        storageItemType {
            id
            name
            description
            image
            metadataDefinitions {
                propertyName
                isRequired
                propertyType
                values
                isActive
                sortOrder
            }
        }
        latestVisit {
            id
            locations {
                id
                ...SortingLocation
            }
        }
    }
    ${SortingLocationFragmentDoc}
`;
export const TruckLoadingDeliverProcessDeliverStorageItemRequestFragmentDoc = gql`
    fragment TruckLoadingDeliverProcessDeliverStorageItemRequest on DeliverStorageItemRequest {
        id
        status
        requestType
        storageItem {
            id
            status
            barcode
            location {
                description
            }
            storageItemType {
                id
                name
                description
                image
            }
        }
        customer {
            email
            firstName
            lastName
            homePhone
            cellPhone
        }
    }
`;
export const TruckLoadingDeliverProcessDeliverEmptyContainerRequestFragmentDoc = gql`
    fragment TruckLoadingDeliverProcessDeliverEmptyContainerRequest on DeliverEmptyContainerRequest {
        id
        status
        requestType
        storageItem {
            id
            status
            barcode
            location {
                description
            }
            storageItemType {
                id
                name
                description
                image
            }
        }
        customer {
            email
            firstName
            lastName
            homePhone
            cellPhone
        }
    }
`;
export const TruckLoadingRouteDetailRouteStopFragmentDoc = gql`
    fragment truckLoadingRouteDetailRouteStop on RouteStop {
        id
        startTime
        endTime
        sequence
        visit {
            id
            status
            expectedDuration
            startTime
            endTime
            summary {
                inComing
                outGoing
                multiplePeople
                firstVisit
            }
            customerAddress {
                address1
                city
                countryId
                firstName
                lastName
            }
            scheduleWindow {
                startTime
                endTime
            }
            customer {
                email
                firstName
                lastName
                homePhone
                cellPhone
            }
            requests(filter: {}) {
                edges {
                    cursor
                    node {
                        id
                        status
                    }
                }
            }
        }
    }
`;
export const TruckLoadingRoutesRouteFragmentDoc = gql`
    fragment truckLoadingRoutesRoute on Route {
        id
        description
        routeNumber
        status
        operationDate
        employees {
            id
            firstName
            lastName
        }
        vehicles {
            description
        }
    }
`;
export const TruckUnloadingProcessItemRouteFragmentDoc = gql`
    fragment TruckUnloadingProcessItemRoute on Route {
        id
        routeNumber
    }
`;
export const TruckUnloadingLocationFragmentDoc = gql`
    fragment TruckUnloadingLocation on Location {
        id
        barcode
        description
        type
    }
`;
export const TruckUnloadingVehiclesVehicleFragmentDoc = gql`
    fragment truckUnloadingVehiclesVehicle on Vehicle {
        id
        description
    }
`;
export const VisitReviewMapVisitFragmentDoc = gql`
    fragment VisitReviewMapVisit on Visit {
        id
        customerAddress {
            longitude
            latitude
            territory {
                id
            }
            firstName
            lastName
            address1
            city
            zoneId
            countryId
            zip
        }
        summary {
            multiplePeople
            inComing
            outGoing
        }
    }
`;
export const VisitReviewVisitRequestScheduleWindowFragmentDoc = gql`
    fragment VisitReviewVisitRequestScheduleWindow on ScheduleWindow {
        id
        isLocked
        startTime
        endTime
    }
`;
export const VisitReviewVisitRequestPickupStorageItemRequestFragmentDoc = gql`
    fragment VisitReviewVisitRequestPickupStorageItemRequest on PickupStorageItemRequest {
        id
        requestType
        storageItem {
            id
            storageItemType {
                id
                name
            }
        }
    }
`;
export const VisitReviewVisitRequestDeliverStorageItemRequestFragmentDoc = gql`
    fragment VisitReviewVisitRequestDeliverStorageItemRequest on DeliverStorageItemRequest {
        id
        requestType
        storageItem {
            id
            storageItemType {
                id
                name
            }
        }
    }
`;
export const VisitReviewVisitRequestDeliverEmptyContainerRequestFragmentDoc = gql`
    fragment VisitReviewVisitRequestDeliverEmptyContainerRequest on DeliverEmptyContainerRequest {
        id
        requestType
        storageItem {
            id
            storageItemType {
                id
                name
            }
        }
    }
`;
export const VisitReviewVisitRequestPickupEmptyContainerRequestFragmentDoc = gql`
    fragment VisitReviewVisitRequestPickupEmptyContainerRequest on PickupEmptyContainerRequest {
        id
        requestType
        storageItem {
            id
            storageItemType {
                id
                name
            }
        }
    }
`;
export const VisitReviewVisitRequestDriverPickupEmptyContainerRequestFragmentDoc = gql`
    fragment VisitReviewVisitRequestDriverPickupEmptyContainerRequest on DriverPickupEmptyContainerRequest {
        id
        requestType
        storageItem {
            id
            storageItemType {
                id
                name
            }
        }
    }
`;
export const ApplicationContextDocument = gql`
    query ApplicationContext {
        serviceProvider {
            name
            employeeUserPool
            cognitoRegion
            cognitoWebApplicationId
            cognitoHostedDomain
            cognitoSocialSignInCallback
            cognitoSocialSignOutCallback
        }
    }
`;

/**
 * __useApplicationContextQuery__
 *
 * To run a query within a React component, call `useApplicationContextQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicationContextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicationContextQuery({
 *   variables: {
 *   },
 * });
 */
export function useApplicationContextQuery(
    baseOptions?: Apollo.QueryHookOptions<
        ApplicationContextQuery,
        ApplicationContextQueryVariables
    >,
) {
    return Apollo.useQuery<ApplicationContextQuery, ApplicationContextQueryVariables>(
        ApplicationContextDocument,
        baseOptions,
    );
}
export function useApplicationContextLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        ApplicationContextQuery,
        ApplicationContextQueryVariables
    >,
) {
    return Apollo.useLazyQuery<ApplicationContextQuery, ApplicationContextQueryVariables>(
        ApplicationContextDocument,
        baseOptions,
    );
}
export type ApplicationContextQueryHookResult = ReturnType<typeof useApplicationContextQuery>;
export type ApplicationContextLazyQueryHookResult = ReturnType<
    typeof useApplicationContextLazyQuery
>;
export type ApplicationContextQueryResult = Apollo.QueryResult<
    ApplicationContextQuery,
    ApplicationContextQueryVariables
>;
export const GetAreaByIdDocument = gql`
    query getAreaById($areaId: String!) {
        areaById(id: $areaId) {
            ...adminAreaDetail
        }
    }
    ${AdminAreaDetailFragmentDoc}
`;

/**
 * __useGetAreaByIdQuery__
 *
 * To run a query within a React component, call `useGetAreaByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAreaByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAreaByIdQuery({
 *   variables: {
 *      areaId: // value for 'areaId'
 *   },
 * });
 */
export function useGetAreaByIdQuery(
    baseOptions: Apollo.QueryHookOptions<GetAreaByIdQuery, GetAreaByIdQueryVariables>,
) {
    return Apollo.useQuery<GetAreaByIdQuery, GetAreaByIdQueryVariables>(
        GetAreaByIdDocument,
        baseOptions,
    );
}
export function useGetAreaByIdLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetAreaByIdQuery, GetAreaByIdQueryVariables>,
) {
    return Apollo.useLazyQuery<GetAreaByIdQuery, GetAreaByIdQueryVariables>(
        GetAreaByIdDocument,
        baseOptions,
    );
}
export type GetAreaByIdQueryHookResult = ReturnType<typeof useGetAreaByIdQuery>;
export type GetAreaByIdLazyQueryHookResult = ReturnType<typeof useGetAreaByIdLazyQuery>;
export type GetAreaByIdQueryResult = Apollo.QueryResult<
    GetAreaByIdQuery,
    GetAreaByIdQueryVariables
>;
export const AdminUpdateAreaDocument = gql`
    mutation adminUpdateArea($data: AdminUpdateAreaInput!) {
        adminUpdateArea(data: $data) {
            data {
                id
                code
            }
        }
    }
`;
export type AdminUpdateAreaMutationFn = Apollo.MutationFunction<
    AdminUpdateAreaMutation,
    AdminUpdateAreaMutationVariables
>;

/**
 * __useAdminUpdateAreaMutation__
 *
 * To run a mutation, you first call `useAdminUpdateAreaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateAreaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateAreaMutation, { data, loading, error }] = useAdminUpdateAreaMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdminUpdateAreaMutation(
    baseOptions?: Apollo.MutationHookOptions<
        AdminUpdateAreaMutation,
        AdminUpdateAreaMutationVariables
    >,
) {
    return Apollo.useMutation<AdminUpdateAreaMutation, AdminUpdateAreaMutationVariables>(
        AdminUpdateAreaDocument,
        baseOptions,
    );
}
export type AdminUpdateAreaMutationHookResult = ReturnType<typeof useAdminUpdateAreaMutation>;
export type AdminUpdateAreaMutationResult = Apollo.MutationResult<AdminUpdateAreaMutation>;
export type AdminUpdateAreaMutationOptions = Apollo.BaseMutationOptions<
    AdminUpdateAreaMutation,
    AdminUpdateAreaMutationVariables
>;
export const GetAreasDocument = gql`
    query getAreas {
        areas {
            ...adminArea
        }
    }
    ${AdminAreaFragmentDoc}
`;

/**
 * __useGetAreasQuery__
 *
 * To run a query within a React component, call `useGetAreasQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAreasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAreasQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAreasQuery(
    baseOptions?: Apollo.QueryHookOptions<GetAreasQuery, GetAreasQueryVariables>,
) {
    return Apollo.useQuery<GetAreasQuery, GetAreasQueryVariables>(GetAreasDocument, baseOptions);
}
export function useGetAreasLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetAreasQuery, GetAreasQueryVariables>,
) {
    return Apollo.useLazyQuery<GetAreasQuery, GetAreasQueryVariables>(
        GetAreasDocument,
        baseOptions,
    );
}
export type GetAreasQueryHookResult = ReturnType<typeof useGetAreasQuery>;
export type GetAreasLazyQueryHookResult = ReturnType<typeof useGetAreasLazyQuery>;
export type GetAreasQueryResult = Apollo.QueryResult<GetAreasQuery, GetAreasQueryVariables>;
export const GetAdminBuildingAreasDocument = gql`
    query getAdminBuildingAreas {
        areas {
            ...adminBuildingArea
        }
    }
    ${AdminBuildingAreaFragmentDoc}
`;

/**
 * __useGetAdminBuildingAreasQuery__
 *
 * To run a query within a React component, call `useGetAdminBuildingAreasQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminBuildingAreasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminBuildingAreasQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAdminBuildingAreasQuery(
    baseOptions?: Apollo.QueryHookOptions<
        GetAdminBuildingAreasQuery,
        GetAdminBuildingAreasQueryVariables
    >,
) {
    return Apollo.useQuery<GetAdminBuildingAreasQuery, GetAdminBuildingAreasQueryVariables>(
        GetAdminBuildingAreasDocument,
        baseOptions,
    );
}
export function useGetAdminBuildingAreasLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetAdminBuildingAreasQuery,
        GetAdminBuildingAreasQueryVariables
    >,
) {
    return Apollo.useLazyQuery<GetAdminBuildingAreasQuery, GetAdminBuildingAreasQueryVariables>(
        GetAdminBuildingAreasDocument,
        baseOptions,
    );
}
export type GetAdminBuildingAreasQueryHookResult = ReturnType<typeof useGetAdminBuildingAreasQuery>;
export type GetAdminBuildingAreasLazyQueryHookResult = ReturnType<
    typeof useGetAdminBuildingAreasLazyQuery
>;
export type GetAdminBuildingAreasQueryResult = Apollo.QueryResult<
    GetAdminBuildingAreasQuery,
    GetAdminBuildingAreasQueryVariables
>;
export const AdminAddBuildingDocument = gql`
    mutation adminAddBuilding($data: AdminAddBuildingInput!) {
        adminAddBuilding(data: $data) {
            data {
                id
                code
                description
                isActive
                address {
                    id
                    address1
                    address2
                    city
                    zoneId
                    countryId
                    zip
                    longitude
                    latitude
                    phone
                    firstName
                    lastName
                }
                area {
                    id
                    code
                }
            }
        }
    }
`;
export type AdminAddBuildingMutationFn = Apollo.MutationFunction<
    AdminAddBuildingMutation,
    AdminAddBuildingMutationVariables
>;

/**
 * __useAdminAddBuildingMutation__
 *
 * To run a mutation, you first call `useAdminAddBuildingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminAddBuildingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminAddBuildingMutation, { data, loading, error }] = useAdminAddBuildingMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdminAddBuildingMutation(
    baseOptions?: Apollo.MutationHookOptions<
        AdminAddBuildingMutation,
        AdminAddBuildingMutationVariables
    >,
) {
    return Apollo.useMutation<AdminAddBuildingMutation, AdminAddBuildingMutationVariables>(
        AdminAddBuildingDocument,
        baseOptions,
    );
}
export type AdminAddBuildingMutationHookResult = ReturnType<typeof useAdminAddBuildingMutation>;
export type AdminAddBuildingMutationResult = Apollo.MutationResult<AdminAddBuildingMutation>;
export type AdminAddBuildingMutationOptions = Apollo.BaseMutationOptions<
    AdminAddBuildingMutation,
    AdminAddBuildingMutationVariables
>;
export const GetAdminBuildingByIdDocument = gql`
    query getAdminBuildingById($buildingId: String!) {
        buildingById(id: $buildingId) {
            ...adminBuildingDetail
        }
    }
    ${AdminBuildingDetailFragmentDoc}
`;

/**
 * __useGetAdminBuildingByIdQuery__
 *
 * To run a query within a React component, call `useGetAdminBuildingByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminBuildingByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminBuildingByIdQuery({
 *   variables: {
 *      buildingId: // value for 'buildingId'
 *   },
 * });
 */
export function useGetAdminBuildingByIdQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetAdminBuildingByIdQuery,
        GetAdminBuildingByIdQueryVariables
    >,
) {
    return Apollo.useQuery<GetAdminBuildingByIdQuery, GetAdminBuildingByIdQueryVariables>(
        GetAdminBuildingByIdDocument,
        baseOptions,
    );
}
export function useGetAdminBuildingByIdLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetAdminBuildingByIdQuery,
        GetAdminBuildingByIdQueryVariables
    >,
) {
    return Apollo.useLazyQuery<GetAdminBuildingByIdQuery, GetAdminBuildingByIdQueryVariables>(
        GetAdminBuildingByIdDocument,
        baseOptions,
    );
}
export type GetAdminBuildingByIdQueryHookResult = ReturnType<typeof useGetAdminBuildingByIdQuery>;
export type GetAdminBuildingByIdLazyQueryHookResult = ReturnType<
    typeof useGetAdminBuildingByIdLazyQuery
>;
export type GetAdminBuildingByIdQueryResult = Apollo.QueryResult<
    GetAdminBuildingByIdQuery,
    GetAdminBuildingByIdQueryVariables
>;
export const AdminUpdateBuildingDocument = gql`
    mutation adminUpdateBuilding($data: AdminUpdateBuildingInput!) {
        adminUpdateBuilding(data: $data) {
            data {
                id
                ...adminBuildingDetail
            }
        }
    }
    ${AdminBuildingDetailFragmentDoc}
`;
export type AdminUpdateBuildingMutationFn = Apollo.MutationFunction<
    AdminUpdateBuildingMutation,
    AdminUpdateBuildingMutationVariables
>;

/**
 * __useAdminUpdateBuildingMutation__
 *
 * To run a mutation, you first call `useAdminUpdateBuildingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateBuildingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateBuildingMutation, { data, loading, error }] = useAdminUpdateBuildingMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdminUpdateBuildingMutation(
    baseOptions?: Apollo.MutationHookOptions<
        AdminUpdateBuildingMutation,
        AdminUpdateBuildingMutationVariables
    >,
) {
    return Apollo.useMutation<AdminUpdateBuildingMutation, AdminUpdateBuildingMutationVariables>(
        AdminUpdateBuildingDocument,
        baseOptions,
    );
}
export type AdminUpdateBuildingMutationHookResult = ReturnType<
    typeof useAdminUpdateBuildingMutation
>;
export type AdminUpdateBuildingMutationResult = Apollo.MutationResult<AdminUpdateBuildingMutation>;
export type AdminUpdateBuildingMutationOptions = Apollo.BaseMutationOptions<
    AdminUpdateBuildingMutation,
    AdminUpdateBuildingMutationVariables
>;
export const GetAdminBuildingsDocument = gql`
    query getAdminBuildings($pageSize: Int!, $cursor: String!) {
        buildings(filter: { pageSize: $pageSize, after: $cursor }) {
            edges {
                cursor
                node {
                    id
                    ...adminBuilding
                }
            }
            pageInfo {
                cursor {
                    beforeCursor
                    afterCursor
                }
                hasNextPage
                hasPreviousPage
            }
        }
    }
    ${AdminBuildingFragmentDoc}
`;

/**
 * __useGetAdminBuildingsQuery__
 *
 * To run a query within a React component, call `useGetAdminBuildingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminBuildingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminBuildingsQuery({
 *   variables: {
 *      pageSize: // value for 'pageSize'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetAdminBuildingsQuery(
    baseOptions: Apollo.QueryHookOptions<GetAdminBuildingsQuery, GetAdminBuildingsQueryVariables>,
) {
    return Apollo.useQuery<GetAdminBuildingsQuery, GetAdminBuildingsQueryVariables>(
        GetAdminBuildingsDocument,
        baseOptions,
    );
}
export function useGetAdminBuildingsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetAdminBuildingsQuery,
        GetAdminBuildingsQueryVariables
    >,
) {
    return Apollo.useLazyQuery<GetAdminBuildingsQuery, GetAdminBuildingsQueryVariables>(
        GetAdminBuildingsDocument,
        baseOptions,
    );
}
export type GetAdminBuildingsQueryHookResult = ReturnType<typeof useGetAdminBuildingsQuery>;
export type GetAdminBuildingsLazyQueryHookResult = ReturnType<typeof useGetAdminBuildingsLazyQuery>;
export type GetAdminBuildingsQueryResult = Apollo.QueryResult<
    GetAdminBuildingsQuery,
    GetAdminBuildingsQueryVariables
>;
export const AdminAddEmployeeDocument = gql`
    mutation adminAddEmployee($data: AdminAddEmployeeInput!) {
        adminAddEmployee(data: $data) {
            data {
                id
            }
        }
    }
`;
export type AdminAddEmployeeMutationFn = Apollo.MutationFunction<
    AdminAddEmployeeMutation,
    AdminAddEmployeeMutationVariables
>;

/**
 * __useAdminAddEmployeeMutation__
 *
 * To run a mutation, you first call `useAdminAddEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminAddEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminAddEmployeeMutation, { data, loading, error }] = useAdminAddEmployeeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdminAddEmployeeMutation(
    baseOptions?: Apollo.MutationHookOptions<
        AdminAddEmployeeMutation,
        AdminAddEmployeeMutationVariables
    >,
) {
    return Apollo.useMutation<AdminAddEmployeeMutation, AdminAddEmployeeMutationVariables>(
        AdminAddEmployeeDocument,
        baseOptions,
    );
}
export type AdminAddEmployeeMutationHookResult = ReturnType<typeof useAdminAddEmployeeMutation>;
export type AdminAddEmployeeMutationResult = Apollo.MutationResult<AdminAddEmployeeMutation>;
export type AdminAddEmployeeMutationOptions = Apollo.BaseMutationOptions<
    AdminAddEmployeeMutation,
    AdminAddEmployeeMutationVariables
>;
export const EmployeeDetailGetEmployeeByIdDocument = gql`
    query employeeDetailGetEmployeeById($employeeId: String!) {
        employeeById(id: $employeeId) {
            ...adminEmployeeDetail
        }
    }
    ${AdminEmployeeDetailFragmentDoc}
`;

/**
 * __useEmployeeDetailGetEmployeeByIdQuery__
 *
 * To run a query within a React component, call `useEmployeeDetailGetEmployeeByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeDetailGetEmployeeByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeDetailGetEmployeeByIdQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *   },
 * });
 */
export function useEmployeeDetailGetEmployeeByIdQuery(
    baseOptions: Apollo.QueryHookOptions<
        EmployeeDetailGetEmployeeByIdQuery,
        EmployeeDetailGetEmployeeByIdQueryVariables
    >,
) {
    return Apollo.useQuery<
        EmployeeDetailGetEmployeeByIdQuery,
        EmployeeDetailGetEmployeeByIdQueryVariables
    >(EmployeeDetailGetEmployeeByIdDocument, baseOptions);
}
export function useEmployeeDetailGetEmployeeByIdLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        EmployeeDetailGetEmployeeByIdQuery,
        EmployeeDetailGetEmployeeByIdQueryVariables
    >,
) {
    return Apollo.useLazyQuery<
        EmployeeDetailGetEmployeeByIdQuery,
        EmployeeDetailGetEmployeeByIdQueryVariables
    >(EmployeeDetailGetEmployeeByIdDocument, baseOptions);
}
export type EmployeeDetailGetEmployeeByIdQueryHookResult = ReturnType<
    typeof useEmployeeDetailGetEmployeeByIdQuery
>;
export type EmployeeDetailGetEmployeeByIdLazyQueryHookResult = ReturnType<
    typeof useEmployeeDetailGetEmployeeByIdLazyQuery
>;
export type EmployeeDetailGetEmployeeByIdQueryResult = Apollo.QueryResult<
    EmployeeDetailGetEmployeeByIdQuery,
    EmployeeDetailGetEmployeeByIdQueryVariables
>;
export const AdminUpdateEmployeeDocument = gql`
    mutation adminUpdateEmployee($data: AdminUpdateEmployeeInput!) {
        adminUpdateEmployee(data: $data) {
            data {
                id
            }
        }
    }
`;
export type AdminUpdateEmployeeMutationFn = Apollo.MutationFunction<
    AdminUpdateEmployeeMutation,
    AdminUpdateEmployeeMutationVariables
>;

/**
 * __useAdminUpdateEmployeeMutation__
 *
 * To run a mutation, you first call `useAdminUpdateEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateEmployeeMutation, { data, loading, error }] = useAdminUpdateEmployeeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdminUpdateEmployeeMutation(
    baseOptions?: Apollo.MutationHookOptions<
        AdminUpdateEmployeeMutation,
        AdminUpdateEmployeeMutationVariables
    >,
) {
    return Apollo.useMutation<AdminUpdateEmployeeMutation, AdminUpdateEmployeeMutationVariables>(
        AdminUpdateEmployeeDocument,
        baseOptions,
    );
}
export type AdminUpdateEmployeeMutationHookResult = ReturnType<
    typeof useAdminUpdateEmployeeMutation
>;
export type AdminUpdateEmployeeMutationResult = Apollo.MutationResult<AdminUpdateEmployeeMutation>;
export type AdminUpdateEmployeeMutationOptions = Apollo.BaseMutationOptions<
    AdminUpdateEmployeeMutation,
    AdminUpdateEmployeeMutationVariables
>;
export const AdminUpdateEmployeePasswordDocument = gql`
    mutation adminUpdateEmployeePassword($data: AdminUpdateEmployeePasswordInput!) {
        adminUpdateEmployeePassword(data: $data) {
            data {
                id
            }
        }
    }
`;
export type AdminUpdateEmployeePasswordMutationFn = Apollo.MutationFunction<
    AdminUpdateEmployeePasswordMutation,
    AdminUpdateEmployeePasswordMutationVariables
>;

/**
 * __useAdminUpdateEmployeePasswordMutation__
 *
 * To run a mutation, you first call `useAdminUpdateEmployeePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateEmployeePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateEmployeePasswordMutation, { data, loading, error }] = useAdminUpdateEmployeePasswordMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdminUpdateEmployeePasswordMutation(
    baseOptions?: Apollo.MutationHookOptions<
        AdminUpdateEmployeePasswordMutation,
        AdminUpdateEmployeePasswordMutationVariables
    >,
) {
    return Apollo.useMutation<
        AdminUpdateEmployeePasswordMutation,
        AdminUpdateEmployeePasswordMutationVariables
    >(AdminUpdateEmployeePasswordDocument, baseOptions);
}
export type AdminUpdateEmployeePasswordMutationHookResult = ReturnType<
    typeof useAdminUpdateEmployeePasswordMutation
>;
export type AdminUpdateEmployeePasswordMutationResult = Apollo.MutationResult<AdminUpdateEmployeePasswordMutation>;
export type AdminUpdateEmployeePasswordMutationOptions = Apollo.BaseMutationOptions<
    AdminUpdateEmployeePasswordMutation,
    AdminUpdateEmployeePasswordMutationVariables
>;
export const GetEmployeesDocument = gql`
    query getEmployees($pageSize: Int!, $cursor: String!) {
        employees(filter: { pageSize: $pageSize, after: $cursor }) {
            edges {
                cursor
                node {
                    id
                    ...adminEmployee
                }
            }
            pageInfo {
                cursor {
                    beforeCursor
                    afterCursor
                }
                hasNextPage
                hasPreviousPage
            }
        }
    }
    ${AdminEmployeeFragmentDoc}
`;

/**
 * __useGetEmployeesQuery__
 *
 * To run a query within a React component, call `useGetEmployeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeesQuery({
 *   variables: {
 *      pageSize: // value for 'pageSize'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetEmployeesQuery(
    baseOptions: Apollo.QueryHookOptions<GetEmployeesQuery, GetEmployeesQueryVariables>,
) {
    return Apollo.useQuery<GetEmployeesQuery, GetEmployeesQueryVariables>(
        GetEmployeesDocument,
        baseOptions,
    );
}
export function useGetEmployeesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetEmployeesQuery, GetEmployeesQueryVariables>,
) {
    return Apollo.useLazyQuery<GetEmployeesQuery, GetEmployeesQueryVariables>(
        GetEmployeesDocument,
        baseOptions,
    );
}
export type GetEmployeesQueryHookResult = ReturnType<typeof useGetEmployeesQuery>;
export type GetEmployeesLazyQueryHookResult = ReturnType<typeof useGetEmployeesLazyQuery>;
export type GetEmployeesQueryResult = Apollo.QueryResult<
    GetEmployeesQuery,
    GetEmployeesQueryVariables
>;
export const GetAdminAddLocationBuildingsDocument = gql`
    query getAdminAddLocationBuildings {
        buildingsAll {
            ...adminAddLocationBuilding
        }
    }
    ${AdminAddLocationBuildingFragmentDoc}
`;

/**
 * __useGetAdminAddLocationBuildingsQuery__
 *
 * To run a query within a React component, call `useGetAdminAddLocationBuildingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminAddLocationBuildingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminAddLocationBuildingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAdminAddLocationBuildingsQuery(
    baseOptions?: Apollo.QueryHookOptions<
        GetAdminAddLocationBuildingsQuery,
        GetAdminAddLocationBuildingsQueryVariables
    >,
) {
    return Apollo.useQuery<
        GetAdminAddLocationBuildingsQuery,
        GetAdminAddLocationBuildingsQueryVariables
    >(GetAdminAddLocationBuildingsDocument, baseOptions);
}
export function useGetAdminAddLocationBuildingsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetAdminAddLocationBuildingsQuery,
        GetAdminAddLocationBuildingsQueryVariables
    >,
) {
    return Apollo.useLazyQuery<
        GetAdminAddLocationBuildingsQuery,
        GetAdminAddLocationBuildingsQueryVariables
    >(GetAdminAddLocationBuildingsDocument, baseOptions);
}
export type GetAdminAddLocationBuildingsQueryHookResult = ReturnType<
    typeof useGetAdminAddLocationBuildingsQuery
>;
export type GetAdminAddLocationBuildingsLazyQueryHookResult = ReturnType<
    typeof useGetAdminAddLocationBuildingsLazyQuery
>;
export type GetAdminAddLocationBuildingsQueryResult = Apollo.QueryResult<
    GetAdminAddLocationBuildingsQuery,
    GetAdminAddLocationBuildingsQueryVariables
>;
export const AdminAddLocationDocument = gql`
    mutation adminAddLocation($data: [AdminAddLocationsInput!]) {
        adminAddLocation(data: $data) {
            data {
                id
            }
        }
    }
`;
export type AdminAddLocationMutationFn = Apollo.MutationFunction<
    AdminAddLocationMutation,
    AdminAddLocationMutationVariables
>;

/**
 * __useAdminAddLocationMutation__
 *
 * To run a mutation, you first call `useAdminAddLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminAddLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminAddLocationMutation, { data, loading, error }] = useAdminAddLocationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdminAddLocationMutation(
    baseOptions?: Apollo.MutationHookOptions<
        AdminAddLocationMutation,
        AdminAddLocationMutationVariables
    >,
) {
    return Apollo.useMutation<AdminAddLocationMutation, AdminAddLocationMutationVariables>(
        AdminAddLocationDocument,
        baseOptions,
    );
}
export type AdminAddLocationMutationHookResult = ReturnType<typeof useAdminAddLocationMutation>;
export type AdminAddLocationMutationResult = Apollo.MutationResult<AdminAddLocationMutation>;
export type AdminAddLocationMutationOptions = Apollo.BaseMutationOptions<
    AdminAddLocationMutation,
    AdminAddLocationMutationVariables
>;
export const GetAdminLocationDetailBuildingsDocument = gql`
    query getAdminLocationDetailBuildings {
        buildingsAll {
            ...adminLocationDetailBuilding
        }
    }
    ${AdminLocationDetailBuildingFragmentDoc}
`;

/**
 * __useGetAdminLocationDetailBuildingsQuery__
 *
 * To run a query within a React component, call `useGetAdminLocationDetailBuildingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminLocationDetailBuildingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminLocationDetailBuildingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAdminLocationDetailBuildingsQuery(
    baseOptions?: Apollo.QueryHookOptions<
        GetAdminLocationDetailBuildingsQuery,
        GetAdminLocationDetailBuildingsQueryVariables
    >,
) {
    return Apollo.useQuery<
        GetAdminLocationDetailBuildingsQuery,
        GetAdminLocationDetailBuildingsQueryVariables
    >(GetAdminLocationDetailBuildingsDocument, baseOptions);
}
export function useGetAdminLocationDetailBuildingsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetAdminLocationDetailBuildingsQuery,
        GetAdminLocationDetailBuildingsQueryVariables
    >,
) {
    return Apollo.useLazyQuery<
        GetAdminLocationDetailBuildingsQuery,
        GetAdminLocationDetailBuildingsQueryVariables
    >(GetAdminLocationDetailBuildingsDocument, baseOptions);
}
export type GetAdminLocationDetailBuildingsQueryHookResult = ReturnType<
    typeof useGetAdminLocationDetailBuildingsQuery
>;
export type GetAdminLocationDetailBuildingsLazyQueryHookResult = ReturnType<
    typeof useGetAdminLocationDetailBuildingsLazyQuery
>;
export type GetAdminLocationDetailBuildingsQueryResult = Apollo.QueryResult<
    GetAdminLocationDetailBuildingsQuery,
    GetAdminLocationDetailBuildingsQueryVariables
>;
export const GetAdminLocationByIdDocument = gql`
    query getAdminLocationById($locationId: String!) {
        locationById(id: $locationId) {
            ...adminLocationDetail
        }
    }
    ${AdminLocationDetailFragmentDoc}
`;

/**
 * __useGetAdminLocationByIdQuery__
 *
 * To run a query within a React component, call `useGetAdminLocationByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminLocationByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminLocationByIdQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useGetAdminLocationByIdQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetAdminLocationByIdQuery,
        GetAdminLocationByIdQueryVariables
    >,
) {
    return Apollo.useQuery<GetAdminLocationByIdQuery, GetAdminLocationByIdQueryVariables>(
        GetAdminLocationByIdDocument,
        baseOptions,
    );
}
export function useGetAdminLocationByIdLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetAdminLocationByIdQuery,
        GetAdminLocationByIdQueryVariables
    >,
) {
    return Apollo.useLazyQuery<GetAdminLocationByIdQuery, GetAdminLocationByIdQueryVariables>(
        GetAdminLocationByIdDocument,
        baseOptions,
    );
}
export type GetAdminLocationByIdQueryHookResult = ReturnType<typeof useGetAdminLocationByIdQuery>;
export type GetAdminLocationByIdLazyQueryHookResult = ReturnType<
    typeof useGetAdminLocationByIdLazyQuery
>;
export type GetAdminLocationByIdQueryResult = Apollo.QueryResult<
    GetAdminLocationByIdQuery,
    GetAdminLocationByIdQueryVariables
>;
export const AdminUpdateLocationDocument = gql`
    mutation adminUpdateLocation($data: AdminUpdateLocationInput!) {
        adminUpdateLocation(data: $data) {
            data {
                ...adminLocationDetail
            }
        }
    }
    ${AdminLocationDetailFragmentDoc}
`;
export type AdminUpdateLocationMutationFn = Apollo.MutationFunction<
    AdminUpdateLocationMutation,
    AdminUpdateLocationMutationVariables
>;

/**
 * __useAdminUpdateLocationMutation__
 *
 * To run a mutation, you first call `useAdminUpdateLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateLocationMutation, { data, loading, error }] = useAdminUpdateLocationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdminUpdateLocationMutation(
    baseOptions?: Apollo.MutationHookOptions<
        AdminUpdateLocationMutation,
        AdminUpdateLocationMutationVariables
    >,
) {
    return Apollo.useMutation<AdminUpdateLocationMutation, AdminUpdateLocationMutationVariables>(
        AdminUpdateLocationDocument,
        baseOptions,
    );
}
export type AdminUpdateLocationMutationHookResult = ReturnType<
    typeof useAdminUpdateLocationMutation
>;
export type AdminUpdateLocationMutationResult = Apollo.MutationResult<AdminUpdateLocationMutation>;
export type AdminUpdateLocationMutationOptions = Apollo.BaseMutationOptions<
    AdminUpdateLocationMutation,
    AdminUpdateLocationMutationVariables
>;
export const GetAdminLocationsDocument = gql`
    query getAdminLocations($pageSize: Int!, $afterCursor: String, $beforeCursor: String) {
        locations(filter: { pageSize: $pageSize, after: $afterCursor, before: $beforeCursor }) {
            edges {
                cursor
                node {
                    id
                    ...adminLocation
                }
            }
            pageInfo {
                cursor {
                    beforeCursor
                    afterCursor
                }
                hasNextPage
                hasPreviousPage
            }
        }
    }
    ${AdminLocationFragmentDoc}
`;

/**
 * __useGetAdminLocationsQuery__
 *
 * To run a query within a React component, call `useGetAdminLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminLocationsQuery({
 *   variables: {
 *      pageSize: // value for 'pageSize'
 *      afterCursor: // value for 'afterCursor'
 *      beforeCursor: // value for 'beforeCursor'
 *   },
 * });
 */
export function useGetAdminLocationsQuery(
    baseOptions: Apollo.QueryHookOptions<GetAdminLocationsQuery, GetAdminLocationsQueryVariables>,
) {
    return Apollo.useQuery<GetAdminLocationsQuery, GetAdminLocationsQueryVariables>(
        GetAdminLocationsDocument,
        baseOptions,
    );
}
export function useGetAdminLocationsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetAdminLocationsQuery,
        GetAdminLocationsQueryVariables
    >,
) {
    return Apollo.useLazyQuery<GetAdminLocationsQuery, GetAdminLocationsQueryVariables>(
        GetAdminLocationsDocument,
        baseOptions,
    );
}
export type GetAdminLocationsQueryHookResult = ReturnType<typeof useGetAdminLocationsQuery>;
export type GetAdminLocationsLazyQueryHookResult = ReturnType<typeof useGetAdminLocationsLazyQuery>;
export type GetAdminLocationsQueryResult = Apollo.QueryResult<
    GetAdminLocationsQuery,
    GetAdminLocationsQueryVariables
>;
export const GetAdminScheduleTemplateAreasDocument = gql`
    query getAdminScheduleTemplateAreas {
        areas {
            ...adminScheduleTemplateArea
        }
    }
    ${AdminScheduleTemplateAreaFragmentDoc}
`;

/**
 * __useGetAdminScheduleTemplateAreasQuery__
 *
 * To run a query within a React component, call `useGetAdminScheduleTemplateAreasQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminScheduleTemplateAreasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminScheduleTemplateAreasQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAdminScheduleTemplateAreasQuery(
    baseOptions?: Apollo.QueryHookOptions<
        GetAdminScheduleTemplateAreasQuery,
        GetAdminScheduleTemplateAreasQueryVariables
    >,
) {
    return Apollo.useQuery<
        GetAdminScheduleTemplateAreasQuery,
        GetAdminScheduleTemplateAreasQueryVariables
    >(GetAdminScheduleTemplateAreasDocument, baseOptions);
}
export function useGetAdminScheduleTemplateAreasLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetAdminScheduleTemplateAreasQuery,
        GetAdminScheduleTemplateAreasQueryVariables
    >,
) {
    return Apollo.useLazyQuery<
        GetAdminScheduleTemplateAreasQuery,
        GetAdminScheduleTemplateAreasQueryVariables
    >(GetAdminScheduleTemplateAreasDocument, baseOptions);
}
export type GetAdminScheduleTemplateAreasQueryHookResult = ReturnType<
    typeof useGetAdminScheduleTemplateAreasQuery
>;
export type GetAdminScheduleTemplateAreasLazyQueryHookResult = ReturnType<
    typeof useGetAdminScheduleTemplateAreasLazyQuery
>;
export type GetAdminScheduleTemplateAreasQueryResult = Apollo.QueryResult<
    GetAdminScheduleTemplateAreasQuery,
    GetAdminScheduleTemplateAreasQueryVariables
>;
export const GetAdminScheduleTemplateByIdDocument = gql`
    query getAdminScheduleTemplateById($scheduleTemplateId: String!) {
        scheduleTemplateById(id: $scheduleTemplateId) {
            ...adminScheduleTemplateDetail
        }
    }
    ${AdminScheduleTemplateDetailFragmentDoc}
`;

/**
 * __useGetAdminScheduleTemplateByIdQuery__
 *
 * To run a query within a React component, call `useGetAdminScheduleTemplateByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminScheduleTemplateByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminScheduleTemplateByIdQuery({
 *   variables: {
 *      scheduleTemplateId: // value for 'scheduleTemplateId'
 *   },
 * });
 */
export function useGetAdminScheduleTemplateByIdQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetAdminScheduleTemplateByIdQuery,
        GetAdminScheduleTemplateByIdQueryVariables
    >,
) {
    return Apollo.useQuery<
        GetAdminScheduleTemplateByIdQuery,
        GetAdminScheduleTemplateByIdQueryVariables
    >(GetAdminScheduleTemplateByIdDocument, baseOptions);
}
export function useGetAdminScheduleTemplateByIdLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetAdminScheduleTemplateByIdQuery,
        GetAdminScheduleTemplateByIdQueryVariables
    >,
) {
    return Apollo.useLazyQuery<
        GetAdminScheduleTemplateByIdQuery,
        GetAdminScheduleTemplateByIdQueryVariables
    >(GetAdminScheduleTemplateByIdDocument, baseOptions);
}
export type GetAdminScheduleTemplateByIdQueryHookResult = ReturnType<
    typeof useGetAdminScheduleTemplateByIdQuery
>;
export type GetAdminScheduleTemplateByIdLazyQueryHookResult = ReturnType<
    typeof useGetAdminScheduleTemplateByIdLazyQuery
>;
export type GetAdminScheduleTemplateByIdQueryResult = Apollo.QueryResult<
    GetAdminScheduleTemplateByIdQuery,
    GetAdminScheduleTemplateByIdQueryVariables
>;
export const AdminAddScheduleTemplatePeriodDocument = gql`
    mutation adminAddScheduleTemplatePeriod($data: AdminAddScheduleTemplatePeriodInput!) {
        adminAddScheduleTemplatePeriod(data: $data) {
            data {
                id
                ...adminScheduleTemplatePeriodDetail
            }
        }
    }
    ${AdminScheduleTemplatePeriodDetailFragmentDoc}
`;
export type AdminAddScheduleTemplatePeriodMutationFn = Apollo.MutationFunction<
    AdminAddScheduleTemplatePeriodMutation,
    AdminAddScheduleTemplatePeriodMutationVariables
>;

/**
 * __useAdminAddScheduleTemplatePeriodMutation__
 *
 * To run a mutation, you first call `useAdminAddScheduleTemplatePeriodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminAddScheduleTemplatePeriodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminAddScheduleTemplatePeriodMutation, { data, loading, error }] = useAdminAddScheduleTemplatePeriodMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdminAddScheduleTemplatePeriodMutation(
    baseOptions?: Apollo.MutationHookOptions<
        AdminAddScheduleTemplatePeriodMutation,
        AdminAddScheduleTemplatePeriodMutationVariables
    >,
) {
    return Apollo.useMutation<
        AdminAddScheduleTemplatePeriodMutation,
        AdminAddScheduleTemplatePeriodMutationVariables
    >(AdminAddScheduleTemplatePeriodDocument, baseOptions);
}
export type AdminAddScheduleTemplatePeriodMutationHookResult = ReturnType<
    typeof useAdminAddScheduleTemplatePeriodMutation
>;
export type AdminAddScheduleTemplatePeriodMutationResult = Apollo.MutationResult<AdminAddScheduleTemplatePeriodMutation>;
export type AdminAddScheduleTemplatePeriodMutationOptions = Apollo.BaseMutationOptions<
    AdminAddScheduleTemplatePeriodMutation,
    AdminAddScheduleTemplatePeriodMutationVariables
>;
export const AdminUpdateScheduleTemplatePeriodDocument = gql`
    mutation adminUpdateScheduleTemplatePeriod($data: AdminUpdateScheduleTemplatePeriodInput!) {
        adminUpdateScheduleTemplatePeriod(data: $data) {
            data {
                id
                ...adminScheduleTemplatePeriodDetail
            }
        }
    }
    ${AdminScheduleTemplatePeriodDetailFragmentDoc}
`;
export type AdminUpdateScheduleTemplatePeriodMutationFn = Apollo.MutationFunction<
    AdminUpdateScheduleTemplatePeriodMutation,
    AdminUpdateScheduleTemplatePeriodMutationVariables
>;

/**
 * __useAdminUpdateScheduleTemplatePeriodMutation__
 *
 * To run a mutation, you first call `useAdminUpdateScheduleTemplatePeriodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateScheduleTemplatePeriodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateScheduleTemplatePeriodMutation, { data, loading, error }] = useAdminUpdateScheduleTemplatePeriodMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdminUpdateScheduleTemplatePeriodMutation(
    baseOptions?: Apollo.MutationHookOptions<
        AdminUpdateScheduleTemplatePeriodMutation,
        AdminUpdateScheduleTemplatePeriodMutationVariables
    >,
) {
    return Apollo.useMutation<
        AdminUpdateScheduleTemplatePeriodMutation,
        AdminUpdateScheduleTemplatePeriodMutationVariables
    >(AdminUpdateScheduleTemplatePeriodDocument, baseOptions);
}
export type AdminUpdateScheduleTemplatePeriodMutationHookResult = ReturnType<
    typeof useAdminUpdateScheduleTemplatePeriodMutation
>;
export type AdminUpdateScheduleTemplatePeriodMutationResult = Apollo.MutationResult<AdminUpdateScheduleTemplatePeriodMutation>;
export type AdminUpdateScheduleTemplatePeriodMutationOptions = Apollo.BaseMutationOptions<
    AdminUpdateScheduleTemplatePeriodMutation,
    AdminUpdateScheduleTemplatePeriodMutationVariables
>;
export const AdminUpdateScheduleTemplateDocument = gql`
    mutation adminUpdateScheduleTemplate($data: AdminUpdateScheduleTemplateInput!) {
        adminUpdateScheduleTemplate(data: $data) {
            data {
                id
                ...adminScheduleTemplateDetail
            }
        }
    }
    ${AdminScheduleTemplateDetailFragmentDoc}
`;
export type AdminUpdateScheduleTemplateMutationFn = Apollo.MutationFunction<
    AdminUpdateScheduleTemplateMutation,
    AdminUpdateScheduleTemplateMutationVariables
>;

/**
 * __useAdminUpdateScheduleTemplateMutation__
 *
 * To run a mutation, you first call `useAdminUpdateScheduleTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateScheduleTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateScheduleTemplateMutation, { data, loading, error }] = useAdminUpdateScheduleTemplateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdminUpdateScheduleTemplateMutation(
    baseOptions?: Apollo.MutationHookOptions<
        AdminUpdateScheduleTemplateMutation,
        AdminUpdateScheduleTemplateMutationVariables
    >,
) {
    return Apollo.useMutation<
        AdminUpdateScheduleTemplateMutation,
        AdminUpdateScheduleTemplateMutationVariables
    >(AdminUpdateScheduleTemplateDocument, baseOptions);
}
export type AdminUpdateScheduleTemplateMutationHookResult = ReturnType<
    typeof useAdminUpdateScheduleTemplateMutation
>;
export type AdminUpdateScheduleTemplateMutationResult = Apollo.MutationResult<AdminUpdateScheduleTemplateMutation>;
export type AdminUpdateScheduleTemplateMutationOptions = Apollo.BaseMutationOptions<
    AdminUpdateScheduleTemplateMutation,
    AdminUpdateScheduleTemplateMutationVariables
>;
export const AdminDeleteScheduleTemplatePeriodDocument = gql`
    mutation adminDeleteScheduleTemplatePeriod($data: AdminDeleteScheduleTemplatePeriodInput!) {
        adminDeleteScheduleTemplatePeriod(data: $data) {
            data {
                id
                ...adminScheduleTemplatePeriodDetail
            }
        }
    }
    ${AdminScheduleTemplatePeriodDetailFragmentDoc}
`;
export type AdminDeleteScheduleTemplatePeriodMutationFn = Apollo.MutationFunction<
    AdminDeleteScheduleTemplatePeriodMutation,
    AdminDeleteScheduleTemplatePeriodMutationVariables
>;

/**
 * __useAdminDeleteScheduleTemplatePeriodMutation__
 *
 * To run a mutation, you first call `useAdminDeleteScheduleTemplatePeriodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminDeleteScheduleTemplatePeriodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminDeleteScheduleTemplatePeriodMutation, { data, loading, error }] = useAdminDeleteScheduleTemplatePeriodMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdminDeleteScheduleTemplatePeriodMutation(
    baseOptions?: Apollo.MutationHookOptions<
        AdminDeleteScheduleTemplatePeriodMutation,
        AdminDeleteScheduleTemplatePeriodMutationVariables
    >,
) {
    return Apollo.useMutation<
        AdminDeleteScheduleTemplatePeriodMutation,
        AdminDeleteScheduleTemplatePeriodMutationVariables
    >(AdminDeleteScheduleTemplatePeriodDocument, baseOptions);
}
export type AdminDeleteScheduleTemplatePeriodMutationHookResult = ReturnType<
    typeof useAdminDeleteScheduleTemplatePeriodMutation
>;
export type AdminDeleteScheduleTemplatePeriodMutationResult = Apollo.MutationResult<AdminDeleteScheduleTemplatePeriodMutation>;
export type AdminDeleteScheduleTemplatePeriodMutationOptions = Apollo.BaseMutationOptions<
    AdminDeleteScheduleTemplatePeriodMutation,
    AdminDeleteScheduleTemplatePeriodMutationVariables
>;
export const GetAdminScheduleTemplatesDocument = gql`
    query getAdminScheduleTemplates {
        scheduleTemplates {
            ...adminScheduleTemplate
        }
    }
    ${AdminScheduleTemplateFragmentDoc}
`;

/**
 * __useGetAdminScheduleTemplatesQuery__
 *
 * To run a query within a React component, call `useGetAdminScheduleTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminScheduleTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminScheduleTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAdminScheduleTemplatesQuery(
    baseOptions?: Apollo.QueryHookOptions<
        GetAdminScheduleTemplatesQuery,
        GetAdminScheduleTemplatesQueryVariables
    >,
) {
    return Apollo.useQuery<GetAdminScheduleTemplatesQuery, GetAdminScheduleTemplatesQueryVariables>(
        GetAdminScheduleTemplatesDocument,
        baseOptions,
    );
}
export function useGetAdminScheduleTemplatesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetAdminScheduleTemplatesQuery,
        GetAdminScheduleTemplatesQueryVariables
    >,
) {
    return Apollo.useLazyQuery<
        GetAdminScheduleTemplatesQuery,
        GetAdminScheduleTemplatesQueryVariables
    >(GetAdminScheduleTemplatesDocument, baseOptions);
}
export type GetAdminScheduleTemplatesQueryHookResult = ReturnType<
    typeof useGetAdminScheduleTemplatesQuery
>;
export type GetAdminScheduleTemplatesLazyQueryHookResult = ReturnType<
    typeof useGetAdminScheduleTemplatesLazyQuery
>;
export type GetAdminScheduleTemplatesQueryResult = Apollo.QueryResult<
    GetAdminScheduleTemplatesQuery,
    GetAdminScheduleTemplatesQueryVariables
>;
export const AdminAddStorageContainerTypeDocument = gql`
    mutation adminAddStorageContainerType($data: AdminAddStorageContainerTypeInput!) {
        adminAddStorageContainerType(data: $data) {
            data {
                description
            }
        }
    }
`;
export type AdminAddStorageContainerTypeMutationFn = Apollo.MutationFunction<
    AdminAddStorageContainerTypeMutation,
    AdminAddStorageContainerTypeMutationVariables
>;

/**
 * __useAdminAddStorageContainerTypeMutation__
 *
 * To run a mutation, you first call `useAdminAddStorageContainerTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminAddStorageContainerTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminAddStorageContainerTypeMutation, { data, loading, error }] = useAdminAddStorageContainerTypeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdminAddStorageContainerTypeMutation(
    baseOptions?: Apollo.MutationHookOptions<
        AdminAddStorageContainerTypeMutation,
        AdminAddStorageContainerTypeMutationVariables
    >,
) {
    return Apollo.useMutation<
        AdminAddStorageContainerTypeMutation,
        AdminAddStorageContainerTypeMutationVariables
    >(AdminAddStorageContainerTypeDocument, baseOptions);
}
export type AdminAddStorageContainerTypeMutationHookResult = ReturnType<
    typeof useAdminAddStorageContainerTypeMutation
>;
export type AdminAddStorageContainerTypeMutationResult = Apollo.MutationResult<AdminAddStorageContainerTypeMutation>;
export type AdminAddStorageContainerTypeMutationOptions = Apollo.BaseMutationOptions<
    AdminAddStorageContainerTypeMutation,
    AdminAddStorageContainerTypeMutationVariables
>;
export const GetAdminStorageContainerTypeByIdDocument = gql`
    query getAdminStorageContainerTypeById($containerTypeId: String!) {
        storageContainerTypeById(id: $containerTypeId) {
            ...adminStorageContainerTypeDetail
        }
    }
    ${AdminStorageContainerTypeDetailFragmentDoc}
`;

/**
 * __useGetAdminStorageContainerTypeByIdQuery__
 *
 * To run a query within a React component, call `useGetAdminStorageContainerTypeByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminStorageContainerTypeByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminStorageContainerTypeByIdQuery({
 *   variables: {
 *      containerTypeId: // value for 'containerTypeId'
 *   },
 * });
 */
export function useGetAdminStorageContainerTypeByIdQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetAdminStorageContainerTypeByIdQuery,
        GetAdminStorageContainerTypeByIdQueryVariables
    >,
) {
    return Apollo.useQuery<
        GetAdminStorageContainerTypeByIdQuery,
        GetAdminStorageContainerTypeByIdQueryVariables
    >(GetAdminStorageContainerTypeByIdDocument, baseOptions);
}
export function useGetAdminStorageContainerTypeByIdLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetAdminStorageContainerTypeByIdQuery,
        GetAdminStorageContainerTypeByIdQueryVariables
    >,
) {
    return Apollo.useLazyQuery<
        GetAdminStorageContainerTypeByIdQuery,
        GetAdminStorageContainerTypeByIdQueryVariables
    >(GetAdminStorageContainerTypeByIdDocument, baseOptions);
}
export type GetAdminStorageContainerTypeByIdQueryHookResult = ReturnType<
    typeof useGetAdminStorageContainerTypeByIdQuery
>;
export type GetAdminStorageContainerTypeByIdLazyQueryHookResult = ReturnType<
    typeof useGetAdminStorageContainerTypeByIdLazyQuery
>;
export type GetAdminStorageContainerTypeByIdQueryResult = Apollo.QueryResult<
    GetAdminStorageContainerTypeByIdQuery,
    GetAdminStorageContainerTypeByIdQueryVariables
>;
export const AdminUpdateStorageContainerTypeDocument = gql`
    mutation adminUpdateStorageContainerType($data: AdminStorageContainerTypeInput!) {
        adminUpdateStorageContainerType(data: $data) {
            data {
                ...adminStorageContainerTypeDetail
            }
        }
    }
    ${AdminStorageContainerTypeDetailFragmentDoc}
`;
export type AdminUpdateStorageContainerTypeMutationFn = Apollo.MutationFunction<
    AdminUpdateStorageContainerTypeMutation,
    AdminUpdateStorageContainerTypeMutationVariables
>;

/**
 * __useAdminUpdateStorageContainerTypeMutation__
 *
 * To run a mutation, you first call `useAdminUpdateStorageContainerTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateStorageContainerTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateStorageContainerTypeMutation, { data, loading, error }] = useAdminUpdateStorageContainerTypeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdminUpdateStorageContainerTypeMutation(
    baseOptions?: Apollo.MutationHookOptions<
        AdminUpdateStorageContainerTypeMutation,
        AdminUpdateStorageContainerTypeMutationVariables
    >,
) {
    return Apollo.useMutation<
        AdminUpdateStorageContainerTypeMutation,
        AdminUpdateStorageContainerTypeMutationVariables
    >(AdminUpdateStorageContainerTypeDocument, baseOptions);
}
export type AdminUpdateStorageContainerTypeMutationHookResult = ReturnType<
    typeof useAdminUpdateStorageContainerTypeMutation
>;
export type AdminUpdateStorageContainerTypeMutationResult = Apollo.MutationResult<AdminUpdateStorageContainerTypeMutation>;
export type AdminUpdateStorageContainerTypeMutationOptions = Apollo.BaseMutationOptions<
    AdminUpdateStorageContainerTypeMutation,
    AdminUpdateStorageContainerTypeMutationVariables
>;
export const GetAdminStorageContainerTypesDocument = gql`
    query getAdminStorageContainerTypes {
        storageContainerType {
            ...adminStorageContainerType
        }
    }
    ${AdminStorageContainerTypeFragmentDoc}
`;

/**
 * __useGetAdminStorageContainerTypesQuery__
 *
 * To run a query within a React component, call `useGetAdminStorageContainerTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminStorageContainerTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminStorageContainerTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAdminStorageContainerTypesQuery(
    baseOptions?: Apollo.QueryHookOptions<
        GetAdminStorageContainerTypesQuery,
        GetAdminStorageContainerTypesQueryVariables
    >,
) {
    return Apollo.useQuery<
        GetAdminStorageContainerTypesQuery,
        GetAdminStorageContainerTypesQueryVariables
    >(GetAdminStorageContainerTypesDocument, baseOptions);
}
export function useGetAdminStorageContainerTypesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetAdminStorageContainerTypesQuery,
        GetAdminStorageContainerTypesQueryVariables
    >,
) {
    return Apollo.useLazyQuery<
        GetAdminStorageContainerTypesQuery,
        GetAdminStorageContainerTypesQueryVariables
    >(GetAdminStorageContainerTypesDocument, baseOptions);
}
export type GetAdminStorageContainerTypesQueryHookResult = ReturnType<
    typeof useGetAdminStorageContainerTypesQuery
>;
export type GetAdminStorageContainerTypesLazyQueryHookResult = ReturnType<
    typeof useGetAdminStorageContainerTypesLazyQuery
>;
export type GetAdminStorageContainerTypesQueryResult = Apollo.QueryResult<
    GetAdminStorageContainerTypesQuery,
    GetAdminStorageContainerTypesQueryVariables
>;
export const AdminAddStorageContainerDocument = gql`
    mutation adminAddStorageContainer($data: AdminAddStorageContainerInput!) {
        adminAddStorageContainer(data: $data) {
            data {
                status
                barcode
                containerType {
                    id
                    description
                }
            }
        }
    }
`;
export type AdminAddStorageContainerMutationFn = Apollo.MutationFunction<
    AdminAddStorageContainerMutation,
    AdminAddStorageContainerMutationVariables
>;

/**
 * __useAdminAddStorageContainerMutation__
 *
 * To run a mutation, you first call `useAdminAddStorageContainerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminAddStorageContainerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminAddStorageContainerMutation, { data, loading, error }] = useAdminAddStorageContainerMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdminAddStorageContainerMutation(
    baseOptions?: Apollo.MutationHookOptions<
        AdminAddStorageContainerMutation,
        AdminAddStorageContainerMutationVariables
    >,
) {
    return Apollo.useMutation<
        AdminAddStorageContainerMutation,
        AdminAddStorageContainerMutationVariables
    >(AdminAddStorageContainerDocument, baseOptions);
}
export type AdminAddStorageContainerMutationHookResult = ReturnType<
    typeof useAdminAddStorageContainerMutation
>;
export type AdminAddStorageContainerMutationResult = Apollo.MutationResult<AdminAddStorageContainerMutation>;
export type AdminAddStorageContainerMutationOptions = Apollo.BaseMutationOptions<
    AdminAddStorageContainerMutation,
    AdminAddStorageContainerMutationVariables
>;
export const GetAdminStorageContainerByIdDocument = gql`
    query getAdminStorageContainerById($containerId: String!) {
        storageContainerById(id: $containerId) {
            ...adminStorageContainerDetail
        }
    }
    ${AdminStorageContainerDetailFragmentDoc}
`;

/**
 * __useGetAdminStorageContainerByIdQuery__
 *
 * To run a query within a React component, call `useGetAdminStorageContainerByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminStorageContainerByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminStorageContainerByIdQuery({
 *   variables: {
 *      containerId: // value for 'containerId'
 *   },
 * });
 */
export function useGetAdminStorageContainerByIdQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetAdminStorageContainerByIdQuery,
        GetAdminStorageContainerByIdQueryVariables
    >,
) {
    return Apollo.useQuery<
        GetAdminStorageContainerByIdQuery,
        GetAdminStorageContainerByIdQueryVariables
    >(GetAdminStorageContainerByIdDocument, baseOptions);
}
export function useGetAdminStorageContainerByIdLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetAdminStorageContainerByIdQuery,
        GetAdminStorageContainerByIdQueryVariables
    >,
) {
    return Apollo.useLazyQuery<
        GetAdminStorageContainerByIdQuery,
        GetAdminStorageContainerByIdQueryVariables
    >(GetAdminStorageContainerByIdDocument, baseOptions);
}
export type GetAdminStorageContainerByIdQueryHookResult = ReturnType<
    typeof useGetAdminStorageContainerByIdQuery
>;
export type GetAdminStorageContainerByIdLazyQueryHookResult = ReturnType<
    typeof useGetAdminStorageContainerByIdLazyQuery
>;
export type GetAdminStorageContainerByIdQueryResult = Apollo.QueryResult<
    GetAdminStorageContainerByIdQuery,
    GetAdminStorageContainerByIdQueryVariables
>;
export const AdminUpdateStorageContainerDocument = gql`
    mutation adminUpdateStorageContainer($data: AdminUpdateStorageContainerInput!) {
        adminUpdateStorageContainer(data: $data) {
            data {
                ...adminStorageContainerDetail
            }
        }
    }
    ${AdminStorageContainerDetailFragmentDoc}
`;
export type AdminUpdateStorageContainerMutationFn = Apollo.MutationFunction<
    AdminUpdateStorageContainerMutation,
    AdminUpdateStorageContainerMutationVariables
>;

/**
 * __useAdminUpdateStorageContainerMutation__
 *
 * To run a mutation, you first call `useAdminUpdateStorageContainerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateStorageContainerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateStorageContainerMutation, { data, loading, error }] = useAdminUpdateStorageContainerMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdminUpdateStorageContainerMutation(
    baseOptions?: Apollo.MutationHookOptions<
        AdminUpdateStorageContainerMutation,
        AdminUpdateStorageContainerMutationVariables
    >,
) {
    return Apollo.useMutation<
        AdminUpdateStorageContainerMutation,
        AdminUpdateStorageContainerMutationVariables
    >(AdminUpdateStorageContainerDocument, baseOptions);
}
export type AdminUpdateStorageContainerMutationHookResult = ReturnType<
    typeof useAdminUpdateStorageContainerMutation
>;
export type AdminUpdateStorageContainerMutationResult = Apollo.MutationResult<AdminUpdateStorageContainerMutation>;
export type AdminUpdateStorageContainerMutationOptions = Apollo.BaseMutationOptions<
    AdminUpdateStorageContainerMutation,
    AdminUpdateStorageContainerMutationVariables
>;
export const GetAdminStorageContainersDocument = gql`
    query getAdminStorageContainers($pageSize: Int!, $cursor: String!) {
        storageContainers(filter: { pageSize: $pageSize, after: $cursor }) {
            edges {
                cursor
                node {
                    id
                    ...adminStorageContainer
                }
            }
            pageInfo {
                cursor {
                    beforeCursor
                    afterCursor
                }
                hasNextPage
                hasPreviousPage
            }
        }
    }
    ${AdminStorageContainerFragmentDoc}
`;

/**
 * __useGetAdminStorageContainersQuery__
 *
 * To run a query within a React component, call `useGetAdminStorageContainersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminStorageContainersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminStorageContainersQuery({
 *   variables: {
 *      pageSize: // value for 'pageSize'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetAdminStorageContainersQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetAdminStorageContainersQuery,
        GetAdminStorageContainersQueryVariables
    >,
) {
    return Apollo.useQuery<GetAdminStorageContainersQuery, GetAdminStorageContainersQueryVariables>(
        GetAdminStorageContainersDocument,
        baseOptions,
    );
}
export function useGetAdminStorageContainersLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetAdminStorageContainersQuery,
        GetAdminStorageContainersQueryVariables
    >,
) {
    return Apollo.useLazyQuery<
        GetAdminStorageContainersQuery,
        GetAdminStorageContainersQueryVariables
    >(GetAdminStorageContainersDocument, baseOptions);
}
export type GetAdminStorageContainersQueryHookResult = ReturnType<
    typeof useGetAdminStorageContainersQuery
>;
export type GetAdminStorageContainersLazyQueryHookResult = ReturnType<
    typeof useGetAdminStorageContainersLazyQuery
>;
export type GetAdminStorageContainersQueryResult = Apollo.QueryResult<
    GetAdminStorageContainersQuery,
    GetAdminStorageContainersQueryVariables
>;
export const GetAdminStorageItemTypeCategoriesDocument = gql`
    query getAdminStorageItemTypeCategories {
        storageItemTypeCategory {
            ...adminStorageItemTypeCategory
        }
    }
    ${AdminStorageItemTypeCategoryFragmentDoc}
`;

/**
 * __useGetAdminStorageItemTypeCategoriesQuery__
 *
 * To run a query within a React component, call `useGetAdminStorageItemTypeCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminStorageItemTypeCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminStorageItemTypeCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAdminStorageItemTypeCategoriesQuery(
    baseOptions?: Apollo.QueryHookOptions<
        GetAdminStorageItemTypeCategoriesQuery,
        GetAdminStorageItemTypeCategoriesQueryVariables
    >,
) {
    return Apollo.useQuery<
        GetAdminStorageItemTypeCategoriesQuery,
        GetAdminStorageItemTypeCategoriesQueryVariables
    >(GetAdminStorageItemTypeCategoriesDocument, baseOptions);
}
export function useGetAdminStorageItemTypeCategoriesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetAdminStorageItemTypeCategoriesQuery,
        GetAdminStorageItemTypeCategoriesQueryVariables
    >,
) {
    return Apollo.useLazyQuery<
        GetAdminStorageItemTypeCategoriesQuery,
        GetAdminStorageItemTypeCategoriesQueryVariables
    >(GetAdminStorageItemTypeCategoriesDocument, baseOptions);
}
export type GetAdminStorageItemTypeCategoriesQueryHookResult = ReturnType<
    typeof useGetAdminStorageItemTypeCategoriesQuery
>;
export type GetAdminStorageItemTypeCategoriesLazyQueryHookResult = ReturnType<
    typeof useGetAdminStorageItemTypeCategoriesLazyQuery
>;
export type GetAdminStorageItemTypeCategoriesQueryResult = Apollo.QueryResult<
    GetAdminStorageItemTypeCategoriesQuery,
    GetAdminStorageItemTypeCategoriesQueryVariables
>;
export const AdminAddStorageItemTypeDocument = gql`
    mutation adminAddStorageItemType($data: AdminAddStorageItemTypeInput!) {
        adminAddStorageItemType(data: $data) {
            data {
                id
                description
                name
                image
                maximumRequestQuantity
                category {
                    id
                    name
                    parentId
                }
                multiplePeople
            }
        }
    }
`;
export type AdminAddStorageItemTypeMutationFn = Apollo.MutationFunction<
    AdminAddStorageItemTypeMutation,
    AdminAddStorageItemTypeMutationVariables
>;

/**
 * __useAdminAddStorageItemTypeMutation__
 *
 * To run a mutation, you first call `useAdminAddStorageItemTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminAddStorageItemTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminAddStorageItemTypeMutation, { data, loading, error }] = useAdminAddStorageItemTypeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdminAddStorageItemTypeMutation(
    baseOptions?: Apollo.MutationHookOptions<
        AdminAddStorageItemTypeMutation,
        AdminAddStorageItemTypeMutationVariables
    >,
) {
    return Apollo.useMutation<
        AdminAddStorageItemTypeMutation,
        AdminAddStorageItemTypeMutationVariables
    >(AdminAddStorageItemTypeDocument, baseOptions);
}
export type AdminAddStorageItemTypeMutationHookResult = ReturnType<
    typeof useAdminAddStorageItemTypeMutation
>;
export type AdminAddStorageItemTypeMutationResult = Apollo.MutationResult<AdminAddStorageItemTypeMutation>;
export type AdminAddStorageItemTypeMutationOptions = Apollo.BaseMutationOptions<
    AdminAddStorageItemTypeMutation,
    AdminAddStorageItemTypeMutationVariables
>;
export const UploadStorageItemTypeImageDocument = gql`
    mutation uploadStorageItemTypeImage($file: Upload!) {
        uploadStorageItemTypeImage(file: $file) {
            data {
                url
            }
        }
    }
`;
export type UploadStorageItemTypeImageMutationFn = Apollo.MutationFunction<
    UploadStorageItemTypeImageMutation,
    UploadStorageItemTypeImageMutationVariables
>;

/**
 * __useUploadStorageItemTypeImageMutation__
 *
 * To run a mutation, you first call `useUploadStorageItemTypeImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadStorageItemTypeImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadStorageItemTypeImageMutation, { data, loading, error }] = useUploadStorageItemTypeImageMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadStorageItemTypeImageMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UploadStorageItemTypeImageMutation,
        UploadStorageItemTypeImageMutationVariables
    >,
) {
    return Apollo.useMutation<
        UploadStorageItemTypeImageMutation,
        UploadStorageItemTypeImageMutationVariables
    >(UploadStorageItemTypeImageDocument, baseOptions);
}
export type UploadStorageItemTypeImageMutationHookResult = ReturnType<
    typeof useUploadStorageItemTypeImageMutation
>;
export type UploadStorageItemTypeImageMutationResult = Apollo.MutationResult<UploadStorageItemTypeImageMutation>;
export type UploadStorageItemTypeImageMutationOptions = Apollo.BaseMutationOptions<
    UploadStorageItemTypeImageMutation,
    UploadStorageItemTypeImageMutationVariables
>;
export const GetAdminStorageItemTypeByIdDocument = gql`
    query getAdminStorageItemTypeById($storageItemTypeId: String!) {
        storageItemTypeById(id: $storageItemTypeId) {
            ...adminStorageItemTypeDetail
        }
    }
    ${AdminStorageItemTypeDetailFragmentDoc}
`;

/**
 * __useGetAdminStorageItemTypeByIdQuery__
 *
 * To run a query within a React component, call `useGetAdminStorageItemTypeByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminStorageItemTypeByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminStorageItemTypeByIdQuery({
 *   variables: {
 *      storageItemTypeId: // value for 'storageItemTypeId'
 *   },
 * });
 */
export function useGetAdminStorageItemTypeByIdQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetAdminStorageItemTypeByIdQuery,
        GetAdminStorageItemTypeByIdQueryVariables
    >,
) {
    return Apollo.useQuery<
        GetAdminStorageItemTypeByIdQuery,
        GetAdminStorageItemTypeByIdQueryVariables
    >(GetAdminStorageItemTypeByIdDocument, baseOptions);
}
export function useGetAdminStorageItemTypeByIdLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetAdminStorageItemTypeByIdQuery,
        GetAdminStorageItemTypeByIdQueryVariables
    >,
) {
    return Apollo.useLazyQuery<
        GetAdminStorageItemTypeByIdQuery,
        GetAdminStorageItemTypeByIdQueryVariables
    >(GetAdminStorageItemTypeByIdDocument, baseOptions);
}
export type GetAdminStorageItemTypeByIdQueryHookResult = ReturnType<
    typeof useGetAdminStorageItemTypeByIdQuery
>;
export type GetAdminStorageItemTypeByIdLazyQueryHookResult = ReturnType<
    typeof useGetAdminStorageItemTypeByIdLazyQuery
>;
export type GetAdminStorageItemTypeByIdQueryResult = Apollo.QueryResult<
    GetAdminStorageItemTypeByIdQuery,
    GetAdminStorageItemTypeByIdQueryVariables
>;
export const AdminUpdateStorageItemTypeDocument = gql`
    mutation adminUpdateStorageItemType($data: AdminStorageItemTypeInput!) {
        adminUpdateStorageItemType(data: $data) {
            data {
                ...adminStorageItemTypeDetail
            }
        }
    }
    ${AdminStorageItemTypeDetailFragmentDoc}
`;
export type AdminUpdateStorageItemTypeMutationFn = Apollo.MutationFunction<
    AdminUpdateStorageItemTypeMutation,
    AdminUpdateStorageItemTypeMutationVariables
>;

/**
 * __useAdminUpdateStorageItemTypeMutation__
 *
 * To run a mutation, you first call `useAdminUpdateStorageItemTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateStorageItemTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateStorageItemTypeMutation, { data, loading, error }] = useAdminUpdateStorageItemTypeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdminUpdateStorageItemTypeMutation(
    baseOptions?: Apollo.MutationHookOptions<
        AdminUpdateStorageItemTypeMutation,
        AdminUpdateStorageItemTypeMutationVariables
    >,
) {
    return Apollo.useMutation<
        AdminUpdateStorageItemTypeMutation,
        AdminUpdateStorageItemTypeMutationVariables
    >(AdminUpdateStorageItemTypeDocument, baseOptions);
}
export type AdminUpdateStorageItemTypeMutationHookResult = ReturnType<
    typeof useAdminUpdateStorageItemTypeMutation
>;
export type AdminUpdateStorageItemTypeMutationResult = Apollo.MutationResult<AdminUpdateStorageItemTypeMutation>;
export type AdminUpdateStorageItemTypeMutationOptions = Apollo.BaseMutationOptions<
    AdminUpdateStorageItemTypeMutation,
    AdminUpdateStorageItemTypeMutationVariables
>;
export const StorageItemTypeDetailUploadImageDocument = gql`
    mutation storageItemTypeDetailUploadImage($file: Upload!) {
        uploadStorageItemTypeImage(file: $file) {
            data {
                url
            }
        }
    }
`;
export type StorageItemTypeDetailUploadImageMutationFn = Apollo.MutationFunction<
    StorageItemTypeDetailUploadImageMutation,
    StorageItemTypeDetailUploadImageMutationVariables
>;

/**
 * __useStorageItemTypeDetailUploadImageMutation__
 *
 * To run a mutation, you first call `useStorageItemTypeDetailUploadImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStorageItemTypeDetailUploadImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [storageItemTypeDetailUploadImageMutation, { data, loading, error }] = useStorageItemTypeDetailUploadImageMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useStorageItemTypeDetailUploadImageMutation(
    baseOptions?: Apollo.MutationHookOptions<
        StorageItemTypeDetailUploadImageMutation,
        StorageItemTypeDetailUploadImageMutationVariables
    >,
) {
    return Apollo.useMutation<
        StorageItemTypeDetailUploadImageMutation,
        StorageItemTypeDetailUploadImageMutationVariables
    >(StorageItemTypeDetailUploadImageDocument, baseOptions);
}
export type StorageItemTypeDetailUploadImageMutationHookResult = ReturnType<
    typeof useStorageItemTypeDetailUploadImageMutation
>;
export type StorageItemTypeDetailUploadImageMutationResult = Apollo.MutationResult<StorageItemTypeDetailUploadImageMutation>;
export type StorageItemTypeDetailUploadImageMutationOptions = Apollo.BaseMutationOptions<
    StorageItemTypeDetailUploadImageMutation,
    StorageItemTypeDetailUploadImageMutationVariables
>;
export const GetAdminStorageItemTypesDocument = gql`
    query getAdminStorageItemTypes($pageSize: Int!, $cursor: String!) {
        storageItemTypes(filter: { pageSize: $pageSize, after: $cursor }) {
            edges {
                cursor
                node {
                    id
                    ...adminStorageItemType
                }
            }
            pageInfo {
                cursor {
                    beforeCursor
                    afterCursor
                }
                hasNextPage
                hasPreviousPage
            }
        }
    }
    ${AdminStorageItemTypeFragmentDoc}
`;

/**
 * __useGetAdminStorageItemTypesQuery__
 *
 * To run a query within a React component, call `useGetAdminStorageItemTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminStorageItemTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminStorageItemTypesQuery({
 *   variables: {
 *      pageSize: // value for 'pageSize'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetAdminStorageItemTypesQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetAdminStorageItemTypesQuery,
        GetAdminStorageItemTypesQueryVariables
    >,
) {
    return Apollo.useQuery<GetAdminStorageItemTypesQuery, GetAdminStorageItemTypesQueryVariables>(
        GetAdminStorageItemTypesDocument,
        baseOptions,
    );
}
export function useGetAdminStorageItemTypesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetAdminStorageItemTypesQuery,
        GetAdminStorageItemTypesQueryVariables
    >,
) {
    return Apollo.useLazyQuery<
        GetAdminStorageItemTypesQuery,
        GetAdminStorageItemTypesQueryVariables
    >(GetAdminStorageItemTypesDocument, baseOptions);
}
export type GetAdminStorageItemTypesQueryHookResult = ReturnType<
    typeof useGetAdminStorageItemTypesQuery
>;
export type GetAdminStorageItemTypesLazyQueryHookResult = ReturnType<
    typeof useGetAdminStorageItemTypesLazyQuery
>;
export type GetAdminStorageItemTypesQueryResult = Apollo.QueryResult<
    GetAdminStorageItemTypesQuery,
    GetAdminStorageItemTypesQueryVariables
>;
export const AdminAddStorageItemTypeCategoryDocument = gql`
    mutation adminAddStorageItemTypeCategory($data: AdminAddStorageItemTypeCategoryInput!) {
        adminAddStorageItemTypeCategory(data: $data) {
            data {
                name
            }
        }
    }
`;
export type AdminAddStorageItemTypeCategoryMutationFn = Apollo.MutationFunction<
    AdminAddStorageItemTypeCategoryMutation,
    AdminAddStorageItemTypeCategoryMutationVariables
>;

/**
 * __useAdminAddStorageItemTypeCategoryMutation__
 *
 * To run a mutation, you first call `useAdminAddStorageItemTypeCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminAddStorageItemTypeCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminAddStorageItemTypeCategoryMutation, { data, loading, error }] = useAdminAddStorageItemTypeCategoryMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdminAddStorageItemTypeCategoryMutation(
    baseOptions?: Apollo.MutationHookOptions<
        AdminAddStorageItemTypeCategoryMutation,
        AdminAddStorageItemTypeCategoryMutationVariables
    >,
) {
    return Apollo.useMutation<
        AdminAddStorageItemTypeCategoryMutation,
        AdminAddStorageItemTypeCategoryMutationVariables
    >(AdminAddStorageItemTypeCategoryDocument, baseOptions);
}
export type AdminAddStorageItemTypeCategoryMutationHookResult = ReturnType<
    typeof useAdminAddStorageItemTypeCategoryMutation
>;
export type AdminAddStorageItemTypeCategoryMutationResult = Apollo.MutationResult<AdminAddStorageItemTypeCategoryMutation>;
export type AdminAddStorageItemTypeCategoryMutationOptions = Apollo.BaseMutationOptions<
    AdminAddStorageItemTypeCategoryMutation,
    AdminAddStorageItemTypeCategoryMutationVariables
>;
export const GetAdminStorageItemTypeCategoryByIdDocument = gql`
    query getAdminStorageItemTypeCategoryById($categoryId: String!) {
        storageItemTypeCategoryById(id: $categoryId) {
            ...adminStorageItemTypeCategoryDetail
        }
    }
    ${AdminStorageItemTypeCategoryDetailFragmentDoc}
`;

/**
 * __useGetAdminStorageItemTypeCategoryByIdQuery__
 *
 * To run a query within a React component, call `useGetAdminStorageItemTypeCategoryByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminStorageItemTypeCategoryByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminStorageItemTypeCategoryByIdQuery({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useGetAdminStorageItemTypeCategoryByIdQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetAdminStorageItemTypeCategoryByIdQuery,
        GetAdminStorageItemTypeCategoryByIdQueryVariables
    >,
) {
    return Apollo.useQuery<
        GetAdminStorageItemTypeCategoryByIdQuery,
        GetAdminStorageItemTypeCategoryByIdQueryVariables
    >(GetAdminStorageItemTypeCategoryByIdDocument, baseOptions);
}
export function useGetAdminStorageItemTypeCategoryByIdLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetAdminStorageItemTypeCategoryByIdQuery,
        GetAdminStorageItemTypeCategoryByIdQueryVariables
    >,
) {
    return Apollo.useLazyQuery<
        GetAdminStorageItemTypeCategoryByIdQuery,
        GetAdminStorageItemTypeCategoryByIdQueryVariables
    >(GetAdminStorageItemTypeCategoryByIdDocument, baseOptions);
}
export type GetAdminStorageItemTypeCategoryByIdQueryHookResult = ReturnType<
    typeof useGetAdminStorageItemTypeCategoryByIdQuery
>;
export type GetAdminStorageItemTypeCategoryByIdLazyQueryHookResult = ReturnType<
    typeof useGetAdminStorageItemTypeCategoryByIdLazyQuery
>;
export type GetAdminStorageItemTypeCategoryByIdQueryResult = Apollo.QueryResult<
    GetAdminStorageItemTypeCategoryByIdQuery,
    GetAdminStorageItemTypeCategoryByIdQueryVariables
>;
export const AdminUpdateStorageItemTypeCategoryDocument = gql`
    mutation adminUpdateStorageItemTypeCategory($data: AdminStorageItemTypeCategoryInput!) {
        adminUpdateStorageItemTypeCategory(data: $data) {
            data {
                ...adminStorageItemTypeCategoryDetail
            }
        }
    }
    ${AdminStorageItemTypeCategoryDetailFragmentDoc}
`;
export type AdminUpdateStorageItemTypeCategoryMutationFn = Apollo.MutationFunction<
    AdminUpdateStorageItemTypeCategoryMutation,
    AdminUpdateStorageItemTypeCategoryMutationVariables
>;

/**
 * __useAdminUpdateStorageItemTypeCategoryMutation__
 *
 * To run a mutation, you first call `useAdminUpdateStorageItemTypeCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateStorageItemTypeCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateStorageItemTypeCategoryMutation, { data, loading, error }] = useAdminUpdateStorageItemTypeCategoryMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdminUpdateStorageItemTypeCategoryMutation(
    baseOptions?: Apollo.MutationHookOptions<
        AdminUpdateStorageItemTypeCategoryMutation,
        AdminUpdateStorageItemTypeCategoryMutationVariables
    >,
) {
    return Apollo.useMutation<
        AdminUpdateStorageItemTypeCategoryMutation,
        AdminUpdateStorageItemTypeCategoryMutationVariables
    >(AdminUpdateStorageItemTypeCategoryDocument, baseOptions);
}
export type AdminUpdateStorageItemTypeCategoryMutationHookResult = ReturnType<
    typeof useAdminUpdateStorageItemTypeCategoryMutation
>;
export type AdminUpdateStorageItemTypeCategoryMutationResult = Apollo.MutationResult<AdminUpdateStorageItemTypeCategoryMutation>;
export type AdminUpdateStorageItemTypeCategoryMutationOptions = Apollo.BaseMutationOptions<
    AdminUpdateStorageItemTypeCategoryMutation,
    AdminUpdateStorageItemTypeCategoryMutationVariables
>;
export const GetAdminStorageItemTypeCategoryDocument = gql`
    query getAdminStorageItemTypeCategory {
        storageItemTypeCategory {
            ...adminStorageItemTypeCategory
        }
    }
    ${AdminStorageItemTypeCategoryFragmentDoc}
`;

/**
 * __useGetAdminStorageItemTypeCategoryQuery__
 *
 * To run a query within a React component, call `useGetAdminStorageItemTypeCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminStorageItemTypeCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminStorageItemTypeCategoryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAdminStorageItemTypeCategoryQuery(
    baseOptions?: Apollo.QueryHookOptions<
        GetAdminStorageItemTypeCategoryQuery,
        GetAdminStorageItemTypeCategoryQueryVariables
    >,
) {
    return Apollo.useQuery<
        GetAdminStorageItemTypeCategoryQuery,
        GetAdminStorageItemTypeCategoryQueryVariables
    >(GetAdminStorageItemTypeCategoryDocument, baseOptions);
}
export function useGetAdminStorageItemTypeCategoryLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetAdminStorageItemTypeCategoryQuery,
        GetAdminStorageItemTypeCategoryQueryVariables
    >,
) {
    return Apollo.useLazyQuery<
        GetAdminStorageItemTypeCategoryQuery,
        GetAdminStorageItemTypeCategoryQueryVariables
    >(GetAdminStorageItemTypeCategoryDocument, baseOptions);
}
export type GetAdminStorageItemTypeCategoryQueryHookResult = ReturnType<
    typeof useGetAdminStorageItemTypeCategoryQuery
>;
export type GetAdminStorageItemTypeCategoryLazyQueryHookResult = ReturnType<
    typeof useGetAdminStorageItemTypeCategoryLazyQuery
>;
export type GetAdminStorageItemTypeCategoryQueryResult = Apollo.QueryResult<
    GetAdminStorageItemTypeCategoryQuery,
    GetAdminStorageItemTypeCategoryQueryVariables
>;
export const GetAdminAddTerritoryAreasDocument = gql`
    query getAdminAddTerritoryAreas {
        areas {
            ...adminTerritoryArea
        }
    }
    ${AdminTerritoryAreaFragmentDoc}
`;

/**
 * __useGetAdminAddTerritoryAreasQuery__
 *
 * To run a query within a React component, call `useGetAdminAddTerritoryAreasQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminAddTerritoryAreasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminAddTerritoryAreasQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAdminAddTerritoryAreasQuery(
    baseOptions?: Apollo.QueryHookOptions<
        GetAdminAddTerritoryAreasQuery,
        GetAdminAddTerritoryAreasQueryVariables
    >,
) {
    return Apollo.useQuery<GetAdminAddTerritoryAreasQuery, GetAdminAddTerritoryAreasQueryVariables>(
        GetAdminAddTerritoryAreasDocument,
        baseOptions,
    );
}
export function useGetAdminAddTerritoryAreasLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetAdminAddTerritoryAreasQuery,
        GetAdminAddTerritoryAreasQueryVariables
    >,
) {
    return Apollo.useLazyQuery<
        GetAdminAddTerritoryAreasQuery,
        GetAdminAddTerritoryAreasQueryVariables
    >(GetAdminAddTerritoryAreasDocument, baseOptions);
}
export type GetAdminAddTerritoryAreasQueryHookResult = ReturnType<
    typeof useGetAdminAddTerritoryAreasQuery
>;
export type GetAdminAddTerritoryAreasLazyQueryHookResult = ReturnType<
    typeof useGetAdminAddTerritoryAreasLazyQuery
>;
export type GetAdminAddTerritoryAreasQueryResult = Apollo.QueryResult<
    GetAdminAddTerritoryAreasQuery,
    GetAdminAddTerritoryAreasQueryVariables
>;
export const AdminAddTerritoryDocument = gql`
    mutation adminAddTerritory($data: AdminAddTerritoryInput!) {
        adminAddTerritory(data: $data) {
            data {
                ...adminAddTerritory
            }
        }
    }
    ${AdminAddTerritoryFragmentDoc}
`;
export type AdminAddTerritoryMutationFn = Apollo.MutationFunction<
    AdminAddTerritoryMutation,
    AdminAddTerritoryMutationVariables
>;

/**
 * __useAdminAddTerritoryMutation__
 *
 * To run a mutation, you first call `useAdminAddTerritoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminAddTerritoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminAddTerritoryMutation, { data, loading, error }] = useAdminAddTerritoryMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdminAddTerritoryMutation(
    baseOptions?: Apollo.MutationHookOptions<
        AdminAddTerritoryMutation,
        AdminAddTerritoryMutationVariables
    >,
) {
    return Apollo.useMutation<AdminAddTerritoryMutation, AdminAddTerritoryMutationVariables>(
        AdminAddTerritoryDocument,
        baseOptions,
    );
}
export type AdminAddTerritoryMutationHookResult = ReturnType<typeof useAdminAddTerritoryMutation>;
export type AdminAddTerritoryMutationResult = Apollo.MutationResult<AdminAddTerritoryMutation>;
export type AdminAddTerritoryMutationOptions = Apollo.BaseMutationOptions<
    AdminAddTerritoryMutation,
    AdminAddTerritoryMutationVariables
>;
export const GetAdminTerritoryAreasDocument = gql`
    query getAdminTerritoryAreas {
        areas {
            ...adminTerritoryArea
        }
    }
    ${AdminTerritoryAreaFragmentDoc}
`;

/**
 * __useGetAdminTerritoryAreasQuery__
 *
 * To run a query within a React component, call `useGetAdminTerritoryAreasQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminTerritoryAreasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminTerritoryAreasQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAdminTerritoryAreasQuery(
    baseOptions?: Apollo.QueryHookOptions<
        GetAdminTerritoryAreasQuery,
        GetAdminTerritoryAreasQueryVariables
    >,
) {
    return Apollo.useQuery<GetAdminTerritoryAreasQuery, GetAdminTerritoryAreasQueryVariables>(
        GetAdminTerritoryAreasDocument,
        baseOptions,
    );
}
export function useGetAdminTerritoryAreasLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetAdminTerritoryAreasQuery,
        GetAdminTerritoryAreasQueryVariables
    >,
) {
    return Apollo.useLazyQuery<GetAdminTerritoryAreasQuery, GetAdminTerritoryAreasQueryVariables>(
        GetAdminTerritoryAreasDocument,
        baseOptions,
    );
}
export type GetAdminTerritoryAreasQueryHookResult = ReturnType<
    typeof useGetAdminTerritoryAreasQuery
>;
export type GetAdminTerritoryAreasLazyQueryHookResult = ReturnType<
    typeof useGetAdminTerritoryAreasLazyQuery
>;
export type GetAdminTerritoryAreasQueryResult = Apollo.QueryResult<
    GetAdminTerritoryAreasQuery,
    GetAdminTerritoryAreasQueryVariables
>;
export const GetAdminTerritoryByIdDocument = gql`
    query getAdminTerritoryById($territoryId: String!) {
        territoryById(id: $territoryId) {
            ...adminTerritoryDetail
        }
    }
    ${AdminTerritoryDetailFragmentDoc}
`;

/**
 * __useGetAdminTerritoryByIdQuery__
 *
 * To run a query within a React component, call `useGetAdminTerritoryByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminTerritoryByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminTerritoryByIdQuery({
 *   variables: {
 *      territoryId: // value for 'territoryId'
 *   },
 * });
 */
export function useGetAdminTerritoryByIdQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetAdminTerritoryByIdQuery,
        GetAdminTerritoryByIdQueryVariables
    >,
) {
    return Apollo.useQuery<GetAdminTerritoryByIdQuery, GetAdminTerritoryByIdQueryVariables>(
        GetAdminTerritoryByIdDocument,
        baseOptions,
    );
}
export function useGetAdminTerritoryByIdLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetAdminTerritoryByIdQuery,
        GetAdminTerritoryByIdQueryVariables
    >,
) {
    return Apollo.useLazyQuery<GetAdminTerritoryByIdQuery, GetAdminTerritoryByIdQueryVariables>(
        GetAdminTerritoryByIdDocument,
        baseOptions,
    );
}
export type GetAdminTerritoryByIdQueryHookResult = ReturnType<typeof useGetAdminTerritoryByIdQuery>;
export type GetAdminTerritoryByIdLazyQueryHookResult = ReturnType<
    typeof useGetAdminTerritoryByIdLazyQuery
>;
export type GetAdminTerritoryByIdQueryResult = Apollo.QueryResult<
    GetAdminTerritoryByIdQuery,
    GetAdminTerritoryByIdQueryVariables
>;
export const AdminUpdateTerritoryDocument = gql`
    mutation adminUpdateTerritory($data: AdminUpdateTerritoryInput!) {
        adminUpdateTerritory(data: $data) {
            data {
                id
                ...adminTerritoryDetail
            }
        }
    }
    ${AdminTerritoryDetailFragmentDoc}
`;
export type AdminUpdateTerritoryMutationFn = Apollo.MutationFunction<
    AdminUpdateTerritoryMutation,
    AdminUpdateTerritoryMutationVariables
>;

/**
 * __useAdminUpdateTerritoryMutation__
 *
 * To run a mutation, you first call `useAdminUpdateTerritoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateTerritoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateTerritoryMutation, { data, loading, error }] = useAdminUpdateTerritoryMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdminUpdateTerritoryMutation(
    baseOptions?: Apollo.MutationHookOptions<
        AdminUpdateTerritoryMutation,
        AdminUpdateTerritoryMutationVariables
    >,
) {
    return Apollo.useMutation<AdminUpdateTerritoryMutation, AdminUpdateTerritoryMutationVariables>(
        AdminUpdateTerritoryDocument,
        baseOptions,
    );
}
export type AdminUpdateTerritoryMutationHookResult = ReturnType<
    typeof useAdminUpdateTerritoryMutation
>;
export type AdminUpdateTerritoryMutationResult = Apollo.MutationResult<AdminUpdateTerritoryMutation>;
export type AdminUpdateTerritoryMutationOptions = Apollo.BaseMutationOptions<
    AdminUpdateTerritoryMutation,
    AdminUpdateTerritoryMutationVariables
>;
export const AdminAddPeriodToTerritoryDocument = gql`
    mutation adminAddPeriodToTerritory($data: AdminAddPeriodToTerritoryInput!) {
        adminAddPeriodToTerritory(data: $data) {
            data {
                id
            }
        }
    }
`;
export type AdminAddPeriodToTerritoryMutationFn = Apollo.MutationFunction<
    AdminAddPeriodToTerritoryMutation,
    AdminAddPeriodToTerritoryMutationVariables
>;

/**
 * __useAdminAddPeriodToTerritoryMutation__
 *
 * To run a mutation, you first call `useAdminAddPeriodToTerritoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminAddPeriodToTerritoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminAddPeriodToTerritoryMutation, { data, loading, error }] = useAdminAddPeriodToTerritoryMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdminAddPeriodToTerritoryMutation(
    baseOptions?: Apollo.MutationHookOptions<
        AdminAddPeriodToTerritoryMutation,
        AdminAddPeriodToTerritoryMutationVariables
    >,
) {
    return Apollo.useMutation<
        AdminAddPeriodToTerritoryMutation,
        AdminAddPeriodToTerritoryMutationVariables
    >(AdminAddPeriodToTerritoryDocument, baseOptions);
}
export type AdminAddPeriodToTerritoryMutationHookResult = ReturnType<
    typeof useAdminAddPeriodToTerritoryMutation
>;
export type AdminAddPeriodToTerritoryMutationResult = Apollo.MutationResult<AdminAddPeriodToTerritoryMutation>;
export type AdminAddPeriodToTerritoryMutationOptions = Apollo.BaseMutationOptions<
    AdminAddPeriodToTerritoryMutation,
    AdminAddPeriodToTerritoryMutationVariables
>;
export const AdminExcludePeriodFromTerritoryDocument = gql`
    mutation adminExcludePeriodFromTerritory($data: AdminExcludePeriodFromTerritoryInput!) {
        adminExcludePeriodFromTerritory(data: $data) {
            data {
                id
            }
        }
    }
`;
export type AdminExcludePeriodFromTerritoryMutationFn = Apollo.MutationFunction<
    AdminExcludePeriodFromTerritoryMutation,
    AdminExcludePeriodFromTerritoryMutationVariables
>;

/**
 * __useAdminExcludePeriodFromTerritoryMutation__
 *
 * To run a mutation, you first call `useAdminExcludePeriodFromTerritoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminExcludePeriodFromTerritoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminExcludePeriodFromTerritoryMutation, { data, loading, error }] = useAdminExcludePeriodFromTerritoryMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdminExcludePeriodFromTerritoryMutation(
    baseOptions?: Apollo.MutationHookOptions<
        AdminExcludePeriodFromTerritoryMutation,
        AdminExcludePeriodFromTerritoryMutationVariables
    >,
) {
    return Apollo.useMutation<
        AdminExcludePeriodFromTerritoryMutation,
        AdminExcludePeriodFromTerritoryMutationVariables
    >(AdminExcludePeriodFromTerritoryDocument, baseOptions);
}
export type AdminExcludePeriodFromTerritoryMutationHookResult = ReturnType<
    typeof useAdminExcludePeriodFromTerritoryMutation
>;
export type AdminExcludePeriodFromTerritoryMutationResult = Apollo.MutationResult<AdminExcludePeriodFromTerritoryMutation>;
export type AdminExcludePeriodFromTerritoryMutationOptions = Apollo.BaseMutationOptions<
    AdminExcludePeriodFromTerritoryMutation,
    AdminExcludePeriodFromTerritoryMutationVariables
>;
export const GetAdminTerritorysDocument = gql`
    query getAdminTerritorys($pageSize: Int!, $cursor: String!) {
        territories(filter: { pageSize: $pageSize, after: $cursor }) {
            edges {
                cursor
                node {
                    id
                    ...adminTerritory
                }
            }
            pageInfo {
                cursor {
                    beforeCursor
                    afterCursor
                }
                hasNextPage
                hasPreviousPage
            }
        }
    }
    ${AdminTerritoryFragmentDoc}
`;

/**
 * __useGetAdminTerritorysQuery__
 *
 * To run a query within a React component, call `useGetAdminTerritorysQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminTerritorysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminTerritorysQuery({
 *   variables: {
 *      pageSize: // value for 'pageSize'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetAdminTerritorysQuery(
    baseOptions: Apollo.QueryHookOptions<GetAdminTerritorysQuery, GetAdminTerritorysQueryVariables>,
) {
    return Apollo.useQuery<GetAdminTerritorysQuery, GetAdminTerritorysQueryVariables>(
        GetAdminTerritorysDocument,
        baseOptions,
    );
}
export function useGetAdminTerritorysLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetAdminTerritorysQuery,
        GetAdminTerritorysQueryVariables
    >,
) {
    return Apollo.useLazyQuery<GetAdminTerritorysQuery, GetAdminTerritorysQueryVariables>(
        GetAdminTerritorysDocument,
        baseOptions,
    );
}
export type GetAdminTerritorysQueryHookResult = ReturnType<typeof useGetAdminTerritorysQuery>;
export type GetAdminTerritorysLazyQueryHookResult = ReturnType<
    typeof useGetAdminTerritorysLazyQuery
>;
export type GetAdminTerritorysQueryResult = Apollo.QueryResult<
    GetAdminTerritorysQuery,
    GetAdminTerritorysQueryVariables
>;
export const AdminAddVehicleDocument = gql`
    mutation adminAddVehicle($data: AdminAddVehicleInput!) {
        adminAddVehicle(data: $data) {
            data {
                id
                created_at
                description
                model
                manufacture_year
                vin
                image
                isActive
            }
        }
    }
`;
export type AdminAddVehicleMutationFn = Apollo.MutationFunction<
    AdminAddVehicleMutation,
    AdminAddVehicleMutationVariables
>;

/**
 * __useAdminAddVehicleMutation__
 *
 * To run a mutation, you first call `useAdminAddVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminAddVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminAddVehicleMutation, { data, loading, error }] = useAdminAddVehicleMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdminAddVehicleMutation(
    baseOptions?: Apollo.MutationHookOptions<
        AdminAddVehicleMutation,
        AdminAddVehicleMutationVariables
    >,
) {
    return Apollo.useMutation<AdminAddVehicleMutation, AdminAddVehicleMutationVariables>(
        AdminAddVehicleDocument,
        baseOptions,
    );
}
export type AdminAddVehicleMutationHookResult = ReturnType<typeof useAdminAddVehicleMutation>;
export type AdminAddVehicleMutationResult = Apollo.MutationResult<AdminAddVehicleMutation>;
export type AdminAddVehicleMutationOptions = Apollo.BaseMutationOptions<
    AdminAddVehicleMutation,
    AdminAddVehicleMutationVariables
>;
export const GetAdminVehicleByIdDocument = gql`
    query getAdminVehicleById($vehicleId: String!) {
        vehicleById(id: $vehicleId) {
            ...adminVehicleDetail
        }
    }
    ${AdminVehicleDetailFragmentDoc}
`;

/**
 * __useGetAdminVehicleByIdQuery__
 *
 * To run a query within a React component, call `useGetAdminVehicleByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminVehicleByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminVehicleByIdQuery({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *   },
 * });
 */
export function useGetAdminVehicleByIdQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetAdminVehicleByIdQuery,
        GetAdminVehicleByIdQueryVariables
    >,
) {
    return Apollo.useQuery<GetAdminVehicleByIdQuery, GetAdminVehicleByIdQueryVariables>(
        GetAdminVehicleByIdDocument,
        baseOptions,
    );
}
export function useGetAdminVehicleByIdLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetAdminVehicleByIdQuery,
        GetAdminVehicleByIdQueryVariables
    >,
) {
    return Apollo.useLazyQuery<GetAdminVehicleByIdQuery, GetAdminVehicleByIdQueryVariables>(
        GetAdminVehicleByIdDocument,
        baseOptions,
    );
}
export type GetAdminVehicleByIdQueryHookResult = ReturnType<typeof useGetAdminVehicleByIdQuery>;
export type GetAdminVehicleByIdLazyQueryHookResult = ReturnType<
    typeof useGetAdminVehicleByIdLazyQuery
>;
export type GetAdminVehicleByIdQueryResult = Apollo.QueryResult<
    GetAdminVehicleByIdQuery,
    GetAdminVehicleByIdQueryVariables
>;
export const AdminUpdateVehicleDocument = gql`
    mutation adminUpdateVehicle($data: AdminUpdateVehicleInput!) {
        adminUpdateVehicle(data: $data) {
            data {
                ...adminVehicleDetail
            }
        }
    }
    ${AdminVehicleDetailFragmentDoc}
`;
export type AdminUpdateVehicleMutationFn = Apollo.MutationFunction<
    AdminUpdateVehicleMutation,
    AdminUpdateVehicleMutationVariables
>;

/**
 * __useAdminUpdateVehicleMutation__
 *
 * To run a mutation, you first call `useAdminUpdateVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateVehicleMutation, { data, loading, error }] = useAdminUpdateVehicleMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdminUpdateVehicleMutation(
    baseOptions?: Apollo.MutationHookOptions<
        AdminUpdateVehicleMutation,
        AdminUpdateVehicleMutationVariables
    >,
) {
    return Apollo.useMutation<AdminUpdateVehicleMutation, AdminUpdateVehicleMutationVariables>(
        AdminUpdateVehicleDocument,
        baseOptions,
    );
}
export type AdminUpdateVehicleMutationHookResult = ReturnType<typeof useAdminUpdateVehicleMutation>;
export type AdminUpdateVehicleMutationResult = Apollo.MutationResult<AdminUpdateVehicleMutation>;
export type AdminUpdateVehicleMutationOptions = Apollo.BaseMutationOptions<
    AdminUpdateVehicleMutation,
    AdminUpdateVehicleMutationVariables
>;
export const GetAdminVehiclesDocument = gql`
    query getAdminVehicles($pageSize: Int!, $cursor: String!) {
        vehicles(filter: { pageSize: $pageSize, after: $cursor }) {
            edges {
                cursor
                node {
                    id
                    ...adminVehicle
                }
            }
            pageInfo {
                cursor {
                    beforeCursor
                    afterCursor
                }
                hasNextPage
                hasPreviousPage
            }
        }
    }
    ${AdminVehicleFragmentDoc}
`;

/**
 * __useGetAdminVehiclesQuery__
 *
 * To run a query within a React component, call `useGetAdminVehiclesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminVehiclesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminVehiclesQuery({
 *   variables: {
 *      pageSize: // value for 'pageSize'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetAdminVehiclesQuery(
    baseOptions: Apollo.QueryHookOptions<GetAdminVehiclesQuery, GetAdminVehiclesQueryVariables>,
) {
    return Apollo.useQuery<GetAdminVehiclesQuery, GetAdminVehiclesQueryVariables>(
        GetAdminVehiclesDocument,
        baseOptions,
    );
}
export function useGetAdminVehiclesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetAdminVehiclesQuery,
        GetAdminVehiclesQueryVariables
    >,
) {
    return Apollo.useLazyQuery<GetAdminVehiclesQuery, GetAdminVehiclesQueryVariables>(
        GetAdminVehiclesDocument,
        baseOptions,
    );
}
export type GetAdminVehiclesQueryHookResult = ReturnType<typeof useGetAdminVehiclesQuery>;
export type GetAdminVehiclesLazyQueryHookResult = ReturnType<typeof useGetAdminVehiclesLazyQuery>;
export type GetAdminVehiclesQueryResult = Apollo.QueryResult<
    GetAdminVehiclesQuery,
    GetAdminVehiclesQueryVariables
>;
export const RetrieveEmployeeScheduleWindowsDocument = gql`
    query RetrieveEmployeeScheduleWindows(
        $zipCode: String!
        $startDate: DateTime!
        $endDate: DateTime!
    ) {
        territoryByZipCode(zipCode: $zipCode) {
            schedule(startDate: $startDate, endDate: $endDate, requestType: PICKUP_STORAGE_ITEM) {
                startTime
                endTime
                isLocked
            }
        }
    }
`;

/**
 * __useRetrieveEmployeeScheduleWindowsQuery__
 *
 * To run a query within a React component, call `useRetrieveEmployeeScheduleWindowsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetrieveEmployeeScheduleWindowsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetrieveEmployeeScheduleWindowsQuery({
 *   variables: {
 *      zipCode: // value for 'zipCode'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useRetrieveEmployeeScheduleWindowsQuery(
    baseOptions: Apollo.QueryHookOptions<
        RetrieveEmployeeScheduleWindowsQuery,
        RetrieveEmployeeScheduleWindowsQueryVariables
    >,
) {
    return Apollo.useQuery<
        RetrieveEmployeeScheduleWindowsQuery,
        RetrieveEmployeeScheduleWindowsQueryVariables
    >(RetrieveEmployeeScheduleWindowsDocument, baseOptions);
}
export function useRetrieveEmployeeScheduleWindowsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        RetrieveEmployeeScheduleWindowsQuery,
        RetrieveEmployeeScheduleWindowsQueryVariables
    >,
) {
    return Apollo.useLazyQuery<
        RetrieveEmployeeScheduleWindowsQuery,
        RetrieveEmployeeScheduleWindowsQueryVariables
    >(RetrieveEmployeeScheduleWindowsDocument, baseOptions);
}
export type RetrieveEmployeeScheduleWindowsQueryHookResult = ReturnType<
    typeof useRetrieveEmployeeScheduleWindowsQuery
>;
export type RetrieveEmployeeScheduleWindowsLazyQueryHookResult = ReturnType<
    typeof useRetrieveEmployeeScheduleWindowsLazyQuery
>;
export type RetrieveEmployeeScheduleWindowsQueryResult = Apollo.QueryResult<
    RetrieveEmployeeScheduleWindowsQuery,
    RetrieveEmployeeScheduleWindowsQueryVariables
>;
export const RetrieveAllVisitsDocument = gql`
    query RetrieveAllVisits(
        $pageSize: Int
        $visitStatus: String!
        $afterCursor: String
        $beforeCursor: String
    ) {
        visits(
            filter: {
                pageSize: $pageSize
                after: $afterCursor
                before: $beforeCursor
                conditions: [{ field: "status", value: $visitStatus, operator: Equal }]
            }
        ) {
            edges {
                cursor
                node {
                    id
                    ...EmployeeVisitManagement
                }
            }
            pageInfo {
                cursor {
                    beforeCursor
                    afterCursor
                }
                hasNextPage
                hasPreviousPage
            }
        }
    }
    ${EmployeeVisitManagementFragmentDoc}
`;

/**
 * __useRetrieveAllVisitsQuery__
 *
 * To run a query within a React component, call `useRetrieveAllVisitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetrieveAllVisitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetrieveAllVisitsQuery({
 *   variables: {
 *      pageSize: // value for 'pageSize'
 *      visitStatus: // value for 'visitStatus'
 *      afterCursor: // value for 'afterCursor'
 *      beforeCursor: // value for 'beforeCursor'
 *   },
 * });
 */
export function useRetrieveAllVisitsQuery(
    baseOptions: Apollo.QueryHookOptions<RetrieveAllVisitsQuery, RetrieveAllVisitsQueryVariables>,
) {
    return Apollo.useQuery<RetrieveAllVisitsQuery, RetrieveAllVisitsQueryVariables>(
        RetrieveAllVisitsDocument,
        baseOptions,
    );
}
export function useRetrieveAllVisitsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        RetrieveAllVisitsQuery,
        RetrieveAllVisitsQueryVariables
    >,
) {
    return Apollo.useLazyQuery<RetrieveAllVisitsQuery, RetrieveAllVisitsQueryVariables>(
        RetrieveAllVisitsDocument,
        baseOptions,
    );
}
export type RetrieveAllVisitsQueryHookResult = ReturnType<typeof useRetrieveAllVisitsQuery>;
export type RetrieveAllVisitsLazyQueryHookResult = ReturnType<typeof useRetrieveAllVisitsLazyQuery>;
export type RetrieveAllVisitsQueryResult = Apollo.QueryResult<
    RetrieveAllVisitsQuery,
    RetrieveAllVisitsQueryVariables
>;
export const GetVisitManagementByIdDocument = gql`
    query getVisitManagementById($visitId: String!) {
        visitById(visitId: $visitId) {
            ...VisitManagementDetailEmployee
        }
    }
    ${VisitManagementDetailEmployeeFragmentDoc}
`;

/**
 * __useGetVisitManagementByIdQuery__
 *
 * To run a query within a React component, call `useGetVisitManagementByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVisitManagementByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVisitManagementByIdQuery({
 *   variables: {
 *      visitId: // value for 'visitId'
 *   },
 * });
 */
export function useGetVisitManagementByIdQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetVisitManagementByIdQuery,
        GetVisitManagementByIdQueryVariables
    >,
) {
    return Apollo.useQuery<GetVisitManagementByIdQuery, GetVisitManagementByIdQueryVariables>(
        GetVisitManagementByIdDocument,
        baseOptions,
    );
}
export function useGetVisitManagementByIdLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetVisitManagementByIdQuery,
        GetVisitManagementByIdQueryVariables
    >,
) {
    return Apollo.useLazyQuery<GetVisitManagementByIdQuery, GetVisitManagementByIdQueryVariables>(
        GetVisitManagementByIdDocument,
        baseOptions,
    );
}
export type GetVisitManagementByIdQueryHookResult = ReturnType<
    typeof useGetVisitManagementByIdQuery
>;
export type GetVisitManagementByIdLazyQueryHookResult = ReturnType<
    typeof useGetVisitManagementByIdLazyQuery
>;
export type GetVisitManagementByIdQueryResult = Apollo.QueryResult<
    GetVisitManagementByIdQuery,
    GetVisitManagementByIdQueryVariables
>;
export const UpdateVisitManagementDocument = gql`
    mutation updateVisitManagement($data: VisitUpdateWindowInput!) {
        visitUpdateWindow(data: $data) {
            data {
                ...VisitManagementDetailEmployee
            }
        }
    }
    ${VisitManagementDetailEmployeeFragmentDoc}
`;
export type UpdateVisitManagementMutationFn = Apollo.MutationFunction<
    UpdateVisitManagementMutation,
    UpdateVisitManagementMutationVariables
>;

/**
 * __useUpdateVisitManagementMutation__
 *
 * To run a mutation, you first call `useUpdateVisitManagementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVisitManagementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVisitManagementMutation, { data, loading, error }] = useUpdateVisitManagementMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateVisitManagementMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateVisitManagementMutation,
        UpdateVisitManagementMutationVariables
    >,
) {
    return Apollo.useMutation<
        UpdateVisitManagementMutation,
        UpdateVisitManagementMutationVariables
    >(UpdateVisitManagementDocument, baseOptions);
}
export type UpdateVisitManagementMutationHookResult = ReturnType<
    typeof useUpdateVisitManagementMutation
>;
export type UpdateVisitManagementMutationResult = Apollo.MutationResult<UpdateVisitManagementMutation>;
export type UpdateVisitManagementMutationOptions = Apollo.BaseMutationOptions<
    UpdateVisitManagementMutation,
    UpdateVisitManagementMutationVariables
>;
export const VisitCancelVisitDocument = gql`
    mutation visitCancelVisit($data: VisitCancelVisitInput!) {
        visitCancelVisit(data: $data) {
            data {
                ...VisitManagementDetailEmployee
            }
        }
    }
    ${VisitManagementDetailEmployeeFragmentDoc}
`;
export type VisitCancelVisitMutationFn = Apollo.MutationFunction<
    VisitCancelVisitMutation,
    VisitCancelVisitMutationVariables
>;

/**
 * __useVisitCancelVisitMutation__
 *
 * To run a mutation, you first call `useVisitCancelVisitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVisitCancelVisitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [visitCancelVisitMutation, { data, loading, error }] = useVisitCancelVisitMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useVisitCancelVisitMutation(
    baseOptions?: Apollo.MutationHookOptions<
        VisitCancelVisitMutation,
        VisitCancelVisitMutationVariables
    >,
) {
    return Apollo.useMutation<VisitCancelVisitMutation, VisitCancelVisitMutationVariables>(
        VisitCancelVisitDocument,
        baseOptions,
    );
}
export type VisitCancelVisitMutationHookResult = ReturnType<typeof useVisitCancelVisitMutation>;
export type VisitCancelVisitMutationResult = Apollo.MutationResult<VisitCancelVisitMutation>;
export type VisitCancelVisitMutationOptions = Apollo.BaseMutationOptions<
    VisitCancelVisitMutation,
    VisitCancelVisitMutationVariables
>;
export const GetEmployeeDocument = gql`
    query getEmployee {
        me {
            ... on Employee {
                id
                firstName
                lastName
            }
        }
    }
`;

/**
 * __useGetEmployeeQuery__
 *
 * To run a query within a React component, call `useGetEmployeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEmployeeQuery(
    baseOptions?: Apollo.QueryHookOptions<GetEmployeeQuery, GetEmployeeQueryVariables>,
) {
    return Apollo.useQuery<GetEmployeeQuery, GetEmployeeQueryVariables>(
        GetEmployeeDocument,
        baseOptions,
    );
}
export function useGetEmployeeLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetEmployeeQuery, GetEmployeeQueryVariables>,
) {
    return Apollo.useLazyQuery<GetEmployeeQuery, GetEmployeeQueryVariables>(
        GetEmployeeDocument,
        baseOptions,
    );
}
export type GetEmployeeQueryHookResult = ReturnType<typeof useGetEmployeeQuery>;
export type GetEmployeeLazyQueryHookResult = ReturnType<typeof useGetEmployeeLazyQuery>;
export type GetEmployeeQueryResult = Apollo.QueryResult<
    GetEmployeeQuery,
    GetEmployeeQueryVariables
>;
export const RequestsByVisitIdDeliveryCompleteDocument = gql`
    query requestsByVisitIdDeliveryComplete($visitId: String!, $pageSize: Int!, $cursor: String!) {
        requestsByVisitId(visitId: $visitId, filter: { pageSize: $pageSize, after: $cursor }) {
            edges {
                cursor
                node {
                    id
                    status
                    requestType
                    __typename
                    ...DeliveryCompleteDeliverStorageItemRequest
                    ...DeliveryCompletePickupStorageItemRequest
                    ...DeliveryCompleteDeliverEmptyContainerRequest
                    ...DeliveryCompletePickupEmptyContainerRequest
                    ...DeliveryCompleteDriverPickupEmptyContainerRequest
                }
            }
            pageInfo {
                cursor {
                    afterCursor
                    beforeCursor
                }
                hasNextPage
                hasPreviousPage
            }
        }
    }
    ${DeliveryCompleteDeliverStorageItemRequestFragmentDoc}
    ${DeliveryCompletePickupStorageItemRequestFragmentDoc}
    ${DeliveryCompleteDeliverEmptyContainerRequestFragmentDoc}
    ${DeliveryCompletePickupEmptyContainerRequestFragmentDoc}
    ${DeliveryCompleteDriverPickupEmptyContainerRequestFragmentDoc}
`;

/**
 * __useRequestsByVisitIdDeliveryCompleteQuery__
 *
 * To run a query within a React component, call `useRequestsByVisitIdDeliveryCompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequestsByVisitIdDeliveryCompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequestsByVisitIdDeliveryCompleteQuery({
 *   variables: {
 *      visitId: // value for 'visitId'
 *      pageSize: // value for 'pageSize'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useRequestsByVisitIdDeliveryCompleteQuery(
    baseOptions: Apollo.QueryHookOptions<
        RequestsByVisitIdDeliveryCompleteQuery,
        RequestsByVisitIdDeliveryCompleteQueryVariables
    >,
) {
    return Apollo.useQuery<
        RequestsByVisitIdDeliveryCompleteQuery,
        RequestsByVisitIdDeliveryCompleteQueryVariables
    >(RequestsByVisitIdDeliveryCompleteDocument, baseOptions);
}
export function useRequestsByVisitIdDeliveryCompleteLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        RequestsByVisitIdDeliveryCompleteQuery,
        RequestsByVisitIdDeliveryCompleteQueryVariables
    >,
) {
    return Apollo.useLazyQuery<
        RequestsByVisitIdDeliveryCompleteQuery,
        RequestsByVisitIdDeliveryCompleteQueryVariables
    >(RequestsByVisitIdDeliveryCompleteDocument, baseOptions);
}
export type RequestsByVisitIdDeliveryCompleteQueryHookResult = ReturnType<
    typeof useRequestsByVisitIdDeliveryCompleteQuery
>;
export type RequestsByVisitIdDeliveryCompleteLazyQueryHookResult = ReturnType<
    typeof useRequestsByVisitIdDeliveryCompleteLazyQuery
>;
export type RequestsByVisitIdDeliveryCompleteQueryResult = Apollo.QueryResult<
    RequestsByVisitIdDeliveryCompleteQuery,
    RequestsByVisitIdDeliveryCompleteQueryVariables
>;
export const DeliveryCompleteVisitDocument = gql`
    mutation deliveryCompleteVisit($data: DeliveryCompleteVisitInput!) {
        deliveryCompleteVisit(data: $data) {
            data {
                id
                status
            }
        }
    }
`;
export type DeliveryCompleteVisitMutationFn = Apollo.MutationFunction<
    DeliveryCompleteVisitMutation,
    DeliveryCompleteVisitMutationVariables
>;

/**
 * __useDeliveryCompleteVisitMutation__
 *
 * To run a mutation, you first call `useDeliveryCompleteVisitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeliveryCompleteVisitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deliveryCompleteVisitMutation, { data, loading, error }] = useDeliveryCompleteVisitMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeliveryCompleteVisitMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeliveryCompleteVisitMutation,
        DeliveryCompleteVisitMutationVariables
    >,
) {
    return Apollo.useMutation<
        DeliveryCompleteVisitMutation,
        DeliveryCompleteVisitMutationVariables
    >(DeliveryCompleteVisitDocument, baseOptions);
}
export type DeliveryCompleteVisitMutationHookResult = ReturnType<
    typeof useDeliveryCompleteVisitMutation
>;
export type DeliveryCompleteVisitMutationResult = Apollo.MutationResult<DeliveryCompleteVisitMutation>;
export type DeliveryCompleteVisitMutationOptions = Apollo.BaseMutationOptions<
    DeliveryCompleteVisitMutation,
    DeliveryCompleteVisitMutationVariables
>;
export const DeliverEmptyContainerRequestsByVisitIdDocument = gql`
    query deliverEmptyContainerRequestsByVisitId(
        $visitId: String!
        $pageSize: Int!
        $cursor: String!
    ) {
        requestsByVisitId(visitId: $visitId, filter: { pageSize: $pageSize, after: $cursor }) {
            edges {
                cursor
                node {
                    id
                    requestType
                    ...deliverEmptyContainerRequestsByVisitIdDeliverRequest
                }
            }
            pageInfo {
                cursor {
                    beforeCursor
                    afterCursor
                }
                hasNextPage
                hasPreviousPage
            }
        }
    }
    ${DeliverEmptyContainerRequestsByVisitIdDeliverRequestFragmentDoc}
`;

/**
 * __useDeliverEmptyContainerRequestsByVisitIdQuery__
 *
 * To run a query within a React component, call `useDeliverEmptyContainerRequestsByVisitIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeliverEmptyContainerRequestsByVisitIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeliverEmptyContainerRequestsByVisitIdQuery({
 *   variables: {
 *      visitId: // value for 'visitId'
 *      pageSize: // value for 'pageSize'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useDeliverEmptyContainerRequestsByVisitIdQuery(
    baseOptions: Apollo.QueryHookOptions<
        DeliverEmptyContainerRequestsByVisitIdQuery,
        DeliverEmptyContainerRequestsByVisitIdQueryVariables
    >,
) {
    return Apollo.useQuery<
        DeliverEmptyContainerRequestsByVisitIdQuery,
        DeliverEmptyContainerRequestsByVisitIdQueryVariables
    >(DeliverEmptyContainerRequestsByVisitIdDocument, baseOptions);
}
export function useDeliverEmptyContainerRequestsByVisitIdLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        DeliverEmptyContainerRequestsByVisitIdQuery,
        DeliverEmptyContainerRequestsByVisitIdQueryVariables
    >,
) {
    return Apollo.useLazyQuery<
        DeliverEmptyContainerRequestsByVisitIdQuery,
        DeliverEmptyContainerRequestsByVisitIdQueryVariables
    >(DeliverEmptyContainerRequestsByVisitIdDocument, baseOptions);
}
export type DeliverEmptyContainerRequestsByVisitIdQueryHookResult = ReturnType<
    typeof useDeliverEmptyContainerRequestsByVisitIdQuery
>;
export type DeliverEmptyContainerRequestsByVisitIdLazyQueryHookResult = ReturnType<
    typeof useDeliverEmptyContainerRequestsByVisitIdLazyQuery
>;
export type DeliverEmptyContainerRequestsByVisitIdQueryResult = Apollo.QueryResult<
    DeliverEmptyContainerRequestsByVisitIdQuery,
    DeliverEmptyContainerRequestsByVisitIdQueryVariables
>;
export const DeliveryDeliverEmptyContainerDocument = gql`
    mutation deliveryDeliverEmptyContainer($data: DeliveryDeliverEmptyContainerInput!) {
        deliveryDeliverEmptyContainer(data: $data) {
            data {
                id
                status
            }
        }
    }
`;
export type DeliveryDeliverEmptyContainerMutationFn = Apollo.MutationFunction<
    DeliveryDeliverEmptyContainerMutation,
    DeliveryDeliverEmptyContainerMutationVariables
>;

/**
 * __useDeliveryDeliverEmptyContainerMutation__
 *
 * To run a mutation, you first call `useDeliveryDeliverEmptyContainerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeliveryDeliverEmptyContainerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deliveryDeliverEmptyContainerMutation, { data, loading, error }] = useDeliveryDeliverEmptyContainerMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeliveryDeliverEmptyContainerMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeliveryDeliverEmptyContainerMutation,
        DeliveryDeliverEmptyContainerMutationVariables
    >,
) {
    return Apollo.useMutation<
        DeliveryDeliverEmptyContainerMutation,
        DeliveryDeliverEmptyContainerMutationVariables
    >(DeliveryDeliverEmptyContainerDocument, baseOptions);
}
export type DeliveryDeliverEmptyContainerMutationHookResult = ReturnType<
    typeof useDeliveryDeliverEmptyContainerMutation
>;
export type DeliveryDeliverEmptyContainerMutationResult = Apollo.MutationResult<DeliveryDeliverEmptyContainerMutation>;
export type DeliveryDeliverEmptyContainerMutationOptions = Apollo.BaseMutationOptions<
    DeliveryDeliverEmptyContainerMutation,
    DeliveryDeliverEmptyContainerMutationVariables
>;
export const DeliveryRequestsByVisitIdDocument = gql`
    query deliveryRequestsByVisitId($visitId: String!, $pageSize: Int!, $cursor: String!) {
        requestsByVisitId(visitId: $visitId, filter: { pageSize: $pageSize, after: $cursor }) {
            edges {
                cursor
                node {
                    id
                    status
                    requestType
                    ...DeliveryDeliverProcessDeliverStorageItemRequest
                }
            }
            pageInfo {
                cursor {
                    afterCursor
                    beforeCursor
                }
                hasNextPage
                hasPreviousPage
            }
        }
    }
    ${DeliveryDeliverProcessDeliverStorageItemRequestFragmentDoc}
`;

/**
 * __useDeliveryRequestsByVisitIdQuery__
 *
 * To run a query within a React component, call `useDeliveryRequestsByVisitIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeliveryRequestsByVisitIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeliveryRequestsByVisitIdQuery({
 *   variables: {
 *      visitId: // value for 'visitId'
 *      pageSize: // value for 'pageSize'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useDeliveryRequestsByVisitIdQuery(
    baseOptions: Apollo.QueryHookOptions<
        DeliveryRequestsByVisitIdQuery,
        DeliveryRequestsByVisitIdQueryVariables
    >,
) {
    return Apollo.useQuery<DeliveryRequestsByVisitIdQuery, DeliveryRequestsByVisitIdQueryVariables>(
        DeliveryRequestsByVisitIdDocument,
        baseOptions,
    );
}
export function useDeliveryRequestsByVisitIdLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        DeliveryRequestsByVisitIdQuery,
        DeliveryRequestsByVisitIdQueryVariables
    >,
) {
    return Apollo.useLazyQuery<
        DeliveryRequestsByVisitIdQuery,
        DeliveryRequestsByVisitIdQueryVariables
    >(DeliveryRequestsByVisitIdDocument, baseOptions);
}
export type DeliveryRequestsByVisitIdQueryHookResult = ReturnType<
    typeof useDeliveryRequestsByVisitIdQuery
>;
export type DeliveryRequestsByVisitIdLazyQueryHookResult = ReturnType<
    typeof useDeliveryRequestsByVisitIdLazyQuery
>;
export type DeliveryRequestsByVisitIdQueryResult = Apollo.QueryResult<
    DeliveryRequestsByVisitIdQuery,
    DeliveryRequestsByVisitIdQueryVariables
>;
export const DeliveryDeliverItemDocument = gql`
    mutation deliveryDeliverItem($data: DeliveryDeliverItemInput!) {
        deliveryDeliverItem(data: $data) {
            data {
                id
                status
            }
        }
    }
`;
export type DeliveryDeliverItemMutationFn = Apollo.MutationFunction<
    DeliveryDeliverItemMutation,
    DeliveryDeliverItemMutationVariables
>;

/**
 * __useDeliveryDeliverItemMutation__
 *
 * To run a mutation, you first call `useDeliveryDeliverItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeliveryDeliverItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deliveryDeliverItemMutation, { data, loading, error }] = useDeliveryDeliverItemMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeliveryDeliverItemMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeliveryDeliverItemMutation,
        DeliveryDeliverItemMutationVariables
    >,
) {
    return Apollo.useMutation<DeliveryDeliverItemMutation, DeliveryDeliverItemMutationVariables>(
        DeliveryDeliverItemDocument,
        baseOptions,
    );
}
export type DeliveryDeliverItemMutationHookResult = ReturnType<
    typeof useDeliveryDeliverItemMutation
>;
export type DeliveryDeliverItemMutationResult = Apollo.MutationResult<DeliveryDeliverItemMutation>;
export type DeliveryDeliverItemMutationOptions = Apollo.BaseMutationOptions<
    DeliveryDeliverItemMutation,
    DeliveryDeliverItemMutationVariables
>;
export const PickupEmptyContainerRequestsByVisitIdDocument = gql`
    query pickupEmptyContainerRequestsByVisitId(
        $visitId: String!
        $pageSize: Int!
        $cursor: String!
    ) {
        requestsByVisitId(visitId: $visitId, filter: { pageSize: $pageSize, after: $cursor }) {
            edges {
                cursor
                node {
                    id
                    requestType
                    ...pickupEmptyContainerRequestsByVisitIdDeliverRequest
                }
            }
            pageInfo {
                cursor {
                    beforeCursor
                    afterCursor
                }
                hasNextPage
                hasPreviousPage
            }
        }
    }
    ${PickupEmptyContainerRequestsByVisitIdDeliverRequestFragmentDoc}
`;

/**
 * __usePickupEmptyContainerRequestsByVisitIdQuery__
 *
 * To run a query within a React component, call `usePickupEmptyContainerRequestsByVisitIdQuery` and pass it any options that fit your needs.
 * When your component renders, `usePickupEmptyContainerRequestsByVisitIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePickupEmptyContainerRequestsByVisitIdQuery({
 *   variables: {
 *      visitId: // value for 'visitId'
 *      pageSize: // value for 'pageSize'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function usePickupEmptyContainerRequestsByVisitIdQuery(
    baseOptions: Apollo.QueryHookOptions<
        PickupEmptyContainerRequestsByVisitIdQuery,
        PickupEmptyContainerRequestsByVisitIdQueryVariables
    >,
) {
    return Apollo.useQuery<
        PickupEmptyContainerRequestsByVisitIdQuery,
        PickupEmptyContainerRequestsByVisitIdQueryVariables
    >(PickupEmptyContainerRequestsByVisitIdDocument, baseOptions);
}
export function usePickupEmptyContainerRequestsByVisitIdLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        PickupEmptyContainerRequestsByVisitIdQuery,
        PickupEmptyContainerRequestsByVisitIdQueryVariables
    >,
) {
    return Apollo.useLazyQuery<
        PickupEmptyContainerRequestsByVisitIdQuery,
        PickupEmptyContainerRequestsByVisitIdQueryVariables
    >(PickupEmptyContainerRequestsByVisitIdDocument, baseOptions);
}
export type PickupEmptyContainerRequestsByVisitIdQueryHookResult = ReturnType<
    typeof usePickupEmptyContainerRequestsByVisitIdQuery
>;
export type PickupEmptyContainerRequestsByVisitIdLazyQueryHookResult = ReturnType<
    typeof usePickupEmptyContainerRequestsByVisitIdLazyQuery
>;
export type PickupEmptyContainerRequestsByVisitIdQueryResult = Apollo.QueryResult<
    PickupEmptyContainerRequestsByVisitIdQuery,
    PickupEmptyContainerRequestsByVisitIdQueryVariables
>;
export const DeliveryPickupEmptyContainerDocument = gql`
    mutation deliveryPickupEmptyContainer($data: DeliveryPickupEmptyContainerInput!) {
        deliveryPickupEmptyContainer(data: $data) {
            data {
                id
                status
            }
        }
    }
`;
export type DeliveryPickupEmptyContainerMutationFn = Apollo.MutationFunction<
    DeliveryPickupEmptyContainerMutation,
    DeliveryPickupEmptyContainerMutationVariables
>;

/**
 * __useDeliveryPickupEmptyContainerMutation__
 *
 * To run a mutation, you first call `useDeliveryPickupEmptyContainerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeliveryPickupEmptyContainerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deliveryPickupEmptyContainerMutation, { data, loading, error }] = useDeliveryPickupEmptyContainerMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeliveryPickupEmptyContainerMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeliveryPickupEmptyContainerMutation,
        DeliveryPickupEmptyContainerMutationVariables
    >,
) {
    return Apollo.useMutation<
        DeliveryPickupEmptyContainerMutation,
        DeliveryPickupEmptyContainerMutationVariables
    >(DeliveryPickupEmptyContainerDocument, baseOptions);
}
export type DeliveryPickupEmptyContainerMutationHookResult = ReturnType<
    typeof useDeliveryPickupEmptyContainerMutation
>;
export type DeliveryPickupEmptyContainerMutationResult = Apollo.MutationResult<DeliveryPickupEmptyContainerMutation>;
export type DeliveryPickupEmptyContainerMutationOptions = Apollo.BaseMutationOptions<
    DeliveryPickupEmptyContainerMutation,
    DeliveryPickupEmptyContainerMutationVariables
>;
export const RetrieveStorageItemDocument = gql`
    query RetrieveStorageItem($requestId: String!) {
        requestById(id: $requestId) {
            id
            ... on PickupStorageItemRequest {
                container {
                    id
                    barcode
                }
                storageItem {
                    id
                    status
                    barcode
                    container {
                        id
                        barcode
                    }
                    storageItemType {
                        id
                        metadataDefinitions {
                            id
                            propertyName
                            isRequired
                            propertyType
                            values
                            isActive
                            sortOrder
                        }
                        containerType {
                            id
                            description
                        }
                    }
                    metadataValue {
                        value
                        metadataDefinition {
                            id
                            propertyName
                        }
                    }
                }
            }
        }
    }
`;

/**
 * __useRetrieveStorageItemQuery__
 *
 * To run a query within a React component, call `useRetrieveStorageItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetrieveStorageItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetrieveStorageItemQuery({
 *   variables: {
 *      requestId: // value for 'requestId'
 *   },
 * });
 */
export function useRetrieveStorageItemQuery(
    baseOptions: Apollo.QueryHookOptions<
        RetrieveStorageItemQuery,
        RetrieveStorageItemQueryVariables
    >,
) {
    return Apollo.useQuery<RetrieveStorageItemQuery, RetrieveStorageItemQueryVariables>(
        RetrieveStorageItemDocument,
        baseOptions,
    );
}
export function useRetrieveStorageItemLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        RetrieveStorageItemQuery,
        RetrieveStorageItemQueryVariables
    >,
) {
    return Apollo.useLazyQuery<RetrieveStorageItemQuery, RetrieveStorageItemQueryVariables>(
        RetrieveStorageItemDocument,
        baseOptions,
    );
}
export type RetrieveStorageItemQueryHookResult = ReturnType<typeof useRetrieveStorageItemQuery>;
export type RetrieveStorageItemLazyQueryHookResult = ReturnType<
    typeof useRetrieveStorageItemLazyQuery
>;
export type RetrieveStorageItemQueryResult = Apollo.QueryResult<
    RetrieveStorageItemQuery,
    RetrieveStorageItemQueryVariables
>;
export const SetStorageItemMetadataDocument = gql`
    mutation setStorageItemMetadata($data: SetStorageItemMetadataInput!) {
        setStorageItemMetadata(data: $data) {
            values {
                value
            }
        }
    }
`;
export type SetStorageItemMetadataMutationFn = Apollo.MutationFunction<
    SetStorageItemMetadataMutation,
    SetStorageItemMetadataMutationVariables
>;

/**
 * __useSetStorageItemMetadataMutation__
 *
 * To run a mutation, you first call `useSetStorageItemMetadataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetStorageItemMetadataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setStorageItemMetadataMutation, { data, loading, error }] = useSetStorageItemMetadataMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSetStorageItemMetadataMutation(
    baseOptions?: Apollo.MutationHookOptions<
        SetStorageItemMetadataMutation,
        SetStorageItemMetadataMutationVariables
    >,
) {
    return Apollo.useMutation<
        SetStorageItemMetadataMutation,
        SetStorageItemMetadataMutationVariables
    >(SetStorageItemMetadataDocument, baseOptions);
}
export type SetStorageItemMetadataMutationHookResult = ReturnType<
    typeof useSetStorageItemMetadataMutation
>;
export type SetStorageItemMetadataMutationResult = Apollo.MutationResult<SetStorageItemMetadataMutation>;
export type SetStorageItemMetadataMutationOptions = Apollo.BaseMutationOptions<
    SetStorageItemMetadataMutation,
    SetStorageItemMetadataMutationVariables
>;
export const StorageItemAssignBarcodeDocument = gql`
    mutation storageItemAssignBarcode($data: StorageItemAssignBarcodeInput!) {
        storageItemAssignBarcode(data: $data) {
            data {
                id
                barcode
            }
        }
    }
`;
export type StorageItemAssignBarcodeMutationFn = Apollo.MutationFunction<
    StorageItemAssignBarcodeMutation,
    StorageItemAssignBarcodeMutationVariables
>;

/**
 * __useStorageItemAssignBarcodeMutation__
 *
 * To run a mutation, you first call `useStorageItemAssignBarcodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStorageItemAssignBarcodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [storageItemAssignBarcodeMutation, { data, loading, error }] = useStorageItemAssignBarcodeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useStorageItemAssignBarcodeMutation(
    baseOptions?: Apollo.MutationHookOptions<
        StorageItemAssignBarcodeMutation,
        StorageItemAssignBarcodeMutationVariables
    >,
) {
    return Apollo.useMutation<
        StorageItemAssignBarcodeMutation,
        StorageItemAssignBarcodeMutationVariables
    >(StorageItemAssignBarcodeDocument, baseOptions);
}
export type StorageItemAssignBarcodeMutationHookResult = ReturnType<
    typeof useStorageItemAssignBarcodeMutation
>;
export type StorageItemAssignBarcodeMutationResult = Apollo.MutationResult<StorageItemAssignBarcodeMutation>;
export type StorageItemAssignBarcodeMutationOptions = Apollo.BaseMutationOptions<
    StorageItemAssignBarcodeMutation,
    StorageItemAssignBarcodeMutationVariables
>;
export const RequestAssignContainerDocument = gql`
    mutation requestAssignContainer($data: RequestAssignContainerInput!) {
        requestAssignContainer(data: $data) {
            data {
                id
            }
        }
    }
`;
export type RequestAssignContainerMutationFn = Apollo.MutationFunction<
    RequestAssignContainerMutation,
    RequestAssignContainerMutationVariables
>;

/**
 * __useRequestAssignContainerMutation__
 *
 * To run a mutation, you first call `useRequestAssignContainerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestAssignContainerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestAssignContainerMutation, { data, loading, error }] = useRequestAssignContainerMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRequestAssignContainerMutation(
    baseOptions?: Apollo.MutationHookOptions<
        RequestAssignContainerMutation,
        RequestAssignContainerMutationVariables
    >,
) {
    return Apollo.useMutation<
        RequestAssignContainerMutation,
        RequestAssignContainerMutationVariables
    >(RequestAssignContainerDocument, baseOptions);
}
export type RequestAssignContainerMutationHookResult = ReturnType<
    typeof useRequestAssignContainerMutation
>;
export type RequestAssignContainerMutationResult = Apollo.MutationResult<RequestAssignContainerMutation>;
export type RequestAssignContainerMutationOptions = Apollo.BaseMutationOptions<
    RequestAssignContainerMutation,
    RequestAssignContainerMutationVariables
>;
export const PickupRequestsByVisitIdDocument = gql`
    query pickupRequestsByVisitId($visitId: String!, $pageSize: Int!, $cursor: String!) {
        requestsByVisitId(visitId: $visitId, filter: { pageSize: $pageSize, after: $cursor }) {
            edges {
                cursor
                node {
                    id
                    requestType
                    ...pickupRequestsByVisitIdPickupRequest
                }
            }
            pageInfo {
                cursor {
                    beforeCursor
                    afterCursor
                }
                hasNextPage
                hasPreviousPage
            }
        }
    }
    ${PickupRequestsByVisitIdPickupRequestFragmentDoc}
`;

/**
 * __usePickupRequestsByVisitIdQuery__
 *
 * To run a query within a React component, call `usePickupRequestsByVisitIdQuery` and pass it any options that fit your needs.
 * When your component renders, `usePickupRequestsByVisitIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePickupRequestsByVisitIdQuery({
 *   variables: {
 *      visitId: // value for 'visitId'
 *      pageSize: // value for 'pageSize'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function usePickupRequestsByVisitIdQuery(
    baseOptions: Apollo.QueryHookOptions<
        PickupRequestsByVisitIdQuery,
        PickupRequestsByVisitIdQueryVariables
    >,
) {
    return Apollo.useQuery<PickupRequestsByVisitIdQuery, PickupRequestsByVisitIdQueryVariables>(
        PickupRequestsByVisitIdDocument,
        baseOptions,
    );
}
export function usePickupRequestsByVisitIdLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        PickupRequestsByVisitIdQuery,
        PickupRequestsByVisitIdQueryVariables
    >,
) {
    return Apollo.useLazyQuery<PickupRequestsByVisitIdQuery, PickupRequestsByVisitIdQueryVariables>(
        PickupRequestsByVisitIdDocument,
        baseOptions,
    );
}
export type PickupRequestsByVisitIdQueryHookResult = ReturnType<
    typeof usePickupRequestsByVisitIdQuery
>;
export type PickupRequestsByVisitIdLazyQueryHookResult = ReturnType<
    typeof usePickupRequestsByVisitIdLazyQuery
>;
export type PickupRequestsByVisitIdQueryResult = Apollo.QueryResult<
    PickupRequestsByVisitIdQuery,
    PickupRequestsByVisitIdQueryVariables
>;
export const DeliveryPickupItemDocument = gql`
    mutation deliveryPickupItem($data: DeliveryPickupItemInput!) {
        deliveryPickupItem(data: $data) {
            data {
                id
                status
            }
        }
    }
`;
export type DeliveryPickupItemMutationFn = Apollo.MutationFunction<
    DeliveryPickupItemMutation,
    DeliveryPickupItemMutationVariables
>;

/**
 * __useDeliveryPickupItemMutation__
 *
 * To run a mutation, you first call `useDeliveryPickupItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeliveryPickupItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deliveryPickupItemMutation, { data, loading, error }] = useDeliveryPickupItemMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeliveryPickupItemMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeliveryPickupItemMutation,
        DeliveryPickupItemMutationVariables
    >,
) {
    return Apollo.useMutation<DeliveryPickupItemMutation, DeliveryPickupItemMutationVariables>(
        DeliveryPickupItemDocument,
        baseOptions,
    );
}
export type DeliveryPickupItemMutationHookResult = ReturnType<typeof useDeliveryPickupItemMutation>;
export type DeliveryPickupItemMutationResult = Apollo.MutationResult<DeliveryPickupItemMutation>;
export type DeliveryPickupItemMutationOptions = Apollo.BaseMutationOptions<
    DeliveryPickupItemMutation,
    DeliveryPickupItemMutationVariables
>;
export const GetRouteDetailsDocument = gql`
    query getRouteDetails($routeId: String!) {
        routes(filter: { conditions: [{ field: "id", value: $routeId, operator: Equal }] }) {
            edges {
                cursor
                node {
                    id
                    description
                    routeNumber
                    status
                    operationDate
                    employees {
                        id
                        firstName
                        lastName
                    }
                    stops {
                        ...deliveryRouteDetailRouteStop
                    }
                }
            }
            pageInfo {
                cursor {
                    beforeCursor
                    afterCursor
                }
                hasNextPage
                hasPreviousPage
            }
        }
    }
    ${DeliveryRouteDetailRouteStopFragmentDoc}
`;

/**
 * __useGetRouteDetailsQuery__
 *
 * To run a query within a React component, call `useGetRouteDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRouteDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRouteDetailsQuery({
 *   variables: {
 *      routeId: // value for 'routeId'
 *   },
 * });
 */
export function useGetRouteDetailsQuery(
    baseOptions: Apollo.QueryHookOptions<GetRouteDetailsQuery, GetRouteDetailsQueryVariables>,
) {
    return Apollo.useQuery<GetRouteDetailsQuery, GetRouteDetailsQueryVariables>(
        GetRouteDetailsDocument,
        baseOptions,
    );
}
export function useGetRouteDetailsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetRouteDetailsQuery, GetRouteDetailsQueryVariables>,
) {
    return Apollo.useLazyQuery<GetRouteDetailsQuery, GetRouteDetailsQueryVariables>(
        GetRouteDetailsDocument,
        baseOptions,
    );
}
export type GetRouteDetailsQueryHookResult = ReturnType<typeof useGetRouteDetailsQuery>;
export type GetRouteDetailsLazyQueryHookResult = ReturnType<typeof useGetRouteDetailsLazyQuery>;
export type GetRouteDetailsQueryResult = Apollo.QueryResult<
    GetRouteDetailsQuery,
    GetRouteDetailsQueryVariables
>;
export const GetRoutesDocument = gql`
    query getRoutes($startDate: String!, $endDate: String!, $pageSize: Int!, $cursor: String!) {
        routes(
            filter: {
                conditions: [
                    { field: "operationDate", value: $startDate, operator: GreaterthanOrEqualto }
                    { field: "operationDate", value: $endDate, operator: Lesserthanorequalto }
                ]
                pageSize: $pageSize
                after: $cursor
            }
        ) {
            edges {
                cursor
                node {
                    id
                    ...deliveryRoutesRoute
                }
            }
            pageInfo {
                cursor {
                    beforeCursor
                    afterCursor
                }
                hasNextPage
                hasPreviousPage
            }
        }
    }
    ${DeliveryRoutesRouteFragmentDoc}
`;

/**
 * __useGetRoutesQuery__
 *
 * To run a query within a React component, call `useGetRoutesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoutesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoutesQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      pageSize: // value for 'pageSize'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetRoutesQuery(
    baseOptions: Apollo.QueryHookOptions<GetRoutesQuery, GetRoutesQueryVariables>,
) {
    return Apollo.useQuery<GetRoutesQuery, GetRoutesQueryVariables>(GetRoutesDocument, baseOptions);
}
export function useGetRoutesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetRoutesQuery, GetRoutesQueryVariables>,
) {
    return Apollo.useLazyQuery<GetRoutesQuery, GetRoutesQueryVariables>(
        GetRoutesDocument,
        baseOptions,
    );
}
export type GetRoutesQueryHookResult = ReturnType<typeof useGetRoutesQuery>;
export type GetRoutesLazyQueryHookResult = ReturnType<typeof useGetRoutesLazyQuery>;
export type GetRoutesQueryResult = Apollo.QueryResult<GetRoutesQuery, GetRoutesQueryVariables>;
export const DeliverVisitDetailsDocument = gql`
    query DeliverVisitDetails($visitId: String!) {
        visitById(visitId: $visitId) {
            id
            status
            expectedDuration
            startTime
            endTime
            summary {
                inComing
                outGoing
                multiplePeople
                firstVisit
            }
            customerAddress {
                address1
                city
                countryId
                firstName
                lastName
            }
            scheduleWindow {
                startTime
                endTime
            }
            customer {
                email
                firstName
                lastName
                homePhone
                cellPhone
            }
            requests(filter: {}) {
                edges {
                    cursor
                    node {
                        id
                        status
                        requestType
                    }
                }
            }
            stop {
                sequence
            }
        }
    }
`;

/**
 * __useDeliverVisitDetailsQuery__
 *
 * To run a query within a React component, call `useDeliverVisitDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeliverVisitDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeliverVisitDetailsQuery({
 *   variables: {
 *      visitId: // value for 'visitId'
 *   },
 * });
 */
export function useDeliverVisitDetailsQuery(
    baseOptions: Apollo.QueryHookOptions<
        DeliverVisitDetailsQuery,
        DeliverVisitDetailsQueryVariables
    >,
) {
    return Apollo.useQuery<DeliverVisitDetailsQuery, DeliverVisitDetailsQueryVariables>(
        DeliverVisitDetailsDocument,
        baseOptions,
    );
}
export function useDeliverVisitDetailsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        DeliverVisitDetailsQuery,
        DeliverVisitDetailsQueryVariables
    >,
) {
    return Apollo.useLazyQuery<DeliverVisitDetailsQuery, DeliverVisitDetailsQueryVariables>(
        DeliverVisitDetailsDocument,
        baseOptions,
    );
}
export type DeliverVisitDetailsQueryHookResult = ReturnType<typeof useDeliverVisitDetailsQuery>;
export type DeliverVisitDetailsLazyQueryHookResult = ReturnType<
    typeof useDeliverVisitDetailsLazyQuery
>;
export type DeliverVisitDetailsQueryResult = Apollo.QueryResult<
    DeliverVisitDetailsQuery,
    DeliverVisitDetailsQueryVariables
>;
export const HomeDocument = gql`
    query home {
        serviceProvider {
            id
            name
            customerUserPool
            employeeUserPool
            cognitoRegion
            cognitoWebApplicationId
            theme
        }
    }
`;

/**
 * __useHomeQuery__
 *
 * To run a query within a React component, call `useHomeQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeQuery({
 *   variables: {
 *   },
 * });
 */
export function useHomeQuery(baseOptions?: Apollo.QueryHookOptions<HomeQuery, HomeQueryVariables>) {
    return Apollo.useQuery<HomeQuery, HomeQueryVariables>(HomeDocument, baseOptions);
}
export function useHomeLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<HomeQuery, HomeQueryVariables>,
) {
    return Apollo.useLazyQuery<HomeQuery, HomeQueryVariables>(HomeDocument, baseOptions);
}
export type HomeQueryHookResult = ReturnType<typeof useHomeQuery>;
export type HomeLazyQueryHookResult = ReturnType<typeof useHomeLazyQuery>;
export type HomeQueryResult = Apollo.QueryResult<HomeQuery, HomeQueryVariables>;
export const MovingProcessItemDocument = gql`
    mutation movingProcessItem($data: MovingProcessItemInput!) {
        movingProcessItem(data: $data) {
            data {
                id
                ...movingProcessItemStorageItem
            }
        }
    }
    ${MovingProcessItemStorageItemFragmentDoc}
`;
export type MovingProcessItemMutationFn = Apollo.MutationFunction<
    MovingProcessItemMutation,
    MovingProcessItemMutationVariables
>;

/**
 * __useMovingProcessItemMutation__
 *
 * To run a mutation, you first call `useMovingProcessItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMovingProcessItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [movingProcessItemMutation, { data, loading, error }] = useMovingProcessItemMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useMovingProcessItemMutation(
    baseOptions?: Apollo.MutationHookOptions<
        MovingProcessItemMutation,
        MovingProcessItemMutationVariables
    >,
) {
    return Apollo.useMutation<MovingProcessItemMutation, MovingProcessItemMutationVariables>(
        MovingProcessItemDocument,
        baseOptions,
    );
}
export type MovingProcessItemMutationHookResult = ReturnType<typeof useMovingProcessItemMutation>;
export type MovingProcessItemMutationResult = Apollo.MutationResult<MovingProcessItemMutation>;
export type MovingProcessItemMutationOptions = Apollo.BaseMutationOptions<
    MovingProcessItemMutation,
    MovingProcessItemMutationVariables
>;
export const MovingLocationByBarcodeDocument = gql`
    query movingLocationByBarcode($barcode: String!) {
        locationByBarcode(barcode: $barcode) {
            id
            ...movingProcessItemLocation
        }
    }
    ${MovingProcessItemLocationFragmentDoc}
`;

/**
 * __useMovingLocationByBarcodeQuery__
 *
 * To run a query within a React component, call `useMovingLocationByBarcodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMovingLocationByBarcodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMovingLocationByBarcodeQuery({
 *   variables: {
 *      barcode: // value for 'barcode'
 *   },
 * });
 */
export function useMovingLocationByBarcodeQuery(
    baseOptions: Apollo.QueryHookOptions<
        MovingLocationByBarcodeQuery,
        MovingLocationByBarcodeQueryVariables
    >,
) {
    return Apollo.useQuery<MovingLocationByBarcodeQuery, MovingLocationByBarcodeQueryVariables>(
        MovingLocationByBarcodeDocument,
        baseOptions,
    );
}
export function useMovingLocationByBarcodeLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        MovingLocationByBarcodeQuery,
        MovingLocationByBarcodeQueryVariables
    >,
) {
    return Apollo.useLazyQuery<MovingLocationByBarcodeQuery, MovingLocationByBarcodeQueryVariables>(
        MovingLocationByBarcodeDocument,
        baseOptions,
    );
}
export type MovingLocationByBarcodeQueryHookResult = ReturnType<
    typeof useMovingLocationByBarcodeQuery
>;
export type MovingLocationByBarcodeLazyQueryHookResult = ReturnType<
    typeof useMovingLocationByBarcodeLazyQuery
>;
export type MovingLocationByBarcodeQueryResult = Apollo.QueryResult<
    MovingLocationByBarcodeQuery,
    MovingLocationByBarcodeQueryVariables
>;
export const GetPickListSummaryDocument = gql`
    query getPickListSummary($filterInput: PickingListSummaryFilterInput!) {
        pickingListSummary(data: $filterInput) {
            data {
                storageItemType {
                    id
                    name
                }
                listTotalItems
                warehouseTotalItems
            }
        }
    }
`;

/**
 * __useGetPickListSummaryQuery__
 *
 * To run a query within a React component, call `useGetPickListSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPickListSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPickListSummaryQuery({
 *   variables: {
 *      filterInput: // value for 'filterInput'
 *   },
 * });
 */
export function useGetPickListSummaryQuery(
    baseOptions: Apollo.QueryHookOptions<GetPickListSummaryQuery, GetPickListSummaryQueryVariables>,
) {
    return Apollo.useQuery<GetPickListSummaryQuery, GetPickListSummaryQueryVariables>(
        GetPickListSummaryDocument,
        baseOptions,
    );
}
export function useGetPickListSummaryLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetPickListSummaryQuery,
        GetPickListSummaryQueryVariables
    >,
) {
    return Apollo.useLazyQuery<GetPickListSummaryQuery, GetPickListSummaryQueryVariables>(
        GetPickListSummaryDocument,
        baseOptions,
    );
}
export type GetPickListSummaryQueryHookResult = ReturnType<typeof useGetPickListSummaryQuery>;
export type GetPickListSummaryLazyQueryHookResult = ReturnType<
    typeof useGetPickListSummaryLazyQuery
>;
export type GetPickListSummaryQueryResult = Apollo.QueryResult<
    GetPickListSummaryQuery,
    GetPickListSummaryQueryVariables
>;
export const GetVisitsDocument = gql`
    query getVisits($visitsFilterInput: FilterInput!) {
        visits(filter: $visitsFilterInput) {
            edges {
                cursor
                node {
                    id
                    ...PickListFiltersVisit
                }
            }
            pageInfo {
                cursor {
                    beforeCursor
                    afterCursor
                }
                hasNextPage
                hasPreviousPage
            }
        }
    }
    ${PickListFiltersVisitFragmentDoc}
`;

/**
 * __useGetVisitsQuery__
 *
 * To run a query within a React component, call `useGetVisitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVisitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVisitsQuery({
 *   variables: {
 *      visitsFilterInput: // value for 'visitsFilterInput'
 *   },
 * });
 */
export function useGetVisitsQuery(
    baseOptions: Apollo.QueryHookOptions<GetVisitsQuery, GetVisitsQueryVariables>,
) {
    return Apollo.useQuery<GetVisitsQuery, GetVisitsQueryVariables>(GetVisitsDocument, baseOptions);
}
export function useGetVisitsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetVisitsQuery, GetVisitsQueryVariables>,
) {
    return Apollo.useLazyQuery<GetVisitsQuery, GetVisitsQueryVariables>(
        GetVisitsDocument,
        baseOptions,
    );
}
export type GetVisitsQueryHookResult = ReturnType<typeof useGetVisitsQuery>;
export type GetVisitsLazyQueryHookResult = ReturnType<typeof useGetVisitsLazyQuery>;
export type GetVisitsQueryResult = Apollo.QueryResult<GetVisitsQuery, GetVisitsQueryVariables>;
export const GetStorageItemTypesDocument = gql`
    query getStorageItemTypes($storageItemTypesFilterInput: FilterInput!) {
        serviceProvider {
            id
            storageItemTypes(filter: $storageItemTypesFilterInput) {
                edges {
                    cursor
                    node {
                        id
                        ...PickListFiltersStorageItemType
                    }
                }
                pageInfo {
                    cursor {
                        beforeCursor
                        afterCursor
                    }
                    hasNextPage
                    hasPreviousPage
                }
            }
        }
    }
    ${PickListFiltersStorageItemTypeFragmentDoc}
`;

/**
 * __useGetStorageItemTypesQuery__
 *
 * To run a query within a React component, call `useGetStorageItemTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStorageItemTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStorageItemTypesQuery({
 *   variables: {
 *      storageItemTypesFilterInput: // value for 'storageItemTypesFilterInput'
 *   },
 * });
 */
export function useGetStorageItemTypesQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetStorageItemTypesQuery,
        GetStorageItemTypesQueryVariables
    >,
) {
    return Apollo.useQuery<GetStorageItemTypesQuery, GetStorageItemTypesQueryVariables>(
        GetStorageItemTypesDocument,
        baseOptions,
    );
}
export function useGetStorageItemTypesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetStorageItemTypesQuery,
        GetStorageItemTypesQueryVariables
    >,
) {
    return Apollo.useLazyQuery<GetStorageItemTypesQuery, GetStorageItemTypesQueryVariables>(
        GetStorageItemTypesDocument,
        baseOptions,
    );
}
export type GetStorageItemTypesQueryHookResult = ReturnType<typeof useGetStorageItemTypesQuery>;
export type GetStorageItemTypesLazyQueryHookResult = ReturnType<
    typeof useGetStorageItemTypesLazyQuery
>;
export type GetStorageItemTypesQueryResult = Apollo.QueryResult<
    GetStorageItemTypesQuery,
    GetStorageItemTypesQueryVariables
>;
export const CreatePickListDocument = gql`
    mutation createPickList($description: String, $filterInput: PickingCreateListFilterInput!) {
        pickingCreateList(data: { description: $description, filter: $filterInput }) {
            pickList {
                id
            }
        }
    }
`;
export type CreatePickListMutationFn = Apollo.MutationFunction<
    CreatePickListMutation,
    CreatePickListMutationVariables
>;

/**
 * __useCreatePickListMutation__
 *
 * To run a mutation, you first call `useCreatePickListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePickListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPickListMutation, { data, loading, error }] = useCreatePickListMutation({
 *   variables: {
 *      description: // value for 'description'
 *      filterInput: // value for 'filterInput'
 *   },
 * });
 */
export function useCreatePickListMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreatePickListMutation,
        CreatePickListMutationVariables
    >,
) {
    return Apollo.useMutation<CreatePickListMutation, CreatePickListMutationVariables>(
        CreatePickListDocument,
        baseOptions,
    );
}
export type CreatePickListMutationHookResult = ReturnType<typeof useCreatePickListMutation>;
export type CreatePickListMutationResult = Apollo.MutationResult<CreatePickListMutation>;
export type CreatePickListMutationOptions = Apollo.BaseMutationOptions<
    CreatePickListMutation,
    CreatePickListMutationVariables
>;
export const GetPickListsDocument = gql`
    query GetPickLists($pageSize: Int!, $cursor: String!) {
        pickLists(filter: { pageSize: $pageSize, after: $cursor }) {
            edges {
                cursor
                node {
                    id
                    ...RetrievePickListsPickList
                }
            }
            pageInfo {
                cursor {
                    beforeCursor
                    afterCursor
                }
                hasNextPage
                hasPreviousPage
            }
        }
    }
    ${RetrievePickListsPickListFragmentDoc}
`;

/**
 * __useGetPickListsQuery__
 *
 * To run a query within a React component, call `useGetPickListsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPickListsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPickListsQuery({
 *   variables: {
 *      pageSize: // value for 'pageSize'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetPickListsQuery(
    baseOptions: Apollo.QueryHookOptions<GetPickListsQuery, GetPickListsQueryVariables>,
) {
    return Apollo.useQuery<GetPickListsQuery, GetPickListsQueryVariables>(
        GetPickListsDocument,
        baseOptions,
    );
}
export function useGetPickListsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetPickListsQuery, GetPickListsQueryVariables>,
) {
    return Apollo.useLazyQuery<GetPickListsQuery, GetPickListsQueryVariables>(
        GetPickListsDocument,
        baseOptions,
    );
}
export type GetPickListsQueryHookResult = ReturnType<typeof useGetPickListsQuery>;
export type GetPickListsLazyQueryHookResult = ReturnType<typeof useGetPickListsLazyQuery>;
export type GetPickListsQueryResult = Apollo.QueryResult<
    GetPickListsQuery,
    GetPickListsQueryVariables
>;
export const GetPickListByIdDocument = gql`
    query getPickListById($pickListId: String!, $pageSize: Int!, $cursor: String!) {
        pickListById(id: $pickListId) {
            id
            pickListNumber
            description
            status
            startTime
            endTime
            created_at
            items(filter: { pageSize: $pageSize, after: $cursor }) {
                edges {
                    cursor
                    node {
                        id
                        ...PicklistProcessPicklistItem
                        ...PicklistSummaryPicklistItem
                    }
                }
                pageInfo {
                    cursor {
                        beforeCursor
                        afterCursor
                    }
                    hasNextPage
                    hasPreviousPage
                }
            }
            visitStartTime
            visitEndTime
            locations {
                from
                to
            }
            storageItemTypes {
                id
            }
            visits {
                id
            }
            maxQuantity
        }
    }
    ${PicklistProcessPicklistItemFragmentDoc}
    ${PicklistSummaryPicklistItemFragmentDoc}
`;

/**
 * __useGetPickListByIdQuery__
 *
 * To run a query within a React component, call `useGetPickListByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPickListByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPickListByIdQuery({
 *   variables: {
 *      pickListId: // value for 'pickListId'
 *      pageSize: // value for 'pageSize'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetPickListByIdQuery(
    baseOptions: Apollo.QueryHookOptions<GetPickListByIdQuery, GetPickListByIdQueryVariables>,
) {
    return Apollo.useQuery<GetPickListByIdQuery, GetPickListByIdQueryVariables>(
        GetPickListByIdDocument,
        baseOptions,
    );
}
export function useGetPickListByIdLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetPickListByIdQuery, GetPickListByIdQueryVariables>,
) {
    return Apollo.useLazyQuery<GetPickListByIdQuery, GetPickListByIdQueryVariables>(
        GetPickListByIdDocument,
        baseOptions,
    );
}
export type GetPickListByIdQueryHookResult = ReturnType<typeof useGetPickListByIdQuery>;
export type GetPickListByIdLazyQueryHookResult = ReturnType<typeof useGetPickListByIdLazyQuery>;
export type GetPickListByIdQueryResult = Apollo.QueryResult<
    GetPickListByIdQuery,
    GetPickListByIdQueryVariables
>;
export const PickListCompleteListDocument = gql`
    mutation pickListCompleteList($pickListId: String!) {
        pickingCompleteList(data: { pickListId: $pickListId }) {
            pickList {
                id
                pickListNumber
                status
            }
        }
    }
`;
export type PickListCompleteListMutationFn = Apollo.MutationFunction<
    PickListCompleteListMutation,
    PickListCompleteListMutationVariables
>;

/**
 * __usePickListCompleteListMutation__
 *
 * To run a mutation, you first call `usePickListCompleteListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePickListCompleteListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pickListCompleteListMutation, { data, loading, error }] = usePickListCompleteListMutation({
 *   variables: {
 *      pickListId: // value for 'pickListId'
 *   },
 * });
 */
export function usePickListCompleteListMutation(
    baseOptions?: Apollo.MutationHookOptions<
        PickListCompleteListMutation,
        PickListCompleteListMutationVariables
    >,
) {
    return Apollo.useMutation<PickListCompleteListMutation, PickListCompleteListMutationVariables>(
        PickListCompleteListDocument,
        baseOptions,
    );
}
export type PickListCompleteListMutationHookResult = ReturnType<
    typeof usePickListCompleteListMutation
>;
export type PickListCompleteListMutationResult = Apollo.MutationResult<PickListCompleteListMutation>;
export type PickListCompleteListMutationOptions = Apollo.BaseMutationOptions<
    PickListCompleteListMutation,
    PickListCompleteListMutationVariables
>;
export const PickListPickItemDocument = gql`
    mutation pickListPickItem($pickListItemId: String!, $targetLocationName: String!) {
        pickingPickItem(
            data: { pickListItemId: $pickListItemId, targetLocationName: $targetLocationName }
        ) {
            pickListItem {
                id
                request {
                    id
                    status
                }
            }
        }
    }
`;
export type PickListPickItemMutationFn = Apollo.MutationFunction<
    PickListPickItemMutation,
    PickListPickItemMutationVariables
>;

/**
 * __usePickListPickItemMutation__
 *
 * To run a mutation, you first call `usePickListPickItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePickListPickItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pickListPickItemMutation, { data, loading, error }] = usePickListPickItemMutation({
 *   variables: {
 *      pickListItemId: // value for 'pickListItemId'
 *      targetLocationName: // value for 'targetLocationName'
 *   },
 * });
 */
export function usePickListPickItemMutation(
    baseOptions?: Apollo.MutationHookOptions<
        PickListPickItemMutation,
        PickListPickItemMutationVariables
    >,
) {
    return Apollo.useMutation<PickListPickItemMutation, PickListPickItemMutationVariables>(
        PickListPickItemDocument,
        baseOptions,
    );
}
export type PickListPickItemMutationHookResult = ReturnType<typeof usePickListPickItemMutation>;
export type PickListPickItemMutationResult = Apollo.MutationResult<PickListPickItemMutation>;
export type PickListPickItemMutationOptions = Apollo.BaseMutationOptions<
    PickListPickItemMutation,
    PickListPickItemMutationVariables
>;
export const PickListExcludeItemDocument = gql`
    mutation pickListExcludeItem($pickListItemId: String!) {
        pickingExcludeItem(data: { pickListItemId: $pickListItemId }) {
            pickListItem {
                id
                request {
                    id
                    status
                }
            }
        }
    }
`;
export type PickListExcludeItemMutationFn = Apollo.MutationFunction<
    PickListExcludeItemMutation,
    PickListExcludeItemMutationVariables
>;

/**
 * __usePickListExcludeItemMutation__
 *
 * To run a mutation, you first call `usePickListExcludeItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePickListExcludeItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pickListExcludeItemMutation, { data, loading, error }] = usePickListExcludeItemMutation({
 *   variables: {
 *      pickListItemId: // value for 'pickListItemId'
 *   },
 * });
 */
export function usePickListExcludeItemMutation(
    baseOptions?: Apollo.MutationHookOptions<
        PickListExcludeItemMutation,
        PickListExcludeItemMutationVariables
    >,
) {
    return Apollo.useMutation<PickListExcludeItemMutation, PickListExcludeItemMutationVariables>(
        PickListExcludeItemDocument,
        baseOptions,
    );
}
export type PickListExcludeItemMutationHookResult = ReturnType<
    typeof usePickListExcludeItemMutation
>;
export type PickListExcludeItemMutationResult = Apollo.MutationResult<PickListExcludeItemMutation>;
export type PickListExcludeItemMutationOptions = Apollo.BaseMutationOptions<
    PickListExcludeItemMutation,
    PickListExcludeItemMutationVariables
>;
export const RefileStorageItemByBarcodeDocument = gql`
    query refileStorageItemByBarcode($barcode: String!) {
        storageItemByBarcode(barcode: $barcode) {
            ...RefilingStorageItem
        }
    }
    ${RefilingStorageItemFragmentDoc}
`;

/**
 * __useRefileStorageItemByBarcodeQuery__
 *
 * To run a query within a React component, call `useRefileStorageItemByBarcodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useRefileStorageItemByBarcodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRefileStorageItemByBarcodeQuery({
 *   variables: {
 *      barcode: // value for 'barcode'
 *   },
 * });
 */
export function useRefileStorageItemByBarcodeQuery(
    baseOptions: Apollo.QueryHookOptions<
        RefileStorageItemByBarcodeQuery,
        RefileStorageItemByBarcodeQueryVariables
    >,
) {
    return Apollo.useQuery<
        RefileStorageItemByBarcodeQuery,
        RefileStorageItemByBarcodeQueryVariables
    >(RefileStorageItemByBarcodeDocument, baseOptions);
}
export function useRefileStorageItemByBarcodeLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        RefileStorageItemByBarcodeQuery,
        RefileStorageItemByBarcodeQueryVariables
    >,
) {
    return Apollo.useLazyQuery<
        RefileStorageItemByBarcodeQuery,
        RefileStorageItemByBarcodeQueryVariables
    >(RefileStorageItemByBarcodeDocument, baseOptions);
}
export type RefileStorageItemByBarcodeQueryHookResult = ReturnType<
    typeof useRefileStorageItemByBarcodeQuery
>;
export type RefileStorageItemByBarcodeLazyQueryHookResult = ReturnType<
    typeof useRefileStorageItemByBarcodeLazyQuery
>;
export type RefileStorageItemByBarcodeQueryResult = Apollo.QueryResult<
    RefileStorageItemByBarcodeQuery,
    RefileStorageItemByBarcodeQueryVariables
>;
export const RefilingCreateListDocument = gql`
    mutation refilingCreateList($inputData: RefilingCreateListInput!) {
        refilingCreateList(data: $inputData) {
            data {
                id
            }
        }
    }
`;
export type RefilingCreateListMutationFn = Apollo.MutationFunction<
    RefilingCreateListMutation,
    RefilingCreateListMutationVariables
>;

/**
 * __useRefilingCreateListMutation__
 *
 * To run a mutation, you first call `useRefilingCreateListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefilingCreateListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refilingCreateListMutation, { data, loading, error }] = useRefilingCreateListMutation({
 *   variables: {
 *      inputData: // value for 'inputData'
 *   },
 * });
 */
export function useRefilingCreateListMutation(
    baseOptions?: Apollo.MutationHookOptions<
        RefilingCreateListMutation,
        RefilingCreateListMutationVariables
    >,
) {
    return Apollo.useMutation<RefilingCreateListMutation, RefilingCreateListMutationVariables>(
        RefilingCreateListDocument,
        baseOptions,
    );
}
export type RefilingCreateListMutationHookResult = ReturnType<typeof useRefilingCreateListMutation>;
export type RefilingCreateListMutationResult = Apollo.MutationResult<RefilingCreateListMutation>;
export type RefilingCreateListMutationOptions = Apollo.BaseMutationOptions<
    RefilingCreateListMutation,
    RefilingCreateListMutationVariables
>;
export const RefileListGetRefilesDocument = gql`
    query refileListGetRefiles($pageSize: Int!, $cursor: String!) {
        refileLists(filter: { pageSize: $pageSize, after: $cursor }) {
            edges {
                cursor
                node {
                    id
                    ...refileList
                }
            }
            pageInfo {
                cursor {
                    beforeCursor
                    afterCursor
                }
                hasNextPage
                hasPreviousPage
            }
        }
    }
    ${RefileListFragmentDoc}
`;

/**
 * __useRefileListGetRefilesQuery__
 *
 * To run a query within a React component, call `useRefileListGetRefilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRefileListGetRefilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRefileListGetRefilesQuery({
 *   variables: {
 *      pageSize: // value for 'pageSize'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useRefileListGetRefilesQuery(
    baseOptions: Apollo.QueryHookOptions<
        RefileListGetRefilesQuery,
        RefileListGetRefilesQueryVariables
    >,
) {
    return Apollo.useQuery<RefileListGetRefilesQuery, RefileListGetRefilesQueryVariables>(
        RefileListGetRefilesDocument,
        baseOptions,
    );
}
export function useRefileListGetRefilesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        RefileListGetRefilesQuery,
        RefileListGetRefilesQueryVariables
    >,
) {
    return Apollo.useLazyQuery<RefileListGetRefilesQuery, RefileListGetRefilesQueryVariables>(
        RefileListGetRefilesDocument,
        baseOptions,
    );
}
export type RefileListGetRefilesQueryHookResult = ReturnType<typeof useRefileListGetRefilesQuery>;
export type RefileListGetRefilesLazyQueryHookResult = ReturnType<
    typeof useRefileListGetRefilesLazyQuery
>;
export type RefileListGetRefilesQueryResult = Apollo.QueryResult<
    RefileListGetRefilesQuery,
    RefileListGetRefilesQueryVariables
>;
export const RefileListByIdDocument = gql`
    query refileListById($refileListId: String!) {
        refileListById(id: $refileListId) {
            id
            ...refileListProcessItem
        }
    }
    ${RefileListProcessItemFragmentDoc}
`;

/**
 * __useRefileListByIdQuery__
 *
 * To run a query within a React component, call `useRefileListByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useRefileListByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRefileListByIdQuery({
 *   variables: {
 *      refileListId: // value for 'refileListId'
 *   },
 * });
 */
export function useRefileListByIdQuery(
    baseOptions: Apollo.QueryHookOptions<RefileListByIdQuery, RefileListByIdQueryVariables>,
) {
    return Apollo.useQuery<RefileListByIdQuery, RefileListByIdQueryVariables>(
        RefileListByIdDocument,
        baseOptions,
    );
}
export function useRefileListByIdLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<RefileListByIdQuery, RefileListByIdQueryVariables>,
) {
    return Apollo.useLazyQuery<RefileListByIdQuery, RefileListByIdQueryVariables>(
        RefileListByIdDocument,
        baseOptions,
    );
}
export type RefileListByIdQueryHookResult = ReturnType<typeof useRefileListByIdQuery>;
export type RefileListByIdLazyQueryHookResult = ReturnType<typeof useRefileListByIdLazyQuery>;
export type RefileListByIdQueryResult = Apollo.QueryResult<
    RefileListByIdQuery,
    RefileListByIdQueryVariables
>;
export const RefilingProcessItemDocument = gql`
    mutation refilingProcessItem($data: RefilingProcessItemInput!) {
        refilingProcessItem(data: $data) {
            data {
                id
                status
            }
        }
    }
`;
export type RefilingProcessItemMutationFn = Apollo.MutationFunction<
    RefilingProcessItemMutation,
    RefilingProcessItemMutationVariables
>;

/**
 * __useRefilingProcessItemMutation__
 *
 * To run a mutation, you first call `useRefilingProcessItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefilingProcessItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refilingProcessItemMutation, { data, loading, error }] = useRefilingProcessItemMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRefilingProcessItemMutation(
    baseOptions?: Apollo.MutationHookOptions<
        RefilingProcessItemMutation,
        RefilingProcessItemMutationVariables
    >,
) {
    return Apollo.useMutation<RefilingProcessItemMutation, RefilingProcessItemMutationVariables>(
        RefilingProcessItemDocument,
        baseOptions,
    );
}
export type RefilingProcessItemMutationHookResult = ReturnType<
    typeof useRefilingProcessItemMutation
>;
export type RefilingProcessItemMutationResult = Apollo.MutationResult<RefilingProcessItemMutation>;
export type RefilingProcessItemMutationOptions = Apollo.BaseMutationOptions<
    RefilingProcessItemMutation,
    RefilingProcessItemMutationVariables
>;
export const RefilingLocationByBarcodeDocument = gql`
    query refilingLocationByBarcode($barcode: String!) {
        locationByBarcode(barcode: $barcode) {
            id
            ...RefilingProcessItemLocation
        }
    }
    ${RefilingProcessItemLocationFragmentDoc}
`;

/**
 * __useRefilingLocationByBarcodeQuery__
 *
 * To run a query within a React component, call `useRefilingLocationByBarcodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useRefilingLocationByBarcodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRefilingLocationByBarcodeQuery({
 *   variables: {
 *      barcode: // value for 'barcode'
 *   },
 * });
 */
export function useRefilingLocationByBarcodeQuery(
    baseOptions: Apollo.QueryHookOptions<
        RefilingLocationByBarcodeQuery,
        RefilingLocationByBarcodeQueryVariables
    >,
) {
    return Apollo.useQuery<RefilingLocationByBarcodeQuery, RefilingLocationByBarcodeQueryVariables>(
        RefilingLocationByBarcodeDocument,
        baseOptions,
    );
}
export function useRefilingLocationByBarcodeLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        RefilingLocationByBarcodeQuery,
        RefilingLocationByBarcodeQueryVariables
    >,
) {
    return Apollo.useLazyQuery<
        RefilingLocationByBarcodeQuery,
        RefilingLocationByBarcodeQueryVariables
    >(RefilingLocationByBarcodeDocument, baseOptions);
}
export type RefilingLocationByBarcodeQueryHookResult = ReturnType<
    typeof useRefilingLocationByBarcodeQuery
>;
export type RefilingLocationByBarcodeLazyQueryHookResult = ReturnType<
    typeof useRefilingLocationByBarcodeLazyQuery
>;
export type RefilingLocationByBarcodeQueryResult = Apollo.QueryResult<
    RefilingLocationByBarcodeQuery,
    RefilingLocationByBarcodeQueryVariables
>;
export const RefilingCompleteListDocument = gql`
    mutation refilingCompleteList($data: RefilingCompleteListInput!) {
        refilingCompleteList(data: $data) {
            data {
                id
                status
            }
        }
    }
`;
export type RefilingCompleteListMutationFn = Apollo.MutationFunction<
    RefilingCompleteListMutation,
    RefilingCompleteListMutationVariables
>;

/**
 * __useRefilingCompleteListMutation__
 *
 * To run a mutation, you first call `useRefilingCompleteListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefilingCompleteListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refilingCompleteListMutation, { data, loading, error }] = useRefilingCompleteListMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRefilingCompleteListMutation(
    baseOptions?: Apollo.MutationHookOptions<
        RefilingCompleteListMutation,
        RefilingCompleteListMutationVariables
    >,
) {
    return Apollo.useMutation<RefilingCompleteListMutation, RefilingCompleteListMutationVariables>(
        RefilingCompleteListDocument,
        baseOptions,
    );
}
export type RefilingCompleteListMutationHookResult = ReturnType<
    typeof useRefilingCompleteListMutation
>;
export type RefilingCompleteListMutationResult = Apollo.MutationResult<RefilingCompleteListMutation>;
export type RefilingCompleteListMutationOptions = Apollo.BaseMutationOptions<
    RefilingCompleteListMutation,
    RefilingCompleteListMutationVariables
>;
export const RoutingRouteDetailsEmployeesDocument = gql`
    query RoutingRouteDetailsEmployees {
        employees(filter: {}) {
            edges {
                node {
                    id
                    firstName
                    lastName
                }
            }
            pageInfo {
                cursor {
                    beforeCursor
                    afterCursor
                }
                hasNextPage
                hasPreviousPage
            }
        }
    }
`;

/**
 * __useRoutingRouteDetailsEmployeesQuery__
 *
 * To run a query within a React component, call `useRoutingRouteDetailsEmployeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoutingRouteDetailsEmployeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoutingRouteDetailsEmployeesQuery({
 *   variables: {
 *   },
 * });
 */
export function useRoutingRouteDetailsEmployeesQuery(
    baseOptions?: Apollo.QueryHookOptions<
        RoutingRouteDetailsEmployeesQuery,
        RoutingRouteDetailsEmployeesQueryVariables
    >,
) {
    return Apollo.useQuery<
        RoutingRouteDetailsEmployeesQuery,
        RoutingRouteDetailsEmployeesQueryVariables
    >(RoutingRouteDetailsEmployeesDocument, baseOptions);
}
export function useRoutingRouteDetailsEmployeesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        RoutingRouteDetailsEmployeesQuery,
        RoutingRouteDetailsEmployeesQueryVariables
    >,
) {
    return Apollo.useLazyQuery<
        RoutingRouteDetailsEmployeesQuery,
        RoutingRouteDetailsEmployeesQueryVariables
    >(RoutingRouteDetailsEmployeesDocument, baseOptions);
}
export type RoutingRouteDetailsEmployeesQueryHookResult = ReturnType<
    typeof useRoutingRouteDetailsEmployeesQuery
>;
export type RoutingRouteDetailsEmployeesLazyQueryHookResult = ReturnType<
    typeof useRoutingRouteDetailsEmployeesLazyQuery
>;
export type RoutingRouteDetailsEmployeesQueryResult = Apollo.QueryResult<
    RoutingRouteDetailsEmployeesQuery,
    RoutingRouteDetailsEmployeesQueryVariables
>;
export const RoutingRouteDetailsVehiclesDocument = gql`
    query RoutingRouteDetailsVehicles {
        vehicles(filter: {}) {
            edges {
                node {
                    id
                    description
                }
            }
            pageInfo {
                cursor {
                    beforeCursor
                    afterCursor
                }
                hasNextPage
                hasPreviousPage
            }
        }
    }
`;

/**
 * __useRoutingRouteDetailsVehiclesQuery__
 *
 * To run a query within a React component, call `useRoutingRouteDetailsVehiclesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoutingRouteDetailsVehiclesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoutingRouteDetailsVehiclesQuery({
 *   variables: {
 *   },
 * });
 */
export function useRoutingRouteDetailsVehiclesQuery(
    baseOptions?: Apollo.QueryHookOptions<
        RoutingRouteDetailsVehiclesQuery,
        RoutingRouteDetailsVehiclesQueryVariables
    >,
) {
    return Apollo.useQuery<
        RoutingRouteDetailsVehiclesQuery,
        RoutingRouteDetailsVehiclesQueryVariables
    >(RoutingRouteDetailsVehiclesDocument, baseOptions);
}
export function useRoutingRouteDetailsVehiclesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        RoutingRouteDetailsVehiclesQuery,
        RoutingRouteDetailsVehiclesQueryVariables
    >,
) {
    return Apollo.useLazyQuery<
        RoutingRouteDetailsVehiclesQuery,
        RoutingRouteDetailsVehiclesQueryVariables
    >(RoutingRouteDetailsVehiclesDocument, baseOptions);
}
export type RoutingRouteDetailsVehiclesQueryHookResult = ReturnType<
    typeof useRoutingRouteDetailsVehiclesQuery
>;
export type RoutingRouteDetailsVehiclesLazyQueryHookResult = ReturnType<
    typeof useRoutingRouteDetailsVehiclesLazyQuery
>;
export type RoutingRouteDetailsVehiclesQueryResult = Apollo.QueryResult<
    RoutingRouteDetailsVehiclesQuery,
    RoutingRouteDetailsVehiclesQueryVariables
>;
export const RoutingSetRouteEmployeesDocument = gql`
    mutation RoutingSetRouteEmployees($routeId: String!, $employeeIds: [String!]!) {
        routingSetEmployees(data: { routeId: $routeId, employeeIds: $employeeIds }) {
            route {
                id
                employees {
                    id
                }
            }
        }
    }
`;
export type RoutingSetRouteEmployeesMutationFn = Apollo.MutationFunction<
    RoutingSetRouteEmployeesMutation,
    RoutingSetRouteEmployeesMutationVariables
>;

/**
 * __useRoutingSetRouteEmployeesMutation__
 *
 * To run a mutation, you first call `useRoutingSetRouteEmployeesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRoutingSetRouteEmployeesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [routingSetRouteEmployeesMutation, { data, loading, error }] = useRoutingSetRouteEmployeesMutation({
 *   variables: {
 *      routeId: // value for 'routeId'
 *      employeeIds: // value for 'employeeIds'
 *   },
 * });
 */
export function useRoutingSetRouteEmployeesMutation(
    baseOptions?: Apollo.MutationHookOptions<
        RoutingSetRouteEmployeesMutation,
        RoutingSetRouteEmployeesMutationVariables
    >,
) {
    return Apollo.useMutation<
        RoutingSetRouteEmployeesMutation,
        RoutingSetRouteEmployeesMutationVariables
    >(RoutingSetRouteEmployeesDocument, baseOptions);
}
export type RoutingSetRouteEmployeesMutationHookResult = ReturnType<
    typeof useRoutingSetRouteEmployeesMutation
>;
export type RoutingSetRouteEmployeesMutationResult = Apollo.MutationResult<RoutingSetRouteEmployeesMutation>;
export type RoutingSetRouteEmployeesMutationOptions = Apollo.BaseMutationOptions<
    RoutingSetRouteEmployeesMutation,
    RoutingSetRouteEmployeesMutationVariables
>;
export const RoutingSetRouteVehicleDocument = gql`
    mutation RoutingSetRouteVehicle($routeId: String!, $vehicleId: String) {
        routingSetVehicle(data: { routeId: $routeId, vehicleId: $vehicleId }) {
            route {
                id
                vehicles {
                    id
                }
            }
        }
    }
`;
export type RoutingSetRouteVehicleMutationFn = Apollo.MutationFunction<
    RoutingSetRouteVehicleMutation,
    RoutingSetRouteVehicleMutationVariables
>;

/**
 * __useRoutingSetRouteVehicleMutation__
 *
 * To run a mutation, you first call `useRoutingSetRouteVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRoutingSetRouteVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [routingSetRouteVehicleMutation, { data, loading, error }] = useRoutingSetRouteVehicleMutation({
 *   variables: {
 *      routeId: // value for 'routeId'
 *      vehicleId: // value for 'vehicleId'
 *   },
 * });
 */
export function useRoutingSetRouteVehicleMutation(
    baseOptions?: Apollo.MutationHookOptions<
        RoutingSetRouteVehicleMutation,
        RoutingSetRouteVehicleMutationVariables
    >,
) {
    return Apollo.useMutation<
        RoutingSetRouteVehicleMutation,
        RoutingSetRouteVehicleMutationVariables
    >(RoutingSetRouteVehicleDocument, baseOptions);
}
export type RoutingSetRouteVehicleMutationHookResult = ReturnType<
    typeof useRoutingSetRouteVehicleMutation
>;
export type RoutingSetRouteVehicleMutationResult = Apollo.MutationResult<RoutingSetRouteVehicleMutation>;
export type RoutingSetRouteVehicleMutationOptions = Apollo.BaseMutationOptions<
    RoutingSetRouteVehicleMutation,
    RoutingSetRouteVehicleMutationVariables
>;
export const RoutingUpdateRouteStopTimeDocument = gql`
    mutation RoutingUpdateRouteStopTime($routeStopId: ID!, $startTime: DateTime!) {
        routingUpdateStop(data: { routeStopId: $routeStopId, startTime: $startTime }) {
            routeStop {
                id
                startTime
                visit {
                    ...RoutingVisitInformation
                }
            }
        }
    }
    ${RoutingVisitInformationFragmentDoc}
`;
export type RoutingUpdateRouteStopTimeMutationFn = Apollo.MutationFunction<
    RoutingUpdateRouteStopTimeMutation,
    RoutingUpdateRouteStopTimeMutationVariables
>;

/**
 * __useRoutingUpdateRouteStopTimeMutation__
 *
 * To run a mutation, you first call `useRoutingUpdateRouteStopTimeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRoutingUpdateRouteStopTimeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [routingUpdateRouteStopTimeMutation, { data, loading, error }] = useRoutingUpdateRouteStopTimeMutation({
 *   variables: {
 *      routeStopId: // value for 'routeStopId'
 *      startTime: // value for 'startTime'
 *   },
 * });
 */
export function useRoutingUpdateRouteStopTimeMutation(
    baseOptions?: Apollo.MutationHookOptions<
        RoutingUpdateRouteStopTimeMutation,
        RoutingUpdateRouteStopTimeMutationVariables
    >,
) {
    return Apollo.useMutation<
        RoutingUpdateRouteStopTimeMutation,
        RoutingUpdateRouteStopTimeMutationVariables
    >(RoutingUpdateRouteStopTimeDocument, baseOptions);
}
export type RoutingUpdateRouteStopTimeMutationHookResult = ReturnType<
    typeof useRoutingUpdateRouteStopTimeMutation
>;
export type RoutingUpdateRouteStopTimeMutationResult = Apollo.MutationResult<RoutingUpdateRouteStopTimeMutation>;
export type RoutingUpdateRouteStopTimeMutationOptions = Apollo.BaseMutationOptions<
    RoutingUpdateRouteStopTimeMutation,
    RoutingUpdateRouteStopTimeMutationVariables
>;
export const RoutingVisitsDocument = gql`
    query RoutingVisits($cursor: String, $startDate: String, $endDate: String) {
        visits(
            filter: {
                pageSize: 100
                after: $cursor
                conditions: [
                    { field: "startTime", operator: GreaterthanOrEqualto, value: $startDate }
                    { field: "startTime", operator: Lesserthanorequalto, value: $endDate }
                ]
            }
        ) {
            pageInfo {
                hasNextPage
                hasPreviousPage
                cursor {
                    afterCursor
                    beforeCursor
                }
            }
            edges {
                cursor
                node {
                    id
                    ...RoutingVisitInformation
                }
            }
        }
    }
    ${RoutingVisitInformationFragmentDoc}
`;

/**
 * __useRoutingVisitsQuery__
 *
 * To run a query within a React component, call `useRoutingVisitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoutingVisitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoutingVisitsQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useRoutingVisitsQuery(
    baseOptions?: Apollo.QueryHookOptions<RoutingVisitsQuery, RoutingVisitsQueryVariables>,
) {
    return Apollo.useQuery<RoutingVisitsQuery, RoutingVisitsQueryVariables>(
        RoutingVisitsDocument,
        baseOptions,
    );
}
export function useRoutingVisitsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<RoutingVisitsQuery, RoutingVisitsQueryVariables>,
) {
    return Apollo.useLazyQuery<RoutingVisitsQuery, RoutingVisitsQueryVariables>(
        RoutingVisitsDocument,
        baseOptions,
    );
}
export type RoutingVisitsQueryHookResult = ReturnType<typeof useRoutingVisitsQuery>;
export type RoutingVisitsLazyQueryHookResult = ReturnType<typeof useRoutingVisitsLazyQuery>;
export type RoutingVisitsQueryResult = Apollo.QueryResult<
    RoutingVisitsQuery,
    RoutingVisitsQueryVariables
>;
export const RoutingAreaInformationDocument = gql`
    query RoutingAreaInformation {
        areas {
            buildings {
                id
                ...RoutingBuildingInformation
            }
            territories {
                id
                ...RoutingTerritoryInformation
            }
        }
    }
    ${RoutingBuildingInformationFragmentDoc}
    ${RoutingTerritoryInformationFragmentDoc}
`;

/**
 * __useRoutingAreaInformationQuery__
 *
 * To run a query within a React component, call `useRoutingAreaInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoutingAreaInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoutingAreaInformationQuery({
 *   variables: {
 *   },
 * });
 */
export function useRoutingAreaInformationQuery(
    baseOptions?: Apollo.QueryHookOptions<
        RoutingAreaInformationQuery,
        RoutingAreaInformationQueryVariables
    >,
) {
    return Apollo.useQuery<RoutingAreaInformationQuery, RoutingAreaInformationQueryVariables>(
        RoutingAreaInformationDocument,
        baseOptions,
    );
}
export function useRoutingAreaInformationLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        RoutingAreaInformationQuery,
        RoutingAreaInformationQueryVariables
    >,
) {
    return Apollo.useLazyQuery<RoutingAreaInformationQuery, RoutingAreaInformationQueryVariables>(
        RoutingAreaInformationDocument,
        baseOptions,
    );
}
export type RoutingAreaInformationQueryHookResult = ReturnType<
    typeof useRoutingAreaInformationQuery
>;
export type RoutingAreaInformationLazyQueryHookResult = ReturnType<
    typeof useRoutingAreaInformationLazyQuery
>;
export type RoutingAreaInformationQueryResult = Apollo.QueryResult<
    RoutingAreaInformationQuery,
    RoutingAreaInformationQueryVariables
>;
export const RoutingDeliveryWindowsDocument = gql`
    query RoutingDeliveryWindows($date: DateTime!) {
        areas {
            deliveryWindowsByDate(date: $date) {
                id
                ...RoutingDeliveryWindowInformation
            }
        }
    }
    ${RoutingDeliveryWindowInformationFragmentDoc}
`;

/**
 * __useRoutingDeliveryWindowsQuery__
 *
 * To run a query within a React component, call `useRoutingDeliveryWindowsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoutingDeliveryWindowsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoutingDeliveryWindowsQuery({
 *   variables: {
 *      date: // value for 'date'
 *   },
 * });
 */
export function useRoutingDeliveryWindowsQuery(
    baseOptions: Apollo.QueryHookOptions<
        RoutingDeliveryWindowsQuery,
        RoutingDeliveryWindowsQueryVariables
    >,
) {
    return Apollo.useQuery<RoutingDeliveryWindowsQuery, RoutingDeliveryWindowsQueryVariables>(
        RoutingDeliveryWindowsDocument,
        baseOptions,
    );
}
export function useRoutingDeliveryWindowsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        RoutingDeliveryWindowsQuery,
        RoutingDeliveryWindowsQueryVariables
    >,
) {
    return Apollo.useLazyQuery<RoutingDeliveryWindowsQuery, RoutingDeliveryWindowsQueryVariables>(
        RoutingDeliveryWindowsDocument,
        baseOptions,
    );
}
export type RoutingDeliveryWindowsQueryHookResult = ReturnType<
    typeof useRoutingDeliveryWindowsQuery
>;
export type RoutingDeliveryWindowsLazyQueryHookResult = ReturnType<
    typeof useRoutingDeliveryWindowsLazyQuery
>;
export type RoutingDeliveryWindowsQueryResult = Apollo.QueryResult<
    RoutingDeliveryWindowsQuery,
    RoutingDeliveryWindowsQueryVariables
>;
export const RoutingRoutesDocument = gql`
    query RoutingRoutes($cursor: String, $startDate: String, $endDate: String) {
        routes(
            filter: {
                pageSize: 100
                after: $cursor
                conditions: [
                    { field: "operationDate", operator: GreaterthanOrEqualto, value: $startDate }
                    { field: "operationDate", operator: Lesserthanorequalto, value: $endDate }
                ]
            }
        ) {
            pageInfo {
                hasNextPage
                hasPreviousPage
                cursor {
                    afterCursor
                    beforeCursor
                }
            }
            edges {
                cursor
                node {
                    id
                    ...RoutingRouteInformation
                }
            }
        }
    }
    ${RoutingRouteInformationFragmentDoc}
`;

/**
 * __useRoutingRoutesQuery__
 *
 * To run a query within a React component, call `useRoutingRoutesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoutingRoutesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoutingRoutesQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useRoutingRoutesQuery(
    baseOptions?: Apollo.QueryHookOptions<RoutingRoutesQuery, RoutingRoutesQueryVariables>,
) {
    return Apollo.useQuery<RoutingRoutesQuery, RoutingRoutesQueryVariables>(
        RoutingRoutesDocument,
        baseOptions,
    );
}
export function useRoutingRoutesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<RoutingRoutesQuery, RoutingRoutesQueryVariables>,
) {
    return Apollo.useLazyQuery<RoutingRoutesQuery, RoutingRoutesQueryVariables>(
        RoutingRoutesDocument,
        baseOptions,
    );
}
export type RoutingRoutesQueryHookResult = ReturnType<typeof useRoutingRoutesQuery>;
export type RoutingRoutesLazyQueryHookResult = ReturnType<typeof useRoutingRoutesLazyQuery>;
export type RoutingRoutesQueryResult = Apollo.QueryResult<
    RoutingRoutesQuery,
    RoutingRoutesQueryVariables
>;
export const RoutingCreateRouteDocument = gql`
    mutation RoutingCreateRoute($date: DateTime!, $building: String!, $description: String) {
        routingCreateRoute(
            data: {
                operationDate: $date
                startBuildingId: $building
                endBuildingId: $building
                description: $description
            }
        ) {
            route {
                id
                description
                operationDate
            }
        }
    }
`;
export type RoutingCreateRouteMutationFn = Apollo.MutationFunction<
    RoutingCreateRouteMutation,
    RoutingCreateRouteMutationVariables
>;

/**
 * __useRoutingCreateRouteMutation__
 *
 * To run a mutation, you first call `useRoutingCreateRouteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRoutingCreateRouteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [routingCreateRouteMutation, { data, loading, error }] = useRoutingCreateRouteMutation({
 *   variables: {
 *      date: // value for 'date'
 *      building: // value for 'building'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useRoutingCreateRouteMutation(
    baseOptions?: Apollo.MutationHookOptions<
        RoutingCreateRouteMutation,
        RoutingCreateRouteMutationVariables
    >,
) {
    return Apollo.useMutation<RoutingCreateRouteMutation, RoutingCreateRouteMutationVariables>(
        RoutingCreateRouteDocument,
        baseOptions,
    );
}
export type RoutingCreateRouteMutationHookResult = ReturnType<typeof useRoutingCreateRouteMutation>;
export type RoutingCreateRouteMutationResult = Apollo.MutationResult<RoutingCreateRouteMutation>;
export type RoutingCreateRouteMutationOptions = Apollo.BaseMutationOptions<
    RoutingCreateRouteMutation,
    RoutingCreateRouteMutationVariables
>;
export const RoutingAddStopsToRouteDocument = gql`
    mutation RoutingAddStopsToRoute($routeId: ID!, $visits: [RoutingAddStopVisitInput!]!) {
        routingAddStops(data: { routeId: $routeId, visits: $visits }) {
            routeStops {
                id
                route {
                    id
                }
                startTime
                visit {
                    ...RoutingVisitInformation
                }
            }
        }
    }
    ${RoutingVisitInformationFragmentDoc}
`;
export type RoutingAddStopsToRouteMutationFn = Apollo.MutationFunction<
    RoutingAddStopsToRouteMutation,
    RoutingAddStopsToRouteMutationVariables
>;

/**
 * __useRoutingAddStopsToRouteMutation__
 *
 * To run a mutation, you first call `useRoutingAddStopsToRouteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRoutingAddStopsToRouteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [routingAddStopsToRouteMutation, { data, loading, error }] = useRoutingAddStopsToRouteMutation({
 *   variables: {
 *      routeId: // value for 'routeId'
 *      visits: // value for 'visits'
 *   },
 * });
 */
export function useRoutingAddStopsToRouteMutation(
    baseOptions?: Apollo.MutationHookOptions<
        RoutingAddStopsToRouteMutation,
        RoutingAddStopsToRouteMutationVariables
    >,
) {
    return Apollo.useMutation<
        RoutingAddStopsToRouteMutation,
        RoutingAddStopsToRouteMutationVariables
    >(RoutingAddStopsToRouteDocument, baseOptions);
}
export type RoutingAddStopsToRouteMutationHookResult = ReturnType<
    typeof useRoutingAddStopsToRouteMutation
>;
export type RoutingAddStopsToRouteMutationResult = Apollo.MutationResult<RoutingAddStopsToRouteMutation>;
export type RoutingAddStopsToRouteMutationOptions = Apollo.BaseMutationOptions<
    RoutingAddStopsToRouteMutation,
    RoutingAddStopsToRouteMutationVariables
>;
export const RoutingRemoveStopFromRouteDocument = gql`
    mutation RoutingRemoveStopFromRoute($routeStopId: ID!) {
        routingRemoveStop(data: { routeStopId: $routeStopId }) {
            visit {
                ...RoutingVisitInformation
            }
            route {
                stops {
                    id
                    route {
                        id
                    }
                    startTime
                    visit {
                        ...RoutingVisitInformation
                    }
                }
            }
        }
    }
    ${RoutingVisitInformationFragmentDoc}
`;
export type RoutingRemoveStopFromRouteMutationFn = Apollo.MutationFunction<
    RoutingRemoveStopFromRouteMutation,
    RoutingRemoveStopFromRouteMutationVariables
>;

/**
 * __useRoutingRemoveStopFromRouteMutation__
 *
 * To run a mutation, you first call `useRoutingRemoveStopFromRouteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRoutingRemoveStopFromRouteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [routingRemoveStopFromRouteMutation, { data, loading, error }] = useRoutingRemoveStopFromRouteMutation({
 *   variables: {
 *      routeStopId: // value for 'routeStopId'
 *   },
 * });
 */
export function useRoutingRemoveStopFromRouteMutation(
    baseOptions?: Apollo.MutationHookOptions<
        RoutingRemoveStopFromRouteMutation,
        RoutingRemoveStopFromRouteMutationVariables
    >,
) {
    return Apollo.useMutation<
        RoutingRemoveStopFromRouteMutation,
        RoutingRemoveStopFromRouteMutationVariables
    >(RoutingRemoveStopFromRouteDocument, baseOptions);
}
export type RoutingRemoveStopFromRouteMutationHookResult = ReturnType<
    typeof useRoutingRemoveStopFromRouteMutation
>;
export type RoutingRemoveStopFromRouteMutationResult = Apollo.MutationResult<RoutingRemoveStopFromRouteMutation>;
export type RoutingRemoveStopFromRouteMutationOptions = Apollo.BaseMutationOptions<
    RoutingRemoveStopFromRouteMutation,
    RoutingRemoveStopFromRouteMutationVariables
>;
export const LocationByBarcodeDocument = gql`
    query locationByBarcode($barcode: String!) {
        locationByBarcode(barcode: $barcode) {
            id
            ...SortingLocation
        }
    }
    ${SortingLocationFragmentDoc}
`;

/**
 * __useLocationByBarcodeQuery__
 *
 * To run a query within a React component, call `useLocationByBarcodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationByBarcodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationByBarcodeQuery({
 *   variables: {
 *      barcode: // value for 'barcode'
 *   },
 * });
 */
export function useLocationByBarcodeQuery(
    baseOptions: Apollo.QueryHookOptions<LocationByBarcodeQuery, LocationByBarcodeQueryVariables>,
) {
    return Apollo.useQuery<LocationByBarcodeQuery, LocationByBarcodeQueryVariables>(
        LocationByBarcodeDocument,
        baseOptions,
    );
}
export function useLocationByBarcodeLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        LocationByBarcodeQuery,
        LocationByBarcodeQueryVariables
    >,
) {
    return Apollo.useLazyQuery<LocationByBarcodeQuery, LocationByBarcodeQueryVariables>(
        LocationByBarcodeDocument,
        baseOptions,
    );
}
export type LocationByBarcodeQueryHookResult = ReturnType<typeof useLocationByBarcodeQuery>;
export type LocationByBarcodeLazyQueryHookResult = ReturnType<typeof useLocationByBarcodeLazyQuery>;
export type LocationByBarcodeQueryResult = Apollo.QueryResult<
    LocationByBarcodeQuery,
    LocationByBarcodeQueryVariables
>;
export const StorageItemByBarcodeDocument = gql`
    query storageItemByBarcode($barcode: String!) {
        storageItemByBarcode(barcode: $barcode) {
            id
            ...SortingStorageItem
        }
    }
    ${SortingStorageItemFragmentDoc}
`;

/**
 * __useStorageItemByBarcodeQuery__
 *
 * To run a query within a React component, call `useStorageItemByBarcodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useStorageItemByBarcodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStorageItemByBarcodeQuery({
 *   variables: {
 *      barcode: // value for 'barcode'
 *   },
 * });
 */
export function useStorageItemByBarcodeQuery(
    baseOptions: Apollo.QueryHookOptions<
        StorageItemByBarcodeQuery,
        StorageItemByBarcodeQueryVariables
    >,
) {
    return Apollo.useQuery<StorageItemByBarcodeQuery, StorageItemByBarcodeQueryVariables>(
        StorageItemByBarcodeDocument,
        baseOptions,
    );
}
export function useStorageItemByBarcodeLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        StorageItemByBarcodeQuery,
        StorageItemByBarcodeQueryVariables
    >,
) {
    return Apollo.useLazyQuery<StorageItemByBarcodeQuery, StorageItemByBarcodeQueryVariables>(
        StorageItemByBarcodeDocument,
        baseOptions,
    );
}
export type StorageItemByBarcodeQueryHookResult = ReturnType<typeof useStorageItemByBarcodeQuery>;
export type StorageItemByBarcodeLazyQueryHookResult = ReturnType<
    typeof useStorageItemByBarcodeLazyQuery
>;
export type StorageItemByBarcodeQueryResult = Apollo.QueryResult<
    StorageItemByBarcodeQuery,
    StorageItemByBarcodeQueryVariables
>;
export const PickingSortItemDocument = gql`
    mutation PickingSortItem($pickingSortItem: PickingSortItemInput!) {
        pickingSortItem(data: $pickingSortItem) {
            storageItem {
                id
            }
        }
    }
`;
export type PickingSortItemMutationFn = Apollo.MutationFunction<
    PickingSortItemMutation,
    PickingSortItemMutationVariables
>;

/**
 * __usePickingSortItemMutation__
 *
 * To run a mutation, you first call `usePickingSortItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePickingSortItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pickingSortItemMutation, { data, loading, error }] = usePickingSortItemMutation({
 *   variables: {
 *      pickingSortItem: // value for 'pickingSortItem'
 *   },
 * });
 */
export function usePickingSortItemMutation(
    baseOptions?: Apollo.MutationHookOptions<
        PickingSortItemMutation,
        PickingSortItemMutationVariables
    >,
) {
    return Apollo.useMutation<PickingSortItemMutation, PickingSortItemMutationVariables>(
        PickingSortItemDocument,
        baseOptions,
    );
}
export type PickingSortItemMutationHookResult = ReturnType<typeof usePickingSortItemMutation>;
export type PickingSortItemMutationResult = Apollo.MutationResult<PickingSortItemMutation>;
export type PickingSortItemMutationOptions = Apollo.BaseMutationOptions<
    PickingSortItemMutation,
    PickingSortItemMutationVariables
>;
export const TruckLoadingRequestsByVisitIdDocument = gql`
    query truckLoadingRequestsByVisitId($visitId: String!, $pageSize: Int!, $cursor: String!) {
        requestsByVisitId(visitId: $visitId, filter: { pageSize: $pageSize, after: $cursor }) {
            edges {
                cursor
                node {
                    id
                    status
                    requestType
                    ...TruckLoadingDeliverProcessDeliverStorageItemRequest
                    ...TruckLoadingDeliverProcessDeliverEmptyContainerRequest
                }
            }
            pageInfo {
                cursor {
                    afterCursor
                    beforeCursor
                }
                hasNextPage
                hasPreviousPage
            }
        }
    }
    ${TruckLoadingDeliverProcessDeliverStorageItemRequestFragmentDoc}
    ${TruckLoadingDeliverProcessDeliverEmptyContainerRequestFragmentDoc}
`;

/**
 * __useTruckLoadingRequestsByVisitIdQuery__
 *
 * To run a query within a React component, call `useTruckLoadingRequestsByVisitIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useTruckLoadingRequestsByVisitIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTruckLoadingRequestsByVisitIdQuery({
 *   variables: {
 *      visitId: // value for 'visitId'
 *      pageSize: // value for 'pageSize'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useTruckLoadingRequestsByVisitIdQuery(
    baseOptions: Apollo.QueryHookOptions<
        TruckLoadingRequestsByVisitIdQuery,
        TruckLoadingRequestsByVisitIdQueryVariables
    >,
) {
    return Apollo.useQuery<
        TruckLoadingRequestsByVisitIdQuery,
        TruckLoadingRequestsByVisitIdQueryVariables
    >(TruckLoadingRequestsByVisitIdDocument, baseOptions);
}
export function useTruckLoadingRequestsByVisitIdLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        TruckLoadingRequestsByVisitIdQuery,
        TruckLoadingRequestsByVisitIdQueryVariables
    >,
) {
    return Apollo.useLazyQuery<
        TruckLoadingRequestsByVisitIdQuery,
        TruckLoadingRequestsByVisitIdQueryVariables
    >(TruckLoadingRequestsByVisitIdDocument, baseOptions);
}
export type TruckLoadingRequestsByVisitIdQueryHookResult = ReturnType<
    typeof useTruckLoadingRequestsByVisitIdQuery
>;
export type TruckLoadingRequestsByVisitIdLazyQueryHookResult = ReturnType<
    typeof useTruckLoadingRequestsByVisitIdLazyQuery
>;
export type TruckLoadingRequestsByVisitIdQueryResult = Apollo.QueryResult<
    TruckLoadingRequestsByVisitIdQuery,
    TruckLoadingRequestsByVisitIdQueryVariables
>;
export const TruckLoadingProcessItemDocument = gql`
    mutation truckLoadingProcessItem($data: TruckLoadingProcessItemInput!) {
        truckLoadingProcessItem(data: $data) {
            data {
                id
                status
            }
        }
    }
`;
export type TruckLoadingProcessItemMutationFn = Apollo.MutationFunction<
    TruckLoadingProcessItemMutation,
    TruckLoadingProcessItemMutationVariables
>;

/**
 * __useTruckLoadingProcessItemMutation__
 *
 * To run a mutation, you first call `useTruckLoadingProcessItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTruckLoadingProcessItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [truckLoadingProcessItemMutation, { data, loading, error }] = useTruckLoadingProcessItemMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useTruckLoadingProcessItemMutation(
    baseOptions?: Apollo.MutationHookOptions<
        TruckLoadingProcessItemMutation,
        TruckLoadingProcessItemMutationVariables
    >,
) {
    return Apollo.useMutation<
        TruckLoadingProcessItemMutation,
        TruckLoadingProcessItemMutationVariables
    >(TruckLoadingProcessItemDocument, baseOptions);
}
export type TruckLoadingProcessItemMutationHookResult = ReturnType<
    typeof useTruckLoadingProcessItemMutation
>;
export type TruckLoadingProcessItemMutationResult = Apollo.MutationResult<TruckLoadingProcessItemMutation>;
export type TruckLoadingProcessItemMutationOptions = Apollo.BaseMutationOptions<
    TruckLoadingProcessItemMutation,
    TruckLoadingProcessItemMutationVariables
>;
export const TruckLoadingProcessEmptyContainersDocument = gql`
    mutation truckLoadingProcessEmptyContainers($data: TruckLoadingProcessEmptyContainersInput!) {
        truckLoadingProcessEmptyContainers(data: $data) {
            data {
                id
                status
            }
        }
    }
`;
export type TruckLoadingProcessEmptyContainersMutationFn = Apollo.MutationFunction<
    TruckLoadingProcessEmptyContainersMutation,
    TruckLoadingProcessEmptyContainersMutationVariables
>;

/**
 * __useTruckLoadingProcessEmptyContainersMutation__
 *
 * To run a mutation, you first call `useTruckLoadingProcessEmptyContainersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTruckLoadingProcessEmptyContainersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [truckLoadingProcessEmptyContainersMutation, { data, loading, error }] = useTruckLoadingProcessEmptyContainersMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useTruckLoadingProcessEmptyContainersMutation(
    baseOptions?: Apollo.MutationHookOptions<
        TruckLoadingProcessEmptyContainersMutation,
        TruckLoadingProcessEmptyContainersMutationVariables
    >,
) {
    return Apollo.useMutation<
        TruckLoadingProcessEmptyContainersMutation,
        TruckLoadingProcessEmptyContainersMutationVariables
    >(TruckLoadingProcessEmptyContainersDocument, baseOptions);
}
export type TruckLoadingProcessEmptyContainersMutationHookResult = ReturnType<
    typeof useTruckLoadingProcessEmptyContainersMutation
>;
export type TruckLoadingProcessEmptyContainersMutationResult = Apollo.MutationResult<TruckLoadingProcessEmptyContainersMutation>;
export type TruckLoadingProcessEmptyContainersMutationOptions = Apollo.BaseMutationOptions<
    TruckLoadingProcessEmptyContainersMutation,
    TruckLoadingProcessEmptyContainersMutationVariables
>;
export const TruckLoadingProcessConfirmRouteDocument = gql`
    mutation truckLoadingProcessConfirmRoute($data: TruckLoadingProcessConfirmRouteInput!) {
        truckLoadingProcessConfirmRoute(data: $data) {
            data {
                id
                status
            }
        }
    }
`;
export type TruckLoadingProcessConfirmRouteMutationFn = Apollo.MutationFunction<
    TruckLoadingProcessConfirmRouteMutation,
    TruckLoadingProcessConfirmRouteMutationVariables
>;

/**
 * __useTruckLoadingProcessConfirmRouteMutation__
 *
 * To run a mutation, you first call `useTruckLoadingProcessConfirmRouteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTruckLoadingProcessConfirmRouteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [truckLoadingProcessConfirmRouteMutation, { data, loading, error }] = useTruckLoadingProcessConfirmRouteMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useTruckLoadingProcessConfirmRouteMutation(
    baseOptions?: Apollo.MutationHookOptions<
        TruckLoadingProcessConfirmRouteMutation,
        TruckLoadingProcessConfirmRouteMutationVariables
    >,
) {
    return Apollo.useMutation<
        TruckLoadingProcessConfirmRouteMutation,
        TruckLoadingProcessConfirmRouteMutationVariables
    >(TruckLoadingProcessConfirmRouteDocument, baseOptions);
}
export type TruckLoadingProcessConfirmRouteMutationHookResult = ReturnType<
    typeof useTruckLoadingProcessConfirmRouteMutation
>;
export type TruckLoadingProcessConfirmRouteMutationResult = Apollo.MutationResult<TruckLoadingProcessConfirmRouteMutation>;
export type TruckLoadingProcessConfirmRouteMutationOptions = Apollo.BaseMutationOptions<
    TruckLoadingProcessConfirmRouteMutation,
    TruckLoadingProcessConfirmRouteMutationVariables
>;
export const TruckLoadingGetRouteDetailsDocument = gql`
    query truckLoadingGetRouteDetails($routeId: String!) {
        routes(filter: { conditions: [{ field: "id", value: $routeId, operator: Equal }] }) {
            edges {
                cursor
                node {
                    id
                    description
                    routeNumber
                    status
                    operationDate
                    employees {
                        id
                        firstName
                        lastName
                    }
                    stops {
                        ...truckLoadingRouteDetailRouteStop
                    }
                }
            }
            pageInfo {
                cursor {
                    beforeCursor
                    afterCursor
                }
                hasNextPage
                hasPreviousPage
            }
        }
    }
    ${TruckLoadingRouteDetailRouteStopFragmentDoc}
`;

/**
 * __useTruckLoadingGetRouteDetailsQuery__
 *
 * To run a query within a React component, call `useTruckLoadingGetRouteDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTruckLoadingGetRouteDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTruckLoadingGetRouteDetailsQuery({
 *   variables: {
 *      routeId: // value for 'routeId'
 *   },
 * });
 */
export function useTruckLoadingGetRouteDetailsQuery(
    baseOptions: Apollo.QueryHookOptions<
        TruckLoadingGetRouteDetailsQuery,
        TruckLoadingGetRouteDetailsQueryVariables
    >,
) {
    return Apollo.useQuery<
        TruckLoadingGetRouteDetailsQuery,
        TruckLoadingGetRouteDetailsQueryVariables
    >(TruckLoadingGetRouteDetailsDocument, baseOptions);
}
export function useTruckLoadingGetRouteDetailsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        TruckLoadingGetRouteDetailsQuery,
        TruckLoadingGetRouteDetailsQueryVariables
    >,
) {
    return Apollo.useLazyQuery<
        TruckLoadingGetRouteDetailsQuery,
        TruckLoadingGetRouteDetailsQueryVariables
    >(TruckLoadingGetRouteDetailsDocument, baseOptions);
}
export type TruckLoadingGetRouteDetailsQueryHookResult = ReturnType<
    typeof useTruckLoadingGetRouteDetailsQuery
>;
export type TruckLoadingGetRouteDetailsLazyQueryHookResult = ReturnType<
    typeof useTruckLoadingGetRouteDetailsLazyQuery
>;
export type TruckLoadingGetRouteDetailsQueryResult = Apollo.QueryResult<
    TruckLoadingGetRouteDetailsQuery,
    TruckLoadingGetRouteDetailsQueryVariables
>;
export const TruckLoadingGetRoutesDocument = gql`
    query truckLoadingGetRoutes(
        $startDate: String!
        $endDate: String!
        $pageSize: Int!
        $cursor: String!
    ) {
        routes(
            filter: {
                conditions: [
                    { field: "operationDate", value: $startDate, operator: GreaterthanOrEqualto }
                    { field: "operationDate", value: $endDate, operator: Lesserthanorequalto }
                ]
                pageSize: $pageSize
                after: $cursor
            }
        ) {
            edges {
                cursor
                node {
                    id
                    ...truckLoadingRoutesRoute
                }
            }
            pageInfo {
                cursor {
                    beforeCursor
                    afterCursor
                }
                hasNextPage
                hasPreviousPage
            }
        }
    }
    ${TruckLoadingRoutesRouteFragmentDoc}
`;

/**
 * __useTruckLoadingGetRoutesQuery__
 *
 * To run a query within a React component, call `useTruckLoadingGetRoutesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTruckLoadingGetRoutesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTruckLoadingGetRoutesQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      pageSize: // value for 'pageSize'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useTruckLoadingGetRoutesQuery(
    baseOptions: Apollo.QueryHookOptions<
        TruckLoadingGetRoutesQuery,
        TruckLoadingGetRoutesQueryVariables
    >,
) {
    return Apollo.useQuery<TruckLoadingGetRoutesQuery, TruckLoadingGetRoutesQueryVariables>(
        TruckLoadingGetRoutesDocument,
        baseOptions,
    );
}
export function useTruckLoadingGetRoutesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        TruckLoadingGetRoutesQuery,
        TruckLoadingGetRoutesQueryVariables
    >,
) {
    return Apollo.useLazyQuery<TruckLoadingGetRoutesQuery, TruckLoadingGetRoutesQueryVariables>(
        TruckLoadingGetRoutesDocument,
        baseOptions,
    );
}
export type TruckLoadingGetRoutesQueryHookResult = ReturnType<typeof useTruckLoadingGetRoutesQuery>;
export type TruckLoadingGetRoutesLazyQueryHookResult = ReturnType<
    typeof useTruckLoadingGetRoutesLazyQuery
>;
export type TruckLoadingGetRoutesQueryResult = Apollo.QueryResult<
    TruckLoadingGetRoutesQuery,
    TruckLoadingGetRoutesQueryVariables
>;
export const TruckUnloadingProcessItemGetRouteByVehicleDocument = gql`
    query truckUnloadingProcessItemGetRouteByVehicle(
        $vehicleId: String!
        $routeStatus: RouteStatus
        $fromDate: DateTime
        $toDate: DateTime
    ) {
        routeByVehicleId(
            vehicleId: $vehicleId
            routeStatus: $routeStatus
            fromDate: $fromDate
            toDate: $toDate
        ) {
            id
            ...TruckUnloadingProcessItemRoute
        }
    }
    ${TruckUnloadingProcessItemRouteFragmentDoc}
`;

/**
 * __useTruckUnloadingProcessItemGetRouteByVehicleQuery__
 *
 * To run a query within a React component, call `useTruckUnloadingProcessItemGetRouteByVehicleQuery` and pass it any options that fit your needs.
 * When your component renders, `useTruckUnloadingProcessItemGetRouteByVehicleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTruckUnloadingProcessItemGetRouteByVehicleQuery({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *      routeStatus: // value for 'routeStatus'
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *   },
 * });
 */
export function useTruckUnloadingProcessItemGetRouteByVehicleQuery(
    baseOptions: Apollo.QueryHookOptions<
        TruckUnloadingProcessItemGetRouteByVehicleQuery,
        TruckUnloadingProcessItemGetRouteByVehicleQueryVariables
    >,
) {
    return Apollo.useQuery<
        TruckUnloadingProcessItemGetRouteByVehicleQuery,
        TruckUnloadingProcessItemGetRouteByVehicleQueryVariables
    >(TruckUnloadingProcessItemGetRouteByVehicleDocument, baseOptions);
}
export function useTruckUnloadingProcessItemGetRouteByVehicleLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        TruckUnloadingProcessItemGetRouteByVehicleQuery,
        TruckUnloadingProcessItemGetRouteByVehicleQueryVariables
    >,
) {
    return Apollo.useLazyQuery<
        TruckUnloadingProcessItemGetRouteByVehicleQuery,
        TruckUnloadingProcessItemGetRouteByVehicleQueryVariables
    >(TruckUnloadingProcessItemGetRouteByVehicleDocument, baseOptions);
}
export type TruckUnloadingProcessItemGetRouteByVehicleQueryHookResult = ReturnType<
    typeof useTruckUnloadingProcessItemGetRouteByVehicleQuery
>;
export type TruckUnloadingProcessItemGetRouteByVehicleLazyQueryHookResult = ReturnType<
    typeof useTruckUnloadingProcessItemGetRouteByVehicleLazyQuery
>;
export type TruckUnloadingProcessItemGetRouteByVehicleQueryResult = Apollo.QueryResult<
    TruckUnloadingProcessItemGetRouteByVehicleQuery,
    TruckUnloadingProcessItemGetRouteByVehicleQueryVariables
>;
export const TruckUnloadingProcessItemDocument = gql`
    mutation truckUnloadingProcessItem($data: TruckUnloadingProcessItemInput!) {
        truckUnloadingProcessItem(data: $data) {
            data
        }
    }
`;
export type TruckUnloadingProcessItemMutationFn = Apollo.MutationFunction<
    TruckUnloadingProcessItemMutation,
    TruckUnloadingProcessItemMutationVariables
>;

/**
 * __useTruckUnloadingProcessItemMutation__
 *
 * To run a mutation, you first call `useTruckUnloadingProcessItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTruckUnloadingProcessItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [truckUnloadingProcessItemMutation, { data, loading, error }] = useTruckUnloadingProcessItemMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useTruckUnloadingProcessItemMutation(
    baseOptions?: Apollo.MutationHookOptions<
        TruckUnloadingProcessItemMutation,
        TruckUnloadingProcessItemMutationVariables
    >,
) {
    return Apollo.useMutation<
        TruckUnloadingProcessItemMutation,
        TruckUnloadingProcessItemMutationVariables
    >(TruckUnloadingProcessItemDocument, baseOptions);
}
export type TruckUnloadingProcessItemMutationHookResult = ReturnType<
    typeof useTruckUnloadingProcessItemMutation
>;
export type TruckUnloadingProcessItemMutationResult = Apollo.MutationResult<TruckUnloadingProcessItemMutation>;
export type TruckUnloadingProcessItemMutationOptions = Apollo.BaseMutationOptions<
    TruckUnloadingProcessItemMutation,
    TruckUnloadingProcessItemMutationVariables
>;
export const TruckUnloadingLocationByBarcodeDocument = gql`
    query TruckUnloadingLocationByBarcode($barcode: String!) {
        locationByBarcode(barcode: $barcode) {
            id
            ...TruckUnloadingLocation
        }
    }
    ${TruckUnloadingLocationFragmentDoc}
`;

/**
 * __useTruckUnloadingLocationByBarcodeQuery__
 *
 * To run a query within a React component, call `useTruckUnloadingLocationByBarcodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useTruckUnloadingLocationByBarcodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTruckUnloadingLocationByBarcodeQuery({
 *   variables: {
 *      barcode: // value for 'barcode'
 *   },
 * });
 */
export function useTruckUnloadingLocationByBarcodeQuery(
    baseOptions: Apollo.QueryHookOptions<
        TruckUnloadingLocationByBarcodeQuery,
        TruckUnloadingLocationByBarcodeQueryVariables
    >,
) {
    return Apollo.useQuery<
        TruckUnloadingLocationByBarcodeQuery,
        TruckUnloadingLocationByBarcodeQueryVariables
    >(TruckUnloadingLocationByBarcodeDocument, baseOptions);
}
export function useTruckUnloadingLocationByBarcodeLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        TruckUnloadingLocationByBarcodeQuery,
        TruckUnloadingLocationByBarcodeQueryVariables
    >,
) {
    return Apollo.useLazyQuery<
        TruckUnloadingLocationByBarcodeQuery,
        TruckUnloadingLocationByBarcodeQueryVariables
    >(TruckUnloadingLocationByBarcodeDocument, baseOptions);
}
export type TruckUnloadingLocationByBarcodeQueryHookResult = ReturnType<
    typeof useTruckUnloadingLocationByBarcodeQuery
>;
export type TruckUnloadingLocationByBarcodeLazyQueryHookResult = ReturnType<
    typeof useTruckUnloadingLocationByBarcodeLazyQuery
>;
export type TruckUnloadingLocationByBarcodeQueryResult = Apollo.QueryResult<
    TruckUnloadingLocationByBarcodeQuery,
    TruckUnloadingLocationByBarcodeQueryVariables
>;
export const TruckUnloadingGetVehiclesDocument = gql`
    query truckUnloadingGetVehicles {
        vehiclesByInProgressRoutes {
            id
            ...truckUnloadingVehiclesVehicle
        }
    }
    ${TruckUnloadingVehiclesVehicleFragmentDoc}
`;

/**
 * __useTruckUnloadingGetVehiclesQuery__
 *
 * To run a query within a React component, call `useTruckUnloadingGetVehiclesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTruckUnloadingGetVehiclesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTruckUnloadingGetVehiclesQuery({
 *   variables: {
 *   },
 * });
 */
export function useTruckUnloadingGetVehiclesQuery(
    baseOptions?: Apollo.QueryHookOptions<
        TruckUnloadingGetVehiclesQuery,
        TruckUnloadingGetVehiclesQueryVariables
    >,
) {
    return Apollo.useQuery<TruckUnloadingGetVehiclesQuery, TruckUnloadingGetVehiclesQueryVariables>(
        TruckUnloadingGetVehiclesDocument,
        baseOptions,
    );
}
export function useTruckUnloadingGetVehiclesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        TruckUnloadingGetVehiclesQuery,
        TruckUnloadingGetVehiclesQueryVariables
    >,
) {
    return Apollo.useLazyQuery<
        TruckUnloadingGetVehiclesQuery,
        TruckUnloadingGetVehiclesQueryVariables
    >(TruckUnloadingGetVehiclesDocument, baseOptions);
}
export type TruckUnloadingGetVehiclesQueryHookResult = ReturnType<
    typeof useTruckUnloadingGetVehiclesQuery
>;
export type TruckUnloadingGetVehiclesLazyQueryHookResult = ReturnType<
    typeof useTruckUnloadingGetVehiclesLazyQuery
>;
export type TruckUnloadingGetVehiclesQueryResult = Apollo.QueryResult<
    TruckUnloadingGetVehiclesQuery,
    TruckUnloadingGetVehiclesQueryVariables
>;
export const RetrievePendingVisitsDocument = gql`
    query RetrievePendingVisits($pageSize: Int!, $cursor: String!) {
        visits(filter: { pageSize: $pageSize, after: $cursor }) {
            pageInfo {
                cursor {
                    beforeCursor
                    afterCursor
                }
                hasNextPage
                hasPreviousPage
            }
            edges {
                cursor
                node {
                    id
                    ...VisitReviewMapVisit
                    status
                    startTime
                    endTime
                    expectedDuration
                    summary {
                        inComing
                        outGoing
                        multiplePeople
                        firstVisit
                    }
                    customerAddress {
                        id
                        address1
                        zoneId
                        city
                        zoneId
                        countryId
                        zip
                        longitude
                        latitude
                        phone
                        firstName
                        lastName
                        addressType
                        territory {
                            id
                            description
                        }
                    }
                    scheduleWindow {
                        id
                        isLocked
                        startTime
                        endTime
                    }
                }
            }
        }
    }
    ${VisitReviewMapVisitFragmentDoc}
`;

/**
 * __useRetrievePendingVisitsQuery__
 *
 * To run a query within a React component, call `useRetrievePendingVisitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetrievePendingVisitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetrievePendingVisitsQuery({
 *   variables: {
 *      pageSize: // value for 'pageSize'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useRetrievePendingVisitsQuery(
    baseOptions: Apollo.QueryHookOptions<
        RetrievePendingVisitsQuery,
        RetrievePendingVisitsQueryVariables
    >,
) {
    return Apollo.useQuery<RetrievePendingVisitsQuery, RetrievePendingVisitsQueryVariables>(
        RetrievePendingVisitsDocument,
        baseOptions,
    );
}
export function useRetrievePendingVisitsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        RetrievePendingVisitsQuery,
        RetrievePendingVisitsQueryVariables
    >,
) {
    return Apollo.useLazyQuery<RetrievePendingVisitsQuery, RetrievePendingVisitsQueryVariables>(
        RetrievePendingVisitsDocument,
        baseOptions,
    );
}
export type RetrievePendingVisitsQueryHookResult = ReturnType<typeof useRetrievePendingVisitsQuery>;
export type RetrievePendingVisitsLazyQueryHookResult = ReturnType<
    typeof useRetrievePendingVisitsLazyQuery
>;
export type RetrievePendingVisitsQueryResult = Apollo.QueryResult<
    RetrievePendingVisitsQuery,
    RetrievePendingVisitsQueryVariables
>;
export const RetrieveScheduleWindowsDocument = gql`
    query RetrieveScheduleWindows($zipCode: String!, $startDate: DateTime!, $endDate: DateTime!) {
        territoryByZipCode(zipCode: $zipCode) {
            id
            schedule(startDate: $startDate, endDate: $endDate, requestType: DELIVER_STORAGE_ITEM) {
                id
                ...VisitReviewVisitRequestScheduleWindow
            }
        }
    }
    ${VisitReviewVisitRequestScheduleWindowFragmentDoc}
`;

/**
 * __useRetrieveScheduleWindowsQuery__
 *
 * To run a query within a React component, call `useRetrieveScheduleWindowsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetrieveScheduleWindowsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetrieveScheduleWindowsQuery({
 *   variables: {
 *      zipCode: // value for 'zipCode'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useRetrieveScheduleWindowsQuery(
    baseOptions: Apollo.QueryHookOptions<
        RetrieveScheduleWindowsQuery,
        RetrieveScheduleWindowsQueryVariables
    >,
) {
    return Apollo.useQuery<RetrieveScheduleWindowsQuery, RetrieveScheduleWindowsQueryVariables>(
        RetrieveScheduleWindowsDocument,
        baseOptions,
    );
}
export function useRetrieveScheduleWindowsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        RetrieveScheduleWindowsQuery,
        RetrieveScheduleWindowsQueryVariables
    >,
) {
    return Apollo.useLazyQuery<RetrieveScheduleWindowsQuery, RetrieveScheduleWindowsQueryVariables>(
        RetrieveScheduleWindowsDocument,
        baseOptions,
    );
}
export type RetrieveScheduleWindowsQueryHookResult = ReturnType<
    typeof useRetrieveScheduleWindowsQuery
>;
export type RetrieveScheduleWindowsLazyQueryHookResult = ReturnType<
    typeof useRetrieveScheduleWindowsLazyQuery
>;
export type RetrieveScheduleWindowsQueryResult = Apollo.QueryResult<
    RetrieveScheduleWindowsQuery,
    RetrieveScheduleWindowsQueryVariables
>;
export const RetrieveRequestsByVisitIdDocument = gql`
    query RetrieveRequestsByVisitId($visitId: String!, $pageSize: Int!, $cursor: String!) {
        requestsByVisitId(visitId: $visitId, filter: { pageSize: $pageSize, after: $cursor }) {
            pageInfo {
                cursor {
                    beforeCursor
                    afterCursor
                }
                hasNextPage
                hasPreviousPage
            }
            edges {
                cursor
                node {
                    id
                    requestType
                    ...VisitReviewVisitRequestPickupStorageItemRequest
                    ...VisitReviewVisitRequestDeliverStorageItemRequest
                    ...VisitReviewVisitRequestDeliverEmptyContainerRequest
                    ...VisitReviewVisitRequestPickupEmptyContainerRequest
                    ...VisitReviewVisitRequestDriverPickupEmptyContainerRequest
                }
            }
        }
    }
    ${VisitReviewVisitRequestPickupStorageItemRequestFragmentDoc}
    ${VisitReviewVisitRequestDeliverStorageItemRequestFragmentDoc}
    ${VisitReviewVisitRequestDeliverEmptyContainerRequestFragmentDoc}
    ${VisitReviewVisitRequestPickupEmptyContainerRequestFragmentDoc}
    ${VisitReviewVisitRequestDriverPickupEmptyContainerRequestFragmentDoc}
`;

/**
 * __useRetrieveRequestsByVisitIdQuery__
 *
 * To run a query within a React component, call `useRetrieveRequestsByVisitIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetrieveRequestsByVisitIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetrieveRequestsByVisitIdQuery({
 *   variables: {
 *      visitId: // value for 'visitId'
 *      pageSize: // value for 'pageSize'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useRetrieveRequestsByVisitIdQuery(
    baseOptions: Apollo.QueryHookOptions<
        RetrieveRequestsByVisitIdQuery,
        RetrieveRequestsByVisitIdQueryVariables
    >,
) {
    return Apollo.useQuery<RetrieveRequestsByVisitIdQuery, RetrieveRequestsByVisitIdQueryVariables>(
        RetrieveRequestsByVisitIdDocument,
        baseOptions,
    );
}
export function useRetrieveRequestsByVisitIdLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        RetrieveRequestsByVisitIdQuery,
        RetrieveRequestsByVisitIdQueryVariables
    >,
) {
    return Apollo.useLazyQuery<
        RetrieveRequestsByVisitIdQuery,
        RetrieveRequestsByVisitIdQueryVariables
    >(RetrieveRequestsByVisitIdDocument, baseOptions);
}
export type RetrieveRequestsByVisitIdQueryHookResult = ReturnType<
    typeof useRetrieveRequestsByVisitIdQuery
>;
export type RetrieveRequestsByVisitIdLazyQueryHookResult = ReturnType<
    typeof useRetrieveRequestsByVisitIdLazyQuery
>;
export type RetrieveRequestsByVisitIdQueryResult = Apollo.QueryResult<
    RetrieveRequestsByVisitIdQuery,
    RetrieveRequestsByVisitIdQueryVariables
>;
export const ApproveVisitDocument = gql`
    mutation approveVisit($details: VisitReviewApproveVisitInput!) {
        visitReviewApproveVisit(data: $details) {
            visit {
                id
                ...VisitReviewMapVisit
                status
                startTime
                endTime
                expectedDuration
                summary {
                    inComing
                    outGoing
                    multiplePeople
                    firstVisit
                }
                customerAddress {
                    id
                    address1
                    zoneId
                    city
                    zoneId
                    countryId
                    zip
                    longitude
                    latitude
                    phone
                    firstName
                    lastName
                    addressType
                }
                scheduleWindow {
                    id
                    isLocked
                    startTime
                    endTime
                }
            }
        }
    }
    ${VisitReviewMapVisitFragmentDoc}
`;
export type ApproveVisitMutationFn = Apollo.MutationFunction<
    ApproveVisitMutation,
    ApproveVisitMutationVariables
>;

/**
 * __useApproveVisitMutation__
 *
 * To run a mutation, you first call `useApproveVisitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveVisitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveVisitMutation, { data, loading, error }] = useApproveVisitMutation({
 *   variables: {
 *      details: // value for 'details'
 *   },
 * });
 */
export function useApproveVisitMutation(
    baseOptions?: Apollo.MutationHookOptions<ApproveVisitMutation, ApproveVisitMutationVariables>,
) {
    return Apollo.useMutation<ApproveVisitMutation, ApproveVisitMutationVariables>(
        ApproveVisitDocument,
        baseOptions,
    );
}
export type ApproveVisitMutationHookResult = ReturnType<typeof useApproveVisitMutation>;
export type ApproveVisitMutationResult = Apollo.MutationResult<ApproveVisitMutation>;
export type ApproveVisitMutationOptions = Apollo.BaseMutationOptions<
    ApproveVisitMutation,
    ApproveVisitMutationVariables
>;
