import React, { useContext, useState } from 'react';
import { gql } from '@apollo/client';
import { AuthContext, SignOutFunction } from '../Auth/AuthFunctions';

import { View, Text, ScrollView } from 'react-native';
import { Button } from '@valet/ui-components';
import { useIntl } from 'react-intl';

import { useHomeQuery } from '../../../schema-types';

export const HOME_QUERY = gql`
    query home {
        serviceProvider {
            id
            name
            customerUserPool
            employeeUserPool
            cognitoRegion
            cognitoWebApplicationId
            theme
        }
    }
`;

type HomeProps = {};

export const Home: React.FC<HomeProps> = () => {
    const intl = useIntl();

    const { username, employee, getCurrentAccessToken } = useContext(AuthContext);
    const [accessToken, setAccessToken] = useState<string | undefined>(undefined);
    const [displayAccessToken, setDisplayAccessToken] = useState<boolean>(false);

    const handleGetAccessToken = async (): Promise<void> => {
        setDisplayAccessToken(!displayAccessToken);

        setAccessToken(undefined);
        getCurrentAccessToken().then((res) => setAccessToken(res));
    };

    const { data, error } = useHomeQuery();

    return (
        <ScrollView testID="data-homePage">
            <Text>Home</Text>
            <Text>Hello {username}, you're signed in</Text>
            <Text>employee Name {employee?.firstName}</Text>

            <View style={{ marginBottom: 10 }}>
                <Button
                    title={intl.formatMessage({
                        id: 'account.signOutButton',
                        defaultMessage: 'Click Here To Sign Out',
                    })}
                    onPress={() => SignOutFunction()}
                    appearance="outline"
                />
            </View>

            <Button
                title={
                    displayAccessToken ? 'Hide Current Access Token' : 'Show Current Access Token'
                }
                onPress={handleGetAccessToken}
            />

            {displayAccessToken && (
                <View style={{ marginVertical: 5, borderColor: '#d4d4d5', borderWidth: 1 }}>
                    <Text>{accessToken === undefined ? 'Loading' : accessToken}</Text>
                </View>
            )}

            {error && <Text testID="data-homeDataResult">{JSON.stringify(error)}</Text>}
            {data && <Text testID="data-homeDataResult">{JSON.stringify(data, undefined, 2)}</Text>}
        </ScrollView>
    );
};
