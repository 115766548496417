import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useHistory, useRouteMatch, useParams } from 'react-router-dom';
import { gql } from '@apollo/client';

import { View, Text, StyleSheet } from 'react-native';

import { PickListSummary, PICKLIST_SUMMARY_PICKLIST_ITEM_FRAGMENT } from './PickListSummary';
import { PickListProcess, PICKLIST_PROCESS_PICKLIST_ITEM_FRAGMENT } from './PickListProcess';
import { format, parseISO } from 'date-fns';
import { FormattedMessage } from 'react-intl';

import {
    useGetPickListByIdQuery,
    RequestStatus,
    usePickListCompleteListMutation,
} from '../../../../schema-types';

export const pickListItemsSize = 100;

export const RETRIEVE_SPECIFIC_PICKLIST_QUERY = gql`
    query getPickListById($pickListId: String!, $pageSize: Int!, $cursor: String!) {
        pickListById(id: $pickListId) {
            id
            pickListNumber
            description
            status
            startTime
            endTime
            created_at
            items(filter: { pageSize: $pageSize, after: $cursor }) {
                edges {
                    cursor
                    node {
                        id
                        ...PicklistProcessPicklistItem
                        ...PicklistSummaryPicklistItem
                    }
                }
                pageInfo {
                    cursor {
                        beforeCursor
                        afterCursor
                    }
                    hasNextPage
                    hasPreviousPage
                }
            }
            visitStartTime
            visitEndTime
            locations {
                from
                to
            }
            storageItemTypes {
                id
            }
            visits {
                id
            }
            maxQuantity
        }
    }

    ${PICKLIST_PROCESS_PICKLIST_ITEM_FRAGMENT}
    ${PICKLIST_SUMMARY_PICKLIST_ITEM_FRAGMENT}
`;

export const COMPLETE_PICKLIST_MUTATION = gql`
    mutation pickListCompleteList($pickListId: String!) {
        pickingCompleteList(data: { pickListId: $pickListId }) {
            pickList {
                id
                pickListNumber
                status
            }
        }
    }
`;

type PickListProps = {};

export const PickList: React.FC<PickListProps> = () => {
    const [picking, setPicking] = useState<boolean>(false);
    const [isFetching, setIsFetching] = useState<boolean>(false);

    const history = useHistory();
    const { path } = useRouteMatch();
    const match: { picklistid: string } = useParams();

    const handleGoBack = useCallback(() => {
        return history.push(`${path}/..`);
    }, [history, path]);

    useEffect(() => {
        if (!match.picklistid) {
            return handleGoBack();
        }
    }, [match, handleGoBack]);

    const pickListId = match.picklistid;

    const {
        data: retrievedPickListData,
        loading: retrievedPickListLoading,
        error: retrievedPickListError,
        refetch: retrievedPickListRefetch,
        fetchMore: retrievedPickListFetchMore,
    } = useGetPickListByIdQuery({
        variables: { pickListId: pickListId, pageSize: pickListItemsSize, cursor: '' },
    });

    const pickListItemsEdges = retrievedPickListData?.pickListById?.items?.edges ?? [];
    const pickListItemsData = useMemo(() => {
        return {
            pickListItems: pickListItemsEdges.map((itemEdge) => itemEdge.node),
            hasNextPage: retrievedPickListData?.pickListById?.items?.pageInfo.hasNextPage ?? false,
            endCursor:
                retrievedPickListData?.pickListById?.items?.pageInfo.cursor.afterCursor ?? '',
        };
    }, [pickListItemsEdges, retrievedPickListData]);

    const handleLoadMoreItems = useCallback((): void => {
        if (pickListItemsData.hasNextPage && !isFetching) {
            setIsFetching(true);

            retrievedPickListFetchMore({
                variables: {
                    pageSize: pickListItemsSize,
                    cursor: pickListItemsData.endCursor,
                },
            }).then(() => {
                setIsFetching(false);
            });
        }
    }, [isFetching, pickListItemsData, retrievedPickListFetchMore]);

    useEffect(() => {
        if (!retrievedPickListError && pickListItemsData.hasNextPage) {
            handleLoadMoreItems();
        }
    }, [handleLoadMoreItems, pickListItemsData, retrievedPickListError]);

    const [
        completePickList,
        { loading: completePickListLoading, error: completePickListError },
    ] = usePickListCompleteListMutation({
        errorPolicy: 'all',
    });

    useEffect(() => {
        if (picking === false) {
            retrievedPickListRefetch();
        }
    }, [picking, retrievedPickListRefetch]);

    const handleStartPicking = (): void => {
        setPicking(true);
    };

    const handleCompleteListPress = (): void => {
        completePickList({ variables: { pickListId: pickListId } }).then(() => {
            return history.push(`${path}/..`);
        });
    };

    const handleGoBackPress = (): void => {
        setPicking(false);
    };

    const handleDeleteListPress = (): void => {
        console.log('Pressed Delete List');
        return history.push(`${path}/..}`);
    };

    const pickListData = retrievedPickListData?.pickListById;

    const pickListItems = pickListItemsData.pickListItems;

    const filteredPickListItems = pickListItems.filter(
        (item) =>
            item.request?.status === RequestStatus.Created ||
            item.request?.status === RequestStatus.InPicklist ||
            item.request?.status === RequestStatus.PickingProcessed,
    );

    const filters = {
        description: retrievedPickListData?.pickListById?.description,
        locations: retrievedPickListData?.pickListById?.locations || undefined,
        maxQuantity: retrievedPickListData?.pickListById?.maxQuantity,
        storageItemTypeIds: retrievedPickListData?.pickListById?.storageItemTypes?.map(
            (storageItemType) => storageItemType.id,
        ),
        visitEndTime: retrievedPickListData?.pickListById?.visitEndTime
            ? format(parseISO(retrievedPickListData?.pickListById.visitEndTime), 'MM/dd/yyyy')
            : '',
        visitStartTime: retrievedPickListData?.pickListById?.visitStartTime
            ? format(
                  parseISO(retrievedPickListData?.pickListById.visitStartTime ?? ''),
                  'MM/dd/yyyy',
              )
            : '',
        visitIds: retrievedPickListData?.pickListById?.visits?.map((visit) => visit.id),
    };

    return (
        <View style={pickListStyles.ViewParent} testID="data-pickList">
            {(retrievedPickListLoading || completePickListLoading) && (
                <Text testID="data-pickListLoadingText">
                    <FormattedMessage id="pickList.loadingText" defaultMessage="Loading..." />
                </Text>
            )}

            {(retrievedPickListError || completePickListError) && (
                <Text testID="data-pickListErrorText">
                    <FormattedMessage
                        id="pickList.errorText"
                        defaultMessage="There was an error - please reload this page."
                    />
                </Text>
            )}

            {!retrievedPickListLoading &&
                !retrievedPickListData?.pickListById?.items?.pageInfo.hasNextPage &&
                retrievedPickListData && (
                    <View testID="data-pickListData">
                        {pickListData && picking && (
                            <PickListProcess
                                pickListItems={filteredPickListItems}
                                onGoBackPress={handleGoBackPress}
                            />
                        )}

                        {pickListData && !picking && (
                            <PickListSummary
                                onGoBack={handleGoBack}
                                id={pickListData?.id}
                                filters={filters}
                                pickListItems={pickListItems}
                                status={pickListData.status}
                                onProcessListPress={handleStartPicking}
                                onCompleteListPress={handleCompleteListPress}
                                onDeleteListPress={handleDeleteListPress}
                            />
                        )}

                        {!pickListData && (
                            <Text>
                                <FormattedMessage
                                    id="pickList.errorLoadingData"
                                    defaultMessage="Could not load data"
                                />
                            </Text>
                        )}
                    </View>
                )}
        </View>
    );
};

const pickListStyles = StyleSheet.create({
    ViewParent: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
});
