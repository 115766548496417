import { gql } from '@apollo/client';
import React, { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { FlatList, ScrollView, StyleSheet, Text, View } from 'react-native';
import { Card, Icon } from '@valet/ui-components';
import {
    useTruckUnloadingGetVehiclesQuery,
    TruckUnloadingVehiclesVehicleFragment,
} from '../../../schema-types';

export const vehiclesPageSize = 100;

const TRUCKUNLOADING_VEHICLE_FRAGMENT = gql`
    fragment truckUnloadingVehiclesVehicle on Vehicle {
        id
        description
    }
`;

export const RETRIEVE_VEHICLE_QUERY = gql`
    query truckUnloadingGetVehicles {
        vehiclesByInProgressRoutes {
            id
            ...truckUnloadingVehiclesVehicle
        }
    }

    ${TRUCKUNLOADING_VEHICLE_FRAGMENT}
`;

type TruckUnloadingVehiclesPageProps = {
    onTruckSelect: (vehicleId: string) => void;
};

export const TruckUnloadingVehiclesPage: React.FC<TruckUnloadingVehiclesPageProps> = ({
    onTruckSelect,
}) => {
    const {
        data: retrievedVehiclesData,
        loading: retrievedVehiclesLoading,
    } = useTruckUnloadingGetVehiclesQuery();

    const vehiclesData = useMemo(() => {
        return {
            vehicles: retrievedVehiclesData?.vehiclesByInProgressRoutes ?? [],
        };
    }, [retrievedVehiclesData]);

    const handleItemClick = useCallback(
        (id: string) => {
            onTruckSelect(id);
        },
        [onTruckSelect],
    );

    const data = useMemo<{
        trucks: TruckUnloadingVehiclesVehicleFragment[];
    }>(() => {
        const trucks = [];
        for (let i = 0; i < vehiclesData.vehicles.length; i++) {
            const item = vehiclesData.vehicles[i];
            trucks.push(item);
        }

        return {
            trucks,
        };
    }, [vehiclesData.vehicles]);

    return (
        <ScrollView>
            <View testID="data-truckUnloadingVehiclesDataView">
                <View
                    style={truckUnloadingVehiclesPageStyles.ViewParent}
                    testID="data-truckUnloading"
                >
                    {!retrievedVehiclesLoading && retrievedVehiclesData && (
                        <View style={truckUnloadingVehiclesPageStyles.ViewContent}>
                            {data.trucks.length > 0 ? (
                                <FlatList
                                    data={data.trucks}
                                    renderItem={({ item }) => (
                                        <VehicleItem
                                            key={item.id}
                                            vehicle={item}
                                            onItemClick={handleItemClick}
                                        />
                                    )}
                                    keyExtractor={(item) => item.id}
                                />
                            ) : (
                                <Text>
                                    <FormattedMessage
                                        id="truckUnloadingVehicles.noTruckText"
                                        defaultMessage="There are no trucks for unloading."
                                    />
                                </Text>
                            )}
                        </View>
                    )}
                </View>
            </View>
        </ScrollView>
    );
};

const truckUnloadingVehiclesPageStyles = StyleSheet.create({
    ViewParent: {
        display: 'flex',
        flexDirection: 'column',
    },
    ViewTabs: {
        marginVertical: 5,
        display: 'flex',
        flexDirection: 'row',
    },
    ViewContent: {
        paddingVertical: 5,
    },
    TextTab: {
        flex: 1,
        color: '#000',
        backgroundColor: '#D4D4D5',
        fontSize: 18,
        textAlign: 'center',
        fontWeight: '700',
    },
});

type VehicleItemProps = {
    vehicle: TruckUnloadingVehiclesVehicleFragment;
    onItemClick: (id: string) => void;
};

const VehicleItem: React.FC<VehicleItemProps> = ({ vehicle, onItemClick }) => {
    const { id, description } = vehicle;

    return (
        <Card
            style={vehicleItemStyles.ViewParent}
            onPress={() => onItemClick(id)}
            testID="data-truckUnloadingDetail"
            footer={(props) => (
                <View style={[props?.style]}>
                    <View style={vehicleItemStyles.ViewStatus}>
                        {/* <Text style={vehicleItemStyles.TextStatus}>{status}</Text> */}
                    </View>

                    <View style={vehicleItemStyles.ViewDate}>
                        <Text style={vehicleItemStyles.TextDate} testID="data-vehicleItemLocation">
                            {/* {new Date(operationDate).toLocaleString()} */}
                        </Text>
                    </View>
                </View>
            )}
        >
            <View style={vehicleItemStyles.ViewChild}>
                <View style={vehicleItemStyles.ViewImageParent}>
                    <Icon icon="menuTruckLoading" />
                </View>

                <View style={vehicleItemStyles.ViewContentParent}>
                    <View style={vehicleItemStyles.ViewTitleParent}>
                        <Text
                            style={vehicleItemStyles.TextTitle}
                            testID="data-vehicleItemDescription"
                        >
                            {description}
                        </Text>
                    </View>
                </View>
            </View>
        </Card>
    );
};

const vehicleItemStyles = StyleSheet.create({
    ViewParent: {
        marginVertical: 3,
    },
    ViewChild: {
        display: 'flex',
        flexDirection: 'row',
    },
    ViewImageParent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    VehicleImage: {
        height: 40,
        width: 40,
        resizeMode: 'stretch',
    },
    ViewContentParent: {
        paddingHorizontal: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    ViewTitleParent: {
        display: 'flex',
        flexDirection: 'column',
    },
    ViewTitleChild: {},
    TextTitle: {
        fontSize: 18,
    },
    ViewDescription: {},
    TextDescription: { fontSize: 14 },
    ViewStatus: {},
    TextStatus: {
        fontSize: 12,
        fontWeight: '700',
    },
    ViewDate: {},
    TextDate: {
        fontSize: 12,
    },
});
