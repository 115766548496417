import React, { useState, useCallback, useEffect, useRef } from 'react';
import { gql } from '@apollo/client';
import { addDays, format, set } from 'date-fns';

import { FormattedMessage, useIntl } from 'react-intl';
import { TextInput, RangeCalendar, Checkbox, Button } from '@valet/ui-components';
import { View, Text, FlatList, StyleSheet } from 'react-native';
import { FiltersInputType } from './CreatePickList';
import { parseISO } from 'date-fns';
import {
    PickListFiltersVisitFragment,
    PickListFiltersStorageItemTypeFragment,
} from '../../../../schema-types';

export const PICKLIST_FILTERS_VISIT_FRAGMENT = gql`
    fragment PickListFiltersVisit on Visit {
        id
    }
`;
export const PICKLIST_FILTERS_STORAGE_ITEM_TYPE_FRAGMENT = gql`
    fragment PickListFiltersStorageItemType on StorageItemType {
        id
        name
    }
`;

type FilterVisitDateRangeType = {
    startDate?: Date;
    endDate?: Date;
};

type FilterLocationRangeType =
    | {
          from: string;
          to: string;
      }
    | undefined;

type FilterStorageItemTypesType = string[];

type FilterMaximumQuantityType = number | undefined;

type FilterVisitsType = string[];

type PickListFiltersProps = {
    onFilterChange: (filters: FiltersInputType) => void;
    filtersInput: FiltersInputType;
    storageItemTypes: PickListFiltersStorageItemTypeFragment[];
    visits: PickListFiltersVisitFragment[];
};

export const PickListFilters: React.FC<PickListFiltersProps> = ({
    onFilterChange,
    filtersInput,
    storageItemTypes,
    visits,
}) => {
    const [description, setDescription] = useState<string | undefined>(filtersInput.description);
    const [dateRange, setDateRange] = useState<FilterVisitDateRangeType>({
        startDate: filtersInput.visitStartTime ? parseISO(filtersInput.visitStartTime) : undefined,
        endDate: filtersInput.visitEndTime ? parseISO(filtersInput.visitEndTime) : undefined,
    });
    const [locationRange, setLocationRange] = useState<FilterLocationRangeType>(
        filtersInput.locations
            ? {
                  from: filtersInput.locations[0].from,
                  to: filtersInput.locations[0].to,
              }
            : undefined,
    );
    const [storageItemTypesFilter, setStorageItemTypesFilter] = useState<string>('');
    const [
        selectedStorageItemTypes,
        setSelectedStorageItemTypes,
    ] = useState<FilterStorageItemTypesType>(filtersInput.storageItemTypeIds ?? []);
    const [maximumQuantity, setMaximumQuantity] = useState<FilterMaximumQuantityType>(
        filtersInput.maxQuantity,
    );
    const [visitsFilter, setVisitsFilter] = useState<string>('');
    const [selectedVisits, setSelectedVisits] = useState<FilterVisitsType>(
        filtersInput.visitIds ?? [],
    );

    const initialRender = useRef(true);

    useEffect(() => {
        if (initialRender.current) {
            initialRender.current = false;
        } else {
            onFilterChange({
                description: description,
                visitStartTime: set(dateRange.startDate ?? addDays(new Date(), 1), {
                    hours: 0,
                    minutes: 0,
                    seconds: 0,
                }).toISOString(),
                visitEndTime: dateRange.endDate
                    ? set(dateRange.endDate, { hours: 23, minutes: 59, seconds: 59 }).toISOString()
                    : undefined,
                locations: [
                    {
                        from: locationRange?.from ?? '',
                        to: locationRange?.to ?? '',
                    },
                ],
                storageItemTypeIds: selectedStorageItemTypes,
                maxQuantity: maximumQuantity,
                visitIds: selectedVisits,
            });
        }
    }, [
        onFilterChange,
        description,
        dateRange,
        locationRange,
        selectedStorageItemTypes,
        maximumQuantity,
        selectedVisits,
    ]);

    const handleDescriptionChange = useCallback((value: string) => {
        setDescription(value);
    }, []);

    const handleLocationRangeStartChange = useCallback(
        (value: string) => {
            setLocationRange({
                from: value,
                to: locationRange?.to ? locationRange.to : '',
            });
        },
        [locationRange],
    );

    const handleLocationRangeEndChange = useCallback(
        (value: string) => {
            setLocationRange({
                to: value,
                from: locationRange?.from ? locationRange.from : '',
            });
        },
        [locationRange],
    );

    const handleStorageItemTypesFilterChange = useCallback((value: string) => {
        setStorageItemTypesFilter(value);
    }, []);

    const handleStorageItemCheckboxChange = useCallback(
        (id: string) => {
            selectedStorageItemTypes.includes(id)
                ? setSelectedStorageItemTypes(
                      selectedStorageItemTypes.filter((item) => item !== id),
                  )
                : setSelectedStorageItemTypes([...selectedStorageItemTypes, id]);
        },
        [selectedStorageItemTypes],
    );

    const handleDeselectAllStorageItemTypesPress = useCallback(() => {
        if (selectedStorageItemTypes.length > 0) {
            setSelectedStorageItemTypes([]);
        }
    }, [selectedStorageItemTypes]);

    const handleMaximumQuantityChange = useCallback((value: string) => {
        if (value.match(/^\d+$/)) {
            setMaximumQuantity(parseInt(value));
        }
    }, []);

    const handleVisitFilterChange = useCallback((value: string) => {
        setVisitsFilter(value);
    }, []);

    const handleVisitCheckboxChange = useCallback(
        (id: string) => {
            selectedVisits.includes(id)
                ? setSelectedVisits(selectedVisits.filter((visit) => visit !== id))
                : setSelectedVisits([...selectedVisits, id]);
        },
        [selectedVisits],
    );

    const handleDeselectAllVisitsPress = useCallback(() => {
        if (selectedVisits.length > 0) {
            setSelectedVisits([]);
        }
    }, [selectedVisits]);

    const handleDateRangeChange = useCallback((range: CalendarRange) => {
        setDateRange(range);
    }, []);

    return (
        <View style={filterStyles.ViewFiltersParent} testID="data-createPickListFilters">
            <FilterDescription
                description={description || ''}
                onDescriptionChange={handleDescriptionChange}
            />

            <FilterDateRange range={dateRange} onRangeChange={handleDateRangeChange} />

            <FilterLocationRange
                locationRange={locationRange}
                onLocationRangeStartChange={handleLocationRangeStartChange}
                onLocationRangeEndChange={handleLocationRangeEndChange}
            />

            <FilterStorageItemTypes
                filterValue={storageItemTypesFilter}
                onFilterChange={handleStorageItemTypesFilterChange}
                storageItemTypes={storageItemTypes ?? []}
                selectedStorageItemTypes={selectedStorageItemTypes}
                onItemCheckboxChange={handleStorageItemCheckboxChange}
                onDeselectAllPress={handleDeselectAllStorageItemTypesPress}
            />

            <FilterMaximumQuantity
                maximumQuantity={maximumQuantity}
                onMaximumQuantityChange={handleMaximumQuantityChange}
            />

            <FilterVisits
                filterValue={visitsFilter}
                onFilterChange={handleVisitFilterChange}
                visits={visits ?? []}
                selectedVisits={selectedVisits}
                onVisitCheckboxChange={handleVisitCheckboxChange}
                onDeselectAllPress={handleDeselectAllVisitsPress}
            />
        </View>
    );
};

type FilterDescriptionProps = {
    description: string;
    onDescriptionChange: (value: string) => void;
};

const FilterDescription: React.FC<FilterDescriptionProps> = ({
    description,
    onDescriptionChange,
}) => {
    const intl = useIntl();

    return (
        <View style={filterStyles.ViewFiltersChild}>
            <Text style={filterStyles.TextFilterHeader}>
                <FormattedMessage
                    id="pickListFilters.descriptionRange"
                    defaultMessage="Description"
                />
            </Text>
            <TextInput
                onChange={onDescriptionChange}
                type="text"
                value={description}
                ariaLabel={intl.formatMessage({
                    id: 'pickListFilters.descriptionPlaceholder',
                    defaultMessage: 'Name of pick list, description, etc.',
                })}
                placeholder={intl.formatMessage({
                    id: 'pickListFilters.descriptionPlaceholder',
                    defaultMessage: 'Name of pick list, description, etc.',
                })}
                testID="data-createPickListFilterDescription"
            />
        </View>
    );
};

type CalendarRange = {
    startDate?: Date;
    endDate?: Date;
};

type FilterDateRangeProps = {
    range: CalendarRange;
    onRangeChange: (range: CalendarRange) => void;
};

const FilterDateRange: React.FC<FilterDateRangeProps> = ({ range, onRangeChange }) => {
    const intl = useIntl();

    return (
        <View style={filterStyles.ViewFiltersChild}>
            <Text style={filterStyles.TextFilterHeader}>
                <FormattedMessage
                    id="pickListFilters.visitDateRangeHeader"
                    defaultMessage="Visit date range"
                />
            </Text>

            <View style={filterStyles.ViewRangeGroup}>
                <View
                    style={[
                        filterStyles.ViewRangeItem,
                        {
                            marginRight: 2,
                        },
                    ]}
                >
                    <Text>
                        <FormattedMessage
                            id="pickListFilters.visitDateRangeFromLabel"
                            defaultMessage="From"
                        />
                    </Text>

                    <Text>
                        <FormattedMessage
                            id="pickListFilters.visitDateRangeFromValue"
                            defaultMessage="{startDate}"
                            values={{
                                startDate: range.startDate
                                    ? intl.formatDate(format(range.startDate, 'MM/dd/yyyy'))
                                    : 'Select a date',
                            }}
                        />
                    </Text>

                    <Text>
                        <FormattedMessage
                            id="pickListFilters.visitDateRangeToLabel"
                            defaultMessage="To"
                        />
                    </Text>

                    <Text>
                        <FormattedMessage
                            id="pickListFilters.visitDateRangeToValue"
                            defaultMessage="{endDate}"
                            values={{
                                endDate: range.endDate
                                    ? intl.formatDate(format(range.endDate, 'MM/dd/yyyy'))
                                    : 'Select a date',
                            }}
                        />
                    </Text>

                    <View testID="data-createPickListFilterVisitDate">
                        <RangeCalendar range={range} onSelect={onRangeChange} />
                    </View>
                </View>
            </View>
        </View>
    );
};

type FilterLocationRangeProps = {
    locationRange:
        | {
              from: string;
              to: string;
          }
        | undefined;
    onLocationRangeStartChange: (value: string) => void;
    onLocationRangeEndChange: (value: string) => void;
};

const FilterLocationRange: React.FC<FilterLocationRangeProps> = ({
    locationRange,
    onLocationRangeStartChange,
    onLocationRangeEndChange,
}) => {
    const intl = useIntl();

    return (
        <View style={filterStyles.ViewFiltersChild}>
            <Text style={filterStyles.TextFilterHeader}>
                <FormattedMessage
                    id="pickListFilters.locationRangeHeader"
                    defaultMessage="Location range"
                />
            </Text>

            <View style={filterStyles.ViewRangeGroup}>
                <View
                    style={[
                        filterStyles.ViewRangeItem,
                        {
                            marginRight: 2,
                        },
                    ]}
                >
                    <Text>
                        <FormattedMessage
                            id="pickListFilters.locationRangeStartLabel"
                            defaultMessage="Start"
                        />
                    </Text>
                    <TextInput
                        onChange={onLocationRangeStartChange}
                        type="text"
                        value={locationRange?.from ?? ''}
                        ariaLabel={intl.formatMessage({
                            id: 'pickListFilters.locationStartPlaceholder',
                            defaultMessage: 'L5 - A01',
                        })}
                        placeholder={intl.formatMessage({
                            id: 'pickListFilters.locationStartPlaceholder',
                            defaultMessage: 'L5 - A01',
                        })}
                        testID="data-createPickListFilterStartLocation"
                    />
                </View>

                <View
                    style={[
                        filterStyles.ViewRangeItem,
                        {
                            marginLeft: 2,
                        },
                    ]}
                >
                    <Text>
                        <FormattedMessage
                            id="pickListFilters.locationRangeEndLabel"
                            defaultMessage="End"
                        />
                    </Text>
                    <TextInput
                        onChange={onLocationRangeEndChange}
                        type="text"
                        value={locationRange?.to ?? ''}
                        ariaLabel={intl.formatMessage({
                            id: 'pickListFilters.locationEndPlaceholder',
                            defaultMessage: 'L5 - D01',
                        })}
                        placeholder={intl.formatMessage({
                            id: 'pickListFilters.locationEndPlaceholder',
                            defaultMessage: 'L5 - D01',
                        })}
                        testID="data-createPickListFilterEndLocation"
                    />
                </View>
            </View>
        </View>
    );
};

type FilterStorageItemTypesProps = {
    filterValue: string;
    onFilterChange: (value: string) => void;
    storageItemTypes: PickListFiltersStorageItemTypeFragment[];
    onItemCheckboxChange: (id: string) => void;
    selectedStorageItemTypes: string[];
    onDeselectAllPress: () => void;
};

const FilterStorageItemTypes: React.FC<FilterStorageItemTypesProps> = ({
    filterValue,
    onFilterChange,
    storageItemTypes,
    onItemCheckboxChange,
    selectedStorageItemTypes,
    onDeselectAllPress,
}) => {
    const intl = useIntl();

    return (
        <View style={filterStyles.ViewFiltersChild}>
            <Text style={filterStyles.TextFilterHeader}>
                <FormattedMessage
                    id="pickListFilters.storageItemTypesHeader"
                    defaultMessage="Storage item types"
                />
            </Text>

            <View style={filterStyles.ViewContainer}>
                <TextInput
                    onChange={onFilterChange}
                    type="text"
                    value={filterValue}
                    ariaLabel={intl.formatMessage({
                        id: 'pickListFilters.searchForItem',
                        defaultMessage: 'Search for item...',
                    })}
                    placeholder={intl.formatMessage({
                        id: 'pickListFilters.searchForItem',
                        defaultMessage: 'Search for item...',
                    })}
                    testID="data-createPickListFilterStorageItemFilter"
                />

                <View style={{ paddingVertical: 5, display: 'flex', flexDirection: 'row' }}>
                    <Button
                        title={intl.formatMessage({
                            id: 'pickListFilters.storageItemTypesDeselectAllLabel',
                            defaultMessage: 'Deselect all',
                        })}
                        onPress={onDeselectAllPress}
                        testID="data-createPickListFilterStorageItemFilterDeselect"
                        appearance="ghost"
                    />
                </View>

                <FlatList
                    style={{ maxHeight: 200 }}
                    data={storageItemTypes.filter((storageItemType) =>
                        storageItemType.name.toUpperCase().includes(filterValue.toUpperCase()),
                    )}
                    renderItem={({ item: storageItem }) => (
                        <View
                            style={filterStyles.TextContainerItem}
                            key={storageItem.id}
                            testID="data-createPickListFilterStorageItem"
                        >
                            <Text
                                onPress={() => onItemCheckboxChange(storageItem.id)}
                                testID="data-createPickListFilterStorageItemText"
                            >
                                <View style={{ marginRight: 5 }}>
                                    <Checkbox
                                        onValueChange={() => onItemCheckboxChange(storageItem.id)}
                                        checked={selectedStorageItemTypes.includes(storageItem.id)}
                                        testID={
                                            selectedStorageItemTypes.includes(storageItem.id)
                                                ? 'data-createPickListFilterStorageItemChecked'
                                                : 'data-createPickListFilterStorageItemUnchecked'
                                        }
                                    />
                                </View>

                                <Text testID="data-createPickListFilterStorageItemName">
                                    <FormattedMessage
                                        id="pickListFilters.storageItemTypesItem"
                                        defaultMessage="{storageItemName}"
                                        values={{
                                            storageItemName: storageItem.name,
                                        }}
                                    />
                                </Text>
                            </Text>
                        </View>
                    )}
                />
            </View>
        </View>
    );
};

type FilterMaximumQuantityProps = {
    maximumQuantity: number | undefined;
    onMaximumQuantityChange: (value: string) => void;
};

const FilterMaximumQuantity: React.FC<FilterMaximumQuantityProps> = ({
    maximumQuantity,
    onMaximumQuantityChange,
}) => {
    const intl = useIntl();

    return (
        <View style={filterStyles.ViewFiltersChild}>
            <Text style={filterStyles.TextFilterHeader}>
                <FormattedMessage
                    id="pickListFilters.maxQuantityItemsHeader"
                    defaultMessage="Maximum quantity of items"
                />
            </Text>

            {/* TODO: UPDATE TO USE NUMBER INPUT */}
            <TextInput
                onChange={onMaximumQuantityChange}
                type="text"
                value={maximumQuantity ?? ''}
                ariaLabel={intl.formatMessage({
                    id: 'pickListFilters.maxQuantityPlaceholder',
                    defaultMessage: '100',
                })}
                placeholder={intl.formatMessage({
                    id: 'pickListFilters.maxQuantityPlaceholder',
                    defaultMessage: '100',
                })}
                testID="data-createPickListFilterMaxQuantity"
            />
        </View>
    );
};

type FilterVisitsProps = {
    filterValue: string;
    onFilterChange: (value: string) => void;
    visits: PickListFiltersVisitFragment[];
    onVisitCheckboxChange: (id: string) => void;
    selectedVisits: string[];
    onDeselectAllPress: () => void;
};

const FilterVisits: React.FC<FilterVisitsProps> = ({
    filterValue,
    onFilterChange,
    visits,
    onVisitCheckboxChange,
    selectedVisits,
    onDeselectAllPress,
}) => {
    const intl = useIntl();

    return (
        <View style={filterStyles.ViewFiltersChild}>
            <Text style={filterStyles.TextFilterHeader}>
                <FormattedMessage id="pickListFilters.visitsHeader" defaultMessage="Visits" />
            </Text>

            <View style={filterStyles.ViewContainer}>
                <TextInput
                    onChange={onFilterChange}
                    type="text"
                    value={filterValue}
                    ariaLabel={intl.formatMessage({
                        id: 'pickListFilters.searchForVisit',
                        defaultMessage: 'Search for visit...',
                    })}
                    placeholder={intl.formatMessage({
                        id: 'pickListFilters.searchForVisit',
                        defaultMessage: 'Search for visit...',
                    })}
                    testID="data-createPickListFilterVisitFilter"
                />

                <View style={{ paddingVertical: 5, display: 'flex', flexDirection: 'row' }}>
                    <Button
                        title={intl.formatMessage({
                            id: 'pickListFilters.visitsDeselectAllLabel',
                            defaultMessage: 'Deselect all',
                        })}
                        onPress={onDeselectAllPress}
                        testID="data-createPickListFilterVisitFilterDeselect"
                        appearance="ghost"
                    />
                </View>

                <FlatList
                    style={{ maxHeight: 200 }}
                    data={visits.filter((visit) =>
                        visit.id.toUpperCase().includes(filterValue.toUpperCase()),
                    )}
                    renderItem={({ item: visit }) => (
                        <View style={filterStyles.TextContainerItem} key={visit.id}>
                            <Text
                                onPress={() => onVisitCheckboxChange(visit.id)}
                                testID="data-createPickListFilterVisitText"
                            >
                                <View style={{ marginRight: 5 }}>
                                    <Checkbox
                                        onValueChange={() => onVisitCheckboxChange(visit.id)}
                                        checked={selectedVisits.includes(visit.id)}
                                        testID={
                                            selectedVisits.includes(visit.id)
                                                ? 'data-createPickListFilterVisitChecked'
                                                : 'data-createPickListFilterVisitUnchecked'
                                        }
                                    />
                                </View>

                                <Text testID="data-createPickListFilterVisitName">
                                    <FormattedMessage
                                        id="pickListFilters.visitId"
                                        defaultMessage="Visit {visitId}"
                                        values={{
                                            visitId: visit.id,
                                        }}
                                    />
                                </Text>
                            </Text>
                        </View>
                    )}
                />
            </View>
        </View>
    );
};

const filterStyles = StyleSheet.create({
    ViewFiltersParent: {
        display: 'flex',
        flexDirection: 'column',
    },
    ViewFiltersChild: {
        marginVertical: 5,
        display: 'flex',
        flexDirection: 'column',
    },
    ViewRangeGroup: {
        display: 'flex',
        flexDirection: 'row',
    },
    ViewRangePicker: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
    },
    ViewRangeItem: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
    },
    ViewContainer: {
        padding: 5,
        borderWidth: 1,
        borderColor: '#D4D4D5',
    },
    TextContainerItem: {
        marginVertical: 2,
        display: 'flex',
        flexDirection: 'row',
    },
    TextFilterHeader: {
        fontWeight: '700',
        marginVertical: 5,
    },
});
