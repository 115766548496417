import React from 'react';

type UploadFileProps = {
    onFileUploaded: (file: any) => void;
};

export const UploadFile: React.FC<UploadFileProps> = ({ onFileUploaded }) => {
    const onChange = ({
        target: {
            validity,
            files: [file],
        },
    }: any) => validity.valid && onFileUploaded(file);

    return (
        <React.Fragment>
            <input type="file" required onChange={onChange} />
        </React.Fragment>
    );
};
