import React from 'react';
import { Platform } from 'react-native';
import { useDrag } from 'react-dnd';
import { FormattedMessage, useIntl } from 'react-intl';
import { View, Text, StyleSheet } from 'react-native';
import { VisitsSummaryData } from './Routing';
import { VisitsSummaryIcons } from './VisitsSummaryIcons';
import { Button, Card } from '@valet/ui-components';

type SelectedVisitsListItemProps = {
    selectedVisitsSummary: VisitsSummaryData;
    isSelected?: boolean;
    onSelected?: () => void;
    onUnselected?: () => void;
    onAddToRoute?: (routeId?: string) => void;
};

const SelectedVisitsListItemContent: React.FC<SelectedVisitsListItemProps> = ({
    selectedVisitsSummary,
    isSelected = false,
    onSelected = () => undefined,
    onUnselected = () => undefined,
    onAddToRoute = () => undefined,
}) => {
    const intl = useIntl();

    return (
        <View style={menuListStyles.ViewListItemContainer}>
            <Card
                style={{ flex: 1 }}
                disabled
                footer={() => (
                    <View style={menuListStyles.ViewListItemActions}>
                        <Button
                            title={intl.formatMessage({
                                id: 'routing.addAllVisitsToRoute',
                                defaultMessage: 'Add all visits to route',
                            })}
                            onPress={() => onAddToRoute()}
                            size="tiny"
                        />

                        <View style={{ flex: 1, marginLeft: 2 }}>
                            {isSelected ? (
                                <Button
                                    title={intl.formatMessage({
                                        id: 'routing.hideVisits',
                                        defaultMessage: 'Hide visits',
                                    })}
                                    onPress={onUnselected}
                                    size="tiny"
                                />
                            ) : (
                                <Button
                                    title={intl.formatMessage({
                                        id: 'routing.showVisits',
                                        defaultMessage: 'Show visits',
                                    })}
                                    onPress={onSelected}
                                    size="tiny"
                                />
                            )}
                        </View>
                    </View>
                )}
            >
                <View style={menuListStyles.ViewListItemBody}>
                    <View
                        style={{
                            paddingVertical: 2,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}
                    >
                        <Text style={{ fontSize: 16 }}>
                            <FormattedMessage
                                id="routing.selectedItems"
                                defaultMessage="Selected Items"
                            />
                        </Text>
                    </View>
                    <View style={{ paddingVertical: 2 }}>
                        <VisitsSummaryIcons {...selectedVisitsSummary} />
                    </View>
                </View>
            </Card>
        </View>
    );
};

export const SelectedVisitsListItem: React.FC<SelectedVisitsListItemProps> = (props) => {
    const [, drag] = useDrag({ item: { type: 'selected' } });

    if (Platform.OS === 'web') {
        return (
            <div ref={drag}>
                <SelectedVisitsListItemContent {...props} />
            </div>
        );
    }

    return <SelectedVisitsListItemContent {...props} />;
};

const menuListStyles = StyleSheet.create({
    ViewListItemContainer: {
        flexDirection: 'row',
        paddingVertical: 4,
        paddingRight: 4,
    },
    ViewListItemBody: {
        flex: 1,
        flexDirection: 'column',
    },
    ViewListItemActions: {
        padding: 5,
        flexDirection: 'row',
        justifyContent: 'center',
        flex: 1,
        display: 'flex',
    },
});
