import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { gql } from '@apollo/client';
import { useDebouncedCallback } from 'use-debounce';

import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '@valet/ui-components';
import { View, Text, StyleSheet } from 'react-native';
import {
    PickListFilters,
    PICKLIST_FILTERS_STORAGE_ITEM_TYPE_FRAGMENT,
    PICKLIST_FILTERS_VISIT_FRAGMENT,
} from './PickListFilters';
import { addDays, format, parseISO, set } from 'date-fns';
import {
    useGetPickListSummaryQuery,
    useGetVisitsQuery,
    Operators,
    WhereCondition,
    useGetStorageItemTypesQuery,
    useCreatePickListMutation,
} from '../../../../schema-types';

// Picklist Summary Typings

type RetrievedPickListSummaryDataItem = {
    storageItemType: {
        id: string;
        name: string;
    };
    listTotalItems: number;
    warehouseTotalItems: number;
};

type RetrievePickListSummaryVariableFilterInputType = {
    visitStartTime?: string;
    visitEndTime?: string;
    locations?: { from: string; to: string }[];
    storageItemTypeIds?: string[];
    maxQuantity?: number;
    visitIds?: string[];
};

// Create Picklist Typings

type CreatePickListVariableFilterInputType = {
    visitStartTime?: string;
    visitEndTime?: string;
    locations?: { from: string; to: string }[];
    storageItemTypeIds?: string[];
    maxQuantity?: number;
    visitIds?: string[];
};

export const RETRIEVE_PICKLIST_SUMMARY_QUERY = gql`
    query getPickListSummary($filterInput: PickingListSummaryFilterInput!) {
        pickingListSummary(data: $filterInput) {
            data {
                storageItemType {
                    id
                    name
                }
                listTotalItems
                warehouseTotalItems
            }
        }
    }
`;

export const visitsSize = 100;
export const RETRIEVE_VISITS_QUERY = gql`
    query getVisits($visitsFilterInput: FilterInput!) {
        visits(filter: $visitsFilterInput) {
            edges {
                cursor
                node {
                    id
                    ...PickListFiltersVisit
                }
            }
            pageInfo {
                cursor {
                    beforeCursor
                    afterCursor
                }
                hasNextPage
                hasPreviousPage
            }
        }
    }

    ${PICKLIST_FILTERS_VISIT_FRAGMENT}
`;

export const storageItemTypesSize = 100;
export const RETRIEVE_STORAGE_ITEM_TYPES_QUERY = gql`
    query getStorageItemTypes($storageItemTypesFilterInput: FilterInput!) {
        serviceProvider {
            id
            storageItemTypes(filter: $storageItemTypesFilterInput) {
                edges {
                    cursor
                    node {
                        id
                        ...PickListFiltersStorageItemType
                    }
                }
                pageInfo {
                    cursor {
                        beforeCursor
                        afterCursor
                    }
                    hasNextPage
                    hasPreviousPage
                }
            }
        }
    }

    ${PICKLIST_FILTERS_STORAGE_ITEM_TYPE_FRAGMENT}
`;

export const CREATE_PICKLIST_MUTATION = gql`
    mutation createPickList($description: String, $filterInput: PickingCreateListFilterInput!) {
        pickingCreateList(data: { description: $description, filter: $filterInput }) {
            pickList {
                id
            }
        }
    }
`;

export type FiltersInputType = {
    description?: string;
    visitStartTime?: string;
    visitEndTime?: string;
    locations?: { from: string; to: string }[];
    storageItemTypeIds?: string[];
    maxQuantity?: number;
    visitIds?: string[];
};

export const currentDate = set(new Date(), { seconds: 0, milliseconds: 0 }).toISOString();

const getUpdatedFilterInput = (
    filtersInput: FiltersInputType,
): RetrievePickListSummaryVariableFilterInputType | CreatePickListVariableFilterInputType => {
    const updatedFilterInput: CreatePickListVariableFilterInputType = {};

    if (
        filtersInput.visitStartTime &&
        format(parseISO(filtersInput.visitStartTime), 'MM dd yyyy') !==
            format(addDays(new Date(), 1), 'MM dd yyyy')
    ) {
        updatedFilterInput.visitStartTime = filtersInput.visitStartTime;
    } else {
        updatedFilterInput.visitStartTime = currentDate;
    }

    if (filtersInput.visitEndTime) {
        updatedFilterInput.visitEndTime = filtersInput.visitEndTime;
    }

    if (
        filtersInput.locations &&
        filtersInput.locations[0].from !== '' &&
        filtersInput.locations[0].to !== ''
    ) {
        updatedFilterInput.locations = [
            { from: filtersInput.locations[0].from, to: filtersInput.locations[0].to },
        ];
    }

    if (filtersInput?.storageItemTypeIds && filtersInput.storageItemTypeIds.length > 0) {
        updatedFilterInput.storageItemTypeIds = filtersInput.storageItemTypeIds;
    }

    if (filtersInput.maxQuantity) {
        updatedFilterInput.maxQuantity = filtersInput.maxQuantity;
    }

    if (filtersInput?.visitIds && filtersInput.visitIds.length > 0) {
        updatedFilterInput.visitIds = filtersInput.visitIds;
    }

    return updatedFilterInput;
};

type CreatePickListProps = {};

export const CreatePickList: React.FC<CreatePickListProps> = () => {
    const intl = useIntl();

    const [filtersInput, setFiltersInput] = useState<FiltersInputType>({});
    const [fetchingVisits, setFetchingVisits] = useState<boolean>(false);
    const [fetchingStorageItemTypes, setFetchingStorageItemTypes] = useState<boolean>(false);
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [showSummary, setShowSummary] = useState<boolean>(false);

    const history = useHistory();
    const { path } = useRouteMatch();

    const {
        data: retrievePickListData,
        loading: retrievePickListLoading,
        error: retrievePickListError,
        refetch: retrievePickListRefetch,
    } = useGetPickListSummaryQuery({
        variables: {
            filterInput: {
                visitStartTime: currentDate,
            },
        },
        errorPolicy: 'all',
    });

    const {
        data: retrieveVisitsData,
        error: retrieveVisitsError,
        refetch: retrieveVisitsRefetch,
    } = useGetVisitsQuery({
        variables: {
            visitsFilterInput: {
                after: '',
                pageSize: visitsSize,
                conditions: [
                    {
                        field: 'startTime',
                        operator: Operators.GreaterthanOrEqualto,
                        value: currentDate,
                    },
                ],
            },
        },
        errorPolicy: 'all',
    });

    const visitsDataEdges = retrieveVisitsData?.visits.edges ?? [];
    const visitsData = useMemo(() => {
        return {
            visits: visitsDataEdges.map((visitEdge) => visitEdge.node),
            hasNextPage: retrieveVisitsData?.visits.pageInfo.hasNextPage ?? false,
            endCursor: retrieveVisitsData?.visits.pageInfo.cursor.afterCursor ?? '',
        };
    }, [retrieveVisitsData, visitsDataEdges]);

    const {
        data: retrieveStorageItemTypesData,
        error: retrieveStorageItemTypesError,
        refetch: retrieveStorageItemTypesRefetch,
    } = useGetStorageItemTypesQuery({
        variables: {
            storageItemTypesFilterInput: {
                after: '',
                pageSize: storageItemTypesSize,
            },
        },
        errorPolicy: 'all',
    });

    const handleLoadMoreVisits = useCallback((): void => {
        if (retrieveVisitsData?.visits.pageInfo.hasNextPage && !fetchingVisits) {
            setFetchingVisits(true);

            retrieveVisitsRefetch({
                visitsFilterInput: {
                    pageSize: visitsSize,
                    after: retrieveVisitsData?.visits.pageInfo.cursor.afterCursor ?? '',
                },
            }).then(() => {
                setFetchingVisits(false);
            });
        }
    }, [retrieveVisitsData, fetchingVisits, retrieveVisitsRefetch]);

    useEffect(() => {
        if (!retrieveVisitsError && retrieveVisitsData?.visits.pageInfo.hasNextPage) {
            handleLoadMoreVisits();
        }
    }, [handleLoadMoreVisits, retrieveVisitsData, retrieveVisitsError]);

    const handleLoadMoreStorageItemTypes = useCallback((): void => {
        if (
            retrieveStorageItemTypesData?.serviceProvider?.storageItemTypes.pageInfo.hasNextPage &&
            !fetchingStorageItemTypes
        ) {
            setFetchingStorageItemTypes(true);

            retrieveStorageItemTypesRefetch({
                storageItemTypesFilterInput: {
                    pageSize: storageItemTypesSize,
                    after:
                        retrieveStorageItemTypesData?.serviceProvider.storageItemTypes.pageInfo
                            .cursor.afterCursor ?? '',
                },
            }).then(() => {
                setFetchingStorageItemTypes(false);
            });
        }
    }, [retrieveStorageItemTypesData, fetchingStorageItemTypes, retrieveStorageItemTypesRefetch]);

    useEffect(() => {
        if (
            !retrieveStorageItemTypesError &&
            retrieveStorageItemTypesData?.serviceProvider?.storageItemTypes.pageInfo.hasNextPage
        ) {
            handleLoadMoreStorageItemTypes();
        }
    }, [
        handleLoadMoreStorageItemTypes,
        retrieveStorageItemTypesData,
        retrieveStorageItemTypesError,
    ]);

    const storageItemTypesDataEdges =
        retrieveStorageItemTypesData?.serviceProvider?.storageItemTypes.edges ?? [];
    const storageItemTypesData = useMemo(() => {
        return {
            storageItemTypes: storageItemTypesDataEdges.map(
                (storageItemType) => storageItemType.node,
            ),
            hasNextPage:
                retrieveStorageItemTypesData?.serviceProvider?.storageItemTypes.pageInfo
                    .hasNextPage ?? false,
            endCursor:
                retrieveStorageItemTypesData?.serviceProvider?.storageItemTypes.pageInfo.cursor
                    .afterCursor ?? '',
        };
    }, [retrieveStorageItemTypesData, storageItemTypesDataEdges]);

    const [
        createPickList,
        { loading: createPickListLoading, error: createPickListError },
    ] = useCreatePickListMutation({ errorPolicy: 'all' });

    const debounced = useDebouncedCallback(
        (filters: FiltersInputType, refetch: typeof retrievePickListRefetch): void => {
            const updatedFilterInput: RetrievePickListSummaryVariableFilterInputType = getUpdatedFilterInput(
                filters,
            );
            const visitsConditions: WhereCondition[] = [
                ...(filtersInput.visitEndTime
                    ? [
                          {
                              field: 'endTime',
                              operator: 'Lesserthanorequalto',
                              value: filtersInput.visitEndTime,
                          } as WhereCondition,
                      ]
                    : []),
                ...(filtersInput.visitStartTime
                    ? [
                          {
                              field: 'startTime',
                              operator: 'GreaterthanOrEqualto',
                              value: filtersInput.visitStartTime,
                          } as WhereCondition,
                      ]
                    : []),
            ];
            refetch({
                filterInput: updatedFilterInput,
            })
                .then(() => {
                    retrieveVisitsRefetch({
                        visitsFilterInput: {
                            conditions: visitsConditions,
                        },
                    });
                })
                .then(() => {
                    setIsFetching(false);
                })
                .catch((err: Error) => console.log(err));
        },
        1000,
    );

    const handleGoBackPress = (): void => {
        return history.push(`${path}/..`);
    };

    useEffect(() => {
        debounced.callback(filtersInput, retrievePickListRefetch);
    }, [debounced, filtersInput, retrievePickListRefetch]);

    const handleFilterChange = useCallback(
        (filters: FiltersInputType): void => {
            if (!isFetching) {
                setIsFetching(true);
                setFiltersInput(filters);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    const handleCreatePickListPress = (): void => {
        const updatedFilterInput: CreatePickListVariableFilterInputType = getUpdatedFilterInput(
            filtersInput,
        );

        createPickList({
            variables: {
                description: filtersInput.description ?? '',
                filterInput: updatedFilterInput,
            },
        }).then(() => {
            return history.push(`${path}/..`);
        });
    };

    const retrievedPickListLength = useMemo(
        () =>
            retrievePickListData?.pickingListSummary?.data?.reduce(
                (total, { listTotalItems }) => total + listTotalItems,
                0,
            ) ?? 0,
        [retrievePickListData],
    );

    const pickListSummaryItems: RetrievedPickListSummaryDataItem[] = useMemo(
        () => retrievePickListData?.pickingListSummary.data ?? [],

        [retrievePickListData],
    );

    const storageItemTypeMap: Record<string, string> = {};

    return (
        <View style={createPickListStyles.ViewParent} testID="data-createPickListPage">
            <View style={createPickListStyles.ViewHeader}>
                <Button
                    title={intl.formatMessage({
                        id: 'createPickList.goBackButton',
                        defaultMessage: 'Go Back',
                    })}
                    onPress={handleGoBackPress}
                    size="small"
                />
            </View>

            <View style={createPickListStyles.ViewContent}>
                {retrievePickListError && (
                    <Text testID="data-createPickListRetrieveError">
                        <FormattedMessage
                            id="createPickList.retrievePickListError"
                            defaultMessage="There was an error fetching the pick list summary - please reload this page."
                        />
                    </Text>
                )}

                {createPickListError && (
                    <Text testID="data-createPickListCreateError">
                        <FormattedMessage
                            id="createPickList.createPickListError"
                            defaultMessage="There was an error creating the pick list - please reload this page and try
                                        again."
                        />
                    </Text>
                )}

                {!retrievePickListData && retrievePickListLoading && (
                    <Text testID="data-createPickListRetrieveLoading">
                        <FormattedMessage id="createPickList.loading" defaultMessage="Loading..." />
                    </Text>
                )}

                {retrievePickListData && (
                    <View testID="data-createPickListRetrieveData">
                        {showSummary && (
                            <RetrievedPickList
                                pickListSummaryItems={pickListSummaryItems}
                                filtersInput={filtersInput}
                                storageItemTypeMap={storageItemTypeMap}
                            />
                        )}

                        {!showSummary && (
                            <PickListFilters
                                onFilterChange={handleFilterChange}
                                filtersInput={filtersInput}
                                storageItemTypes={storageItemTypesData.storageItemTypes}
                                visits={visitsData.visits}
                            />
                        )}
                    </View>
                )}
            </View>

            <View style={createPickListStyles.ViewActions}>
                <Text style={{ flex: 1 }} testID="data-createPickListRetrievedItemsNum">
                    <Text>
                        <FormattedMessage
                            id="createPickList.retrievedPickListLength"
                            defaultMessage="{length}"
                            values={{
                                length: isFetching
                                    ? 'loading... '
                                    : `${retrievedPickListLength} items - `,
                            }}
                        />
                    </Text>

                    <Button
                        title={intl.formatMessage(
                            {
                                id: 'createPickList.retrievedPickListSummaryButton',
                                defaultMessage: '{message}',
                            },
                            { message: showSummary ? 'Hide items' : 'Show items' },
                        )}
                        onPress={() => setShowSummary(!showSummary)}
                        size="small"
                        appearance="ghost"
                    />
                </Text>

                <View style={{ flex: 1 }}>
                    <Button
                        disabled={createPickListLoading || retrievedPickListLength === 0}
                        title={intl.formatMessage({
                            id: 'createPickList.createButton',
                            defaultMessage: 'Create Pick List',
                        })}
                        onPress={handleCreatePickListPress}
                        testID="data-createPickListButton"
                        size="small"
                    />
                </View>
            </View>
        </View>
    );
};

const createPickListStyles = StyleSheet.create({
    ViewParent: {
        height: '100%',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    ViewHeader: {
        paddingVertical: 5,
    },
    ViewContent: {
        paddingVertical: 5,
        flex: 1,
        overflowX: 'none',
        overflowY: 'scroll',
    },
    ViewActions: {
        borderTopColor: '#D4D4D5',
        borderTopWidth: 1,
        paddingVertical: 5,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
});

type RetrievedPickListProps = {
    pickListSummaryItems: RetrievedPickListSummaryDataItem[];
    filtersInput: FiltersInputType;
    storageItemTypeMap: Record<string, string>;
};

const RetrievedPickList: React.FC<RetrievedPickListProps> = ({
    pickListSummaryItems,
    filtersInput,
    storageItemTypeMap,
}) => {
    const mappedStorageItemTypes: string[] | undefined = filtersInput.storageItemTypeIds
        ?.filter((item) => storageItemTypeMap[item] !== undefined)
        .map((item) => storageItemTypeMap[item]);

    return (
        <View
            style={retrievedPickListStyles.ViewParent}
            testID="data-createPickListRetrievedItemsSummary"
        >
            <Text>
                <FormattedMessage
                    id="createPickList.summaryPageTitle"
                    defaultMessage="Summary List"
                />
            </Text>

            <Text style={retrievedPickListStyles.TextHeader}>
                <FormattedMessage
                    id="createPickList.summaryPageHeader"
                    defaultMessage="Pick List Summary"
                />
            </Text>

            <View
                style={{ paddingVertical: 5, borderBottomWidth: 1, borderBottomColor: '#D4D4D5' }}
                testID="data-createPickListRetrievedItemsSummaryFilters"
            >
                <Text style={{ fontSize: 18, fontWeight: '700' }}>
                    <FormattedMessage
                        id="createPickList.summaryPageFiltersHeader"
                        defaultMessage="Filters"
                    />
                </Text>

                <View style={retrievedPickListStyles.ViewItem}>
                    <Text style={retrievedPickListStyles.TextItemValue}>
                        <FormattedMessage
                            id="createPickList.summaryPageFilterVisitDateRangeLabel"
                            defaultMessage="Visit date range"
                        />
                    </Text>
                    <Text style={retrievedPickListStyles.TextItemLabel}>
                        <FormattedMessage
                            id="createPickList.summaryPageFilterDateRangeValue"
                            defaultMessage="{dateRangeValue}"
                            values={{
                                dateRangeValue:
                                    filtersInput.visitStartTime && filtersInput.visitEndTime
                                        ? `${filtersInput.visitStartTime} to ${filtersInput.visitEndTime}`
                                        : 'Any',
                            }}
                        />
                    </Text>
                </View>

                <View style={retrievedPickListStyles.ViewItem}>
                    <Text style={retrievedPickListStyles.TextItemValue}>
                        <FormattedMessage
                            id="createPickList.summaryPageFilterLocationRangeLabel"
                            defaultMessage="Location range"
                        />
                    </Text>
                    <Text style={retrievedPickListStyles.TextItemLabel}>
                        <FormattedMessage
                            id="createPickList.summaryPageFilterLocationRangeValue"
                            defaultMessage="{locationRangeValue}"
                            values={{
                                locationRangeValue:
                                    filtersInput.locations &&
                                    filtersInput.locations[0].from !== '' &&
                                    filtersInput.locations[0].to !== ''
                                        ? `${filtersInput.locations[0].from} to ${filtersInput.locations[0].to}`
                                        : 'All',
                            }}
                        />
                    </Text>
                </View>

                <View style={retrievedPickListStyles.ViewItem}>
                    <Text style={retrievedPickListStyles.TextItemValue}>
                        <FormattedMessage
                            id="createPickList.summaryPageFilterStorageItemTypesLabel"
                            defaultMessage="Storage item types"
                        />
                    </Text>
                    <Text style={retrievedPickListStyles.TextItemLabel}>
                        <FormattedMessage
                            id="createPickList.summaryPageFilterStorageItemTypeValue"
                            defaultMessage="{storageItemTypeValue}"
                            values={{
                                storageItemTypeValue:
                                    mappedStorageItemTypes && mappedStorageItemTypes.length > 0
                                        ? `${mappedStorageItemTypes.join(', ')}`
                                        : 'All',
                            }}
                        />
                    </Text>
                </View>

                <View style={retrievedPickListStyles.ViewItem}>
                    <Text style={retrievedPickListStyles.TextItemValue}>
                        <FormattedMessage
                            id="createPickList.summaryPageFilterMaxQuantityLabel"
                            defaultMessage="Max. item quantity"
                        />
                    </Text>

                    <Text style={retrievedPickListStyles.TextItemLabel}>
                        <FormattedMessage
                            id="createPickList.summaryPageFilterMaxItemQuantityValue"
                            defaultMessage="{maxItemQuantityValue}"
                            values={{
                                maxItemQuantityValue: filtersInput.maxQuantity
                                    ? `${filtersInput.maxQuantity}`
                                    : 'All',
                            }}
                        />
                    </Text>
                </View>

                <View style={retrievedPickListStyles.ViewItem}>
                    <Text style={retrievedPickListStyles.TextItemValue}>
                        <FormattedMessage
                            id="createPickList.summaryPageFilterVisitsLabel"
                            defaultMessage="Visits"
                        />
                    </Text>

                    <Text style={retrievedPickListStyles.TextItemLabel}>
                        <FormattedMessage
                            id="createPickList.summaryPageFilterVisitsValue"
                            defaultMessage="{visitsValue}"
                            values={{
                                visitsValue:
                                    filtersInput.visitIds && filtersInput.visitIds.length > 0
                                        ? `${filtersInput.visitIds.join(', ')}`
                                        : 'All',
                            }}
                        />
                    </Text>
                </View>
            </View>

            <View
                style={retrievedPickListStyles.ViewItemsList}
                testID="data-createPickListRetrievedItemsSummaryItems"
            >
                <Text style={[retrievedPickListStyles.TextHeader, { fontSize: 18 }]}>
                    <FormattedMessage
                        id="createPickList.summaryPageItemsListHeader"
                        defaultMessage="Items in this list"
                    />
                </Text>

                {pickListSummaryItems.map((item: RetrievedPickListSummaryDataItem) => (
                    <View style={retrievedPickListStyles.ViewItem} key={item.storageItemType.id}>
                        <Text style={retrievedPickListStyles.TextItemLabel}>
                            <FormattedMessage
                                id="createPickList.summaryPageListItemLabel"
                                defaultMessage="{itemLabel}"
                                values={{
                                    itemLabel: item.storageItemType.name,
                                }}
                            />
                        </Text>

                        <Text style={retrievedPickListStyles.TextItemValue}>
                            <FormattedMessage
                                id="createPickList.summaryPageListItemTotal"
                                defaultMessage="{itemTotal}"
                                values={{
                                    itemTotal: item.listTotalItems,
                                }}
                            />
                        </Text>
                    </View>
                ))}
            </View>
        </View>
    );
};

const retrievedPickListStyles = StyleSheet.create({
    ViewParent: {
        display: 'flex',
        flexDirection: 'column',
    },
    ViewDetails: {
        flex: 1,
        overflowY: 'scroll',
        overflowX: 'none',
        marginVertical: 5,
        display: 'flex',
        flexDirection: 'column',
    },
    ViewItemsList: {
        marginVertical: 5,
    },
    ViewItem: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
    },
    TextHeader: {
        fontWeight: '700',
    },
    TextItemLabel: {
        flex: 1,
    },
    TextItemValue: {
        flex: 1,
        fontWeight: '700',
    },
});
