import { gql } from '@apollo/client';
import React, { useState, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Card, ErrorMessage, Message } from '@valet/ui-components';

import { FlatList, ScrollView, StyleSheet, Text, View } from 'react-native';
import {
    AdminLocationFragment,
    GetAdminLocationsQuery,
    useGetAdminLocationsQuery,
} from '../../../../schema-types';
import { useHistory } from 'react-router-dom';

export const locationPageSize = 100;

const ADMIN_LOCATION_FRAGMENT = gql`
    fragment adminLocation on Location {
        id
        description
        barcode
        sortedItems
        itemsToSort
    }
`;

export const RETRIEVE_LOCATION_QUERY = gql`
    query getAdminLocations($pageSize: Int!, $afterCursor: String, $beforeCursor: String) {
        locations(filter: { pageSize: $pageSize, after: $afterCursor, before: $beforeCursor }) {
            edges {
                cursor
                node {
                    id
                    ...adminLocation
                }
            }
            pageInfo {
                cursor {
                    beforeCursor
                    afterCursor
                }
                hasNextPage
                hasPreviousPage
            }
        }
    }

    ${ADMIN_LOCATION_FRAGMENT}
`;

type LocationPageProps = {
    onLocationSelect: (id: string) => void;
    onAddLocationSelect: () => void;
};

export const LocationPage: React.FC<LocationPageProps> = ({
    onLocationSelect,
    onAddLocationSelect,
}) => {
    const history = useHistory();
    const intl = useIntl();

    const [errorMessage, setErrorMessage] = useState<ErrorMessage | undefined>(undefined);

    const {
        data,
        loading: retrievedLocationsLoading,
        error: retrievedLocationsError,
        fetchMore,
    } = useGetAdminLocationsQuery({
        variables: {
            pageSize: locationPageSize,
            afterCursor: '',
        },
    });

    const [isFetching, setIsFetching] = useState<boolean>(false);

    const locationEdges = data?.locations.edges ?? [];
    const locationData = locationEdges?.map((itemEdge: any) => itemEdge.node);

    const [retrievedLocationsData, setRetrievedLocationsData] = useState<AdminLocationFragment[]>(
        locationEdges?.map((itemEdge: any) => itemEdge.node) ?? [],
    );
    const [locationQueryData, setLocationQueryData] = useState<GetAdminLocationsQuery | undefined>(
        data,
    );

    const handlePrevPress = useCallback(async () => {
        setErrorMessage(undefined);
        if (!isFetching) {
            setIsFetching(true);

            const { data, error } = await fetchMore({
                variables: {
                    pageSize: locationPageSize,
                    beforeCursor: locationQueryData?.locations.pageInfo.cursor.beforeCursor || '',
                },
            });
            const edges = data?.locations.edges ?? [];

            setRetrievedLocationsData(edges?.map((itemEdge: any) => itemEdge.node) ?? []);
            setLocationQueryData(data);

            if (error && error.message) {
                setErrorMessage({
                    content: intl.formatMessage({
                        id: 'locationPage.errorLoadingPrevPageContent',
                        defaultMessage: error.message,
                    }),
                    type: 'warning',
                    header: intl.formatMessage({
                        id: 'locationPage.errorLoadingPrevPageHeader',
                        defaultMessage:
                            'Error in retrieving Container List, please refresh the page.',
                    }),
                });
            }

            setIsFetching(false);
        }
    }, [isFetching, locationQueryData, fetchMore, intl]);

    const handleNextPress = useCallback(async () => {
        setErrorMessage(undefined);
        if (!isFetching) {
            setIsFetching(true);

            const { data, error } = await fetchMore({
                variables: {
                    pageSize: locationPageSize,
                    afterCursor: locationQueryData?.locations.pageInfo.cursor.afterCursor || '',
                },
            });
            const edges = data?.locations.edges ?? [];

            setRetrievedLocationsData(edges?.map((itemEdge: any) => itemEdge.node) ?? []);
            setLocationQueryData(data);

            if (error && error.message) {
                setErrorMessage({
                    content: intl.formatMessage({
                        id: 'locationPage.errorLoadingPrevPageContent',
                        defaultMessage: error.message,
                    }),
                    type: 'warning',
                    header: intl.formatMessage({
                        id: 'locationPage.errorLoadingPrevPageHeader',
                        defaultMessage:
                            'Error in retrieving Container List, please refresh the page.',
                    }),
                });
            }

            setIsFetching(false);
        }
    }, [isFetching, locationQueryData, fetchMore, intl]);

    const handleItemClick = useCallback(
        (id: string) => {
            onLocationSelect(id);
        },
        [onLocationSelect],
    );

    const handleBacklClick = useCallback(async () => {
        history.push(`/admin`);
    }, [history]);

    return (
        <ScrollView>
            <View style={locationsPageStyles.ViewParent} testID="data-location">
                <View style={locationsPageStyles.ViewTabs}>
                    <View>
                        <View style={{ flex: 1, marginRight: 2 }}>
                            <Button
                                title={intl.formatMessage({
                                    id: 'locations.backButton',
                                    defaultMessage: 'Back',
                                })}
                                onPress={handleBacklClick}
                            />
                        </View>
                    </View>
                    <View>
                        <View style={{ flex: 1, marginRight: 10, marginBottom: 10 }}>
                            <Button
                                title="+ Add New Location"
                                onPress={() => onAddLocationSelect()}
                            />
                        </View>
                    </View>
                </View>
                <LocationNavigation
                    hasPrev={
                        locationQueryData?.locations.pageInfo.cursor.afterCursor ||
                        locationQueryData?.locations.pageInfo.cursor.beforeCursor
                            ? locationQueryData?.locations.pageInfo.hasPreviousPage
                            : data?.locations?.pageInfo?.hasPreviousPage ?? false
                    }
                    hasNext={
                        locationQueryData?.locations.pageInfo.cursor.afterCursor ||
                        locationQueryData?.locations.pageInfo.cursor.beforeCursor
                            ? locationQueryData?.locations.pageInfo.hasNextPage
                            : data?.locations?.pageInfo?.hasNextPage ?? false
                    }
                    onPrevPress={handlePrevPress}
                    onNextPress={handleNextPress}
                />
                {errorMessage && (
                    <Message
                        type={errorMessage.type}
                        header={errorMessage.header}
                        content={errorMessage.content}
                    />
                )}
                {(isFetching || retrievedLocationsLoading) && (
                    <View testID="data-retrievedLocationsLoading">
                        <Text>
                            <FormattedMessage
                                id="retrievedLocations.loading"
                                defaultMessage="Loading..."
                            />
                        </Text>
                    </View>
                )}

                {retrievedLocationsError && (
                    <View style={locationItemStyles.ViewTitleParent}>
                        <Text
                            style={locationItemStyles.TextTitle}
                            testID="data-retrievedLocationsError"
                        >
                            {`${intl.formatMessage({
                                id: 'retrievedLocations.error',
                                defaultMessage:
                                    'Error in retrieving Container List, please refresh the page.',
                            })}`}
                        </Text>
                    </View>
                )}
                {!retrievedLocationsLoading && !isFetching && retrievedLocationsData && (
                    <View style={locationsPageStyles.ViewContent}>
                        <FlatList
                            data={
                                locationQueryData?.locations.pageInfo.cursor.afterCursor ||
                                locationQueryData?.locations.pageInfo.cursor.beforeCursor
                                    ? retrievedLocationsData
                                    : locationData
                            }
                            renderItem={({ item }) => (
                                <LocationItem
                                    key={item.id}
                                    location={item}
                                    onItemClick={handleItemClick}
                                />
                            )}
                            keyExtractor={(item) => item.id}
                        />
                    </View>
                )}
            </View>
        </ScrollView>
    );
};

const locationsPageStyles = StyleSheet.create({
    ViewParent: {
        display: 'flex',
        flexDirection: 'column',
    },
    ViewTabs: {
        marginVertical: 5,
        display: 'flex',
        flexDirection: 'row',
    },
    ViewContent: {
        paddingVertical: 5,
    },
    TextTab: {
        flex: 1,
        color: '#000',
        backgroundColor: '#D4D4D5',
        fontSize: 18,
        textAlign: 'center',
        fontWeight: '700',
    },
    ViewErrorMessageParent: {
        marginTop: 5,
        marginBottom: 5,
    },
});

type LocationItemProps = {
    location: AdminLocationFragment;
    onItemClick: (id: string) => void;
};

const LocationItem: React.FC<LocationItemProps> = ({ location, onItemClick }) => {
    const intl = useIntl();

    return (
        <Card
            style={locationItemStyles.ViewParent}
            onPress={() => onItemClick(location?.id || '')}
            testID="data-deliveryDetail"
        >
            <View style={locationItemStyles.ViewChild}>
                <View style={locationItemStyles.ViewContentParent}>
                    <View style={locationItemStyles.ViewTitleParent}>
                        <Text
                            style={locationItemStyles.TextTitle}
                            testID="data-locationItemDescription"
                        >
                            {`${intl.formatMessage({
                                id: 'locationItem.Description',
                                defaultMessage: 'Description: ',
                            })}`}
                        </Text>
                        <Text>{location?.description}</Text>
                    </View>
                    <View style={locationItemStyles.ViewTitleParent}>
                        <Text
                            style={locationItemStyles.TextTitle}
                            testID="data-locationItemBarcode"
                        >
                            {`${intl.formatMessage({
                                id: 'locationItem.barcode',
                                defaultMessage: 'Barcode: ',
                            })}`}
                        </Text>
                        <Text>{location?.barcode}</Text>
                    </View>
                </View>
            </View>
        </Card>
    );
};

const locationItemStyles = StyleSheet.create({
    ViewParent: {
        marginVertical: 3,
    },
    ViewChild: {
        display: 'flex',
        flexDirection: 'row',
    },
    ViewImageParent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    locationImage: {
        height: 40,
        width: 40,
        resizeMode: 'stretch',
    },
    ViewContentParent: {
        paddingHorizontal: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    ViewTitleParent: {
        display: 'flex',
        flexDirection: 'column',
    },
    ViewTitleChild: {},
    TextTitle: {
        fontSize: 18,
        fontWeight: 'bold',
    },
    ViewDescription: {},
    TextDescription: { fontSize: 14 },
    ViewStatus: {},
    TextStatus: {
        fontSize: 12,
        fontWeight: '700',
    },
    ViewDate: {},
    TextDate: {
        fontSize: 12,
    },
    ViewErrorMessageParent: {
        marginTop: 5,
        marginBottom: 5,
    },
});

type LocationNavigationProps = {
    hasNext: boolean;
    onNextPress: () => void;
    hasPrev: boolean;
    onPrevPress: () => void;
};

const LocationNavigation: React.FC<LocationNavigationProps> = ({
    hasNext,
    onNextPress,
    hasPrev,
    onPrevPress,
}) => {
    const intl = useIntl();

    return (
        <View
            style={{
                marginVertical: 10,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
            }}
        >
            <View style={{ flex: 1 }}>
                <Button
                    title={intl.formatMessage({
                        id: 'locationPage.navigationPrevious',
                        defaultMessage: 'Previous',
                    })}
                    onPress={onPrevPress}
                    disabled={!hasPrev}
                    testID="data-locationPagePrevLocationButton"
                />
            </View>
            <View style={{ flex: 1 }}>
                <Button
                    title={intl.formatMessage({
                        id: 'locationPage.navigationNext',
                        defaultMessage: 'Next',
                    })}
                    onPress={onNextPress}
                    disabled={!hasNext}
                    testID="data-locationPageNextLocationButton"
                />
            </View>
        </View>
    );
};
