import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { FlatList, StyleSheet, Text, View } from 'react-native';
import { Card, Checkbox, Select, SelectItem, TextInput } from '@valet/ui-components';

export type ScheduleTemplate = {
    id: string;
    startDate: Date;
    areaId: string;
};

export type ScheduleTemplateWindowType = {
    id: string;
    startTime: string;
    endTime: string;
    week: number;
    day: number;
};

type ScheduleTemplateWeekType = {
    monday: ScheduleTemplateWindowType[];
    tuesday: ScheduleTemplateWindowType[];
    wednesday: ScheduleTemplateWindowType[];
    thursday: ScheduleTemplateWindowType[];
    friday: ScheduleTemplateWindowType[];
    saturday: ScheduleTemplateWindowType[];
    sunday: ScheduleTemplateWindowType[];
};

type ScheduleWeekProps = {
    weekData: ScheduleTemplateWeekType;
    weekNumber: number;
    excludedPeriods?: ScheduleTemplateWindowType[] | undefined;
    handleSchedulePeriodTerritory: (scheduleTemplatePeriodId: string, checked: boolean) => void;
};

export const ScheduleWeek: React.FC<ScheduleWeekProps> = ({
    weekData,
    weekNumber,
    excludedPeriods,
    handleSchedulePeriodTerritory
}) => {
    type DayType = { day: string; value: number; periods: ScheduleTemplateWindowType[] };
    const weekDays: DayType[] = [
        { day: 'Mon', value: 1, periods: weekData.monday },
        { day: 'Tue', value: 2, periods: weekData.tuesday },
        { day: 'Wed', value: 3, periods: weekData.wednesday },
        { day: 'Thu', value: 4, periods: weekData.thursday },
        { day: 'Fri', value: 5, periods: weekData.friday },
        { day: 'Sat', value: 6, periods: weekData.saturday },
        { day: 'Sun', value: 7, periods: weekData.sunday },
    ];

    const [selectedDay, setSelectedDay] = useState<DayType | undefined>(undefined);
    return (
        <View style={scheduleWeekPageStyles.ViewListItemContainer}>
            <Card
                style={{ flex: 1 }}
                disabled
            >
                <View style={scheduleWeekPageStyles.ViewListItemBody}>
                    <View
                        style={{
                            paddingVertical: 2,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}
                    >
                        <Text style={{ fontSize: 16 }}>
                            <FormattedMessage id="routing.selectedItems" defaultMessage="Week " />
                            {weekNumber}
                        </Text>
                    </View>
                    <View style={{ paddingVertical: 2 }}>
                        <Select
                            currentIndex={undefined}
                            itemsDisplayValues={weekDays.map((items) => items.day)}
                            onSelect={(index) => setSelectedDay(weekDays[index])}
                        >
                            {weekDays.map((item) => (
                                <SelectItem title={item.day} key={item.value} />
                            ))}
                        </Select>
                    </View>
                    <View style={{ paddingVertical: 2 }}>
                        {selectedDay && (
                            <ScheduleDay
                                key={`week_${selectedDay.value}_day_${selectedDay.value}`}
                                dayData={selectedDay}
                                excludedPeriods={excludedPeriods}
                                handleSchedulePeriodTerritory={handleSchedulePeriodTerritory}
                            />
                        )}
                    </View>

                </View>
            </Card>
        </View>
    );
};

const scheduleWeekPageStyles = StyleSheet.create({
    ViewParent: {
        display: 'flex',
        flexDirection: 'column',
    },
    TextContainerItem: {
        marginVertical: 2,
        display: 'flex',
        flexDirection: 'row',
        fontWeight: 'bold',
    },
    ViewChild: {
        display: 'flex',
        flexDirection: 'row',
    },
    ViewListItemContainer: {
        flexDirection: 'row',
        paddingVertical: 4,
        paddingRight: 4,
    },
    ViewListItemBody: {
        flex: 1,
        flexDirection: 'column',
    },
    ViewListItemActions: {
        padding: 5,
        flexDirection: 'row',
        justifyContent: 'center',
        flex: 1,
        display: 'flex',
    },
});

type ScheduleDayProps = {
    dayData: { day: string; value: number; periods: ScheduleTemplateWindowType[] };
    excludedPeriods?: ScheduleTemplateWindowType[];
    handleSchedulePeriodTerritory: (scheduleTemplatePeriodId: string, checked: boolean) => void;
};

export const ScheduleDay: React.FC<ScheduleDayProps> = ({ dayData, excludedPeriods, handleSchedulePeriodTerritory }) => {

    return (
        <View style={scheduleDayPageStyles.ViewParent} testID="data-ScheduleWeek">
            <View style={scheduleDayPageStyles.ViewChild}>
                {dayData && (
                    <View style={scheduleDayPageStyles.ViewContent}>
                        <FlatList
                            data={dayData.periods}
                            renderItem={({ item }): any => (
                                <SchedulePeriod
                                    excluded={excludedPeriods?.find(r => r.id === item.id) ? true : false}
                                    key={item.id}
                                    periodData={item}
                                    handleSchedulePeriodTerritory={handleSchedulePeriodTerritory}
                                />
                            )}
                            keyExtractor={(item) => item.id}
                        />
                    </View>
                )}
            </View>
        </View>
    );
};

const scheduleDayPageStyles = StyleSheet.create({
    ViewParent: {
        display: 'flex',
        flexDirection: 'column',
    },
    ViewChild: {
        display: 'flex',
        flexDirection: 'column',
    },
    ViewContent: {
        paddingVertical: 5,
    },
    AddNewPeriodButton: {},
    ViewActionButtons: {
        marginVertical: 5,
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
});

type SchedulePeriodProps = {
    periodData: ScheduleTemplateWindowType;
    excluded: boolean;
    handleSchedulePeriodTerritory: (scheduleTemplatePeriodId: string, checked: boolean) => void;
};

export const SchedulePeriod: React.FC<SchedulePeriodProps> = ({ periodData, excluded = false, handleSchedulePeriodTerritory }) => {
    const [period, setPeriod] = useState({ ...periodData });
    const [exclud, setExclud] = useState(excluded);

    return (
        <View style={scheduleDayPageStyles.ViewContent}>
            <Card
                style={{ flex: 1 }}
                disabled
                footer={() => <View style={scheduleWeekPageStyles.ViewListItemActions}></View>}
            >
                <View
                    style={scheduleWeekPageStyles.TextContainerItem}
                    key={period.id}
                    testID="data-disableBuilding"
                >
                    <FormattedMessage
                        id="buildingDetail.isActive"
                        defaultMessage="Excluded Schedule:  "
                    />
                    <View style={{ marginLeft: 5 }}>
                        <Checkbox
                            onValueChange={(value: boolean) => {
                                setExclud(value);
                                handleSchedulePeriodTerritory(period.id, value)
                            }
                            }
                            checked={exclud}
                            testID="data-disableBuildingCheck"
                        />
                    </View>
                </View>
                <View style={SchedulePeriodPageStyles.ViewDescription}>
                    <View>
                        <FormattedMessage
                            id="scheduleDetail.startTime"
                            defaultMessage="StartTime"
                        />
                        <TextInput
                            type="text"
                            ariaLabel="StartTime"
                            value={period.startTime}
                            disabled={true}
                            onChange={(value: any) => {
                                setPeriod({ ...period, startTime: value });
                            }}
                        />
                    </View>
                </View>
                <View style={SchedulePeriodPageStyles.ViewDescription}>
                    <View>
                        <FormattedMessage
                            id="scheduleDetail.endTime"
                            defaultMessage="EndTime"
                        />
                        <TextInput
                            type="text"
                            ariaLabel="EndTime"
                            value={period.endTime}
                            disabled={true}
                            onChange={(value: any) => {
                                setPeriod({ ...period, endTime: value });
                            }}
                        />
                    </View>
                </View>
            </Card>
        </View>
    );
};

const SchedulePeriodPageStyles = StyleSheet.create({
    ViewParent: {
        display: 'flex',
        flexDirection: 'column',
    },
    ViewChild: {
        display: 'flex',
        flexDirection: 'column',
    },
    ViewDescription: {
        marginTop: 10,
        fontWeight: 'bold',
    },
});
