import { gql } from '@apollo/client';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FlatList, Image, ScrollView, StyleSheet, Text, View } from 'react-native';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Button, Icon, TextInput, Message, TopTabBar, TopTab, Card } from '@valet/ui-components';
import {
    useTruckLoadingRequestsByVisitIdQuery,
    useTruckLoadingProcessItemMutation,
    useTruckLoadingProcessEmptyContainersMutation,
    TruckLoadingDeliverProcessDeliverStorageItemRequestFragment,
    TruckLoadingDeliverProcessDeliverEmptyContainerRequestFragment,
    RequestStatus,
    RequestType,
    useTruckLoadingProcessConfirmRouteMutation,
} from '../../../schema-types';

type MenuItem = {
    key: string;
    label: string;
};

const TRUCKLOADING_ITEM_PROCESS_DELIVER_STORAGE_ITEM_REQUEST_FRAGMENT = gql`
    fragment TruckLoadingDeliverProcessDeliverStorageItemRequest on DeliverStorageItemRequest {
        id
        status
        requestType
        storageItem {
            id
            status
            barcode
            location {
                description
            }
            storageItemType {
                id
                name
                description
                image
            }
        }
        customer {
            email
            firstName
            lastName
            homePhone
            cellPhone
        }
    }
`;

const TRUCKLOADING_ITEM_PROCESS_DELIVER_EMPTY_STORAGE_Container_REQUEST_FRAGMENT = gql`
    fragment TruckLoadingDeliverProcessDeliverEmptyContainerRequest on DeliverEmptyContainerRequest {
        id
        status
        requestType
        storageItem {
            id
            status
            barcode
            location {
                description
            }
            storageItemType {
                id
                name
                description
                image
            }
        }
        customer {
            email
            firstName
            lastName
            homePhone
            cellPhone
        }
    }
`;

export const RETRIEVE_TRUCKLOADING_REQUESTS_QUERY = gql`
    query truckLoadingRequestsByVisitId($visitId: String!, $pageSize: Int!, $cursor: String!) {
        requestsByVisitId(visitId: $visitId, filter: { pageSize: $pageSize, after: $cursor }) {
            edges {
                cursor
                node {
                    id
                    status
                    requestType
                    ...TruckLoadingDeliverProcessDeliverStorageItemRequest
                    ...TruckLoadingDeliverProcessDeliverEmptyContainerRequest
                }
            }
            pageInfo {
                cursor {
                    afterCursor
                    beforeCursor
                }
                hasNextPage
                hasPreviousPage
            }
        }
    }

    ${TRUCKLOADING_ITEM_PROCESS_DELIVER_STORAGE_ITEM_REQUEST_FRAGMENT}
    ${TRUCKLOADING_ITEM_PROCESS_DELIVER_EMPTY_STORAGE_Container_REQUEST_FRAGMENT}
`;

export const TRUCKLOADING_DELIVER_ITEM_MUTATION = gql`
    mutation truckLoadingProcessItem($data: TruckLoadingProcessItemInput!) {
        truckLoadingProcessItem(data: $data) {
            data {
                id
                status
            }
        }
    }
`;

export const TRUCKLOADING_DELIVER_EMPTY_CONTAINER_MUTATION = gql`
    mutation truckLoadingProcessEmptyContainers($data: TruckLoadingProcessEmptyContainersInput!) {
        truckLoadingProcessEmptyContainers(data: $data) {
            data {
                id
                status
            }
        }
    }
`;

export const TRUCKLOADING_CONFIRM_ROUTE_MUTATION = gql`
    mutation truckLoadingProcessConfirmRoute($data: TruckLoadingProcessConfirmRouteInput!) {
        truckLoadingProcessConfirmRoute(data: $data) {
            data {
                id
                status
            }
        }
    }
`;

type TruckLoadingProcessItemProps = {};

export const requestPageSize = 100;
export const TruckLoadingProcessItem: React.FC<TruckLoadingProcessItemProps> = () => {
    const history = useHistory();
    const match = useRouteMatch();
    const params: { routeId?: string; visitId?: string } = match.params;
    const routeId = params.routeId;
    const visitId = params.visitId;

    const [showActionMenu, setShowActionMenu] = useState<boolean>(false);
    const intl = useIntl();

    const handleGoBackPress = useCallback((): void => {
        history.push(`/truckLoading/${routeId}`);
    }, [history, routeId]);

    useEffect(() => {
        if (!visitId) {
            handleGoBackPress();
        }
    }, [visitId, handleGoBackPress]);

    const [isFetching, setIsFetching] = useState<boolean>(false);

    const {
        data: retrievedRequestsData,
        loading: retrievedRequestsLoading,
        error: retrievedRequestsError,
        fetchMore: retrievedRequestsFetchMore,
    } = useTruckLoadingRequestsByVisitIdQuery({
        variables: {
            visitId: visitId || '',
            pageSize: requestPageSize,
            cursor: '',
        },
    });

    const requestEdges = retrievedRequestsData?.requestsByVisitId.edges ?? [];
    const requestData = useMemo(() => {
        return {
            requests: requestEdges?.map((itemEdge) => itemEdge.node) ?? [],
            hasNextPage: retrievedRequestsData?.requestsByVisitId?.pageInfo?.hasNextPage ?? false,
            endCursor: retrievedRequestsData?.requestsByVisitId?.pageInfo?.cursor.afterCursor ?? '',
        };
    }, [requestEdges, retrievedRequestsData]);

    const handleLoadMoreItems = useCallback((): void => {
        if (requestData.hasNextPage && !isFetching) {
            setIsFetching(true);
            retrievedRequestsFetchMore({
                variables: {
                    pageSize: requestPageSize,
                    cursor: requestData.endCursor,
                },
            }).then(() => {
                setIsFetching(false);
            });
        }
    }, [isFetching, retrievedRequestsFetchMore, requestData.hasNextPage, requestData.endCursor]);

    useEffect(() => {
        if (!retrievedRequestsError && requestData.hasNextPage) {
            handleLoadMoreItems();
        }
    }, [handleLoadMoreItems, requestData, retrievedRequestsError]);

    const requests = useMemo<{
        inTransit: TruckLoadingDeliverProcessDeliverStorageItemRequestFragment[];
        waiting: TruckLoadingDeliverProcessDeliverStorageItemRequestFragment[];
        waitingContainers: TruckLoadingDeliverProcessDeliverEmptyContainerRequestFragment[];
        inTransitContainers: TruckLoadingDeliverProcessDeliverEmptyContainerRequestFragment[];
    }>(() => {
        const inTransit = [];
        const waiting = [];
        const waitingContainers = [];
        const inTransitContainers = [];

        const truckLoadingRequests = requestData.requests
            .filter(
                (r) =>
                    r.requestType === RequestType.DeliverStorageItem ||
                    r.requestType === RequestType.DeliverEmptyContainer,
            )
            .map((r) => r);

        for (let i = 0; i < truckLoadingRequests.length; i++) {
            const item = truckLoadingRequests[i];
            if (item.requestType === RequestType.DeliverStorageItem) {
                if (item.status === RequestStatus.InTransitToCustomer) {
                    inTransit.push(
                        item as TruckLoadingDeliverProcessDeliverStorageItemRequestFragment,
                    );
                }

                if (item.status !== RequestStatus.InTransitToCustomer) {
                    waiting.push(
                        item as TruckLoadingDeliverProcessDeliverStorageItemRequestFragment,
                    );
                }
            } else if (item.requestType === RequestType.DeliverEmptyContainer) {
                if (item.status === RequestStatus.InTransitToCustomer) {
                    inTransitContainers.push(
                        item as TruckLoadingDeliverProcessDeliverEmptyContainerRequestFragment,
                    );
                }

                if (item.status !== RequestStatus.InTransitToCustomer) {
                    waitingContainers.push(
                        item as TruckLoadingDeliverProcessDeliverEmptyContainerRequestFragment,
                    );
                }
            }
        }

        return {
            inTransit,
            waiting,
            waitingContainers,
            inTransitContainers,
        };
    }, [requestData.requests]);

    const showConfirmationButton =
        !requests.inTransit.length &&
        !requests.waiting.length &&
        !requests.waitingContainers.length &&
        !requests.inTransitContainers.length;

    const [selectedStorageItem, setSelectedStorageItem] = useState<
        TruckLoadingDeliverProcessDeliverStorageItemRequestFragment['storageItem']
    >();

    useEffect(() => {
        if (!routeId) {
            handleGoBackPress();
        }
    }, [routeId, handleGoBackPress]);

    const handleItemClick = useCallback(
        (id: string) => {
            if (requestData.requests) {
                const req = requestData.requests.find(
                    (r) => r.requestType === RequestType.DeliverStorageItem && r.id === id,
                ) as TruckLoadingDeliverProcessDeliverStorageItemRequestFragment;
                if (req !== undefined) {
                    setSelectedStorageItem(req.storageItem);
                }
            }
            setShowActionMenu(!showActionMenu);
        },
        [requestData.requests, showActionMenu],
    );

    const menuItems: MenuItem[] = [];

    const [expandBarcodeManualEntry, setExpandBarcodeManualEntry] = useState<boolean>(false);
    const [barcodeEntryValue, setBarcodeEntryValue] = useState<string>('');
    const [currentItem, setCurrentItem] = useState<
        TruckLoadingDeliverProcessDeliverStorageItemRequestFragment['storageItem'] | undefined
    >(undefined);

    const handleManualScanCameraPress = useCallback((): void => {
        console.log('Pressed Manual Scan Camera Icon');
    }, []);

    const handleManualScanKeyboardPress = useCallback((): void => {
        setExpandBarcodeManualEntry(!expandBarcodeManualEntry);
    }, [expandBarcodeManualEntry]);

    const [
        truckLoadingProcessItemMutation,
        {
            loading: truckLoadingProcessItemMutationLoading,
            error: truckLoadingProcessItemMutationError,
        },
    ] = useTruckLoadingProcessItemMutation({
        onCompleted({ truckLoadingProcessItem: { data } }) {
            if (data) {
                handleLoadMoreItems();
            }
        },
    });

    const [
        truckLoadingProcessEmptyContainersMutation,
        {
            loading: truckLoadingProcessEmptyContainersMutationLoading,
            error: truckLoadingProcessEmptyContainersMutationError,
        },
    ] = useTruckLoadingProcessEmptyContainersMutation({
        onCompleted({ truckLoadingProcessEmptyContainers: { data } }) {
            if (data) {
                handleLoadMoreItems();
            }
        },
    });

    const [
        truckLoadingProcessConfirmRoute,
        {
            loading: truckLoadingProcessConfirmRouteMutationLoading,
            error: truckLoadingProcessConfirmRouteMutationError,
        },
    ] = useTruckLoadingProcessConfirmRouteMutation({
        onCompleted({ truckLoadingProcessConfirmRoute: { data } }) {
            if (data) {
                handleGoBackPress();
            }
        },
    });

    const handleManualScanSubmit = useCallback(async () => {
        if (barcodeEntryValue) {
            if (requestData.requests && requestData.requests.length > 0) {
                for (let i = 0; i < requestData.requests.length; i++) {
                    const request = requestData.requests[i];
                    if (
                        request.requestType === RequestType.DeliverStorageItem &&
                        (request as TruckLoadingDeliverProcessDeliverStorageItemRequestFragment)
                            .storageItem?.barcode === barcodeEntryValue
                    ) {
                        setCurrentItem(
                            (request as TruckLoadingDeliverProcessDeliverStorageItemRequestFragment)
                                .storageItem,
                        );

                        await truckLoadingProcessItemMutation({
                            variables: {
                                data: {
                                    requestId: request.id,
                                },
                            },
                        });
                        break;
                    }
                }
            }
            setExpandBarcodeManualEntry(!expandBarcodeManualEntry);
            setBarcodeEntryValue('');
        }
    }, [
        requestData.requests,
        barcodeEntryValue,
        expandBarcodeManualEntry,
        setCurrentItem,
        truckLoadingProcessItemMutation,
    ]);

    const handleClearScreenPress = useCallback((): void => {
        setCurrentItem(undefined);
        setExpandBarcodeManualEntry(false);
    }, []);

    const handleMenuItemClick = useCallback(
        (key: string) => {
            setShowActionMenu(!showActionMenu);
        },
        [showActionMenu],
    );

    // Top tabs
    const tabs = [
        {
            title: intl.formatMessage({
                id: 'truckLoadingItemProcess.waitingTabText',
                defaultMessage: 'Waiting',
            }),
        },
        {
            title: intl.formatMessage({
                id: 'truckLoadingItemProcess.inTransitTabText',
                defaultMessage: 'In-Transit',
            }),
        },
    ];
    const [currentTab, setCurrentTab] = useState<number>(0);

    const handleTabSelect = (index: number): void => {
        setCurrentTab(index);
    };

    const handleConfirmContainers = useCallback(async () => {
        if (requests && requests.waitingContainers.length > 0) {
            try {
                await truckLoadingProcessEmptyContainersMutation({
                    variables: {
                        data: {
                            routeId: routeId || '',
                        },
                    },
                });
            } catch {}
        }
    }, [requests, truckLoadingProcessEmptyContainersMutation, routeId]);

    const handleConfirmTruckLoading = useCallback(async () => {
        if (showConfirmationButton) {
            try {
                await truckLoadingProcessConfirmRoute({
                    variables: {
                        data: {
                            routeId: routeId || '',
                        },
                    },
                });
            } catch {}
        }
    }, [truckLoadingProcessConfirmRoute, routeId, showConfirmationButton]);

    if (
        isFetching ||
        retrievedRequestsLoading ||
        truckLoadingProcessItemMutationLoading ||
        truckLoadingProcessEmptyContainersMutationLoading ||
        truckLoadingProcessConfirmRouteMutationLoading
    ) {
        return (
            <View testID="data-truckLoadingProcessItemLoading">
                <Text>
                    <FormattedMessage
                        id="truckLoadingItemProcess.loading"
                        defaultMessage="Loading..."
                    />
                </Text>
            </View>
        );
    }

    if (
        truckLoadingProcessItemMutationError ||
        truckLoadingProcessEmptyContainersMutationError ||
        truckLoadingProcessConfirmRouteMutationError
    ) {
        return (
            <View
                style={truckLoadingItemProcessStyles.ViewErrorMessageParent}
                testID="data-errorMessage"
            >
                <Message
                    type="negative"
                    header={intl.formatMessage({
                        id: 'truckLoadingItemProcess.truckLoadingItemProcessErrorMessage',
                        defaultMessage: 'Could not deliver this item - please try again',
                    })}
                    content={
                        truckLoadingProcessItemMutationError?.message ||
                        truckLoadingProcessEmptyContainersMutationError?.message ||
                        truckLoadingProcessConfirmRouteMutationError?.message
                    }
                    testID="data-truckLoadingItemProcessErrorMessage"
                />
            </View>
        );
    }

    return (
        <ScrollView>
            <View style={truckLoadingItemProcessStyles.ViewParent} testID="data-truckLoadingDetail">
                {!showActionMenu && (
                    <View>
                        <View style={truckLoadingItemProcessStyles.ViewTopBar}>
                            <Button
                                accessoryLeft={() => <Icon icon="back" />}
                                title={intl.formatMessage({
                                    id: 'truckLoadingRouteDetail.back',
                                    defaultMessage: 'Back',
                                })}
                                onPress={handleGoBackPress}
                                appearance="ghost"
                            />
                        </View>

                        <TopTabBar onSelect={handleTabSelect} selectedIndex={currentTab}>
                            {tabs.map((tab) => (
                                <TopTab key={tab.title} title={tab.title} />
                            ))}
                        </TopTabBar>

                        <View style={truckLoadingItemProcessStyles.ViewContent}>
                            <View style={truckLoadingItemProcessStyles.ViewContent}>
                                {showConfirmationButton && (
                                    <View>
                                        <Button
                                            accessoryLeft={() => <Icon icon="pickListCompleted" />}
                                            title={intl.formatMessage({
                                                id: 'truckLoadingRouteDetail.confirmTruckLoading',
                                                defaultMessage: 'Confirm Truck Loading',
                                            })}
                                            onPress={handleConfirmTruckLoading}
                                            appearance="outline"
                                        />
                                    </View>
                                )}

                                {(currentTab === 0 && requests.waiting.length > 0) ||
                                (currentTab === 1 && requests.inTransit.length > 0) ? (
                                    <FlatList
                                        data={
                                            currentTab === 0 ? requests.waiting : requests.inTransit
                                        }
                                        renderItem={({ item }) => (
                                            <RequestDetails
                                                request={item}
                                                onItemClick={handleItemClick}
                                            />
                                        )}
                                        keyExtractor={(item) => item.id}
                                    />
                                ) : (
                                    <Text>
                                        <FormattedMessage
                                            id="truckLoadingItemProcess.noRequestsText"
                                            defaultMessage="There are no requests."
                                        />
                                    </Text>
                                )}
                                {currentTab === 1 && requests.inTransitContainers.length > 0 && (
                                    <FlatList
                                        data={requests.inTransitContainers}
                                        renderItem={({ item }) => (
                                            <RequestDetails
                                                request={item}
                                                onItemClick={handleItemClick}
                                            />
                                        )}
                                        keyExtractor={(item) => item.id}
                                    />
                                )}

                                {currentTab === 0 && requests.waitingContainers.length > 0 && (
                                    <View style={truckLoadingItemProcessStyles.button}>
                                        <Button
                                            title={intl.formatMessage({
                                                id: 'truckLoadingItemProcess.confirmContainers',
                                                defaultMessage: 'Confirm Containers',
                                            })}
                                            onPress={handleConfirmContainers}
                                        />
                                    </View>
                                )}
                            </View>
                        </View>

                        <View
                            style={truckLoadingItemProcessStyles.ViewActionsGroup}
                            testID="data-truckLoadingProcessItemActionsContainer"
                        >
                            {expandBarcodeManualEntry && (
                                <View
                                    style={{
                                        padding: 3,
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                    testID="data-truckLoadingProcessItemManualBarcodeEntryContainer"
                                >
                                    <View style={{ flex: 1, marginRight: 5 }}>
                                        <TextInput
                                            ariaLabel={intl.formatMessage({
                                                id:
                                                    'truckLoadingProcessItemManualBarcodeEntryPlaceholderText',
                                                defaultMessage: 'Enter item barcode',
                                            })}
                                            type="text"
                                            onChange={(e) => setBarcodeEntryValue(e)}
                                            value={barcodeEntryValue}
                                            label={intl.formatMessage({
                                                id:
                                                    'truckLoadingProcessItemManualBarcodeEntryPlaceholderText',
                                                defaultMessage: 'Enter item barcode',
                                            })}
                                            placeholder={intl.formatMessage({
                                                id:
                                                    'truckLoadingProcessItemManualBarcodeEntryPlaceholderText',
                                                defaultMessage: 'Enter item barcode',
                                            })}
                                            testID="data-truckLoadingProcessItemManualBarcodeEntryInput"
                                        />
                                    </View>

                                    <View style={{ marginLeft: 5 }}>
                                        <Button
                                            onPress={handleManualScanSubmit}
                                            title={intl.formatMessage({
                                                id: 'truckLoadingProcessItemScanButton',
                                                defaultMessage: 'SCAN',
                                            })}
                                            testID="data-truckLoadingProcessItemManualBarcodeEntrySubmitButton"
                                        />
                                    </View>
                                </View>
                            )}
                            <View style={{ display: 'flex', flexDirection: 'row' }}>
                                <View style={truckLoadingItemProcessStyles.ViewManualScanIcons}>
                                    {currentItem && (
                                        <Button
                                            accessoryLeft={() => <Icon icon="pickListCancelled" />}
                                            onPress={handleClearScreenPress}
                                            testID="data-truckLoadingProcessItemActionKeyboard"
                                            title={''}
                                            appearance="ghost"
                                            accessibilityLabel={intl.formatMessage({
                                                id: 'truckLoadingProcessItemCancelButton',
                                                defaultMessage: 'Go back',
                                            })}
                                        />
                                    )}

                                    <Button
                                        accessoryLeft={() => <Icon icon="barcodeCamera" />}
                                        onPress={handleManualScanCameraPress}
                                        testID="data-truckLoadingProcessItemActionCamera"
                                        title={''}
                                        appearance="ghost"
                                        accessibilityLabel={intl.formatMessage({
                                            id: 'truckLoadingProcessItemCameraButton',
                                            defaultMessage: 'Open camera to scan item',
                                        })}
                                    />

                                    <Button
                                        accessoryLeft={() => <Icon icon="barcodeKeyboard" />}
                                        onPress={handleManualScanKeyboardPress}
                                        testID="data-truckLoadingProcessItemActionKeyboard"
                                        title={''}
                                        appearance="ghost"
                                        accessibilityLabel={intl.formatMessage({
                                            id: 'truckLoadingProcessItemKeyboardButton',
                                            defaultMessage: 'Open keyboard to enter barcode',
                                        })}
                                    />
                                </View>
                            </View>
                        </View>
                    </View>
                )}

                {showActionMenu && (
                    <View>
                        <View style={{ flex: 1 }}>
                            <View style={truckLoadingItemProcessStyles.ViewDetailGroup}>
                                <Text
                                    style={truckLoadingItemProcessStyles.TruckLoadingHeader}
                                    testID="data-routeStopDetailsDescription"
                                >
                                    {selectedStorageItem?.storageItemType.name}
                                </Text>
                                <Button
                                    onPress={() => {
                                        setShowActionMenu(!showActionMenu);
                                    }}
                                    accessoryLeft={() => <Icon icon="pickListCancelled" />}
                                    title={''}
                                    appearance="ghost"
                                    accessibilityLabel={intl.formatMessage({
                                        id: 'truckLoadingProcessItemCancelButton',
                                        defaultMessage: 'Go back',
                                    })}
                                />
                            </View>
                        </View>
                        <View style={truckLoadingItemProcessStyles.ViewContent}>
                            <FlatList
                                data={menuItems}
                                renderItem={({ item }) => (
                                    <View style={truckLoadingItemProcessStyles.ViewButton}>
                                        <Button
                                            title={intl.formatMessage({
                                                id: item.key,
                                                defaultMessage: item.label,
                                            })}
                                            onPress={() => {
                                                handleMenuItemClick(item.key);
                                            }}
                                        />
                                    </View>
                                )}
                                keyExtractor={(item) => item.key}
                            />
                        </View>
                    </View>
                )}
            </View>
        </ScrollView>
    );
};

const truckLoadingItemProcessStyles = StyleSheet.create({
    ViewParent: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    ViewTabs: {
        marginVertical: 5,
        display: 'flex',
        flexDirection: 'row',
    },
    ViewContent: {
        paddingVertical: 5,
    },
    TextTab: {
        flex: 1,
        color: '#000',
        backgroundColor: '#D4D4D5',
        fontSize: 18,
        textAlign: 'center',
        fontWeight: '700',
    },
    ViewTopBar: {
        paddingBottom: 5,
        display: 'flex',
        flexDirection: 'row' as 'row',
        alignItems: 'center',
        borderBottomWidth: 1,
        borderBottomColor: '#d4d4d5',
    },
    ViewActionsGroup: {
        marginVertical: 5,
        display: 'flex',
        flexDirection: 'column',
        borderTopColor: '#D4D4D5',
        borderTopWidth: 1,
    },
    ViewButton: {
        marginTop: 10,
    },
    CancelAction: {
        borderWidth: 1,
        borderRadius: 5,
        padding: 5,
    },
    ViewDetailGroup: {
        marginVertical: 2,
        display: 'flex',
        flexDirection: 'row',
    },
    TruckLoadingHeader: {
        fontWeight: '700',
        flex: 1,
    },

    ViewExpandPickList: {
        flex: 1,
        paddingVertical: 5,
        display: 'flex',
        overflowY: 'scroll',
        overflowX: 'none',
    },
    ViewExpandPickListText: {
        marginVertical: 5,
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
    },
    ViewManualScanIcons: {
        marginVertical: 5,
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    ViewActions: {
        marginVertical: 5,
        display: 'flex',
        flexDirection: 'row',
    },
    ViewNavigationButtons: {
        flex: 1,
        marginRight: 5,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    TextActionList: {
        alignSelf: 'flex-start',
        color: '#0984e3',
    },
    TextCurrentItemNumber: {
        fontWeight: '700',
        fontSize: 18,
        textAlign: 'center',
    },
    ViewItem: {
        flex: 1,
    },
    TextHeader: {
        fontWeight: '700',
    },
    ViewErrorMessageParent: {
        marginTop: 5,
        marginBottom: 5,
    },
    buttonContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    button: {
        width: '40%',
        height: 40,
    },
});

type RequestDetailsProps = {
    request:
        | TruckLoadingDeliverProcessDeliverStorageItemRequestFragment
        | TruckLoadingDeliverProcessDeliverEmptyContainerRequestFragment;
    onItemClick: (id: string) => void;
};

const RequestDetails: React.FC<RequestDetailsProps> = ({ request, onItemClick }) => {
    console.log(request);
    const { storageItem, status, customer } = request;

    return (
        <Card
            onPress={() => onItemClick(request.id)}
            style={RequestDetailsStyles.ViewParent}
            testID="data-requestDetails"
        >
            <View style={RequestDetailsStyles.ViewDetailGroup}>
                <Image
                    style={RequestDetailsStyles.ImageItem}
                    source={{ uri: storageItem?.storageItemType?.image }}
                    testID="data-RequestDetailsImage"
                />
                <View>
                    <View style={RequestDetailsStyles.ViewDetailGroup}>
                        <Text
                            style={RequestDetailsStyles.TextItemValue}
                            testID="data-RequestDetailsDescription"
                        >
                            {storageItem?.storageItemType?.description}
                        </Text>
                    </View>
                    <View style={RequestDetailsStyles.ViewDetailGroup}>
                        <Text
                            style={RequestDetailsStyles.CustomerTextItemLabel}
                            testID="data-RequestDetailsLocation"
                        >
                            <FormattedMessage
                                id="requestDetails.Location"
                                defaultMessage="Location: "
                            />
                        </Text>
                        <Text style={RequestDetailsStyles.TextItemValue}>
                            {storageItem?.location?.description}
                        </Text>
                    </View>
                    <View style={RequestDetailsStyles.ViewDetailGroup}>
                        <Text
                            style={RequestDetailsStyles.CustomerTextItemLabel}
                            testID="data-RequestDetailsLocation"
                        >
                            <FormattedMessage
                                id="requestDetails.customer"
                                defaultMessage="Customer: "
                            />
                        </Text>
                        <Text
                            style={RequestDetailsStyles.TextItemValue}
                        >{`${customer?.firstName} ${customer?.lastName}`}</Text>
                    </View>
                    <View style={RequestDetailsStyles.ViewDetailGroup}>
                        <Text
                            style={RequestDetailsStyles.CustomerTextItemLabel}
                            testID="data-RequestDetailsLocation"
                        >
                            <FormattedMessage
                                id="requestDetails.barcode"
                                defaultMessage="Barcode"
                            />
                            :
                        </Text>
                        <Text style={RequestDetailsStyles.TextItemValue}>
                            {storageItem?.barcode}
                        </Text>
                    </View>
                    <View style={RequestDetailsStyles.ViewDetailGroup}>
                        <Text
                            style={RequestDetailsStyles.CustomerTextItemLabel}
                            testID="data-RequestDetailsLocation"
                        >
                            <FormattedMessage
                                id="requestDetails.customer"
                                defaultMessage="Status: "
                            />
                        </Text>
                        <Text style={RequestDetailsStyles.TextItemValue}>{status}</Text>
                    </View>
                </View>
            </View>
        </Card>
    );
};

const RequestDetailsStyles = StyleSheet.create({
    ViewParent: {
        marginVertical: 3,
    },
    ViewDetailGroup: {
        marginVertical: 2,
        display: 'flex',
        flexDirection: 'row',
    },
    ViewImageGroup: {
        marginVertical: 5,
        display: 'flex',
        justifyContent: 'center',
    },
    TextDetailHeader: {
        fontWeight: '700',
    },
    ImageItem: {
        height: 40,
        width: 40,
        resizeMode: 'stretch',
        marginLeft: 5,
        marginRight: 10,
    },

    ViewItemsList: {
        marginVertical: 5,
    },
    ViewItem: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
    },
    ViewItemsSummary: {
        marginVertical: 5,
    },
    ViewAssignee: {
        marginVertical: 5,
    },
    TextHeader: {
        fontWeight: '700',
    },
    TextItemLabel: {
        flex: 1,
    },
    CustomerTextItemLabel: {
        flex: 0,
        marginRight: 5,
    },
    TextItemValue: {
        flex: 1,
        fontWeight: '700',
    },
    TouchableOpacityItem: {
        borderWidth: 1,
        borderRadius: 5,
        padding: 5,
    },
    TextDescription: {
        fontWeight: '700',
    },
    ViewButton: {},
});
