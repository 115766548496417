import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { format } from 'date-fns';
import { gql } from '@apollo/client';

import { Card, TopTabBar, TopTab } from '@valet/ui-components';
import { View, Text, FlatList, StyleSheet } from 'react-native';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    useGetPickListsQuery,
    PickListStatus,
    RetrievePickListsPickListFragment,
} from '../../../schema-types';

export const pickListsSize = 100;

const RETRIEVE_PICKLISTS_PICKLIST_FRAGMENT = gql`
    fragment RetrievePickListsPickList on PickList {
        id
        pickListNumber
        status
        created_at
        description
        summary {
            toBePicked
            picked
            excluded
        }
    }
`;

export const RETRIEVE_PICKLISTS_QUERY = gql`
    query GetPickLists($pageSize: Int!, $cursor: String!) {
        pickLists(filter: { pageSize: $pageSize, after: $cursor }) {
            edges {
                cursor
                node {
                    id
                    ...RetrievePickListsPickList
                }
            }
            pageInfo {
                cursor {
                    beforeCursor
                    afterCursor
                }
                hasNextPage
                hasPreviousPage
            }
        }
    }

    ${RETRIEVE_PICKLISTS_PICKLIST_FRAGMENT}
`;

type PickListSelectionProps = {
    onPickListPress: (id: string) => void;
};

export const PickListSelection: React.FC<PickListSelectionProps> = ({ onPickListPress }) => {
    const intl = useIntl();

    const { data, loading, error, fetchMore, refetch } = useGetPickListsQuery({
        variables: {
            pageSize: pickListsSize,
            cursor: '',
        },
    });

    const [currentTab, setCurrentTab] = useState<number>(0);
    const [isFetching, setIsFetching] = useState<boolean>(false);

    // Refetch pick list data after navigating to this page (ex: after completing a pick list)
    useEffect(() => {
        if (data) {
            refetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const pickListsData = useMemo(() => {
        const pickListsEdges = data?.pickLists.edges ?? [];
        return {
            pickLists: pickListsEdges.map((pickListEdge) => pickListEdge.node),
            hasNextPage: data?.pickLists.pageInfo.hasNextPage ?? false,
            endCursor: data?.pickLists.pageInfo.cursor.afterCursor ?? '',
        };
    }, [data]);

    const handleLoadMoreItems = useCallback((): void => {
        if (data?.pickLists.pageInfo.hasNextPage && !isFetching) {
            setIsFetching(true);

            fetchMore({
                variables: {
                    pageSize: pickListsSize,
                    cursor: data.pickLists.pageInfo.cursor.afterCursor,
                },
            }).then(() => {
                setIsFetching(false);
            });
        }
    }, [data, fetchMore, isFetching]);

    useEffect(() => {
        if (!error && data?.pickLists.pageInfo.hasNextPage) {
            handleLoadMoreItems();
        }
    }, [data, error, handleLoadMoreItems]);

    const pickLists = useMemo<{
        completed: RetrievePickListsPickListFragment[];
        todo: RetrievePickListsPickListFragment[];
    }>(
        () => ({
            completed: [
                ...pickListsData.pickLists.filter(
                    (item) => item.status === PickListStatus.Completed,
                ),
            ],
            todo: [
                ...pickListsData.pickLists.filter(
                    (item) =>
                        item.status === PickListStatus.Created ||
                        item.status === PickListStatus.InProgress,
                ),
            ],
        }),
        [pickListsData.pickLists],
    );

    return (
        <View style={{ display: 'flex', flexDirection: 'column' }} testID="data-pickListSelection">
            {error && (
                <Text testID="data-pickListSelectionErrorText">
                    <FormattedMessage
                        id="pickListSelection.errorText"
                        defaultMessage="There was an error - please reload this page."
                    />
                </Text>
            )}

            {loading && (
                <Text testID="data-pickListSelectionLoadingText">
                    <FormattedMessage
                        id="pickListSelection.loadingText"
                        defaultMessage="Loading..."
                    />
                </Text>
            )}

            {data && (
                <View testID="data-pickListSelectionDataView">
                    <TopTabBar
                        selectedIndex={currentTab}
                        onSelect={(index) => setCurrentTab(index)}
                    >
                        <TopTab
                            title={intl.formatMessage({
                                id: 'pickListSelection.toDoTabText',
                                defaultMessage: 'To Do',
                            })}
                            testID="data-pickListSelectionTodoTab"
                        />
                        <TopTab
                            title={intl.formatMessage({
                                id: 'pickListSelection.completedTabText',
                                defaultMessage: 'Completed',
                            })}
                            testID="data-pickListSelectionCompletedTab"
                        />
                    </TopTabBar>

                    <View style={pickListSelectionStyles.ViewContent}>
                        {currentTab === 1 ? (
                            pickLists.completed.length > 0 ? (
                                <FlatList
                                    data={pickLists.completed}
                                    renderItem={({ item }) => (
                                        <PickListItem
                                            details={item}
                                            onPickListPress={onPickListPress}
                                        />
                                    )}
                                    keyExtractor={(item) => item.pickListNumber.toString()}
                                />
                            ) : (
                                <Text>
                                    <FormattedMessage
                                        id="pickListSelection.noCompletedPickListsText"
                                        defaultMessage="No completed pick lists."
                                    />
                                </Text>
                            )
                        ) : pickLists.todo.length > 0 ? (
                            <FlatList
                                data={pickLists.todo}
                                renderItem={({ item }) => (
                                    <PickListItem
                                        details={item}
                                        onPickListPress={onPickListPress}
                                    />
                                )}
                                keyExtractor={(item) => item.pickListNumber.toString()}
                            />
                        ) : (
                            <Text>
                                <FormattedMessage
                                    id="pickListSelection.noToDoPickListsText"
                                    defaultMessage="No pick lists to do."
                                />
                            </Text>
                        )}
                    </View>
                </View>
            )}
        </View>
    );
};

const pickListSelectionStyles = StyleSheet.create({
    ViewTabs: {
        marginVertical: 5,
        display: 'flex',
        flexDirection: 'row',
    },
    ViewContent: {},
    TextTab: {
        flex: 1,
        color: '#000',
        backgroundColor: '#D4D4D5',
        fontSize: 18,
        textAlign: 'center',
        fontWeight: '700',
    },
});

type PickListItemProps = {
    details: RetrievePickListsPickListFragment;
    onPickListPress: (id: string) => void;
};

const PickListItem: React.FC<PickListItemProps> = ({ details, onPickListPress }) => {
    const intl = useIntl();

    const PickListStatusMap: Record<string, string> = {
        CREATED: intl.formatMessage({
            id: 'pickListSelectionItem.statusMapCreated',
            defaultMessage: 'Created',
        }),
        IN_PROGRESS: intl.formatMessage({
            id: 'pickListSelectionItem.statusMapInProgress',
            defaultMessage: 'In Progress',
        }),
        COMPLETED: intl.formatMessage({
            id: 'pickListSelectionItem.statusMapCompleted',
            defaultMessage: 'Completed',
        }),
        CANCELLED: intl.formatMessage({
            id: 'pickListSelectionItem.statusMapCancelled',
            defaultMessage: 'Cancelled',
        }),
    };

    const {
        id,
        pickListNumber,
        description,
        status,
        // eslint-disable-next-line @typescript-eslint/camelcase
        created_at,
        summary: { toBePicked, picked, excluded },
    } = details;

    return (
        <View style={pickListItemStyles.ViewParent}>
            <Card
                onPress={() => onPickListPress(id)}
                style={pickListItemStyles.TouchableOpacityItem}
                testID="data-pickListItem"
            >
                <Text style={pickListItemStyles.TextDescription}>
                    <FormattedMessage
                        id="pickListSelectionItem.descriptionText"
                        defaultMessage={`{description}`}
                        values={{ description: description || `Pick List ${pickListNumber}` }}
                    />
                </Text>

                {/* TODO: Get assignees from back-end */}
                <Text>
                    <FormattedMessage
                        id="pickListSelectionItem.assigneeText"
                        defaultMessage={`Assignee: {assignee}`}
                        values={{ assignee: 'Radiun Huq, Maurice Israel, Amin Ramin' }}
                    />
                </Text>
                <Text>
                    <FormattedMessage
                        id="pickListSelectionItem.itemsPickedText"
                        defaultMessage={`{pickedItemsLength}/{itemsLength} items picked`}
                        values={{
                            pickedItemsLength: picked,
                            itemsLength: toBePicked + picked + excluded,
                        }}
                    />
                </Text>

                <Text>
                    <FormattedMessage
                        id="pickListSelectionItem.statusText"
                        defaultMessage={`Status: {status}`}
                        values={{
                            status: PickListStatusMap[status],
                        }}
                    />
                </Text>
                <Text>
                    <FormattedMessage
                        id="pickListSelectionItem.createdText"
                        defaultMessage={`Created {createdDate}`}
                        values={{
                            createdDate: intl.formatDate(
                                format(new Date(created_at), 'MMMM dd yyyy h:mm aa'),
                                {
                                    year: 'numeric',
                                    month: 'numeric',
                                    day: 'numeric',
                                },
                            ),
                        }}
                    />
                </Text>
            </Card>
        </View>
    );
};

const pickListItemStyles = StyleSheet.create({
    ViewParent: {
        margin: 5,
    },
    TouchableOpacityItem: {
        borderWidth: 1,
        borderRadius: 5,
    },
    TextDescription: {
        fontWeight: '700',
    },
});
