import { gql } from '@apollo/client';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ScrollView, StyleSheet, Text, View } from 'react-native';
import { Button, Card, Message } from '@valet/ui-components';
import { useGetAreasQuery, AdminAreaFragment } from '../../../../schema-types';
import { useHistory } from 'react-router-dom';

const AREA_FRAGMENT = gql`
    fragment adminArea on Area {
        id
        code
    }
`;

export const RETRIEVE_AREA_QUERY = gql`
    query getAreas {
  areas{
    ...adminArea
  }
}
    ${AREA_FRAGMENT}
`;


type AreaPageProps = {
    onAreaSelect: (routeId: string) => void;
};

export const AreaPage: React.FC<AreaPageProps> = ({ onAreaSelect }) => {

    const {
        data: retrievedAreaData,
        loading: retrievedAreaLoading,
        error: retrievedAreaError,
    } = useGetAreasQuery();

    const areasData = retrievedAreaData?.areas ? retrievedAreaData?.areas[0] as AdminAreaFragment : null;

    const intl = useIntl();
    const history = useHistory();

    const handleBacklClick = useCallback(async () => {
        history.push(`/admin`);
    }, [history]);

    return (
        <ScrollView>
            <View style={areaPageStyles.ViewParent} testID="data-delivery">
                <View style={areaPageStyles.ViewTabs}>
                    <View>
                        <View style={{ flex: 1, marginRight: 2 }}>
                            <Button
                                title={intl.formatMessage({
                                    id: 'area.backButton',
                                    defaultMessage: 'Back',
                                })}
                                onPress={handleBacklClick}
                            />
                        </View>
                    </View>
                </View>
                {retrievedAreaLoading &&
                    (
                        <View testID="data-areaItemLoading">
                            <Text>
                                <FormattedMessage
                                    id="area.loading"
                                    defaultMessage="Loading..."
                                />
                            </Text>
                        </View>
                    )
                }
                {retrievedAreaError &&
                    (
                        <Message
                            type="negative"
                            header={intl.formatMessage({
                                id: 'area.areaErrorMessage',
                                defaultMessage: 'Could not load detail for the area - please try again',
                            })}
                            content={retrievedAreaError.message}
                            testID="data-areaErrorMessage"
                        />
                    )
                }
                <Card
                    onPress={() => onAreaSelect(areasData?.id || '')}
                    style={areaPageStyles.ViewParent}
                    testID="data-deliveryDetail"

                >
                    <View style={areaPageStyles.ViewChild}>
                        <View style={areaPageStyles.ViewContentParent}>
                            <View style={areaPageStyles.ViewTitleParent}>
                                <Text style={areaPageStyles.TextTitle} testID="data-areaItemDescription">
                                    {`${intl.formatMessage({
                                        id: 'areaItem.AreaCode',
                                        defaultMessage: 'Code: ',
                                    })}${areasData?.code}`}
                                </Text>
                            </View>
                        </View>
                    </View>

                </Card>

            </View>
        </ScrollView>
    );
};

const areaPageStyles = StyleSheet.create({
    ViewParent: {
        display: 'flex',
        flexDirection: 'column',
    },
    ViewTabs: {
        marginVertical: 5,
        display: 'flex',
        flexDirection: 'row',
    },
    ViewContent: {
        paddingVertical: 5,
    },
    TextTab: {
        flex: 1,
        color: '#000',
        backgroundColor: '#D4D4D5',
        fontSize: 18,
        textAlign: 'center',
        fontWeight: '700',
    },

    ViewChild: {
        display: 'flex',
        flexDirection: 'row',
    },
    ViewImageParent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    RouteImage: {
        height: 40,
        width: 40,
        resizeMode: 'stretch',
    },
    ViewContentParent: {
        paddingHorizontal: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    ViewTitleParent: {
        display: 'flex',
        flexDirection: 'column',
    },
    ViewTitleChild: {},
    TextTitle: {
        fontSize: 18,
        fontWeight: 'bold',
    },
    ViewDescription: {},
    TextDescription: { fontSize: 14 },
    ViewStatus: {},
    TextStatus: {
        fontSize: 12,
        fontWeight: '700',
    },
    ViewDate: {},
    TextDate: {
        fontSize: 12,
    },
    ViewErrorMessageParent: {
        marginTop: 5,
        marginBottom: 5,
    },
});


