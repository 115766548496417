import { gql } from '@apollo/client';
import React, { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ScrollView, StyleSheet, Text, View } from 'react-native';
import { Button, Message, Select, SelectItem, TextInput } from '@valet/ui-components';
import {
    useAdminAddTerritoryMutation,
    AdminAddTerritoryInput,
    useGetAdminAddTerritoryAreasQuery,
    AdminAddTerritoryAreaFragment,
} from '../../../../schema-types';
import { useHistory } from 'react-router-dom';

const AREA_FRAGMENT = gql`
    fragment adminAddTerritoryArea on Area {
        id
        code
    }
`;

export const RETRIEVE_AREA_QUERY = gql`
    query getAdminAddTerritoryAreas {
        areas {
            ...adminTerritoryArea
        }
    }
    ${AREA_FRAGMENT}
`;

const TERRITORY_FRAGMENT = gql`
    fragment adminAddTerritory on Territory {
        id
        description
        maximumRequestQuantity
        deliveryCutoff
        deliveryDelay
        pickupCutoff
        pickupDelay
        zipCodes
        area {
            id
            code
        }
    }
`;

export const ADMIN_ADD_TERRITORY_MUTATION = gql`
    mutation adminAddTerritory($data: AdminAddTerritoryInput!) {
        adminAddTerritory(data: $data) {
            data {
                ...adminAddTerritory
            }
        }
    }

    ${TERRITORY_FRAGMENT}
`;

type AddTerritoryPageProps = {
    onTerritoryAdded: () => void;
};

export const AddTerritoryPage: React.FC<AddTerritoryPageProps> = ({ onTerritoryAdded }) => {
    const history = useHistory();
    const intl = useIntl();

    const [selectIndex] = useState<number>(0);

    const { data: retrievedAreaData } = useGetAdminAddTerritoryAreasQuery();

    const areasData = retrievedAreaData?.areas
        ? (retrievedAreaData?.areas as AdminAddTerritoryAreaFragment[])
        : [];

    const [newTerritory, setNewTerritory] = useState<AdminAddTerritoryInput>({
        description: '',
        maximumRequestQuantity: 0,
        deliveryCutoff: 0,
        deliveryDelay: 0,
        pickupCutoff: 0,
        pickupDelay: 0,
        areaId: '',
        zipCodes: [],
    });

    const [
        adminAddTerritoryMutation,
        {
            loading: adminAddTerritoryDetailMutationLoading,
            error: adminAddTerritoryDetailMutationError,
        },
    ] = useAdminAddTerritoryMutation({
        onCompleted(data: any) {
            if (data) {
                onTerritoryAdded();
            }
        },
    });

    const handleAddTerritoryClick = useCallback(async () => {
        if (newTerritory) {
            try {
                await adminAddTerritoryMutation({
                    variables: {
                        data: {
                            ...newTerritory,
                        },
                    },
                });
            }
            catch (ignore) { }
        }

    }, [adminAddTerritoryMutation, newTerritory]);

    const handleBacklClick = useCallback(async () => {
        history.push(`/admin/territories`);
    }, [history]);

    const selectItems: { id: string; title: string }[] = [];
    areasData.map((item) =>
        selectItems.push({
            id: item.id,
            title: item.code,
        }),
    );

    if (adminAddTerritoryDetailMutationError) {
        return (
            <View style={newTerritoryPageStyles.ViewErrorMessageParent} testID="data-errorMessage">
                <Message
                    type="negative"
                    header={intl.formatMessage({
                        id: 'AddTerritory.addTerritoryErrorMessage',
                        defaultMessage: 'Could not add newTerritory - please try again',
                    })}
                    content={adminAddTerritoryDetailMutationError.message}
                    testID="data-AddTerritoryErrorMessage"
                />
            </View>
        );
    }

    return (
        <ScrollView>
            {adminAddTerritoryDetailMutationLoading ?
                <View testID="data-AddTerritoryItemLoading">
                    <Text>
                        <FormattedMessage id="AddTerritory.loading" defaultMessage="Loading..." />
                    </Text>
                </View>
                :
                <View style={newTerritoryPageStyles.ViewParent} testID="data-AddTerritory">
                    <View style={newTerritoryPageStyles.ViewChild}>
                        <View style={newTerritoryPageStyles.ViewContentParent}>
                            <View style={newTerritoryPageStyles.ViewTitleParent}>
                                <Text
                                    style={newTerritoryPageStyles.TextTitle}
                                    testID="data-newTerritory"
                                >
                                    {`${intl.formatMessage({
                                        id: 'newTerritoryDetail.id',
                                        defaultMessage: 'Add New Territory: ',
                                    })}${''}`}
                                </Text>
                            </View>
                            <View style={newTerritoryPageStyles.ViewTitleParent}>
                                <View>
                                    <FormattedMessage
                                        id="newTerritoryDetail.description"
                                        defaultMessage="Description"
                                    />
                                    <TextInput
                                        type="text"
                                        ariaLabel="Description"
                                        value={newTerritory?.description}
                                        onChange={(value: string) => {
                                            setNewTerritory({ ...newTerritory, description: value });
                                        }}
                                    />
                                </View>
                                <View style={newTerritoryPageStyles.ViewDescription}>
                                    <View>
                                        <FormattedMessage
                                            id="newTerritoryDetail.maximumRequestQuantity"
                                            defaultMessage="MaximumRequestQuantity"
                                        />
                                        <TextInput
                                            type="text"
                                            ariaLabel="MaximumRequestQuantity"
                                            value={newTerritory?.maximumRequestQuantity || 0}
                                            onChange={(value: any) => {
                                                setNewTerritory({
                                                    ...newTerritory,
                                                    maximumRequestQuantity: value ? parseInt(value) : 0,
                                                });
                                            }}
                                        />
                                    </View>
                                </View>
                                <View style={newTerritoryPageStyles.ViewDescription}>
                                    <View>
                                        <FormattedMessage
                                            id="newTerritoryDetail.deliveryCutoff"
                                            defaultMessage="DeliveryCutoff"
                                        />
                                        <TextInput
                                            type="text"
                                            ariaLabel="DeliveryCutoff"
                                            value={newTerritory?.deliveryCutoff || 0}
                                            onChange={(value: any) => {
                                                setNewTerritory({
                                                    ...newTerritory,
                                                    deliveryCutoff: value ? parseInt(value) : 0,
                                                });
                                            }}
                                        />
                                    </View>
                                </View>
                                <View style={newTerritoryPageStyles.ViewDescription}>
                                    <FormattedMessage
                                        id="newTerritoryDetail.Area"
                                        defaultMessage="Area"
                                    />
                                    <Select
                                        currentIndex={selectIndex}
                                        itemsDisplayValues={selectItems.map((items) => items.title)}
                                        onSelect={(index) =>
                                            setNewTerritory({
                                                ...newTerritory,
                                                areaId: selectItems[index].id,
                                            })
                                        }
                                    >
                                        {selectItems.map((item) => (
                                            <SelectItem title={item.title} key={item.id} />
                                        ))}
                                    </Select>
                                </View>
                                <View style={newTerritoryPageStyles.ViewDescription}>
                                    <View>
                                        <FormattedMessage
                                            id="newTerritoryDetail.deliveryDelay"
                                            defaultMessage="DeliveryDelay"
                                        />
                                        <TextInput
                                            type="text"
                                            ariaLabel="DeliveryDelay"
                                            value={newTerritory?.deliveryDelay || 0}
                                            onChange={(value: any) => {
                                                setNewTerritory({
                                                    ...newTerritory,
                                                    deliveryDelay: value ? parseInt(value) : 0,
                                                });
                                            }}
                                        />
                                    </View>
                                </View>
                                <View style={newTerritoryPageStyles.ViewDescription}>
                                    <View>
                                        <FormattedMessage
                                            id="newTerritoryDetail.pickupCutoff"
                                            defaultMessage="PickupCutoff"
                                        />
                                        <TextInput
                                            type="text"
                                            ariaLabel="PeliveryCutoff"
                                            value={newTerritory?.pickupCutoff || 0}
                                            onChange={(value: any) => {
                                                setNewTerritory({
                                                    ...newTerritory,
                                                    pickupCutoff: value ? parseInt(value) : 0,
                                                });
                                            }}
                                        />
                                    </View>
                                </View>
                                <View style={newTerritoryPageStyles.ViewDescription}>
                                    <View>
                                        <FormattedMessage
                                            id="newTerritoryDetail.pickupDelay"
                                            defaultMessage="PickupDelay"
                                        />
                                        <TextInput
                                            type="text"
                                            ariaLabel="PickupDelay"
                                            value={newTerritory?.pickupDelay || 0}
                                            onChange={(value: any) => {
                                                setNewTerritory({
                                                    ...newTerritory,
                                                    pickupDelay: value ? parseInt(value) : 0,
                                                });
                                            }}
                                        />
                                    </View>
                                </View>
                                <View style={newTerritoryPageStyles.ViewDescription}>
                                    <View>
                                        <FormattedMessage
                                            id="territoryDetail.zipCodes"
                                            defaultMessage="ZipCodes"
                                        />
                                        <TextInput
                                            type="text"
                                            ariaLabel="ZipCodes"
                                            multiline={true}
                                            value={newTerritory?.zipCodes?.join('\r\n') || ''}
                                            onChange={(value: string) => {
                                                setNewTerritory({
                                                    ...newTerritory,
                                                    zipCodes: value.split('\n'),
                                                });
                                            }}
                                        />
                                    </View>
                                </View>
                            </View>
                        </View>
                    </View>
                    <View
                        style={{
                            marginVertical: 10,
                            marginHorizontal: 10,
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        <View style={{ flex: 1, marginRight: 10 }}>
                            <Button
                                title={intl.formatMessage({
                                    id: 'newTerritoryDetail.Cancel',
                                    defaultMessage: 'Cancel',
                                })}
                                onPress={handleBacklClick}
                            />
                        </View>
                        <View style={{ flex: 1 }}>
                            <Button
                                title={intl.formatMessage({
                                    id: 'newTerritoryDetail.Add',
                                    defaultMessage: 'Add',
                                })}
                                onPress={handleAddTerritoryClick}
                            />
                        </View>
                    </View>
                </View>
            }
        </ScrollView>
    );
};

const newTerritoryPageStyles = StyleSheet.create({
    ViewParent: {
        display: 'flex',
        flexDirection: 'column',
    },
    ViewTabs: {
        marginVertical: 5,
        display: 'flex',
        flexDirection: 'row',
    },
    ViewContent: {
        paddingVertical: 5,
    },
    TextTab: {
        flex: 1,
        color: '#000',
        backgroundColor: '#D4D4D5',
        fontSize: 18,
        textAlign: 'center',
        fontWeight: '700',
    },

    ViewChild: {
        display: 'flex',
        flexDirection: 'row',
    },
    ViewImageParent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    RouteImage: {
        height: 40,
        width: 40,
        resizeMode: 'stretch',
    },
    ViewContentParent: {
        paddingHorizontal: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    ViewTitleParent: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 5,
    },
    ViewTitleChild: {},
    TextTitle: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 10,
    },
    ViewDescription: {
        marginTop: 10,
        fontWeight: 'bold',
    },
    TextDescription: { fontSize: 14 },
    ViewStatus: {},
    TextStatus: {
        fontSize: 12,
        fontWeight: '700',
    },
    ViewDate: {},
    TextDate: {
        fontSize: 12,
    },
    ViewErrorMessageParent: {
        marginTop: 5,
        marginBottom: 5,
    },
    TextContainerItem: {
        marginVertical: 2,
        display: 'flex',
        flexDirection: 'row',
        fontWeight: 'bold',
    },
});
