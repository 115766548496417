import React, { useEffect, useState, useCallback, useLayoutEffect, useMemo } from 'react';

import { Button, TopTabBar, TopTab } from '@valet/ui-components';
import { View, Text, ScrollView } from 'react-native';
import { VisitReviewMap } from './Map/Map.web';
import { useIntl, FormattedMessage } from 'react-intl';
import { visitsPageSize } from './VisitReviewPageQueries';
import { VisitRequest } from './VisitRequest';
import {
    useRetrievePendingVisitsQuery,
    useApproveVisitMutation,
    VisitStatus,
    VisitReviewVisitRequestScheduleWindowFragment,
} from '../../../schema-types';

type VisitReviewPageProps = {};

export const VisitReviewPage: React.FC<VisitReviewPageProps> = () => {
    return (
        <View
            testID="data-visitReviewPage"
            style={{ flexDirection: 'row', flex: 1, height: '100%' }}
        >
            <VisitReviewScaffold />
        </View>
    );
};

type VisitReviewScaffoldProps = {};

const VisitReviewScaffold: React.FC<VisitReviewScaffoldProps> = () => {
    const {
        data: visitsDataData,
        loading: visitsDataLoading,
        error: visitsDataError,
        refetch: visitsDataRefetch,
        fetchMore: visitsDataFetchMore,
    } = useRetrievePendingVisitsQuery({
        variables: {
            pageSize: visitsPageSize,
            cursor: '',
        },
    });
    const [isFetchingVisits, setIsFetchingVisits] = useState<boolean>(false);

    const [
        approveVisit,
        { loading: approveVisitLoading, error: approveVisitError },
    ] = useApproveVisitMutation({
        errorPolicy: 'all',
    });

    const intl = useIntl();

    const [mobileView, setMobileView] = useState<boolean>(false);

    useLayoutEffect(() => {
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);

        function updateSize(): void {
            const isMobileView = window.innerWidth < 700;
            if (mobileView !== isMobileView) {
                setMobileView(isMobileView);
            }
        }
    }, [mobileView]);

    const [currentTab, setCurrentTab] = useState<number>(0);
    const [currentVisitIndex, setCurrentVisitIndex] = useState<number>(0);
    const [scheduleWindowsForDate, setScheduleWindowsForDate] = useState<
        VisitReviewVisitRequestScheduleWindowFragment[] | undefined
    >(undefined);
    const [selectedScheduleWindowId, setSelectedScheduleWindowId] = useState<string | undefined>(
        undefined,
    );

    // START VISITS PAGINATION
    const visitsDataEdges = visitsDataData?.visits.edges ?? [];
    const visitsData = useMemo(() => {
        return {
            visits: visitsDataEdges.map((visitEdge) => visitEdge.node),
            hasNextPage: visitsDataData?.visits.pageInfo.hasNextPage ?? false,
            endCursor: visitsDataData?.visits.pageInfo.cursor.afterCursor ?? '',
        };
    }, [visitsDataData, visitsDataEdges]);

    const handleLoadMoreVisits = useCallback((): void => {
        if (
            visitsDataData?.visits.pageInfo.hasNextPage &&
            !isFetchingVisits &&
            visitsDataFetchMore
        ) {
            setIsFetchingVisits(true);

            visitsDataFetchMore({
                variables: {
                    pageSize: visitsPageSize,
                    cursor: visitsDataData.visits.pageInfo.cursor.afterCursor,
                },
            }).then(() => {
                setIsFetchingVisits(false);
            });
        }
    }, [isFetchingVisits, visitsDataData, visitsDataFetchMore]);

    useEffect(() => {
        if (!visitsDataError && visitsDataData?.visits.pageInfo.hasNextPage) {
            handleLoadMoreVisits();
        }
    }, [visitsDataError, visitsDataData, handleLoadMoreVisits]);
    // END VISITS PAGINATION

    const currentVisitsDataArr =
        currentTab === 0
            ? visitsData.visits.filter((visit) => visit.status === VisitStatus.Pending)
            : visitsData.visits.filter((visit) => visit.status !== VisitStatus.Pending);
    const currentVisitData = currentVisitsDataArr[currentVisitIndex] ?? undefined;

    if (visitsDataLoading) {
        return (
            <View testID="data-visitReviewPageDataLoading">
                <Text>
                    <FormattedMessage
                        id="visitReviewPage.loadingData"
                        defaultMessage="Loading..."
                    />
                </Text>
            </View>
        );
    }

    if (visitsDataError) {
        return (
            <View testID="data-visitReviewPageDataError">
                <Text>
                    <FormattedMessage
                        id="visitReviewPage.errorLoadingData"
                        defaultMessage="There was an error - please reload this page"
                    />
                </Text>
            </View>
        );
    }

    const scheduleWindowsForDateMap: string[] =
        scheduleWindowsForDate?.map((scheduleWindow) => scheduleWindow.id) ??
        currentVisitsDataArr.map((visit) => visit.scheduleWindow.id);

    const visitsByScheduleWindowMap = currentVisitsDataArr.reduce((acc, curr) => {
        if (scheduleWindowsForDateMap?.includes(curr.scheduleWindow.id)) {
            acc[curr.scheduleWindow.id] = [...(acc[curr.scheduleWindow.id] ?? []), curr];
        }

        return acc;
    }, {} as { [key: string]: typeof currentVisitsDataArr });

    // Top tabs
    const tabs = [
        {
            title: intl.formatMessage(
                { id: 'visitReviewPage.tabTitleNew', defaultMessage: 'New ({requests})' },
                { requests: currentVisitsDataArr.length },
            ),
        },
        {
            title: intl.formatMessage({
                id: 'visitReviewPage.tabTitleHistory',
                defaultMessage: 'History',
            }),
        },
    ];

    const handleTabSelect = (index: number): void => {
        setCurrentVisitIndex(0);
        setCurrentTab(index);
    };

    const handlePrevPress = (): void => {
        if (currentVisitIndex > 0) {
            setCurrentVisitIndex(currentVisitIndex - 1);
        }
    };

    const handleNextPress = (): void => {
        if (currentVisitIndex < currentVisitsDataArr.length - 1) {
            setCurrentVisitIndex(currentVisitIndex + 1);
        }
    };

    const handleScheduleWindowsForDateChange = (
        scheduleWindows: VisitReviewVisitRequestScheduleWindowFragment[] | undefined,
    ): void => {
        setScheduleWindowsForDate(scheduleWindows);
    };

    const handleSelectedScheduleWindowIdChange = (id: string | undefined): void => {
        setSelectedScheduleWindowId(id);
    };

    const handleConfirmRequestPress = (visitDetails: {
        visitId: string;
        estimatedDuration: number;
        scheduleWindowId?: string;
        scheduleWindowDate?: Date;
    }): void => {
        approveVisit({
            variables: {
                details: visitDetails,
            },
        })
            .then(() => {
                visitsDataRefetch();
            })
            .catch((err) => console.log(err));
    };

    return (
        <View
            style={{ width: '100%', display: 'flex', flexDirection: mobileView ? 'column' : 'row' }}
            testID="data-visitReviewPageData"
        >
            <View
                style={{
                    paddingHorizontal: 5,
                    flex: 1,
                    paddingBottom: mobileView ? 10 : 0,
                }}
            >
                <TopTabBar onSelect={handleTabSelect} selectedIndex={currentTab}>
                    {tabs.map((tab) => (
                        <TopTab key={tab.title} title={tab.title} />
                    ))}
                </TopTabBar>

                <VisitRequestsNavigation
                    current={currentVisitIndex}
                    total={currentVisitsDataArr.length}
                    hasPrev={currentVisitIndex > 0}
                    hasNext={currentVisitIndex < currentVisitsDataArr.length - 1}
                    onPrevPress={handlePrevPress}
                    onNextPress={handleNextPress}
                />

                <ScrollView style={{ flex: 1 }}>
                    {currentVisitsDataArr.length > 0 ? (
                        <VisitRequest
                            mobileView={mobileView}
                            visitRequest={currentVisitsDataArr[currentVisitIndex]}
                            onScheduleWindowsForDateChange={handleScheduleWindowsForDateChange}
                            onScheduleWindowIdChange={handleSelectedScheduleWindowIdChange}
                            onConfirmRequestPress={handleConfirmRequestPress}
                            approveRequestLoading={approveVisitLoading}
                            approveRequestError={approveVisitError?.message}
                        />
                    ) : (
                        <Text>
                            <FormattedMessage
                                id="visitReviewPage.noVisitsData"
                                defaultMessage="No requests to show."
                            />
                        </Text>
                    )}
                </ScrollView>
            </View>

            <View testID="data-visitReviewPageMap" style={{ flex: 1, width: 'auto' }}>
                <VisitReviewMap
                    currentVisit={currentVisitData}
                    visitsToRender={
                        visitsByScheduleWindowMap[
                            selectedScheduleWindowId ?? currentVisitData?.scheduleWindow.id
                        ] ?? undefined
                    }
                />
            </View>
        </View>
    );
};

type VisitRequestsNavigationProps = {
    current: number;
    total: number;
    hasNext: boolean;
    onNextPress: () => void;
    hasPrev: boolean;
    onPrevPress: () => void;
};

const VisitRequestsNavigation: React.FC<VisitRequestsNavigationProps> = ({
    current,
    total,
    hasNext,
    onNextPress,
    hasPrev,
    onPrevPress,
}) => {
    const intl = useIntl();

    return (
        <View
            style={{
                marginVertical: 10,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
            }}
        >
            <View style={{ flex: 1 }}>
                <Button
                    title={intl.formatMessage({
                        id: 'visitReviewPage.navigationPrevious',
                        defaultMessage: 'Previous',
                    })}
                    onPress={onPrevPress}
                    disabled={!hasPrev}
                    testID="data-visitReviewPagePrevVisitButton"
                />
            </View>

            <View
                style={{
                    marginHorizontal: 10,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Text style={{ fontSize: 18, fontWeight: '700' }}>
                    {total === 0 ? 0 : current + 1} / {total}
                </Text>
            </View>

            <View style={{ flex: 1 }}>
                <Button
                    title={intl.formatMessage({
                        id: 'visitReviewPage.navigationNext',
                        defaultMessage: 'Next',
                    })}
                    onPress={onNextPress}
                    disabled={!hasNext}
                    testID="data-visitReviewPageNextVisitButton"
                />
            </View>
        </View>
    );
};
