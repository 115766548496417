import React, { useState, useCallback } from 'react';
import { gql } from '@apollo/client';

import { View, Text, StyleSheet, ScrollView } from 'react-native';
import { Button, Card, ErrorMessage, Message, Select, SelectItem } from '@valet/ui-components';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    useRetrieveAllVisitsQuery,
    VisitManagementDetailEmployeeFragment,
    VisitStatus,
} from '../../../../schema-types';
import { format, parseISO } from 'date-fns';

type VisitManagementPageProps = {
    handleVisitManagementSelect: (visitId: string) => void;
};

const VISIT_SELECTION_VISIT_FRAGMENT = gql`
    fragment EmployeeVisitManagement on Visit {
        id
        status
        startTime
        endTime
        expectedDuration
    }
`;

export const PICKUP_RETRIEVE_VISITS_QUERY = gql`
    query RetrieveAllVisits(
        $pageSize: Int
        $visitStatus: String!
        $afterCursor: String
        $beforeCursor: String
    ) {
        visits(
            filter: {
                pageSize: $pageSize
                after: $afterCursor
                before: $beforeCursor
                conditions: [{ field: "status", value: $visitStatus, operator: Equal }]
            }
        ) {
            edges {
                cursor
                node {
                    id
                    ...EmployeeVisitManagement
                }
            }
            pageInfo {
                cursor {
                    beforeCursor
                    afterCursor
                }
                hasNextPage
                hasPreviousPage
            }
        }
    }
    ${VISIT_SELECTION_VISIT_FRAGMENT}
`;

export const visitManagementPageSize = 50;

export const VisitManagementPage: React.FC<VisitManagementPageProps> = ({
    handleVisitManagementSelect,
}) => {
    const [visitStatus, setVisitStatus] = useState<VisitStatus>(VisitStatus.Pending);
    const intl = useIntl();
    const [selectIndex, setSelectIndex] = useState<number>(0);

    const handleItemClick = useCallback(
        (id: string) => {
            handleVisitManagementSelect(id);
        },
        [handleVisitManagementSelect],
    );

    const selectItems: { id: string; unit: VisitStatus; title: string }[] = [
        {
            id: 'BeingPicked',
            unit: VisitStatus.BeingPicked,
            title: intl.formatMessage({
                id: 'VisitStatus.BeingPicked',
                defaultMessage: 'BeingPicked',
            }),
        },
        {
            id: 'Cancelled',
            unit: VisitStatus.Cancelled,
            title: intl.formatMessage({
                id: 'VisitStatus.Cancelled',
                defaultMessage: 'Cancelled',
            }),
        },
        {
            id: 'Completed',
            unit: VisitStatus.Completed,
            title: intl.formatMessage({
                id: 'VisitStatus.Completed',
                defaultMessage: 'Completed',
            }),
        },
        {
            id: 'Created',
            unit: VisitStatus.Created,
            title: intl.formatMessage({
                id: 'VisitStatus.Created',
                defaultMessage: 'Created',
            }),
        },
        {
            id: 'InTransit',
            unit: VisitStatus.InTransit,
            title: intl.formatMessage({
                id: 'VisitStatus.InTransit',
                defaultMessage: 'InTransit',
            }),
        },
        {
            id: 'Pending',
            unit: VisitStatus.Pending,
            title: intl.formatMessage({
                id: 'VisitStatus.Pending',
                defaultMessage: 'Pending',
            }),
        },
        {
            id: 'PickingProcessed',
            unit: VisitStatus.PickingProcessed,
            title: intl.formatMessage({
                id: 'VisitStatus.PickingProcessed',
                defaultMessage: 'PickingProcessed',
            }),
        },
        {
            id: 'Reviewed',
            unit: VisitStatus.Reviewed,
            title: intl.formatMessage({
                id: 'VisitStatus.Reviewed',
                defaultMessage: 'Reviewed',
            }),
        },
    ];

    const handleSelectItem = useCallback(
        (index: number) => {
            setSelectIndex(index);
            setVisitStatus(selectItems[index].unit);
        },
        [selectItems],
    );

    return (
        <ScrollView>
            <View testID="data-visitManagementPage">
                <View style={visitManagementPageStyles.ViewContent}>
                    <Text style={visitManagementPageStyles.TextHeader}>
                        <FormattedMessage
                            id="visitManagement.selectVisitHeader"
                            defaultMessage="Select a Visit"
                        />
                    </Text>
                    <View
                        style={{
                            marginVertical: 10,
                            marginHorizontal: 10,
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        <View style={{ flex: 1, marginRight: 10 }}>
                            <FormattedMessage
                                id="visitManagement.visitStatus"
                                defaultMessage="Visit Status: "
                            />
                            <Select
                                currentIndex={selectIndex}
                                itemsDisplayValues={selectItems.map((items) => items.title)}
                                onSelect={handleSelectItem}
                            >
                                {selectItems.map((item) => (
                                    <SelectItem title={item.title} key={item.id} />
                                ))}
                            </Select>
                        </View>
                    </View>

                    <VisitManagementItemsByStatus
                        onItemSelect={handleItemClick}
                        status={visitStatus}
                    />
                </View>
            </View>
        </ScrollView>
    );
};

type VisitManagementItemsByStatusProps = {
    status: VisitStatus;
    onItemSelect: (visitId: string) => void;
};

export const VisitManagementItemsByStatus: React.FC<VisitManagementItemsByStatusProps> = ({
    status,
    onItemSelect,
}) => {
    const intl = useIntl();
    const [errorMessage, setErrorMessage] = useState<ErrorMessage | undefined>(undefined);

    const { data, loading, error, fetchMore } = useRetrieveAllVisitsQuery({
        variables: { pageSize: visitManagementPageSize, afterCursor: '', visitStatus: status },
        fetchPolicy: 'network-only',
    });
    const visitEdges = data?.visits?.edges ?? [];
    const visits = visitEdges.map((visitEdge: any) => visitEdge?.node);
    const [isFetching, setIsFetching] = useState<boolean>(false);

    const [hasNextPage, setHasNextPage] = useState<boolean>(
        data?.visits?.pageInfo?.hasNextPage ?? false,
    );

    const [hasPrevPage, setHasPrevPage] = useState<boolean>(
        data?.visits?.pageInfo?.hasPreviousPage ?? false,
    );
    const [afterCursor, setAfterCursor] = useState<string>(
        data?.visits?.pageInfo?.cursor?.afterCursor ?? '',
    );

    const [beforeCursor, setBeforeCursor] = useState<string>(
        data?.visits?.pageInfo?.cursor.beforeCursor ?? '',
    );

    const handlePrevPress = useCallback(async () => {
        setErrorMessage(undefined);
        if (!isFetching) {
            setIsFetching(true);

            const { data, error } = await fetchMore({
                variables: {
                    pageSize: visitManagementPageSize,
                    beforeCursor: beforeCursor,
                    visitStatus: status,
                },
            });

            setHasNextPage(data?.visits?.pageInfo?.hasNextPage || false);
            setHasPrevPage(data?.visits?.pageInfo?.hasPreviousPage || false);
            setAfterCursor(data?.visits?.pageInfo?.cursor?.afterCursor || '');
            setBeforeCursor(data?.visits?.pageInfo?.cursor?.beforeCursor || '');

            if (error && error.message) {
                setErrorMessage({
                    content: intl.formatMessage({
                        id: 'visitManagementPage.errorLoadingPrevPageContent',
                        defaultMessage: error.message,
                    }),
                    type: 'warning',
                    header: intl.formatMessage({
                        id: 'visitManagementPage.errorLoadingPrevPageHeader',
                        defaultMessage: 'Error in retrieving visit List, please refresh the page.',
                    }),
                });
            }

            setIsFetching(false);
        }
    }, [isFetching, beforeCursor, fetchMore, intl, status]);

    const handleNextPress = useCallback(async () => {
        setErrorMessage(undefined);
        if (!isFetching) {
            setIsFetching(true);

            const { data, error } = await fetchMore({
                variables: {
                    pageSize: visitManagementPageSize,
                    afterCursor: afterCursor,
                    visitStatus: status,
                },
            });

            setHasNextPage(data?.visits?.pageInfo?.hasNextPage || false);
            setHasPrevPage(data?.visits?.pageInfo?.hasPreviousPage || false);
            setAfterCursor(data?.visits?.pageInfo?.cursor?.afterCursor || '');
            setBeforeCursor(data?.visits?.pageInfo?.cursor?.beforeCursor || '');

            if (error && error.message) {
                setErrorMessage({
                    content: intl.formatMessage({
                        id: 'visitManagementPage.errorLoadingPrevPageContent',
                        defaultMessage: error.message,
                    }),
                    type: 'warning',
                    header: intl.formatMessage({
                        id: 'visitManagementPage.errorLoadingPrevPageHeader',
                        defaultMessage: 'Error in retrieving visit List, please refresh the page.',
                    }),
                });
            }

            setIsFetching(false);
        }
    }, [isFetching, afterCursor, fetchMore, intl, status]);

    const handleItemClick = useCallback(
        (id: string) => {
            onItemSelect(id);
        },
        [onItemSelect],
    );

    return (
        <ScrollView>
            <View testID="data-visitManagementItem">
                {loading && (
                    <View testID="data-errorLoading">
                        <Text>
                            <FormattedMessage id="errorLoading" defaultMessage="loading..." />
                        </Text>
                    </View>
                )}
                {error && (
                    <View testID="data-error">
                        <Text>
                            <FormattedMessage
                                id="error"
                                defaultMessage="There was an error. Please reload this page."
                            />
                        </Text>
                    </View>
                )}
                {errorMessage && (
                    <Message
                        type={errorMessage.type}
                        header={errorMessage.header}
                        content={errorMessage.content}
                    />
                )}
                <VisitManagementNavigation
                    hasPrev={
                        afterCursor || beforeCursor
                            ? hasPrevPage
                            : data?.visits?.pageInfo?.hasPreviousPage ?? false
                    }
                    hasNext={
                        afterCursor || beforeCursor
                            ? hasNextPage
                            : data?.visits?.pageInfo?.hasNextPage ?? false
                    }
                    onPrevPress={handlePrevPress}
                    onNextPress={handleNextPress}
                />
                {!data && visits.length < 0 && (
                    <Text>
                        <FormattedMessage
                            id="visitManagement.noUpcomingVisits"
                            defaultMessage="No upcoming visits."
                        />
                    </Text>
                )}

                {data && visits.length > 0 && (
                    <View style={visitManagementPageStyles.ViewContent}>
                        <Text style={visitManagementPageStyles.TextHeader}>
                            <FormattedMessage
                                id="visitManagement.selectVisitHeader"
                                defaultMessage="Select a Visit"
                            />
                        </Text>

                        <>
                            {visits.length === 0 ? (
                                <Text>
                                    <FormattedMessage
                                        id="visitManagement.noUpcomingVisits"
                                        defaultMessage="No upcoming visits."
                                    />
                                </Text>
                            ) : (
                                <>
                                    {visits.map((visit) => (
                                        <VisitManagementItem
                                            key={visit.id}
                                            details={visit}
                                            selected={false}
                                            onVisitItemManagement={handleItemClick}
                                        />
                                    ))}
                                </>
                            )}
                        </>
                    </View>
                )}
            </View>
        </ScrollView>
    );
};

const visitManagementPageStyles = StyleSheet.create({
    TextHeader: {
        fontSize: 20,
        fontWeight: '700',
    },
    TextContentHeader: {
        marginVertical: 5,
        fontSize: 18,
        fontWeight: '700',
    },
    ViewContent: {
        paddingVertical: 5,
    },
    ViewDescription: {
        marginTop: 10,
        fontWeight: 'bold',
    },
});

type VisitManagementItemProps = {
    details: VisitManagementDetailEmployeeFragment;
    selected: boolean;
    onVisitItemManagement: (visitId: string) => void;
};

const VisitManagementItem: React.FC<VisitManagementItemProps> = ({
    details,
    selected,
    onVisitItemManagement,
}) => {
    const intl = useIntl();

    const { startTime, endTime, status } = details;

    return (
        <View style={visitStyles.ViewParent}>
            <Card
                onPress={() => onVisitItemManagement(details?.id || '')}
                testID="data-visitItem"
                status={selected ? 'success' : undefined}
            >
                <Text>{format(parseISO(startTime), 'MMMM dd yyyy')}</Text>
                <Text>
                    <FormattedMessage
                        id="visitManagement.visitWindowbetweenTimes"
                        defaultMessage="Between {startTime} and {endTime}"
                        values={{
                            startTime: intl.formatTime(parseISO(startTime), {
                                hour: 'numeric',
                                minute: 'numeric',
                            }),

                            endTime: intl.formatTime(parseISO(endTime), {
                                hour: 'numeric',
                                minute: 'numeric',
                            }),
                        }}
                    />
                </Text>
                <Text>{status}</Text>
            </Card>
        </View>
    );
};

const visitStyles = StyleSheet.create({
    ViewParent: {
        marginTop: 5,
        marginBottom: 5,
        width: '100%',
    },
});

type VisitManagementNavigationProps = {
    hasNext: boolean;
    onNextPress: () => void;
    hasPrev: boolean;
    onPrevPress: () => void;
};

const VisitManagementNavigation: React.FC<VisitManagementNavigationProps> = ({
    hasNext,
    onNextPress,
    hasPrev,
    onPrevPress,
}) => {
    const intl = useIntl();

    return (
        <View
            style={{
                marginVertical: 10,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
            }}
        >
            <View style={{ flex: 1 }}>
                <Button
                    title={intl.formatMessage({
                        id: 'visitManagementPage.navigationPrevious',
                        defaultMessage: 'Previous',
                    })}
                    onPress={onPrevPress}
                    disabled={!hasPrev}
                    testID="data-PreVisitButton"
                />
            </View>
            <View style={{ flex: 1 }}>
                <Button
                    title={intl.formatMessage({
                        id: 'visitManagementPage.navigationNext',
                        defaultMessage: 'Next',
                    })}
                    onPress={onNextPress}
                    disabled={!hasNext}
                    testID="data-NextVisitButton"
                />
            </View>
        </View>
    );
};
