import { gql } from '@apollo/client';
import React, { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ScrollView, StyleSheet, Text, View } from 'react-native';
import { Button, ErrorMessage, Message, Select, SelectItem, TextInput } from '@valet/ui-components';
import {
    useAdminAddStorageItemTypeMutation,
    AdminAddStorageItemTypeInput,
    useUploadStorageItemTypeImageMutation,
} from '../../../../schema-types';
import { useHistory } from 'react-router-dom';
import {
    useGetAdminStorageItemTypeCategoriesQuery,
    AdminStorageItemTypeCategoryFragment,
} from '../../../../schema-types';
import { UploadFile } from '../FileUpload/UploadFile';

const CATEGORY_FRAGMENT = gql`
    fragment adminStorageItemTypeCategory on StorageItemTypeCategory {
        id
        name
        parentId
    }
`;

export const RETRIEVE_AREA_QUERY = gql`
    query getAdminStorageItemTypeCategories {
        storageItemTypeCategory {
            ...adminStorageItemTypeCategory
        }
    }
    ${CATEGORY_FRAGMENT}
`;

export const ADMIN_ADD_VEHICLE_MUTATION = gql`
    mutation adminAddStorageItemType($data: AdminAddStorageItemTypeInput!) {
        adminAddStorageItemType(data: $data) {
            data {
                id
                description
                name
                image
                maximumRequestQuantity
                category {
                    id
                    name
                    parentId
                }
                multiplePeople
            }
        }
    }
`;

export const UPLOAD_FILE_MUTATION = gql`
    mutation uploadStorageItemTypeImage($file: Upload!) {
        uploadStorageItemTypeImage(file: $file) {
            data {
                url
            }
        }
    }
`;

type AddStorageItemTypePageProps = {
    onStorageItemTypeAdded: () => void;
};

export const AddStorageItemTypePage: React.FC<AddStorageItemTypePageProps> = ({
    onStorageItemTypeAdded,
}) => {
    const history = useHistory();
    const [selectIndex] = useState<number>(0);
    const [errorMessage, setErrorMessage] = useState<ErrorMessage | undefined>();
    const [selectedFile, setSelectedFile] = useState(undefined);

    const intl = useIntl();
    const [newStorageItemType, setNewStorageItemType] = useState<AdminAddStorageItemTypeInput>({
        description: '',
        name: '',
        image: '',
        maximumRequestQuantity: 0,
        categoryId: '',
        multiplePeople: true,
    });

    const [
        uploadStorageItemTypeImageMutation,
        {
            loading: uploadStorageItemTypeImageMutationLoading,
            error: uploadStorageItemTypeImageMutationError,
        },
    ] = useUploadStorageItemTypeImageMutation();

    const [
        adminAddStorageItemTypeMutation,
        {
            loading: adminAddStorageItemTypeDetailMutationLoading,
            error: adminAddStorageItemTypeDetailMutationError,
        },
    ] = useAdminAddStorageItemTypeMutation({
        onCompleted(data: any) {
            if (data) {
                onStorageItemTypeAdded();
            }
        },
    });

    const { data: retrievedCategoryData } = useGetAdminStorageItemTypeCategoriesQuery();

    const categoryData = retrievedCategoryData?.storageItemTypeCategory
        ? (retrievedCategoryData?.storageItemTypeCategory as AdminStorageItemTypeCategoryFragment[])
        : [];

    const selectItems: { id: string; name: string }[] = [];
    categoryData.map((item) =>
        selectItems.push({
            id: item.id,
            name: item.name,
        }),
    );

    const handleAddStorageItemTypeClick = useCallback(async () => {
        setErrorMessage(undefined);
        if (!newStorageItemType.categoryId) {
            setErrorMessage({
                header: '',
                content: intl.formatMessage({
                    id: 'addStorageItemType.invalidCategory',
                    defaultMessage: 'Please select category.',
                }),
                type: 'warning',
            });
        } else if (newStorageItemType.description && newStorageItemType.name) {
            setErrorMessage(undefined);

            const result = await uploadStorageItemTypeImageMutation({
                variables: {
                    file: selectedFile,
                },
            });

            if (result.data?.uploadStorageItemTypeImage.data.url) {
                await adminAddStorageItemTypeMutation({
                    variables: {
                        data: {
                            ...newStorageItemType,
                            image: result.data?.uploadStorageItemTypeImage.data.url,
                        },
                    },
                });
            }
        } else {
            setErrorMessage({
                header: '',
                content: intl.formatMessage({
                    id: 'AddStorageItemType.handleAddStorageItemTypeClick',
                    defaultMessage: 'Please Enter the StorageItemType Information.',
                }),
                type: 'warning',
            });
        }
    }, [
        uploadStorageItemTypeImageMutation,
        adminAddStorageItemTypeMutation,
        newStorageItemType,
        setErrorMessage,
        intl,
        selectedFile,
    ]);

    const handleBacklClick = useCallback(async () => {
        history.push(`/admin/storageItemTypes`);
    }, [history]);

    const handleOnFileUpload = useCallback(async (file) => {
        setSelectedFile(file);
    }, []);

    return (
        <ScrollView>
            {adminAddStorageItemTypeDetailMutationLoading && (
                <View testID="data-AddStorageItemTypeItemLoading">
                    <Text>
                        <FormattedMessage
                            id="AddStorageItemType.loading"
                            defaultMessage="Loading..."
                        />
                    </Text>
                </View>
            )}
            {uploadStorageItemTypeImageMutationLoading && (
                <View testID="data-AddStorageItemTypeUploadingImage">
                    <Text>
                        <FormattedMessage
                            id="AddStorageItemType.uploadingImage"
                            defaultMessage="Uploading Image..."
                        />
                    </Text>
                </View>
            )}
            {uploadStorageItemTypeImageMutationError && (
                <Message
                    type="negative"
                    header={intl.formatMessage({
                        id: 'AddStorageItemType.addStorageItemTypeUploadImageErrorMessage',
                        defaultMessage: 'Could not upload image - please try again',
                    })}
                    content={uploadStorageItemTypeImageMutationError.message}
                    testID="data-AddStorageItemTypeErrorMessage"
                />
            )}
            {errorMessage && (
                <Message
                    type={errorMessage.type}
                    header={errorMessage.header}
                    content={errorMessage.content}
                />
            )}
            {adminAddStorageItemTypeDetailMutationError && (
                <Message
                    type="negative"
                    header={intl.formatMessage({
                        id: 'AddStorageItemType.addStorageItemTypeErrorMessage',
                        defaultMessage: 'Could not add newStorageItemType - please try again',
                    })}
                    content={adminAddStorageItemTypeDetailMutationError.message}
                    testID="data-AddStorageItemTypeErrorMessage"
                />
            )}

            <View style={newStorageItemTypePageStyles.ViewParent} testID="data-AddStorageItemType">
                <View style={newStorageItemTypePageStyles.ViewChild}>
                    <View style={newStorageItemTypePageStyles.ViewContentParent}>
                        <View style={newStorageItemTypePageStyles.ViewTitleParent}>
                            <Text
                                style={newStorageItemTypePageStyles.TextTitle}
                                testID="data-newStorageItemType"
                            >
                                {`${intl.formatMessage({
                                    id: 'newStorageItemTypeDetail.id',
                                    defaultMessage: 'Add New Storage Item Type: ',
                                })}${''}`}
                            </Text>
                        </View>
                        <View style={newStorageItemTypePageStyles.ViewTitleParent}>
                            <View style={newStorageItemTypePageStyles.ViewDescription}>
                                <FormattedMessage
                                    id="storageItemTypeDetail.Category"
                                    defaultMessage="Category"
                                />
                                <Select
                                    currentIndex={selectIndex}
                                    itemsDisplayValues={selectItems.map((items) => items.name)}
                                    onSelect={(index) =>
                                        setNewStorageItemType({
                                            ...newStorageItemType,
                                            categoryId: selectItems[index].id,
                                        })
                                    }
                                >
                                    {selectItems.map((item) => (
                                        <SelectItem title={item.name} key={item.id} />
                                    ))}
                                </Select>
                            </View>
                            <View style={newStorageItemTypePageStyles.ViewDescription}>
                                <View>
                                    <FormattedMessage
                                        id="newStorageItemTypeDetail.description"
                                        defaultMessage="Description: "
                                    />
                                    <TextInput
                                        type="text"
                                        ariaLabel="Description: "
                                        value={newStorageItemType?.description || ''}
                                        onChange={(value: string) => {
                                            setNewStorageItemType({
                                                ...newStorageItemType,
                                                description: value,
                                            });
                                        }}
                                    />
                                </View>
                            </View>
                            <View style={newStorageItemTypePageStyles.ViewDescription}>
                                <FormattedMessage
                                    id="newStorageItemTypeDetail.name"
                                    defaultMessage="Name: "
                                />
                                <TextInput
                                    type="text"
                                    ariaLabel="Name"
                                    value={newStorageItemType?.name || ''}
                                    onChange={(value: string) => {
                                        setNewStorageItemType({
                                            ...newStorageItemType,
                                            name: value,
                                        });
                                    }}
                                />
                            </View>
                            <View style={newStorageItemTypePageStyles.ViewDescription}>
                                <FormattedMessage
                                    id="newStorageItemTypeDetail.maximumRequestQuantity"
                                    defaultMessage="Maximum Request Quantity: "
                                />
                                <TextInput
                                    type="text"
                                    ariaLabel="Maximum Request Quantity: "
                                    value={newStorageItemType.maximumRequestQuantity}
                                    onChange={(value: any) => {
                                        setNewStorageItemType({
                                            ...newStorageItemType,
                                            maximumRequestQuantity: value ? parseInt(value) : 0,
                                        });
                                    }}
                                />
                            </View>
                        </View>
                        <View style={newStorageItemTypePageStyles.ViewDescription}>
                            <View style={newStorageItemTypePageStyles.ViewImage}>
                                <UploadFile onFileUploaded={handleOnFileUpload} />
                            </View>
                        </View>
                    </View>
                </View>
                <View
                    style={{
                        marginVertical: 10,
                        marginHorizontal: 10,
                        display: 'flex',
                        flexDirection: 'row',
                    }}
                >
                    <View style={{ flex: 1, marginRight: 10 }}>
                        <Button
                            title={intl.formatMessage({
                                id: 'newStorageItemTypeDetail.Cancel',
                                defaultMessage: 'Cancel',
                            })}
                            onPress={handleBacklClick}
                        />
                    </View>
                    <View style={{ flex: 1 }}>
                        <Button
                            title={intl.formatMessage({
                                id: 'newStorageItemTypeDetail.Add',
                                defaultMessage: 'Add',
                            })}
                            onPress={handleAddStorageItemTypeClick}
                        />
                    </View>
                </View>
            </View>
        </ScrollView>
    );
};

const newStorageItemTypePageStyles = StyleSheet.create({
    ViewParent: {
        display: 'flex',
        flexDirection: 'column',
    },
    ViewTabs: {
        marginVertical: 5,
        display: 'flex',
        flexDirection: 'row',
    },
    ViewContent: {
        paddingVertical: 5,
    },
    TextTab: {
        flex: 1,
        color: '#000',
        backgroundColor: '#D4D4D5',
        fontSize: 18,
        textAlign: 'center',
        fontWeight: '700',
    },

    ViewChild: {
        display: 'flex',
        flexDirection: 'row',
    },
    ViewImageParent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    RouteImage: {
        height: 40,
        width: 40,
        resizeMode: 'stretch',
    },
    ViewContentParent: {
        paddingHorizontal: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    ViewTitleParent: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 5,
    },
    ViewTitleChild: {},
    TextTitle: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 10,
    },
    ViewDescription: {
        marginTop: 10,
        fontWeight: 'bold',
    },
    TextDescription: { fontSize: 14 },
    ViewStatus: {},
    TextStatus: {
        fontSize: 12,
        fontWeight: '700',
    },
    ViewDate: {},
    TextDate: {
        fontSize: 12,
    },
    ViewErrorMessageParent: {
        marginTop: 5,
        marginBottom: 5,
    },
    TextContainerItem: {
        marginVertical: 2,
        display: 'flex',
        flexDirection: 'row',
        fontWeight: 'bold',
    },
    ViewImage: {
        paddingHorizontal: 5,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
});
