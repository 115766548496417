import { gql } from '@apollo/client';
import React, { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ScrollView, StyleSheet, Text, View, Image } from 'react-native';
import { Button, Checkbox, Message, TextInput } from '@valet/ui-components';
import {
    useGetAdminVehicleByIdQuery,
    AdminVehicleDetailFragment,
    useAdminUpdateVehicleMutation,
} from '../../../../schema-types';
import { useHistory, useRouteMatch } from 'react-router-dom';

const Vehicle_FRAGMENT = gql`
    fragment adminVehicleDetail on Vehicle {
        id
        created_at
        description
        model
        manufacture_year
        vin
        image
        isActive
    }
`;

export const RETRIEVE_BUILDING_QUERY = gql`
    query getAdminVehicleById($vehicleId: String!) {
        vehicleById(id: $vehicleId) {
            ...adminVehicleDetail
        }
    }
    ${Vehicle_FRAGMENT}
`;

export const ADMIN_UPDATE_BUILDING_MUTATION = gql`
    mutation adminUpdateVehicle($data: AdminUpdateVehicleInput!) {
        adminUpdateVehicle(data: $data) {
            data {
                ...adminVehicleDetail
            }
        }
    }
`;

type VehicleDetailPageProps = {};

export const VehicleDetailPage: React.FC<VehicleDetailPageProps> = () => {
    const history = useHistory();
    const intl = useIntl();


    const match = useRouteMatch();
    const params: { vehicleId?: string } = match.params;
    const vehicleId = params.vehicleId;
    const [vehicle, setVehicle] = useState<AdminVehicleDetailFragment>({
        id: '',
        created_at: '',
        description: '',
        model: '',
        manufacture_year: 0,
        vin: '',
        image: '',
        isActive: true,
    });

    const {
        loading: retrievedVehicleDetailLoading,
        error: retrievedVehicleDetailError,
    } = useGetAdminVehicleByIdQuery({
        variables: {
            vehicleId: vehicleId || '',
        },
        onCompleted: (data: any) => {
            setVehicle(data.vehicleById as AdminVehicleDetailFragment);
        },
    });


    const [
        adminUpdateVehicleMutation,
        {
            loading: adminUpdateVehicleDetailMutationLoading,
            error: adminUpdateVehicleDetailMutationError,
        },
    ] = useAdminUpdateVehicleMutation();

    const handleBacklClick = useCallback(async () => {
        history.push(`/admin/vehicle`);
    }, [history]);

    const handleSaveVehicleDetailClick = useCallback(async () => {
        if (vehicle) {
            try {
                await adminUpdateVehicleMutation({
                    variables: {
                        data: {
                            id: vehicle.id,
                            description: vehicle.description,
                            model: vehicle.model,
                            manufacture_year: vehicle.manufacture_year,
                            vin: vehicle.vin,
                            image: vehicle.image,
                            isActive: vehicle.isActive,
                        }

                    },
                });
                handleBacklClick();
            } catch (error) { }
        }
    }, [adminUpdateVehicleMutation, vehicle, handleBacklClick]);

    return (
        <ScrollView>
            <View style={vehiclePageStyles.ViewParent} testID="data-vehicleDetail">
                {retrievedVehicleDetailLoading || adminUpdateVehicleDetailMutationLoading ?

                    <View testID="data-vehicleDetailItemLoading">
                        <Text>
                            <FormattedMessage id="vehicleDetail.loading" defaultMessage="Loading..." />
                        </Text>
                    </View>
                    :
                    adminUpdateVehicleDetailMutationError ?
                        <View style={vehiclePageStyles.ViewErrorMessageParent} testID="data-errorMessage">
                            <Message
                                type="negative"
                                header={intl.formatMessage({
                                    id: 'vehicleDetail.vehicleDetailUpdateErrorMessage',
                                    defaultMessage: 'Could not update vehicle detail - please try again',
                                })}
                                content={adminUpdateVehicleDetailMutationError.message}
                                testID="data-vehicleDetailUpdateErrorMessage"
                            />
                        </View>
                        :
                        retrievedVehicleDetailError ?

                            <View style={vehiclePageStyles.ViewErrorMessageParent} testID="data-errorMessage">
                                <Message
                                    type="negative"
                                    header={intl.formatMessage({
                                        id: 'vehicleDetail.vehicleDetailErrorMessage',
                                        defaultMessage: 'Could not load detail for the eployee - please try again',
                                    })}
                                    content={retrievedVehicleDetailError.message}
                                    testID="data-vehicleDetailErrorMessage"
                                />
                            </View>
                            :
                            <>
                                <View>
                                    <View style={vehiclePageStyles.ViewChild}>
                                        <View style={vehiclePageStyles.ViewContentParent}>
                                            <View style={vehiclePageStyles.ViewTitleParent}>
                                                <Text style={vehiclePageStyles.TextTitle} testID="data-vehicle">
                                                    {`${intl.formatMessage({
                                                        id: 'vehicleDetail.id',
                                                        defaultMessage: 'Vehicle Details:',
                                                    })}${''}`}
                                                </Text>
                                            </View>
                                            <View style={vehiclePageStyles.ViewTitleParent}>
                                                <View
                                                    style={vehiclePageStyles.TextContainerItem}
                                                    key={vehicle.id}
                                                    testID="data-disableVehicle"
                                                >
                                                    <FormattedMessage
                                                        id="vehicleDetail.isActive"
                                                        defaultMessage="Active:  "
                                                    />
                                                    <View style={{ marginLeft: 5 }}>
                                                        <Checkbox
                                                            onValueChange={(value: any) => setVehicle({ ...vehicle, isActive: value })}
                                                            checked={vehicle?.isActive || false}
                                                            testID={'data-disableVehicleCheck'}
                                                        />
                                                    </View>
                                                </View>
                                                <View style={vehiclePageStyles.ViewDescription}>
                                                    <View>
                                                        <FormattedMessage
                                                            id="vehicleDetail.description"
                                                            defaultMessage="Description: "
                                                        />
                                                        <TextInput
                                                            type="text"
                                                            ariaLabel="Description: "
                                                            value={vehicle?.description}
                                                            onChange={(value: string) => {
                                                                setVehicle({ ...vehicle, description: value });
                                                            }}
                                                        />
                                                    </View>
                                                </View>
                                                <View style={vehiclePageStyles.ViewDescription}>
                                                    <FormattedMessage
                                                        id="vehicleDetail.model"
                                                        defaultMessage="Model: "
                                                    />
                                                    <TextInput
                                                        type="text"
                                                        ariaLabel="Model"
                                                        value={vehicle?.model || ''}
                                                        onChange={(value: string) => {
                                                            setVehicle({ ...vehicle, model: value });
                                                        }}
                                                    />
                                                </View>
                                                <View style={vehiclePageStyles.ViewDescription}>
                                                    <FormattedMessage
                                                        id="vehicleDetail.manufacture_year"
                                                        defaultMessage="Manufacture Year: "
                                                    />
                                                    <TextInput
                                                        type="text"
                                                        ariaLabel="Manufacture Year: "
                                                        value={vehicle.manufacture_year}
                                                        onChange={(value: any) => {
                                                            setVehicle({ ...vehicle, manufacture_year: value ? parseInt(value) : 0 });
                                                        }}
                                                    />
                                                </View>
                                            </View>
                                            <View style={vehiclePageStyles.ViewDescription}>
                                                <View>
                                                    <FormattedMessage
                                                        id="vehicleDetail.vin"
                                                        defaultMessage="Vin: "
                                                    />
                                                    <TextInput
                                                        type="text"
                                                        ariaLabel="vin: "
                                                        value={vehicle?.vin || ''}
                                                        onChange={(value: string) => {
                                                            setVehicle({ ...vehicle, vin: value });
                                                        }}
                                                    />
                                                </View>
                                                <View style={vehiclePageStyles.ViewDescription}>
                                                    <FormattedMessage
                                                        id="vehicleDetail.image"
                                                        defaultMessage="Image: "
                                                    />
                                                    <TextInput
                                                        type="text"
                                                        ariaLabel="Image: "
                                                        value={vehicle?.image || ''}
                                                        onChange={(value: string) => {
                                                            setVehicle({ ...vehicle, image: value });
                                                        }}
                                                    />
                                                </View>
                                                <View style={vehiclePageStyles.ViewImage}>
                                                    {vehicle.image ? (
                                                        <Image
                                                            style={{ height: 80, width: 120 }}
                                                            source={{ uri: vehicle?.image }}
                                                        />
                                                    ) : (
                                                        <Text>
                                                            <FormattedMessage
                                                                id="vehicle.image"
                                                                defaultMessage="No image loaded."
                                                            />
                                                        </Text>
                                                    )}
                                                </View>
                                            </View>
                                        </View>
                                    </View>

                                    <View style={vehiclePageStyles.ViewDescription}>
                                        <FormattedMessage
                                            id="vehicleDetail.model"
                                            defaultMessage="Model: "
                                        />
                                        <TextInput
                                            type="text"
                                            ariaLabel="Model"
                                            value={vehicle?.model || ''}
                                            onChange={(value: string) => {
                                                setVehicle({ ...vehicle, model: value });
                                            }}
                                        />
                                    </View>
                                    <View style={vehiclePageStyles.ViewDescription}>
                                        <FormattedMessage
                                            id="vehicleDetail.manufacture_year"
                                            defaultMessage="Manufacture Year: "
                                        />
                                        <TextInput
                                            type="text"
                                            ariaLabel="Manufacture Year: "
                                            value={vehicle.manufacture_year}
                                            onChange={(value: any) => {
                                                setVehicle({ ...vehicle, manufacture_year: value ? parseInt(value) : 0 });
                                            }}
                                        />
                                    </View>
                                    <View style={vehiclePageStyles.ViewDescription}>
                                        <View>
                                            <FormattedMessage
                                                id="vehicleDetail.vin"
                                                defaultMessage="Vin: "
                                            />
                                            <TextInput
                                                type="text"
                                                ariaLabel="vin: "
                                                value={vehicle?.vin || ''}
                                                onChange={(value: string) => {
                                                    setVehicle({ ...vehicle, vin: value });
                                                }}
                                            />
                                        </View>
                                        <View style={vehiclePageStyles.ViewDescription}>
                                            <FormattedMessage
                                                id="vehicleDetail.image"
                                                defaultMessage="Image: "
                                            />
                                            <TextInput
                                                type="text"
                                                ariaLabel="Image: "
                                                value={vehicle?.image || ''}
                                                onChange={(value: string) => {
                                                    setVehicle({ ...vehicle, image: value });
                                                }}
                                            />
                                        </View>
                                        <View style={vehiclePageStyles.ViewImage}>
                                            {vehicle.image ? (
                                                <Image
                                                    style={{ height: 80, width: 120 }}
                                                    source={{ uri: vehicle?.image }}
                                                />
                                            ) : (
                                                <Text>
                                                    <FormattedMessage
                                                        id="vehicle.image"
                                                        defaultMessage="No image loaded."
                                                    />
                                                </Text>
                                            )}
                                        </View>
                                    </View>

                                    <View style={{
                                        marginVertical: 10,
                                        marginHorizontal: 10,
                                        display: 'flex',
                                        flexDirection: 'row',
                                    }}
                                    >
                                        <View style={{ flex: 1, marginRight: 10 }}>
                                            <Button
                                                title={intl.formatMessage({
                                                    id: 'vehicleDetail.Cancel',
                                                    defaultMessage: 'Cancel',
                                                })}
                                                onPress={handleBacklClick}
                                            />
                                        </View>
                                        <View style={{ flex: 1 }}>
                                            <Button
                                                title={intl.formatMessage({
                                                    id: 'vehicleDetail.Save',
                                                    defaultMessage: 'Save',
                                                })}
                                                onPress={handleSaveVehicleDetailClick}
                                            />
                                        </View>
                                    </View>
                                </View>
                            </>}
            </View >
        </ScrollView >
    );
};

const vehiclePageStyles = StyleSheet.create({
    ViewParent: {
        display: 'flex',
        flexDirection: 'column',
    },
    ViewTabs: {
        marginVertical: 5,
        display: 'flex',
        flexDirection: 'row',
    },
    ViewContent: {
        paddingVertical: 5,
    },
    TextTab: {
        flex: 1,
        color: '#000',
        backgroundColor: '#D4D4D5',
        fontSize: 18,
        textAlign: 'center',
        fontWeight: '700',
    },

    ViewChild: {
        display: 'flex',
        flexDirection: 'row',
    },
    ViewImageParent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    RouteImage: {
        height: 40,
        width: 40,
        resizeMode: 'stretch',
    },
    ViewContentParent: {
        paddingHorizontal: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    ViewTitleParent: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 10,
    },
    ViewTitleChild: {},
    TextTitle: {
        fontSize: 18,
        fontWeight: 'bold',
    },
    ViewDescription: {
        marginTop: 10,
        fontWeight: 'bold',
    },
    TextDescription: { fontSize: 14 },
    ViewStatus: {},
    TextStatus: {
        fontSize: 12,
        fontWeight: '700',
    },
    ViewDate: {},
    TextDate: {
        fontSize: 12,
    },
    ViewErrorMessageParent: {
        marginTop: 5,
        marginBottom: 5,
    },
    TextContainerItem: {
        marginVertical: 2,
        display: 'flex',
        flexDirection: 'row',
        fontWeight: 'bold',
    },
    ViewImage: {
        paddingHorizontal: 5,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
});
