import React, { useCallback } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { Button } from '@valet/ui-components';
import { View, Text, StyleSheet } from 'react-native';
import { PickListSelection } from './PickListSelection';
import { FormattedMessage, useIntl } from 'react-intl';

type PickListPageProps = {};

export const PickListPage: React.FC<PickListPageProps> = () => {
    const intl = useIntl();
    const history = useHistory();
    const { path } = useRouteMatch();

    const handleNewPickListPress = (): void => {
        return history.push(`${path}/create`);
    };

    const handlePickListPress = useCallback(
        (id: string): void => {
            return history.push(`${path}/${id}`);
        },
        [history, path],
    );

    return (
        <View style={pickListPageStyles.ViewParent} testID="data-pickListPage">
            <View style={pickListPageStyles.ViewHeader}>
                <View style={{ flex: 1 }}>
                    <Text>
                        <FormattedMessage
                            id="pickListPage.pickListsHeader"
                            defaultMessage="Pick Lists"
                        />
                    </Text>
                </View>

                <View style={{ flex: 1 }}>
                    <Button
                        title={intl.formatMessage({
                            id: 'pickListPage.newPickListButton',
                            defaultMessage: 'New Pick List',
                        })}
                        onPress={handleNewPickListPress}
                        testID="data-pickListNewButton"
                        size="small"
                    />
                </View>
            </View>

            <View style={{ flex: 1 }} testID="data-pickListsList">
                <PickListSelection onPickListPress={handlePickListPress} />
            </View>
        </View>
    );
};

const pickListPageStyles = StyleSheet.create({
    ViewParent: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    ViewHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 5,
    },
});
