import React from 'react';
import { View, Text } from 'react-native';
import { RouteStopData, VisitData } from './Routing';
import { VisitSummaryIcons } from './VisitSummaryIcons';

type VisitInformationProps = {
    visit: VisitData;
    routeStop?: RouteStopData;
};

//TODO: Fragment

export const VisitInformation: React.FC<VisitInformationProps> = ({ visit, routeStop }) => (
    <>
        <View>
            <Text style={{ fontWeight: 'bold' }}>
                {visit.customer.firstName} {visit.customer.lastName}
            </Text>
        </View>
        {/* TODO: Format with address templates */}
        <View>
            <Text>{visit.customerAddress.address1}</Text>
            <Text>
                {visit.customerAddress.city}, {visit.customerAddress.zoneId}
            </Text>
            <Text>
                {visit.customerAddress.countryId}, {visit.customerAddress.zip}
            </Text>
        </View>
        <View>
            <VisitSummaryIcons
                {...visit.summary}
                expectedDuration={visit.expectedDuration ?? undefined}
                routeStop={routeStop}
            />
        </View>
    </>
);
