import { gql } from '@apollo/client';
import React, { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ScrollView, StyleSheet, Text, View, FlatList } from 'react-native';
import { Button, Message, Select, SelectItem, TextInput } from '@valet/ui-components';
import {
    useGetAdminTerritoryByIdQuery,
    AdminTerritoryDetailFragment,
    useAdminUpdateTerritoryMutation,
    useGetAdminTerritoryAreasQuery,
    AdminTerritoryAreaFragment,
    useAdminExcludePeriodFromTerritoryMutation,
    useAdminAddPeriodToTerritoryMutation,
} from '../../../../schema-types';
import { useHistory, useParams } from 'react-router';
import { ScheduleWeek } from './TerritorySchedules';

const AREA_FRAGMENT = gql`
    fragment adminTerritoryArea on Area {
        id
        code
    }
`;

export const RETRIEVE_AREA_QUERY = gql`
    query getAdminTerritoryAreas {
        areas {
            ...adminTerritoryArea
        }
    }
    ${AREA_FRAGMENT}
`;

const TERRITORY_FRAGMENT = gql`
    fragment adminTerritoryDetail on Territory {
        id
        description
        maximumRequestQuantity
        deliveryCutoff
        deliveryDelay
        pickupCutoff
        pickupDelay
        zipCodes
        area {
            id
            code
            scheduleTemplate {
                id
                startDate
                weeks {
                    monday {
                        id
                        startTime
                        endTime
                        week
                        day
                    }
                    tuesday {
                        id
                        startTime
                        endTime
                        week
                        day
                    }
                    wednesday {
                        id
                        startTime
                        endTime
                        week
                        day
                    }
                    thursday {
                        id
                        startTime
                        endTime
                        week
                        day
                    }
                    friday {
                        id
                        startTime
                        endTime
                        week
                        day
                    }
                    saturday {
                        id
                        startTime
                        endTime
                        week
                        day
                    }
                    sunday {
                        id
                        startTime
                        endTime
                        week
                        day
                    }
                }
                area {
                    id
                    code
                }
            }
        }
        excludedDeliveryWindows {
            id
            startTime
            endTime
            week
            day
        }
    }
`;

export const RETRIEVE_TERRITORY_QUERY = gql`
    query getAdminTerritoryById($territoryId: String!) {
        territoryById(id: $territoryId) {
            ...adminTerritoryDetail
        }
    }
    ${TERRITORY_FRAGMENT}
`;

export const ADMIN_UPDATE_TERRITORY_MUTATION = gql`
    mutation adminUpdateTerritory($data: AdminUpdateTerritoryInput!) {
        adminUpdateTerritory(data: $data) {
            data {
                id
                ...adminTerritoryDetail
            }
        }
    }
    ${TERRITORY_FRAGMENT}
`;

export const ADMIN_ADD_PERIOD_TO_TERRITORY_MUTATION = gql`
    mutation adminAddPeriodToTerritory($data: AdminAddPeriodToTerritoryInput!) {
        adminAddPeriodToTerritory(data: $data) {
            data {
                id
            }
        }
    }
`;

export const ADMIN_EXCLUDE_PERIOD_FROM_TERRITORY_MUTATION = gql`
    mutation adminExcludePeriodFromTerritory($data: AdminExcludePeriodFromTerritoryInput!) {
        adminExcludePeriodFromTerritory(data: $data) {
            data {
                id
            }
        }
    }
`;

type TerritoryDetailPageProps = {};

export const TerritoryDetailPage: React.FC<TerritoryDetailPageProps> = () => {
    const history = useHistory();
    const intl = useIntl();
    const [selectIndex] = useState<number>(0);

    const { data: retrievedAreaData } = useGetAdminTerritoryAreasQuery();

    const areasData = retrievedAreaData?.areas
        ? (retrievedAreaData?.areas as AdminTerritoryAreaFragment[])
        : [];

    const { territoryId } = useParams<{ territoryId?: string }>();
    const [territory, setTerritory] = useState<AdminTerritoryDetailFragment>({
        id: '',
        description: '',
        maximumRequestQuantity: 0,
        deliveryCutoff: 0,
        deliveryDelay: 0,
        pickupCutoff: 0,
        pickupDelay: 0,
        zipCodes: [],
        area: {
            id: '',
            code: '',
            scheduleTemplate: {
                id: '',
                startDate: new Date(),
                weeks: [],
                area: {
                    id: '',
                    code: '',
                },
            },
        },
        excludedDeliveryWindows: [],
    });

    const {
        loading: retrievedTerritoryDetailLoading,
        error: retrievedTerritoryDetailError,
    } = useGetAdminTerritoryByIdQuery({
        variables: {
            territoryId: territoryId ?? '',
        },
        onCompleted: (data: any) => {
            setTerritory(
                data.territoryById ?? {
                    id: '',
                    description: '',
                    maximumRequestQuantity: 0,
                    deliveryCutoff: 0,
                    deliveryDelay: 0,
                    pickupCutoff: 0,
                    pickupDelay: 0,
                    zipCodes: [],
                    area: {
                        id: '',
                        code: '',
                        deliveryWindows: [],
                    },
                    excludedDeliveryWindows: [],
                },
            );
        },
    });

    const selectItems: { id: string; title: string }[] = [];
    areasData.map((item) =>
        selectItems.push({
            id: item.id,
            title: item.code,
        }),
    );

    const [
        adminUpdateTerritoryMutation,
        {
            loading: adminUpdateTerritoryDetailMutationLoading,
            error: adminUpdateTerritoryDetailMutationError,
        },
    ] = useAdminUpdateTerritoryMutation({
        onCompleted(data) {
            if (data) {
                handleBacklClick();
            }
        },
    });

    const [
        adminAddPeriodToTerritoryMutation,
        {
            loading: adminAddPeriodToTerritoryMutationLoading,
            error: adminAddPeriodToTerritoryMutationError,
        },
    ] = useAdminAddPeriodToTerritoryMutation();

    const [
        adminExcludePeriodFromTerritoryMutation,
        {
            loading: adminExcludePeriodFromTerritoryMutationLoading,
            error: adminExcludePeriodFromTerritoryMutationError,
        },
    ] = useAdminExcludePeriodFromTerritoryMutation();

    const handleBacklClick = useCallback(async () => {
        history.push(`/admin/territories`);
    }, [history]);

    const handleSaveTerritoryDetailClick = useCallback(async () => {
        if (territory) {
            try {
                await adminUpdateTerritoryMutation({
                    variables: {
                        data: {
                            id: territory.id,
                            description: territory.description,
                            maximumRequestQuantity: territory.maximumRequestQuantity,
                            deliveryCutoff: territory.deliveryCutoff,
                            deliveryDelay: territory.deliveryDelay,
                            pickupCutoff: territory.pickupCutoff,
                            pickupDelay: territory.pickupDelay,
                            areaId: territory.area.id,
                            zipCodes: territory.zipCodes,
                        },
                    },
                });
            } catch (ignore) {} //eslint-disable-line no-empty
        }
    }, [adminUpdateTerritoryMutation, territory]);

    const handleSchedulePeriodTerritory = (scheduleTemplatePeriodId: string, checked: boolean) => {
        try {
            if (checked) {
                adminExcludePeriodFromTerritoryMutation({
                    variables: {
                        data: { scheduleTemplatePeriodId, territoryId: territoryId || '' },
                    },
                });
            } else {
                adminAddPeriodToTerritoryMutation({
                    variables: {
                        data: { scheduleTemplatePeriodId, territoryId: territoryId || '' },
                    },
                });
            }
        } catch (ignore) {} //eslint-disable-line no-empty
    };

    return (
        <ScrollView>
            <View style={territoryPageStyles.ViewParent} testID="data-territoryDetail">
                <View style={territoryPageStyles.ViewChild}>
                    <View style={territoryPageStyles.ViewContentParent}>
                        <View style={territoryPageStyles.ViewTitleParent}>
                            <Text style={territoryPageStyles.TextTitle} testID="data-territory">
                                {`${intl.formatMessage({
                                    id: 'territoryDetail.id',
                                    defaultMessage: 'Territory Details:',
                                })}${''}`}
                            </Text>
                        </View>
                        {retrievedTerritoryDetailError && (
                            <Message
                                type="negative"
                                header={intl.formatMessage({
                                    id: 'territoryDetail.territoryDetailErrorMessage',
                                    defaultMessage:
                                        'Could not load detail for the eployee - please try again',
                                })}
                                content={retrievedTerritoryDetailError.message}
                                testID="data-territoryDetailErrorMessage"
                            />
                        )}
                        {retrievedTerritoryDetailLoading && (
                            <View testID="data-territoryDetailItemLoading">
                                <Text>
                                    <FormattedMessage
                                        id="territoryDetail.loading"
                                        defaultMessage="Loading..."
                                    />
                                </Text>
                            </View>
                        )}
                        {adminUpdateTerritoryDetailMutationError && (
                            <Message
                                type="negative"
                                header={intl.formatMessage({
                                    id: 'territoryDetail.territoryDetailUpdateErrorMessage',
                                    defaultMessage:
                                        'Could not update territory detail - please try again',
                                })}
                                content={adminUpdateTerritoryDetailMutationError.message}
                                testID="data-territoryDetailUpdateErrorMessage"
                            />
                        )}
                        {adminUpdateTerritoryDetailMutationLoading && (
                            <Text>
                                <FormattedMessage
                                    id="territoryDetail.loading"
                                    defaultMessage="Loading..."
                                />
                            </Text>
                        )}
                        <View style={territoryPageStyles.ViewTitleParent}>
                            <View>
                                <FormattedMessage
                                    id="territoryDetail.description"
                                    defaultMessage="Description"
                                />
                                <TextInput
                                    type="text"
                                    ariaLabel="Description"
                                    value={territory?.description}
                                    onChange={(value: string) => {
                                        setTerritory({ ...territory, description: value });
                                    }}
                                />
                            </View>
                            <View style={territoryPageStyles.ViewDescription}>
                                <View>
                                    <FormattedMessage
                                        id="territoryDetail.maximumRequestQuantity"
                                        defaultMessage="MaximumRequestQuantity"
                                    />
                                    <TextInput
                                        type="text"
                                        ariaLabel="MaximumRequestQuantity"
                                        value={territory?.maximumRequestQuantity || 0}
                                        onChange={(value: any) => {
                                            setTerritory({
                                                ...territory,
                                                maximumRequestQuantity: parseInt(value),
                                            });
                                        }}
                                    />
                                </View>
                            </View>
                            <View style={territoryPageStyles.ViewDescription}>
                                <View>
                                    <FormattedMessage
                                        id="territoryDetail.deliveryCutoff"
                                        defaultMessage="DeliveryCutoff"
                                    />
                                    <TextInput
                                        type="text"
                                        ariaLabel="DeliveryCutoff"
                                        value={territory?.deliveryCutoff || 0}
                                        onChange={(value: any) => {
                                            setTerritory({
                                                ...territory,
                                                deliveryCutoff: parseInt(value),
                                            });
                                        }}
                                    />
                                </View>
                            </View>
                            <View style={territoryPageStyles.ViewDescription}>
                                <FormattedMessage id="territoryDetail.Area" defaultMessage="Area" />
                                <Select
                                    currentIndex={selectIndex}
                                    itemsDisplayValues={selectItems.map((items) => items.title)}
                                    onSelect={(index) =>
                                        setTerritory({
                                            ...territory,
                                            area: {
                                                id: selectItems[index].id,
                                                code: selectItems[index].title,
                                                scheduleTemplate: territory.area.scheduleTemplate,
                                            },
                                        })
                                    }
                                >
                                    {selectItems.map((item) => (
                                        <SelectItem title={item.title} key={item.id} />
                                    ))}
                                </Select>
                            </View>
                            <View style={territoryPageStyles.ViewDescription}>
                                <View>
                                    <FormattedMessage
                                        id="territoryDetail.deliveryDelay"
                                        defaultMessage="DeliveryDelay"
                                    />
                                    <TextInput
                                        type="text"
                                        ariaLabel="DeliveryDelay"
                                        value={territory?.deliveryDelay || 0}
                                        onChange={(value: any) => {
                                            setTerritory({
                                                ...territory,
                                                deliveryDelay: parseInt(value),
                                            });
                                        }}
                                    />
                                </View>
                            </View>
                            <View style={territoryPageStyles.ViewDescription}>
                                <View>
                                    <FormattedMessage
                                        id="territoryDetail.pickupCutoff"
                                        defaultMessage="PickupCutoff"
                                    />
                                    <TextInput
                                        type="text"
                                        ariaLabel="PickupCutoff"
                                        value={territory?.pickupCutoff || 0}
                                        onChange={(value: any) => {
                                            setTerritory({
                                                ...territory,
                                                pickupCutoff: parseInt(value),
                                            });
                                        }}
                                    />
                                </View>
                            </View>
                            <View style={territoryPageStyles.ViewDescription}>
                                <View>
                                    <FormattedMessage
                                        id="territoryDetail.pickupDelay"
                                        defaultMessage="PickupDelay"
                                    />
                                    <TextInput
                                        type="text"
                                        ariaLabel="PickupDelay"
                                        value={territory?.pickupDelay || 0}
                                        onChange={(value: any) => {
                                            setTerritory({
                                                ...territory,
                                                pickupDelay: parseInt(value),
                                            });
                                        }}
                                    />
                                </View>
                            </View>
                            <View style={territoryPageStyles.ViewDescription}>
                                <View>
                                    <FormattedMessage
                                        id="territoryDetail.zipCodes"
                                        defaultMessage="ZipCodes"
                                    />
                                    <TextInput
                                        type="text"
                                        ariaLabel="ZipCodes"
                                        multiline={true}
                                        value={territory?.zipCodes?.join('\r\n') || ''}
                                        onChange={(value: string) => {
                                            setTerritory({
                                                ...territory,
                                                zipCodes: value.split('\n'),
                                            });
                                        }}
                                    />
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
            </View>
            {territory.area.scheduleTemplate ? (
                <View style={territoryPageStyles.ViewContent}>
                    <FlatList
                        data={territory.area.scheduleTemplate.weeks}
                        renderItem={({ item, index }): any => (
                            <ScheduleWeek
                                key={`week_${index + 1}`}
                                weekData={item}
                                weekNumber={index + 1}
                                excludedPeriods={territory?.excludedDeliveryWindows || []}
                                handleSchedulePeriodTerritory={handleSchedulePeriodTerritory}
                            />
                        )}
                    />
                </View>
            ) : adminAddPeriodToTerritoryMutationLoading ||
              adminExcludePeriodFromTerritoryMutationLoading ? (
                <View testID="data-periodTerritoryDetailItemLoading">
                    <Text>
                        <FormattedMessage
                            id="periodTerritoryDetail.loading"
                            defaultMessage="Loading..."
                        />
                    </Text>
                </View>
            ) : adminAddPeriodToTerritoryMutationError ||
              adminExcludePeriodFromTerritoryMutationError ? (
                <View testID="data-periodTerritoryDetailItemError">
                    <Text>
                        <FormattedMessage
                            id="periodTerritoryDetail.Error"
                            defaultMessage="Coild Not add or Delete Schedule Period..."
                        />
                    </Text>
                </View>
            ) : null}
            <View
                style={{
                    marginVertical: 10,
                    marginHorizontal: 10,
                    display: 'flex',
                    flexDirection: 'row',
                }}
            >
                <View style={{ flex: 1, marginRight: 10 }}>
                    <Button
                        title={intl.formatMessage({
                            id: 'territoryDetail.Cancel',
                            defaultMessage: 'Cancel',
                        })}
                        onPress={handleBacklClick}
                    />
                </View>

                <View style={{ flex: 1 }}>
                    <Button
                        title={intl.formatMessage({
                            id: 'territoryDetail.Save',
                            defaultMessage: 'Save',
                        })}
                        onPress={handleSaveTerritoryDetailClick}
                    />
                </View>
            </View>
            {/* </View> */}
        </ScrollView>
    );
};

const territoryPageStyles = StyleSheet.create({
    ViewParent: {
        display: 'flex',
        flexDirection: 'column',
    },
    ViewTabs: {
        marginVertical: 5,
        display: 'flex',
        flexDirection: 'row',
    },
    ViewContent: {
        paddingVertical: 5,
    },
    TextTab: {
        flex: 1,
        color: '#000',
        backgroundColor: '#D4D4D5',
        fontSize: 18,
        textAlign: 'center',
        fontWeight: '700',
    },

    ViewChild: {
        display: 'flex',
        flexDirection: 'row',
    },
    ViewImageParent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    RouteImage: {
        height: 40,
        width: 40,
        resizeMode: 'stretch',
    },
    ViewContentParent: {
        paddingHorizontal: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    ViewTitleParent: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 10,
    },
    ViewTitleChild: {},
    TextTitle: {
        fontSize: 18,
        fontWeight: 'bold',
    },
    ViewDescription: {
        marginTop: 10,
        fontWeight: 'bold',
    },
    TextDescription: { fontSize: 14 },
    ViewStatus: {},
    TextStatus: {
        fontSize: 12,
        fontWeight: '700',
    },
    ViewDate: {},
    TextDate: {
        fontSize: 12,
    },
    ViewErrorMessageParent: {
        marginTop: 5,
        marginBottom: 5,
    },
    TextContainerItem: {
        marginVertical: 2,
        display: 'flex',
        flexDirection: 'row',
        fontWeight: 'bold',
    },
});
