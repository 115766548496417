import { gql } from '@apollo/client';
import React, { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ScrollView, StyleSheet, Text, View } from 'react-native';
import { Button, ErrorMessage, Message, Select, SelectItem, TextInput } from '@valet/ui-components';
import {
    useAdminAddStorageContainerMutation,
    AdminAddStorageContainerInput,
    useGetAdminStorageContainerTypesQuery,
    AdminStorageContainerTypeFragment,
    StorageContainerStatus
} from '../../../../schema-types';
import { useHistory } from 'react-router-dom';
// import { useGetAdminStorageContainerCategoriesQuery, AdminStorageContainerCategoryFragment } from '../../../../schema-types';

const CONTAINERTYPE_FRAGMENT = gql`
    fragment adminStorageContainerType on StorageContainerType {
        id
        description
    }
`;

export const RETRIEVE_CONTAINERTYPE_QUERY = gql`
    query getAdminStorageContainerTypes {
        storageContainerType {
            ...adminStorageContainerType
        }
    }
    ${CONTAINERTYPE_FRAGMENT}
`;



export const ADMIN_ADD_CONTAINER_MUTATION = gql`
    mutation adminAddStorageContainer($data: AdminAddStorageContainerInput!) {
        adminAddStorageContainer(data: $data) {
            data {
                status
                barcode
                containerType {
                    id
                    description
                }
            }
        }
    }
`;

type AddStorageContainerPageProps = {
    onStorageContainerAdded: () => void;
};

export const AddStorageContainerPage: React.FC<AddStorageContainerPageProps> = ({ onStorageContainerAdded }) => {
    const history = useHistory();
    const [errorMessage, setErrorMessage] = useState<ErrorMessage | undefined>();
    const [selectIndex] = useState<number>(0);
    const [containerSelectedIndex] = useState<number>(0);
    const intl = useIntl();

    const selectItems: { id: string; unit: StorageContainerStatus; title: string }[] = [
        {
            id: 'Available',
            unit: StorageContainerStatus.Available,
            title: intl.formatMessage({
                id: 'StorageContainerStatus.AVAILABLE',
                defaultMessage: 'AVAILABLE',
            }),
        },
        {
            id: 'InUse',
            unit: StorageContainerStatus.InUse,
            title: intl.formatMessage({
                id: 'StorageContainerStatus.IN_USE',
                defaultMessage: 'IN_USE',
            }),
        },
        {
            id: 'Retired',
            unit: StorageContainerStatus.Retired,
            title: intl.formatMessage({
                id: 'StorageContainerStatus.CUBIC_METRE',
                defaultMessage: 'RETIRED',
            }),
        },
    ];
    const [newStorageContainer, setNewStorageContainer] = useState<AdminAddStorageContainerInput>({
        status: StorageContainerStatus.Available,
        barcode: '',
        storageContainerTypeId: ''
    });

    const {
        data: retrievedContainerData,
    } = useGetAdminStorageContainerTypesQuery();

    const containerData = retrievedContainerData?.storageContainerType
        ? (retrievedContainerData?.storageContainerType as AdminStorageContainerTypeFragment[])
        : [];

    const [
        adminAddStorageContainerMutation,
        {
            loading: adminAddStorageContainerDetailMutationLoading,
            error: adminAddStorageContainerDetailMutationError,
        },
    ] = useAdminAddStorageContainerMutation({
        onCompleted(data: any) {
            if (data) {
                onStorageContainerAdded();
            }
        },
    });

    const containerSelectItems: { id: string; description: string }[] = [];
    containerData.map((item) =>
        containerSelectItems.push({
            id: item.id,
            description: item.description,
        }),
    );

    const handleAddStorageContainerClick = useCallback(async () => {

        if (newStorageContainer.status || newStorageContainer.storageContainerTypeId) {
            setErrorMessage(undefined);
            await adminAddStorageContainerMutation({
                variables: {
                    data: {
                        ...newStorageContainer,
                    },
                },
            });
        }
        else {
            setErrorMessage({
                header: '',
                content:
                    intl.formatMessage({
                        id: 'AddStorageContainer.handleAddStorageContainerClick',
                        defaultMessage: 'Please Enter the StorageContainer Information.',
                    }),
                type: 'warning',
            });
        }

    }, [adminAddStorageContainerMutation, newStorageContainer, setErrorMessage, intl]);

    const handleBacklClick = useCallback(async () => {
        history.push(`/admin/containers`);
    }, [history]);



    if (adminAddStorageContainerDetailMutationError) {
        return (
            <View style={newStorageContainerPageStyles.ViewErrorMessageParent} testID="data-errorMessage">
                <Message
                    type="negative"
                    header={intl.formatMessage({
                        id: 'AddStorageContainer.addStorageContainerErrorMessage',
                        defaultMessage: 'Could not add newStorageContainer - please try again',
                    })}
                    content={adminAddStorageContainerDetailMutationError.message}
                    testID="data-AddStorageContainerErrorMessage"
                />
            </View>
        );
    }

    if (errorMessage) {
        return (
            <View style={newStorageContainerPageStyles.ViewErrorMessageParent} testID="data-errorMessage">
                <Message
                    type={errorMessage.type}
                    header={errorMessage.header}
                    content={errorMessage.content}
                />
            </View>
        );
    }


    return (
        <ScrollView>
            {adminAddStorageContainerDetailMutationLoading ?
                <View testID="data-AddStorageContainertemLoading">
                    <Text>
                        <FormattedMessage id="AddStorageContainer.loading" defaultMessage="Loading..." />
                    </Text>
                </View>
                :
                <View style={newStorageContainerPageStyles.ViewParent} testID="data-AddStorageContainer">
                    <View style={newStorageContainerPageStyles.ViewChild}>
                        <View style={newStorageContainerPageStyles.ViewContentParent}>
                            <View style={newStorageContainerPageStyles.ViewTitleParent}>
                                <Text style={newStorageContainerPageStyles.TextTitle} testID="data-newStorageContainer">
                                    {`${intl.formatMessage({
                                        id: 'storageContainerAdd.id',
                                        defaultMessage: 'Add New Storage Container: ',
                                    })}${''}`}
                                </Text>
                            </View>
                            <View style={newStorageContainerPageStyles.ViewTitleParent}>
                                <View>
                                    <FormattedMessage
                                        id="storageContainerAdd.description"
                                        defaultMessage="Description: "
                                    />
                                    <Select
                                        currentIndex={containerSelectedIndex}
                                        itemsDisplayValues={containerSelectItems.map((items) => items.description)}
                                        onSelect={(index) => {
                                            setNewStorageContainer({
                                                ...newStorageContainer,
                                                storageContainerTypeId: containerSelectItems[index].id,
                                            });
                                        }}
                                    >
                                        {containerSelectItems.map((item) => (
                                            <SelectItem title={item.description} key={item.id} />
                                        ))}
                                    </Select>
                                </View>
                                <View style={newStorageContainerPageStyles.ViewDescription}>
                                    <FormattedMessage
                                        id="storageContainerAdd.status"
                                        defaultMessage="Status: "
                                    />
                                    <Select
                                        currentIndex={selectIndex}
                                        itemsDisplayValues={selectItems.map((items) => items.title)}
                                        onSelect={(index) => setNewStorageContainer({ ...newStorageContainer, status: selectItems[index].unit })}
                                    >
                                        {selectItems.map((item) => (
                                            <SelectItem title={item.title} key={item.id} />
                                        ))}
                                    </Select>
                                </View>

                                <View style={newStorageContainerPageStyles.ViewDescription}>
                                    <FormattedMessage
                                        id="storageContainerAdd.barcode"
                                        defaultMessage="Barcode: "
                                    />
                                    <TextInput
                                        type="text"
                                        ariaLabel="Barcode: "
                                        value={newStorageContainer.barcode || ''}
                                        onChange={(value: any) => {
                                            setNewStorageContainer({ ...newStorageContainer, barcode: value });
                                        }}
                                    />
                                </View>
                            </View>
                        </View>
                    </View>
                    <View
                        style={{
                            marginVertical: 10,
                            marginHorizontal: 10,
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        <View style={{ flex: 1, marginRight: 10 }}>
                            <Button
                                title={intl.formatMessage({
                                    id: 'storageContainerAdd.Cancel',
                                    defaultMessage: 'Cancel',
                                })}
                                onPress={handleBacklClick}
                            />
                        </View>
                        <View style={{ flex: 1 }}>
                            <Button
                                title={intl.formatMessage({
                                    id: 'storageContainerAdd.Add',
                                    defaultMessage: 'Add',
                                })}
                                onPress={handleAddStorageContainerClick}
                            />
                        </View>
                    </View>
                </View>}
        </ScrollView>
    );
};

const newStorageContainerPageStyles = StyleSheet.create({
    ViewParent: {
        display: 'flex',
        flexDirection: 'column',
    },
    ViewTabs: {
        marginVertical: 5,
        display: 'flex',
        flexDirection: 'row',
    },
    ViewContent: {
        paddingVertical: 5,
    },
    TextTab: {
        flex: 1,
        color: '#000',
        backgroundColor: '#D4D4D5',
        fontSize: 18,
        textAlign: 'center',
        fontWeight: '700',
    },

    ViewChild: {
        display: 'flex',
        flexDirection: 'row',
    },
    ViewImageParent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    RouteImage: {
        height: 40,
        width: 40,
        resizeMode: 'stretch',
    },
    ViewContentParent: {
        paddingHorizontal: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    ViewTitleParent: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 5,
    },
    ViewTitleChild: {},
    TextTitle: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 10,
    },
    ViewDescription: {
        marginTop: 10,
        fontWeight: 'bold',
    },
    TextDescription: { fontSize: 14 },
    ViewStatus: {},
    TextStatus: {
        fontSize: 12,
        fontWeight: '700',
    },
    ViewDate: {},
    TextDate: {
        fontSize: 12,
    },
    ViewErrorMessageParent: {
        marginTop: 5,
        marginBottom: 5,
    },
    TextContainerItem: {
        marginVertical: 2,
        display: 'flex',
        flexDirection: 'row',
        fontWeight: 'bold',
    },
    ViewImage: {
        paddingHorizontal: 5,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
});
