import { gql } from '@apollo/client';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { FlatList, ScrollView, StyleSheet, Text, View } from 'react-native';
import { Button, Card, TopTab, TopTabBar } from '@valet/ui-components';
import {
    RefileListFragment,
    RefileListStatus,
    useRefileListGetRefilesQuery,
} from '../../../schema-types';
import { useHistory } from 'react-router-dom';

export const refileListsPageSize = 100;

const REFILELIST_FRAGMENT = gql`
    fragment refileList on RefileList {
        id
        refileListNumber
        status
        created_at
    }
`;

export const RETRIEVE_REFILELIST_QUERY = gql`
    query refileListGetRefiles($pageSize: Int!, $cursor: String!) {
        refileLists(filter: { pageSize: $pageSize, after: $cursor }) {
            edges {
                cursor
                node {
                    id
                    ...refileList
                }
            }
            pageInfo {
                cursor {
                    beforeCursor
                    afterCursor
                }
                hasNextPage
                hasPreviousPage
            }
        }
    }

    ${REFILELIST_FRAGMENT}
`;

type RefileListPageProps = {
    onRefileListSelect: (refileListId: string) => void;
    onAddRefileListPressed: () => void;
};

export const RefileListPage: React.FC<RefileListPageProps> = ({
    onRefileListSelect,
    onAddRefileListPressed,
}) => {
    const intl = useIntl();
    const history = useHistory();

    const [isFetching, setIsFetching] = useState<boolean>(false);

    const {
        data: retrievedRoutesData,
        loading: retrievedRoutesLoading,
        error: retrievedRoutesError,
        fetchMore: retrievedRoutesFetchMore,
    } = useRefileListGetRefilesQuery({
        variables: {
            pageSize: refileListsPageSize,
            cursor: '',
        },
    });

    const refileListEdges = retrievedRoutesData?.refileLists.edges ?? [];
    const refileListsData = useMemo(() => {
        return {
            refileLists: refileListEdges?.map((itemEdge) => itemEdge.node) ?? [],
            hasNextPage: retrievedRoutesData?.refileLists?.pageInfo?.hasNextPage ?? false,
            endCursor: retrievedRoutesData?.refileLists?.pageInfo?.cursor.afterCursor ?? '',
        };
    }, [refileListEdges, retrievedRoutesData]);

    const handleLoadMoreItems = useCallback((): void => {
        if (refileListsData.hasNextPage && !isFetching) {
            setIsFetching(true);

            retrievedRoutesFetchMore({
                variables: {
                    pageSize: refileListsPageSize,
                    cursor: refileListsData.endCursor,
                },
            }).then(() => {
                setIsFetching(false);
            });
        }
    }, [isFetching, refileListsData, retrievedRoutesFetchMore]);

    useEffect(() => {
        if (!retrievedRoutesError && refileListsData.hasNextPage) {
            handleLoadMoreItems();
        }
    }, [handleLoadMoreItems, refileListsData, retrievedRoutesError]);

    const handleItemClick = useCallback(
        (id: string) => {
            console.log(id);
            onRefileListSelect(id);
        },
        [onRefileListSelect],
    );

    const [currentTab, setCurrentTab] = useState<number>(0);

    const refiles = useMemo<{
        completed: RefileListFragment[];
        open: RefileListFragment[];
    }>(() => {
        const completed = [];
        const open = [];
        for (let i = 0; i < refileListsData.refileLists.length; i++) {
            const item = refileListsData.refileLists[i];
            if (item.status === RefileListStatus.Completed) {
                completed.push(item);
            }

            if (
                item.status === RefileListStatus.Created ||
                item.status === RefileListStatus.InProgress
            ) {
                open.push(item);
            }
        }

        return {
            completed,
            open,
        };
    }, [refileListsData]);

    const handleAddClick = useCallback(() => {
        onAddRefileListPressed();
    }, [onAddRefileListPressed]);

    const handleBacklClick = useCallback(async () => {
        history.push(`/admin`);
    }, [history]);

    return (
        <ScrollView>
            <View testID="data-refileListDataView">
                <View style={refileListPageStyles.ViewTabs}>
                    <View>
                        <View style={{ flex: 1, marginRight: 2 }}>
                            <Button title="Back" onPress={handleBacklClick} />
                        </View>
                    </View>
                    <View>
                        <View style={{ flex: 1, marginRight: 10, marginBottom: 10 }}>
                            <Button title="+ Create List" onPress={handleAddClick} />
                        </View>
                    </View>
                </View>
                <TopTabBar selectedIndex={currentTab} onSelect={(index) => setCurrentTab(index)}>
                    <TopTab
                        title={intl.formatMessage({
                            id: 'refileList.openTabText',
                            defaultMessage: 'Open',
                        })}
                    />
                    <TopTab
                        title={intl.formatMessage({
                            id: 'refileList.completedTabText',
                            defaultMessage: 'Completed',
                        })}
                    />
                </TopTabBar>

                <View style={refileListPageStyles.ViewParent} testID="data-delivery">
                    {!retrievedRoutesLoading &&
                        !retrievedRoutesData?.refileLists.pageInfo.hasNextPage &&
                        retrievedRoutesData && (
                            <View style={refileListPageStyles.ViewContent}>
                                {currentTab === 0 ? (
                                    refiles.open.length > 0 ? (
                                        <FlatList
                                            data={refiles.open}
                                            renderItem={({ item }) => (
                                                <RouteItem
                                                    key={item.id}
                                                    refileList={item}
                                                    onItemClick={handleItemClick}
                                                />
                                            )}
                                            keyExtractor={(item) => item.id}
                                        />
                                    ) : (
                                        <Text>
                                            <FormattedMessage
                                                id="refileList.noDeliveriesText"
                                                defaultMessage="There are no refile list."
                                            />
                                        </Text>
                                    )
                                ) : currentTab === 1 ? (
                                    refiles.completed.length > 0 ? (
                                        <FlatList
                                            data={refiles.completed}
                                            renderItem={({ item }) => (
                                                <RouteItem
                                                    key={item.id}
                                                    refileList={item}
                                                    onItemClick={handleItemClick}
                                                />
                                            )}
                                            keyExtractor={(item) => item.id}
                                        />
                                    ) : (
                                        <Text>
                                            <FormattedMessage
                                                id="refileList.noCompletedDeliveriesText"
                                                defaultMessage="no completed deliveries."
                                            />
                                        </Text>
                                    )
                                ) : (
                                    <Text>
                                        <FormattedMessage
                                            id="refileList.noDeliveriesText"
                                            defaultMessage="There are no refile list."
                                        />
                                    </Text>
                                )}
                            </View>
                        )}
                </View>
            </View>
        </ScrollView>
    );
};

const refileListPageStyles = StyleSheet.create({
    ViewParent: {
        display: 'flex',
        flexDirection: 'column',
    },
    ViewTabs: {
        marginVertical: 5,
        display: 'flex',
        flexDirection: 'row',
    },
    ViewContent: {
        paddingVertical: 5,
    },
    TextTab: {
        flex: 1,
        color: '#000',
        backgroundColor: '#D4D4D5',
        fontSize: 18,
        textAlign: 'center',
        fontWeight: '700',
    },
});

type RouteItemProps = {
    refileList: RefileListFragment;
    onItemClick: (id: string) => void;
};

const RouteItem: React.FC<RouteItemProps> = ({ refileList, onItemClick }) => {
    const { refileListNumber, created_at, status } = refileList;
    const intl = useIntl();

    return (
        <Card
            style={refileListItemStyles.ViewParent}
            onPress={() => onItemClick(refileList.id)}
            testID="data-refileListCardItem"
            footer={(props) => (
                <View style={[props?.style]}>
                    <View style={refileListItemStyles.ViewStatus}>
                        <Text style={refileListItemStyles.TextStatus}>{status}</Text>
                    </View>

                    <View style={refileListItemStyles.ViewDate}>
                        <Text
                            style={refileListItemStyles.TextDate}
                            testID="data-refileListCardItemCreationDate"
                        >
                            {new Date(created_at).toLocaleString()}
                        </Text>
                    </View>
                </View>
            )}
        >
            <View style={refileListItemStyles.ViewChild}>
                <View style={refileListItemStyles.ViewContentParent}>
                    <View style={refileListItemStyles.ViewTitleParent}>
                        <Text
                            style={refileListItemStyles.TextTitle}
                            testID="data-refileListCardItemDescription"
                        >
                            {`${intl.formatMessage({
                                id: 'refileListCardItem.RefileNumber',
                                defaultMessage: 'Refile #',
                            })}${refileListNumber}`}
                        </Text>
                    </View>
                </View>
            </View>
        </Card>
    );
};

const refileListItemStyles = StyleSheet.create({
    ViewParent: {
        marginVertical: 3,
    },
    ViewChild: {
        display: 'flex',
        flexDirection: 'row',
    },

    ViewContentParent: {
        paddingHorizontal: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    ViewTitleParent: {
        display: 'flex',
        flexDirection: 'column',
    },
    ViewTitleChild: {},
    TextTitle: {
        fontSize: 18,
    },
    ViewDescription: {},
    TextDescription: { fontSize: 14 },
    ViewStatus: {},
    TextStatus: {
        fontSize: 12,
        fontWeight: '700',
    },
    ViewDate: {},
    TextDate: {
        fontSize: 12,
    },
});
