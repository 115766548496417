import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Platform } from 'react-native';
import { useDrag } from 'react-dnd';
import { Text, View, FlatList, ListRenderItem, StyleSheet } from 'react-native';
import {
    Button,
    Card,
    Icon,
    Message,
    Modal,
    Select,
    SelectItem,
    Timepicker,
} from '@valet/ui-components';
import { RouteData, RouteStopData, VisitData } from './Routing';
import { VisitInformation } from './VisitInformation';
import { FormattedMessage, useIntl } from 'react-intl';
import { format, isAfter, isBefore } from 'date-fns';
import { gql } from '@apollo/client';
import {
    useRoutingUpdateRouteStopTimeMutation,
    RoutingVisitInformationFragmentDoc,
    useRoutingRouteDetailsEmployeesQuery,
    useRoutingRouteDetailsVehiclesQuery,
    useRoutingSetRouteEmployeesMutation,
    useRoutingSetRouteVehicleMutation,
} from '../../../schema-types';

type RouteStopDataWithVisit = RouteStopData & { visit: VisitData };

export const ROUTING_DETAILS_EMPLOYEES_QUERY = gql`
    query RoutingRouteDetailsEmployees {
        employees(filter: {}) {
            edges {
                node {
                    id
                    firstName
                    lastName
                }
            }
            pageInfo {
                cursor {
                    beforeCursor
                    afterCursor
                }
                hasNextPage
                hasPreviousPage
            }
        }
    }
`;

export const ROUTING_DETAILS_VEHICLES_QUERY = gql`
    query RoutingRouteDetailsVehicles {
        vehicles(filter: {}) {
            edges {
                node {
                    id
                    description
                }
            }
            pageInfo {
                cursor {
                    beforeCursor
                    afterCursor
                }
                hasNextPage
                hasPreviousPage
            }
        }
    }
`;

export const ROUTING_SET_ROUTE_EMPLOYEES_MUTATION = gql`
    mutation RoutingSetRouteEmployees($routeId: String!, $employeeIds: [String!]!) {
        routingSetEmployees(data: { routeId: $routeId, employeeIds: $employeeIds }) {
            route {
                id
                employees {
                    id
                }
            }
        }
    }
`;

export const ROUTING_SET_ROUTE_VEHICLE_MUTATION = gql`
    mutation RoutingSetRouteVehicle($routeId: String!, $vehicleId: String) {
        routingSetVehicle(data: { routeId: $routeId, vehicleId: $vehicleId }) {
            route {
                id
                vehicles {
                    id
                }
            }
        }
    }
`;

export const ROUTING_UPDATE_ROUTE_STOP_TIME_MUTATION = gql`
    mutation RoutingUpdateRouteStopTime($routeStopId: ID!, $startTime: DateTime!) {
        routingUpdateStop(data: { routeStopId: $routeStopId, startTime: $startTime }) {
            routeStop {
                id
                startTime
                visit {
                    ...RoutingVisitInformation
                }
            }
        }
    }

    ${RoutingVisitInformationFragmentDoc}
`;

type RouteDetailsProps = {
    route: RouteData;
    routeStops: RouteStopDataWithVisit[];
    onRemoveVisitFromRoute?: (routeStopId: RouteStopData['id']) => void;
};

export const RouteDetails: React.FC<RouteDetailsProps> = ({
    route,
    routeStops,
    onRemoveVisitFromRoute = () => undefined,
}) => {
    // TODO: Handle loading and error states
    const {
        data: employeesData,
        loading: employeesDataLoading,
        error: employeesDataError,
        fetchMore: employeesDataFetchMore,
    } = useRoutingRouteDetailsEmployeesQuery();

    const {
        data: vehiclesData,
        loading: vehiclesDataLoading,
        error: vehiclesDataError,
        fetchMore: vehiclesDataFetchMore,
    } = useRoutingRouteDetailsVehiclesQuery();

    useEffect(() => {
        if (
            !employeesDataError &&
            !employeesDataLoading &&
            employeesData?.employees.pageInfo.hasNextPage
        ) {
            employeesDataFetchMore({
                variables: {
                    cursor: employeesData.employees.pageInfo.cursor.afterCursor,
                },
            });
        }
    }, [employeesDataError, employeesDataLoading, employeesData, employeesDataFetchMore]);

    useEffect(() => {
        if (
            !vehiclesDataError &&
            !vehiclesDataLoading &&
            vehiclesData?.vehicles.pageInfo.hasNextPage
        ) {
            vehiclesDataFetchMore({
                variables: {
                    cursor: vehiclesData.vehicles.pageInfo.cursor.afterCursor,
                },
            });
        }
    }, [vehiclesDataError, vehiclesDataLoading, vehiclesData, vehiclesDataFetchMore]);

    const employees = useMemo(
        () => employeesData?.employees.edges.map((employeeNode) => employeeNode.node) ?? [],
        [employeesData],
    );

    const vehicles = useMemo(
        () => vehiclesData?.vehicles.edges.map((vehicleNode) => vehicleNode.node) ?? [],
        [vehiclesData],
    );

    const sortedRouteStops = useMemo(
        () =>
            routeStops.sort((routeStop1, routeStop2) => {
                if (routeStop1.time > routeStop2.time) {
                    return 1;
                }
                if (routeStop1.time < routeStop2.time) {
                    return -1;
                }
                return 0;
            }),
        [routeStops],
    );

    const [updateRouteStopTime] = useRoutingUpdateRouteStopTimeMutation();
    const [setRouteVehicle] = useRoutingSetRouteVehicleMutation();
    const [setRouteEmployees] = useRoutingSetRouteEmployeesMutation();

    const renderItem: ListRenderItem<RouteStopDataWithVisit> = ({ item: routeStop, index }) =>
        routeStop.visit ? (
            <>
                <RouteDetailsVisitListItem
                    visit={routeStop.visit}
                    routeStopTime={routeStop.time}
                    onRemoveFromRoute={() =>
                        routeStop.visit ? onRemoveVisitFromRoute(routeStop.id) : undefined
                    }
                    onRouteStopTimeChange={async (newTime) => {
                        try {
                            await updateRouteStopTime({
                                variables: {
                                    routeStopId: routeStop.id,
                                    startTime: newTime,
                                },
                            });
                        } catch (e) {
                            // TODO: Proper error management
                            alert('Error:' + e.message);
                        }
                    }}
                />

                {index < sortedRouteStops.length - 1 ? (
                    <Text
                        style={
                            Math.round(
                                (sortedRouteStops[index + 1].time - routeStop.time) / 1000 / 60 -
                                    (routeStop.visit.expectedDuration ?? 0),
                            ) <= 0
                                ? menuListStyles.TextTimeBetweenVisitsWarning
                                : menuListStyles.TextTimeBetweenVisits
                        }
                    >
                        <FormattedMessage
                            id="routeDetail.timeBetweenVisitsLabel"
                            defaultMessage={`Time between visits: {time} minutes`}
                            values={{
                                time: Math.round(
                                    (sortedRouteStops[index + 1].time - routeStop.time) /
                                        1000 /
                                        60 -
                                        (routeStop.visit.expectedDuration ?? 0),
                                ),
                                /* TODO: Format time properly */
                            }}
                        />
                    </Text>
                ) : undefined}
            </>
        ) : (
            <></>
        );

    return (
        <>
            <View>
                <Text>{route.description}</Text>
            </View>

            <View style={menuListStyles.ViewSelectActions}>
                <Text>
                    <FormattedMessage id="routeDetail.employees" defaultMessage="Employees" />
                </Text>

                <Select
                    currentIndex={
                        1 +
                        employees.findIndex((employee) =>
                            route?.employees?.length
                                ? employee.id === route.employees[0].id
                                : false,
                        )
                    }
                    onSelect={(index) => {
                        setRouteEmployees({
                            variables: {
                                routeId: route.id,
                                employeeIds:
                                    index > 0 && index <= employees.length
                                        ? [employees[index - 1].id]
                                        : [],
                            },
                        });
                    }}
                    itemsDisplayValues={[
                        ' ',
                        ...(employees.map(
                            (employee) => `${employee.firstName} ${employee.lastName}`,
                        ) ?? []),
                    ]}
                >
                    {[
                        <SelectItem title="" key={'NONE'} />,
                        ...(employees.map((employee) => (
                            <SelectItem
                                title={`${employee.firstName} ${employee.lastName}`}
                                key={employee.id}
                            />
                        )) ?? []),
                    ]}
                </Select>
            </View>

            <View style={menuListStyles.ViewSelectActions}>
                <Text>
                    <FormattedMessage id="routeDetail.vehicle" defaultMessage="Vehicle" />
                </Text>

                <Select
                    currentIndex={
                        1 +
                        vehicles.findIndex((vehicle) =>
                            route.vehicles?.length ? vehicle.id === route.vehicles[0].id : false,
                        )
                    }
                    onSelect={(index) =>
                        setRouteVehicle({
                            variables: {
                                routeId: route.id,
                                vehicleId:
                                    index > 0 && index <= vehicles.length
                                        ? vehicles[index - 1].id
                                        : undefined,
                            },
                        })
                    }
                    itemsDisplayValues={[
                        ' ',
                        ...(vehicles.map((vehicle) => `${vehicle.description}`) ?? []),
                    ]}
                >
                    {[
                        <SelectItem title="" key={'NONE'} />,
                        ...(vehicles.map((vehicle) => (
                            <SelectItem title={`${vehicle.description}`} key={vehicle.id} />
                        )) ?? []),
                    ]}
                </Select>
            </View>

            <FlatList
                data={sortedRouteStops}
                extraData={[onRemoveVisitFromRoute]}
                renderItem={renderItem}
            />
        </>
    );
};

type RouteDetailsVisitListItemProps = {
    visit: VisitData;
    routeStopTime: number;
    onRemoveFromRoute?: () => void;
    onRouteStopTimeChange?: (newTime: Date) => void;
};

const RouteDetailsVisitListItemContent: React.FC<RouteDetailsVisitListItemProps> = ({
    visit,
    routeStopTime,
    onRemoveFromRoute = () => undefined,
    onRouteStopTimeChange = () => undefined,
}) => {
    const intl = useIntl();

    const [displayModal, setDisplayModal] = useState<boolean>(false);

    const handleRemoveRouteStop = useCallback(() => {
        onRemoveFromRoute();
    }, [onRemoveFromRoute]);

    const handleChangeRouteStopTimeValue = useCallback(
        (value: Date) => {
            onRouteStopTimeChange(value);
        },
        [onRouteStopTimeChange],
    );

    return (
        <>
            <Modal visible={displayModal} onBackdropPress={() => setDisplayModal(false)}>
                <Card>
                    <Text>
                        <FormattedMessage
                            id="routeDetail.customerNameLabel"
                            defaultMessage={`Name: {firstName} {lastName}`}
                            values={{
                                firstName: visit.customer.firstName,
                                lastName: visit.customer.lastName,
                            }}
                        />
                    </Text>
                    <Text>
                        <FormattedMessage
                            id="routeDetail.customerEmailLabel"
                            defaultMessage={`Email: {email}`}
                            values={{ email: visit.customer.email }}
                        />
                    </Text>
                    <Text>
                        <FormattedMessage
                            id="routeDetail.customerHomePhoneLabel"
                            defaultMessage={`Home phone: {phone}`}
                            values={{
                                phone:
                                    visit.customer.homePhone ??
                                    intl.formatMessage({
                                        id: 'routeDetail.notAvailableValue',
                                        defaultMessage: 'N/A',
                                    }),
                            }}
                        />
                    </Text>
                    <Text>
                        <FormattedMessage
                            id="routeDetail.customerCellPhoneLabel"
                            defaultMessage={`Cell phone: {phone}`}
                            values={{
                                phone:
                                    visit.customer.cellPhone ??
                                    intl.formatMessage({
                                        id: 'routeDetail.notAvailableValue',
                                        defaultMessage: 'N/A',
                                    }),
                            }}
                        />
                    </Text>
                </Card>
            </Modal>

            <Card style={menuListStyles.ViewListItemContainer} disabled>
                <View style={menuListStyles.ViewListItemTimeDetails}>
                    <View style={{ flexGrow: 0 }}>
                        <Timepicker
                            value={new Date(routeStopTime)}
                            onBlur={handleChangeRouteStopTimeValue}
                        />
                    </View>

                    <View style={{ marginLeft: 5, flexGrow: 1 }}>
                        {visit.expectedDuration !== undefined && visit.expectedDuration !== null ? (
                            <Text>
                                <FormattedMessage
                                    id="routeDetail.visitExpectedDurationLabel"
                                    defaultMessage={`{expectedDuration} minutes`}
                                    values={{
                                        expectedDuration: visit.expectedDuration,
                                    }}
                                />
                            </Text>
                        ) : (
                            <Text style={{ fontStyle: 'italic' }}>
                                <FormattedMessage
                                    id="routeDetail.visitNotReviewed"
                                    defaultMessage={`Not reviewed`}
                                />
                            </Text>
                        )}
                    </View>

                    <View style={{ marginLeft: 5, flexGrow: 0 }}>
                        <Button
                            accessoryLeft={() => <Icon icon="delete" />}
                            onPress={handleRemoveRouteStop}
                            title={''}
                            appearance="ghost"
                            accessibilityLabel={intl.formatMessage({
                                id: 'routeDetail.removeFromRoute',
                                defaultMessage: 'Remove from route',
                            })}
                        />
                    </View>
                </View>

                <View style={{ flex: 1, marginLeft: 18 }}>
                    <View style={menuListStyles.ViewListItemBody}>
                        <VisitInformation visit={visit} />

                        <Button
                            title={intl.formatMessage({
                                id: 'routeDetail.contactInfoModalToggleButton',
                                defaultMessage: 'Contact Info',
                            })}
                            onPress={() => setDisplayModal(true)}
                            appearance="outline"
                            size="tiny"
                        />

                        <Text>
                            <FormattedMessage
                                id="routeDetail.visitDeliveryWindowLabel"
                                defaultMessage={`Delivery Window: {startTime} - {endTime}`}
                                values={{
                                    startTime: format(new Date(visit.startTime), 'h:mm aa'),
                                    endTime: format(new Date(visit.endTime), 'h:mm aa'),
                                }}
                            />
                        </Text>

                        {isBefore(new Date(routeStopTime), new Date(visit.startTime)) ||
                        isAfter(new Date(routeStopTime), new Date(visit.endTime)) ? (
                            <Message
                                type="warning"
                                content={intl.formatMessage({
                                    id: 'routeDetail.outsideRequestedDeliveryWindow',
                                    defaultMessage: `Outside requested delivery window`,
                                })}
                            />
                        ) : undefined}
                    </View>
                </View>
            </Card>
        </>
    );
};

const RouteDetailsVisitListItem: React.FC<RouteDetailsVisitListItemProps> = (props) => {
    const { visit } = props;

    const [, drag] = useDrag({ item: { type: 'visit', id: visit.id } });

    if (Platform.OS === 'web') {
        return (
            <div ref={drag}>
                <RouteDetailsVisitListItemContent {...props} />
            </div>
        );
    }

    return <RouteDetailsVisitListItemContent {...props} />;
};

const menuListStyles = StyleSheet.create({
    ViewListItemContainer: {
        flexDirection: 'column',
        paddingVertical: 4,
        paddingRight: 4,
    },
    ViewListItemBody: {
        flex: 1,
        flexDirection: 'column',
    },
    ViewListItemActions: {
        padding: 5,
        flexDirection: 'row',
        justifyContent: 'center',
        flex: 1,
        display: 'flex',
    },
    ViewListItemTimeDetails: {
        display: 'flex',
        flexDirection: 'row',
        paddingRight: 2,
        alignItems: 'center',
    },
    ViewSelectActions: { paddingVertical: 5 },
    TextTimeBetweenVisits: {
        textAlign: 'center',
        paddingVertical: 3,
    },
    TextTimeBetweenVisitsWarning: {
        textAlign: 'center',
        paddingVertical: 3,
        color: 'red',
    },
});
