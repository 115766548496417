import React from 'react';
import { ScrollView } from 'react-native';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { MovingProcessPage } from './MovingProcessPage';

const RefileListRoutes: React.FC = () => {
    const { url } = useRouteMatch();

    return (
        <ScrollView>
            <Switch>
                <Route path={`${url}`}>
                    <MovingProcessPage />
                </Route>
            </Switch>
        </ScrollView>
    );
};

export default RefileListRoutes;
