import React, { useState, useCallback } from 'react';
import { gql } from '@apollo/client';

import { Button, ErrorMessage, Icon, TextInput, Message } from '@valet/ui-components';
import { ScrollView, View, Text, Image, TouchableOpacity, StyleSheet } from 'react-native';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    usePickingSortItemMutation,
    useLocationByBarcodeLazyQuery,
    useStorageItemByBarcodeLazyQuery,
    StorageItemStatus,
    SortingLocationFragment,
    SortingStorageItemFragment,
} from '../../../schema-types';
//import { storageItems, locations as mockLocations } from './_MockData';

const SORTING_LOCATION_FRAGMENT = gql`
    fragment SortingLocation on Location {
        id
        barcode
        description
        sortedItems
        itemsToSort
    }
`;

const SORTING_STORAGE_ITEM_FRAGMENT = gql`
    fragment SortingStorageItem on StorageItem {
        id
        status
        barcode
        location {
            id
            ...SortingLocation
        }
        storageItemType {
            id
            name
            description
            image
            metadataDefinitions {
                propertyName
                isRequired
                propertyType
                values
                isActive
                sortOrder
            }
        }
        latestVisit {
            id
            locations {
                id
                ...SortingLocation
            }
        }
    }

    ${SORTING_LOCATION_FRAGMENT}
`;

export const RETRIEVE_LOCATION_BY_BARCODE_QUERY = gql`
    query locationByBarcode($barcode: String!) {
        locationByBarcode(barcode: $barcode) {
            id
            ...SortingLocation
        }
    }

    ${SORTING_LOCATION_FRAGMENT}
`;

export const RETRIEVE_STORAGE_ITEM_BY_BARCODE_QUERY = gql`
    query storageItemByBarcode($barcode: String!) {
        storageItemByBarcode(barcode: $barcode) {
            id
            ...SortingStorageItem
        }
    }

    ${SORTING_STORAGE_ITEM_FRAGMENT}
`;

export const PICKUP_REQUEST_ADD_TO_VISIT_MUTATION = gql`
    mutation PickingSortItem($pickingSortItem: PickingSortItemInput!) {
        pickingSortItem(data: $pickingSortItem) {
            storageItem {
                id
            }
        }
    }
`;

type SortingPageProps = {};

export const SortingPage: React.FC<SortingPageProps> = () => {
    const intl = useIntl();

    const [errorMessage, setErrorMessage] = useState<ErrorMessage | undefined>(undefined);
    const [expandBarcodeManualEntry, setExpandBarcodeManualEntry] = useState<boolean>(false);
    const [barcodeEntryValue, setBarcodeEntryValue] = useState<string>('');
    const [currentItem, setCurrentItem] = useState<SortingStorageItemFragment | undefined>(
        undefined,
    );
    const [currentLocation, setCurrentLocation] = useState<SortingLocationFragment | undefined>(
        undefined,
    );

    const handleManualScanCameraPress = useCallback((): void => {
        console.log('Pressed Manual Scan Camera Icon');
    }, []);

    const handleManualScanKeyboardPress = useCallback((): void => {
        setExpandBarcodeManualEntry(!expandBarcodeManualEntry);
    }, [expandBarcodeManualEntry]);

    const handleClearScreenPress = useCallback((): void => {
        setErrorMessage(undefined);
        setCurrentItem(undefined);
        setCurrentLocation(undefined);
        setExpandBarcodeManualEntry(false);
    }, []);

    const [locationByBarcode, { loading }] = useLocationByBarcodeLazyQuery({
        onCompleted: async (data) => {
            //TODO: Validation for location

            setCurrentLocation(data?.locationByBarcode ?? undefined);

            if (currentItem?.id && data?.locationByBarcode?.id) {
                await sortItemMutation({
                    variables: {
                        pickingSortItem: {
                            storageItemId: currentItem?.id,
                            locationId: data?.locationByBarcode?.id,
                        },
                    },
                });
            }

            setCurrentItem(undefined);
        },
        onError: (err) =>
            setErrorMessage({
                content: err.message,
                type: 'negative',
                header: 'Error',
            }),
    });

    const [
        storageItemByBarcode,
        { loading: storageItemLoading },
    ] = useStorageItemByBarcodeLazyQuery({
        onCompleted: (data) => {
            if (data.storageItemByBarcode) {
                if (data.storageItemByBarcode?.status !== StorageItemStatus.InStorage) {
                    setErrorMessage({
                        content: intl.formatMessage({
                            id: 'sorting.errorSortItemWithWrongStatus',
                            defaultMessage:
                                'Storage Item status has to be IN_STORAGE for sorting operation',
                        }),
                        type: 'negative',
                        header: 'Error',
                    });
                }

                setCurrentItem(data?.storageItemByBarcode);
            }

            setCurrentLocation(undefined);
        },
        onError: (err) =>
            setErrorMessage({
                content: err.message,
                type: 'negative',
                header: 'Error',
            }),
    });

    const [
        sortItemMutation,
        { loading: sortItemMutationLoading, error: sortItemMutationError },
    ] = usePickingSortItemMutation();

    const handleManualScanSubmit = useCallback(async () => {
        if (barcodeEntryValue) {
            setErrorMessage(undefined);
            if (currentItem) {
                locationByBarcode({
                    variables: {
                        barcode: barcodeEntryValue,
                    },
                });
            } else {
                storageItemByBarcode({
                    variables: {
                        barcode: barcodeEntryValue,
                    },
                });
            }
            setExpandBarcodeManualEntry(!expandBarcodeManualEntry);
            setBarcodeEntryValue('');
        }
    }, [
        barcodeEntryValue,
        expandBarcodeManualEntry,
        currentItem,
        locationByBarcode,
        storageItemByBarcode,
    ]);

    if (loading || storageItemLoading || sortItemMutationLoading) {
        return (
            <View testID="data-sortingPage">
                <Text>
                    <FormattedMessage id="sorting.loading" defaultMessage="Loading..." />
                </Text>
            </View>
        );
    }

    if (sortItemMutationError) {
        return (
            <View style={sortingPageStyles.ViewErrorMessageParent} testID="data-errorMessage">
                <Message
                    type="negative"
                    header={intl.formatMessage({
                        id: 'sortingPage.sortItemError',
                        defaultMessage: 'Could not sort this item - please try again',
                    })}
                    content={sortItemMutationError.message}
                    testID="data-sortItemMessage"
                />
            </View>
        );
    }

    if (errorMessage) {
        return (
            <View style={sortingPageStyles.ViewErrorMessageParent} testID="data-errorMessage">
                <SortingErrorMessage errorMessage={errorMessage} />
            </View>
        );
    }

    return (
        <ScrollView>
            <View style={sortingPageStyles.ViewParent} testID="data-sorting">
                <View
                    style={sortingPageStyles.ViewActionsGroup}
                    testID="data-sortingActionsContainer"
                >
                    <View style={{ display: 'flex', flexDirection: 'row' }}>
                        {!currentItem && (
                            <Text style={sortingPageStyles.TextHeader}>
                                <FormattedMessage
                                    id="sorting.scanItemDesc"
                                    defaultMessage="Scan an Item then scan location to assign to."
                                />
                            </Text>
                        )}
                        {currentItem && (
                            <Text style={sortingPageStyles.TextHeader}>
                                <FormattedMessage
                                    id="sorting.scanLocationDesc"
                                    defaultMessage="Scan a location to assign the scaned item to that location."
                                />
                            </Text>
                        )}
                    </View>
                </View>

                {currentItem && (
                    <View style={sortingPageStyles.ViewItem}>
                        <SortingItem details={currentItem} key={currentItem.id} />
                    </View>
                )}

                {currentLocation && (
                    <View style={sortingPageStyles.ViewItem}>
                        <SortingLocation details={currentLocation} key={currentLocation.id} />
                    </View>
                )}

                <View
                    style={sortingPageStyles.ViewActionsGroup}
                    testID="data-sortingActionsContainer"
                >
                    {expandBarcodeManualEntry && (
                        <View
                            style={{
                                padding: 3,
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                            testID="data-sortingManualBarcodeEntryContainer"
                        >
                            <View style={{ flex: 1, marginRight: 5 }}>
                                <TextInput
                                    type="text"
                                    onChange={(e) => setBarcodeEntryValue(e)}
                                    value={barcodeEntryValue}
                                    ariaLabel={intl.formatMessage({
                                        id: 'sortingManualBarcodeEntryPlaceholderText',
                                        defaultMessage: 'Enter item barcode',
                                    })}
                                    placeholder={intl.formatMessage({
                                        id: 'sortingManualBarcodeEntryPlaceholderText',
                                        defaultMessage: 'Enter item barcode',
                                    })}
                                    testID="data-sortingManualBarcodeEntryInput"
                                />
                            </View>

                            <View style={{ marginLeft: 5 }}>
                                <Button
                                    onPress={handleManualScanSubmit}
                                    title={intl.formatMessage({
                                        id: 'sortingScanButton',
                                        defaultMessage: 'SCAN',
                                    })}
                                    testID="data-sortingManualBarcodeEntrySubmitButton"
                                />
                            </View>
                        </View>
                    )}

                    <View style={{ display: 'flex', flexDirection: 'row' }}>
                        <View style={sortingPageStyles.ViewManualScanIcons}>
                            {currentItem && (
                                <TouchableOpacity
                                    onPress={handleClearScreenPress}
                                    style={[{ marginLeft: 5, flex: 1 }]}
                                    testID="data-sortingActionKeyboard"
                                >
                                    <Icon icon="clearScreen" />
                                </TouchableOpacity>
                            )}

                            <TouchableOpacity
                                onPress={handleManualScanCameraPress}
                                style={[{ marginRight: 5 }]}
                                testID="data-sortingActionCamera"
                            >
                                <Icon icon="barcodeCamera" />
                            </TouchableOpacity>

                            <TouchableOpacity
                                onPress={handleManualScanKeyboardPress}
                                style={[{ marginLeft: 5 }]}
                                testID="data-sortingActionKeyboard"
                            >
                                <Icon icon="barcodeKeyboard" />
                            </TouchableOpacity>
                        </View>
                    </View>
                </View>
            </View>
        </ScrollView>
    );
};

const sortingPageStyles = StyleSheet.create({
    ViewParent: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    ViewActionsGroup: {
        marginVertical: 5,
        display: 'flex',
        flexDirection: 'column',
        borderTopColor: '#D4D4D5',
        borderTopWidth: 1,
    },
    ViewHeader: {
        marginVertical: 5,
        display: 'flex',
        flexDirection: 'column',
        borderTopColor: '#D4D4D5',
        borderTopWidth: 1,
    },
    ViewExpandPickList: {
        flex: 1,
        paddingVertical: 5,
        display: 'flex',
        overflowY: 'scroll',
        overflowX: 'none',
    },
    ViewExpandPickListText: {
        marginVertical: 5,
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
    },
    ViewManualScanIcons: {
        marginVertical: 5,
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    ViewActions: {
        marginVertical: 5,
        display: 'flex',
        flexDirection: 'row',
    },
    ViewNavigationButtons: {
        flex: 1,
        marginRight: 5,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    ViewSortingItem: {
        flex: 1,
        paddingVertical: 5,
        display: 'flex',
        overflowY: 'scroll',
        overflowX: 'none',
    },
    TextActionList: {
        alignSelf: 'flex-start',
        color: '#0984e3',
    },
    TextCurrentItemNumber: {
        fontWeight: '700',
        fontSize: 18,
        textAlign: 'center',
    },
    ViewItem: {
        flex: 1,
    },
    TextHeader: {
        fontWeight: '700',
    },
    ViewErrorMessageParent: {
        marginTop: 5,
        marginBottom: 5,
    },
});

type SortingItemProps = {
    details: SortingStorageItemFragment;
};

const SortingItem: React.FC<SortingItemProps> = ({ details }) => {
    const {
        location,
        storageItemType: { name: itemType, description, image },
        barcode,
        status,
        latestVisit,
    } = details;

    const { locations } = latestVisit ?? {};

    return (
        <View>
            <View
                style={sortingItemStyles.ViewItemsList}
                testID="data-pickListSummaryItemSummaryContainer"
            >
                <View
                    style={sortingItemStyles.ViewDetailGroup}
                    testID="data-sortingLocationListItem"
                >
                    <Text
                        style={sortingItemStyles.TextItemValue}
                        testID="data-sortingVisitLocationsHeader"
                    >
                        <FormattedMessage
                            id="sorting.visitLocations"
                            defaultMessage="Visit Location(s)"
                        />
                    </Text>
                    <Text style={sortingItemStyles.TextItemValue} testID="data-sortingItemLocation">
                        <FormattedMessage id="sorting.locationBarcode" defaultMessage="Barcode" />
                    </Text>

                    <Text style={sortingItemStyles.TextItemValue}>{`Sorted / Total`}</Text>
                </View>
                {locations &&
                    locations.map((loc) => (
                        <View
                            style={sortingItemStyles.ViewDetailGroup}
                            key={`${loc.id}`}
                            testID="data-sortingLocationListItem"
                        >
                            <Text
                                style={sortingItemStyles.TextItemLabel}
                                testID="data-sortingItemLocation"
                            >
                                {loc.description}
                            </Text>
                            <Text
                                style={sortingItemStyles.TextItemValue}
                                testID="data-sortingItemLocationBarcode"
                            >
                                {loc.barcode}
                            </Text>

                            <Text
                                style={sortingItemStyles.TextItemValue}
                            >{`${loc.sortedItems}/${loc.itemsToSort}`}</Text>
                        </View>
                    ))}
            </View>

            <View style={sortingItemStyles.ViewDetailGroup}>
                <Text style={sortingItemStyles.TextDetailHeader} testID="data-sortingItemLocation">
                    <FormattedMessage
                        id="sorting.currentLocation"
                        defaultMessage={`Current Location:${' '}`}
                    />
                </Text>
                {location ? (
                    <Text>{`${location.description} ${location.barcode}`}</Text>
                ) : undefined}
            </View>

            <View style={sortingItemStyles.ViewDetailGroup}>
                <Text style={sortingItemStyles.TextDetailHeader} testID="data-sortingItemBarcode">
                    <FormattedMessage id="sorting.itemBarcode" defaultMessage={`Barcode:${' '}`} />
                </Text>
                <Text>{barcode}</Text>
            </View>

            <View style={sortingItemStyles.ViewImageGroup}>
                <Image
                    style={sortingItemStyles.ImageItem}
                    source={{ uri: image }}
                    testID="data-sortingItemImage"
                />
            </View>

            <View style={sortingItemStyles.ViewDetailGroup}>
                <Text style={sortingItemStyles.TextDetailHeader} testID="data-sortingItemStatus">
                    <FormattedMessage id="sorting.itemStatus" defaultMessage={`Status:${' '}`} />
                </Text>
                <Text>{status}</Text>
            </View>

            <View style={sortingItemStyles.ViewDetailGroup}>
                <Text style={sortingItemStyles.TextDetailHeader} testID="data-sortingItemItemType">
                    <FormattedMessage id="sorting.itemType" defaultMessage={`Item type:${' '}`} />
                </Text>
                <Text>{itemType}</Text>
            </View>

            <View style={{ marginVertical: 2 }}>
                <Text
                    style={sortingItemStyles.TextDetailHeader}
                    testID="data-sortingItemDescription"
                >
                    <FormattedMessage
                        id="sorting.itemDescription"
                        defaultMessage={`Description:${' '}`}
                    />
                </Text>
                <Text>{description}</Text>
            </View>
        </View>
    );
};

const sortingItemStyles = StyleSheet.create({
    ViewDetailGroup: {
        marginVertical: 2,
        display: 'flex',
        flexDirection: 'row',
    },
    ViewImageGroup: {
        marginVertical: 5,
        display: 'flex',
        justifyContent: 'center',
    },
    TextDetailHeader: {
        fontWeight: '700',
    },
    ImageItem: {
        height: 150,
        width: 200,
        resizeMode: 'stretch',
    },

    ViewItemsList: {
        marginVertical: 5,
    },
    ViewItem: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
    },
    ViewItemsSummary: {
        marginVertical: 5,
    },
    ViewAssignee: {
        marginVertical: 5,
    },
    TextHeader: {
        fontWeight: '700',
    },
    TextItemLabel: {
        flex: 1,
    },
    TextItemValue: {
        flex: 1,
        fontWeight: '700',
    },
});

type SortingLocationProps = {
    details: SortingLocationFragment;
};

const SortingLocation: React.FC<SortingLocationProps> = ({ details }) => {
    const { barcode, description, sortedItems, itemsToSort } = details;

    return (
        <View>
            <View
                style={sortingLocationStyles.ViewItemsList}
                testID="data-pickListSummaryItemSummaryContainer"
            >
                <Text testID="data-sortingVisitLocationsHeader">
                    <FormattedMessage
                        id="sorting.visitLocationsHeader"
                        defaultMessage={`Selecte Item has been assigned to the below location:${' '}`}
                    />
                </Text>
            </View>

            <View style={sortingLocationStyles.ViewDetailGroup}>
                <Text
                    style={[sortingLocationStyles.TextDetailHeader, { fontSize: 20 }]}
                    testID="data-sortingLocationName"
                >
                    {description}
                </Text>
            </View>

            <View style={sortingLocationStyles.ViewDetailGroup}>
                <Text
                    style={sortingLocationStyles.TextDetailHeader}
                    testID="data-sortingLocationBarcode"
                >
                    <FormattedMessage id="sorting.itemBarcode" defaultMessage={`Barcode:${' '}`} />
                </Text>
                <Text>{barcode}</Text>
            </View>

            <View style={sortingLocationStyles.ViewDetailGroup}>
                <Text
                    style={sortingLocationStyles.TextDetailHeader}
                    testID="data-sortingLocationItemType"
                >
                    <FormattedMessage
                        id="sorting.sortedItemAllItemCount"
                        defaultMessage={`Sorted Items / All Items:${' '}`}
                    />
                </Text>
                <Text>{`${sortedItems}/${itemsToSort}`}</Text>
            </View>
        </View>
    );
};

const sortingLocationStyles = StyleSheet.create({
    ViewDetailGroup: {
        marginVertical: 2,
        display: 'flex',
        flexDirection: 'row',
    },
    ViewImageGroup: {
        marginVertical: 5,
        display: 'flex',
        justifyContent: 'center',
    },
    TextDetailHeader: {
        fontWeight: '700',
    },
    ImageItem: {
        height: 150,
        width: 200,
        resizeMode: 'stretch',
    },

    ViewItemsList: {
        marginVertical: 5,
    },
    ViewItem: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
    },
    ViewItemsSummary: {
        marginVertical: 5,
    },
    ViewAssignee: {
        marginVertical: 5,
    },
    TextHeader: {
        fontWeight: '700',
    },
    TextItemLabel: {
        flex: 1,
    },
    TextItemValue: {
        flex: 1,
        fontWeight: '700',
    },
});

type SortingErrorMessageProps = {
    errorMessage: ErrorMessage | undefined;
};

const SortingErrorMessage: React.FC<SortingErrorMessageProps> = ({ errorMessage }) => {
    return <Message errorMessage={errorMessage} />;
};
