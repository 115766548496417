import { gql } from '@apollo/client';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Card } from '@valet/ui-components';

import { FlatList, ScrollView, StyleSheet, Text, View } from 'react-native';

import { useHistory } from 'react-router-dom';
import {
    AdminScheduleTemplateFragment,
    useGetAdminScheduleTemplatesQuery,
} from '../../../../schema-types';

export const schedulePageSize = 100;

const ADMIN_SCHEDULE_TEMPLATE_FRAGMENT = gql`
    fragment adminScheduleTemplate on ScheduleTemplate {
        id
        startDate
        area {
            id
            code
        }
    }
`;

export const RETRIEVE_SCHEDULE_TEMPLATE_QUERY = gql`
    query getAdminScheduleTemplates {
        scheduleTemplates {
            ...adminScheduleTemplate
        }
    }

    ${ADMIN_SCHEDULE_TEMPLATE_FRAGMENT}
`;

export const schedule = [
    {
        id: '',
        scheduleTemplate: '',
        startTime: '',
    },
];

type SchedulePageProps = {
    onScheduleSelect: (id: string) => void;
};

export const SchedulePage: React.FC<SchedulePageProps> = ({ onScheduleSelect }) => {
    const intl = useIntl();
    const history = useHistory();

    const {
        data: retrievedScheduleTemplateData,
        loading: retrievedScheduleTemplateLoading,
        error: retrievedScheduleTemplateError,
    } = useGetAdminScheduleTemplatesQuery({
        fetchPolicy: 'network-only',
    });

    const scheduleTemplatesData = retrievedScheduleTemplateData?.scheduleTemplates;

    const handleItemClick = useCallback(
        (id: string) => {
            onScheduleSelect(id);
        },
        [onScheduleSelect],
    );

    const handleBacklClick = useCallback(async () => {
        history.push(`/admin`);
    }, [history]);

    return (
        <ScrollView>
            <View style={schedulesPageStyles.ViewParent} testID="data-schedule">
                <View style={schedulesPageStyles.ViewTabs}>
                    <View>
                        <View style={{ flex: 1, marginRight: 2 }}>
                            <Button
                                title={intl.formatMessage({
                                    id: 'schedules.Back',
                                    defaultMessage: 'Back',
                                })}
                                onPress={handleBacklClick}
                            />
                        </View>
                    </View>
                </View>
                {retrievedScheduleTemplateError && (
                    <Text testID="data-retrievedScheduleTemplateError">
                        <FormattedMessage
                            id="schedulePage.retrievedScheduleTemplateError"
                            defaultMessage="There was an error fetching the schedule templates - please reload this page."
                        />
                    </Text>
                )}

                {retrievedScheduleTemplateLoading ? (
                    <View testID="data-scheduleDetailItemLoading">
                        <Text>
                            <FormattedMessage
                                id="scheduleDetail.loading"
                                defaultMessage="Loading..."
                            />
                        </Text>
                    </View>
                ) : (
                    scheduleTemplatesData && (
                        <View style={schedulesPageStyles.ViewContent}>
                            <FlatList
                                data={scheduleTemplatesData}
                                renderItem={({ item, index }) => (
                                    <ScheduleItem
                                        key={item.id}
                                        schedule={item}
                                        index={index}
                                        onItemClick={handleItemClick}
                                    />
                                )}
                                keyExtractor={(item) => item.id}
                            />
                        </View>
                    )
                )}
            </View>
        </ScrollView>
    );
};

const schedulesPageStyles = StyleSheet.create({
    ViewParent: {
        display: 'flex',
        flexDirection: 'column',
    },
    ViewTabs: {
        marginVertical: 5,
        display: 'flex',
        flexDirection: 'row',
    },
    ViewContent: {
        paddingVertical: 5,
    },
    TextTab: {
        flex: 1,
        color: '#000',
        backgroundColor: '#D4D4D5',
        fontSize: 18,
        textAlign: 'center',
        fontWeight: '700',
    },
    ViewErrorMessageParent: {
        marginTop: 5,
        marginBottom: 5,
    },
});

type ScheduleItemProps = {
    schedule: AdminScheduleTemplateFragment;
    index: number;
    onItemClick: (id: string) => void;
};

const ScheduleItem: React.FC<ScheduleItemProps> = ({ schedule, index, onItemClick }) => {
    const intl = useIntl();
    return (
        <Card
            style={scheduleItemStyles.ViewParent}
            onPress={() => onItemClick(schedule.id || '')}
            testID="data-deliveryDetail"
        >
            <View style={scheduleItemStyles.ViewChild}>
                <View style={scheduleItemStyles.ViewContentParent}>
                    <View style={scheduleItemStyles.ViewTitleParent}>
                        <Text
                            style={scheduleItemStyles.TextTitle}
                            testID="data-scheduleItemScheduleTemplate"
                        >
                            {`${intl.formatMessage({
                                id: 'scheduleItem.ScheduleTemplate',
                                defaultMessage: 'Template: ',
                            })}`}
                        </Text>
                        <Text
                            style={{ display: 'flex' }}
                            testID="data-scheduleItemScheduleTemplate"
                        >
                            #{index + 1}
                        </Text>
                    </View>
                    <View style={scheduleItemStyles.ViewTitleParent}>
                        <Text
                            style={scheduleItemStyles.TextTitle}
                            testID="data-scheduleItemScheduleTemplate"
                        >
                            {`${intl.formatMessage({
                                id: 'scheduleItem.ScheduleTemplateArea',
                                defaultMessage: 'Area: ',
                            })}`}
                        </Text>
                        <Text>{schedule.area.code}</Text>
                    </View>
                    <View style={scheduleItemStyles.ViewTitleParent}>
                        <Text
                            style={scheduleItemStyles.TextTitle}
                            testID="data-scheduleIteStartTime"
                        >
                            {`${intl.formatMessage({
                                id: 'scheduleItem.startTime',
                                defaultMessage: 'startTime: ',
                            })}`}
                        </Text>
                        <Text>{schedule.startDate}</Text>
                    </View>
                </View>
            </View>
        </Card>
    );
};

const scheduleItemStyles = StyleSheet.create({
    ViewParent: {
        marginVertical: 3,
    },
    ViewChild: {
        display: 'flex',
        flexDirection: 'row',
    },
    ViewImageParent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    ScheduleImage: {
        height: 40,
        width: 40,
        resizeMode: 'stretch',
    },
    ViewContentParent: {
        paddingHorizontal: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    ViewTitleParent: {
        display: 'flex',
        flexDirection: 'column',
    },
    ViewTitleChild: {},
    TextTitle: {
        fontSize: 18,
        fontWeight: 'bold',
    },
    ViewDescription: {},
    TextDescription: { fontSize: 14 },
    ViewStatus: {},
    TextStatus: {
        fontSize: 12,
        fontWeight: '700',
    },
    ViewDate: {},
    TextDate: {
        fontSize: 12,
    },
    ViewErrorMessageParent: {
        marginTop: 5,
        marginBottom: 5,
    },
});
