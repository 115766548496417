import React from 'react';
import { ScrollView } from 'react-native';
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom';
import { TruckLoadingRoutesPage } from './TruckLoadingRoutesPage';
import { TruckLoadingRouteDetailPage } from './TruckLoadingRouteDetailPage';
import { TruckLoadingProcessItem } from './TruckLoadingProcessItem';

type TruckLoadingRoutesProps = {};

export type RouteStopType = {
    id: string;
    routeNumber: number;
    sequence?: number;
    routeId?: string;
    visitId: string;
};

export const TruckLoadingRoutes: React.FC<TruckLoadingRoutesProps> = () => {
    const { url } = useRouteMatch();
    const history = useHistory();

    const handleRouteSelect = (routeId: string): void => {
        history.push(`/truckLoading/${routeId}`);
    };

    const handleRouteStopItemSelect = (routeId: string, visitId: string): void => {
        history.push(`/truckLoading/${routeId}/${visitId}/processItem`);
    };

    return (
        <ScrollView>
            <Switch>
                <Route path={`${url}/:routeId`} exact>
                    <TruckLoadingRouteDetailPage
                        onRouteStopItemSelect={handleRouteStopItemSelect}
                    />
                </Route>
                <Route path={`${url}/:routeId/:visitId/processItem`} exact>
                    <TruckLoadingProcessItem />
                </Route>

                <Route path={`${url}`}>
                    <TruckLoadingRoutesPage onRouteSelect={handleRouteSelect} />
                </Route>
            </Switch>
        </ScrollView>
    );
};
