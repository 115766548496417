import { gql } from '@apollo/client';
import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { Button, Card, ErrorMessage, Message } from '@valet/ui-components';

import { FlatList, ScrollView, StyleSheet, Text, View } from 'react-native';
import { AdminStorageContainerFragment, useGetAdminStorageContainersQuery } from '../../../../schema-types';
import { useHistory } from 'react-router-dom';

export const storageContainerPageSize = 100;

const ADMIN_CONTAINER_FRAGMENT = gql`
    fragment adminStorageContainer on StorageContainer {
        id
        status
        barcode
        containerType {
            id
            description
        }      
    }
`;


export const RETRIEVE_CONTAINER_QUERY = gql`
    query getAdminStorageContainers($pageSize: Int!, $cursor: String!) {
        storageContainers(
            filter: {
                pageSize: $pageSize
                after: $cursor
            }
        ) {
            edges {
                cursor
                node {
                    id
                    ...adminStorageContainer
                }
            }
            pageInfo {
                cursor {
                    beforeCursor
                    afterCursor
                }
                hasNextPage
                hasPreviousPage
            }
        }
    }

    ${ADMIN_CONTAINER_FRAGMENT}
`;

type StorageContainerPageProps = {
    onStorageContainerSelect: (id: string) => void;
    onAddStorageContainerSelect: () => void
};

export const StorageContainerPage: React.FC<StorageContainerPageProps> = ({ onStorageContainerSelect, onAddStorageContainerSelect }) => {
    const history = useHistory();
    const intl = useIntl();
    const [errorMessage,] = useState<ErrorMessage | undefined>(undefined);

    const [isFetching, setIsFetching] = useState<boolean>(false);

    const {
        data: retrievedStorageContainersData,
        loading: retrievedStorageContainersLoading,
        error: retrievedStorageContainersError,
        fetchMore: retrievedStorageContainerFetchMore,
    } = useGetAdminStorageContainersQuery({
        variables: {
            pageSize: storageContainerPageSize,
            cursor: '',
        },
    });

    const storageContainerEdges = retrievedStorageContainersData?.storageContainers.edges ?? [];
    const storageContainerData = useMemo(() => {
        return {
            storageContainer: storageContainerEdges?.map((itemEdge: any) => itemEdge.node) ?? [],
            hasNextPage: retrievedStorageContainersData?.storageContainers?.pageInfo?.hasNextPage ?? false,
            endCursor: retrievedStorageContainersData?.storageContainers?.pageInfo?.cursor.afterCursor ?? '',
        };
    }, [storageContainerEdges, retrievedStorageContainersData]);

    const handleLoadMoreItems = useCallback((): void => {
        if (storageContainerData.hasNextPage && !isFetching) {
            setIsFetching(true);

            retrievedStorageContainerFetchMore({
                variables: {
                    pageSize: storageContainerPageSize,
                    cursor: storageContainerData.endCursor,
                },
            }).then(() => {
                setIsFetching(false);
            });
        }
    }, [isFetching, storageContainerData, retrievedStorageContainerFetchMore]);

    useEffect(() => {
        if (!retrievedStorageContainersError && storageContainerData.hasNextPage) {
            handleLoadMoreItems();
        }
    }, [handleLoadMoreItems, storageContainerData, retrievedStorageContainersError]);


    const handleItemClick = useCallback(
        (id: string) => {
            onStorageContainerSelect(id);
        },
        [onStorageContainerSelect],
    );

    const handleAddClick = useCallback(
        () => {
            onAddStorageContainerSelect();
        },
        [onAddStorageContainerSelect],
    );

    const handleBacklClick = useCallback(async () => {
        history.push(`/admin`);
    }, [history]);

    return (
        <ScrollView>
            <View style={storageContainersPageStyles.ViewParent} testID="data-storageContainer">
                <View style={storageContainersPageStyles.ViewTabs}>
                    <View>
                        <View style={{ flex: 1, marginRight: 2 }}>
                            <Button
                                title={intl.formatMessage({
                                    id: 'storageContainers.backButton',
                                    defaultMessage: 'Back',
                                })}
                                onPress={handleBacklClick}
                            />
                        </View>
                    </View>
                    <View>
                        <View style={{ flex: 1, marginRight: 20, marginBottom: 10 }}>
                            <Button
                                title={intl.formatMessage({
                                    id: 'storageContainers.addNewStorageContainer',
                                    defaultMessage: '+ Add New Container',
                                })}
                                onPress={handleAddClick}
                            />
                        </View>
                    </View>
                </View>
                {errorMessage && (
                    <Message
                        type={errorMessage.type}
                        header={errorMessage.header}
                        content={errorMessage.content}
                    />
                )}
                {retrievedStorageContainersError &&
                    (<View style={storageContainerItemStyles.ViewTitleParent}>
                        <Text style={storageContainerItemStyles.TextTitle} testID="data-retrievedStorageContainersError">
                            {`${intl.formatMessage({
                                id: 'retrievedStorageContainers.error',
                                defaultMessage: 'Error in retrieving Container List, please refresh the page.',
                            })}`}
                        </Text>
                    </View>)}
                {!retrievedStorageContainersLoading &&
                    !retrievedStorageContainersData?.storageContainers.pageInfo.hasNextPage &&
                    retrievedStorageContainersData && (
                        <View style={storageContainersPageStyles.ViewContent}>
                            <FlatList
                                data={storageContainerData.storageContainer}
                                renderItem={({ item }) => (
                                    <StorageContainerItem
                                        key={item.id}
                                        storageContainer={item}
                                        onItemClick={handleItemClick}
                                    />
                                )}
                                keyExtractor={(item) => item.id}
                            />
                        </View>
                    )}
            </View>
        </ScrollView>
    );
};


const storageContainersPageStyles = StyleSheet.create({
    ViewParent: {
        display: 'flex',
        flexDirection: 'column',
    },
    ViewTabs: {
        marginVertical: 5,
        display: 'flex',
        flexDirection: 'row',
    },
    ViewContent: {
        paddingVertical: 5,
    },
    TextTab: {
        flex: 1,
        color: '#000',
        backgroundColor: '#D4D4D5',
        fontSize: 18,
        textAlign: 'center',
        fontWeight: '700',
    },
    ViewErrorMessageParent: {
        marginTop: 5,
        marginBottom: 5,
    },
});

type StorageContainerItemProps = {
    storageContainer: AdminStorageContainerFragment;
    onItemClick: (id: string) => void;
};

const StorageContainerItem: React.FC<StorageContainerItemProps> = ({ storageContainer, onItemClick }) => {
    const intl = useIntl();

    return (
        <Card
            style={storageContainerItemStyles.ViewParent}
            onPress={() => onItemClick(storageContainer?.id || '')}
            testID="data-deliveryDetail"
        >
            <View style={storageContainerItemStyles.ViewChild}>
                <View style={storageContainerItemStyles.ViewContentParent}>
                    <View style={storageContainerItemStyles.ViewTitleParent}>
                        <Text style={storageContainerItemStyles.TextTitle} testID="data-storageContainerItemDescription">
                            {`${intl.formatMessage({
                                id: 'storageContainerItem.Description',
                                defaultMessage: 'Description: ',
                            })}`}
                        </Text>
                        <Text>{storageContainer?.containerType?.description}</Text>
                    </View>
                    <View style={storageContainerItemStyles.ViewTitleParent}>
                        <Text style={storageContainerItemStyles.TextTitle} testID="data-storageContainerItemStatus">
                            {`${intl.formatMessage({
                                id: 'storageContainerItem.status',
                                defaultMessage: 'Status: ',
                            })}`}
                        </Text>
                        <Text>{storageContainer?.status}</Text>
                    </View>
                    <View style={storageContainerItemStyles.ViewTitleParent}>
                        <Text style={storageContainerItemStyles.TextTitle} testID="data-storageContainerItemBarcode">
                            {`${intl.formatMessage({
                                id: 'storageContainerItem.barcode',
                                defaultMessage: 'Barcode: ',
                            })}`}
                        </Text>
                        <Text>{storageContainer?.barcode}</Text>
                    </View>
                </View>
            </View>
        </Card>
    );
};

const storageContainerItemStyles = StyleSheet.create({
    ViewParent: {
        marginVertical: 3,
    },
    ViewChild: {
        display: 'flex',
        flexDirection: 'row',
    },
    ViewImageParent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    StorageContainerImage: {
        height: 40,
        width: 40,
        resizeMode: 'stretch',
    },
    ViewContentParent: {
        paddingHorizontal: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    ViewTitleParent: {
        display: 'flex',
        flexDirection: 'column',
    },
    ViewTitleChild: {},
    TextTitle: {
        fontSize: 18,
        fontWeight: 'bold',
    },
    ViewDescription: {},
    TextDescription: { fontSize: 14 },
    ViewStatus: {},
    TextStatus: {
        fontSize: 12,
        fontWeight: '700',
    },
    ViewDate: {},
    TextDate: {
        fontSize: 12,
    },
    ViewErrorMessageParent: {
        marginTop: 5,
        marginBottom: 5,
    },
});

