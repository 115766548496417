import React from 'react';
import { gql } from '@apollo/client';
import L, { LatLngExpression } from 'leaflet';

import { Map, Marker, Tooltip, TileLayer } from 'react-leaflet';

import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import { getCurrentVisitMapIcon, getMapIcon, MapIconTypes } from './MapIcon';

import { View, Text, StyleSheet } from 'react-native';
import { Icon } from '@valet/ui-components';

import { VisitReviewMapVisitFragment } from '../../../../schema-types';

export const VISIT_REVIEW_MAP_VISIT_FRAGMENT = gql`
    fragment VisitReviewMapVisit on Visit {
        id
        customerAddress {
            longitude
            latitude
            territory {
                id
            }
            firstName
            lastName
            address1
            city
            zoneId
            countryId
            zip
        }
        summary {
            multiplePeople
            inComing
            outGoing
        }
    }
`;

type VisitReviewMapProps = {
    visitsToRender?: VisitReviewMapVisitFragment[];
    currentVisit?: VisitReviewMapVisitFragment;
};

export const VisitReviewMap: React.FC<VisitReviewMapProps> = ({
    visitsToRender = [],
    currentVisit,
}) => {
    let mapBounds;

    if (currentVisit) {
        visitsToRender.push(currentVisit);
    }

    const visitsToRenderWithLocation = visitsToRender.filter(
        (visit) =>
            (visit.customerAddress.latitude ?? undefined) !== undefined &&
            (visit.customerAddress.longitude ?? undefined) !== undefined,
    );

    if (visitsToRenderWithLocation.length) {
        const visitLatLngBounds: LatLngExpression[] = visitsToRenderWithLocation
            .map((visit) => ({
                lat: visit.customerAddress.latitude,
                lng: visit.customerAddress.longitude,
            }))
            .filter((visit) => visit !== undefined) as LatLngExpression[];

        mapBounds = L.latLngBounds(visitLatLngBounds);

        const locationPoints: { [key: string]: VisitReviewMapVisitFragment[] } = {};

        visitsToRenderWithLocation.forEach((visit) => {
            const locationKey = `${visit.customerAddress.longitude}_${visit.customerAddress.latitude}`;
            locationPoints[locationKey] = [...(locationPoints[locationKey] ?? []), visit];
        });
    }

    const colors = [
        '#fdcb6e',
        '#00cec9',
        '#e17055',
        '#0984e3',
        '#d63031',
        '#6c5ce7',
        '#e84393',
        '#55efc4',
        '#ffeaa7',
        '#81ecec',
        '#fab1a0',
        '#74b9ff',
        '#ff7675',
        '#a29bfe',
        '#fd79a8',
        '#34495e',
    ];

    const territoriesMap: Record<string, string> = visitsToRender.reduce(
        (acc: Record<string, string>, curr: VisitReviewMapVisitFragment) => {
            acc[curr.customerAddress.territory.id] = acc[curr.customerAddress.territory.id]
                ? acc[curr.customerAddress.territory.id]
                : colors[Object.keys(acc).length % colors.length];

            return acc;
        },
        {},
    );

    return (
        <Map bounds={mapBounds} zoom={2} center={[0, 0]} style={{ width: '100%', height: '100%' }}>
            <TileLayer
                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://a.tile.openstreetmap.org/{z}/{x}/{y}.png"
                opacity={0.7}
            />

            {visitsToRenderWithLocation.map((visit, index) =>
                (visit.customerAddress.latitude ?? undefined) !== undefined &&
                (visit.customerAddress.longitude ?? undefined) !== undefined ? (
                    <Marker
                        key={`${index}${visit.id}`}
                        position={[
                            visit.customerAddress.latitude ?? 0,
                            visit.customerAddress.longitude ?? 0,
                        ]}
                        opacity={visit.id === currentVisit?.id ? 1 : 0.7}
                        icon={
                            visit.id === currentVisit?.id
                                ? getCurrentVisitMapIcon()
                                : getMapIcon({
                                      color: territoriesMap[visit.customerAddress.territory.id],
                                      content: MapIconTypes.truck,
                                  })
                        }
                    >
                        <Tooltip offset={[12, 6]} direction={'top'} opacity={100}>
                            <View style={{ width: 200 }}>
                                <VisitInformation visit={visit} />
                            </View>
                        </Tooltip>
                    </Marker>
                ) : undefined,
            )}
        </Map>
    );
};

type VisitInformationProps = {
    visit: VisitReviewMapVisitFragment;
};

export const VisitInformation: React.FC<VisitInformationProps> = ({ visit }) => {
    const {
        customerAddress,
        summary: { multiplePeople, inComing, outGoing },
    } = visit;

    return (
        <>
            <View>
                <Text style={{ fontWeight: 'bold' }}>
                    {customerAddress.firstName} {customerAddress.lastName}
                </Text>
            </View>
            {/* TODO: Format with address templates */}
            <View>
                <Text>{customerAddress.address1}</Text>
                <Text>
                    {customerAddress.city}, {customerAddress.zoneId}
                </Text>
                <Text>
                    {customerAddress.countryId}, {customerAddress.zip}
                </Text>
            </View>

            <View style={{ display: 'flex', flexDirection: 'row' }}>
                <View style={visitInformationStyles.ViewSummaryDetails}>
                    <Icon icon="delivery" />
                    <Text style={visitInformationStyles.TextSummaryValue}>{outGoing ?? 0}</Text>
                </View>

                <View style={visitInformationStyles.ViewSummaryDetails}>
                    <Icon icon="pickup" />
                    <Text style={visitInformationStyles.TextSummaryValue}>{inComing ?? 0}</Text>
                </View>

                {multiplePeople && (
                    <View style={visitInformationStyles.ViewSummaryDetails}>
                        <Icon icon="multiplePeopleVisit" />
                    </View>
                )}
            </View>
        </>
    );
};

const visitInformationStyles = StyleSheet.create({
    ViewSummaryDetails: {
        paddingRight: 5,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    TextSummaryValue: {},
});
