import React, { useState, useEffect, useCallback } from 'react';
import AddressFormatter, { Country, Zone, FieldName } from '@shopify/address';

import { View, Text, StyleSheet } from 'react-native';
import { TextInput, Select, SelectItem } from '@valet/ui-components';
import { CountryCode, LocaleType } from '../AddressTypings';
import { FormattedMessage } from 'react-intl';

type AddressDetails = {
    locale: LocaleType;
    countryCode: string;
};

type AddressSelectItem = {
    title: string;
    value: string;
};

type AddressFields = {
    fields: FieldName[][];
    countryDetails: Country;
    items: {
        zoneItems: AddressSelectItem[];
        countryItems: AddressSelectItem[];
    };
};

export type AddressInput = {
    id: string;
    company?: string;
    firstName?: string;
    lastName?: string;
    address1: string;
    address2: string;
    city: string;
    zoneId?: string;
    zip: string;
    countryId: string;
    phone?: string;
};

const requiredFields: FieldName[] = [FieldName.Address1, FieldName.City, FieldName.Country];

type NewAddressFormProps = {
    locale: LocaleType;
    countryCode: CountryCode;
    currentAddress: AddressInput;
    onAddressChanged: (addressInput: AddressInput) => void;
    displayNames?: boolean;
};

export const NewAddressForm: React.FC<NewAddressFormProps> = ({
    locale = 'en',
    countryCode = 'CA',
    currentAddress,
    displayNames = true,
    onAddressChanged,
}) => {
    const [addressInput, setAddressInput] = useState<AddressInput>({
        ...currentAddress,
        countryId: countryCode,
    });
    const [addressDetails, setAddressDetails] = useState<AddressDetails>({
        locale: locale,
        countryCode: countryCode,
    });
    const [addressFields, setAddressFields] = useState<AddressFields | undefined>(undefined);
    const getAddressFields = useCallback(
        async (currentAddress: AddressInput, locale: LocaleType, countryCode: string) => {
            const addressFormatter: AddressFormatter = new AddressFormatter(locale);
            const countries: Country[] = await addressFormatter.getCountries();
            const countryDetails: Country = await addressFormatter.getCountry(
                currentAddress.countryId || countryCode,
            );

            const retrievedAddressFields: FieldName[][] = await addressFormatter
                .getOrderedFields(currentAddress.countryId || countryCode)
                .then((res: FieldName[][]) => {
                    return res.filter(
                        (fieldGroup: FieldName[]) => !fieldGroup.includes(FieldName.Company),
                    );
                });

            const zoneItems: AddressSelectItem[] = countryDetails.zones.map((zone: Zone) => ({
                title: zone.name,
                value: zone.code,
            }));

            const countryItems: AddressSelectItem[] = countries.map((country: Country) => ({
                title: country.name,
                value: country.code,
            }));

            return setAddressFields({
                fields: displayNames
                    ? retrievedAddressFields
                    : retrievedAddressFields.filter(
                        (x) =>
                            !x.find((j) => j === FieldName.FirstName) &&
                            !x.find((j) => j === FieldName.Phone),
                    ),
                countryDetails: countryDetails,
                items: {
                    zoneItems: zoneItems,
                    countryItems: countryItems,
                },
            });
        },
        [displayNames],
    );

    useEffect(() => {
        setAddressInput(() => {
            getAddressFields(currentAddress, addressDetails.locale, countryCode);
            return currentAddress;
        });
    }, [addressDetails, getAddressFields, countryCode, currentAddress, setAddressInput]);

    const handleAddressFieldInputChange = useCallback(
        (value: string, fieldName: FieldName) => {
            const updatedAddressInput: AddressInput = {
                ...addressInput,
            };

            // Translate 'province' into 'zoneId'
            if (fieldName === 'province') {
                updatedAddressInput['zoneId'] = value;
            } else if (fieldName === 'country') {
                updatedAddressInput['countryId'] = value;
                updatedAddressInput['zip'] = '';

                setAddressDetails({
                    ...addressDetails,
                    countryCode: value as CountryCode,
                });
            } else {
                updatedAddressInput[fieldName] = value;
            }

            setAddressInput(updatedAddressInput);
            return onAddressChanged(updatedAddressInput);
        },
        [addressInput, addressDetails, onAddressChanged],
    );

    if (!addressFields) {
        // TODO: Better loading interface
        return (
            <View testID="data-newAddressForm">
                <Text>
                    <FormattedMessage id="loading" defaultMessage="Loading..." />
                </Text>
            </View>
        );
    }

    return (
        <View testID="data-newAddressForm">
            {addressFields.fields
                .filter((x) => !x.includes(FieldName.Country))
                .map((fieldGroup: FieldName[]) => (
                    <View key={fieldGroup[0]}>
                        <AddressFieldsGroup
                            fieldGroup={fieldGroup}
                            details={{
                                countryDetails: addressFields.countryDetails,
                                items: addressFields.items,
                            }}
                            onAddressFieldInputChange={handleAddressFieldInputChange}
                            addressInput={addressInput}
                        />
                    </View>
                ))}
            {addressFields.fields
                .filter((x) => x.includes(FieldName.Country))
                .map((fieldGroup: FieldName[]) => (
                    <View key={fieldGroup[0]}>
                        <AddressFieldsGroup
                            fieldGroup={fieldGroup}
                            details={{
                                countryDetails: addressFields.countryDetails,
                                items: addressFields.items,
                            }}
                            onAddressFieldInputChange={handleAddressFieldInputChange}
                            addressInput={addressInput}
                        />
                    </View>
                ))}
        </View>
    );
};

type AddressFieldsGroupProps = {
    fieldGroup: FieldName[];
    details: {
        countryDetails: Country;
        items: {
            zoneItems: AddressSelectItem[];
            countryItems: AddressSelectItem[];
        };
    };
    onAddressFieldInputChange: (value: string, fieldName: FieldName) => void;
    addressInput: AddressInput;
};

const AddressFieldsGroup: React.FC<AddressFieldsGroupProps> = ({
    fieldGroup,
    details,
    onAddressFieldInputChange,
    addressInput,
}) => {
    const { zoneItems, countryItems } = details.items;
    return (
        <View style={newAddressFieldsGroupStyles.ViewParent}>
            {fieldGroup.map((field: FieldName) => (
                <View style={newAddressFieldsGroupStyles.ViewFields} key={field}>
                    {field === FieldName.Country ? (
                        <CountryAddressFieldItem
                            field={field}
                            value={addressInput['countryId'] || countryItems[0].value}
                            onAddressFieldInputChange={onAddressFieldInputChange}
                            countryItems={countryItems}
                        />
                    ) : field === FieldName.Zone ? (
                        <ZoneAddressFieldItem
                            field={field}
                            value={addressInput['zoneId'] || zoneItems[0].value}
                            onAddressFieldInputChange={onAddressFieldInputChange}
                            zoneItems={zoneItems}
                        />
                    ) : (
                        <AddressFieldItem
                            field={field}
                            label={`${details.countryDetails?.labels[
                                field === FieldName.PostalCode ? 'postalCode' : field
                            ]
                                }${requiredFields.includes(field) ? ' (required)' : ''}`}
                            value={addressInput[field] || ''}
                            onAddressFieldInputChange={onAddressFieldInputChange}
                        />
                    )}
                </View>
            ))}
        </View>
    );
};

type AddressFieldItemProps = {
    field: FieldName;
    label: string;
    value: string;
    onAddressFieldInputChange: (value: string, fieldName: FieldName) => void;
};

const AddressFieldItem: React.FC<AddressFieldItemProps> = React.memo(
    ({ field, label, value, onAddressFieldInputChange }) => {
        return (
            <TextInput
                type="text"
                ariaLabel={label}
                value={value}
                onChange={(e) => onAddressFieldInputChange(e, field)}
                placeholder={label}
            />
        );
    },
);

type CountryAddressFieldItemProps = {
    field: FieldName;
    value: string;
    onAddressFieldInputChange: (value: string, fieldName: FieldName) => void;
    countryItems: AddressSelectItem[];
};

const CountryAddressFieldItem: React.FC<CountryAddressFieldItemProps> = React.memo(
    ({ field, value, onAddressFieldInputChange, countryItems }) => {
        const countryIndex = countryItems.findIndex((item) => item.value === value);

        const [selected, setSelected] = useState<number | undefined>(
            countryIndex > -1 ? countryIndex : 0,
        );

        const handleSelectChange = (index: number): void => {
            setSelected(index);
            onAddressFieldInputChange(countryItems[index].value, field);
        };

        return (
            <Select
                currentIndex={selected}
                onSelect={handleSelectChange}
                itemsDisplayValues={countryItems.map((item) => item.title)}
            >
                {countryItems.map((country: AddressSelectItem, index: number) => (
                    <SelectItem title={country.title} key={country.title} />
                ))}
            </Select>
        );
    },
);

type ZoneAddressFieldItemProps = {
    field: FieldName;
    value: string;
    onAddressFieldInputChange: (value: string, fieldName: FieldName) => void;
    zoneItems: AddressSelectItem[];
};

const ZoneAddressFieldItem: React.FC<ZoneAddressFieldItemProps> = React.memo(
    ({ field, value, onAddressFieldInputChange, zoneItems }) => {
        const zoneIndex = zoneItems.findIndex((item) => item.value === value);

        const [selected, setSelected] = useState<number | undefined>(
            zoneIndex > -1 ? zoneIndex : 0,
        );

        useEffect(() => {
            setSelected(zoneIndex > -1 ? zoneIndex : 0);
        }, [zoneIndex]);

        const handleSelectChange = (index: number): void => {
            setSelected(index);
            onAddressFieldInputChange(zoneItems[index].value, field);
        };

        return (
            <Select
                currentIndex={selected}
                onSelect={handleSelectChange}
                itemsDisplayValues={zoneItems.map((item) => item.title)}
            >
                {zoneItems.map((zone: AddressSelectItem, index: number) => (
                    <SelectItem title={zone.title} key={zone.title} />
                ))}
            </Select>
        );
    },
);

const newAddressFieldsGroupStyles = StyleSheet.create({
    ViewParent: {
        marginTop: 5,
        marginBottom: 5,
        width: '100%',
        flexDirection: 'row',
        alignContent: 'space-between',
    },
    ViewFields: {
        flex: 1,
    },
});
