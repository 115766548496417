import React, { useEffect, useState } from 'react';
import { IntlProvider, FormattedMessage } from 'react-intl';

import { ApplicationContextProvider, useApplicationContext } from './Components/ApplicationContext';
import { AuthContextProvider } from './Components/Pages/Auth/AuthFunctions';
import { ApolloClientProvider } from './Components/ApolloClientContext';
import { Routes } from './Routes';

import { View, StyleSheet } from 'react-native';
import { LanguageSelector } from './Components/Organisms/LanguageSelector/LanguageSelector';
import { ThemeProvider } from './Theme';

const AppWithContextAndSettings: React.FC = () => {
    const { loading, error } = useApplicationContext();

    const [slowLoading, setSlowLoading] = useState(false);

    useEffect(() => {
        setSlowLoading(false);
        if (loading) {
            setTimeout(() => setSlowLoading(true), 250);
        }
    }, [loading]);

    //TODO: Make it look better
    if (loading) {
        if (slowLoading) {
            return (
                <div>
                    <FormattedMessage id="loading" defaultMessage="Loading..." />
                </div>
            );
        } else {
            return <></>;
        }
    }

    //TODO: Make it look better
    if (error) {
        return (
            <div>
                <FormattedMessage
                    id="ErrorMessage"
                    defaultMessage="Error: {error}!"
                    values={{ error }}
                />
            </div>
        );
    }

    return (
        <ThemeProvider>
            <AuthContextProvider>
                <ApolloClientProvider>
                    {/* TODO: Move */}
                    <LanguageSelector />
                    <Routes />
                </ApolloClientProvider>
            </AuthContextProvider>
        </ThemeProvider>
    );
};

export const AppWithContext: React.FC = () => {
    const { currentLanguage, messages } = useApplicationContext();

    return (
        <IntlProvider locale={currentLanguage} messages={messages}>
            <AppWithContextAndSettings />
        </IntlProvider>
    );
};

export const App: React.FC = () => {
    return (
        <View style={appStyles.ViewParent} testID="data-app">
            <ApplicationContextProvider>
                <AppWithContext />
            </ApplicationContextProvider>
        </View>
    );
};

const appStyles = StyleSheet.create({
    ViewParent: {
        minHeight: '100vh',
        minWidth: '100vw',
        display: 'flex',
        fontSize: 14,
        fontFamily: 'Roboto, Arial, sans-serif',
    },
});
