import { gql } from '@apollo/client';
import React, { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ScrollView, StyleSheet, Text, View } from 'react-native';
import { Button, ErrorMessage, Message, TextInput } from '@valet/ui-components';
import { useAdminAddStorageItemTypeCategoryMutation, AdminAddStorageItemTypeCategoryInput } from '../../../../schema-types';
import { useHistory } from 'react-router-dom';

export const ADMIN_ADD_STORAGEITEMTYPE_CATEGORY_MUTATION = gql`
    mutation adminAddStorageItemTypeCategory($data: AdminAddStorageItemTypeCategoryInput!) {
        adminAddStorageItemTypeCategory(data: $data) {
            data {
                name
            }
        }
    }
`;

type AddStorageItemTypeCategoryPageProps = {
    onItemCategoryAdded: () => void;
};

export const AddStorageItemTypeCategoryPage: React.FC<AddStorageItemTypeCategoryPageProps> = ({ onItemCategoryAdded: onCategoryAdded }) => {
    const history = useHistory();
    const [errorMessage, setErrorMessage] = useState<ErrorMessage | undefined>();
    const intl = useIntl();
    const [newStorageItemTypeCategory, setNewStorageItemTypeCategory] = useState<AdminAddStorageItemTypeCategoryInput>({
        name: '',
    });

    const [
        adminAddStorageItemTypeCategoryMutation,
        {
            loading: adminAddStorageItemTypeCategoryDetailMutationLoading,
            error: adminAddStorageItemTypeCategoryDetailMutationError,
        },
    ] = useAdminAddStorageItemTypeCategoryMutation({
        onCompleted(data: any) {
            if (data) {
                onCategoryAdded();
            }
        },
    });

    const handleAddStorageItemTypeCategoryClick = useCallback(async () => {

        if (newStorageItemTypeCategory.name) {
            setErrorMessage(undefined);
            await adminAddStorageItemTypeCategoryMutation({
                variables: {
                    data: {
                        ...newStorageItemTypeCategory,
                    },
                },
            });
        }
        else {
            setErrorMessage({
                header: '',
                content:
                    intl.formatMessage({
                        id: 'AddStorageItemTypeCategory.handleAddStorageItemTypeCategoryClick',
                        defaultMessage: 'Please Enter the Category Information.',
                    }),
                type: 'warning',
            });
        }

    }, [adminAddStorageItemTypeCategoryMutation, newStorageItemTypeCategory, setErrorMessage, intl]);

    const handleBacklClick = useCallback(async () => {
        history.push(`/admin/categories`);
    }, [history]);

    // For some reason when I move this under the ScrollView , getting error regarding the type, header and the content 
    // I will invesitigate more later once all the MRs completed
    if (errorMessage) {
        return (
            <View style={newStorageItemTypeCategoryPageStyles.ViewErrorMessageParent} testID="data-errorMessage">
                <Message
                    type={errorMessage.type}
                    header={errorMessage.header}
                    content={errorMessage.content}
                />
            </View>
        );
    }


    return (
        <ScrollView>
            {adminAddStorageItemTypeCategoryDetailMutationError ?
                <View style={newStorageItemTypeCategoryPageStyles.ViewErrorMessageParent} testID="data-errorMessage">
                    <Text>
                        <FormattedMessage id="AddStorageItemTypeCategory.addStorageItemTypeCategoryErrorMessage"
                            defaultMessage="Could not add Category - please try again" />
                    </Text>
                </View>
                :
                adminAddStorageItemTypeCategoryDetailMutationLoading ?
                    <View testID="data-AddStorageItemTypeCategorytemLoading">
                        <Text>
                            <FormattedMessage id="AddStorageItemTypeCategory.loading" defaultMessage="Loading..." />
                        </Text>
                    </View>
                    :
                    <View style={newStorageItemTypeCategoryPageStyles.ViewParent} testID="data-AddStorageItemTypeCategory">
                        <View style={newStorageItemTypeCategoryPageStyles.ViewChild}>
                            <View style={newStorageItemTypeCategoryPageStyles.ViewContentParent}>
                                <View style={newStorageItemTypeCategoryPageStyles.ViewTitleParent}>
                                    <Text style={newStorageItemTypeCategoryPageStyles.TextTitle} testID="data-newStorageItemTypeCategory">
                                        {`${intl.formatMessage({
                                            id: 'newStorageItemTypeCategoryDetail.id',
                                            defaultMessage: 'Add New Category: ',
                                        })}${''}`}
                                    </Text>
                                </View>
                                <View style={newStorageItemTypeCategoryPageStyles.ViewTitleParent}>
                                    <View style={newStorageItemTypeCategoryPageStyles.ViewDescription}>
                                        <FormattedMessage
                                            id="StorageItemTypeCategoryDetail.name"
                                            defaultMessage="Name: "
                                        />
                                        <TextInput
                                            type="text"
                                            ariaLabel="Name"
                                            value={newStorageItemTypeCategory?.name || ''}
                                            onChange={(value: string) => {
                                                setNewStorageItemTypeCategory({ ...newStorageItemTypeCategory, name: value });
                                            }}
                                        />
                                    </View>
                                </View>
                            </View>
                        </View>
                        <View
                            style={{
                                marginVertical: 10,
                                marginHorizontal: 10,
                                display: 'flex',
                                flexDirection: 'row',
                            }}
                        >
                            <View style={{ flex: 1, marginRight: 10 }}>
                                <Button
                                    title={intl.formatMessage({
                                        id: 'newStorageItemTypeCategoryDetail.Cancel',
                                        defaultMessage: 'Cancel',
                                    })}
                                    onPress={handleBacklClick}
                                />
                            </View>
                            <View style={{ flex: 1 }}>
                                <Button
                                    title={intl.formatMessage({
                                        id: 'newStorageItemTypeCategoryDetail.Add',
                                        defaultMessage: 'Add',
                                    })}
                                    onPress={handleAddStorageItemTypeCategoryClick}
                                />
                            </View>
                        </View>
                    </View>}
        </ScrollView>
    );
};

const newStorageItemTypeCategoryPageStyles = StyleSheet.create({
    ViewParent: {
        display: 'flex',
        flexDirection: 'column',
    },
    ViewTabs: {
        marginVertical: 5,
        display: 'flex',
        flexDirection: 'row',
    },
    ViewContent: {
        paddingVertical: 5,
    },
    TextTab: {
        flex: 1,
        color: '#000',
        backgroundColor: '#D4D4D5',
        fontSize: 18,
        textAlign: 'center',
        fontWeight: '700',
    },

    ViewChild: {
        display: 'flex',
        flexDirection: 'row',
    },
    ViewImageParent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    RouteImage: {
        height: 40,
        width: 40,
        resizeMode: 'stretch',
    },
    ViewContentParent: {
        paddingHorizontal: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    ViewTitleParent: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 5,
    },
    ViewTitleChild: {},
    TextTitle: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 10,
    },
    ViewDescription: {
        marginTop: 10,
        fontWeight: 'bold',
    },
    TextDescription: { fontSize: 14 },
    ViewStatus: {},
    TextStatus: {
        fontSize: 12,
        fontWeight: '700',
    },
    ViewDate: {},
    TextDate: {
        fontSize: 12,
    },
    ViewErrorMessageParent: {
        marginTop: 5,
        marginBottom: 5,
    },
    TextContainerItem: {
        marginVertical: 2,
        display: 'flex',
        flexDirection: 'row',
        fontWeight: 'bold',
    },
    ViewImage: {
        paddingHorizontal: 5,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
});
