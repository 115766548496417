import React from 'react';

import { View, Text, StyleSheet } from 'react-native';
import { Button } from '@valet/ui-components';
import { useIntl, FormattedMessage } from 'react-intl';

type EmailExistsProps = {
    handleRedirectSignIn: () => void;
    handleRedirectForgotPassword: () => void;
};

export const EmailExists: React.FC<EmailExistsProps> = ({
    handleRedirectSignIn,
    handleRedirectForgotPassword,
}) => {
    const intl = useIntl();

    return (
        <View style={emailExistsStyles.ViewParent} testID="data-emailExistsPage">
            <Text style={emailExistsStyles.TextHeader}>
                <FormattedMessage
                    id="authentication.existingEmailHeader"
                    defaultMessage="Sign in failed"
                />
            </Text>
            <Text>
                <FormattedMessage
                    id="authentication.existingEmailMessage"
                    defaultMessage="The sign in failed because the email address has already been taken."
                />
            </Text>
            <Text>
                <FormattedMessage
                    id="authentication.existingEmailTryEmailLogin"
                    defaultMessage="Try logging in using your email. If you have forgotten your password, try recovering it."
                />
            </Text>

            <View style={emailExistsStyles.ViewButton}>
                <Button
                    title={intl.formatMessage({
                        id: 'authentication.signIn',
                        defaultMessage: 'Sign In',
                    })}
                    onPress={handleRedirectSignIn}
                />
            </View>

            <View style={emailExistsStyles.ViewButton}>
                <Button
                    title={intl.formatMessage({
                        id: 'authentication.recoverPassword',
                        defaultMessage: 'Recover Password',
                    })}
                    onPress={handleRedirectForgotPassword}
                />
            </View>
        </View>
    );
};

const emailExistsStyles = StyleSheet.create({
    ViewParent: {
        padding: 10,
        display: 'flex',
        flexDirection: 'column' as 'column',
    },
    ViewButton: {
        marginTop: 10,
    },
    TextHeader: {
        fontSize: 16,
    },
});
