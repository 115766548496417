import { gql } from '@apollo/client';
import React, { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ScrollView, StyleSheet, Text, View } from 'react-native';
import { Button, Message, TextInput } from '@valet/ui-components';
import {
    useGetAdminStorageContainerTypeByIdQuery,
    AdminStorageContainerTypeDetailFragment,
    useAdminUpdateStorageContainerTypeMutation
} from '../../../../schema-types';
import { useHistory, useRouteMatch } from 'react-router-dom';


const CONTAINERTYPE_FRAGMENT = gql`
    fragment adminStorageContainerTypeDetail on StorageContainerType {
        id
        description
    }
`;

export const RETRIEVE_CONTAINERTYPE_QUERY = gql`
    query getAdminStorageContainerTypeById($containerTypeId: String!) {
        storageContainerTypeById(id: $containerTypeId) {
            ...adminStorageContainerTypeDetail
        }
    }
    ${CONTAINERTYPE_FRAGMENT}
`;

export const ADMIN_UPDATE_CONTAINERTYPE_MUTATION = gql`
    mutation adminUpdateStorageContainerType($data: AdminStorageContainerTypeInput!) {
        adminUpdateStorageContainerType(data: $data) {
            data {
                ...adminStorageContainerTypeDetail
            }
        }
    }
`;

type StorageContainerTypeDetailPageProps = {};

export const StorageContainerTypeDetailPage: React.FC<StorageContainerTypeDetailPageProps> = () => {
    const history = useHistory();
    const intl = useIntl();
    const match = useRouteMatch();
    const params: { containerTypeId?: string } = match.params;
    const containerTypeId = params.containerTypeId;
    const [storageContainerType, setStorageContainerType] = useState<AdminStorageContainerTypeDetailFragment>({
        id: '',
        description: '',
    });


    const {
        loading: retrievedStorageContainerTypeDetailLoading,
        error: retrievedStorageContainerTypeDetailError,
    } = useGetAdminStorageContainerTypeByIdQuery({
        variables: {
            containerTypeId: containerTypeId || '',
        },
        onCompleted: (data: any) => {
            setStorageContainerType(data.storageContainerTypeById as AdminStorageContainerTypeDetailFragment);
        },
    });

    const [
        adminUpdateStorageContainerTypeMutation,
        {
            loading: adminUpdateStorageContainerTypeDetailMutationLoading,
            error: adminUpdateStorageContainerTypeDetailMutationError,
        },
    ] = useAdminUpdateStorageContainerTypeMutation();

    const handleBackClick = useCallback(async () => {
        history.push(`/admin/containerTypes`);
    }, [history]);

    const handleSaveStorageContainerTypeDetailClick = useCallback(async () => {
        if (storageContainerType) {
            try {
                await adminUpdateStorageContainerTypeMutation({
                    variables: {
                        data: {
                            id: storageContainerType.id,
                            description: storageContainerType.description,
                        }

                    },
                });
                handleBackClick();
            } catch (error) { }
        }
    }, [storageContainerType, handleBackClick, adminUpdateStorageContainerTypeMutation]);

    return (
        <ScrollView>
            {retrievedStorageContainerTypeDetailError ?
                <View style={StorageContainerTypePageStyles.ViewErrorMessageParent} testID="data-errorMessage">
                    <Message
                        type="negative"
                        header={intl.formatMessage({
                            id: 'StorageContainerTypeDetail.StorageContainerTypeDetailErrorMessage',
                            defaultMessage: 'Could not load detail for the storage Item Type - please try again',
                        })}
                        content={retrievedStorageContainerTypeDetailError.message}
                        testID="data-StorageContainerTypeDetailErrorMessage"
                    />
                </View>
                :
                adminUpdateStorageContainerTypeDetailMutationError ?
                    <View style={StorageContainerTypePageStyles.ViewErrorMessageParent} testID="data-errorMessage">
                        <Message
                            type="negative"
                            header={intl.formatMessage({
                                id: 'StorageContainerTypeDetail.StorageContainerTypeDetailUpdateErrorMessage',
                                defaultMessage: 'Could not update storage Item Type detail - please try again',
                            })}
                            content={adminUpdateStorageContainerTypeDetailMutationError.message}
                            testID="data-StorageContainerTypeDetailUpdateErrorMessage"
                        />
                    </View>
                    :
                    retrievedStorageContainerTypeDetailLoading || adminUpdateStorageContainerTypeDetailMutationLoading ?
                        <View testID="data-StorageContainerTypeDetailItemLoading">
                            <Text>
                                <FormattedMessage id="StorageContainerTypeDetail.loading" defaultMessage="Loading..." />
                            </Text>
                        </View>
                        :
                        <>
                            <View style={StorageContainerTypePageStyles.ViewParent} testID="data-StorageContainerTypeDetail">
                                <View style={StorageContainerTypePageStyles.ViewChild}>
                                    <View style={StorageContainerTypePageStyles.ViewContentParent}>
                                        <View style={StorageContainerTypePageStyles.ViewTitleParent}>
                                            <Text style={StorageContainerTypePageStyles.TextTitle} testID="data-StorageContainerTypeDetailTitle">
                                                {`${intl.formatMessage({
                                                    id: 'StorageContainerTypeDetail.id',
                                                    defaultMessage: 'Container Type Details:',
                                                })}${''}`}
                                            </Text>
                                        </View>
                                        <View style={StorageContainerTypePageStyles.ViewTitleParent}>
                                            <View style={StorageContainerTypePageStyles.ViewDescription}>
                                                <FormattedMessage
                                                    id="StorageContainerTypeDetail.description"
                                                    defaultMessage="Description: "
                                                />
                                                <TextInput
                                                    type="text"
                                                    ariaLabel="Description"
                                                    value={storageContainerType?.description || ''}
                                                    onChange={(value: string) => {
                                                        setStorageContainerType({ ...storageContainerType, description: value });
                                                    }}
                                                />
                                            </View>
                                        </View>
                                    </View>
                                </View>
                                <View
                                    style={{
                                        marginVertical: 10,
                                        marginHorizontal: 10,
                                        display: 'flex',
                                        flexDirection: 'row',
                                    }}
                                >
                                    <View style={{ flex: 1, marginRight: 10 }}>
                                        <Button
                                            title={intl.formatMessage({
                                                id: 'StorageContainerTypeDetail.Cancel',
                                                defaultMessage: 'Cancel',
                                            })}
                                            onPress={handleBackClick}
                                        />
                                    </View>

                                    <View style={{ flex: 1 }}>
                                        <Button
                                            title={intl.formatMessage({
                                                id: 'StorageContainerTypeDetail.Save',
                                                defaultMessage: 'Save',
                                            })}
                                            onPress={handleSaveStorageContainerTypeDetailClick}
                                        />
                                    </View>
                                </View>
                            </View>
                        </>}
        </ScrollView >
    );
};

const StorageContainerTypePageStyles = StyleSheet.create({
    ViewParent: {
        display: 'flex',
        flexDirection: 'column',
    },
    ViewTabs: {
        marginVertical: 5,
        display: 'flex',
        flexDirection: 'row',
    },
    ViewContent: {
        paddingVertical: 5,
    },
    TextTab: {
        flex: 1,
        color: '#000',
        backgroundColor: '#D4D4D5',
        fontSize: 18,
        textAlign: 'center',
        fontWeight: '700',
    },

    ViewChild: {
        display: 'flex',
        flexDirection: 'row',
    },
    ViewImageParent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    RouteImage: {
        height: 40,
        width: 40,
        resizeMode: 'stretch',
    },
    ViewContentParent: {
        paddingHorizontal: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    ViewTitleParent: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 10,
    },
    ViewTitleChild: {},
    TextTitle: {
        fontSize: 18,
        fontWeight: 'bold',
    },
    ViewDescription: {
        marginTop: 10,
        fontWeight: 'bold',
    },
    TextDescription: { fontSize: 14 },
    ViewStatus: {},
    TextStatus: {
        fontSize: 12,
        fontWeight: '700',
    },
    ViewDate: {},
    TextDate: {
        fontSize: 12,
    },
    ViewErrorMessageParent: {
        marginTop: 5,
        marginBottom: 5,
    },
    TextContainerItem: {
        marginVertical: 2,
        display: 'flex',
        flexDirection: 'row',
        fontWeight: 'bold',
    },
    ViewImage: {
        paddingHorizontal: 5,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
});
