import { gql } from '@apollo/client';
import React, { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ScrollView, StyleSheet, Text, View } from 'react-native';
import { Button, Card, Message, TextInput } from '@valet/ui-components';
import {
    useGetAreaByIdQuery,
    AdminAreaDetailFragment,
    useAdminUpdateAreaMutation,
} from '../../../../schema-types';
import { useRouteMatch } from 'react-router-dom';

const AREA_FRAGMENT = gql`
    fragment adminAreaDetail on Area {
        id
        code
    }
`;

export const RETRIEVE_AREA_QUERY = gql`
    query getAreaById($areaId: String!) {
        areaById(id: $areaId) {
            ...adminAreaDetail
        }
    }
    ${AREA_FRAGMENT}
`;

export const ADMIN_UPDATE_AREA_MUTATION = gql`
    mutation adminUpdateArea($data: AdminUpdateAreaInput!) {
        adminUpdateArea(data: $data) {
            data {
                id
                code
            }
        }
    }
`;

type AreaDetailPageProps = {
    onBackClick: () => void;
};

export const AreaDetailPage: React.FC<AreaDetailPageProps> = ({ onBackClick }) => {
    const [areaCode, setAreaCode] = useState<string | undefined>(undefined);
    const match = useRouteMatch();
    const params: { areaId?: string } = match.params;
    const areaId = params.areaId;

    const {
        data: retrievedAreaData,
        loading: retrievedAreaDetailLoading,
        error: retrievedAreaDetailError,
        refetch,
    } = useGetAreaByIdQuery({
        variables: {
            areaId: areaId || '',
        },
        onCompleted: () => setAreaCode(retrievedAreaData?.areaById?.code || '')
    });

    const areasData = retrievedAreaData?.areaById as AdminAreaDetailFragment;
    const intl = useIntl();

    const [
        adminUpdateAreaMutation,
        { loading: adminUpdateAreaMutationLoading, error: adminUpdateAreaMutationError },
    ] = useAdminUpdateAreaMutation({
        onCompleted({ adminUpdateArea: { data } }) {
            if (data) {
                refetch({
                    areaId: areaId,
                });
            }
        },
    });

    const handleSaveAreaDetailClick = useCallback(async () => {
        if (areaCode) {
            await adminUpdateAreaMutation({
                variables: {
                    data: {
                        areaId: areaId || '',
                        code: areaCode,
                    },
                },
            });
            onBackClick();
        }
    }, [adminUpdateAreaMutation, areaCode, areaId, onBackClick]);

    return (
        <ScrollView>
            <View style={areaPageStyles.ViewParent} testID="data-areaDetail">
                {(retrievedAreaDetailLoading || adminUpdateAreaMutationLoading) &&
                    (
                        <View testID="data-areaDetailItemLoading">
                            <Text>
                                <FormattedMessage
                                    id="areaDetail.loading"
                                    defaultMessage="Loading..."
                                />
                            </Text>
                        </View>
                    )}
                {adminUpdateAreaMutationError &&
                    (
                        <Message
                            type="negative"
                            header={intl.formatMessage({
                                id: 'areaDetail.areaDetailUpdateErrorMessage',
                                defaultMessage: 'Could not Update detail for the area - please try again',
                            })}
                            content={adminUpdateAreaMutationError.message}
                            testID="data-areaDetailUpdateErrorMessage"
                        />
                    )}
                {retrievedAreaDetailError &&
                    (<Message
                        type="negative"
                        header={intl.formatMessage({
                            id: 'areaDetail.areaDetailErrorMessage',
                            defaultMessage: 'Could not load detail for the area - please try again',
                        })}
                        content={retrievedAreaDetailError.message}
                        testID="data-areaDetailErrorMessage"
                    />)}

                {areasData &&
                    <Card style={areaPageStyles.ViewParent} testID="data-areaDetailDetail">
                        <View style={areaPageStyles.ViewChild}>
                            <View style={areaPageStyles.ViewContentParent}>
                                <View style={areaPageStyles.ViewTitleParent}>
                                    <Text
                                        style={areaPageStyles.TextTitle}
                                        testID="data-areaItemDescription"
                                    >
                                        {`${intl.formatMessage({
                                            id: 'areaItem.AreaCode',
                                            defaultMessage: 'AreaDetail Code:',
                                        })}${areasData?.code}`}
                                    </Text>
                                </View>

                                <View style={areaPageStyles.ViewDescription}>
                                    <View>
                                        <FormattedMessage
                                            id="deliveryPickupItemDetail.back"
                                            defaultMessage="Description"
                                        />
                                        <TextInput
                                            type="text"
                                            ariaLabel="Description"
                                            value={areaCode || ''}
                                            onChange={(value: string) => setAreaCode(value)}
                                        />
                                    </View>
                                </View>
                            </View>
                        </View>
                    </Card>}

                <View style={{
                    marginVertical: 10,
                    marginHorizontal: 10,
                    display: 'flex',
                    flexDirection: 'row',

                }}>
                    <View style={{ flex: 1, marginRight: 10 }}>
                        <Button
                            title="Cancel"
                            onPress={onBackClick}
                        />
                    </View>
                    <View style={{ flex: 1 }}>
                        <Button
                            title="Save Detail"
                            onPress={() => handleSaveAreaDetailClick()}
                        />
                    </View>
                </View>
            </View>
        </ScrollView>
    );
};

const areaPageStyles = StyleSheet.create({
    ViewParent: {
        display: 'flex',
        flexDirection: 'column',
    },
    ViewTabs: {
        marginVertical: 5,
        display: 'flex',
        flexDirection: 'row',
    },
    ViewContent: {
        paddingVertical: 5,
    },
    TextTab: {
        flex: 1,
        color: '#000',
        backgroundColor: '#D4D4D5',
        fontSize: 18,
        textAlign: 'center',
        fontWeight: '700',
    },

    ViewChild: {
        display: 'flex',
        flexDirection: 'row',
    },
    ViewImageParent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    RouteImage: {
        height: 40,
        width: 40,
        resizeMode: 'stretch',
    },
    ViewContentParent: {
        paddingHorizontal: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    ViewTitleParent: {
        display: 'flex',
        flexDirection: 'row',
    },
    ViewTitleChild: {},
    TextTitle: {
        fontSize: 18,
        fontWeight: 'bold',
    },
    ViewDescription: {},
    TextDescription: { fontSize: 14 },
    ViewStatus: {},
    TextStatus: {
        fontSize: 12,
        fontWeight: '700',
    },
    ViewDate: {},
    TextDate: {
        fontSize: 12,
    },
    ViewErrorMessageParent: {
        marginTop: 5,
        marginBottom: 5,
    },
});
