import { gql } from '@apollo/client';
import React, { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ScrollView, StyleSheet, Text, View } from 'react-native';
import {
    Button,
    Checkbox,
    ErrorMessage,
    Message,
    Select,
    SelectItem,
    TextInput,
} from '@valet/ui-components';
import {
    useGetAdminBuildingByIdQuery,
    AdminBuildingDetailFragment,
    useAdminUpdateBuildingMutation,
} from '../../../../schema-types';
import { useHistory, useParams } from 'react-router';
import { AddressInput, NewAddressForm } from '../../../Organisms/NewAddress/NewAddressForm';
import { CountryCode, LocaleType } from '../../../Organisms/AddressTypings';
import { validateAddressInput } from '../../../Organisms/NewAddress/ValidateAddressInput';
import { useGetAdminBuildingAreasQuery, AdminBuildingAreaFragment } from '../../../../schema-types';

const AREA_FRAGMENT = gql`
    fragment adminBuildingArea on Area {
        id
        code
    }
`;

export const RETRIEVE_AREA_QUERY = gql`
    query getAdminBuildingAreas {
        areas {
            ...adminBuildingArea
        }
    }
    ${AREA_FRAGMENT}
`;

const BUILDING_FRAGMENT = gql`
    fragment adminBuildingDetail on Building {
        id
        code
        description
        isActive
        address {
            id
            address1
            address2
            city
            zoneId
            countryId
            zip
            longitude
            latitude
            phone
            firstName
            lastName
        }
        area {
            id
            code
        }
    }
`;

export const RETRIEVE_BUILDING_QUERY = gql`
    query getAdminBuildingById($buildingId: String!) {
        buildingById(id: $buildingId) {
            ...adminBuildingDetail
        }
    }
    ${BUILDING_FRAGMENT}
`;

export const ADMIN_UPDATE_BUILDING_MUTATION = gql`
    mutation adminUpdateBuilding($data: AdminUpdateBuildingInput!) {
        adminUpdateBuilding(data: $data) {
            data {
                id
                ...adminBuildingDetail
            }
        }
    }
    ${BUILDING_FRAGMENT}
`;

const defaultAddressInput: AddressInput = {
    id: '',
    firstName: '',
    lastName: '',
    address1: '',
    address2: '',
    city: '',
    zoneId: '',
    zip: '',
    countryId: '',
    phone: '',
};

type BuildingDetailPageProps = {
    locale: LocaleType;
    countryCode: CountryCode;
};

export const BuildingDetailPage: React.FC<BuildingDetailPageProps> = ({ countryCode, locale }) => {
    const history = useHistory();
    const intl = useIntl();
    const [selectIndex,] = useState<number>(0);
    const [errorMessage, setErrorMessage] = useState<ErrorMessage | undefined>(undefined);
    const [addressInput, setAddressInput] = useState<AddressInput>({
        ...defaultAddressInput,
        countryId: countryCode,
    });

    const {
        data: retrievedAreaData,
    } = useGetAdminBuildingAreasQuery();

    const areasData = retrievedAreaData?.areas
        ? (retrievedAreaData?.areas as AdminBuildingAreaFragment[])
        : [];

    const { buildingId } = useParams<{ buildingId?: string }>();
    const [building, setBuilding] = useState<AdminBuildingDetailFragment>({
        id: '',
        code: '',
        description: '',
        area: {
            id: '',
            code: '',
        },
        isActive: true,
        address: {
            id: '',
            address1: '',
            address2: '',
            city: '',
            zoneId: '',
            countryId: '',
            zip: '',
            phone: '',
            firstName: '',
            lastName: '',
        },
    });

    const {
        loading: retrievedBuildingDetailLoading,
        error: retrievedBuildingDetailError,
    } = useGetAdminBuildingByIdQuery({
        variables: {
            buildingId: buildingId ?? '',
        },
        onCompleted: (data) => {
            setBuilding(
                data.buildingById ?? {
                    id: '',
                    code: '',
                    description: '',
                    area: {
                        id: '',
                        code: '',
                    },
                    isActive: true,
                    address: {
                        id: '',
                        address1: '',
                        address2: '',
                        city: '',
                        zoneId: '',
                        countryId: '',
                        zip: '',
                    },
                },
            );
            setAddressInput({
                id: data?.buildingById?.address.id || '',
                address1: data?.buildingById?.address.address1 || '',
                address2: data?.buildingById?.address.address2 || '',
                city: data?.buildingById?.address.city || '',
                zoneId: data?.buildingById?.address.zoneId || '',
                countryId: data?.buildingById?.address.countryId || '',
                zip: data?.buildingById?.address.zip || '',
                phone: data?.buildingById?.address.phone || '',
                firstName: data?.buildingById?.address.firstName || '',
                lastName: data?.buildingById?.address.lastName || '',
            });
        },
    });

    const selectItems: { id: string; title: string }[] = [];
    areasData.map((item) =>
        selectItems.push({
            id: item.id,
            title: item.code,
        }),
    );

    const [
        adminUpdateBuildingMutation,
        {
            loading: adminUpdateBuildingDetailMutationLoading,
            error: adminUpdateBuildingDetailMutationError,
        },
    ] = useAdminUpdateBuildingMutation({
        onCompleted(data) {
            if (data) {
                handleBacklClick();
            }
        },
    });

    const handleBacklClick = useCallback(async () => {
        history.push(`/admin/buildings`);
    }, [history]);

    const handleAddressChanged = useCallback(
        async (changedAddressInput: AddressInput): Promise<void> => {
            try {
                const {
                    id,
                    address1,
                    address2,
                    city,
                    zoneId,
                    countryId,
                    zip,
                    phone,
                    firstName,
                    lastName,
                } = changedAddressInput;
                if (id) {
                    setAddressInput(changedAddressInput);
                    setBuilding({
                        ...building,
                        address: {
                            id,
                            address1,
                            address2,
                            city,
                            zoneId,
                            countryId,
                            zip,
                            phone,
                            firstName,
                            lastName,
                        },
                    });
                }
            } catch (ignore) { } //eslint-disable-line no-empty
        },
        [setAddressInput, building, setBuilding],
    );

    const handleSaveBuildingDetailClick = useCallback(async () => {
        if (building) {
            try {
                const invalidAddress = validateAddressInput(addressInput, intl);
                if (invalidAddress) {
                    return setErrorMessage(invalidAddress);
                } else {
                    setErrorMessage(undefined);
                    await adminUpdateBuildingMutation({
                        variables: {
                            data: {
                                id: building.id,
                                code: building.code,
                                description: building.description,
                                areaId: building.area.id,
                                isActive: building.isActive,
                                address: {
                                    address1: building.address.address1,
                                    address2: building.address.address2,
                                    city: building.address.city,
                                    zoneId: building.address.zoneId,
                                    countryId: building.address.countryId,
                                    zip: building.address.zip,
                                    phone: building.address.phone,
                                    firstName: building.address.firstName,
                                    lastName: building.address.lastName,
                                },
                            },
                        },
                    });
                }
            } catch (ignore) { } //eslint-disable-line no-empty
        }
    }, [adminUpdateBuildingMutation, building, addressInput, intl]);

    return (
        <ScrollView>
            <View style={buildingPageStyles.ViewParent} testID="data-buildingDetail">
                <View style={buildingPageStyles.ViewChild}>
                    <View style={buildingPageStyles.ViewContentParent}>
                        <View style={buildingPageStyles.ViewTitleParent}>
                            <Text style={buildingPageStyles.TextTitle} testID="data-building">
                                {`${intl.formatMessage({
                                    id: 'buildingDetail.id',
                                    defaultMessage: 'Building Details:',
                                })}${''}`}
                            </Text>
                        </View>
                        {errorMessage && (
                            <Message
                                type={errorMessage.type}
                                header={errorMessage.header}
                                content={errorMessage.content}
                            />
                        )}
                        {retrievedBuildingDetailLoading && (
                            <View testID="data-buildingDetailItemLoading">
                                <Text>
                                    <FormattedMessage id="buildingDetail.loading" defaultMessage="Loading..." />
                                </Text>
                            </View>
                        )}
                        {retrievedBuildingDetailError && (
                            <View style={buildingPageStyles.ViewErrorMessageParent} testID="data-errorMessage">
                                <Message
                                    type="negative"
                                    header={intl.formatMessage({
                                        id: 'buildingDetail.buildingDetailErrorMessage',
                                        defaultMessage: 'Could not load detail for the building - please try again',
                                    })}
                                    content={retrievedBuildingDetailError.message}
                                    testID="data-buildingDetailErrorMessage"
                                />
                            </View>
                        )}
                        {adminUpdateBuildingDetailMutationError && (
                            <Message
                                type="negative"
                                header={intl.formatMessage({
                                    id: 'buildingDetail.buildingDetailUpdateErrorMessage',
                                    defaultMessage:
                                        'Could not update building detail - please try again',
                                })}
                                content={adminUpdateBuildingDetailMutationError.message}
                                testID="data-buildingDetailUpdateErrorMessage"
                            />
                        )}
                        {adminUpdateBuildingDetailMutationLoading && (
                            <Text>
                                <FormattedMessage
                                    id="buildingDetail.loading"
                                    defaultMessage="Loading..."
                                />
                            </Text>
                        )}
                        <View style={buildingPageStyles.ViewTitleParent}>
                            <View
                                style={buildingPageStyles.TextContainerItem}
                                key={building.id}
                                testID="data-disableBuilding"
                            >
                                <FormattedMessage
                                    id="buildingDetail.isActive"
                                    defaultMessage="Active:  "
                                />
                                <View style={{ marginLeft: 5 }}>
                                    <Checkbox
                                        onValueChange={(value: boolean) =>
                                            setBuilding({ ...building, isActive: value })
                                        }
                                        checked={building.isActive}
                                        testID="data-disableBuildingCheck"
                                    />
                                </View>
                            </View>
                            <View style={buildingPageStyles.ViewDescription}>
                                <View>
                                    <FormattedMessage
                                        id="buildingDetail.code"
                                        defaultMessage="Code"
                                    />
                                    <TextInput
                                        type="text"
                                        ariaLabel="Code"
                                        value={building?.code}
                                        onChange={(value: string) => {
                                            setBuilding({ ...building, code: value });
                                        }}
                                    />
                                </View>
                            </View>
                            <View style={buildingPageStyles.ViewDescription}>
                                <View>
                                    <FormattedMessage
                                        id="buildingDetail.description"
                                        defaultMessage="Description"
                                    />
                                    <TextInput
                                        type="text"
                                        ariaLabel="Description"
                                        value={building?.description}
                                        onChange={(value: string) => {
                                            setBuilding({ ...building, description: value });
                                        }}
                                    />
                                </View>
                            </View>
                            <View style={buildingPageStyles.ViewDescription}>
                                <FormattedMessage id="buildingDetail.Area" defaultMessage="Area" />
                                <Select
                                    currentIndex={selectIndex}
                                    itemsDisplayValues={selectItems.map((items) => items.title)}
                                    onSelect={(index) =>
                                        setBuilding({
                                            ...building,
                                            area: {
                                                id: selectItems[index].id,
                                                code: selectItems[index].title,
                                            },
                                        })
                                    }
                                >
                                    {selectItems.map((item) => (
                                        <SelectItem title={item.title} key={item.id} />
                                    ))}
                                </Select>
                            </View>
                            <View style={buildingPageStyles.ViewDescription}>
                                <FormattedMessage
                                    id="buildingDetail.AddressHeader"
                                    defaultMessage="Address"
                                />
                                <NewAddressForm
                                    locale={locale}
                                    countryCode={countryCode}
                                    currentAddress={addressInput}
                                    onAddressChanged={handleAddressChanged}
                                />
                            </View>
                        </View>
                    </View>
                </View>
                <View
                    style={{
                        marginVertical: 10,
                        marginHorizontal: 10,
                        display: 'flex',
                        flexDirection: 'row',
                    }}
                >
                    <View style={{ flex: 1, marginRight: 10 }}>
                        <Button
                            title={intl.formatMessage({
                                id: 'buildingDetail.Cancel',
                                defaultMessage: 'Cancel',
                            })}
                            onPress={handleBacklClick}
                        />
                    </View>

                    <View style={{ flex: 1 }}>
                        <Button
                            title={intl.formatMessage({
                                id: 'buildingDetail.Save',
                                defaultMessage: 'Save',
                            })}
                            onPress={handleSaveBuildingDetailClick}
                        />
                    </View>
                </View>
            </View>
        </ScrollView>
    );
};

const buildingPageStyles = StyleSheet.create({
    ViewParent: {
        display: 'flex',
        flexDirection: 'column',
    },
    ViewTabs: {
        marginVertical: 5,
        display: 'flex',
        flexDirection: 'row',
    },
    ViewContent: {
        paddingVertical: 5,
    },
    TextTab: {
        flex: 1,
        color: '#000',
        backgroundColor: '#D4D4D5',
        fontSize: 18,
        textAlign: 'center',
        fontWeight: '700',
    },

    ViewChild: {
        display: 'flex',
        flexDirection: 'row',
    },
    ViewImageParent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    RouteImage: {
        height: 40,
        width: 40,
        resizeMode: 'stretch',
    },
    ViewContentParent: {
        paddingHorizontal: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    ViewTitleParent: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 10,
    },
    ViewTitleChild: {},
    TextTitle: {
        fontSize: 18,
        fontWeight: 'bold',
    },
    ViewDescription: {
        marginTop: 10,
        fontWeight: 'bold',
    },
    TextDescription: { fontSize: 14 },
    ViewStatus: {},
    TextStatus: {
        fontSize: 12,
        fontWeight: '700',
    },
    ViewDate: {},
    TextDate: {
        fontSize: 12,
    },
    ViewErrorMessageParent: {
        marginTop: 5,
        marginBottom: 5,
    },
    TextContainerItem: {
        marginVertical: 2,
        display: 'flex',
        flexDirection: 'row',
        fontWeight: 'bold',
    },
});
