import { gql } from '@apollo/client';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FlatList, Image, ScrollView, StyleSheet, Text, View } from 'react-native';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Button, Icon, TextInput, Message, TopTabBar, TopTab, Card } from '@valet/ui-components';
import {
    usePickupRequestsByVisitIdQuery,
    useDeliveryPickupItemMutation,
    RequestStatus,
    PickupRequestsByVisitIdPickupRequestFragment,
    PickupRequestsByVisitIdStorageItemFragment,
    RequestType,
} from '../../../schema-types';

export const requestPageSize = 100;

const RETRIEVE_PICKUP_REQUESTS_STORAGE_ITEM_FRAGMENT = gql`
    fragment pickupRequestsByVisitIdStorageItem on StorageItem {
        id
        status
        barcode
        container {
            id
            barcode
        }
        storageItemType {
            id
            name
            description
            image
            containerType {
                id
            }
            metadataDefinitions {
                id
                propertyName
                isRequired
                propertyType
                values
                isActive
                sortOrder
            }
        }
        metadataValue {
            value
            metadataDefinition {
                id
                propertyName
            }
        }
    }
`;

const RETRIEVE_PICKUP_REQUESTS_PICKUP_REQUEST_FRAGMENT = gql`
    fragment pickupRequestsByVisitIdPickupRequest on PickupStorageItemRequest {
        id
        status
        requestType
        container {
            id
            status
            barcode
        }
        storageItem {
            id
            ...pickupRequestsByVisitIdStorageItem
        }
        customer {
            email
            firstName
            lastName
            homePhone
            cellPhone
        }
    }

    ${RETRIEVE_PICKUP_REQUESTS_STORAGE_ITEM_FRAGMENT}
`;

export const RETRIEVE_PICKUP_REQUESTS_QUERY = gql`
    query pickupRequestsByVisitId($visitId: String!, $pageSize: Int!, $cursor: String!) {
        requestsByVisitId(visitId: $visitId, filter: { pageSize: $pageSize, after: $cursor }) {
            edges {
                cursor
                node {
                    id
                    requestType
                    ...pickupRequestsByVisitIdPickupRequest
                }
            }
            pageInfo {
                cursor {
                    beforeCursor
                    afterCursor
                }
                hasNextPage
                hasPreviousPage
            }
        }
    }

    ${RETRIEVE_PICKUP_REQUESTS_PICKUP_REQUEST_FRAGMENT}
`;

export const DELIVERY_PICKUP_ITEM_MUTATION = gql`
    mutation deliveryPickupItem($data: DeliveryPickupItemInput!) {
        deliveryPickupItem(data: $data) {
            data {
                id
                status
            }
        }
    }
`;

type DeliveryPickupProcessProps = {
    onItemSelected: (routeId: string, visitId: string, requestId: string, itemId: string) => void;
};

export const DeliveryPickupProcess: React.FC<DeliveryPickupProcessProps> = ({ onItemSelected }) => {
    const history = useHistory();
    const match = useRouteMatch();
    const params: { routeId?: string; visitId?: string } = match.params;
    const routeId = params.routeId;
    const visitId = params.visitId;

    const [showActionMenu, setShowActionMenu] = useState<boolean>(false);
    const intl = useIntl();

    const handleGoBackPress = useCallback((): void => {
        history.push(`/delivery/${routeId}/${visitId}`);
    }, [history, routeId, visitId]);

    useEffect(() => {
        if (!visitId) {
            handleGoBackPress();
        }
    }, [visitId, handleGoBackPress]);

    const [isFetching, setIsFetching] = useState<boolean>(false);

    const {
        data: retrievedRequestsData,
        loading: retrievedRequestsLoading,
        error: retrievedRequestsError,
        fetchMore: retrievedRequestsFetchMore,
    } = usePickupRequestsByVisitIdQuery({
        variables: {
            visitId: visitId || '',
            pageSize: requestPageSize,
            cursor: '',
        },
    });

    const requestEdges = retrievedRequestsData?.requestsByVisitId.edges ?? [];
    const requestData = useMemo(() => {
        return {
            requests:
                requestEdges
                    .map((itemEdge) => itemEdge.node)
                    .filter((request) => request.requestType === RequestType.PickupStorageItem)
                    .map((request) => request as PickupRequestsByVisitIdPickupRequestFragment) ??
                [],
            hasNextPage: retrievedRequestsData?.requestsByVisitId?.pageInfo?.hasNextPage ?? false,
            endCursor: retrievedRequestsData?.requestsByVisitId?.pageInfo?.cursor.afterCursor ?? '',
        };
    }, [requestEdges, retrievedRequestsData]);

    const handleLoadMoreItems = useCallback((): void => {
        if (retrievedRequestsData?.requestsByVisitId?.pageInfo?.hasNextPage && !isFetching) {
            setIsFetching(true);
            retrievedRequestsFetchMore({
                variables: {
                    pageSize: requestPageSize,
                    cursor: retrievedRequestsData?.requestsByVisitId?.pageInfo?.cursor.afterCursor,
                },
            }).then(() => {
                setIsFetching(false);
            });
        }
    }, [isFetching, retrievedRequestsFetchMore, retrievedRequestsData]);

    useEffect(() => {
        if (!retrievedRequestsError && requestData.hasNextPage) {
            handleLoadMoreItems();
        }
    }, [handleLoadMoreItems, requestData, retrievedRequestsError]);

    useEffect(() => {
        if (!routeId) {
            handleGoBackPress();
        }
    }, [routeId, handleGoBackPress]);

    const requests = useMemo<{
        scanned: PickupRequestsByVisitIdPickupRequestFragment[];
        waiting: PickupRequestsByVisitIdPickupRequestFragment[];
    }>(() => {
        const scanned = [];
        const waiting = [];

        const pickupRequests = requestData.requests
            .filter((r) => r.requestType === RequestType.PickupStorageItem)
            .map((r) => r as PickupRequestsByVisitIdPickupRequestFragment);
        for (let i = 0; i < pickupRequests.length; i++) {
            const item = pickupRequests[i];
            if (item.status === RequestStatus.Processed) {
                scanned.push(item);
            }

            if (item.status !== RequestStatus.Processed) {
                waiting.push(item);
            }
        }

        return {
            scanned,
            waiting,
        };
    }, [requestData.requests]);

    useEffect(() => {
        if (!routeId) {
            handleGoBackPress();
        }
    }, [routeId, handleGoBackPress]);

    const handleItemClick = useCallback(
        (id: string) => {
            if (requestData.requests) {
                const req = requestData.requests.find((r) => r.id === id);
                onItemSelected(routeId || '', visitId || '', id, req?.storageItem?.id || '');
            }

            setShowActionMenu(!showActionMenu);
        },
        [requestData.requests, showActionMenu, onItemSelected, routeId, visitId],
    );

    const [expandBarcodeManualEntry, setExpandBarcodeManualEntry] = useState<boolean>(false);
    const [barcodeEntryValue, setBarcodeEntryValue] = useState<string>('');
    const [currentItem, setCurrentItem] = useState<
        PickupRequestsByVisitIdStorageItemFragment | undefined
    >(undefined);

    const handleManualScanCameraPress = useCallback((): void => {
        console.log('Pressed Manual Scan Camera Icon');
    }, []);

    const handleManualScanKeyboardPress = useCallback((): void => {
        setExpandBarcodeManualEntry(!expandBarcodeManualEntry);
    }, [expandBarcodeManualEntry]);

    const [
        deliveryPickupItemMutation,
        { loading: deliveryPickupItemMutationLoading, error: deliveryPickupItemMutationError },
    ] = useDeliveryPickupItemMutation({
        onCompleted({ deliveryPickupItem: { data } }) {
            if (data) {
                handleLoadMoreItems();
            }
        },
    });

    const handleManualScanSubmit = useCallback(async () => {
        if (barcodeEntryValue) {
            try {
                if (requestData.requests && requestData.requests.length > 0) {
                    for (let i = 0; i < requestData.requests.length; i++) {
                        const request = requestData.requests[i];

                        if (
                            request.storageItem?.container?.barcode === barcodeEntryValue ||
                            request.container?.barcode === barcodeEntryValue ||
                            request.storageItem?.barcode === barcodeEntryValue
                        ) {
                            setCurrentItem(request.storageItem || undefined);

                            let hasMissingMetadata = false;
                            const requiredMetadata =
                                request?.storageItem?.storageItemType?.metadataDefinitions?.filter(
                                    (m) => m.isRequired,
                                ) ?? [];

                            for (let index = 0; index < requiredMetadata.length; index++) {
                                const metdt = requiredMetadata[index];
                                if (
                                    !request?.storageItem?.metadataValue?.find(
                                        (m) => m.metadataDefinition?.id === metdt.id && m.value,
                                    )
                                )
                                    hasMissingMetadata = true;
                            }

                            if (hasMissingMetadata)
                                onItemSelected(
                                    routeId || '',
                                    visitId || '',
                                    request.id,
                                    request?.storageItem?.id || '',
                                );
                            else {
                                await deliveryPickupItemMutation({
                                    variables: {
                                        data: {
                                            requestId: request.id,
                                        },
                                    },
                                });
                            }

                            break;
                        }
                    }
                }
                setExpandBarcodeManualEntry(!expandBarcodeManualEntry);
                setBarcodeEntryValue('');
            } catch {}
        }
    }, [
        requestData.requests,
        barcodeEntryValue,
        expandBarcodeManualEntry,
        deliveryPickupItemMutation,
        routeId,
        visitId,
        onItemSelected,
    ]);

    const handleClearScreenPress = useCallback((): void => {
        setCurrentItem(undefined);
        setExpandBarcodeManualEntry(false);
    }, []);

    // Top tabs
    const tabs = [
        {
            title: intl.formatMessage({
                id: 'deliveryPickupProcess.waitingTabText',
                defaultMessage: 'Waiting',
            }),
        },
        {
            title: intl.formatMessage({
                id: 'deliveryPickupProcess.scannedTabText',
                defaultMessage: 'Scanned',
            }),
        },
    ];
    const [currentTab, setCurrentTab] = useState<number>(0);

    const handleTabSelect = (index: number): void => {
        setCurrentTab(index);
    };

    if (isFetching || retrievedRequestsLoading || deliveryPickupItemMutationLoading) {
        return (
            <View testID="data-deliveryPickupItemLoading">
                <Text>
                    <FormattedMessage
                        id="deliveryPickupProcess.loading"
                        defaultMessage="Loading..."
                    />
                </Text>
            </View>
        );
    }

    if (deliveryPickupItemMutationError) {
        return (
            <View
                style={deliveryPickupProcessStyles.ViewErrorMessageParent}
                testID="data-errorMessage"
            >
                <Message
                    type="negative"
                    header={intl.formatMessage({
                        id: 'deliveryPickupProcess.deliveryPickupProcessErrorMessage',
                        defaultMessage: 'Could not pickup this item - please try again',
                    })}
                    content={deliveryPickupItemMutationError.message}
                    testID="data-deliveryPickupProcessErrorMessage"
                />
            </View>
        );
    }

    return (
        <ScrollView>
            <View style={deliveryPickupProcessStyles.ViewParent} testID="data-deliveryDetail">
                {!showActionMenu && (
                    <View>
                        <View style={deliveryPickupProcessStyles.ViewTopBar}>
                            <Button
                                accessoryLeft={() => <Icon icon="back" />}
                                title={intl.formatMessage({
                                    id: 'deliveryRouteDetail.back',
                                    defaultMessage: 'Back',
                                })}
                                onPress={handleGoBackPress}
                                appearance="ghost"
                            />
                        </View>
                        <TopTabBar onSelect={handleTabSelect} selectedIndex={currentTab}>
                            {tabs.map((tab) => (
                                <TopTab key={tab.title} title={tab.title} />
                            ))}
                        </TopTabBar>
                        <View style={deliveryPickupProcessStyles.ViewContent}>
                            <View style={deliveryPickupProcessStyles.ViewContent}>
                                {(currentTab === 0 && requests.waiting.length > 0) ||
                                (currentTab === 1 && requests.scanned.length > 0) ? (
                                    <FlatList
                                        data={
                                            currentTab === 0 ? requests.waiting : requests.scanned
                                        }
                                        renderItem={({ item }) => (
                                            <RequestDetails
                                                request={item}
                                                onItemClick={handleItemClick}
                                            />
                                        )}
                                        keyExtractor={(item) => item.id}
                                    />
                                ) : (
                                    <Text>
                                        <FormattedMessage
                                            id="deliveryPickupProcess.noRequestsText"
                                            defaultMessage="There are no requests for this visit."
                                        />
                                    </Text>
                                )}
                            </View>
                        </View>

                        <View
                            style={deliveryPickupProcessStyles.ViewActionsGroup}
                            testID="data-deliveryPickupItemActionsContainer"
                        >
                            {expandBarcodeManualEntry && (
                                <View
                                    style={{
                                        padding: 3,
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                    testID="data-deliveryPickupItemManualBarcodeEntryContainer"
                                >
                                    <View style={{ flex: 1, marginRight: 5 }}>
                                        <TextInput
                                            type="text"
                                            onChange={(e) => setBarcodeEntryValue(e)}
                                            value={barcodeEntryValue}
                                            ariaLabel={intl.formatMessage({
                                                id:
                                                    'deliveryPickupItemManualBarcodeEntryPlaceholderText',
                                                defaultMessage: 'Enter item barcode',
                                            })}
                                            placeholder={intl.formatMessage({
                                                id:
                                                    'deliveryPickupItemManualBarcodeEntryPlaceholderText',
                                                defaultMessage: 'Enter item barcode',
                                            })}
                                            testID="data-deliveryPickupItemManualBarcodeEntryInput"
                                        />
                                    </View>

                                    <View style={{ marginLeft: 5 }}>
                                        <Button
                                            onPress={handleManualScanSubmit}
                                            title={intl.formatMessage({
                                                id: 'deliveryPickupItemScanButton',
                                                defaultMessage: 'SCAN',
                                            })}
                                            testID="data-deliveryPickupItemManualBarcodeEntrySubmitButton"
                                        />
                                    </View>
                                </View>
                            )}
                            <View style={{ display: 'flex', flexDirection: 'row' }}>
                                <View style={deliveryPickupProcessStyles.ViewManualScanIcons}>
                                    {currentItem && (
                                        <Button
                                            accessoryLeft={() => <Icon icon="pickListCancelled" />}
                                            onPress={handleClearScreenPress}
                                            testID="data-deliveryPickupItemActionKeyboard"
                                            title={''}
                                            appearance="ghost"
                                            accessibilityLabel={intl.formatMessage({
                                                id: 'deliveryPickupItemCancelButton',
                                                defaultMessage: 'Go back',
                                            })}
                                        />
                                    )}

                                    <Button
                                        accessoryLeft={() => <Icon icon="barcodeCamera" />}
                                        onPress={handleManualScanCameraPress}
                                        testID="data-deliveryPickupItemActionCamera"
                                        title={''}
                                        appearance="ghost"
                                        accessibilityLabel={intl.formatMessage({
                                            id: 'deliveryPickupItemCameraButton',
                                            defaultMessage: 'Open camera to scan item',
                                        })}
                                    />

                                    <Button
                                        accessoryLeft={() => <Icon icon="barcodeKeyboard" />}
                                        onPress={handleManualScanKeyboardPress}
                                        testID="data-deliveryPickupItemActionKeyboard"
                                        title={''}
                                        appearance="ghost"
                                        accessibilityLabel={intl.formatMessage({
                                            id: 'deliveryPickupItemKeyboardButton',
                                            defaultMessage: 'Open keyboard to enter barcode',
                                        })}
                                    />
                                </View>
                            </View>
                        </View>
                    </View>
                )}
            </View>
        </ScrollView>
    );
};

const deliveryPickupProcessStyles = StyleSheet.create({
    ViewParent: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    ViewTabs: {
        marginVertical: 5,
        display: 'flex',
        flexDirection: 'row',
    },
    ViewContent: {
        paddingVertical: 5,
    },
    TextTab: {
        flex: 1,
        color: '#000',
        backgroundColor: '#D4D4D5',
        fontSize: 18,
        textAlign: 'center',
        fontWeight: '700',
    },
    ViewTopBar: {
        paddingBottom: 5,
        display: 'flex',
        flexDirection: 'row' as 'row',
        alignItems: 'center',
        borderBottomWidth: 1,
        borderBottomColor: '#d4d4d5',
    },
    ViewActionsGroup: {
        marginVertical: 5,
        display: 'flex',
        flexDirection: 'column',
        borderTopColor: '#D4D4D5',
        borderTopWidth: 1,
    },
    ViewButton: {
        marginTop: 10,
    },
    CancelAction: {
        borderWidth: 1,
        borderRadius: 5,
        padding: 5,
    },
    ViewDetailGroup: {
        marginVertical: 2,
        display: 'flex',
        flexDirection: 'row',
    },
    DeliveryHeader: {
        fontWeight: '700',
        flex: 1,
    },

    ViewExpandPickList: {
        flex: 1,
        paddingVertical: 5,
        display: 'flex',
        overflowY: 'scroll',
        overflowX: 'none',
    },
    ViewExpandPickListText: {
        marginVertical: 5,
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
    },
    ViewManualScanIcons: {
        marginVertical: 5,
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    ViewActions: {
        marginVertical: 5,
        display: 'flex',
        flexDirection: 'row',
    },
    ViewNavigationButtons: {
        flex: 1,
        marginRight: 5,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    TextActionList: {
        alignSelf: 'flex-start',
        color: '#0984e3',
    },
    TextCurrentItemNumber: {
        fontWeight: '700',
        fontSize: 18,
        textAlign: 'center',
    },
    ViewItem: {
        flex: 1,
    },
    TextHeader: {
        fontWeight: '700',
    },
    ViewErrorMessageParent: {
        marginTop: 5,
        marginBottom: 5,
    },
});

type RequestDetailsProps = {
    request: PickupRequestsByVisitIdPickupRequestFragment;
    onItemClick: (id: string) => void;
};

const RequestDetails: React.FC<RequestDetailsProps> = ({ request, onItemClick }) => {
    const {
        storageItem,
        status,
        customer: { firstName, lastName },
        container: requestContainer,
    } = request;

    return (
        <Card
            onPress={() => onItemClick(request.id)}
            style={RequestDetailsStyles.ViewParent}
            testID="data-requestDetails"
        >
            <View style={RequestDetailsStyles.ViewDetailGroup}>
                <Image
                    style={RequestDetailsStyles.ImageItem}
                    source={{ uri: storageItem?.storageItemType.image }}
                    testID="data-RequestDetailsImage"
                />
                <View>
                    <View style={RequestDetailsStyles.ViewDetailGroup}>
                        <Text
                            style={RequestDetailsStyles.TextItemValue}
                            testID="data-RequestDetailsDescription"
                        >
                            {storageItem?.storageItemType.description}
                        </Text>
                    </View>
                    <View style={RequestDetailsStyles.ViewDetailGroup}>
                        <Text
                            style={RequestDetailsStyles.CustomerTextItemLabel}
                            testID="data-RequestDetailsLocation"
                        >
                            <FormattedMessage
                                id="requestDetails.customer"
                                defaultMessage="Customer: "
                            />
                        </Text>
                        <Text
                            style={RequestDetailsStyles.TextItemValue}
                        >{`${firstName} ${lastName}`}</Text>
                    </View>
                    <View style={RequestDetailsStyles.ViewDetailGroup}>
                        <Text
                            style={RequestDetailsStyles.CustomerTextItemLabel}
                            testID="data-RequestDetailsLocation"
                        >
                            <FormattedMessage
                                id="requestDetails.barcode"
                                defaultMessage="Barcode"
                            />
                            :
                        </Text>
                        <Text style={RequestDetailsStyles.TextItemValue}>
                            {storageItem?.container?.barcode ||
                            storageItem?.storageItemType?.containerType
                                ? requestContainer?.barcode
                                : storageItem?.barcode}
                        </Text>
                    </View>
                    <View style={RequestDetailsStyles.ViewDetailGroup}>
                        <Text
                            style={RequestDetailsStyles.CustomerTextItemLabel}
                            testID="data-RequestDetailsLocation"
                        >
                            <FormattedMessage
                                id="requestDetails.customer"
                                defaultMessage="Status: "
                            />
                        </Text>
                        <Text style={RequestDetailsStyles.TextItemValue}>{status}</Text>
                    </View>
                </View>
            </View>
        </Card>
    );
};

const RequestDetailsStyles = StyleSheet.create({
    ViewParent: {
        marginVertical: 3,
    },
    ViewDetailGroup: {
        marginVertical: 2,
        display: 'flex',
        flexDirection: 'row',
    },
    ViewImageGroup: {
        marginVertical: 5,
        display: 'flex',
        justifyContent: 'center',
    },
    TextDetailHeader: {
        fontWeight: '700',
    },
    ImageItem: {
        height: 40,
        width: 40,
        resizeMode: 'stretch',
        marginLeft: 5,
        marginRight: 10,
    },

    ViewItemsList: {
        marginVertical: 5,
    },
    ViewItem: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
    },
    ViewItemsSummary: {
        marginVertical: 5,
    },
    ViewAssignee: {
        marginVertical: 5,
    },
    TextHeader: {
        fontWeight: '700',
    },
    TextItemLabel: {
        flex: 1,
    },
    CustomerTextItemLabel: {
        flex: 0,
        marginRight: 5,
    },
    TextItemValue: {
        flex: 1,
        fontWeight: '700',
    },
    TouchableOpacityItem: {
        borderWidth: 1,
        borderRadius: 5,
        padding: 5,
    },
    TextDescription: {
        fontWeight: '700',
    },
    ViewButton: {},
});
