import { FieldName } from "@shopify/address";
import { ErrorMessage } from "@valet/ui-components";
import { AddressInput } from "./NewAddressForm";
import postalCodes from 'postal-codes-js';
import { IntlShape } from "react-intl";

export function validateAddressInput(addressInput: AddressInput, intl: IntlShape): ErrorMessage | undefined {
    const requiredFields: FieldName[] = [FieldName.Address1, FieldName.City, FieldName.Country];
    const addressInputMapping: { [key: string]: FieldName } = {
        firstName: FieldName.FirstName,
        lastName: FieldName.LastName,
        address1: FieldName.Address1,
        address2: FieldName.Address2,
        city: FieldName.City,
        province: FieldName.Zone,
        zip: FieldName.PostalCode,
        country: FieldName.Country,
        phone: FieldName.Phone,
    };

    for (const [key, value] of Object.entries(addressInput)) {
        if (requiredFields.includes(addressInputMapping[key])) {
            if (value === '') {
                return {
                    header: '',
                    content:
                        intl.formatMessage({
                            id: 'newAddress.errorFillInRequiredFields',
                            defaultMessage: 'Please fill in the required fields.',
                        }),
                    type: 'warning',
                };
            }
        }
    }

    // These countries' zip fields have discrepencies in the packages @shopify/address vs. postal-codes-js
    const countriesToSkip = ['AI', 'BO', 'JM', 'PA', 'TD', 'TT'];

    if (!countriesToSkip.includes(addressInput['countryId'])) {
        const country = addressInput['countryId'];
        const zip = addressInput['zip'] || 'placeholder'; // Empty string will not work

        const validPostalCode: boolean | string = postalCodes.validate(country, zip);

        if (validPostalCode !== true) {
            let errorMessageContent =
                intl.formatMessage(
                    {
                        id: 'newAddress.errorIssueWithPostalCode',
                        defaultMessage:
                            'There was an issue with your postal code: {postalCode}',
                    },
                    {
                        postalCode: validPostalCode,
                    },
                );

            if (validPostalCode.toString().includes('is not valid for country')) {
                errorMessageContent =
                    intl.formatMessage(
                        {
                            id: 'newAddress.errorPostalCodeNotValidForCountry',
                            defaultMessage:
                                'The postal code {postalCode} is not valid for your country.',
                        },
                        {
                            postalCode: zip,
                        },
                    );
            }

            if (
                validPostalCode
                    .toString()
                    .includes('Postal code placeholder is not valid for country')
            ) {
                errorMessageContent =
                    intl.formatMessage({
                        id: 'newAddress.errorFillInPostalCode',
                        defaultMessage: 'Please fill in your postal/zip code.',
                    });
            }

            return {
                header: '',
                content: errorMessageContent,
                type: 'warning',
            };
        }
    }
    return undefined;
}