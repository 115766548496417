import { gql } from '@apollo/client';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ScrollView, StyleSheet, Text, View } from 'react-native';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Button, Icon, TextInput, Message, ErrorMessage } from '@valet/ui-components';
import {
    useTruckUnloadingProcessItemGetRouteByVehicleQuery,
    useTruckUnloadingProcessItemMutation,
    useTruckUnloadingLocationByBarcodeLazyQuery,
    TruckUnloadingProcessItemRouteFragment,
    TruckUnloadingLocationFragment,
    LocationType,
} from '../../../schema-types';

const TRUCKUNLOADING_PROCESS_ITEM_ROUTE_FRAGMENT = gql`
    fragment TruckUnloadingProcessItemRoute on Route {
        id
        routeNumber
    }
`;

export const RETRIEVE_TRUCKUNLOADING_REQUESTS_QUERY = gql`
    query truckUnloadingProcessItemGetRouteByVehicle(
        $vehicleId: String!
        $routeStatus: RouteStatus
        $fromDate: DateTime
        $toDate: DateTime
    ) {
        routeByVehicleId(
            vehicleId: $vehicleId
            routeStatus: $routeStatus
            fromDate: $fromDate
            toDate: $toDate
        ) {
            id
            ...TruckUnloadingProcessItemRoute
        }
    }

    ${TRUCKUNLOADING_PROCESS_ITEM_ROUTE_FRAGMENT}
`;

export const TRUCKUNLOADING_DELIVER_ITEM_MUTATION = gql`
    mutation truckUnloadingProcessItem($data: TruckUnloadingProcessItemInput!) {
        truckUnloadingProcessItem(data: $data) {
            data
        }
    }
`;

const TRUCKUNLOADING_LOCATION_FRAGMENT = gql`
    fragment TruckUnloadingLocation on Location {
        id
        barcode
        description
        type
    }
`;

export const RETRIEVE_LOCATION_BY_BARCODE_QUERY = gql`
    query TruckUnloadingLocationByBarcode($barcode: String!) {
        locationByBarcode(barcode: $barcode) {
            id
            ...TruckUnloadingLocation
        }
    }
    ${TRUCKUNLOADING_LOCATION_FRAGMENT}
`;

type TruckUnloadingProcessItemProps = {
    onTruckUnloaded: () => void;
};

export const requestPageSize = 100;
export const TruckUnloadingProcessItem: React.FC<TruckUnloadingProcessItemProps> = ({
    onTruckUnloaded,
}) => {
    const history = useHistory();
    const match = useRouteMatch();
    const params: { routeId?: string; vehicleId?: string } = match.params;
    const vehicleId = params.vehicleId;
    const [errorMessage, setErrorMessage] = useState<ErrorMessage | undefined>(undefined);

    const [currentLocation, setCurrentLocation] = useState<
        TruckUnloadingLocationFragment | undefined
    >(undefined);
    const intl = useIntl();

    const handleGoBackPress = useCallback((): void => {
        history.push(`/truckUnloading/${vehicleId}`);
    }, [history, vehicleId]);

    useEffect(() => {
        if (!vehicleId) {
            handleGoBackPress();
        }
    }, [vehicleId, handleGoBackPress]);

    const {
        data: retrievedRoutesData,
        loading: retrievedRoutesLoading,
        error: retrievedRoutesError,
    } = useTruckUnloadingProcessItemGetRouteByVehicleQuery({
        variables: {
            vehicleId: vehicleId || '',
        },
    });

    const requestData = useMemo(() => {
        return {
            routes: retrievedRoutesData?.routeByVehicleId as TruckUnloadingProcessItemRouteFragment[],
        };
    }, [retrievedRoutesData]);

    const [expandBarcodeManualEntry, setExpandBarcodeManualEntry] = useState<boolean>(false);
    const [barcodeEntryValue, setBarcodeEntryValue] = useState<string>('');

    const handleManualScanCameraPress = useCallback((): void => {
        console.log('Pressed Manual Scan Camera Icon');
    }, []);

    const handleManualScanKeyboardPress = useCallback((): void => {
        setExpandBarcodeManualEntry(!expandBarcodeManualEntry);
    }, [expandBarcodeManualEntry]);

    const [
        truckUnloadingProcessItemMutation,
        {
            loading: truckUnloadingProcessItemMutationLoading,
            error: truckUnloadingProcessItemMutationError,
        },
    ] = useTruckUnloadingProcessItemMutation({
        onCompleted() {
            onTruckUnloaded();
        },
    });

    const [locationByBarcode, { loading }] = useTruckUnloadingLocationByBarcodeLazyQuery({
        onCompleted: async (data) => {
            if (data?.locationByBarcode?.type !== LocationType.Incoming)
                setErrorMessage({
                    content: intl.formatMessage({
                        id: 'truckUnloadingItemProcess.invalidLocationType',
                        defaultMessage:
                            'Invalid Location Type - only Incoming location type can be used for unloading.',
                    }),
                    type: 'negative',
                    header: 'Error',
                });
            setCurrentLocation(data?.locationByBarcode ?? undefined);
        },
        onError: (err) =>
            setErrorMessage({
                content: err.message,
                type: 'negative',
                header: 'Error',
            }),
    });

    const handleManualScanSubmit = useCallback(async () => {
        if (barcodeEntryValue) {
            setErrorMessage(undefined);
            locationByBarcode({
                variables: {
                    barcode: barcodeEntryValue,
                },
            });

            setExpandBarcodeManualEntry(!expandBarcodeManualEntry);
            setBarcodeEntryValue('');
        }
    }, [locationByBarcode, barcodeEntryValue, expandBarcodeManualEntry]);

    const handleConfirmButtonClick = useCallback(() => {
        if (currentLocation && requestData.routes) {
            const routes = requestData.routes;

            truckUnloadingProcessItemMutation({
                variables: {
                    data: {
                        routeIds: routes.map((r) => r.id),
                        locationBarcode: currentLocation.barcode,
                    },
                },
            });

            setBarcodeEntryValue('');
        } else if (!currentLocation) {
            setErrorMessage({
                content: intl.formatMessage({
                    id: 'truckUnloadingItemProcess.noLocationAssigned',
                    defaultMessage:
                        'No Location is assigned - please assign location first and try again.',
                }),
                type: 'negative',
                header: 'Error',
            });
        }
    }, [requestData.routes, truckUnloadingProcessItemMutation, currentLocation, intl]);

    if (loading || retrievedRoutesLoading || truckUnloadingProcessItemMutationLoading) {
        return (
            <View testID="data-truckUnloadingProcessItemLoading">
                <Text>
                    <FormattedMessage
                        id="truckUnloadingItemProcess.loading"
                        defaultMessage="Loading..."
                    />
                </Text>
            </View>
        );
    }

    if (errorMessage) {
        return (
            <View
                style={truckUnloadingItemProcessStyles.ViewErrorMessageParent}
                testID="data-errorMessage"
            >
                <TruckUnloadingErrorMessage errorMessage={errorMessage} />
            </View>
        );
    }

    if (truckUnloadingProcessItemMutationError) {
        return (
            <View
                style={truckUnloadingItemProcessStyles.ViewErrorMessageParent}
                testID="data-errorMessage"
            >
                <Message
                    type="negative"
                    header={intl.formatMessage({
                        id: 'truckUnloadingItemProcess.truckUnloadingItemProcessErrorMessage',
                        defaultMessage: 'Could not deliver this item - please try again',
                    })}
                    content={truckUnloadingProcessItemMutationError.message}
                    testID="data-truckUnloadingItemProcessErrorMessage"
                />
            </View>
        );
    }

    if (retrievedRoutesError) {
        return (
            <View
                style={truckUnloadingItemProcessStyles.ViewErrorMessageParent}
                testID="data-errorMessage"
            >
                <Message
                    type="negative"
                    header={intl.formatMessage({
                        id: 'truckUnloadingItemProcess.truckUnloadingItemProcessErrorMessage',
                        defaultMessage:
                            'Could not load acitve route for selected truck - please try again',
                    })}
                    content={retrievedRoutesError.message}
                    testID="data-retrievedRoutesErrorMessage"
                />
            </View>
        );
    }

    return (
        <ScrollView>
            <View
                style={truckUnloadingItemProcessStyles.ViewParent}
                testID="data-truckUnloadingDetail"
            >
                {requestData.routes?.map((route) => (
                    <View style={truckUnloadingItemProcessStyles.ViewDetailGroup}>
                        <Text
                            style={truckUnloadingItemProcessStyles.TextHeader}
                            testID="data-truckUnloadingLocationBarcode"
                        >
                            <FormattedMessage
                                id="truckUnloading.routeNumber"
                                defaultMessage={`Route:${' #'}`}
                            />
                        </Text>
                        <Text>{route.routeNumber}</Text>
                    </View>
                ))}

                <View style={truckUnloadingItemProcessStyles.ViewDetailGroup}>
                    <Text
                        style={truckUnloadingItemProcessStyles.TextHeader}
                        testID="data-sortingLocationBarcode"
                    >
                        <FormattedMessage
                            id="sorting.itemBarcode"
                            defaultMessage={`Location:${' '}`}
                        />
                    </Text>
                    <Text>{`${currentLocation?.description || ''} ${
                        currentLocation?.barcode ? `(${currentLocation?.barcode})` : ''
                    }`}</Text>
                </View>
                <View style={truckUnloadingItemProcessStyles.ViewDetailGroup}>
                    <View
                        style={{
                            flexDirection: 'row',
                            margin: 5,
                        }}
                    >
                        <Button
                            title={intl.formatMessage({
                                id: 'truckUnloadingItemProcesscompletebutton',
                                defaultMessage: 'Confirm Completion',
                            })}
                            onPress={() => {
                                handleConfirmButtonClick();
                            }}
                        />
                    </View>
                </View>
                <View
                    style={truckUnloadingItemProcessStyles.ViewActionsGroup}
                    testID="data-deliveryPickupItemActionsContainer"
                >
                    {expandBarcodeManualEntry && (
                        <View
                            style={{
                                padding: 3,
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                            testID="data-deliveryPickupItemManualBarcodeEntryContainer"
                        >
                            <View style={{ flex: 1, marginRight: 5 }}>
                                <TextInput
                                    type="text"
                                    onChange={(e) => setBarcodeEntryValue(e)}
                                    value={barcodeEntryValue}
                                    ariaLabel={intl.formatMessage({
                                        id: 'deliveryPickupItemManualBarcodeEntryPlaceholderText',
                                        defaultMessage: 'Enter item barcode',
                                    })}
                                    placeholder={intl.formatMessage({
                                        id: 'deliveryPickupItemManualBarcodeEntryPlaceholderText',
                                        defaultMessage: 'Enter item barcode',
                                    })}
                                    testID="data-deliveryPickupItemManualBarcodeEntryInput"
                                />
                            </View>

                            <View style={{ marginLeft: 5 }}>
                                <Button
                                    onPress={handleManualScanSubmit}
                                    title={intl.formatMessage({
                                        id: 'deliveryPickupItemScanButton',
                                        defaultMessage: 'SCAN',
                                    })}
                                    testID="data-deliveryPickupItemManualBarcodeEntrySubmitButton"
                                />
                            </View>
                        </View>
                    )}
                    <View style={{ display: 'flex', flexDirection: 'row' }}>
                        <View style={truckUnloadingItemProcessStyles.ViewManualScanIcons}>
                            <Button
                                accessoryLeft={() => <Icon icon="barcodeCamera" />}
                                onPress={handleManualScanCameraPress}
                                testID="data-deliveryPickupItemActionCamera"
                                title={''}
                                appearance="ghost"
                                accessibilityLabel={intl.formatMessage({
                                    id: 'deliveryPickupItemCameraButton',
                                    defaultMessage: 'Open camera to scan item',
                                })}
                            />

                            <Button
                                accessoryLeft={() => <Icon icon="barcodeKeyboard" />}
                                onPress={handleManualScanKeyboardPress}
                                testID="data-deliveryPickupItemActionKeyboard"
                                title={''}
                                appearance="ghost"
                                accessibilityLabel={intl.formatMessage({
                                    id: 'deliveryPickupItemKeyboardButton',
                                    defaultMessage: 'Open keyboard to enter barcode',
                                })}
                            />
                        </View>
                    </View>
                </View>
            </View>
        </ScrollView>
    );
};

const truckUnloadingItemProcessStyles = StyleSheet.create({
    ViewParent: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    ViewTabs: {
        marginVertical: 5,
        display: 'flex',
        flexDirection: 'row',
    },
    ViewContent: {
        paddingVertical: 5,
    },
    TextTab: {
        flex: 1,
        color: '#000',
        backgroundColor: '#D4D4D5',
        fontSize: 18,
        textAlign: 'center',
        fontWeight: '700',
    },
    ViewTopBar: {
        paddingBottom: 5,
        display: 'flex',
        flexDirection: 'row' as 'row',
        alignItems: 'center',
        borderBottomWidth: 1,
        borderBottomColor: '#d4d4d5',
    },
    ViewActionsGroup: {
        marginVertical: 5,
        display: 'flex',
        flexDirection: 'column',
        borderTopColor: '#D4D4D5',
        borderTopWidth: 1,
    },
    ViewButton: {
        marginTop: 10,
    },
    CancelAction: {
        borderWidth: 1,
        borderRadius: 5,
        padding: 5,
    },
    ViewDetailGroup: {
        marginVertical: 2,
        display: 'flex',
        flexDirection: 'row',
    },
    TruckUnloadingHeader: {
        fontWeight: '700',
        flex: 1,
    },

    ViewExpandPickList: {
        flex: 1,
        paddingVertical: 5,
        display: 'flex',
        overflowY: 'scroll',
        overflowX: 'none',
    },
    ViewExpandPickListText: {
        marginVertical: 5,
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
    },
    ViewManualScanIcons: {
        marginVertical: 5,
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    ViewActions: {
        marginVertical: 5,
        display: 'flex',
        flexDirection: 'row',
    },
    ViewNavigationButtons: {
        flex: 1,
        marginRight: 5,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    TextActionList: {
        alignSelf: 'flex-start',
        color: '#0984e3',
    },
    TextCurrentItemNumber: {
        fontWeight: '700',
        fontSize: 18,
        textAlign: 'center',
    },
    ViewItem: {
        flex: 1,
    },
    TextHeader: {
        fontWeight: '700',
    },
    ViewErrorMessageParent: {
        marginTop: 5,
        marginBottom: 5,
    },
});

type TruckUnloadingErrorMessageProps = {
    errorMessage: ErrorMessage | undefined;
};

const TruckUnloadingErrorMessage: React.FC<TruckUnloadingErrorMessageProps> = ({
    errorMessage,
}) => {
    return <Message errorMessage={errorMessage} />;
};
