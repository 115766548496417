import React, { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { TouchableOpacity, FlatList, StyleSheet, Text, View } from 'react-native';
import { Button, Card, Icon, Select, SelectItem, TextInput } from '@valet/ui-components';

export type ScheduleTemplate = {
    id: string;
    startDate: Date;
    areaId: string;
};

export type ScheduleTemplateWindowType = {
    id: string;
    startTime: string;
    endTime: string;
    week: number;
    day: number;
};

type ScheduleTemplateWeekType = {
    monday: ScheduleTemplateWindowType[];
    tuesday: ScheduleTemplateWindowType[];
    wednesday: ScheduleTemplateWindowType[];
    thursday: ScheduleTemplateWindowType[];
    friday: ScheduleTemplateWindowType[];
    saturday: ScheduleTemplateWindowType[];
    sunday: ScheduleTemplateWindowType[];
};

type ScheduleWeekProps = {
    onSchedulePeriodAdded: (data: ScheduleTemplateWindowType) => void;
    onSchedulePeriodDeleted: (id: string) => void;
    onSchedulePeriodUpdated: (data: ScheduleTemplateWindowType) => void;
    weekData: ScheduleTemplateWeekType;
    weekNumber: number;
    parentMode: string;
};

export const ScheduleWeek: React.FC<ScheduleWeekProps> = ({
    onSchedulePeriodAdded,
    onSchedulePeriodDeleted,
    onSchedulePeriodUpdated,
    weekData,
    weekNumber,
    parentMode
}) => {
    type DayType = { day: string; value: number; periods: ScheduleTemplateWindowType[] };
    //TODO: use localised strings
    const weekDays: DayType[] = [
        { day: 'Mon', value: 1, periods: weekData.monday },
        { day: 'Tue', value: 2, periods: weekData.tuesday },
        { day: 'Wed', value: 3, periods: weekData.wednesday },
        { day: 'Thu', value: 4, periods: weekData.thursday },
        { day: 'Fri', value: 5, periods: weekData.friday },
        { day: 'Sat', value: 6, periods: weekData.saturday },
        { day: 'Sun', value: 7, periods: weekData.sunday },
    ];
    const intl = useIntl();
    console.log("parentMode", parentMode);

    const handleSchedulePeriodAdded = (data: ScheduleTemplateWindowType): void => {
        onSchedulePeriodAdded(data);
    };

    const handleSchedulePeriodDeleted = (id: string): void => {
        onSchedulePeriodDeleted(id);
    };

    const handleSchedulePeriodUpdated = (data: ScheduleTemplateWindowType): void => {
        onSchedulePeriodUpdated(data);
    };

    const [selectedDay, setSelectedDay] = useState<DayType | undefined>(undefined);

    const [addClicked, setAddClicked] = useState(false);
    const [newPeriod, setNewPeriod] = useState<ScheduleTemplateWindowType>({
        day: 1,
        week: weekNumber,
        startTime: '08:00',
        endTime: '10:00',
        id: '',
    });

    const handleNewPeriodSaved = (): void => {
        onSchedulePeriodAdded(newPeriod);
        setAddClicked(false);
    };

    const handleNewPeriodChanged = (data: ScheduleTemplateWindowType): void => {
        setNewPeriod(data);
    };

    return (
        <View style={scheduleWeekPageStyles.ViewListItemContainer}>
            <Card
                style={{ flex: 1 }}
                disabled
                footer={() => (
                    <View style={scheduleWeekPageStyles.ViewListItemActions}>
                        {!addClicked && selectedDay && (
                            <Button
                                title={intl.formatMessage({
                                    id: 'newScheduleDetail.AddPeriod',
                                    defaultMessage: 'Add Period',
                                })}
                                disabled={parentMode === 'View' ? false : true}
                                onPress={() => setAddClicked(true)}
                                size="tiny"
                            />
                        )}
                    </View>
                )}
            >
                <View style={scheduleWeekPageStyles.ViewListItemBody}>
                    <View
                        style={{
                            paddingVertical: 2,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}
                    >
                        <Text style={{ fontSize: 16 }}>
                            <FormattedMessage id="routing.selectedItems" defaultMessage="Week " />
                            {weekNumber}
                        </Text>
                    </View>
                    <View style={{ paddingVertical: 2 }}>
                        <Select
                            currentIndex={undefined}
                            itemsDisplayValues={weekDays.map((items) => items.day)}
                            onSelect={(index) => setSelectedDay(weekDays[index])}
                            disabled={parentMode === 'View' ? false : true}
                        >
                            {weekDays.map((item) => (
                                <SelectItem title={item.day} key={item.value} />
                            ))}
                        </Select>
                    </View>
                    <View style={{ paddingVertical: 2 }}>
                        {selectedDay && (
                            <ScheduleDay
                                key={`week_${selectedDay.value}_day_${selectedDay.value}`}
                                dayData={selectedDay}
                                onSchedulePeriodAdded={handleSchedulePeriodAdded}
                                onSchedulePeriodDeleted={handleSchedulePeriodDeleted}
                                onSchedulePeriodUpdated={handleSchedulePeriodUpdated}
                            />
                        )}
                    </View>
                    <View style={scheduleDayPageStyles.AddNewPeriodButton}>
                        {addClicked ? (
                            <View>
                                <SchedulePeriod
                                    key="newPeriod"
                                    periodData={newPeriod}
                                    onPeriodChanged={handleNewPeriodChanged}
                                    onPeriodDelete={handleSchedulePeriodDeleted}
                                />
                                <View style={{ display: 'flex', flexDirection: 'row' }}>
                                    <View style={scheduleDayPageStyles.ViewActionButtons}>
                                        <TouchableOpacity
                                            onPress={() => {
                                                setAddClicked(false);
                                            }}
                                            style={[{ marginRight: 5 }]}
                                        >
                                            <Icon icon="pickListCancelled" />
                                        </TouchableOpacity>

                                        <TouchableOpacity
                                            onPress={handleNewPeriodSaved}
                                            style={[{ marginLeft: 5 }]}
                                        >
                                            <Icon icon="pickListCompleted" />
                                        </TouchableOpacity>
                                    </View>
                                </View>
                            </View>
                        ) : null}
                    </View>
                </View>
            </Card>
        </View>
    );
};

const scheduleWeekPageStyles = StyleSheet.create({
    ViewParent: {
        display: 'flex',
        flexDirection: 'column',
    },

    ViewChild: {
        display: 'flex',
        flexDirection: 'row',
    },
    ViewListItemContainer: {
        flexDirection: 'row',
        paddingVertical: 4,
        paddingRight: 4,
    },
    ViewListItemBody: {
        flex: 1,
        flexDirection: 'column',
    },
    ViewListItemActions: {
        padding: 5,
        flexDirection: 'row',
        justifyContent: 'center',
        flex: 1,
        display: 'flex',
    },
});

type ScheduleDayProps = {
    onSchedulePeriodAdded: (data: ScheduleTemplateWindowType) => void;
    onSchedulePeriodDeleted: (id: string) => void;
    onSchedulePeriodUpdated: (data: ScheduleTemplateWindowType) => void;
    dayData: { day: string; value: number; periods: ScheduleTemplateWindowType[] };
};

export const ScheduleDay: React.FC<ScheduleDayProps> = ({
    onSchedulePeriodUpdated,
    dayData,
    onSchedulePeriodDeleted,
}) => {
    const handlePeriodChanged = (data: ScheduleTemplateWindowType): void => {
        onSchedulePeriodUpdated(data);
    };
    const handleSchedulePeriodDeleted = (id: string): void => {
        onSchedulePeriodDeleted(id);
    };

    const periods = [...dayData.periods].sort((period1, period2) => {
        return period1.startTime.localeCompare(period2.startTime);
    });

    return (
        <View style={scheduleDayPageStyles.ViewParent} testID="data-ScheduleWeek">
            <View style={scheduleDayPageStyles.ViewChild}>
                {dayData && (
                    <View style={scheduleDayPageStyles.ViewContent}>
                        <FlatList
                            data={periods}
                            renderItem={({ item }) => (
                                <SchedulePeriod
                                    key={item.id}
                                    periodData={item}
                                    onPeriodChanged={handlePeriodChanged}
                                    onPeriodDelete={handleSchedulePeriodDeleted}
                                />
                            )}
                            keyExtractor={(item) => item.id}
                        />
                    </View>
                )}
            </View>
        </View>
    );
};

const scheduleDayPageStyles = StyleSheet.create({
    ViewParent: {
        display: 'flex',
        flexDirection: 'column',
    },
    ViewChild: {
        display: 'flex',
        flexDirection: 'column',
    },
    ViewContent: {
        paddingVertical: 5,
    },
    AddNewPeriodButton: {},
    ViewActionButtons: {
        marginVertical: 5,
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
});

type SchedulePeriodProps = {
    onPeriodChanged: (data: ScheduleTemplateWindowType) => void;
    periodData: ScheduleTemplateWindowType;
    onPeriodDelete: (id: string) => void;
};

export const SchedulePeriod: React.FC<SchedulePeriodProps> = ({
    periodData,
    onPeriodChanged,
    onPeriodDelete,
}) => {
    const [period, setPeriod] = useState({ ...periodData });
    const [editMode, setEditMode] = useState(false);
    const intl = useIntl();

    const handleCancelClick = useCallback(async () => {
        setEditMode(false);
        setPeriod({ ...periodData });
    }, [periodData]);

    const handleEditClick = useCallback(async () => {
        setEditMode(true);
    }, []);

    const handleScheduleTemplateWindowChanged = (data: ScheduleTemplateWindowType): void => {
        onPeriodChanged(data);
        setEditMode(false);
    };

    const handleSchedulePeriodDeleted = (id: string): void => {
        onPeriodDelete(id);
    };

    return (
        <View style={scheduleDayPageStyles.ViewContent}>
            <Card
                style={{ flex: 1 }}
                disabled
                footer={() => <View style={scheduleWeekPageStyles.ViewListItemActions}></View>}
            >

                <View style={SchedulePeriodPageStyles.ViewDescription}>
                    <View>
                        <FormattedMessage
                            id="scheduleDetail.startTime"
                            defaultMessage="StartTime"
                        />
                        <TextInput
                            type="text"
                            ariaLabel="StartTime"
                            value={period.startTime}
                            disabled={editMode ? false : true}
                            onChange={(value: any) => {
                                setPeriod({ ...period, startTime: value });
                            }}
                        />
                    </View>
                </View>
                <View style={SchedulePeriodPageStyles.ViewDescription}>
                    <View>
                        <FormattedMessage
                            id="scheduleDetail.endTime"
                            defaultMessage="EndTime"
                        />
                        <TextInput
                            type="text"
                            ariaLabel="EndTime"
                            value={period.endTime}
                            disabled={editMode ? false : true}
                            onChange={(value: any) => {
                                setPeriod({ ...period, endTime: value });
                            }}
                        />
                    </View>
                </View>
                {!editMode ? (
                    <View
                        style={{
                            marginVertical: 10,
                            marginHorizontal: 10,
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        <View style={{ marginRight: 10 }}>
                            <Button
                                title={intl.formatMessage({
                                    id: 'schedulePeriod.Edit',
                                    defaultMessage: 'Edit',
                                })}
                                size="tiny"
                                onPress={handleEditClick}
                            />
                        </View>
                        <View >
                            <Button
                                title={intl.formatMessage({
                                    id: 'schedulePeriod.Delete',
                                    defaultMessage: 'Delete',
                                })}
                                size="tiny"
                                onPress={() => handleSchedulePeriodDeleted(period.id)}
                            />
                        </View>
                    </View>
                ) : (
                    <View
                        style={{
                            marginVertical: 10,
                            marginHorizontal: 10,
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        <View style={{ marginRight: 10 }}>
                            <Button
                                title={intl.formatMessage({
                                    id: 'schedulePeriod.Cancel',
                                    defaultMessage: 'Cancel',
                                })}
                                size="tiny"
                                onPress={handleCancelClick}
                            />
                        </View>
                        <View >
                            <Button
                                title={intl.formatMessage({
                                    id: 'schedulePeriod.Save',
                                    defaultMessage: 'Save',
                                })}
                                size="tiny"
                                onPress={() => handleScheduleTemplateWindowChanged({
                                    id: period.id,
                                    startTime: period.startTime,
                                    endTime: period.endTime,
                                    week: period.week,
                                    day: period.day
                                })}
                            />
                        </View>
                    </View>
                )}
            </Card>
        </View>
    );
};

const SchedulePeriodPageStyles = StyleSheet.create({
    ViewParent: {
        display: 'flex',
        flexDirection: 'column',
    },
    ViewChild: {
        display: 'flex',
        flexDirection: 'column',
    },
    ViewDescription: {
        marginTop: 10,
        fontWeight: 'bold',
    },
});
