import { gql } from '@apollo/client';
import React, { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ScrollView, StyleSheet, Text, View, Image } from 'react-native';
import { Button, Checkbox, Message, Select, SelectItem, TextInput } from '@valet/ui-components';
import {
    useGetAdminStorageItemTypeByIdQuery,
    AdminStorageItemTypeDetailFragment,
    useAdminUpdateStorageItemTypeMutation,
    useStorageItemTypeDetailUploadImageMutation,
} from '../../../../schema-types';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
    useGetAdminStorageItemTypeCategoriesQuery,
    AdminStorageItemTypeCategoryFragment,
} from '../../../../schema-types';
import { UploadFile } from '../FileUpload/UploadFile';

const CATEGORY_FRAGMENT = gql`
    fragment adminStorageItemTypeCategory on StorageItemTypeCategory {
        id
        name
        parentId
    }
`;

export const RETRIEVE_AREA_QUERY = gql`
    query getAdminStorageItemTypeCategories {
        storageItemTypeCategory {
            ...adminStorageItemTypeCategory
        }
    }
    ${CATEGORY_FRAGMENT}
`;

const STORAGEITEMTYPE_FRAGMENT = gql`
    fragment adminStorageItemTypeDetail on StorageItemType {
        id
        description
        name
        image
        maximumRequestQuantity
        category {
            id
            name
            parentId
        }
        multiplePeople
    }
`;

export const RETRIEVE_BUILDING_QUERY = gql`
    query getAdminStorageItemTypeById($storageItemTypeId: String!) {
        storageItemTypeById(id: $storageItemTypeId) {
            ...adminStorageItemTypeDetail
        }
    }
    ${STORAGEITEMTYPE_FRAGMENT}
`;

export const ADMIN_UPDATE_BUILDING_MUTATION = gql`
    mutation adminUpdateStorageItemType($data: AdminStorageItemTypeInput!) {
        adminUpdateStorageItemType(data: $data) {
            data {
                ...adminStorageItemTypeDetail
            }
        }
    }
`;

export const UPLOAD_FILE_MUTATION = gql`
    mutation storageItemTypeDetailUploadImage($file: Upload!) {
        uploadStorageItemTypeImage(file: $file) {
            data {
                url
            }
        }
    }
`;

type StorageItemTypeDetailPageProps = {};

export const StorageItemTypeDetailPage: React.FC<StorageItemTypeDetailPageProps> = () => {
    const history = useHistory();
    const [selectIndex, setSelectIndex] = useState<number>(0);
    const intl = useIntl();
    const match = useRouteMatch();
    const params: { storageItemTypeId?: string } = match.params;
    const storageItemTypeId = params.storageItemTypeId;
    const [selectedFile, setSelectedFile] = useState(undefined);
    const [storageItemType, setStorageItemType] = useState<AdminStorageItemTypeDetailFragment>({
        id: '',
        description: '',
        name: '',
        image: '',
        maximumRequestQuantity: 0,
        category: {
            id: '',
            name: '',
            parentId: '',
        },
        multiplePeople: true,
    });

    const { data: retrievedCategoryData } = useGetAdminStorageItemTypeCategoriesQuery();

    const categoryData = retrievedCategoryData?.storageItemTypeCategory
        ? (retrievedCategoryData?.storageItemTypeCategory as AdminStorageItemTypeCategoryFragment[])
        : [];

    const {
        loading: retrievedStorageItemTypeDetailLoading,
        error: retrievedStorageItemTypeDetailError,
        refetch,
    } = useGetAdminStorageItemTypeByIdQuery({
        variables: {
            storageItemTypeId: storageItemTypeId || '',
        },
        onCompleted: (data: any) => {
            setSelectIndex(
                selectItems.findIndex(
                    (s) =>
                        s.id ===
                        (data.storageItemTypeById as AdminStorageItemTypeDetailFragment).category
                            .id,
                ),
            );
            setStorageItemType(data.storageItemTypeById as AdminStorageItemTypeDetailFragment);
        },
    });

    const selectItems: { id: string; name: string }[] = [];
    categoryData.map((item) =>
        selectItems.push({
            id: item.id,
            name: item.name,
        }),
    );
    const [
        adminUpdateStorageItemTypeMutation,
        {
            loading: adminUpdateStorageItemTypeDetailMutationLoading,
            error: adminUpdateStorageItemTypeDetailMutationError,
        },
    ] = useAdminUpdateStorageItemTypeMutation({
        onCompleted(data) {
            if (data) {
                refetch({
                    storageItemTypeId: storageItemTypeId || '',
                });
            }
        },
    });

    const [
        storageItemTypeDetailUploadImageMutation,
        {
            loading: storageItemTypeDetailUploadImageMutationLoading,
            error: storageItemTypeDetailUploadImageMutationError,
        },
    ] = useStorageItemTypeDetailUploadImageMutation();

    const handleBacklClick = useCallback(async () => {
        history.push(`/admin/storageItemTypes`);
    }, [history]);

    const handleSaveStorageItemTypeDetailClick = useCallback(async () => {
        if (storageItemType) {
            try {
                const result = await storageItemTypeDetailUploadImageMutation({
                    variables: {
                        file: selectedFile,
                    },
                });
                await adminUpdateStorageItemTypeMutation({
                    variables: {
                        data: {
                            id: storageItemType.id,
                            description: storageItemType.description,
                            name: storageItemType.name,
                            image:
                                result.data?.uploadStorageItemTypeImage?.data?.url ||
                                storageItemType.image,
                            maximumRequestQuantity: storageItemType.maximumRequestQuantity,
                            categoryId: storageItemType.category?.id,
                            multiplePeople: storageItemType.multiplePeople,
                        },
                    },
                });
            } catch (error) {}
            handleBacklClick();
        }
    }, [
        storageItemType,
        handleBacklClick,
        adminUpdateStorageItemTypeMutation,
        selectedFile,
        storageItemTypeDetailUploadImageMutation,
    ]);

    const handleOnFileUpload = useCallback(async (file) => {
        setSelectedFile(file);
    }, []);

    return (
        <ScrollView>
            {storageItemTypeDetailUploadImageMutationLoading && (
                <View testID="data-AddStorageItemTypeUploadingImage">
                    <Text>
                        <FormattedMessage
                            id="AddStorageItemType.uploadingImage"
                            defaultMessage="Uploading Image..."
                        />
                    </Text>
                </View>
            )}
            {storageItemTypeDetailUploadImageMutationError && (
                <Message
                    type="negative"
                    header={intl.formatMessage({
                        id: 'storageItemTypeDetail.storageItemTypeDetailUploadImageErrorMessage',
                        defaultMessage: 'Could not upload image - please try again',
                    })}
                    content={storageItemTypeDetailUploadImageMutationError.message}
                    testID="data-StorageItemTypeDetailErrorMessage"
                />
            )}
            {retrievedStorageItemTypeDetailLoading &&
                adminUpdateStorageItemTypeDetailMutationLoading && (
                    <View testID="data-storageItemTypeDetailItemLoading">
                        <Text>
                            <FormattedMessage
                                id="storageItemTypeDetail.loading"
                                defaultMessage="Loading..."
                            />
                        </Text>
                    </View>
                )}
            {adminUpdateStorageItemTypeDetailMutationError && (
                <Message
                    type="negative"
                    header={intl.formatMessage({
                        id: 'storageItemTypeDetail.storageItemTypeDetailUpdateErrorMessage',
                        defaultMessage:
                            'Could not update storage Item Type detail - please try again',
                    })}
                    content={adminUpdateStorageItemTypeDetailMutationError.message}
                    testID="data-storageItemTypeDetailUpdateErrorMessage"
                />
            )}
            {retrievedStorageItemTypeDetailError && (
                <Message
                    type="negative"
                    header={intl.formatMessage({
                        id: 'storageItemTypeDetail.storageItemTypeDetailErrorMessage',
                        defaultMessage:
                            'Could not load detail for the storage Item Type - please try again',
                    })}
                    content={retrievedStorageItemTypeDetailError.message}
                    testID="data-storageItemTypeDetailErrorMessage"
                />
            )}
            <>
                <View
                    style={storageItemTypePageStyles.ViewParent}
                    testID="data-storageItemTypeDetail"
                >
                    <View style={storageItemTypePageStyles.ViewChild}>
                        <View style={storageItemTypePageStyles.ViewContentParent}>
                            <View style={storageItemTypePageStyles.ViewTitleParent}>
                                <Text
                                    style={storageItemTypePageStyles.TextTitle}
                                    testID="data-storageItemType"
                                >
                                    {`${intl.formatMessage({
                                        id: 'storageItemTypeDetail.id',
                                        defaultMessage: 'Storage Item Type Details:',
                                    })}${''}`}
                                </Text>
                            </View>
                            <View style={storageItemTypePageStyles.ViewTitleParent}>
                                <View
                                    style={storageItemTypePageStyles.TextContainerItem}
                                    key={storageItemType.id}
                                    testID="data-disableStorageItemType"
                                >
                                    <FormattedMessage
                                        id="storageItemTypeDetail.multiplePeople"
                                        defaultMessage="Mltiple People:  "
                                    />
                                    <View style={{ marginLeft: 5 }}>
                                        <Checkbox
                                            onValueChange={(value: any) =>
                                                setStorageItemType({
                                                    ...storageItemType,
                                                    multiplePeople: value,
                                                })
                                            }
                                            checked={storageItemType?.multiplePeople || false}
                                            testID={'data-StorageItemTypeMultiplePeople'}
                                        />
                                    </View>
                                </View>
                                <View style={storageItemTypePageStyles.ViewDescription}>
                                    <FormattedMessage
                                        id="storageItemTypeDetail.Category"
                                        defaultMessage="Category"
                                    />
                                    <Select
                                        currentIndex={selectIndex}
                                        itemsDisplayValues={selectItems.map((items) => items.name)}
                                        onSelect={(index) => {
                                            setStorageItemType({
                                                ...storageItemType,
                                                category: {
                                                    id: selectItems[index].id,
                                                    name: selectItems[index].name,
                                                },
                                            });
                                        }}
                                    >
                                        {selectItems.map((item) => (
                                            <SelectItem title={item.name} key={item.id} />
                                        ))}
                                    </Select>
                                </View>
                                <View style={storageItemTypePageStyles.ViewDescription}>
                                    <View>
                                        <FormattedMessage
                                            id="storageItemTypeDetail.description"
                                            defaultMessage="Description: "
                                        />
                                        <TextInput
                                            type="text"
                                            ariaLabel="Description: "
                                            value={storageItemType?.description}
                                            onChange={(value: string) => {
                                                setStorageItemType({
                                                    ...storageItemType,
                                                    description: value,
                                                });
                                            }}
                                        />
                                    </View>
                                </View>
                                <View style={storageItemTypePageStyles.ViewDescription}>
                                    <FormattedMessage
                                        id="storageItemTypeDetail.name"
                                        defaultMessage="Name: "
                                    />
                                    <TextInput
                                        type="text"
                                        ariaLabel="Model"
                                        value={storageItemType?.name || ''}
                                        onChange={(value: string) => {
                                            setStorageItemType({ ...storageItemType, name: value });
                                        }}
                                    />
                                </View>
                                <View style={storageItemTypePageStyles.ViewDescription}>
                                    <FormattedMessage
                                        id="storageItemTypeDetail.maximumRequestQuantity"
                                        defaultMessage="Maximum Request Quantity: "
                                    />
                                    <TextInput
                                        type="text"
                                        ariaLabel="Maximum Request Quantity: "
                                        value={storageItemType.maximumRequestQuantity}
                                        onChange={(value: any) => {
                                            setStorageItemType({
                                                ...storageItemType,
                                                maximumRequestQuantity: value ? parseInt(value) : 0,
                                            });
                                        }}
                                    />
                                </View>
                            </View>
                            <View style={storageItemTypePageStyles.ViewDescription}>
                                <View style={storageItemTypePageStyles.ViewDescription}>
                                    <FormattedMessage
                                        id="storageItemTypeDetail.image"
                                        defaultMessage="Image: "
                                    />
                                    <UploadFile onFileUploaded={handleOnFileUpload} />
                                </View>
                                <View style={storageItemTypePageStyles.ViewImage}>
                                    {storageItemType.image ? (
                                        <Image
                                            style={{ height: 80, width: 120 }}
                                            source={{ uri: storageItemType?.image }}
                                        />
                                    ) : (
                                        <Text>
                                            <FormattedMessage
                                                id="storageItemType.image"
                                                defaultMessage="No image loaded."
                                            />
                                        </Text>
                                    )}
                                </View>
                            </View>
                        </View>
                    </View>
                    <View
                        style={{
                            marginVertical: 10,
                            marginHorizontal: 10,
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        <View style={{ flex: 1, marginRight: 10 }}>
                            <Button
                                title={intl.formatMessage({
                                    id: 'storageItemTypeDetail.Cancel',
                                    defaultMessage: 'Cancel',
                                })}
                                onPress={handleBacklClick}
                            />
                        </View>

                        <View style={{ flex: 1 }}>
                            <Button
                                title={intl.formatMessage({
                                    id: 'storageItemTypeDetail.Save',
                                    defaultMessage: 'Save',
                                })}
                                onPress={handleSaveStorageItemTypeDetailClick}
                            />
                        </View>
                    </View>
                </View>
            </>
        </ScrollView>
    );
};

const storageItemTypePageStyles = StyleSheet.create({
    ViewParent: {
        display: 'flex',
        flexDirection: 'column',
    },
    ViewTabs: {
        marginVertical: 5,
        display: 'flex',
        flexDirection: 'row',
    },
    ViewContent: {
        paddingVertical: 5,
    },
    TextTab: {
        flex: 1,
        color: '#000',
        backgroundColor: '#D4D4D5',
        fontSize: 18,
        textAlign: 'center',
        fontWeight: '700',
    },

    ViewChild: {
        display: 'flex',
        flexDirection: 'row',
    },
    ViewImageParent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    RouteImage: {
        height: 40,
        width: 40,
        resizeMode: 'stretch',
    },
    ViewContentParent: {
        paddingHorizontal: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    ViewTitleParent: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 10,
    },
    ViewTitleChild: {},
    TextTitle: {
        fontSize: 18,
        fontWeight: 'bold',
    },
    ViewDescription: {
        marginTop: 10,
        fontWeight: 'bold',
    },
    TextDescription: { fontSize: 14 },
    ViewStatus: {},
    TextStatus: {
        fontSize: 12,
        fontWeight: '700',
    },
    ViewDate: {},
    TextDate: {
        fontSize: 12,
    },
    ViewErrorMessageParent: {
        marginTop: 5,
        marginBottom: 5,
    },
    TextContainerItem: {
        marginVertical: 2,
        display: 'flex',
        flexDirection: 'row',
        fontWeight: 'bold',
    },
    ViewImage: {
        paddingHorizontal: 5,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
});
