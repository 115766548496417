import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { Icon } from '@valet/ui-components';
import { VisitsSummaryData } from './Routing';
import { FormattedMessage } from 'react-intl';

type VisitsSummaryIconsProps = VisitsSummaryData;

//TODO: Fragment

export const VisitsSummaryIcons: React.FC<VisitsSummaryIconsProps> = ({
    count,
    outGoing,
    inComing,
    multiplePeople,
}) => (
    <View style={visitsSummaryIconsStyles.ViewContainer}>
        <View style={visitsSummaryIconsStyles.ViewVisitsContainer}>
            <View style={visitsSummaryIconsStyles.ViewSummaryContainer}>
                <Text style={{ fontWeight: '700', fontSize: 16 }}>{count ?? 0}</Text>
                <Text style={{ fontSize: 16 }}>
                    {' '}
                    <FormattedMessage id="visitSummaryIcons.visits" defaultMessage="visits" />
                </Text>
            </View>
        </View>

        <View style={visitsSummaryIconsStyles.ViewDeliveryPickupContainer}>
            <View style={[visitsSummaryIconsStyles.ViewSummaryContainer, { flex: 1 }]}>
                <View style={visitsSummaryIconsStyles.ViewIcon}>
                    <Icon icon="delivery" size="tiny" color="color-primary-600" />
                </View>

                <View style={visitsSummaryIconsStyles.ViewText}>
                    <Text style={{ fontWeight: '700' }}>{outGoing ?? 0}</Text>
                    <Text>
                        {' '}
                        <FormattedMessage
                            id="visitSummaryIcons.deliveries"
                            defaultMessage="deliveries"
                        />
                    </Text>
                </View>
            </View>

            <View style={[visitsSummaryIconsStyles.ViewSummaryContainer, { flex: 1 }]}>
                <View style={visitsSummaryIconsStyles.ViewIcon}>
                    <Icon icon="pickup" size="tiny" color="color-primary-600" />
                </View>

                <View style={visitsSummaryIconsStyles.ViewText}>
                    <Text style={{ fontWeight: '700' }}>{inComing ?? 0}</Text>
                    <Text>
                        {' '}
                        <FormattedMessage id="visitSummaryIcons.pickups" defaultMessage="pickups" />
                    </Text>
                </View>
            </View>

            {multiplePeople && (
                <View style={[visitsSummaryIconsStyles.ViewSummaryContainer]}>
                    <Icon icon="multiplePeopleVisit" size="tiny" color="color-primary-600" />
                </View>
            )}
        </View>
    </View>
);

const visitsSummaryIconsStyles = StyleSheet.create({
    ViewContainer: { flexDirection: 'column', flexWrap: 'wrap' },
    ViewVisitsContainer: {},
    ViewDeliveryPickupContainer: {
        paddingTop: 3,
        display: 'flex',
        flexDirection: 'row',
    },
    ViewIcon: {
        paddingRight: 3,
    },
    ViewText: {
        paddingLeft: 3,
        display: 'flex',
        flexDirection: 'row',
    },
    ViewSummaryContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
});
