import { gql } from '@apollo/client';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FlatList, ScrollView, StyleSheet, Text, View } from 'react-native';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Button, Icon, Message } from '@valet/ui-components';

import { useDeliverVisitDetailsQuery, RequestStatus, RequestType } from '../../../schema-types';

export const RETRIEVE_VISIT_QUERY = gql`
    query DeliverVisitDetails($visitId: String!) {
        visitById(visitId: $visitId) {
            id
            status
            expectedDuration
            startTime
            endTime
            summary {
                inComing
                outGoing
                multiplePeople
                firstVisit
            }
            customerAddress {
                address1
                city
                countryId
                firstName
                lastName
            }
            scheduleWindow {
                startTime
                endTime
            }
            customer {
                email
                firstName
                lastName
                homePhone
                cellPhone
            }
            requests(filter: {}) {
                edges {
                    cursor
                    node {
                        id
                        status
                        requestType
                    }
                }
            }
            stop {
                sequence
            }
        }
    }
`;

type DeliveryVisitDetailProps = {
    onHandleGoBackPress: (routeId: string) => void;
};

type MenuItem = {
    key: string;
    label: string;
};

export const DeliveryVisitDetail: React.FC<DeliveryVisitDetailProps> = ({
    onHandleGoBackPress,
}) => {
    const history = useHistory();
    const match = useRouteMatch();
    const params: { visitId?: string; routeId?: string } = match.params;
    const visitId = params.visitId;
    const routeId = params.routeId;
    const intl = useIntl();
    const [menuItems, setMenuItems] = useState<MenuItem[]>([
        {
            key: 'item_pickup',
            label: intl.formatMessage({
                id: 'routeStopItem.pickupItems',
                defaultMessage: 'Pickup Items',
            }),
        },
        {
            key: 'item_delivery',
            label: intl.formatMessage({
                id: 'routeStopItem.deliveryItems',
                defaultMessage: 'Deliver Items',
            }),
        },
        {
            key: 'empty_container_delivery',
            label: intl.formatMessage({
                id: 'routeStopItem.deliveryContainers',
                defaultMessage: 'Deliver empty container',
            }),
        },
        {
            key: 'empty_container_pickup',
            label: intl.formatMessage({
                id: 'routeStopItem.pickupEmptyContainers',
                defaultMessage: 'Pickup container',
            }),
        },
        {
            key: 'complete',
            label: intl.formatMessage({
                id: 'routeStopItem.complete',
                defaultMessage: 'Complete',
            }),
        },
    ]);

    const handleGoBackPress = useCallback((): void => {
        onHandleGoBackPress(routeId || '');
    }, [onHandleGoBackPress, routeId]);

    useEffect(() => {
        if (!visitId) {
            handleGoBackPress();
        }
    }, [visitId, handleGoBackPress]);

    const {
        data: retrievedVisitData,
        loading: retrievedVisitLoading,
        error: retrievedVisitError,
    } = useDeliverVisitDetailsQuery({
        variables: {
            visitId: visitId || '',
        },
        onCompleted: (data) => {
            const requests = data.visitById?.requests.edges.map((r) => r.node);
            const pickupRequests = requests?.filter(
                (r) => r.requestType === RequestType.PickupStorageItem,
            );
            const itemDeliveryRequests = requests?.filter(
                (r) => r.requestType === RequestType.DeliverStorageItem,
            );

            const emptyContainerDeliveryRequests = requests?.filter(
                (r) => r.requestType === RequestType.DeliverEmptyContainer,
            );

            const pickupEmptyContainerRequests = requests?.filter(
                (r) => r.requestType === RequestType.PickupEmptyContainer,
            );

            const allPickups = pickupRequests?.length || 0;
            const cmpltdPickups =
                pickupRequests?.filter((r) => r.status === RequestStatus.Processed).length || 0;

            const allItemDeliveries = itemDeliveryRequests?.length || 0;
            const cmpltdItemDeliveries =
                itemDeliveryRequests?.filter((r) => r.status === RequestStatus.Processed).length ||
                0;

            const allContainerDeliveries = emptyContainerDeliveryRequests?.length || 0;
            const cmpltdContainerDeliveries =
                emptyContainerDeliveryRequests?.filter((r) => r.status === RequestStatus.Processed)
                    .length || 0;

            const allpickupEmptyContainers = pickupEmptyContainerRequests?.length || 0;
            const cmpltdpickupEmptyContainers =
                pickupEmptyContainerRequests?.filter((r) => r.status === RequestStatus.Processed)
                    .length || 0;

            setMenuItems([
                {
                    key: 'item_pickup',
                    label: `${intl.formatMessage({
                        id: 'routeStopItem.pickupItems',
                        defaultMessage: 'Pickup Items',
                    })}  ${cmpltdPickups}/${allPickups}`,
                },
                {
                    key: 'item_delivery',
                    label: `${intl.formatMessage({
                        id: 'routeStopItem.deliveryItems',
                        defaultMessage: 'Deliver Items',
                    })}  ${cmpltdItemDeliveries}/${allItemDeliveries}`,
                },
                {
                    key: 'empty_container_delivery',
                    label: `${intl.formatMessage({
                        id: 'routeStopItem.deliveryContainers',
                        defaultMessage: 'Deliver empty container',
                    })}  ${cmpltdContainerDeliveries}/${allContainerDeliveries}`,
                },
                {
                    key: 'empty_container_pickup',
                    label: `${intl.formatMessage({
                        id: 'routeStopItem.pickupEmptyContainers',
                        defaultMessage: 'Pickup empty container',
                    })}  ${cmpltdpickupEmptyContainers}/${allpickupEmptyContainers}`,
                },
                {
                    key: 'complete',
                    label: intl.formatMessage({
                        id: 'routeStopItem.complete',
                        defaultMessage: 'Complete',
                    }),
                },
            ]);
        },
    });

    const routeStop = retrievedVisitData?.visitById?.stop;

    const handleMenuItemClick = useCallback(
        (key: string) => {
            switch (key) {
                case 'item_pickup':
                    history.push(`/delivery/${routeId}/${visitId}/pickup`);
                    break;
                case 'item_delivery':
                    history.push(`/delivery/${routeId}/${visitId}/deliverItem`);
                    break;
                case 'empty_container_delivery':
                    history.push(`/delivery/${routeId}/${visitId}/deliverEmptyContainer`);
                    break;
                case 'empty_container_pickup':
                    history.push(`/delivery/${routeId}/${visitId}/pickupEmptyContainer`);
                    break;
                case 'complete':
                    history.push(`/delivery/${routeId}/${visitId}/complete`);
                    break;
                default:
                    break;
            }
        },
        [history, routeId, visitId],
    );

    useEffect(() => {
        if (!visitId) {
            handleGoBackPress();
        }
    }, [visitId, handleGoBackPress]);

    if (retrievedVisitError) {
        return (
            <View
                style={deliveryVisitDetailStyles.ViewErrorMessageParent}
                testID="data-errorMessage"
            >
                <Message
                    type="negative"
                    header={intl.formatMessage({
                        id: 'deliveryVisitDetail.deliveryPickupProcessErrorMessage',
                        defaultMessage: 'Could not load actions for the visit - please try again',
                    })}
                    content={retrievedVisitError.message}
                    testID="data-deliveryVisitDetailErrorMessage"
                />
            </View>
        );
    }

    if (retrievedVisitLoading) {
        return (
            <View testID="data-deliveryPickupItemLoading">
                <Text>
                    <FormattedMessage
                        id="deliveryPickupProcess.loading"
                        defaultMessage="Loading..."
                    />
                </Text>
            </View>
        );
    }

    return (
        <ScrollView>
            <View testID="data-deliveryRouteDetailDataView">
                <View>
                    <View style={deliveryVisitDetailStyles.ViewTopBar}>
                        <Button
                            accessoryLeft={() => <Icon icon="back" />}
                            title={intl.formatMessage({
                                id: 'deliveryRouteDetail.back',
                                defaultMessage: 'Back',
                            })}
                            onPress={handleGoBackPress}
                            appearance="ghost"
                        />
                    </View>
                    <View style={{ flex: 1 }}>
                        <View style={deliveryVisitDetailStyles.ViewDetailGroup}>
                            <Text
                                style={deliveryVisitDetailStyles.DeliveryHeader}
                                testID="data-routeStopDetailsDescription"
                            >
                                <FormattedMessage
                                    id="deliveryVisitDetail.delivery"
                                    defaultMessage="Delivery: "
                                />
                                {routeStop?.sequence}
                            </Text>
                        </View>
                    </View>
                    <View style={deliveryVisitDetailStyles.ViewContent}>
                        <FlatList
                            data={menuItems}
                            renderItem={({ item }) => (
                                <View style={deliveryVisitDetailStyles.ViewButton}>
                                    <View style={{ flex: 1 }}>
                                        <Button
                                            title={intl.formatMessage({
                                                id: item.key,
                                                defaultMessage: item.label,
                                            })}
                                            onPress={() => {
                                                handleMenuItemClick(item.key);
                                            }}
                                        />
                                    </View>
                                </View>
                            )}
                            keyExtractor={(item) => item.key}
                        />
                    </View>
                </View>
            </View>
        </ScrollView>
    );
};

const deliveryVisitDetailStyles = StyleSheet.create({
    ViewParent: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    ViewTopBar: {
        paddingBottom: 5,
        display: 'flex',
        flexDirection: 'row' as 'row',
        alignItems: 'center',
        borderBottomWidth: 1,
        borderBottomColor: '#d4d4d5',
    },
    ViewTabs: {
        marginVertical: 5,
        display: 'flex',
        flexDirection: 'row',
    },
    ViewContent: {},
    TextTab: {
        flex: 1,
        color: '#000',
        backgroundColor: '#D4D4D5',
        fontSize: 18,
        textAlign: 'center',
        fontWeight: '700',
    },
    ViewButton: {
        marginTop: 10,
        display: 'flex',
        flexDirection: 'row',
    },
    CancelAction: {
        borderWidth: 1,
        borderRadius: 5,
        padding: 5,
    },
    ViewDetailGroup: {
        marginVertical: 2,
        display: 'flex',
        flexDirection: 'row',
    },
    DeliveryHeader: {
        fontWeight: '700',
        flex: 1,
    },
    ViewErrorMessageParent: {
        marginTop: 5,
        marginBottom: 5,
    },
});
