import { gql } from '@apollo/client';
import React, { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ScrollView, StyleSheet, Text, View } from 'react-native';
import { Button, ErrorMessage, Message, TextInput } from '@valet/ui-components';
import { useAdminAddEmployeeMutation, AdminAddEmployeeInput } from '../../../../schema-types';
import { useHistory } from 'react-router-dom';
import { AddressInput, NewAddressForm } from '../../../Organisms/NewAddress/NewAddressForm';
import { CountryCode, LocaleType } from '../../../Organisms/AddressTypings';
import { validateAddressInput } from '../../../Organisms/NewAddress/ValidateAddressInput';

export const ADMIN_ADD_EMLOYEE_MUTATION = gql`
    mutation adminAddEmployee($data: AdminAddEmployeeInput!) {
        adminAddEmployee(data: $data) {
            data {
                id
            }
        }
    }
`;

const defaultAddressInput: AddressInput = {
    id: '',
    firstName: '',
    lastName: '',
    address1: '',
    address2: '',
    city: '',
    zoneId: '',
    zip: '',
    countryId: 'CA',
    phone: '',
};

type AddEmployeePageProps = {
    onEmployeeAdded: () => void;
    locale: LocaleType;
    countryCode: CountryCode;
};

export const AddEmployeePage: React.FC<AddEmployeePageProps> = ({
    onEmployeeAdded,
    countryCode,
    locale, }) => {
    const history = useHistory();

    const intl = useIntl();
    const [errorMessage, setErrorMessage] = useState<ErrorMessage | undefined>(undefined);
    const [addressInput, setAddressInput] = useState<AddressInput>({
        ...defaultAddressInput,
        countryId: countryCode,
    });
    const [newEmployee, setNewEmployee] = useState<AdminAddEmployeeInput>({
        firstName: '',
        lastName: '',
        email: '',
        homePhone: '',
        cellPhone: '',
        workPhone: '',
        startDate: null,
        endDate: null,
        address1: '',
        address2: '',
        city: '',
        zoneId: '',
        countryId: 'CA',
        zip: '',
    });

    const [
        adminAddEmployeeMutation,
        {
            loading: adminAddEmployeeDetailMutationLoading,
            error: adminAddEmployeeDetailMutationError,
        },
    ] = useAdminAddEmployeeMutation({
        onCompleted(data) {
            if (data) {
                setNewEmployee({
                    firstName: '',
                    lastName: '',
                    email: '',
                    homePhone: '',
                    cellPhone: '',
                    workPhone: '',
                    startDate: null,
                    endDate: null,
                    address1: '',
                    address2: '',
                    city: '',
                    zoneId: '',
                    countryId: 'CA',
                    zip: '',
                });
                onEmployeeAdded();
            }
        },
    });

    const handleAddEmployeeClick = useCallback(async () => {
        if (newEmployee) {
            try {
                const invalidAddress = validateAddressInput(addressInput, intl);
                if (invalidAddress) {
                    return setErrorMessage(invalidAddress);
                } else {
                    setErrorMessage(undefined);
                    await adminAddEmployeeMutation({
                        variables: {
                            data: {
                                ...newEmployee,
                            },
                        },
                    });
                }
            } catch (ignore) { }

        }
    }, [adminAddEmployeeMutation, newEmployee, addressInput, setErrorMessage, intl]);

    const handleBacklClick = useCallback(async () => {
        history.push(`/admin/employee`);
    }, [history]);


    const handleAddressChanged = useCallback(
        async (changedAddressInput: AddressInput): Promise<void> => {
            try {
                setErrorMessage(undefined);
                const {
                    address1,
                    address2,
                    city,
                    zoneId,
                    countryId,
                    zip
                } = changedAddressInput;
                setAddressInput(changedAddressInput);
                setNewEmployee({
                    ...newEmployee,
                    address1,
                    address2,
                    city,
                    zoneId,
                    countryId,
                    zip,

                });
                defaultAddressInput.countryId = countryId;
            } catch (ignore) { setErrorMessage(ignore) } //eslint-disable-line no-empty
        },
        [setAddressInput, newEmployee],
    );

    return (
        <ScrollView>
            <View style={employeePageStyles.ViewParent} testID="data-AddEmployee">
                <View style={employeePageStyles.ViewChild}>
                    <View style={employeePageStyles.ViewContentParent}>
                        <View style={employeePageStyles.ViewTitleParent}>
                            <Text style={employeePageStyles.TextTitle} testID="data-employee">
                                {`${intl.formatMessage({
                                    id: 'addEmployee.newEmployee',
                                    defaultMessage: 'New Employee Details:',
                                })}${''}`}
                            </Text>
                        </View>
                        {adminAddEmployeeDetailMutationError && (
                            <View style={employeePageStyles.ViewErrorMessageParent} testID="data-errorMessage">
                                <Message
                                    type="negative"
                                    header={intl.formatMessage({
                                        id: 'AddEmployee.addEmployeeErrorMessage',
                                        defaultMessage: 'Could not add employee - please try again',
                                    })}
                                    content={adminAddEmployeeDetailMutationError.message}
                                    testID="data-AddEmployeeErrorMessage"
                                />
                            </View>
                        )}
                        {adminAddEmployeeDetailMutationLoading && (
                            <View testID="data-AddEmployeeItemLoading">
                                <Text>
                                    <FormattedMessage id="AddEmployee.loading" defaultMessage="Loading..." />
                                </Text>
                            </View>
                        )}
                        {errorMessage && (
                            <Message
                                type={errorMessage.type}
                                header={errorMessage.header}
                                content={errorMessage.content}
                            />
                        )}
                        <View style={employeePageStyles.ViewTitleParent}>
                            <View style={employeePageStyles.ViewDescription}>
                                <View>
                                    <FormattedMessage
                                        id="adminAddEmployee.back"
                                        defaultMessage="First Name"
                                    />
                                    <TextInput
                                        type="text"
                                        ariaLabel="First Name"
                                        value={newEmployee.firstName}
                                        onChange={(value: string) => { setNewEmployee({ ...newEmployee, firstName: value }) }}
                                    />
                                </View>
                            </View>
                            <View style={employeePageStyles.ViewDescription}>
                                <View>
                                    <FormattedMessage
                                        id="adminAddEmployee.back"
                                        defaultMessage="Last Name"
                                    />
                                    <TextInput
                                        type="text"
                                        ariaLabel="Description"
                                        value={newEmployee.lastName}
                                        onChange={(value: string) => { setNewEmployee({ ...newEmployee, lastName: value }) }}
                                    />
                                </View>
                            </View>
                            <View style={employeePageStyles.ViewDescription}>
                                <FormattedMessage
                                    id="adminAddEmployee.email"
                                    defaultMessage="Email"
                                />
                                <TextInput
                                    type="text"
                                    ariaLabel="Email"
                                    value={newEmployee?.email || ''}
                                    onChange={(value: string) => { setNewEmployee({ ...newEmployee, email: value }) }}
                                />
                            </View>
                            <View style={employeePageStyles.ViewDescription}>
                                <FormattedMessage
                                    id="adminAddEmployee.homePhone"
                                    defaultMessage="Home Phone"
                                />
                                <TextInput
                                    type="text"
                                    ariaLabel="Home Phone"
                                    value={newEmployee?.homePhone || ''}
                                    onChange={(value: string) => { setNewEmployee({ ...newEmployee, homePhone: value }) }}
                                />
                            </View>
                            <View style={employeePageStyles.ViewDescription}>
                                <FormattedMessage
                                    id="adminAddEmployee.cellPhone"
                                    defaultMessage="Cell Phone"
                                />
                                <TextInput
                                    type="text"
                                    ariaLabel="Cell Phone"
                                    value={newEmployee?.cellPhone || ''}
                                    onChange={(value: string) => { setNewEmployee({ ...newEmployee, cellPhone: value }) }}
                                />
                            </View>
                            <View style={employeePageStyles.ViewDescription}>
                                <FormattedMessage
                                    id="adminAddEmployee.workPhone"
                                    defaultMessage="Work Phone"
                                />
                                <TextInput
                                    type="text"
                                    ariaLabel="Work Phone"
                                    value={newEmployee?.workPhone || ''}
                                    onChange={(value: string) => { setNewEmployee({ ...newEmployee, workPhone: value }) }}
                                />
                            </View>
                            <View style={employeePageStyles.ViewDescription}>
                                <FormattedMessage
                                    id="adminAddEmployee.AddressHeader"
                                    defaultMessage="Address"
                                />
                                <NewAddressForm
                                    locale={locale}
                                    countryCode={countryCode}
                                    currentAddress={defaultAddressInput}
                                    displayNames={false}
                                    onAddressChanged={handleAddressChanged}
                                />
                            </View>
                        </View>
                    </View>
                </View>
                <View
                    style={{
                        marginVertical: 10,
                        marginHorizontal: 10,
                        display: 'flex',
                        flexDirection: 'row',
                    }}
                >
                    <View style={{ flex: 1, marginRight: 10 }}>
                        <Button
                            title={intl.formatMessage({
                                id: 'employeeDetail.Cancel',
                                defaultMessage: 'Cancel',
                            })}
                            onPress={handleBacklClick}
                        />
                    </View>
                    <View style={{ flex: 1 }}>
                        <Button
                            title={intl.formatMessage({
                                id: 'employeeDetail.Add',
                                defaultMessage: 'Add',
                            })}
                            onPress={handleAddEmployeeClick}
                        />
                    </View>
                </View>
            </View>
        </ScrollView>
    );
};

const employeePageStyles = StyleSheet.create({
    ViewParent: {
        display: 'flex',
        flexDirection: 'column',
    },
    ViewTabs: {
        marginVertical: 5,
        display: 'flex',
        flexDirection: 'row',
    },
    ViewContent: {
        paddingVertical: 5,
    },
    TextTab: {
        flex: 1,
        color: '#000',
        backgroundColor: '#D4D4D5',
        fontSize: 18,
        textAlign: 'center',
        fontWeight: '700',
    },

    ViewChild: {
        display: 'flex',
        flexDirection: 'row',
    },
    ViewImageParent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    RouteImage: {
        height: 40,
        width: 40,
        resizeMode: 'stretch',
    },
    ViewContentParent: {
        paddingHorizontal: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    ViewTitleParent: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 5,
    },
    ViewTitleChild: {},
    TextTitle: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 10,
    },
    ViewDescription: {
        marginTop: 10,
        fontWeight: 'bold',

    },
    TextDescription: { fontSize: 14 },
    ViewStatus: {},
    TextStatus: {
        fontSize: 12,
        fontWeight: '700',
    },
    ViewDate: {},
    TextDate: {
        fontSize: 12,
    },
    ViewErrorMessageParent: {
        marginTop: 5,
        marginBottom: 5,
    },
});
