import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { BrowserRouter as Router, Switch, Route, useHistory } from 'react-router-dom';

import { BaseLayout, MenuItemData } from '../Layouts/BaseLayout';
import { Home } from '../Pages/Home/Home';
import { SortingPage } from './Sorting/SortingPage';
import PickListRoutes from './PickList/PickListRoutes';
import RoutingPage from '../Pages/Routing/Routing';
import { DeliveryRoutes } from './Delivery/DeliveryRoutes';
import { VisitReviewPage } from './VisitReview/VisitReviewPage';
import { TruckLoadingRoutes } from './TruckLoading/TruckLoadingRoutes';
import { TruckUnloadingRoutes } from './TruckUnloading/TruckUnloadingRoutes';
import { AdminRoutes } from './Admin/AdminRoutes';
import RefileListRoutes from './Refiling/RefileListRoutes';
import MovingRoutes from './Moving/MovingRoutes';

type BaseRoutesProps = {};

export const BaseRoutes: React.FC<BaseRoutesProps> = () => {
    const intl = useIntl();

    const menuItems: MenuItemData[] = [
        {
            key: 0,
            label: intl.formatMessage({ id: 'navigation.home', defaultMessage: 'Home' }),
            icon: 'menuHome',
            route: '/home',
            display: true,
        },
        {
            key: 1,
            label: intl.formatMessage({ id: 'navigation.picklist', defaultMessage: 'Pick List' }),
            icon: 'menuPicklist',
            route: '/picklist',
            display: true,
        },
        {
            key: 2,
            label: intl.formatMessage({
                id: 'navigation.visitReviewMenuOptions',
                defaultMessage: 'Visit Review',
            }),
            icon: 'menuVisitReview',
            route: '/visitreview',
            display: true,
        },
        {
            key: 3,
            label: intl.formatMessage({
                id: 'navigation.routingMenuOptions',
                defaultMessage: 'Routing',
            }),
            icon: 'menuRouting',
            route: '/routing',
            display: true,
        },
        {
            key: 4,
            label: intl.formatMessage({ id: 'navigation.sorting', defaultMessage: 'Sort Item' }),
            icon: 'menuSorting',
            route: '/sorting',
            display: true,
        },
        {
            key: 5,
            label: intl.formatMessage({ id: 'navigation.delivery', defaultMessage: 'Delivery' }),
            icon: 'menuDelivery',
            route: '/delivery',
            display: true,
        },
        {
            key: 6,
            label: intl.formatMessage({
                id: 'navigation.truckLoading',
                defaultMessage: 'Truck Loading',
            }),
            icon: 'menuTruckLoading',
            route: '/truckLoading',
            display: true,
        },
        {
            key: 7,
            label: intl.formatMessage({
                id: 'navigation.truckUnloading',
                defaultMessage: 'Truck Unloading',
            }),
            icon: 'menuTruckUnloading',
            route: '/truckUnloading',
        },
        {
            key: 8,
            label: intl.formatMessage({ id: 'navigation.admin', defaultMessage: 'Admin' }),
            icon: 'admin',
            route: '/admin',
            display: true,
        },
        {
            key: 9,
            label: intl.formatMessage({ id: 'navigation.refile', defaultMessage: 'Refile Item' }),
            icon: 'menuRefile',
            route: '/refile',
            display: true,
        },
        {
            key: 10,
            label: intl.formatMessage({ id: 'navigation.move', defaultMessage: 'Move Item' }),
            icon: 'menuRefile',
            route: '/moving',
            display: true,
        },
    ];

    const [page, setPage] = useState('');

    const handleUpdatePageState = (page: string): void => {
        setPage(page);
    };

    return (
        <Router>
            <PageHandler page={page} handleUpdatePageState={handleUpdatePageState} />
            <BaseLayout menuItemsData={menuItems}>
                <Switch>
                    <Route path="/picklist">
                        <PickListRoutes />
                    </Route>

                    <Route path="/sorting">
                        <SortingPage />
                    </Route>

                    <Route path="/visitreview">
                        <VisitReviewPage />
                    </Route>

                    <Route path="/routing">
                        <RoutingPage />
                    </Route>

                    <Route path="/delivery">
                        <DeliveryRoutes />
                    </Route>

                    <Route path="/truckLoading">
                        <TruckLoadingRoutes />
                    </Route>
                    <Route path="/truckUnloading">
                        <TruckUnloadingRoutes />
                    </Route>

                    <Route path="/admin">
                        <AdminRoutes />
                    </Route>

                    <Route path="/refile">
                        <RefileListRoutes />
                    </Route>

                    <Route path="/moving">
                        <MovingRoutes />
                    </Route>

                    <Route path="/">
                        <Home />
                    </Route>
                </Switch>
            </BaseLayout>
        </Router>
    );
};

type PageHandlerProps = {
    page: string;
    handleUpdatePageState: (page: string) => void;
};

export const PageHandler: React.FC<PageHandlerProps> = ({ page, handleUpdatePageState }) => {
    const history = useHistory();
    const firstUpdate = useRef(true);

    useLayoutEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        history.push(`/${page}`);
    }, [page, history]);

    useEffect(() => {
        const currentPage = history.location.pathname.substr(1);
        if (page !== currentPage) {
            handleUpdatePageState(currentPage);
        }
    }, [history.location, page, handleUpdatePageState]);

    return null;
};
