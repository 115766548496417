import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, useHistory } from 'react-router-dom';

import { View, StyleSheet } from 'react-native';

import { SignIn } from './SignIn';
import { ForgotPassword } from './ForgotPassword';
import { ResetPassword } from './ResetPassword';
import { SocialSignIn } from './SocialSignIn';
import { EmailExists } from './EmailExists';
import { SetNewPassword } from './SetNewPassword';

import { AuthErrorMessage } from './AuthTypes';
import {
    SignInFunction,
    ForgotPasswordFunction,
    ResetPasswordFunction,
    SetNewPasswordPasswordFunction,
} from './AuthFunctions';

type AuthRoutesProps = {};

export const AuthRoutes: React.FC<AuthRoutesProps> = () => {
    const [page, setPage] = useState('');

    const handleUpdatePageState = (page: string): void => {
        setPage(page);
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [user, setUser] = useState<any>(undefined);

    const handleRedirectSignInPage = (): void => setPage('signin');
    const handleRedirectForgotPasswordPage = (): void => setPage('forgotpassword');
    const handleRedirectResetPasswordPage = (): void => setPage('resetpassword');
    const handleRedirectSetNewPasswordPage = (): void => setPage('setnewpassword');
    const handleRedirectConfirmSignUpPage = (): void => setPage('confirmsignup');
    const handleRedirectEmailExists = (): void => setPage('emailexists');

    type HandleError = (err: AuthErrorMessage) => void;
    const handleError: HandleError = (err: AuthErrorMessage): void => {
        // // handle error here
        // console.log(err);
        return;
    };

    const handleSignInAttempt = async (email: string, password: string): Promise<void> => {
        try {
            return await SignInFunction(email, password);
        } catch (err) {
            switch (err.code) {
                case 'UserNotConfirmedException':
                    handleRedirectConfirmSignUpPage();
                    return;
                default:
                    handleError(err);
                    throw err;
            }
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleNewPasswordRequired = async (user: any): Promise<void> => {
        setUser(user);
        handleRedirectSetNewPasswordPage();
    };

    const handleForgotPasswordAttempt = async (email: string): Promise<void> => {
        try {
            await ForgotPasswordFunction(email);
            handleRedirectResetPasswordPage();
        } catch (err) {
            handleError(err);
            throw err;
        }
    };

    const handleResetPasswordAttempt = async (
        email: string,
        code: string,
        password: string,
    ): Promise<void> => {
        try {
            await ResetPasswordFunction(email, code, password);
            handleRedirectSignInPage();
        } catch (err) {
            handleError(err);
            throw err;
        }
    };

    const handleSetNewPasswordAttempt = async (
        user: any, // eslint-disable-line @typescript-eslint/no-explicit-any
        password: string,
        requiredAttributes: any, // eslint-disable-line @typescript-eslint/no-explicit-any
    ): Promise<void> => {
        try {
            await SetNewPasswordPasswordFunction(user, password, requiredAttributes);
        } catch (err) {
            handleError(err);
            throw err;
        }
    };

    return (
        <Router>
            <PageHandler page={page} handleUpdatePageState={handleUpdatePageState} />
            <AuthLayout handleRedirectSignIn={handleRedirectSignInPage}>
                <Switch>
                    <Route path="/signin" exact>
                        <SignIn
                            handleSignInAttempt={handleSignInAttempt}
                            handleNewPasswordRequired={handleNewPasswordRequired}
                            handleRedirectForgotPassword={handleRedirectForgotPasswordPage}
                        />
                    </Route>

                    <Route path="/forgotpassword" exact>
                        <ForgotPassword
                            handleRedirectSignIn={handleRedirectSignInPage}
                            handleForgotPasswordAttempt={handleForgotPasswordAttempt}
                        />
                    </Route>

                    <Route path="/resetpassword" exact>
                        <ResetPassword
                            handleRedirectSignIn={handleRedirectSignInPage}
                            handleResetPasswordAttempt={handleResetPasswordAttempt}
                        />
                    </Route>

                    <Route path="/setnewpassword" exact>
                        <SetNewPassword
                            user={user}
                            handleSetNewPasswordAttempt={handleSetNewPasswordAttempt}
                            handleRedirectSignIn={handleRedirectSignInPage}
                        />
                    </Route>

                    <Route path="/socialsignin" exact>
                        <SocialSignIn
                            handleRedirectEmailExists={handleRedirectEmailExists}
                            handleRedirectSignIn={handleRedirectSignInPage}
                        />
                    </Route>

                    <Route path="/emailexists" exact>
                        <EmailExists
                            handleRedirectSignIn={handleRedirectSignInPage}
                            handleRedirectForgotPassword={handleRedirectForgotPasswordPage}
                        />
                    </Route>

                    <Route path="/">
                        <SignIn
                            handleSignInAttempt={handleSignInAttempt}
                            handleNewPasswordRequired={handleNewPasswordRequired}
                            handleRedirectForgotPassword={handleRedirectForgotPasswordPage}
                        />
                    </Route>
                </Switch>
            </AuthLayout>
        </Router>
    );
};

type PageHandlerProps = {
    page: string;
    handleUpdatePageState: (page: string) => void;
};

export const PageHandler: React.FC<PageHandlerProps> = ({ page, handleUpdatePageState }) => {
    const history = useHistory();
    const firstUpdate = useRef(true);

    useLayoutEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        history.push(`/${page}`);
    }, [page, history]);

    useEffect(() => {
        const currentPage = history.location.pathname.substr(1);
        if (page !== currentPage) {
            handleUpdatePageState(currentPage);
        }
    }, [history.location, page, handleUpdatePageState]);

    return null;
};

type AuthLayoutProps = {
    handleRedirectSignIn: () => void;
};

export const AuthLayout: React.FC<AuthLayoutProps> = ({ children, handleRedirectSignIn }) => {
    return (
        <View style={authLayoutStyles.ViewParent}>
            <View style={authLayoutStyles.ViewChild}>{children}</View>
        </View>
    );
};

const authLayoutStyles = StyleSheet.create({
    ViewParent: {
        flex: 1,
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column' as 'column',
    },
    ViewChild: {
        height: '100%',
        width: '100%',
        flexGrow: 1,
    },
});
