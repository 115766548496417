import { gql } from '@apollo/client';
import React, { useCallback, useEffect, useMemo, useState, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { AuthContext } from '../Auth/AuthFunctions';
import { FlatList, Image, ScrollView, StyleSheet, Text, View } from 'react-native';
import { Card, TopTab, TopTabBar } from '@valet/ui-components';
import {
    useTruckLoadingGetRoutesQuery,
    RouteStatus,
    TruckLoadingRoutesRouteFragment,
} from '../../../schema-types';

export const routesPageSize = 100;

const TRUCKLOADING_ROUTES_ROUTE_FRAGMENT = gql`
    fragment truckLoadingRoutesRoute on Route {
        id
        description
        routeNumber
        status
        operationDate
        employees {
            id
            firstName
            lastName
        }
        vehicles {
            description
        }
    }
`;

export const RETRIEVE_ROUTES_QUERY = gql`
    query truckLoadingGetRoutes(
        $startDate: String!
        $endDate: String!
        $pageSize: Int!
        $cursor: String!
    ) {
        routes(
            filter: {
                conditions: [
                    { field: "operationDate", value: $startDate, operator: GreaterthanOrEqualto }
                    { field: "operationDate", value: $endDate, operator: Lesserthanorequalto }
                ]
                pageSize: $pageSize
                after: $cursor
            }
        ) {
            edges {
                cursor
                node {
                    id
                    ...truckLoadingRoutesRoute
                }
            }
            pageInfo {
                cursor {
                    beforeCursor
                    afterCursor
                }
                hasNextPage
                hasPreviousPage
            }
        }
    }

    ${TRUCKLOADING_ROUTES_ROUTE_FRAGMENT}
`;

type TruckLoadingRoutesPageProps = {
    onRouteSelect: (routeId: string) => void;
};

export const TruckLoadingRoutesPage: React.FC<TruckLoadingRoutesPageProps> = ({
    onRouteSelect,
}) => {
    const intl = useIntl();

    const { employee } = useContext(AuthContext);
    const [isFetching, setIsFetching] = useState<boolean>(false);

    const {
        data: retrievedRoutesData,
        loading: retrievedRoutesLoading,
        error: retrievedRoutesError,
        fetchMore: retrievedRoutesFetchMore,
    } = useTruckLoadingGetRoutesQuery({
        variables: {
            // startDate: new Date(new Date().setHours(0, 0, 0, 0)).toISOString(),
            // endDate: new Date(new Date().setHours(23, 59, 59, 999)).toISOString(),
            // For Test
            startDate: new Date(
                new Date().getFullYear(),
                new Date().getMonth() - 1,
                1,
            ).toISOString(),
            endDate: new Date(new Date().getFullYear(), new Date().getMonth() + 4, 1).toISOString(),

            pageSize: routesPageSize,
            cursor: '',
        },
    });

    const routeEdges = retrievedRoutesData?.routes.edges ?? [];
    const routesData = useMemo(() => {
        return {
            routes: routeEdges?.map((itemEdge) => itemEdge.node) ?? [],
            hasNextPage: retrievedRoutesData?.routes?.pageInfo?.hasNextPage ?? false,
            endCursor: retrievedRoutesData?.routes?.pageInfo?.cursor.afterCursor ?? '',
        };
    }, [routeEdges, retrievedRoutesData]);

    const handleLoadMoreItems = useCallback((): void => {
        if (routesData.hasNextPage && !isFetching) {
            setIsFetching(true);

            retrievedRoutesFetchMore({
                variables: {
                    pageSize: routesPageSize,
                    cursor: routesData.endCursor,
                },
            }).then(() => {
                setIsFetching(false);
            });
        }
    }, [isFetching, routesData, retrievedRoutesFetchMore]);

    useEffect(() => {
        if (!retrievedRoutesError && routesData.hasNextPage) {
            handleLoadMoreItems();
        }
    }, [handleLoadMoreItems, routesData, retrievedRoutesError]);

    const handleItemClick = useCallback(
        (id: string) => {
            console.log(id);
            onRouteSelect(id);
        },
        [onRouteSelect],
    );

    const [currentTab, setCurrentTab] = useState<number>(0);

    const deliveries = useMemo<{
        completed: TruckLoadingRoutesRouteFragment[];
        open: TruckLoadingRoutesRouteFragment[];
        assigned: TruckLoadingRoutesRouteFragment[];
    }>(() => {
        const completed = [];
        const open = [];
        const assigned = [];
        for (let i = 0; i < routesData.routes.length; i++) {
            const item = routesData.routes[i];
            if (item.status === RouteStatus.Completed) {
                completed.push(item);
            }

            if (item.status === RouteStatus.Created || item.status === RouteStatus.InProgress) {
                open.push(item);
            }
            if (
                item.employees &&
                item.employees.filter((emp) => emp.id === employee?.id).length > 0
            ) {
                assigned.push(item);
            }
        }

        return {
            completed,
            open,
            assigned,
        };
    }, [routesData.routes, employee]);

    return (
        <ScrollView>
            <View testID="data-truckLoadingRoutesDataView">
                <TopTabBar selectedIndex={currentTab} onSelect={(index) => setCurrentTab(index)}>
                    <TopTab
                        title={intl.formatMessage({
                            id: 'truckLoadingRoutes.openTabText',
                            defaultMessage: 'Open',
                        })}
                    />

                    <TopTab
                        title={intl.formatMessage({
                            id: 'truckLoadingRoutes.assignedTabText',
                            defaultMessage: 'Assigned',
                        })}
                    />

                    <TopTab
                        title={intl.formatMessage({
                            id: 'truckLoadingRoutes.completedTabText',
                            defaultMessage: 'Completed',
                        })}
                    />
                </TopTabBar>

                <View style={truckLoadingRoutesPageStyles.ViewParent} testID="data-delivery">
                    {!retrievedRoutesLoading &&
                        !retrievedRoutesData?.routes.pageInfo.hasNextPage &&
                        retrievedRoutesData && (
                            <View style={truckLoadingRoutesPageStyles.ViewContent}>
                                {currentTab === 0 ? (
                                    deliveries.open.length > 0 ? (
                                        <FlatList
                                            data={deliveries.open}
                                            renderItem={({ item }) => (
                                                <RouteItem
                                                    key={item.id}
                                                    route={item}
                                                    onItemClick={handleItemClick}
                                                />
                                            )}
                                            keyExtractor={(item) => item.id}
                                        />
                                    ) : (
                                        <Text>
                                            <FormattedMessage
                                                id="truckLoadingRoutes.noDeliveriesText"
                                                defaultMessage="There are no deliveries scheduled for today."
                                            />
                                        </Text>
                                    )
                                ) : currentTab === 2 ? (
                                    deliveries.completed.length > 0 ? (
                                        <FlatList
                                            data={deliveries.completed}
                                            renderItem={({ item }) => (
                                                <RouteItem
                                                    key={item.id}
                                                    route={item}
                                                    onItemClick={handleItemClick}
                                                />
                                            )}
                                            keyExtractor={(item) => item.id}
                                        />
                                    ) : (
                                        <Text>
                                            <FormattedMessage
                                                id="truckLoadingRoutes.noCompletedDeliveriesText"
                                                defaultMessage="no completed deliveries."
                                            />
                                        </Text>
                                    )
                                ) : currentTab === 1 ? (
                                    deliveries.assigned.length > 0 ? (
                                        <FlatList
                                            data={deliveries.assigned}
                                            renderItem={({ item }) => (
                                                <RouteItem
                                                    key={item.id}
                                                    route={item}
                                                    onItemClick={handleItemClick}
                                                />
                                            )}
                                            keyExtractor={(item) => item.id}
                                        />
                                    ) : (
                                        <Text>
                                            <FormattedMessage
                                                id="truckLoadingRoutes.noAssignedText"
                                                defaultMessage="no deliveries assigned to you."
                                            />
                                        </Text>
                                    )
                                ) : (
                                    <Text>
                                        <FormattedMessage
                                            id="truckLoadingRoutes.noDeliveriesText"
                                            defaultMessage="There are no deliveries scheduled for today."
                                        />
                                    </Text>
                                )}
                            </View>
                        )}
                </View>
            </View>
        </ScrollView>
    );
};

const truckLoadingRoutesPageStyles = StyleSheet.create({
    ViewParent: {
        display: 'flex',
        flexDirection: 'column',
    },
    ViewTabs: {
        marginVertical: 5,
        display: 'flex',
        flexDirection: 'row',
    },
    ViewContent: {
        paddingVertical: 5,
    },
    TextTab: {
        flex: 1,
        color: '#000',
        backgroundColor: '#D4D4D5',
        fontSize: 18,
        textAlign: 'center',
        fontWeight: '700',
    },
});

type RouteItemProps = {
    route: TruckLoadingRoutesRouteFragment;
    onItemClick: (id: string) => void;
};

const RouteItem: React.FC<RouteItemProps> = ({ route, onItemClick }) => {
    const { routeNumber, operationDate, status, employees, vehicles } = route;
    const intl = useIntl();

    return (
        <Card
            style={routeItemStyles.ViewParent}
            onPress={() => onItemClick(route.id)}
            testID="data-deliveryDetail"
            footer={(props) => (
                <View style={[props?.style]}>
                    <View style={routeItemStyles.ViewStatus}>
                        <Text style={routeItemStyles.TextStatus}>{status}</Text>
                    </View>

                    <View style={routeItemStyles.ViewDate}>
                        <Text style={routeItemStyles.TextDate} testID="data-routeItemLocation">
                            {new Date(operationDate).toLocaleString()}
                        </Text>
                    </View>
                </View>
            )}
        >
            <View style={routeItemStyles.ViewChild}>
                <View style={routeItemStyles.ViewImageParent}>
                    <Image
                        style={routeItemStyles.RouteImage}
                        source={require(`./MockImages/map_route.png`)}
                        testID="data-routeItemImage"
                    />
                </View>

                <View style={routeItemStyles.ViewContentParent}>
                    <View style={routeItemStyles.ViewTitleParent}>
                        <Text style={routeItemStyles.TextTitle} testID="data-routeItemDescription">
                            {`${intl.formatMessage({
                                id: 'routeItem.RouteNumber',
                                defaultMessage: 'Route #',
                            })}${routeNumber}`}
                        </Text>
                    </View>

                    <View style={routeItemStyles.ViewDescription}>
                        {employees &&
                            employees.map((emp) => (
                                <Text
                                    style={routeItemStyles.TextDescription}
                                    key={emp.id}
                                >{`${emp.lastName}, ${emp.firstName}`}</Text>
                            ))}

                        {vehicles &&
                            vehicles.map((vhcl, index) => (
                                <View key={index}>
                                    <Text
                                        style={routeItemStyles.TextDescription}
                                    >{`${vhcl.description}`}</Text>
                                </View>
                            ))}
                    </View>
                </View>
            </View>
        </Card>
    );
};

const routeItemStyles = StyleSheet.create({
    ViewParent: {
        marginVertical: 3,
    },
    ViewChild: {
        display: 'flex',
        flexDirection: 'row',
    },
    ViewImageParent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    RouteImage: {
        height: 40,
        width: 40,
        resizeMode: 'stretch',
    },
    ViewContentParent: {
        paddingHorizontal: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    ViewTitleParent: {
        display: 'flex',
        flexDirection: 'column',
    },
    ViewTitleChild: {},
    TextTitle: {
        fontSize: 18,
    },
    ViewDescription: {},
    TextDescription: { fontSize: 14 },
    ViewStatus: {},
    TextStatus: {
        fontSize: 12,
        fontWeight: '700',
    },
    ViewDate: {},
    TextDate: {
        fontSize: 12,
    },
});
