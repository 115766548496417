import React from 'react';

import { ValetThemeProvider, ValetTheme } from '@valet/ui-components';

// Include the following properties to ensure the color contrasts are accessible:
// 'color-basic-600': '#576582',
// 'text-hint-color': '$color-basic-600',

const theme: ValetTheme = {
    'color-basic-600': '#576582',
    'text-hint-color': '$color-basic-600',
    'background-basic-color-2': '$color-basic-100',
    'background-basic-color-3': '$color-basic-200',
    'color-primary-100': '#D4CFF5',
    'color-primary-200': '#ABA3EB',
    'color-primary-300': '#736AC5',
    'color-primary-400': '#433B8C',
    'color-primary-500': '#130F40',
    'color-primary-600': '#0E0A37',
    'color-primary-700': '#0A072E',
    'color-primary-800': '#060425',
    'color-primary-900': '#04021E',
    'color-primary-transparent-100': 'rgba(19, 15, 64, 0.08)',
    'color-primary-transparent-200': 'rgba(19, 15, 64, 0.16)',
    'color-primary-transparent-300': 'rgba(19, 15, 64, 0.24)',
    'color-primary-transparent-400': 'rgba(19, 15, 64, 0.32)',
    'color-primary-transparent-500': 'rgba(19, 15, 64, 0.4)',
    'color-primary-transparent-600': 'rgba(19, 15, 64, 0.48)',
    'color-success-100': '#E0F9CF',
    'color-success-200': '#BCF3A1',
    'color-success-300': '#88DC6C',
    'color-success-400': '#58BA44',
    'color-success-500': '#208C16',
    'color-success-600': '#107810',
    'color-success-700': '#0B6412',
    'color-success-800': '#075112',
    'color-success-900': '#044313',
    'color-success-transparent-100': 'rgba(32, 140, 22, 0.08)',
    'color-success-transparent-200': 'rgba(32, 140, 22, 0.16)',
    'color-success-transparent-300': 'rgba(32, 140, 22, 0.24)',
    'color-success-transparent-400': 'rgba(32, 140, 22, 0.32)',
    'color-success-transparent-500': 'rgba(32, 140, 22, 0.4)',
    'color-success-transparent-600': 'rgba(32, 140, 22, 0.48)',
    'color-info-100': '#C7ECF9',
    'color-info-200': '#92D6F4',
    'color-info-300': '#59AFE0',
    'color-info-400': '#3085C1',
    'color-info-500': '#005199',
    'color-info-600': '#003E83',
    'color-info-700': '#002E6E',
    'color-info-800': '#002058',
    'color-info-900': '#001749',
    'color-info-transparent-100': 'rgba(0, 81, 153, 0.08)',
    'color-info-transparent-200': 'rgba(0, 81, 153, 0.16)',
    'color-info-transparent-300': 'rgba(0, 81, 153, 0.24)',
    'color-info-transparent-400': 'rgba(0, 81, 153, 0.32)',
    'color-info-transparent-500': 'rgba(0, 81, 153, 0.4)',
    'color-info-transparent-600': 'rgba(0, 81, 153, 0.48)',
    'color-warning-100': '#FCF1C9',
    'color-warning-200': '#F9E095',
    'color-warning-300': '#EDC45F',
    'color-warning-400': '#DBA637',
    'color-warning-500': '#C47D01',
    'color-warning-600': '#A86500',
    'color-warning-700': '#8D4F00',
    'color-warning-800': '#713B00',
    'color-warning-900': '#5E2D00',
    'color-warning-transparent-100': 'rgba(196, 125, 1, 0.08)',
    'color-warning-transparent-200': 'rgba(196, 125, 1, 0.16)',
    'color-warning-transparent-300': 'rgba(196, 125, 1, 0.24)',
    'color-warning-transparent-400': 'rgba(196, 125, 1, 0.32)',
    'color-warning-transparent-500': 'rgba(196, 125, 1, 0.4)',
    'color-warning-transparent-600': 'rgba(196, 125, 1, 0.48)',
    'color-danger-100': '#FACFCD',
    'color-danger-200': '#F59EA4',
    'color-danger-300': '#E1697D',
    'color-danger-400': '#C44264',
    'color-danger-500': '#9E1245',
    'color-danger-600': '#870D46',
    'color-danger-700': '#710943',
    'color-danger-800': '#5B053E',
    'color-danger-900': '#4B033A',
    'color-danger-transparent-100': 'rgba(158, 18, 69, 0.08)',
    'color-danger-transparent-200': 'rgba(158, 18, 69, 0.16)',
    'color-danger-transparent-300': 'rgba(158, 18, 69, 0.24)',
    'color-danger-transparent-400': 'rgba(158, 18, 69, 0.32)',
    'color-danger-transparent-500': 'rgba(158, 18, 69, 0.4)',
    'color-danger-transparent-600': 'rgba(158, 18, 69, 0.48)',
};

type ThemeProviderProps = {};

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
    return <ValetThemeProvider theme={theme}>{children}</ValetThemeProvider>;
};
