import { gql } from '@apollo/client';
import React, { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ScrollView, StyleSheet, Text, View } from 'react-native';
import { Button, Message, TextInput } from '@valet/ui-components';
import { useAdminAddVehicleMutation, AdminAddVehicleInput } from '../../../../schema-types';
import { useHistory } from 'react-router-dom';

export const ADMIN_ADD_VEHICLE_MUTATION = gql`
    mutation adminAddVehicle($data: AdminAddVehicleInput!) {
        adminAddVehicle(data: $data) {
            data {
                id
                created_at
                description
                model
                manufacture_year
                vin
                image
                isActive
            }
        }
    }
`;

type AddVehiclePageProps = {
    onVehicleAdded: () => void;
};

export const AddVehiclePage: React.FC<AddVehiclePageProps> = ({ onVehicleAdded }) => {
    const history = useHistory();
    const intl = useIntl();
    const [newVehicle, setNewVehicle] = useState<AdminAddVehicleInput>({
        description: '',
        model: '',
        manufacture_year: 0,
        vin: '',
        image: ''
    });

    const [
        adminAddVehicleMutation,
        {
            loading: adminAddVehicleDetailMutationLoading,
            error: adminAddVehicleDetailMutationError,
        },
    ] = useAdminAddVehicleMutation({
        onCompleted(data: any) {
            if (data) {
                onVehicleAdded();
            }
        },
    });

    const handleAddVehicleClick = useCallback(async () => {
        if (newVehicle.description || newVehicle.model || newVehicle.manufacture_year) {
            await adminAddVehicleMutation({
                variables: {
                    data: {
                        ...newVehicle,
                    },
                },
            });
        }
        else
            alert("Please Enter the Vehicle Information");

    }, [adminAddVehicleMutation, newVehicle]);

    const handleBacklClick = useCallback(async () => {
        history.push(`/admin/vehicle`);
    }, [history]);


    return (
        <ScrollView>
            <View style={newVehiclePageStyles.ViewParent} testID="data-AddVehicle">
                {adminAddVehicleDetailMutationLoading &&
                    (
                        <View testID="data-AddVehicleItemLoading">
                            <Text>
                                <FormattedMessage id="AddVehicle.loading" defaultMessage="Loading..." />
                            </Text>
                        </View>
                    )
                }
                {adminAddVehicleDetailMutationError &&
                    (
                        <View style={newVehiclePageStyles.ViewErrorMessageParent} testID="data-errorMessage">
                            <Message
                                type="negative"
                                header={intl.formatMessage({
                                    id: 'AddVehicle.addVehicleErrorMessage',
                                    defaultMessage: 'Could not add newVehicle - please try again',
                                })}
                                content={adminAddVehicleDetailMutationError.message}
                                testID="data-AddVehicleErrorMessage"
                            />
                        </View>
                    )
                }
                <View style={newVehiclePageStyles.ViewChild}>
                    <View style={newVehiclePageStyles.ViewContentParent}>
                        <View style={newVehiclePageStyles.ViewTitleParent}>
                            <Text style={newVehiclePageStyles.TextTitle} testID="data-newVehicle">
                                {`${intl.formatMessage({
                                    id: 'newVehicleDetail.id',
                                    defaultMessage: 'Add New Vehicle: ',
                                })}${''}`}
                            </Text>
                        </View>
                        <View style={newVehiclePageStyles.ViewTitleParent}>
                            <View style={newVehiclePageStyles.ViewDescription}>
                                <View>
                                    <FormattedMessage
                                        id="newVehicleDetail.description"
                                        defaultMessage="Description: "
                                    />
                                    <TextInput
                                        type="text"
                                        ariaLabel="Description: "
                                        value={newVehicle?.description}
                                        onChange={(value: string) => {
                                            setNewVehicle({ ...newVehicle, description: value });
                                        }}
                                    />
                                </View>
                            </View>
                            <View style={newVehiclePageStyles.ViewDescription}>
                                <FormattedMessage
                                    id="newVehicleDetail.model"
                                    defaultMessage="Model: "
                                />
                                <TextInput
                                    type="text"
                                    ariaLabel="Model"
                                    value={newVehicle?.model || ''}
                                    onChange={(value: string) => {
                                        setNewVehicle({ ...newVehicle, model: value });
                                    }}
                                />
                            </View>
                            <View style={newVehiclePageStyles.ViewDescription}>
                                <FormattedMessage
                                    id="newVehicleDetail.manufacture_year"
                                    defaultMessage="Manufacture Year: "
                                />
                                <TextInput
                                    type="text"
                                    ariaLabel="Manufacture Year: "
                                    value={newVehicle.manufacture_year}
                                    onChange={(value: any) => {
                                        setNewVehicle({ ...newVehicle, manufacture_year: value ? parseInt(value) : 0 });
                                    }}
                                />
                            </View>
                        </View>
                        <View style={newVehiclePageStyles.ViewDescription}>
                            <View>
                                <FormattedMessage
                                    id="newVehicleDetail.vin"
                                    defaultMessage="Vin: "
                                />
                                <TextInput
                                    type="text"
                                    ariaLabel="vin: "
                                    value={newVehicle?.vin || ''}
                                    onChange={(value: string) => {
                                        setNewVehicle({ ...newVehicle, vin: value });
                                    }}
                                />
                            </View>
                            <View style={newVehiclePageStyles.ViewDescription}>
                                <FormattedMessage
                                    id="newVehicleDetail.image"
                                    defaultMessage="Image: "
                                />
                                <TextInput
                                    type="text"
                                    ariaLabel="Image: "
                                    value={newVehicle?.image || ''}
                                    onChange={(value: string) => {
                                        setNewVehicle({ ...newVehicle, image: value });
                                    }}
                                />
                            </View>
                        </View>
                    </View>
                </View>
                <View
                    style={{
                        marginVertical: 10,
                        marginHorizontal: 10,
                        display: 'flex',
                        flexDirection: 'row',
                    }}
                >
                    <View style={{ flex: 1, marginRight: 10 }}>
                        <Button
                            title={intl.formatMessage({
                                id: 'newVehicleDetail.Cancel',
                                defaultMessage: 'Cancel',
                            })}
                            onPress={handleBacklClick}
                        />
                    </View>
                    <View style={{ flex: 1 }}>
                        <Button
                            title={intl.formatMessage({
                                id: 'newVehicleDetail.Add',
                                defaultMessage: 'Add',
                            })}
                            onPress={handleAddVehicleClick}
                        />
                    </View>
                </View>
            </View>
        </ScrollView>
    );
};

const newVehiclePageStyles = StyleSheet.create({
    ViewParent: {
        display: 'flex',
        flexDirection: 'column',
    },
    ViewTabs: {
        marginVertical: 5,
        display: 'flex',
        flexDirection: 'row',
    },
    ViewContent: {
        paddingVertical: 5,
    },
    TextTab: {
        flex: 1,
        color: '#000',
        backgroundColor: '#D4D4D5',
        fontSize: 18,
        textAlign: 'center',
        fontWeight: '700',
    },

    ViewChild: {
        display: 'flex',
        flexDirection: 'row',
    },
    ViewImageParent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    RouteImage: {
        height: 40,
        width: 40,
        resizeMode: 'stretch',
    },
    ViewContentParent: {
        paddingHorizontal: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    ViewTitleParent: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 5,
    },
    ViewTitleChild: {},
    TextTitle: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 10,
    },
    ViewDescription: {
        marginTop: 10,
        fontWeight: 'bold',
    },
    TextDescription: { fontSize: 14 },
    ViewStatus: {},
    TextStatus: {
        fontSize: 12,
        fontWeight: '700',
    },
    ViewDate: {},
    TextDate: {
        fontSize: 12,
    },
    ViewErrorMessageParent: {
        marginTop: 5,
        marginBottom: 5,
    },
    TextContainerItem: {
        marginVertical: 2,
        display: 'flex',
        flexDirection: 'row',
        fontWeight: 'bold',
    },
});
