import { gql } from '@apollo/client';
import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Card } from '@valet/ui-components';

import { FlatList, ScrollView, StyleSheet, Text, View } from 'react-native';
import { AdminTerritoryFragment, useGetAdminTerritorysQuery } from '../../../../schema-types';
import { useHistory } from 'react-router-dom';

export const territoryPageSize = 100;

const ADMIN_TERRITORY_FRAGMENT = gql`
    fragment adminTerritory on Territory {
        id
        description
        maximumRequestQuantity
        deliveryCutoff
        deliveryDelay
        pickupCutoff
        pickupDelay
    }
`;

export const RETRIEVE_TERRITORY_QUERY = gql`
    query getAdminTerritorys($pageSize: Int!, $cursor: String!) {
        territories(filter: { pageSize: $pageSize, after: $cursor }) {
            edges {
                cursor
                node {
                    id
                    ...adminTerritory
                }
            }
            pageInfo {
                cursor {
                    beforeCursor
                    afterCursor
                }
                hasNextPage
                hasPreviousPage
            }
        }
    }

    ${ADMIN_TERRITORY_FRAGMENT}
`;

type TerritoryPageProps = {
    onTerritorySelect: (id: string) => void;
    onAddTerritorySelect: () => void;
};

export const TerritoryPage: React.FC<TerritoryPageProps> = ({
    onTerritorySelect,
    onAddTerritorySelect,
}) => {
    const history = useHistory();
    const [isFetching, setIsFetching] = useState<boolean>(false);

    const {
        data: retrievedTerritorysData,
        loading: retrievedTerritorysLoading,
        error: retrievedTerritorysError,
        fetchMore: retrievedTerritoryFetchMore,
    } = useGetAdminTerritorysQuery({
        fetchPolicy: 'network-only',
        variables: {
            pageSize: territoryPageSize,
            cursor: '',
        },
    });

    const territoryEdges = retrievedTerritorysData?.territories.edges ?? [];
    const territoryData = useMemo(() => {
        return {
            territory: territoryEdges?.map((itemEdge) => itemEdge.node) ?? [],
            hasNextPage: retrievedTerritorysData?.territories?.pageInfo?.hasNextPage ?? false,
            endCursor: retrievedTerritorysData?.territories?.pageInfo?.cursor.afterCursor ?? '',
        };
    }, [territoryEdges, retrievedTerritorysData]);

    const handleLoadMoreItems = useCallback((): void => {
        if (territoryData.hasNextPage && !isFetching) {
            setIsFetching(true);

            retrievedTerritoryFetchMore({
                variables: {
                    pageSize: territoryPageSize,
                    cursor: territoryData.endCursor,
                },
            }).then(() => {
                setIsFetching(false);
            });
        }
    }, [isFetching, territoryData, retrievedTerritoryFetchMore]);

    useEffect(() => {
        if (!retrievedTerritorysError && territoryData.hasNextPage) {
            handleLoadMoreItems();
        }
    }, [handleLoadMoreItems, territoryData, retrievedTerritorysError]);

    const handleItemClick = useCallback(
        (id: string) => {
            onTerritorySelect(id);
        },
        [onTerritorySelect],
    );

    const handleAddClick = useCallback(() => {
        onAddTerritorySelect();
    }, [onAddTerritorySelect]);

    const handleBacklClick = useCallback(async () => {
        history.push(`/admin`);
    }, [history]);


    return (
        <ScrollView>
            <View style={territorysPageStyles.ViewParent} testID="data-territory">
                <View style={territorysPageStyles.ViewTabs}>
                    <View>
                        <View style={{ flex: 1, marginRight: 2 }}>
                            <Button
                                title="Back"
                                onPress={handleBacklClick}
                            />
                        </View>
                    </View>
                    <View>
                        <View style={{ flex: 1, marginRight: 10, marginBottom: 10 }}>
                            <Button title="+ Add New Territory" onPress={handleAddClick} />
                        </View>
                    </View>
                </View>
                {
                    retrievedTerritorysLoading && (
                        <View testID="data-territoryDetailItemLoading">
                            <Text>
                                <FormattedMessage id="territoryDetail.loading" defaultMessage="Loading..." />
                            </Text>
                        </View>
                    )
                }
                {
                    retrievedTerritorysError && (
                        <View testID="territoryDetail.territoryDetailErrorMessage">
                            <Text>
                                <FormattedMessage id="data-territoryDetailErrorMessage"
                                    defaultMessage="Could not load detail for the territory - please try again." />
                            </Text>
                        </View>
                    )
                }
                {!retrievedTerritorysLoading &&
                    !retrievedTerritorysData?.territories.pageInfo.hasNextPage &&
                    retrievedTerritorysData && (
                        <View style={territorysPageStyles.ViewContent}>
                            <FlatList
                                data={territoryData.territory}
                                renderItem={({ item }) => (
                                    <TerritoryItem
                                        key={item.id}
                                        territory={item}
                                        onItemClick={handleItemClick}
                                    />
                                )}
                                keyExtractor={(item) => item.id}
                            />
                        </View>
                    )}
            </View>
        </ScrollView>
    );
};

const territorysPageStyles = StyleSheet.create({
    ViewParent: {
        display: 'flex',
        flexDirection: 'column',
    },
    ViewTabs: {
        marginVertical: 5,
        display: 'flex',
        flexDirection: 'row',
    },
    ViewContent: {
        paddingVertical: 5,
    },
    TextTab: {
        flex: 1,
        color: '#000',
        backgroundColor: '#D4D4D5',
        fontSize: 18,
        textAlign: 'center',
        fontWeight: '700',
    },
    ViewErrorMessageParent: {
        marginTop: 5,
        marginBottom: 5,
    },
});

type TerritoryItemProps = {
    territory: AdminTerritoryFragment;
    onItemClick: (id: string) => void;
};

const TerritoryItem: React.FC<TerritoryItemProps> = ({ territory, onItemClick }) => {
    const intl = useIntl();

    return (
        <Card
            style={territoryItemStyles.ViewParent}
            onPress={() => onItemClick(territory?.id || '')}
            testID="data-deliveryDetail"
        >
            <View style={territoryItemStyles.ViewChild}>
                <View style={territoryItemStyles.ViewContentParent}>
                    <View style={territoryItemStyles.ViewTitleParent}>
                        <Text
                            style={territoryItemStyles.TextTitle}
                            testID="data-territoryItemDescription"
                        >
                            {`${intl.formatMessage({
                                id: 'territoryItem.Description',
                                defaultMessage: 'Description: ',
                            })}`}
                        </Text>
                        <Text>{territory?.description}</Text>
                    </View>
                    <View style={territoryItemStyles.ViewTitleParent}>
                        <Text
                            style={territoryItemStyles.TextTitle}
                            testID="data-territoryIteMmaximumRequestQuantity"
                        >
                            {`${intl.formatMessage({
                                id: 'territoryItem.maximumRequestQuantity',
                                defaultMessage: 'MaximumRequestQuantity: ',
                            })}`}
                        </Text>
                        <Text>{territory?.maximumRequestQuantity}</Text>
                    </View>
                </View>
            </View>
        </Card>
    );
};

const territoryItemStyles = StyleSheet.create({
    ViewParent: {
        marginVertical: 3,
    },
    ViewChild: {
        display: 'flex',
        flexDirection: 'row',
    },
    ViewImageParent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    TerritoryImage: {
        height: 40,
        width: 40,
        resizeMode: 'stretch',
    },
    ViewContentParent: {
        paddingHorizontal: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    ViewTitleParent: {
        display: 'flex',
        flexDirection: 'column',
    },
    ViewTitleChild: {},
    TextTitle: {
        fontSize: 18,
        fontWeight: 'bold',
    },
    ViewDescription: {},
    TextDescription: { fontSize: 14 },
    ViewStatus: {},
    TextStatus: {
        fontSize: 12,
        fontWeight: '700',
    },
    ViewDate: {},
    TextDate: {
        fontSize: 12,
    },
    ViewErrorMessageParent: {
        marginTop: 5,
        marginBottom: 5,
    },
});
