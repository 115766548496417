import React, { useRef } from 'react';
import L, { LatLngExpression } from 'leaflet';
import { Map, Marker, Tooltip, TileLayer, FeatureGroup } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import { MapIconTypes, getMapIcon } from './MapIcon';
import { RoutingMapProps } from './Map';
import { VisitData } from './Routing';

import { View } from 'react-native';

import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import { VisitInformation } from './VisitInformation';

//TODO: Fragment

export const RoutingMap: React.FC<RoutingMapProps> = ({
    visits = [],
    selectedVisits = [],
    hightlightedVisits = [],
    onVisitClicked = () => undefined,
    onAreaSelected = () => undefined,
}) => {
    const editFeatureGroupEl = useRef<FeatureGroup>(null);

    let mapBounds;

    const visitsToRenderWithLocation = visits.filter(
        (visit) =>
            (visit.customerAddress.latitude ?? undefined) !== undefined &&
            (visit.customerAddress.longitude ?? undefined) !== undefined,
    );

    if (visitsToRenderWithLocation.length) {
        const visitLatLngBounds: LatLngExpression[] = visitsToRenderWithLocation
            .map((visit) => ({
                lat: visit.customerAddress.latitude,
                lng: visit.customerAddress.longitude,
            }))
            .filter((visit) => visit !== undefined) as LatLngExpression[];

        mapBounds = L.latLngBounds(visitLatLngBounds);

        const locationPoints: { [key: string]: VisitData[] } = {};

        visitsToRenderWithLocation.forEach((visit) => {
            const locationKey = `${visit.customerAddress.longitude}_${visit.customerAddress.latitude}`;
            locationPoints[locationKey] = [...(locationPoints[locationKey] ?? []), visit];
        });
    }

    return (
        <Map bounds={mapBounds} zoom={2} center={[0, 0]} style={{ width: '100%', height: '100%' }}>
            <TileLayer
                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://a.tile.openstreetmap.org/{z}/{x}/{y}.png"
                opacity={0.7}
            />
            <FeatureGroup ref={editFeatureGroupEl}>
                <EditControl
                    position="topright"
                    onCreated={(e: L.LeafletEvent) => {
                        onAreaSelected(e.layer.toGeoJSON());
                        editFeatureGroupEl?.current?.leafletElement.removeLayer(e.layer);
                    }}
                    edit={{ remove: false, edit: false }}
                    draw={{
                        polyline: false,
                        polygon: {},
                        circle: false,
                        rectangle: true,
                        marker: false,
                        circlemarker: false,
                    }}
                />
            </FeatureGroup>
            {visitsToRenderWithLocation.map((visit) =>
                (visit.customerAddress.latitude ?? undefined) !== undefined &&
                (visit.customerAddress.longitude ?? undefined) !== undefined ? (
                    <Marker
                        key={visit.id}
                        position={[
                            visit.customerAddress.latitude ?? 0,
                            visit.customerAddress.longitude ?? 0,
                        ]}
                        opacity={
                            hightlightedVisits.length === 0 || hightlightedVisits.includes(visit.id)
                                ? 1
                                : 0.25
                        }
                        onclick={() => onVisitClicked(visit.id)}
                        icon={getMapIcon({
                            color: visit.groupColor ?? '#000000',
                            content: selectedVisits.includes(visit.id)
                                ? MapIconTypes.check
                                : visit.routeStop !== undefined
                                ? MapIconTypes.truck
                                : MapIconTypes.default,
                        })}
                    >
                        <Tooltip offset={[12, 6]} direction={'top'} opacity={100}>
                            <View style={{ width: 200 }}>
                                <VisitInformation visit={visit} routeStop={visit.routeStop} />
                            </View>
                        </Tooltip>
                    </Marker>
                ) : undefined,
            )}
        </Map>
    );
};
