import { gql } from '@apollo/client';
import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Card } from '@valet/ui-components';

import { FlatList, ScrollView, StyleSheet, Text, View } from 'react-native';
import { AdminStorageItemTypeFragment, useGetAdminStorageItemTypesQuery } from '../../../../schema-types';
import { useHistory } from 'react-router-dom';

export const storageItemTypePageSize = 100;

const ADMIN_STORAGEITEMTYPE_FRAGMENT = gql`
    fragment adminStorageItemType on StorageItemType {
        id
        description
        name
        image
        maximumRequestQuantity
        category {
            id
            name
            parentId
        }
        multiplePeople
    }
`;


export const RETRIEVE_STORAGEITEMTYPE_QUERY = gql`
    query getAdminStorageItemTypes($pageSize: Int!, $cursor: String!) {
        storageItemTypes(
            filter: {
                pageSize: $pageSize
                after: $cursor
            }
        ) {
            edges {
                cursor
                node {
                    id
                    ...adminStorageItemType
                }
            }
            pageInfo {
                cursor {
                    beforeCursor
                    afterCursor
                }
                hasNextPage
                hasPreviousPage
            }
        }
    }

    ${ADMIN_STORAGEITEMTYPE_FRAGMENT}
`;

type StorageItemTypePageProps = {
    onStorageItemTypeSelect: (id: string) => void;
    onAddStorageItemTypeSelect: () => void
};

export const StorageItemTypePage: React.FC<StorageItemTypePageProps> = ({ onStorageItemTypeSelect, onAddStorageItemTypeSelect }) => {
    const history = useHistory();
    const intl = useIntl();

    const [isFetching, setIsFetching] = useState<boolean>(false);

    const {
        data: retrievedStorageItemTypesData,
        loading: retrievedStorageItemTypesLoading,
        error: retrievedStorageItemTypesError,
        fetchMore: retrievedStorageItemTypeFetchMore,
    } = useGetAdminStorageItemTypesQuery({
        variables: {
            pageSize: storageItemTypePageSize,
            cursor: '',
        },
    });

    const storageItemTypeEdges = retrievedStorageItemTypesData?.storageItemTypes.edges ?? [];
    const storageItemTypeData = useMemo(() => {
        return {
            storageItemType: storageItemTypeEdges?.map((itemEdge: any) => itemEdge.node) ?? [],
            hasNextPage: retrievedStorageItemTypesData?.storageItemTypes?.pageInfo?.hasNextPage ?? false,
            endCursor: retrievedStorageItemTypesData?.storageItemTypes?.pageInfo?.cursor.afterCursor ?? '',
        };
    }, [storageItemTypeEdges, retrievedStorageItemTypesData]);

    const handleLoadMoreItems = useCallback((): void => {
        if (storageItemTypeData.hasNextPage && !isFetching) {
            setIsFetching(true);

            retrievedStorageItemTypeFetchMore({
                variables: {
                    pageSize: storageItemTypePageSize,
                    cursor: storageItemTypeData.endCursor,
                },
            }).then(() => {
                setIsFetching(false);
            });
        }
    }, [isFetching, storageItemTypeData, retrievedStorageItemTypeFetchMore]);

    useEffect(() => {
        if (!retrievedStorageItemTypesError && storageItemTypeData.hasNextPage) {
            handleLoadMoreItems();
        }
    }, [handleLoadMoreItems, storageItemTypeData, retrievedStorageItemTypesError]);


    const handleItemClick = useCallback(
        (id: string) => {
            onStorageItemTypeSelect(id);
        },
        [onStorageItemTypeSelect],
    );

    const handleAddClick = useCallback(
        () => {
            onAddStorageItemTypeSelect();
        },
        [onAddStorageItemTypeSelect],
    );

    const handleBacklClick = useCallback(async () => {
        history.push(`/admin`);
    }, [history]);

    if (retrievedStorageItemTypesError) {
        return (
            <View
                style={storageItemTypesPageStyles.ViewErrorMessageParent}
                testID="data-errorMessage"
            >

            </View>
        );
    }

    return (
        <ScrollView>
            <View style={storageItemTypesPageStyles.ViewParent} testID="data-storageItemTypes">
                <View style={storageItemTypesPageStyles.ViewTabs}>
                    <View>
                        <View style={{ flex: 1, marginRight: 2 }}>
                            <Button
                                title={intl.formatMessage({
                                    id: 'storageItemTypes.backButton',
                                    defaultMessage: 'Back',
                                })}
                                onPress={handleBacklClick}
                            />
                        </View>
                    </View>
                    <View>
                        <View style={{ flex: 1, marginRight: 20, marginBottom: 10 }}>
                            <Button
                                title={intl.formatMessage({
                                    id: 'storageItemTypes.addNewStorageItemType',
                                    defaultMessage: '+ Add New StorageItemType',
                                })}
                                onPress={handleAddClick}
                            />
                        </View>
                    </View>
                </View>
                {retrievedStorageItemTypesError &&
                    (
                        <Text>
                            <FormattedMessage
                                id="storageItemTypeDetail.storageItemTypeDetailErrorMessage"
                                defaultMessage="Could not load detail for the storageItemType - please try again"
                            />
                        </Text>
                    )
                }
                {!retrievedStorageItemTypesLoading &&
                    !retrievedStorageItemTypesData?.storageItemTypes.pageInfo.hasNextPage &&
                    retrievedStorageItemTypesData && (
                        <View style={storageItemTypesPageStyles.ViewContent}>
                            {storageItemTypeData.storageItemType.length > 0 ?
                                (
                                    <FlatList
                                        data={storageItemTypeData.storageItemType}
                                        renderItem={({ item }) => (
                                            <StorageItemTypeItem
                                                key={item.id}
                                                storageItemType={item}
                                                onItemClick={handleItemClick}
                                            />
                                        )}
                                        keyExtractor={(item) => item.id}
                                    />
                                ) : (
                                    <Text>
                                        <FormattedMessage
                                            id="storageItemTypeDetail.loading"
                                            defaultMessage="Loading..."
                                        />
                                    </Text>
                                )}
                        </View>
                    )}

            </View>
        </ScrollView>
    );
};


const storageItemTypesPageStyles = StyleSheet.create({
    ViewParent: {
        display: 'flex',
        flexDirection: 'column',
    },
    ViewTabs: {
        marginVertical: 5,
        display: 'flex',
        flexDirection: 'row',
    },
    ViewContent: {
        paddingVertical: 5,
    },
    TextTab: {
        flex: 1,
        color: '#000',
        backgroundColor: '#D4D4D5',
        fontSize: 18,
        textAlign: 'center',
        fontWeight: '700',
    },
    ViewErrorMessageParent: {
        marginTop: 5,
        marginBottom: 5,
    },
});

type StorageItemTypeItemProps = {
    storageItemType: AdminStorageItemTypeFragment;
    onItemClick: (id: string) => void;
};

const StorageItemTypeItem: React.FC<StorageItemTypeItemProps> = ({ storageItemType, onItemClick }) => {
    const intl = useIntl();

    return (
        <Card
            style={storageItemTypeItemStyles.ViewParent}
            onPress={() => onItemClick(storageItemType?.id || '')}
            testID="data-deliveryDetail"
        >
            <View style={storageItemTypeItemStyles.ViewChild}>
                <View style={storageItemTypeItemStyles.ViewContentParent}>
                    <View style={storageItemTypeItemStyles.ViewTitleParent}>
                        <Text style={storageItemTypeItemStyles.TextTitle} testID="data-storageItemTypeItemDescription">
                            {`${intl.formatMessage({
                                id: 'storageItemTypeItem.Description',
                                defaultMessage: 'Description: ',
                            })}`}
                        </Text>
                        <Text>{storageItemType?.description}</Text>
                    </View>
                    <View style={storageItemTypeItemStyles.ViewTitleParent}>
                        <Text style={storageItemTypeItemStyles.TextTitle} testID="data-storageItemTypeItemName">
                            {`${intl.formatMessage({
                                id: 'storageItemTypeItem.model',
                                defaultMessage: 'Name: ',
                            })}`}
                        </Text>
                        <Text>{storageItemType?.name}</Text>
                    </View>
                    <View style={storageItemTypeItemStyles.ViewTitleParent}>
                        <Text style={storageItemTypeItemStyles.TextTitle} testID="data-storageItemTypeItemCategoryName">
                            {`${intl.formatMessage({
                                id: 'storageItemTypeItem.categoryName',
                                defaultMessage: 'Category: ',
                            })}`}
                        </Text>
                        <Text>{storageItemType?.category?.name}</Text>
                    </View>
                </View>
            </View>
        </Card>
    );
};

const storageItemTypeItemStyles = StyleSheet.create({
    ViewParent: {
        marginVertical: 3,
    },
    ViewChild: {
        display: 'flex',
        flexDirection: 'row',
    },
    ViewImageParent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    StorageItemTypeImage: {
        height: 40,
        width: 40,
        resizeMode: 'stretch',
    },
    ViewContentParent: {
        paddingHorizontal: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    ViewTitleParent: {
        display: 'flex',
        flexDirection: 'column',
    },
    ViewTitleChild: {},
    TextTitle: {
        fontSize: 18,
        fontWeight: 'bold',
    },
    ViewDescription: {},
    TextDescription: { fontSize: 14 },
    ViewStatus: {},
    TextStatus: {
        fontSize: 12,
        fontWeight: '700',
    },
    ViewDate: {},
    TextDate: {
        fontSize: 12,
    },
    ViewErrorMessageParent: {
        marginTop: 5,
        marginBottom: 5,
    },
});

