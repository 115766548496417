import { gql } from '@apollo/client';
import React, { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ScrollView, StyleSheet, Text, View } from 'react-native';
import { Button, Message, Select, SelectItem, TextInput } from '@valet/ui-components';
import {
    useGetAdminLocationByIdQuery,
    AdminLocationDetailFragment,
    useAdminUpdateLocationMutation,
    useGetAdminLocationDetailBuildingsQuery,
    AdminLocationDetailBuildingFragment,
    LocationType,
} from '../../../../schema-types';
import { useHistory, useRouteMatch } from 'react-router-dom';

const BUILDING_FRAGMENT = gql`
    fragment adminLocationDetailBuilding on Building {
        id
        code
        description
    }
`;

export const RETRIEVE_BUILDING_QUERY = gql`
    query getAdminLocationDetailBuildings {
        buildingsAll {
            ...adminLocationDetailBuilding
        }
    }
    ${BUILDING_FRAGMENT}
`;

const LOCATION_FRAGMENT = gql`
    fragment adminLocationDetail on Location {
        id
        description
        barcode
        type
        building {
            id
            code
            description
        }
    }
`;

export const RETRIEVE_LOCATION_QUERY = gql`
    query getAdminLocationById($locationId: String!) {
        locationById(id: $locationId) {
            ...adminLocationDetail
        }
    }
    ${LOCATION_FRAGMENT}
`;

export const ADMIN_UPDATE_LOCATION_MUTATION = gql`
    mutation adminUpdateLocation($data: AdminUpdateLocationInput!) {
        adminUpdateLocation(data: $data) {
            data {
                ...adminLocationDetail
            }
        }
    }
`;

type LocationDetailPageProps = {};

export const LocationDetailPage: React.FC<LocationDetailPageProps> = () => {
    const history = useHistory();
    const intl = useIntl();

    const match = useRouteMatch();
    const params: { locationId?: string } = match.params;
    const locationId = params.locationId;
    const [location, setLocation] = useState<AdminLocationDetailFragment>({
        id: '',
        description: '',
        barcode: '',
        building: {
            id: '',
            code: '',
            description: '',
        },
        type: LocationType.Storage,
    });

    const { data: retrievedBuildingData } = useGetAdminLocationDetailBuildingsQuery();

    const buildingsData = retrievedBuildingData?.buildingsAll
        ? (retrievedBuildingData?.buildingsAll as AdminLocationDetailBuildingFragment[])
        : [];

    const selectItems: { id: string; title: string; description: string }[] = [];
    buildingsData.map((item) =>
        selectItems.push({
            id: item.id,
            title: item.code,
            description: item.description,
        }),
    );
    const [selectIndex] = useState<number>(0);

    const {
        loading: retrievedLocationDetailLoading,
        error: retrievedLocationDetailError,
        refetch,
    } = useGetAdminLocationByIdQuery({
        variables: {
            locationId: locationId || '',
        },
        onCompleted: (data: any) => {
            setLocation(data.locationById as AdminLocationDetailFragment);
        },
    });

    const [
        adminUpdateLocationMutation,
        {
            loading: adminUpdateLocationDetailMutationLoading,
            error: adminUpdateLocationDetailMutationError,
        },
    ] = useAdminUpdateLocationMutation({
        onCompleted(data) {
            if (data) {
                refetch({
                    locationId: locationId || '',
                });
            }
        },
    });

    const handleBacklClick = useCallback(async () => {
        history.push(`/admin/locations`);
    }, [history]);

    const handleSaveLocationDetailClick = useCallback(async () => {
        if (location) {
            try {
                await adminUpdateLocationMutation({
                    variables: {
                        data: {
                            id: location.id,
                            description: location.description,
                            barcode: location.barcode,
                            buildingId: location.building.id,
                            type: location.type || LocationType.Storage,
                        },
                    },
                });
            } catch (error) { }
            handleBacklClick();
        }
    }, [adminUpdateLocationMutation, location, handleBacklClick]);

    return (
        <ScrollView>
            {retrievedLocationDetailLoading || adminUpdateLocationDetailMutationLoading ?
                <View testID="data-locationDetailItemLoading">
                    <Text>
                        <FormattedMessage id="locationDetail.loading" defaultMessage="Loading..." />
                    </Text>
                </View>
                :
                adminUpdateLocationDetailMutationError ?
                    <View style={locationPageStyles.ViewErrorMessageParent} testID="data-errorMessage">
                        <Message
                            type="negative"
                            header={intl.formatMessage({
                                id: 'locationDetail.locationDetailUpdateErrorMessage',
                                defaultMessage: 'Could not update location detail - please try again',
                            })}
                            content={adminUpdateLocationDetailMutationError.message}
                            testID="data-locationDetailUpdateErrorMessage"
                        />
                    </View>
                    : retrievedLocationDetailError ?
                        <View style={locationPageStyles.ViewErrorMessageParent} testID="data-errorMessage">
                            <Message
                                type="negative"
                                header={intl.formatMessage({
                                    id: 'locationDetail.locationDetailErrorMessage',
                                    defaultMessage: 'Could not load detail for the location - please try again',
                                })}
                                content={retrievedLocationDetailError.message}
                                testID="data-locationDetailErrorMessage"
                            />
                        </View>
                        :
                        <View style={locationPageStyles.ViewParent} testID="data-locationDetail">
                            <View style={locationPageStyles.ViewChild}>
                                <View style={locationPageStyles.ViewContentParent}>
                                    <View style={locationPageStyles.ViewTitleParent}>
                                        <Text style={locationPageStyles.TextTitle} testID="data-location">
                                            {`${intl.formatMessage({
                                                id: 'locationDetail.id',
                                                defaultMessage: 'Location Details:',
                                            })}${''}`}
                                        </Text>
                                    </View>
                                    <View style={locationPageStyles.ViewTitleParent}>
                                        <View style={locationPageStyles.ViewDescription}>
                                            <View>
                                                <FormattedMessage
                                                    id="locationDetail.description"
                                                    defaultMessage="Description: "
                                                />
                                                <TextInput
                                                    type="text"
                                                    ariaLabel="Description: "
                                                    value={location?.description}
                                                    onChange={(value: string) => {
                                                        setLocation({ ...location, description: value });
                                                    }}
                                                />
                                            </View>
                                        </View>
                                        <View style={locationPageStyles.ViewDescription}>
                                            <FormattedMessage
                                                id="locationDetail.barcode"
                                                defaultMessage="Barcode: "
                                            />
                                            <TextInput
                                                type="text"
                                                ariaLabel="Barcode: "
                                                value={location.barcode}
                                                onChange={(value: string) => {
                                                    setLocation({ ...location, barcode: value });
                                                }}
                                            />
                                        </View>
                                        <View style={locationPageStyles.ViewDescription}>
                                            <FormattedMessage
                                                id="addLocation.Building"
                                                defaultMessage="Building"
                                            />
                                            <Select
                                                currentIndex={selectIndex}
                                                itemsDisplayValues={selectItems.map((items) => items.title)}
                                                onSelect={(index: any) =>
                                                    setLocation({
                                                        ...location,
                                                        building: {
                                                            id: selectItems[index].id,
                                                            code: selectItems[index].title,
                                                            description: selectItems[index].description,
                                                        },
                                                    })
                                                }
                                            >
                                                {selectItems.map((item) => (
                                                    <SelectItem title={item.title} key={item.id} />
                                                ))}
                                            </Select>
                                        </View>
                                    </View>
                                </View>
                            </View>
                            <View
                                style={{
                                    marginVertical: 10,
                                    marginHorizontal: 10,
                                    display: 'flex',
                                    flexDirection: 'row',
                                }}
                            >
                                <View style={{ flex: 1, marginRight: 10 }}>
                                    <Button
                                        title={intl.formatMessage({
                                            id: 'locationDetail.Cancel',
                                            defaultMessage: 'Cancel',
                                        })}
                                        onPress={handleBacklClick}
                                    />
                                </View>

                                <View style={{ flex: 1 }}>
                                    <Button
                                        title={intl.formatMessage({
                                            id: 'locationDetail.Save',
                                            defaultMessage: 'Save',
                                        })}
                                        onPress={handleSaveLocationDetailClick}
                                    />
                                </View>
                            </View>
                        </View>}
        </ScrollView>
    );
};

const locationPageStyles = StyleSheet.create({
    ViewParent: {
        display: 'flex',
        flexDirection: 'column',
    },
    ViewTabs: {
        marginVertical: 5,
        display: 'flex',
        flexDirection: 'row',
    },
    ViewContent: {
        paddingVertical: 5,
    },
    TextTab: {
        flex: 1,
        color: '#000',
        backgroundColor: '#D4D4D5',
        fontSize: 18,
        textAlign: 'center',
        fontWeight: '700',
    },

    ViewChild: {
        display: 'flex',
        flexDirection: 'row',
    },
    ViewImageParent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    RouteImage: {
        height: 40,
        width: 40,
        resizeMode: 'stretch',
    },
    ViewContentParent: {
        paddingHorizontal: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    ViewTitleParent: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 10,
    },
    ViewTitleChild: {},
    TextTitle: {
        fontSize: 18,
        fontWeight: 'bold',
    },
    ViewDescription: {
        marginTop: 10,
        fontWeight: 'bold',
    },
    TextDescription: { fontSize: 14 },
    ViewStatus: {},
    TextStatus: {
        fontSize: 12,
        fontWeight: '700',
    },
    ViewDate: {},
    TextDate: {
        fontSize: 12,
    },
    ViewErrorMessageParent: {
        marginTop: 5,
        marginBottom: 5,
    },
    TextContainerItem: {
        marginVertical: 2,
        display: 'flex',
        flexDirection: 'row',
        fontWeight: 'bold',
    },
    ViewImage: {
        paddingHorizontal: 5,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
});
