import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
    Button,
    Card,
    TextInput,
    Datepicker,
    Select,
    Modal,
    Message,
    SelectItem,
} from '@valet/ui-components';
import { View, Text } from 'react-native';
import { addDays, endOfDay, parseISO, startOfDay } from 'date-fns';
import { useIntl, FormattedMessage } from 'react-intl';
import { requestsForVisitPageSize } from './VisitReviewPageQueries';
import {
    useRetrieveRequestsByVisitIdQuery,
    useRetrieveScheduleWindowsQuery,
    RequestType,
    ScheduleWindow,
    CustomerAddress,
    Visit,
    VisitReviewVisitRequestPickupStorageItemRequestFragment,
    VisitReviewVisitRequestDeliverStorageItemRequestFragment,
    VisitReviewVisitRequestDeliverEmptyContainerRequestFragment,
    VisitReviewVisitRequestPickupEmptyContainerRequestFragment,
    VisitReviewVisitRequestDriverPickupEmptyContainerRequestFragment,
    VisitReviewVisitRequestScheduleWindowFragment,
} from '../../../schema-types';

type VisitReviewVisitRequestRequestFragment =
    | VisitReviewVisitRequestPickupStorageItemRequestFragment
    | VisitReviewVisitRequestDeliverStorageItemRequestFragment
    | VisitReviewVisitRequestDeliverEmptyContainerRequestFragment
    | VisitReviewVisitRequestPickupEmptyContainerRequestFragment
    | VisitReviewVisitRequestDriverPickupEmptyContainerRequestFragment;

type VisitRequestProps = {
    mobileView: boolean;
    visitRequest: Pick<Visit, 'id' | 'startTime' | 'status' | 'summary'> & {
        scheduleWindow: Pick<ScheduleWindow, 'id'>;
        customerAddress: Pick<
            CustomerAddress,
            | 'firstName'
            | 'lastName'
            | 'phone'
            | 'address1'
            | 'city'
            | 'zoneId'
            | 'countryId'
            | 'zip'
        >;
    };
    onScheduleWindowsForDateChange: (
        scheduleWindows: VisitReviewVisitRequestScheduleWindowFragment[] | undefined,
    ) => void;
    onScheduleWindowIdChange: (id: string) => void;
    onConfirmRequestPress: (visitDetails: {
        visitId: string;
        estimatedDuration: number;
        scheduleWindowId?: string;
        scheduleWindowDate?: Date;
    }) => void;
    approveRequestLoading: boolean;
    approveRequestError?: string;
};

export const VisitRequest: React.FC<VisitRequestProps> = ({
    mobileView,
    visitRequest,
    onScheduleWindowsForDateChange,
    onScheduleWindowIdChange,
    onConfirmRequestPress,
    approveRequestLoading,
    approveRequestError,
}) => {
    const intl = useIntl();

    const {
        id: visitId,
        startTime: visitStartTime,
        status: visitStatus,
        summary: {
            inComing: visitIncoming,
            outGoing: visitOutGoing,
            firstVisit: visitFirstVisit,
            multiplePeople: visitMultiplePeople,
        },
        scheduleWindow: { id: visitScheduleWindowId },
        customerAddress: { firstName, lastName, phone, address1, city, zoneId, countryId, zip },
    } = visitRequest;

    const [displayModal, setDisplayModal] = useState<boolean>(false);
    const [showItemsBreakdown, setShowItemsBreakdown] = useState<boolean>(false);
    const [selectCurrentIndex, setSelectCurrentIndex] = useState<number>(0);

    const defaultScheduleWindowId = visitScheduleWindowId;
    const defaultSelectedDate = new Date(visitStartTime);

    const [selectedScheduleWindowId, setSelectedScheduleWindowId] = useState<string>(
        visitScheduleWindowId,
    );
    const [selectedDate, setSelectedDate] = useState<Date>(defaultSelectedDate);
    const [estimatedDurationInput, setEstimatedDurationInput] = useState<number | undefined>(
        undefined,
    );

    const [requestsForVisit, setRequestsForVisit] = useState<
        | {
              incoming: VisitReviewVisitRequestRequestFragment[];
              outgoing: VisitReviewVisitRequestRequestFragment[];
          }
        | undefined
    >(undefined);
    const [scheduleWindowsForDate, setScheduleWindowsForDate] = useState<
        VisitReviewVisitRequestScheduleWindowFragment[] | undefined
    >(undefined);
    const [selectedDateChanged, setSelectedDateChanged] = useState<boolean>(false);

    // Retrieve data from backend
    const {
        data: requestsForVisitData,
        loading: requestsForVisitLoading,
        error: requestsForVisitDataError,
        fetchMore: requestsForVisitDataFetchMore,
        refetch: requestsForVisitDataRefetch,
    } = useRetrieveRequestsByVisitIdQuery({
        variables: {
            visitId: visitId,
            cursor: '',
            pageSize: requestsForVisitPageSize,
        },
    });
    const [isFetchingRequestsForVisit, setIsFetchingRequestsForVisit] = useState<boolean>(false);

    useEffect(() => {
        setSelectedScheduleWindowId(visitScheduleWindowId);
        setSelectedDate(new Date(visitStartTime));
        setEstimatedDurationInput(undefined);

        requestsForVisitDataRefetch({
            visitId: visitId,
            cursor: '',
            pageSize: requestsForVisitPageSize,
        }).then(() => {
            if (requestsForVisit !== undefined) {
                setRequestsForVisit(undefined);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestsForVisitDataRefetch, visitId, visitScheduleWindowId, visitStartTime]);

    // START OF REQUESTS FOR VISIT PAGINATION
    const requestsEdges = requestsForVisitData?.requestsByVisitId.edges ?? [];
    const requestsData = useMemo(() => {
        return {
            requests: requestsEdges.map((requestEdge) => requestEdge.node),
            hasNextPage: requestsForVisitData?.requestsByVisitId.pageInfo.hasNextPage ?? false,
            endCursor: requestsForVisitData?.requestsByVisitId.pageInfo.cursor.afterCursor ?? '',
        };
    }, [requestsForVisitData, requestsEdges]);

    const handleLoadMoreItemsRequests = useCallback((): void => {
        if (
            requestsData?.hasNextPage &&
            !isFetchingRequestsForVisit &&
            requestsForVisitDataFetchMore
        ) {
            setIsFetchingRequestsForVisit(true);

            requestsForVisitDataFetchMore({
                variables: {
                    pageSize: requestsForVisitPageSize,
                    cursor: requestsData.endCursor,
                },
            }).then(() => {
                setIsFetchingRequestsForVisit(false);
            });
        }
    }, [requestsData, isFetchingRequestsForVisit, requestsForVisitDataFetchMore]);

    useEffect(() => {
        if (!requestsForVisitDataError && requestsData?.hasNextPage) {
            handleLoadMoreItemsRequests();
        }
    }, [
        requestsForVisitData,
        requestsForVisitDataError,
        handleLoadMoreItemsRequests,
        requestsForVisitDataRefetch,
        requestsData,
        visitId,
    ]);

    useEffect(() => {
        if (
            !requestsForVisitLoading &&
            requestsForVisit === undefined &&
            !requestsData.hasNextPage
        ) {
            setRequestsForVisit({
                incoming: requestsData.requests.filter(
                    (request) =>
                        request.requestType === RequestType.PickupStorageItem ||
                        request.requestType === RequestType.PickupEmptyContainer,
                ),
                outgoing: requestsData.requests.filter(
                    (request) =>
                        request.requestType === RequestType.DeliverStorageItem ||
                        request.requestType === RequestType.DeliverEmptyContainer,
                ),
            });
        }
    }, [
        requestsData.requests,
        requestsData.hasNextPage,
        requestsForVisit,
        requestsForVisitLoading,
    ]);

    // END OF REQUESTS FOR VISITS PAGINATION

    const {
        data: scheduleWindowsData,
        refetch: scheduleWindowRefetch,
    } = useRetrieveScheduleWindowsQuery({
        variables: {
            zipCode: zip ?? 'UNDEFINED_ZIP_CODE',
            startDate: startOfDay(new Date(visitStartTime)).toISOString(),
            endDate: endOfDay(new Date(visitStartTime)).toISOString(),
        },
    });

    useEffect(() => {
        // The dropdown for selecting a schedule window displays 'Loading...' when scheduleWindowsForDate is set to undefined.
        // scheduleWindowsForDate is set to undefined to display the 'Loading...' text whenever the date is changed & the schedule windows for the new date is being retrieved.
        // The selectedDateChanged flag is checked here to ensure the default schedule windows are NOT used when refetching schedule windows for a new date.
        if (
            scheduleWindowsData &&
            scheduleWindowsForDate === undefined &&
            selectedDateChanged === false
        ) {
            const scheduleWindows = scheduleWindowsData?.territoryByZipCode?.schedule ?? [];

            setScheduleWindowsForDate(scheduleWindows);
            onScheduleWindowsForDateChange(scheduleWindows);
        }
    }, [
        onScheduleWindowsForDateChange,
        scheduleWindowsData,
        scheduleWindowsForDate,
        selectedDateChanged,
    ]);

    // Organize data retrieved from backend
    let scheduleWindowItems: Array<{ id: string; title: string }> = [];

    const overrideSelectDisplayValue: string | undefined =
        scheduleWindowsForDate === undefined
            ? intl.formatMessage({
                  id: 'visitReviewPage.loadingScheduleWindow',
                  defaultMessage: 'Loading...',
              })
            : scheduleWindowsForDate?.length === 0
            ? intl.formatMessage({
                  id: 'visitReviewPage.errorScheduleWindow',
                  defaultMessage: 'No schedule windows for this day',
              })
            : undefined;

    if (scheduleWindowsForDate?.length) {
        scheduleWindowItems = scheduleWindowsForDate.map((item) => {
            return {
                id: item.id,
                title: intl.formatMessage(
                    {
                        id: 'visitReviewPage.scheduleWindowItem',
                        defaultMessage: '{startTime} to {endTime}',
                    },
                    {
                        startTime: intl.formatTime(parseISO(item.startTime), {
                            hour: 'numeric',
                            minute: 'numeric',
                        }),
                        endTime: intl.formatTime(parseISO(item.endTime), {
                            hour: 'numeric',
                            minute: 'numeric',
                        }),
                    },
                ),
            };
        });
    }

    if (scheduleWindowsForDate) {
        let selectIndexToSet = 0;

        const visitScheduleWindowIdIndex = scheduleWindowsForDate.findIndex(
            (item) => item.id === visitScheduleWindowId,
        );

        // Set the dropdown value to the visit's schedule window if it exists for the day
        if (visitScheduleWindowIdIndex > -1) {
            selectIndexToSet = visitScheduleWindowIdIndex;
        }

        const scheduleWindowIndex = scheduleWindowsForDate.findIndex(
            (item) => item.id === selectedScheduleWindowId,
        );

        // Override the previous logic if a different schedule window has been selected
        if (scheduleWindowIndex > -1) {
            selectIndexToSet = scheduleWindowIndex;
        }

        if (selectCurrentIndex !== selectIndexToSet) {
            setSelectCurrentIndex(selectIndexToSet);
        }
    }

    if (scheduleWindowsForDate === undefined) {
        if (selectCurrentIndex !== 0) {
            setSelectCurrentIndex(0);
        }
    }

    // Events
    const onNextDayPress = (): void => {
        setSelectedDate(addDays(selectedDate, 1));
    };

    const onPrevDayPress = (): void => {
        setSelectedDate(addDays(selectedDate, -1));
    };

    const handleDateChange = (date: Date): void => {
        setSelectedDate(date);

        setScheduleWindowsForDate(undefined);
        setSelectedDateChanged(true);
        scheduleWindowRefetch({
            zipCode: zip ?? 'UNDEFINED_ZIP_CODE',
            startDate: startOfDay(new Date(date)).toISOString(),
            endDate: endOfDay(new Date(date)).toISOString(),
        }).then((res) => {
            if (res.data.territoryByZipCode) {
                const scheduleWindows = res.data.territoryByZipCode.schedule ?? [];

                setScheduleWindowsForDate(scheduleWindows);
                setSelectedDateChanged(false);
                onScheduleWindowsForDateChange(scheduleWindows);
            }
        });
    };

    const handleScheduleWindowChange = (index: number): void => {
        setSelectCurrentIndex(index);
        setSelectedScheduleWindowId(scheduleWindowsForDate ? scheduleWindowsForDate[index].id : '');
        onScheduleWindowIdChange(
            scheduleWindowsForDate ? scheduleWindowsForDate[index].id : defaultScheduleWindowId,
        );
    };

    const handleEstimatedDurationInputChange = (value: string): void => {
        if (value === '') {
            return setEstimatedDurationInput(undefined);
        }

        if (value.match(/^(\s*|\d+)$/)) {
            setEstimatedDurationInput(parseInt(value));
        }
    };

    const handleResetButtonPress = (): void => {
        setSelectedScheduleWindowId(defaultScheduleWindowId);
        setSelectedDate(defaultSelectedDate);
        onScheduleWindowIdChange(defaultScheduleWindowId);

        setScheduleWindowsForDate(undefined);
        setSelectedDateChanged(true);
        scheduleWindowRefetch({
            zipCode: zip ?? 'UNDEFINED_ZIP_CODE',
            startDate: startOfDay(new Date(defaultSelectedDate)).toISOString(),
            endDate: endOfDay(new Date(defaultSelectedDate)).toISOString(),
        }).then((res) => {
            if (res.data.territoryByZipCode) {
                const scheduleWindows = res.data.territoryByZipCode.schedule ?? [];

                setScheduleWindowsForDate(scheduleWindows);
                setSelectedDateChanged(false);
                onScheduleWindowsForDateChange(scheduleWindows);
            }
        });
    };

    const handleConfirmRequestPress = (): void => {
        if (!estimatedDurationInput) {
            console.log('No estimated duration input');
            return;
        }

        const visitDetails: {
            visitId: string;
            estimatedDuration: number;
            scheduleWindowId?: string;
            scheduleWindowDate?: Date;
        } = {
            visitId: visitId,
            estimatedDuration: estimatedDurationInput,
        };

        if (selectedScheduleWindowId !== visitScheduleWindowId) {
            visitDetails.scheduleWindowId = selectedScheduleWindowId;
        }

        if (selectedDate) {
            if (selectedDate.toISOString() !== visitStartTime) {
                visitDetails.scheduleWindowDate = selectedDate;
            }
        }

        onConfirmRequestPress(visitDetails);
    };

    const handleApproveRequestPress = (): void => {
        if (!estimatedDurationInput) {
            console.log('No estimated duration input');
            return;
        }

        if (
            selectedScheduleWindowId !== visitScheduleWindowId ||
            selectedDate.toISOString() !== new Date(visitStartTime).toISOString()
        ) {
            setDisplayModal(true);
        } else {
            handleConfirmRequestPress();
        }
    };

    const breakdownExists =
        requestsForVisit &&
        (requestsForVisit.incoming.length > 0 || requestsForVisit.outgoing.length > 0);

    return (
        <>
            {displayModal && (
                <Modal
                    visible={displayModal}
                    onBackdropPress={() => setDisplayModal(false)}
                    testID="data-visitRequestConfirmationModal"
                >
                    <Card disabled style={{ margin: 10 }}>
                        <View style={{ marginBottom: 5 }}>
                            <Text>
                                <FormattedMessage
                                    id="visitReviewPage.confirmationModal"
                                    defaultMessage="Are you sure you want to change the visit date and/or schedule window?"
                                />
                            </Text>
                        </View>

                        <View
                            style={{
                                marginTop: 5,
                                display: 'flex',
                                flexDirection: mobileView ? 'column' : 'row',
                            }}
                        >
                            <View style={{ flex: 1, marginRight: mobileView ? 0 : 5 }}>
                                <Button
                                    title={intl.formatMessage({
                                        id: 'visitReviewPage.confirmationDeny',
                                        defaultMessage: 'No, go back',
                                    })}
                                    onPress={() => setDisplayModal(false)}
                                    status="primary"
                                />
                            </View>

                            <View
                                style={{
                                    flex: 1,
                                    marginLeft: mobileView ? 0 : 5,
                                    marginTop: mobileView ? 10 : 0,
                                }}
                            >
                                <Button
                                    title={intl.formatMessage({
                                        id: 'visitReviewPage.confirmationApprove',
                                        defaultMessage: 'Yes, approve request',
                                    })}
                                    onPress={() => {
                                        setDisplayModal(false);
                                        handleConfirmRequestPress();
                                    }}
                                    status="warning"
                                />
                            </View>
                        </View>
                    </Card>
                </Modal>
            )}

            {approveRequestError && (
                <View style={{ marginBottom: 5 }}>
                    <Message
                        header={intl.formatMessage({
                            id: 'visitReviewPage.approveVisitError',
                            defaultMessage: 'There was an error',
                        })}
                        content={approveRequestError}
                        type="negative"
                    />
                </View>
            )}

            <Card disabled testID="data-visitRequest">
                <View>
                    <View style={{ display: 'flex', flexDirection: mobileView ? 'column' : 'row' }}>
                        <View
                            style={{
                                flex: 1,
                                marginRight: mobileView ? 0 : 5,
                                display: 'flex',
                                flexDirection: 'row',
                            }}
                        >
                            <View style={{ marginRight: 5, marginTop: -3 }}>
                                <Button
                                    title={intl.formatMessage({
                                        id: 'visitReviewPageNavigatePrevRequest',
                                        defaultMessage: '<',
                                    })}
                                    onPress={onPrevDayPress}
                                    testID="data-visitRequestPrevDateButton"
                                />
                            </View>

                            <View style={{ flex: 1 }}>
                                <Datepicker
                                    date={selectedDate}
                                    onSelect={handleDateChange}
                                    testID="data-visitRequestSelectedDate"
                                />
                            </View>

                            <View style={{ marginLeft: 5, marginTop: -3 }}>
                                <Button
                                    title={intl.formatMessage({
                                        id: 'visitReviewPageNavigateNextRequest',
                                        defaultMessage: '>',
                                    })}
                                    onPress={onNextDayPress}
                                    testID="data-visitRequestNextDateButton"
                                />
                            </View>
                        </View>

                        <View
                            style={{
                                flex: 1,
                                marginLeft: mobileView ? 0 : 5,
                                marginTop: mobileView ? 5 : 0,
                            }}
                        >
                            <Select
                                overrideDisplayValue={overrideSelectDisplayValue}
                                itemsDisplayValues={scheduleWindowItems.map((item) => item.title)}
                                currentIndex={selectCurrentIndex}
                                onSelect={handleScheduleWindowChange}
                                testID="data-visitRequestScheduleWindowsSelect"
                            >
                                {scheduleWindowItems.map((item) => (
                                    <SelectItem key={item.id} title={item.title} />
                                ))}
                            </Select>
                        </View>
                    </View>

                    <View
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            marginTop: mobileView ? 5 : 0,
                        }}
                    >
                        <View style={{ flex: 1, marginRight: 5 }} />

                        <View style={{ flex: 1, marginLeft: 5 }}>
                            <Button
                                title={intl.formatMessage({
                                    id: 'visitReviewPage.resetSelection',
                                    defaultMessage: 'Reset',
                                })}
                                size="small"
                                onPress={handleResetButtonPress}
                                appearance="ghost"
                                testID="data-visitRequestResetButton"
                            />
                        </View>
                    </View>
                </View>

                <Card
                    disabled
                    style={{ marginVertical: 10 }}
                    testID="data-visitRequestVisitSummary"
                >
                    {visitMultiplePeople && (
                        <Text testID="data-visitRequestMultiplePeopleText">
                            <FormattedMessage
                                id="visitReviewPage.multiplePeopleRequired"
                                defaultMessage="Multiple people required"
                            />
                        </Text>
                    )}
                    <Text style={{ fontWeight: '700' }}>
                        <FormattedMessage
                            id="visitReviewPage.itemsForPickup"
                            defaultMessage="{incoming} x items for pickup"
                            values={{ incoming: visitIncoming }}
                        />
                    </Text>

                    {showItemsBreakdown &&
                        requestsForVisit &&
                        requestsForVisit.incoming.length > 0 && (
                            <View
                                style={{ marginVertical: 5 }}
                                testID="data-visitIncomingRequestItemsBreakdown"
                            >
                                {requestsForVisit.incoming.map((request) => (
                                    <Text key={request.id} style={{ fontSize: 12 }}>
                                        {request.storageItem?.storageItemType.name}
                                    </Text>
                                ))}
                            </View>
                        )}

                    <Text style={{ fontWeight: '700' }}>
                        <FormattedMessage
                            id="visitReviewPage.itemsForDelivery"
                            defaultMessage="{outGoing} x items for delivery"
                            values={{ outGoing: visitOutGoing }}
                        />
                    </Text>

                    {showItemsBreakdown &&
                        requestsForVisit &&
                        requestsForVisit.outgoing.length > 0 && (
                            <View
                                style={{ marginVertical: 5 }}
                                testID="data-visitOutgoingRequestItemsBreakdown"
                            >
                                {requestsForVisit.outgoing.map((request) => (
                                    <Text key={request.id} style={{ fontSize: 12 }}>
                                        {request.storageItem?.storageItemType.name}
                                    </Text>
                                ))}
                            </View>
                        )}

                    {breakdownExists && showItemsBreakdown && (
                        <View style={{ marginVertical: 5 }}>
                            <Button
                                title={intl.formatMessage({
                                    id: 'visitReviewPage.hideBreakdown',
                                    defaultMessage: 'Hide breakdown',
                                })}
                                onPress={() => setShowItemsBreakdown(false)}
                                size="small"
                                appearance="ghost"
                                testID="data-visitRequestHideBreakdownButton"
                            />
                        </View>
                    )}

                    {breakdownExists && !showItemsBreakdown && (
                        <View style={{ marginVertical: 5 }}>
                            <Button
                                title={intl.formatMessage({
                                    id: 'visitReviewPage.showBreakdown',
                                    defaultMessage: 'Show breakdown',
                                })}
                                onPress={() => setShowItemsBreakdown(true)}
                                size="small"
                                appearance="ghost"
                                testID="data-visitRequestShowBreakdownButton"
                            />
                        </View>
                    )}
                </Card>

                <Card disabled testID="data-visitRequestAddress">
                    {visitFirstVisit && (
                        <Text
                            style={{ fontWeight: '700' }}
                            testID="data-visitRequestFirstVisitText"
                        >
                            <FormattedMessage
                                id="visitReviewPage.firstDelivery"
                                defaultMessage={`This is the customer's first visit`}
                            />
                        </Text>
                    )}

                    <Text style={{ fontWeight: '700' }}>
                        {firstName} {lastName}
                    </Text>
                    <Text>{phone}</Text>
                    <Text>{address1}</Text>
                    <Text>
                        {/* TODO: Format address based on regions address format */}
                        {city} {zoneId}, {countryId}
                    </Text>
                    <Text>{zip}</Text>
                </Card>

                <View style={{ marginVertical: 10 }}>
                    <TextInput
                        type="text"
                        ariaLabel={intl.formatMessage({
                            id: 'visitReviewPage.estimatedDurationPlaceholder',
                            defaultMessage: 'Estimated duration (required)',
                        })}
                        placeholder={intl.formatMessage({
                            id: 'visitReviewPage.estimatedDurationPlaceholder',
                            defaultMessage: 'Estimated duration (required)',
                        })}
                        value={estimatedDurationInput ?? ''}
                        onChange={handleEstimatedDurationInputChange}
                        disabled={visitStatus !== 'PENDING'}
                        testID="data-visitRequestEstimatedDurationInput"
                    />
                </View>

                <View>
                    {approveRequestLoading ? (
                        <Button
                            title={intl.formatMessage({
                                id: 'visitReviewPage.approvingRequest',
                                defaultMessage: 'Approving Request...',
                            })}
                            onPress={() => null}
                            size="medium"
                            disabled={true}
                        />
                    ) : (
                        <Button
                            title={intl.formatMessage({
                                id: 'visitReviewPage.approveRequest',
                                defaultMessage: 'Approve Request',
                            })}
                            onPress={handleApproveRequestPress}
                            size="medium"
                            disabled={
                                visitStatus !== 'PENDING' || estimatedDurationInput === undefined
                            }
                            testID="data-visitRequestApproveRequestButton"
                        />
                    )}
                </View>
            </Card>
        </>
    );
};
