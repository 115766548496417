import { gql } from '@apollo/client';
import React, { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FlatList, Image, ScrollView, StyleSheet, Text, View } from 'react-native';
import { Button, Icon, TextInput, Message, Card, ErrorMessage } from '@valet/ui-components';
import {
    MovingProcessItemLocationFragment,
    MovingProcessItemStorageItemFragment,
    useMovingLocationByBarcodeLazyQuery,
    useMovingProcessItemMutation,
} from '../../../schema-types';

export const movingPageSize = 100;

const STORAGEITEM_FRAGMENT = gql`
    fragment movingProcessItemStorageItem on StorageItem {
        id
        status
        barcode
        container {
            id
            barcode
        }
        storageItemType {
            id
            name
            description
            image
        }
        location {
            description
            barcode
        }
    }
`;

export const MOVING_PROCESS_ITEM_MUTATION = gql`
    mutation movingProcessItem($data: MovingProcessItemInput!) {
        movingProcessItem(data: $data) {
            data {
                id
                ...movingProcessItemStorageItem
            }
        }
    }
    ${STORAGEITEM_FRAGMENT}
`;

const LOCATION_FRAGMENT = gql`
    fragment movingProcessItemLocation on Location {
        id
        barcode
        description
    }
`;

export const RETRIEVE_LOCATION_BY_BARCODE_QUERY = gql`
    query movingLocationByBarcode($barcode: String!) {
        locationByBarcode(barcode: $barcode) {
            id
            ...movingProcessItemLocation
        }
    }

    ${LOCATION_FRAGMENT}
`;

type MovingProcessPageProps = {};

export const MovingProcessPage: React.FC<MovingProcessPageProps> = () => {
    const [errorMessage, setErrorMessage] = useState<ErrorMessage | undefined>(undefined);
    const intl = useIntl();

    const [expandBarcodeManualEntry, setExpandBarcodeManualEntry] = useState<boolean>(false);
    const [barcodeEntryValue, setBarcodeEntryValue] = useState<string>('');

    const [currentLocation, setCurrentLocation] = useState<
        MovingProcessItemLocationFragment | undefined
    >(undefined);

    const [movedItems, setMovedItems] = useState<
        {
            location: MovingProcessItemLocationFragment;
            item: MovingProcessItemStorageItemFragment;
        }[]
    >([]);

    const handleManualScanCameraPress = useCallback((): void => {
        console.log('Pressed Manual Scan Camera Icon');
    }, []);

    const handleManualScanKeyboardPress = useCallback((): void => {
        setExpandBarcodeManualEntry(!expandBarcodeManualEntry);
    }, [expandBarcodeManualEntry]);

    const [
        movingProcessItemMutation,
        { loading: movingProcessItemMutationLoading, error: movingProcessItemMutationError },
    ] = useMovingProcessItemMutation();

    const [
        movingLocationByBarcode,
        { loading: movingLocationByBarcodeLoading, error: movingLocationByBarcodeError },
    ] = useMovingLocationByBarcodeLazyQuery({
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            if (data && data.locationByBarcode) setCurrentLocation(data.locationByBarcode);
        },
    });

    const getLocationByBarcode = useCallback(
        async (barcode: string) => {
            setErrorMessage(undefined);
            movingLocationByBarcode({
                variables: { barcode },
            });

            if (movingLocationByBarcodeError) {
                setErrorMessage({
                    content: intl.formatMessage({
                        id: 'movingProcessPage.errorGetLocationByBarcodeContent',
                        defaultMessage: 'Could not find location by barcode',
                    }),
                    type: 'warning',
                    header: intl.formatMessage({
                        id: 'movingProcessPage.errorGetLocationByBarcodeHeader',
                        defaultMessage: 'There was an issue',
                    }),
                });
                return false;
            }

            return true;
        },
        [intl, movingLocationByBarcode, movingLocationByBarcodeError],
    );

    const handleManualScanSubmit = useCallback(async () => {
        setErrorMessage(undefined);
        if (barcodeEntryValue) {
            try {
                if (currentLocation && currentLocation.barcode === barcodeEntryValue) {
                    setCurrentLocation(undefined);
                } else {
                    if (!currentLocation) {
                        const res = await getLocationByBarcode(barcodeEntryValue);
                        if (!res)
                            setErrorMessage({
                                content: intl.formatMessage({
                                    id: 'movingProcessPage.errorInvalidLocationBarcode',
                                    defaultMessage: 'Please enter valid Location barcode',
                                }),
                                type: 'warning',
                                header: intl.formatMessage({
                                    id: 'movingProcessPage.errorMessageHeader',
                                    defaultMessage: 'There was an issue',
                                }),
                            });
                    } else {
                        const result = await movingProcessItemMutation({
                            variables: {
                                data: {
                                    locationId: currentLocation.id,
                                    itemBarcode: barcodeEntryValue,
                                },
                            },
                        });

                        if (result?.data?.movingProcessItem?.data) {
                            movedItems.push({
                                location: currentLocation,
                                item: result?.data?.movingProcessItem?.data,
                            });
                            setMovedItems(movedItems);
                        }
                    }
                }
                setExpandBarcodeManualEntry(!expandBarcodeManualEntry);
                setBarcodeEntryValue('');
            } catch {}
        }
    }, [
        movedItems,
        barcodeEntryValue,
        expandBarcodeManualEntry,
        movingProcessItemMutation,
        setErrorMessage,
        currentLocation,
        getLocationByBarcode,
        intl,
    ]);

    return (
        <ScrollView>
            <View style={movingProcessPageStyles.ViewParent} testID="data-deliveryDetail">
                <View>
                    <View
                        style={movingProcessPageStyles.ViewActionsGroup}
                        testID="data-movingProcessItemActionsContainer"
                    >
                        {(movingProcessItemMutationLoading || movingLocationByBarcodeLoading) && (
                            <View testID="data-movingProcessItemLoading">
                                <Text>
                                    <FormattedMessage
                                        id="movingProcessPage.loading"
                                        defaultMessage="Loading..."
                                    />
                                </Text>
                            </View>
                        )}
                        {errorMessage && (
                            <Message errorMessage={errorMessage} testID="data-messageRefileError" />
                        )}

                        {movingLocationByBarcodeError && (
                            <Message
                                type="negative"
                                header="Error"
                                content={intl.formatMessage({
                                    id:
                                        'movingProcessPage.movingLocationByBarcodeMutationErrorMessage',
                                    defaultMessage:
                                        'Could not retreive location - please try again',
                                })}
                                testID="data-movingLocationByBarcodeMutationErrorMessage"
                            />
                        )}

                        {movingProcessItemMutationError && (
                            <View
                                style={movingProcessPageStyles.ViewErrorMessageParent}
                                testID="data-errorMessage"
                            >
                                <Message
                                    type="negative"
                                    content={intl.formatMessage({
                                        id: 'movingProcessPage.movingProcessPageErrorMessage',
                                        defaultMessage:
                                            'Could not move this item - please try again',
                                    })}
                                    header="Error"
                                    testID="data-movingProcessPageErrorMessage"
                                />
                            </View>
                        )}
                        {currentLocation ? (
                            <View
                                style={movingProcessPageStyles.ViewLocationMessageParent}
                                testID="data-locationMessage"
                            >
                                <Message
                                    type="info"
                                    header={`${intl.formatMessage({
                                        id: 'movingProcessPage.movingProcessPageLocationMessage',
                                        defaultMessage: `Current Location: `,
                                    })}${currentLocation.barcode}`}
                                    content=""
                                    testID="data-movingProcessPageCurrentLocationMessage"
                                />
                            </View>
                        ) : (
                            <View
                                style={movingProcessPageStyles.ViewLocationMessageParent}
                                testID="data-locationMessage"
                            >
                                <Message
                                    type="info"
                                    header={`${intl.formatMessage({
                                        id: 'movingProcessPage.movingProcessPageLocationMessage',
                                        defaultMessage: `Please Scan the location first and then scan storage item for moving to that location.`,
                                    })}`}
                                    content=""
                                    testID="data-movingProcessPageInfoMessage"
                                />
                            </View>
                        )}
                        <View style={movingProcessPageStyles.ViewContent}>
                            <View style={movingProcessPageStyles.ViewContent}>
                                {movedItems.length > 0 ? (
                                    <FlatList
                                        data={movedItems}
                                        renderItem={({ item }) => <MovingItemDetail data={item} />}
                                        keyExtractor={(key) => key.item.id}
                                    />
                                ) : (
                                    <Text>
                                        <FormattedMessage
                                            id="movingProcessPage.noRefileListText"
                                            defaultMessage="No Item has been moved yet.."
                                        />
                                    </Text>
                                )}
                            </View>
                        </View>

                        {expandBarcodeManualEntry && (
                            <View
                                style={{
                                    padding: 3,
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                                testID="data-movingProcessItemManualBarcodeEntryContainer"
                            >
                                <View style={{ flex: 1, marginRight: 5 }}>
                                    <TextInput
                                        type="text"
                                        onChange={(e) => setBarcodeEntryValue(e)}
                                        value={barcodeEntryValue}
                                        ariaLabel={intl.formatMessage({
                                            id:
                                                'movingProcessItemManualBarcodeEntryPlaceholderText',
                                            defaultMessage: 'Enter item barcode',
                                        })}
                                        placeholder={intl.formatMessage({
                                            id:
                                                'movingProcessItemManualBarcodeEntryPlaceholderText',
                                            defaultMessage: 'Enter item barcode',
                                        })}
                                        testID="data-movingProcessItemManualBarcodeEntryInput"
                                    />
                                </View>

                                <View style={{ marginLeft: 5 }}>
                                    <Button
                                        onPress={handleManualScanSubmit}
                                        title={intl.formatMessage({
                                            id: 'movingProcessItemScanButton',
                                            defaultMessage: 'SCAN',
                                        })}
                                        testID="data-movingProcessItemManualBarcodeEntrySubmitButton"
                                    />
                                </View>
                            </View>
                        )}
                        <View style={{ display: 'flex', flexDirection: 'row' }}>
                            <View style={movingProcessPageStyles.ViewManualScanIcons}>
                                <Button
                                    accessoryLeft={() => <Icon icon="barcodeCamera" />}
                                    onPress={handleManualScanCameraPress}
                                    testID="data-movingProcessItemActionCamera"
                                    title={''}
                                    appearance="ghost"
                                    accessibilityLabel={intl.formatMessage({
                                        id: 'movingProcessItemCameraButton',
                                        defaultMessage: 'Open camera to scan item',
                                    })}
                                />

                                <Button
                                    accessoryLeft={() => <Icon icon="barcodeKeyboard" />}
                                    onPress={handleManualScanKeyboardPress}
                                    testID="data-movingProcessItemActionKeyboard"
                                    title={''}
                                    appearance="ghost"
                                    accessibilityLabel={intl.formatMessage({
                                        id: 'movingProcessItemKeyboardButton',
                                        defaultMessage: 'Open keyboard to enter barcode',
                                    })}
                                />
                            </View>
                        </View>
                    </View>
                </View>
            </View>
        </ScrollView>
    );
};

const movingProcessPageStyles = StyleSheet.create({
    ViewParent: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    ViewTabs: {
        marginVertical: 5,
        display: 'flex',
        flexDirection: 'row',
    },
    ViewContent: {
        paddingVertical: 5,
    },
    TextTab: {
        flex: 1,
        color: '#000',
        backgroundColor: '#D4D4D5',
        fontSize: 18,
        textAlign: 'center',
        fontWeight: '700',
    },
    ViewTopBar: {
        paddingBottom: 5,
        display: 'flex',
        flexDirection: 'row' as 'row',
        alignItems: 'center',
        borderBottomWidth: 1,
        borderBottomColor: '#d4d4d5',
    },
    ViewActionsGroup: {
        marginVertical: 5,
        display: 'flex',
        flexDirection: 'column',
        borderTopColor: '#D4D4D5',
        borderTopWidth: 1,
    },
    ViewButton: {
        marginTop: 10,
    },
    CancelAction: {
        borderWidth: 1,
        borderRadius: 5,
        padding: 5,
    },
    ViewDetailGroup: {
        marginVertical: 2,
        display: 'flex',
        flexDirection: 'row',
    },
    DeliveryHeader: {
        fontWeight: '700',
        flex: 1,
    },

    ViewExpandPickList: {
        flex: 1,
        paddingVertical: 5,
        display: 'flex',
        overflowY: 'scroll',
        overflowX: 'none',
    },
    ViewExpandPickListText: {
        marginVertical: 5,
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
    },
    ViewManualScanIcons: {
        marginVertical: 5,
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    ViewActions: {
        marginVertical: 5,
        display: 'flex',
        flexDirection: 'row',
    },
    ViewNavigationButtons: {
        flex: 1,
        marginRight: 5,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    TextActionList: {
        alignSelf: 'flex-start',
        color: '#0984e3',
    },
    TextCurrentItemNumber: {
        fontWeight: '700',
        fontSize: 18,
        textAlign: 'center',
    },
    ViewItem: {
        flex: 1,
    },
    TextHeader: {
        fontWeight: '700',
    },
    ViewErrorMessageParent: {
        marginTop: 5,
        marginBottom: 5,
    },
    ViewLocationMessageParent: {
        marginTop: 5,
        marginBottom: 5,
    },
});

type MovingItemDetailProps = {
    data: {
        location: MovingProcessItemLocationFragment;
        item: MovingProcessItemStorageItemFragment;
    };
};

const MovingItemDetail: React.FC<MovingItemDetailProps> = ({ data }) => {
    const { location, item: storageItem } = data;

    return (
        <Card
            onPress={() => null}
            style={MovingItemDetailStyles.ViewParent}
            testID="data-movingItemDetailss"
        >
            <View style={MovingItemDetailStyles.ViewDetailGroup}>
                <Image
                    style={MovingItemDetailStyles.ImageItem}
                    source={{ uri: storageItem?.storageItemType.image }}
                    testID="data-MovingItemDetailImage"
                />
                <View>
                    <View style={MovingItemDetailStyles.ViewDetailGroup}>
                        <Text
                            style={MovingItemDetailStyles.TextItemValue}
                            testID="data-MovingItemDetailDescription"
                        >
                            {storageItem?.storageItemType.description}
                        </Text>
                    </View>
                    <View style={MovingItemDetailStyles.ViewDetailGroup}>
                        <Text
                            style={MovingItemDetailStyles.CustomerTextItemLabel}
                            testID="data-MovingItemDetailLocation"
                        >
                            <FormattedMessage
                                id="movingItemDetailss.barcode"
                                defaultMessage="Barcode"
                            />
                            :
                        </Text>
                        <Text style={MovingItemDetailStyles.TextItemValue}>
                            {storageItem?.container?.barcode || storageItem?.barcode}
                        </Text>
                    </View>
                    <View style={MovingItemDetailStyles.ViewDetailGroup}>
                        <Text
                            style={MovingItemDetailStyles.CustomerTextItemLabel}
                            testID="data-MovingItemDetailLocation"
                        >
                            <FormattedMessage
                                id="movingItemDetailss.location"
                                defaultMessage="Location: "
                            />
                        </Text>
                        <Text
                            style={MovingItemDetailStyles.TextItemValue}
                        >{`${location.description} (${location.barcode})`}</Text>
                    </View>
                </View>
            </View>
        </Card>
    );
};

const MovingItemDetailStyles = StyleSheet.create({
    ViewParent: {
        marginVertical: 3,
    },
    ViewDetailGroup: {
        marginVertical: 2,
        display: 'flex',
        flexDirection: 'row',
    },
    ViewImageGroup: {
        marginVertical: 5,
        display: 'flex',
        justifyContent: 'center',
    },
    TextDetailHeader: {
        fontWeight: '700',
    },
    ImageItem: {
        height: 40,
        width: 40,
        resizeMode: 'stretch',
        marginLeft: 5,
        marginRight: 10,
    },

    ViewItemsList: {
        marginVertical: 5,
    },
    ViewItem: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
    },
    ViewItemsSummary: {
        marginVertical: 5,
    },
    ViewAssignee: {
        marginVertical: 5,
    },
    TextHeader: {
        fontWeight: '700',
    },
    TextItemLabel: {
        flex: 1,
    },
    CustomerTextItemLabel: {
        flex: 0,
        marginRight: 5,
    },
    TextItemValue: {
        flex: 1,
        fontWeight: '700',
    },
    TouchableOpacityItem: {
        borderWidth: 1,
        borderRadius: 5,
        padding: 5,
    },
    TextDescription: {
        fontWeight: '700',
    },
    ViewButton: {},
});
