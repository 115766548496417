import React from 'react';
import { Platform } from 'react-native';
import { useDrag } from 'react-dnd';
import { View, Text, FlatList, ListRenderItem, StyleSheet } from 'react-native';
import { Button, Card } from '@valet/ui-components';
import { RouteStopData, TerritoryData, VisitData } from './Routing';
import { VisitInformation } from './VisitInformation';
import { useIntl } from 'react-intl';

type VisitWithRouteStop = VisitData & { routeStop: RouteStopData };

type TerritoryDetailsProps = {
    territory: TerritoryData;
    visits: VisitWithRouteStop[];
    onAddVisitToRoute?: (id: VisitData['id']) => void;
};

export const TerritoryDetails: React.FC<TerritoryDetailsProps> = ({
    territory,
    visits,
    onAddVisitToRoute = () => undefined,
}) => {
    const renderItem: ListRenderItem<VisitWithRouteStop> = ({ item: visit }) => (
        <TerritoryDetailsVisitListItem
            visit={visit}
            onAddToRoute={() => onAddVisitToRoute(visit.id)}
        />
    );

    return (
        <>
            <View>
                <Text>{territory.description}</Text>
            </View>

            <FlatList data={visits} extraData={[onAddVisitToRoute]} renderItem={renderItem} />
        </>
    );
};

type TerritoryDetailsVisitListItemProps = {
    visit: VisitWithRouteStop;
    onAddToRoute?: () => void;
};

const TerritoryDetailsVisitListItemContent: React.FC<TerritoryDetailsVisitListItemProps> = ({
    visit,
    onAddToRoute = () => undefined,
}) => {
    const intl = useIntl();

    return (
        <View style={menuListStyles.ViewListItemContainer}>
            <Card
                style={{ flex: 1 }}
                disabled
                footer={() => (
                    <View style={menuListStyles.ViewListItemActions}>
                        <View style={{ flex: 1 }}>
                            <Button
                                title={intl.formatMessage({
                                    id: 'routing.addToVisit',
                                    defaultMessage: 'Add to route',
                                })}
                                onPress={onAddToRoute}
                                size="tiny"
                            />
                        </View>
                    </View>
                )}
            >
                <View style={menuListStyles.ViewListItemBody}>
                    <VisitInformation visit={visit} routeStop={visit.routeStop} />
                </View>
            </Card>
        </View>
    );
};

const TerritoryDetailsVisitListItem: React.FC<TerritoryDetailsVisitListItemProps> = (props) => {
    const { visit } = props;

    const [, drag] = useDrag({ item: { type: 'visit', id: visit.id } });

    if (Platform.OS === 'web') {
        return (
            <div ref={drag}>
                <TerritoryDetailsVisitListItemContent {...props} />
            </div>
        );
    }

    return <TerritoryDetailsVisitListItemContent {...props} />;
};

const menuListStyles = StyleSheet.create({
    ViewListItemContainer: {
        flexDirection: 'row',
        paddingVertical: 4,
        paddingRight: 4,
    },
    ViewListItemBody: {
        flex: 1,
        flexDirection: 'column',
    },
    ViewListItemActions: {
        padding: 5,
        flexDirection: 'row',
        justifyContent: 'center',
        flex: 1,
        display: 'flex',
    },
});
