import React from 'react';
import { ScrollView } from 'react-native';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { PickList } from './Picking/PickList';
import { PickListPage } from './PickListPage';
import { CreatePickList } from './Creation/CreatePickList';

const PickListRoutes: React.FC = () => {
    const { url } = useRouteMatch();
    return (
        <ScrollView>
            <Switch>
                <Route path={`${url}/create`} exact>
                    <CreatePickList />
                </Route>

                <Route path={`${url}/:picklistid`} exact>
                    <PickList />
                </Route>

                <Route path={`${url}`}>
                    <PickListPage />
                </Route>
            </Switch>
        </ScrollView>
    );
};

export default PickListRoutes;
