import { gql } from '@apollo/client';
import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Card, Message } from '@valet/ui-components';
import { AdminEmployeeFragment, useGetEmployeesQuery } from '../../../../schema-types';
import { FlatList, ScrollView, StyleSheet, Text, View } from 'react-native';
import { useHistory } from 'react-router-dom';

export const employeePageSize = 100;

const EMPLOYEE_FRAGMENT = gql`
    fragment adminEmployee on Employee {
        id
        firstName
        lastName
        email
        homePhone
        cellPhone
        workPhone
        startDate
        endDate
        address {
            id
            address1
            address2
            city
            zoneId
            countryId
            zip
            longitude
            latitude
            phone
            firstName
            lastName
        }
        type
    }
`;

export const RETRIEVE_EMPLOYEES_QUERY = gql`
    query getEmployees($pageSize: Int!, $cursor: String!) {
        employees(filter: { pageSize: $pageSize, after: $cursor }) {
            edges {
                cursor
                node {
                    id
                    ...adminEmployee
                }
            }
            pageInfo {
                cursor {
                    beforeCursor
                    afterCursor
                }
                hasNextPage
                hasPreviousPage
            }
        }
    }

    ${EMPLOYEE_FRAGMENT}
`;

type EmployeePageProps = {
    onEmployeeSelect: (employeeId: string) => void;
    onAddEmployeeSelect: () => void;
};

export const EmployeePage: React.FC<EmployeePageProps> = ({
    onEmployeeSelect,
    onAddEmployeeSelect,
}) => {
    const intl = useIntl();
    const history = useHistory();

    const [isFetching, setIsFetching] = useState<boolean>(false);

    const {
        data: retrievedEmployeesData,
        loading: retrievedEmployeesLoading,
        error: retrievedEmployeesError,
        fetchMore: retrievedEmployeeFetchMore,
    } = useGetEmployeesQuery({
        variables: {
            pageSize: employeePageSize,
            cursor: '',
        },
    });

    const employeeEdges = retrievedEmployeesData?.employees.edges ?? [];
    const employeeData = useMemo(() => {
        return {
            employee: employeeEdges?.map((itemEdge) => itemEdge.node) ?? [],
            hasNextPage: retrievedEmployeesData?.employees?.pageInfo?.hasNextPage ?? false,
            endCursor: retrievedEmployeesData?.employees?.pageInfo?.cursor.afterCursor ?? '',
        };
    }, [employeeEdges, retrievedEmployeesData]);

    const handleLoadMoreItems = useCallback((): void => {
        if (employeeData.hasNextPage && !isFetching) {
            setIsFetching(true);

            retrievedEmployeeFetchMore({
                variables: {
                    pageSize: employeePageSize,
                    cursor: employeeData.endCursor,
                },
            }).then(() => {
                setIsFetching(false);
            });
        }
    }, [isFetching, employeeData, retrievedEmployeeFetchMore]);

    useEffect(() => {
        if (!retrievedEmployeesError && employeeData.hasNextPage) {
            handleLoadMoreItems();
        }
    }, [handleLoadMoreItems, employeeData, retrievedEmployeesError]);

    const handleItemClick = useCallback(
        (id: string) => {
            onEmployeeSelect(id);
        },
        [onEmployeeSelect],
    );

    const handleAddClick = useCallback(() => {
        onAddEmployeeSelect();
    }, [onAddEmployeeSelect]);

    const handleBacklClick = useCallback(async () => {
        history.push(`/admin`);
    }, [history]);

    return (
        <ScrollView>
            <View style={employeesPageStyles.ViewParent} testID="data-delivery">
                <View style={employeesPageStyles.ViewTabs}>
                    <View>
                        <View style={{ flex: 1, marginRight: 2 }}>
                            <Button
                                title={intl.formatMessage({
                                    id: 'employees.backButton',
                                    defaultMessage: 'Back',
                                })}
                                onPress={handleBacklClick}
                            />
                        </View>
                    </View>
                    <View>
                        <View style={{ flex: 1, marginRight: 10, marginBottom: 10 }}>
                            <Button
                                title={intl.formatMessage({
                                    id: 'employees.addNewEmployee',
                                    defaultMessage: '+ Add New Employee',
                                })}
                                onPress={handleAddClick} />
                        </View>
                    </View>
                </View>
                {retrievedEmployeesLoading &&
                    (
                        <View testID="data-employeesItemLoading">
                            <Text>
                                <FormattedMessage id="employees.loading" defaultMessage="Loading..." />
                            </Text>
                        </View>
                    )
                }
                {retrievedEmployeesError &&
                    (
                        <View style={employeesPageStyles.ViewErrorMessageParent} testID="data-errorMessage">
                            <Message
                                type="negative"
                                header={intl.formatMessage({
                                    id: 'employees.employeesErrorMessage',
                                    defaultMessage: 'Could not load detail for the area - please try again',
                                })}
                                content={retrievedEmployeesError.message}
                                testID="data-employeesErrorMessage"
                            />
                        </View>
                    )
                }
                {!retrievedEmployeesLoading &&
                    !retrievedEmployeesData?.employees.pageInfo.hasNextPage &&
                    retrievedEmployeesData && (
                        <View style={employeesPageStyles.ViewContent}>
                            <FlatList
                                data={employeeData.employee}
                                renderItem={({ item }) => (
                                    <EmployeeItem
                                        key={item.id}
                                        employee={item}
                                        onItemClick={handleItemClick}
                                    />
                                )}
                                keyExtractor={(item) => item.id}
                            />
                        </View>
                    )}
            </View>
        </ScrollView>
    );
};

const employeesPageStyles = StyleSheet.create({
    ViewParent: {
        display: 'flex',
        flexDirection: 'column',
    },
    ViewTabs: {
        marginVertical: 5,
        display: 'flex',
        flexDirection: 'row',
    },
    ViewContent: {
        paddingVertical: 5,
    },
    TextTab: {
        flex: 1,
        color: '#000',
        backgroundColor: '#D4D4D5',
        fontSize: 18,
        textAlign: 'center',
        fontWeight: '700',
    },
    ViewErrorMessageParent: {
        marginTop: 5,
        marginBottom: 5,
    },
});

type EmployeeItemProps = {
    employee: AdminEmployeeFragment;
    onItemClick: (id: string) => void;
};

const EmployeeItem: React.FC<EmployeeItemProps> = ({ employee, onItemClick }) => {
    const intl = useIntl();

    return (
        <Card
            style={employeeItemStyles.ViewParent}
            onPress={() => onItemClick(employee?.id || '')}
            testID="data-deliveryDetail"
        >
            <View style={employeeItemStyles.ViewChild}>
                <View style={employeeItemStyles.ViewContentParent}>
                    <View style={employeeItemStyles.ViewTitleParent}>
                        <Text
                            style={employeeItemStyles.TextTitle}
                            testID="data-employeeItemDescription"
                        >
                            {`${intl.formatMessage({
                                id: 'employeeItem.Name',
                                defaultMessage: 'Name: ',
                            })}`}
                        </Text>
                        <Text>{employee?.firstName + ' ' + employee?.lastName}</Text>
                    </View>
                    <View style={employeeItemStyles.ViewTitleParent}>
                        <Text
                            style={employeeItemStyles.TextTitle}
                            testID="data-employeeItemDescription"
                        >
                            {`${intl.formatMessage({
                                id: 'employeeItem.Email',
                                defaultMessage: 'Email: ',
                            })}`}
                        </Text>
                        <Text>{employee?.email}</Text>
                    </View>
                </View>
            </View>
        </Card>
    );
};

const employeeItemStyles = StyleSheet.create({
    ViewParent: {
        marginVertical: 3,
    },
    ViewChild: {
        display: 'flex',
        flexDirection: 'row',
    },
    ViewImageParent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    EmployeeImage: {
        height: 40,
        width: 40,
        resizeMode: 'stretch',
    },
    ViewContentParent: {
        paddingHorizontal: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    ViewTitleParent: {
        display: 'flex',
        flexDirection: 'column',
    },
    ViewTitleChild: {},
    TextTitle: {
        fontSize: 18,
        fontWeight: 'bold',
    },
    ViewDescription: {},
    TextDescription: { fontSize: 14 },
    ViewStatus: {},
    TextStatus: {
        fontSize: 12,
        fontWeight: '700',
    },
    ViewDate: {},
    TextDate: {
        fontSize: 12,
    },
    ViewErrorMessageParent: {
        marginTop: 5,
        marginBottom: 5,
    },
});
