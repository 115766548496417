import { gql } from '@apollo/client';
import React, { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ScrollView, StyleSheet, Text, View } from 'react-native';
import { Button, Message, Select, SelectItem, TextInput } from '@valet/ui-components';
import {
    useGetAdminStorageContainerByIdQuery,
    AdminStorageContainerDetailFragment,
    StorageContainerStatus,
    useGetAdminStorageContainerTypesQuery,
    AdminStorageContainerTypeFragment,
    useAdminUpdateStorageContainerMutation,
} from '../../../../schema-types';
import { useHistory, useRouteMatch } from 'react-router-dom';

const CONTAINERTYPE_FRAGMENT = gql`
    fragment adminStorageContainerType on StorageContainerType {
        id
        description
    }
`;

export const RETRIEVE_CONTAINERTYPE_QUERY = gql`
    query getAdminStorageContainerTypes {
        storageContainerType {
            ...adminStorageContainerType
        }
    }
    ${CONTAINERTYPE_FRAGMENT}
`;


const ADMIN_CONTAINER_DETAIL_FRAGMENT = gql`
    fragment adminStorageContainerDetail on StorageContainer {
        id
        status
        barcode
        containerType {
            id
            description
        }
    }
`;

export const RETRIEVE_CONTAINER_DETAIL_QUERY = gql`
    query getAdminStorageContainerById($containerId: String!) {
        storageContainerById(id: $containerId) {
            ...adminStorageContainerDetail
        }
    }
    ${ADMIN_CONTAINER_DETAIL_FRAGMENT}
`;

export const ADMIN_UPDATE_CONTAINER_MUTATION = gql`
    mutation adminUpdateStorageContainer($data: AdminUpdateStorageContainerInput!) {
        adminUpdateStorageContainer(data: $data) {
            data {
                ...adminStorageContainerDetail
            }
        }
    }
`;

type StorageContainerDetailPageProps = {};

export const StorageContainerDetailPage: React.FC<StorageContainerDetailPageProps> = () => {
    const history = useHistory();
    const [selectIndex, setSelectIndex] = useState<number>(0);
    const [containerSelectedIndex, setContainerSelectedIndex] = useState<number>(0);

    const intl = useIntl();


    const match = useRouteMatch();
    const params: { containerId?: string } = match.params;
    const containerId = params.containerId;
    const [storageContainer, setStorageContainer] = useState<AdminStorageContainerDetailFragment>({
        id: '',
        status: StorageContainerStatus.Available,
        barcode: '',
        containerType: {
            id: '',
            description: '',
        },
    });

    const selectItems: { id: string; unit: StorageContainerStatus; title: string }[] = [
        {
            id: 'Available',
            unit: StorageContainerStatus.Available,
            title: intl.formatMessage({
                id: 'StorageContainerStatus.AVAILABLE',
                defaultMessage: 'AVAILABLE',
            }),
        },
        {
            id: 'InUse',
            unit: StorageContainerStatus.InUse,
            title: intl.formatMessage({
                id: 'StorageContainerStatus.IN_USE',
                defaultMessage: 'IN_USE',
            }),
        },
        {
            id: 'Retired',
            unit: StorageContainerStatus.Retired,
            title: intl.formatMessage({
                id: 'StorageContainerStatus.CUBIC_METRE',
                defaultMessage: 'RETIRED',
            }),
        },
    ];

    const {
        data: retrievedContainerData,
    } = useGetAdminStorageContainerTypesQuery();

    const containerData = retrievedContainerData?.storageContainerType
        ? (retrievedContainerData?.storageContainerType as AdminStorageContainerTypeFragment[])
        : [];


    const {
        loading: retrievedStorageContainerDetailLoading,
        error: retrievedStorageContainerDetailError,
        refetch,
    } = useGetAdminStorageContainerByIdQuery({
        variables: {
            containerId: containerId || '',
        },
        onCompleted: (data: any) => {
            setSelectIndex(selectItems.findIndex(s => s.unit === (data.storageContainerById as AdminStorageContainerDetailFragment).status))
            setContainerSelectedIndex(containerSelectItems.findIndex(s => s.id === (data.storageContainerById as AdminStorageContainerDetailFragment).containerType?.id))
            setStorageContainer(data.storageContainerById as AdminStorageContainerDetailFragment);
        },
    });

    const containerSelectItems: { id: string; description: string }[] = [];
    containerData.map((item) =>
        containerSelectItems.push({
            id: item.id,
            description: item.description,
        }),
    );

    const [
        adminUpdateStorageContainerMutation,
        {
            loading: adminUpdateStorageContainerDetailMutationLoading,
            error: adminUpdateStorageContainerDetailMutationError,
        },
    ] = useAdminUpdateStorageContainerMutation({
        onCompleted(data) {
            if (data) {
                refetch({
                    containerId: containerId || '',
                });
            }
        },
    });

    const handleBacklClick = useCallback(async () => {
        history.push(`/admin/containers`);
    }, [history]);

    const handleSaveStorageContainerDetailClick = useCallback(async () => {
        if (storageContainer) {
            try {
                await adminUpdateStorageContainerMutation({
                    variables: {
                        data: {
                            id: storageContainer.id,
                            barcode: storageContainer.barcode,
                            status: storageContainer.status,
                            storageContainerTypeId: storageContainer.containerType.id,
                        }

                    },
                });
            } catch (error) { }
            handleBacklClick();
        }
    }, [adminUpdateStorageContainerMutation, storageContainer, handleBacklClick]);




    if (retrievedStorageContainerDetailError) {
        return (
            <View style={storageContainerPageStyles.ViewErrorMessageParent} testID="data-errorMessage">
                <Message
                    type="negative"
                    header={intl.formatMessage({
                        id: 'storageContainerDetail.storageContainerDetailErrorMessage',
                        defaultMessage: 'Could not load detail for the eployee - please try again',
                    })}
                    content={retrievedStorageContainerDetailError.message}
                    testID="data-storageContainerDetailErrorMessage"
                />
            </View>
        );
    }

    if (adminUpdateStorageContainerDetailMutationError) {
        return (
            <View style={storageContainerPageStyles.ViewErrorMessageParent} testID="data-errorMessage">
                <Message
                    type="negative"
                    header={intl.formatMessage({
                        id: 'storageContainerDetail.storageContainerDetailUpdateErrorMessage',
                        defaultMessage: 'Could not update storageContainer detail - please try again',
                    })}
                    content={adminUpdateStorageContainerDetailMutationError.message}
                    testID="data-storageContainerDetailUpdateErrorMessage"
                />
            </View>
        );
    }

    if (retrievedStorageContainerDetailLoading || adminUpdateStorageContainerDetailMutationLoading) {
        return (
            <View testID="data-storageContainerDetailItemLoading">
                <Text>
                    <FormattedMessage id="storageContainerDetail.loading" defaultMessage="Loading..." />
                </Text>
            </View>
        );
    }

    return (
        <ScrollView>
            <View style={storageContainerPageStyles.ViewParent} testID="data-storageContainerDetail">
                <View style={storageContainerPageStyles.ViewChild}>
                    <View style={storageContainerPageStyles.ViewContentParent}>
                        <View style={storageContainerPageStyles.ViewTitleParent}>
                            <Text style={storageContainerPageStyles.TextTitle} testID="data-storageContainer">
                                {`${intl.formatMessage({
                                    id: 'storageContainerDetail.id',
                                    defaultMessage: 'Container Details:',
                                })}${''}`}
                            </Text>
                        </View>
                        <View style={storageContainerPageStyles.ViewTitleParent}>
                            <View style={storageContainerPageStyles.ViewDescription}>
                                <View>
                                    <FormattedMessage
                                        id="storageContainerDetail.description"
                                        defaultMessage="Description: "
                                    />
                                    <Select
                                        currentIndex={containerSelectedIndex}
                                        itemsDisplayValues={containerSelectItems.map((items) => items.description)}
                                        onSelect={(index) => {
                                            setStorageContainer({
                                                ...storageContainer,
                                                containerType: {
                                                    id: containerSelectItems[index].id,
                                                    description: containerSelectItems[index].description,
                                                },
                                            });
                                        }}
                                    >
                                        {containerSelectItems.map((item) => (
                                            <SelectItem title={item.description} key={item.id} />
                                        ))}
                                    </Select>
                                </View>
                            </View>
                            <View style={storageContainerPageStyles.ViewDescription}>
                                <FormattedMessage
                                    id="storageContainerDetail.capacityUnit"
                                    defaultMessage="Status: "
                                />
                                <Select
                                    currentIndex={selectIndex}
                                    itemsDisplayValues={selectItems.map((items) => items.title)}
                                    onSelect={(index) => setStorageContainer({ ...storageContainer, status: selectItems[index].unit })}
                                >
                                    {selectItems.map((item) => (
                                        <SelectItem title={item.title} key={item.id} />
                                    ))}
                                </Select>
                            </View>
                            <View style={storageContainerPageStyles.ViewDescription}>
                                <FormattedMessage
                                    id="storageContainerDetail.Barcode"
                                    defaultMessage="Barcode: "
                                />
                                <TextInput
                                    type="text"
                                    ariaLabel="Barcode: "
                                    value={storageContainer.barcode || ''}
                                    onChange={(value: string) => {
                                        setStorageContainer({ ...storageContainer, barcode: value });
                                    }}
                                />
                            </View>
                        </View>
                    </View>
                </View>
                <View
                    style={{
                        marginVertical: 10,
                        marginHorizontal: 10,
                        display: 'flex',
                        flexDirection: 'row',
                    }}
                >
                    <View style={{ flex: 1, marginRight: 10 }}>
                        <Button
                            title={intl.formatMessage({
                                id: 'storageContainerDetail.Cancel',
                                defaultMessage: 'Cancel',
                            })}
                            onPress={handleBacklClick}
                        />
                    </View>

                    <View style={{ flex: 1 }}>
                        <Button
                            title={intl.formatMessage({
                                id: 'storageContainerDetail.Save',
                                defaultMessage: 'Save',
                            })}
                            onPress={handleSaveStorageContainerDetailClick}
                        />
                    </View>
                </View>
            </View>
        </ScrollView >
    );
};

const storageContainerPageStyles = StyleSheet.create({
    ViewParent: {
        display: 'flex',
        flexDirection: 'column',
    },
    ViewTabs: {
        marginVertical: 5,
        display: 'flex',
        flexDirection: 'row',
    },
    ViewContent: {
        paddingVertical: 5,
    },
    TextTab: {
        flex: 1,
        color: '#000',
        backgroundColor: '#D4D4D5',
        fontSize: 18,
        textAlign: 'center',
        fontWeight: '700',
    },

    ViewChild: {
        display: 'flex',
        flexDirection: 'row',
    },
    ViewImageParent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    RouteImage: {
        height: 40,
        width: 40,
        resizeMode: 'stretch',
    },
    ViewContentParent: {
        paddingHorizontal: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    ViewTitleParent: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 10,
    },
    ViewTitleChild: {},
    TextTitle: {
        fontSize: 18,
        fontWeight: 'bold',
    },
    ViewDescription: {
        marginTop: 10,
        fontWeight: 'bold',
    },
    TextDescription: { fontSize: 14 },
    ViewStatus: {},
    TextStatus: {
        fontSize: 12,
        fontWeight: '700',
    },
    ViewDate: {},
    TextDate: {
        fontSize: 12,
    },
    ViewErrorMessageParent: {
        marginTop: 5,
        marginBottom: 5,
    },
    TextContainerItem: {
        marginVertical: 2,
        display: 'flex',
        flexDirection: 'row',
        fontWeight: 'bold',
    },
    ViewImage: {
        paddingHorizontal: 5,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
});
