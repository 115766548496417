import L from 'leaflet';
import { faMapMarkerAlt, faTruck, faCheck } from '@fortawesome/free-solid-svg-icons';
import 'leaflet/dist/leaflet.css';
import './MapIcon.css';

export enum MapIconTypes {
    check = 'check',
    truck = 'truck',
    default = 'default',
}

const MapIconCache: {
    [key: string]: { default: L.DivIcon; truck: L.DivIcon; check: L.DivIcon };
} = {};

export const getMapIcon = ({
    color,
    content,
}: {
    color: string;
    content: MapIconTypes;
}): L.DivIcon => {
    if (MapIconCache[color.toUpperCase()] === undefined) {
        MapIconCache[color.toUpperCase()] = {
            default: L.divIcon({
                iconSize: [25, 25],
                iconAnchor: [0, 0],
                className: 'custom-map-marker',
                html: `<svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 380 560"
            enable-background="new 0 0 380 560"
          >
              <path fill="${color}" d="${faMapMarkerAlt.icon[4]}" />
            <circle cx="192" cy="192" r="130" stroke-width="0" fill="#FFFFFF" />
            </svg>
          `,
            }),
            truck: L.divIcon({
                iconSize: [25, 25],
                iconAnchor: [0, 0],
                className: 'custom-map-marker',
                html: `<svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 380 560"
            enable-background="new 0 0 380 560"
          >
              <path fill="${color}" d="${faMapMarkerAlt.icon[4]}" />
            <circle cx="192" cy="192" r="130" stroke-width="0" fill="#FFFFFF" />
              <path
                transform="translate(100 100) scale(0.32 0.32)"
                fill="#777777"
                d="${faTruck.icon[4]}"
              />
              </svg>
          `,
            }),
            check: L.divIcon({
                iconSize: [25, 25],
                iconAnchor: [0, 0],
                className: 'custom-map-marker',
                html: `<svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 380 560"
            enable-background="new 0 0 380 560"
          >
              <path fill="${color}" d="${faMapMarkerAlt.icon[4]}" />
            <circle cx="192" cy="192" r="130" stroke-width="0" fill="#FFFFFF" />
              <path
                transform="translate(100 100) scale(0.4 0.4)"
                fill="#777777"
                d="${faCheck.icon[4]}"
              />
          </svg>
          `,
            }),
        };
    }

    return MapIconCache[color.toUpperCase()][content];
};
